import { Component, OnInit,HostListener  } from '@angular/core';

@Component({
  selector: 'app-level-up',
  templateUrl: './level-up.component.html',
  styleUrls: ['./level-up.component.css']
})
export class LevelUpComponent implements OnInit {
  isShow: boolean; // Variable pour contrôler la visibilité du bouton de retour en haut
  topPosToStartShowing = 100; // Position de défilement à partir de laquelle afficher le bouton

  // Déclaration d'un écouteur d'événements pour le défilement de la fenêtre
  @HostListener('window:scroll')
  checkScroll() {
    // Récupération de la position actuelle de défilement
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    // Mise à jour de la variable de visibilité en fonction de la position de défilement
    this.isShow = scrollPosition >= this.topPosToStartShowing;
  }

  // Fonction pour défiler vers le haut de la page de manière fluide
  scrollToTop() {
    window.scroll({ // Appel de la fonction de défilement de la fenêtre
      top: 0, // Position verticale de destination
      left: 0, // Position horizontale de destination (0 par défaut)
      behavior: 'smooth' // Défilement fluide
    });
  }
  constructor() { }

  ngOnInit(): void {
  }

}
