<div class="bloc" [ngStyle]="{ direction: direction }">
  <div class="c1">
    <div class="Trac-2081"></div>
    <div style="text-align: center">
      <h2 class="titre-page">Liste FDE</h2>
      <div class="about-border"></div>
    </div>
  </div>
  <div class="blocrecherche" [ngStyle]="{ direction: direction }">
    <div class="row" style="width: 100%">
      <div class="col-lg-3">
        <ng-select
          [items]="itemlist"
          (change)="changedate($event)"
          bindLabel="name"
          placeholder="{{ languef.typedate }}"
          [(ngModel)]="selecteddate"
        >
        </ng-select>
      </div>

      <div class="col-lg-4">
        <mat-form-field appearance="fill">
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              [(ngModel)]="startDate"
              placeholder="{{ languef.startdate }}"
              (dateInput)="onchangedatestart($event.value)"
            />
            <input
              matEndDate
              [(ngModel)]="endDate"
              placeholder="{{ languef.enddate }}"
              (dateInput)="onchangedateend($event.value)"
            />
          </mat-date-range-input>

          <mat-datepicker-toggle
            [disabled]="date_disabled"
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>

      <div class="col-lg-3" style="height: 90px">
        <ng-select
          [items]="statuslist"
          (change)="changestatus($event)"
          [clearable]="true"
          bindLabel="name"
          placeholder="{{ languef.etat }}"
          [(ngModel)]="selectedstatus"
        >
        </ng-select>
      </div>
      <div class="col-lg-1"></div>
      <div class="col-lg-3" style="height: 90px; padding-top: 3px">
        <ng-select
          [items]="itemslist"
          (change)="changeconditionitem($event)"
          bindLabel="name"
          placeholder="{{ languef.condition }}"
          [(ngModel)]="selectedcondition"
        >
        </ng-select>
      </div>
      <div class="col-lg-4" style="padding-top: 5px">
        <input
          type="text"
          [(ngModel)]="valeurcondition"
          mdbInput
          class="form-control"
          placeholder="{{ languef.conditionvalue }}"
        />
      </div>

      <div class="col-lg-3 search">
        <div class="div_action1" (click)="search()">
          <div>
            <span style="color: #fff">{{ languet.search }}</span>
          </div>
          <div style="padding: 0% 4% 0% 4%">
            <mat-icon style="color: #ffffff; font-size: 30px">search</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div style="padding-top: 1%">
    <div style="float: right">
      <button
        routerLink="/fde/new"
        type="button"
        class="btn button3"
        style="display: flex"
      >
        <div>
          <mat-icon class="iconadd">add</mat-icon>
        </div>
        <div style="padding-top: 3px">
          <span class="add"> Nouveau</span>
        </div>
      </button>
    </div>
    <div style="display: flex; padding: 0% 1% 0% 1%; float: left">
      <div style="padding: 0% 1% 0% 1%">
        <button
          [disabled]="false"
          type="button"
          class="btn button8"
          style="display: flex"
          (click)="printdoc()"
        >
          <div>
            <mat-icon class="iconprint">print</mat-icon>
          </div>
          <div style="padding-top: 3px">
            <span class="print"> Imprimer</span>
          </div>
        </button>
      </div>
      <div>
        <button
          type="button"
          [disabled]="selection.selected.length == 0"
          class="btn button8"
          (click)="open(content1)"
          style="display: flex"
        >
          <div>
            <mat-icon class="icondelete">delete</mat-icon>
          </div>
          <div style="padding-top: 3px">
            <span class="print"> Supprimer </span>
          </div>
        </button>
      </div>
    </div>

    <div style="width: 100%; padding-top: 4%">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="$event ? toggleAllRows() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>
        <!-- ID Column -->
        <ng-container matColumnDef="id">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell"
          >
            N°
          </th>
          <td mat-cell class="tdcell" *matCellDef="let row">
            {{ row.id }}
          </td>
        </ng-container>

        <ng-container matColumnDef="idDossier">
          <th mat-header-cell class="headercell" *matHeaderCellDef>
            ID (dossier)
          </th>
          <td mat-cell class="tdcell" *matCellDef="let row">
            {{ row.messages[0].idDossier }}
          </td>
        </ng-container>

        <!-- date Column -->
        <ng-container matColumnDef="date">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell"
          >
            Date de création
          </th>
          <td mat-cell class="tdcell" *matCellDef="let row">
            {{ row.applicationDate }}
          </td>
        </ng-container>
        <!-- date domicilation Column -->
        <ng-container matColumnDef="dated">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell"
          >
            Date domicilation
          </th>
          <td mat-cell class="tdcell" *matCellDef="let row">
            {{ row.dateDomiciliation }}
          </td>
        </ng-container>

        <!-- banque Column -->
        <ng-container matColumnDef="banque">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell"
          >
            Banque
          </th>
          <td mat-cell class="tdcell" *matCellDef="let row">
            {{ row.designationOrganismeDomiciliation }}
          </td>
        </ng-container>

        <!-- codeagence Column -->
        <ng-container matColumnDef="codeagence">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell"
          >
            Code Agence
          </th>
          <td mat-cell class="tdcell" *matCellDef="let row">
            {{ row.codeOrganismeDomicilation }}
          </td>
        </ng-container>

        <!-- CodeTCE Column -->
        <ng-container matColumnDef="codefde">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell"
          >
            Code TCE
          </th>
          <td mat-cell class="tdcell" *matCellDef="let row">
            {{ row.codeImportateur }}
          </td>
        </ng-container>

        <!-- nomfde Column -->
        <ng-container matColumnDef="nomfde">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell"
          >
            N Dom TCE
          </th>
          <td mat-cell class="tdcell" *matCellDef="let row">
            <!-- {{ row.nomPrenomImportateur }} -->
          </td>
        </ng-container>

        <!-- Secteur Activite Column -->
        <ng-container matColumnDef="secteura">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell"
          >
            Secteur activite
          </th>
          <td mat-cell class="tdcell" *matCellDef="let row">
            <!-- {{ row.nomPrenomImportateur }} -->
          </td>
        </ng-container>

        <!-- flux Column -->
        <ng-container matColumnDef="flux">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell"
          >
            Etat N° Flux
          </th>
          <td mat-cell class="tdcell" *matCellDef="let row">
            <!-- {{ row.nomPrenomImportateur }} -->
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="exportateur">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell"
          >
            RS operateur
          </th>
          <td mat-cell class="tdcell" *matCellDef="let row">
            {{ row.nomPrenomExportateur }}
          </td>
        </ng-container>

        <!-- CD Column -->
        <ng-container matColumnDef="cd">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell"
          >
            CD
          </th>
          <td mat-cell class="tdcell" *matCellDef="let row"></td>
        </ng-container>
        <ng-container matColumnDef="pays">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell hidden"
          >
            Pays Achat/Destination
          </th>
          <td mat-cell class="tdcell hidden" *matCellDef="let row">
            {{ row.designationPaysAchat }}
          </td>
        </ng-container>

        <ng-container matColumnDef="numeroContratCommercial">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell hidden"
          >
            Numéro Contrat Commercial
          </th>
          <td mat-cell class="tdcell hidden" *matCellDef="let row">
            {{ row.numeroContratCommercial }}
          </td>
        </ng-container>

        <ng-container matColumnDef="modeReglement">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell hidden"
          >
            Mode reglement
          </th>
          <td mat-cell class="tdcell hidden" *matCellDef="let row">
            {{ row.designationReglement }}
          </td>
        </ng-container>

        <ng-container matColumnDef="delaisDeReglemnt">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell hidden"
          >
            Delais de reglement
          </th>
          <td mat-cell class="tdcell hidden" *matCellDef="let row">
            {{ row.delaiReglement }}
          </td>
        </ng-container>

        <ng-container matColumnDef="codedevise">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell hidden"
          >
            Code Devise
          </th>
          <td mat-cell class="tdcell hidden" *matCellDef="let row">
            {{ row.codeDeviseMontant }}
          </td>
        </ng-container>
        <ng-container matColumnDef="montant">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell hidden"
          >
            Montant En devises
          </th>
          <td mat-cell class="tdcell hidden" *matCellDef="let row">
            {{ row.ptfnDinars }}
          </td>
        </ng-container>

        <ng-container matColumnDef="etat">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell"
          >
            Status
          </th>
          <td
            mat-cell
            class="tdcell"
            *matCellDef="let row"
            style="padding-left: 1%"
          >
            <div *ngIf="row.etat == 'Accepter'" style="display: flex">
              <div>
                <b style="width: 100%">{{ row.etat }}</b>
              </div>
              <div style="padding-left: 1%">
                <mat-icon style="color: #73ff01; font-size: 25px"
                  >check_circle</mat-icon
                >
              </div>
            </div>
            <div *ngIf="row.etat == 'Save'" style="display: flex">
              <div>
                <b style="width: 100%">Sauvegarder</b>
              </div>
              <div style="padding-left: 1%">
                <mat-icon style="color: #000000; font-size: 25px"
                  >save_alt</mat-icon
                >
              </div>
            </div>
            <div *ngIf="row.etat == 'Facture Domiciliee'" style="display: flex">
              <div>
                <b style="width: 100%">Facture Domiciliee</b>
              </div>
              <div style="padding-left: 1%">
                <mat-icon style="color: #16ff01; font-size: 25px"
                  >check_circle</mat-icon
                >
              </div>
            </div>
            <div *ngIf="row.etat == 'rejeter'" style="display: flex">
              <div>
                <b style="width: 100%">
                  {{ row.etat }}
                </b>
              </div>
              <div style="padding-left: 1%">
                <mat-icon style="color: red; font-size: 22px">cancel</mat-icon>
              </div>
            </div>
            <div *ngIf="row.etat == 'For Confirmation'" style="display: flex">
              <div>
                <b style="width: 100%"> For confirmation </b>
              </div>
              <div style="padding-left: 1%">
                <mat-icon style="color: rgb(27, 202, 255); font-size: 22px"
                  >error</mat-icon
                >
              </div>
            </div>
            <div
              *ngIf="row.etat == 'Informations suplementaires'"
              style="display: flex"
            >
              <div>
                <b style="width: 100%"> Provide infos for Bank </b>
              </div>
              <div style="padding-left: 1%">
                <mat-icon style="color: rgb(248, 244, 20); font-size: 22px"
                  >error</mat-icon
                >
              </div>
            </div>
          </td>
        </ng-container>

        <!-- Fruit Column -->
        <ng-container matColumnDef="action">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell"
          >
            Actions
          </th>
          <td mat-cell *matCellDef="let row" class="tdcell">
            <button
              matTooltip="View"
              (click)="Arr_view(content4, row.id)"
              style="border: 0px solid; background-color: #ffffff"
            >
              <div class="btn-action">
                <mat-icon style="color: rgb(110, 110, 110)"
                  >remove_red_eye</mat-icon
                >
              </div>
            </button>
            <button
              *ngIf="
                row.etat == 'Save' || row.etat == 'Informations suplementaires'
              "
              matTooltip="Éditer"
              routerLink="/fde/update/{{ row.id }}"
              style="border: 0px solid; background-color: #ffffff"
            >
              <div class="btn-action">
                <mat-icon style="color: #1671b3">edit</mat-icon>
              </div>
            </button>

            <button
              matTooltip="Message user"
              (click)="Arr_messages(content6, row.id)"
              style="border: 0px solid; background-color: #ffffff"
            >
              <div class="btn-action">
                <mat-icon style="color: #b812ce">message</mat-icon>
              </div>
            </button>
            <button
              *ngIf="row.etat == 'Reject'"
              matTooltip="Reason Reject"
              (click)="open_reason(content10, row.rejectionReason)"
              style="border: 0px solid; background-color: #ffffff"
            >
              <div class="btn-action">
                <mat-icon style="color: #ee0d0d">message</mat-icon>
              </div>
            </button>

            <button
              matTooltip="Copier"
              routerLink="/fde/copy/{{ row.id }}"
              style="border: 0px solid; background-color: #ffffff"
            >
              <div class="btn-action">
                <mat-icon style="color: #19ffb2">file_copy</mat-icon>
              </div>
            </button>
            <button
              *ngIf="
                row.etat == 'Save' || row.etat == 'Informations suplementaires'
              "
              matTooltip="{{ languem.submit }}"
              (click)="Submit(row, content3)"
              style="border: 0px solid; background-color: #ffffff"
            >
              <div class="btn-action">
                <mat-icon style="color: #19ffb2">send</mat-icon>
              </div>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell tdcell" colspan="20" style="min-height: 400px">
            <div>
              <div style="display: block; text-align: center">
                <img
                  src="./../../../../assets/tablenotfound.PNG"
                  style="width: 250px; height: 250px"
                />
              </div>
              <div>
                <span class="spanmessage"> Nous n'avons rien trouvé :( </span>
              </div>
            </div>
          </td>
        </tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[10, 25, 100]"
        aria-label="Select page of users"
      ></mat-paginator>
    </div>
  </div>

  <ng-template #content1>
    <div class="modal-body rounded-8">
      <div style="text-align: center">
        <div style="padding-top: 10%">
          <img
            style="width: 90px; height: 90px"
            src="./../../../assets/icons/list.PNG"
          />
        </div>

        <div style="padding-top: 5%">
          <label
            style="
              width: 270px;
              font-family: Poppins;
              font-size: 20px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 0.98;
              letter-spacing: normal;
              text-align: center;
              color: #1d3a66;
            "
          >
            Voulez-vous vraiment supprimer ces éléments ?
          </label>
        </div>
        <div style="padding-top: 3%; display: flex">
          <div class="container text-right nextbtn">
            <button
              type="button"
              class="btn button10"
              (click)="Arr_remove(content12)"
            >
              <span class="next"> Sure </span>
            </button>
          </div>

          <div class="container text-right nextbtn">
            <button type="button" class="btn button9" (click)="dimision()">
              <span class="next"> no </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #content10 let-modal>
    <div class="modal-header">
      <h4 class="titre_modal" id="modal-basic-title">Reason Reject</h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      ></button>
    </div>

    <div class="modal-body" style="z-index: 1">
      <div class="bodymodal">
        <div style="padding-left: 5%">
          <div style="z-index: 1">
            <label>
              {{ reason }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #content4 class="modal-dialog" let-modal>
    <div class="modal-header">
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      ></button>
    </div>
    <div class="modal-body">
      <mat-tab-group>
        <mat-tab label="Exportateur">
          <div class="row" style="width: 90%; padding: 2%">
            <div class="col-lg-4 labeldetail">
              <div>
                <label class="lab1"> Code Exportateur </label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label>
                  {{ document.codeExportateur }}
                </label>
              </div>
            </div>
            <div class="col-lg-4 labeldetail">
              <div>
                <label class="lab1"> Nom prénom </label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label>
                  {{ document.nomPrenomExportateur }}
                </label>
              </div>
            </div>
            <div class="col-lg-4 labeldetail">
              <div>
                <label class="lab1"> Raison sociale </label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label>
                  {{ document.raisonSocialExportateur }}
                </label>
              </div>
            </div>

            <div class="col-lg-4 labeldetail">
              <div>
                <label class="lab1"> Adresse ligne </label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label>
                  {{ document.adresseExportateur }}
                </label>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Importateur">
          <div class="row" style="width: 90%; padding: 2%">
            <div class="col-lg-4 labeldetail">
              <div>
                <label class="lab1"> Code Importateur </label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label>
                  {{ document.codeImportateur }}
                </label>
              </div>
            </div>
            <div class="col-lg-4 labeldetail">
              <div>
                <label class="lab1"> Nom prénom </label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label>
                  {{ document.nomPrenomImportateur }}
                </label>
              </div>
            </div>
            <div class="col-lg-4 labeldetail">
              <div>
                <label class="lab1"> Raison sociale </label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label>
                  {{ document.raisonSocialImportateur }}
                </label>
              </div>
            </div>

            <div class="col-lg-4 labeldetail">
              <div>
                <label class="lab1"> Adresse ligne </label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label>
                  {{ document.adresseImportateur }}
                </label>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Divers & Pays">
          <div class="row" style="width: 90%; padding: 2%">
            <div class="col-lg-4 labeldetail">
              <div>
                <label class="lab1"> Numero Contrat Commercial </label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label>
                  {{ document.numeroContratCommercial }}
                </label>
              </div>
            </div>
            <div class="col-lg-4 labeldetail">
              <div>
                <label class="lab1"> Code Nature Autorisation </label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label>
                  {{ document.codeNatureAutorisation }}
                </label>
              </div>
            </div>

            <div class="col-lg-4 labeldetail">
              <div>
                <label class="lab1"> Designation Nature Autorisation </label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label>
                  {{ document.designationNatureAutorisation }}
                </label>
              </div>
            </div>

            <div class="col-lg-4 labeldetail">
              <div>
                <label class="lab1"> Pays Achat </label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label>
                  {{ document.designationPaysAchat }}
                </label>
              </div>
            </div>

            <div class="col-lg-4 labeldetail">
              <div>
                <label class="lab1"> Pays Provenance </label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label>
                  {{ document.designationPaysProvenance }}
                </label>
              </div>
            </div>

            <div class="col-lg-4 labeldetail">
              <div>
                <label class="lab1"> Pays 1ér destination </label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label>
                  {{ document.designationPaysPremiereDestination }}
                </label>
              </div>
            </div>

            <div class="col-lg-4 labeldetail">
              <div>
                <label class="lab1"> Pays Destination difinitive </label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label>
                  {{ document.designationPaysDestinationDefinitive }}
                </label>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Réglement">
          <div class="row" style="width: 90%; padding: 2%">
            <div class="col-lg-4 labeldetail">
              <div>
                <label class="lab1"> Mode Réglement </label>
              </div>
              <div>
                <label class="lab2">
                  <label style="color: #e8effc">.</label>__</label
                >
              </div>
            </div>
            <div class="col-lg-4 labeldetail">
              <div>
                <label class="lab1"> Delai Réglement </label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label> __</label
                >
              </div>
            </div>
            <div class="col-lg-4 labeldetail">
              <div>
                <label class="lab1"> Devis Réglement</label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label> __
                </label>
              </div>
            </div>

            <div class="col-lg-4 labeldetail">
              <div>
                <label class="lab1"> Devise </label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label>
                  {{ document.codeDeviseMontant }}</label
                >
              </div>
            </div>

            <div class="col-lg-4 labeldetail">
              <div>
                <label class="lab1"> PTFN Devise </label>
              </div>
              <div>
                <label class="lab2">
                  <label style="color: #e8effc">.</label
                  >{{ document.ptfnDevise }}</label
                >
              </div>
            </div>
            <div class="col-lg-4 labeldetail">
              <div>
                <label class="lab1"> FOB Devise</label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label>
                  {{ document.fcbDevise }}
                </label>
              </div>
            </div>

            <div class="col-lg-4 labeldetail">
              <div>
                <label class="lab1"> PTFN Dinars </label>
              </div>
              <div>
                <label class="lab2">
                  <label style="color: #e8effc">.</label
                  >{{ document.ptfnDinars }}</label
                >
              </div>
            </div>
            <div class="col-lg-4 labeldetail">
              <div>
                <label class="lab1"> FOB Dinars</label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label>
                  {{ document.fcbDinars }}
                </label>
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="Articles">
          <div class="row" style="width: 90%; padding: 2%">
            <div class="div_table">
              <table mat-table [dataSource]="dataSourceArticles">
                <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

                <!-- Position Column -->
                <ng-container matColumnDef="no">
                  <th mat-header-cell *matHeaderCellDef class="headercell">
                    No.
                  </th>
                  <td mat-cell *matCellDef="let element" class="tdcell">
                    {{ element.numeroArticle }}
                  </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="ngp">
                  <th mat-header-cell *matHeaderCellDef class="headercell">
                    NGP
                  </th>
                  <td mat-cell *matCellDef="let element" class="tdcell">
                    {{ element.nomenclature }}
                  </td>
                </ng-container>

                <!-- Désignation Column -->
                <ng-container matColumnDef="des">
                  <th mat-header-cell *matHeaderCellDef class="headercell">
                    Désignation
                  </th>
                  <td mat-cell *matCellDef="let element" class="tdcell">
                    {{ element.designation }}
                  </td>
                </ng-container>

                <!-- PFN Column -->
                <ng-container matColumnDef="pfn">
                  <th mat-header-cell *matHeaderCellDef class="headercell">
                    PFN
                  </th>
                  <td mat-cell *matCellDef="let element" class="tdcell">
                    {{ element.prix }}
                  </td>
                </ng-container>

                <!-- PFN Code QCS -->
                <ng-container matColumnDef="codeqcs">
                  <th mat-header-cell *matHeaderCellDef class="headercell">
                    Code QCS
                  </th>
                  <td mat-cell *matCellDef="let element" class="tdcell">
                    {{ element.uniteMesure }}
                  </td>
                </ng-container>

                <!-- PFN QCS -->
                <ng-container matColumnDef="qcs">
                  <th mat-header-cell *matHeaderCellDef class="headercell">
                    QCS
                  </th>
                  <td mat-cell *matCellDef="let element" class="tdcell">
                    {{ element.quantite }}
                  </td>
                </ng-container>

                <!-- PFN Pays Origine -->
                <ng-container matColumnDef="pays">
                  <th mat-header-cell *matHeaderCellDef class="headercell">
                    Pays Origine
                  </th>
                  <td mat-cell *matCellDef="let element" class="tdcell">
                    {{ element.nomPaysOrigine }}
                  </td>
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumnsArticles"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumnsArticles"
                ></tr>
              </table>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="Piece Jointes">
          <div class="row" style="width: 90%; padding: 2%">
            <!--   <div class="div_table">
            <table mat-table [dataSource]="dataSourcep">
          

           
              <ng-container matColumnDef="file">
                <th mat-header-cell *matHeaderCellDef class="headercell">
                  File
                </th>
                <td mat-cell *matCellDef="let element" class="tdcell">
                  {{ element }}
                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="headercell">
                  Actions
                </th>
                <td mat-cell *matCellDef="let element" class="tdcell">
                  <button
                    matTooltip="View"
                    (click)="openpdf(element, content13)"
                    style="border: 0px solid; background-color: #ffffff"
                  >
                    <div class="btn-action">
                      <mat-icon style="color: rgb(110, 110, 110)"
                        >remove_red_eye</mat-icon
                      >
                    </div>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsP"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsP"></tr>
            </table>
          </div> -->

            <ul>
              <li *ngFor="let book of Files_list">
                Fichier
                <button
                  matTooltip="View"
                  (click)="openpdf(book, content13)"
                  style="border: 0px solid; background-color: #ffffff"
                >
                  <div class="btn-action">
                    <mat-icon style="color: rgb(110, 110, 110)"
                      >remove_red_eye</mat-icon
                    >
                  </div>
                </button>
              </li>
            </ul>
          </div>
        </mat-tab>
        <mat-tab label="TCE"
          ><div class="row" style="width: 90%; padding: 2%">
            <div class="col-lg-4 labeldetail">
              <div>
                <label class="lab1"> Numero Depot </label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label>__
                </label>
              </div>
            </div>
            <div class="col-lg-4 labeldetail">
              <div>
                <label class="lab1"> Date Depot </label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label> __
                </label>
              </div>
            </div>

            <div class="col-lg-4 labeldetail">
              <div>
                <label class="lab1"> Code D'agence Depot </label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label> __
                </label>
              </div>
            </div>

            <div class="col-lg-4 labeldetail">
              <div>
                <label class="lab1"> Numero Domiciliation </label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label>
                  {{ document.numeroDomiciliation }}
                </label>
              </div>
            </div>

            <div class="col-lg-4 labeldetail">
              <div>
                <label class="lab1"> Date Domiciliation </label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label>
                  {{ document.dateDomiciliation }}
                </label>
              </div>
            </div>

            <div class="col-lg-4 labeldetail">
              <div>
                <label class="lab1"> Code D'agence Domiciliation </label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label
                  >{{ document.numeroDomiciliation }}
                </label>
              </div>
            </div>

            <div class="col-lg-4 labeldetail">
              <div>
                <label class="lab1"> Code Mode Livraison </label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label>
                  {{ document.codeModeLivraison }}
                </label>
              </div>
            </div>

            <div class="col-lg-4 labeldetail">
              <div>
                <label class="lab1"> Designation Mode Livraison </label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label>
                  {{ document.designationModeLivraison }}
                </label>
              </div>
            </div>

            <div class="col-lg-4 labeldetail">
              <div>
                <label> Date Contrat Commercial </label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label>
                  {{ document.dateContratCommercial }}
                </label>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </ng-template>

  <ng-template #content3>
    <div class="modal-body rounded-8">
      <div style="text-align: center">
        <div>
          <span
            class="material-icons green-color"
            style="
              font-size: 100px;
              color: #41d96f;
              text-align: center;
              font-weight: 800;
            "
            >check
          </span>
        </div>
        <div>
          <label
            style="
              font-family: Montserrat;
              font-size: 26px;
              padding-top: 2%;
              font-weight: bold;
              color: #073866;
            "
          >
            MERCI</label
          >
        </div>
        <div>
          <label
            style="
              width: 310px;
              font-size: 16px;
              font-weight: bold;
              padding-top: 40px;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.77;
              letter-spacing: normal;
              text-align: center;
            "
          >
            Votre Document Titre De Commerce a été envoyé</label
          >
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #content12>
    <div class="modal-body rounded-8">
      <div style="text-align: center">
        <div style="padding-top: 10%">
          <mat-icon style="color: red; font-size: 70px">error</mat-icon>
        </div>

        <div style="padding-top: 5%">
          <label
            style="
              width: 270px;
              font-family: Poppins;
              font-size: 20px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              text-align: center;
              color: #1d3a66;
            "
          >
            Vous ne pouvez pas supprimer ces élément
          </label>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #content13>
    <div class="modal-body rounded-8">
      <embed
        width="100%"
        height="700"
        [src]="sanitizer.bypassSecurityTrustResourceUrl(document_src)"
        type="application/pdf"
      />
    </div>
  </ng-template>
  <ng-template #content6 class="modal-dialog" let-modal>
    <div class="modal-header">
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      ></button>
    </div>
    <div>
      <table mat-table [dataSource]="dataSourceMessages">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>ID</th>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">{{ element.status }}</td>
        </ng-container>

        <ng-container matColumnDef="startDate">
          <th mat-header-cell *matHeaderCellDef>Start Date</th>
          <td mat-cell *matCellDef="let element">{{ element.startDate }}</td>
        </ng-container>

        <ng-container matColumnDef="endDate">
          <th mat-header-cell *matHeaderCellDef>End Date</th>
          <td mat-cell *matCellDef="let element">{{ element.endDate }}</td>
        </ng-container>

        <ng-container matColumnDef="typeFlux">
          <th mat-header-cell *matHeaderCellDef>Type Flux</th>
          <td mat-cell *matCellDef="let element">{{ element.typeFlux }}</td>
        </ng-container>

        <ng-container matColumnDef="etatFlux">
          <th mat-header-cell *matHeaderCellDef>EtaT Flux</th>
          <td mat-cell *matCellDef="let element">{{ element.etatFlux }}</td>
        </ng-container>

        <ng-container matColumnDef="idDossier">
          <th mat-header-cell *matHeaderCellDef>Id Dossier</th>
          <td mat-cell *matCellDef="let element">{{ element.idDossier }}</td>
        </ng-container>

        <ng-container matColumnDef="typeMessage">
          <th mat-header-cell *matHeaderCellDef>Type Message</th>
          <td mat-cell *matCellDef="let element">{{ element.typeMessage }}</td>
        </ng-container>
        <ng-container matColumnDef="typeScenario">
          <th mat-header-cell *matHeaderCellDef>Type Scenario</th>
          <td mat-cell *matCellDef="let element">{{ element.typeScenario }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsMessages"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumnsMessages"
        ></tr>
      </table>
    </div>
  </ng-template>
</div>
