import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, catchError, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommerceService {
  url: any;
  urlAgence : any;
  urlBanque : any;

  Spring_serveur: string = 'fdes/';

  httpHeaders: any
  httpHeaders1: any
  httpHeaders2: any

  //private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;


  constructor(private http: HttpClient) {
    this.url = environment.UrlFde1 + this.Spring_serveur;
    this.urlAgence = environment.urlAgence;
    this.urlBanque = environment.urlBanque;

  //  let token = localStorage.getItem(this.authLocalStorageToken) || ""
 //   let token_1 = JSON.parse(token);
 //   let token_f = token_1.token


  
  }
  public Get() {
    return this.http.get<any>(this.url, { headers: this.httpHeaders });
  }
  public Getbyteam() {
    return this.http.get<any>(`${this.url + 'by-team'}`);
  }
  public Getbyorg() {
    return this.http.get<any>(`${this.url + 'by-org'}`);
  }
  public GetById(id: any) {
    return this.http.get<any>(`${this.url + '' + id + '/'}`, { headers: this.httpHeaders });
   
  }

  public post(formData: any,id:any) {

    return this.http.post(this.url +'save'+'/'+ `${id}`, formData, { headers: this.httpHeaders });
  }
  public submit(formData: any,id:any) {

        return this.http.post(this.url +'first-submit'+'/'+ `${id}`, formData, { headers: this.httpHeaders });
      }

  public update(formData: any) {


    return this.http.put(this.url + formData.id, formData, { headers: this.httpHeaders });
  }

  public delete(id: any) {
    return this.http.delete<any>(`${this.url + id + '/'}`, { headers: this.httpHeaders });
  }
  
  public response(formData: any) {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(this.url + 'respond', formData, {
      headers: headers1,
      responseType: 'text' 
    });
  }

  public GetAllAgences() {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, DELETE, PUT'
    
    });
    return this.http.get<any>(this.urlAgence, { headers: headers1 })
  }

  public GetAgencesBanque(refNomBank : any) {
    return this.http.get<any>(this.urlAgence + '/' + refNomBank, { headers: this.httpHeaders })
  }

  public GetRibBanque(rib : any) {
    return this.http.get<any>(this.urlBanque + '/' + rib, { headers: this.httpHeaders })
  }

  public GetAllBanques() {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, DELETE, PUT'
    
    });
    return this.http.get<any>(this.urlBanque, { headers: headers1 })
  }

  public getProfilDestinataire() {
    return this.http.get<any>(this.url + 'getProfilDestinataire', { responseType: 'text' as 'json' })
  }

  public Search(attribue: any, valeur: any) {
    return this.http.get<any>(
      `${this.url +
      'List-by-attribute?attribute=' +
      attribue +
      '&value=' +
      valeur
      }`
    );
  }
  
}
