import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  url: any;

  Spring_serveur: string = 'bsm-service/api/notifications/';
  constructor(private http: HttpClient) {
    //this.url = environment.Url + this.Spring_serveur;
    this.url = environment.UrlGetway + this.Spring_serveur;
  }

  public Get() {
    return this.http.get<any>(`${this.url + 'by-org'}`);
  }
  
  public Getbyid(id: any) {
    return this.http.get<any>(`${this.url + '' + id}`);
  }

  public post(formdata: any) {
    return this.http.post(this.url, formdata, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    });
  }

  public update(id_notfi: any, formdata: any) {
    return this.http.put(this.url + id_notfi, { formdata });
  }
}
