<div class="containerA">
  <app-navbar></app-navbar>

  <div class="containerbg">
    <div class="auth_container">
      <div class="left_form">
        <h3 class="title">{{languef.titreLog}}</h3>
        <form [formGroup]="loginForm" style="width: 100%;">
          <div class="user_name">
            <img src="../../../../assets/icons/username.png" class="icon_input"/>
            <input
              style="color: #198D9C"
              formControlName="userName"
              required
              [placeholder]="languef.username"
              class="input"
              type="text"
            />
          </div>
          <div class="password">
            <img src="../../../../assets/icons/password.png" class="icon_input"/>
            <input
              style="color: #198D9C"
              formControlName="password"
              class="input"
              type="password"
              [placeholder]="languef.password"
              required
            />
          </div>
          <a href="http://141.95.150.139:5001/forgot-pwd" style="margin-left: 10%;" class="forgetPass">
            {{ languef.forgetPass }}
          </a>
          <div class="button">
            <button
              type="submit"
              [disabled]="loginForm.invalid"
              (click)="login(modalmessage)"
              class="login_btn"
            >
              {{ languef.btnlog }}
            </button>
          </div>
        </form>
        
        

      </div>
      <div class="right_img"></div>
    </div>
  </div>
</div>
<ng-template #modalmessage>
  <div class="modal-body rounded-8" [ngStyle]="{ direction: direction }">
    <div style="text-align: center">
      <div>
        <label
          style="
            width: 310px;
            font-size: 20px;
            font-weight: bold;
            padding-top: 40px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.77;
            letter-spacing: normal;
            text-align: center;
          "
        >
          {{ messagelogin }}</label
        >
      </div>
    </div>
  </div>
</ng-template>
