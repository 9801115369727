import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ShipdockingServiceService {
  url: any;

  Spring_serveur: string = 'api/ShipDokingProcess/';

  headers: any;
  constructor(private router: Router, private http: HttpClient) {
    this.url = environment.Url + this.Spring_serveur;
  }

  //port
  public Get() {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.get<any>(this.url + 'Listinfos-per-user', {
      headers: headers1,
    });
  }

  public GetByUser() {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.get<any>(this.url + 'ShipDokingProcess/for-user-affecte', {
      headers: headers1,
    });
  }

  public GetByOrg__port() {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.get<any>(this.url + 'Listinfos-per-org', {
      headers: headers1,
    });
  }

  public GetByOrg__agent() {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.get<any>(this.url + 'Listinfos-per-team', {
      headers: headers1,
    });
  }
  public GetById(id: any) {
    return this.http.get<any>(`${this.url + 'ShipDokingProcess/' + id + '/'}`);
  }

  public post(formData: any, idOrgR: any) {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(this.url + `save/${idOrgR}`, formData, {
      headers: headers1,
    });
  }

  public updatecALL(formData: any) {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(this.url + 'submit', {
      headers: headers1,
    });
  }

  public response(formData: any) {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(this.url + 'response', formData, {
      headers: headers1,
    });
  }

  public delete(id: any) {
    return this.http.delete<any>(
      `${this.url + 'ShipDokingProcess' + '/' + id + '/'}`
    );
  }

  public submit(formData: any, idOrgR: any) {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post<any>(this.url + `submit/${idOrgR}`, formData, {
      headers: headers1,
    });
  }

  public Serach(attribue: any, valeur: any) {
    return this.http.get<any>(
      `${
        this.url +
        'Listinfos-by-attribute?attribute=' +
        attribue +
        '&value=' +
        valeur
      }`
    );
  }

  finishaction(formData: any) {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(this.url + 'user/finishAction', formData, {
      headers: headers1,
    });
  }
}
