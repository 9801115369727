<!-- carousel -->
<div class="carousel">
  <ng-container *ngFor="let slide of slides; let i = index">
    <div class="tittle">
      <h1 *ngIf="i === currentSlide">{{ slide.tittle }}</h1>
      <p class="description" *ngIf="i === currentSlide">
        {{ slide.description }}
      </p>
      <button class="btn" *ngIf="i === currentSlide" (click)="onSeeExampleClick()">
        See an Exemple
      </button>
    </div>
  </ng-container>
  <!-- controls -->
  <button class="control prev" (click)="onPreviousClick()">
    <span class="arrow left"></span>
  </button>
  <button class="control next" (click)="onNextClick()">
    <span class="arrow right"></span>
  </button>
</div>

<!-- Modal Popup -->
<div *ngIf="showModal" class="modal">
    <div class="modal-content">
      <span class="close" (click)="closeModal()">&times;</span>
      <p>Here is an example content for the popup.</p>
      <img [src]="slides[currentSlide].src" />
    </div>
  </div>