<link
  rel="stylesheet"
  href="https://use.fontawesome.com/releases/v5.5.0/css/all.css"
  integrity="sha384-B4dIYHKNBt8Bc12p+WXckhzcICo0wtJAoU8YZTY5qE0Id1GSseTk6S+L3BlXeVIU"
  crossorigin="anonymous"
/>
<script
  src="https://kit.fontawesome.com/8f702b3b95.js"
  crossorigin="anonymous"
></script>
<div class="c1" [ngStyle]="{ direction: direction }">
  <div class="Trac-2081"></div>

  <div>
    <h2 class="titre-page">{{ languee.titre_r }}</h2>
    <div class="about-border"></div>
  </div>
</div>

<div
  class="row"
  [ngStyle]="{ direction: direction }"
  style="background-color: #fff"
>
  <div class="col-lg-12">
    <!--   <ng-template
          label="{{ languee.applicationinfromation }} 1"
          state="form"
        >
          <div style="display: flex">
            <label style="min-width: 100px"
              >{{ languee.applicationinfromation }} 1
            </label>
          </div>
        </ng-template> -->
    <div style="padding-top: 5%">
      <form [formGroup]="Form1">
        <div class="row">
          <div class="col-lg-4">
            <div>
              <label class="titrechamp"> {{ languee.issuingbank }} *</label>
              <div>
                <ng-select
                  [items]="listofBank"
                  required
                  (change)="changeissuingbank($event)"
                  bindLabel="nom"
                  placeholder=""
                  [(ngModel)]="selectedissuingbank"
                >
                </ng-select>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div>
              <label class="titrechamp"> {{ languee.shippingCompany }} *</label>
              <div>
                <ng-select
                  formControlName="shippingCompany"
                  [items]="portlistofcall"
                  (change)="changeportcall($event)"
                  bindLabel="nom"
                  placeholder=""
                  [(ngModel)]="selectedportcall"
                  required
                >
                </ng-select>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <label class="titrechamp">{{ languee.lc }} *</label>

            <div style="padding-top: 1%">
              <input
                matInput
                class="form-control mb-4"
                type="text"
                formControlName="lc"
                required
              />
            </div>
          </div>

          <div class="col-lg-4">
            <label class="titrechamp">{{ languee.currencycode }}* </label>

            <div style>
              <ng-select
                required
                formControlName="currencycode"
                [items]="amount"
                (change)="changeamount($event)"
                bindLabel="name"
                placeholder=""
                [(ngModel)]="selectedamount"
              >
              </ng-select>
            </div>
          </div>
          <div class="col-lg-4">
            <label class="titrechamp">{{ languee.amount }}* </label>

            <div style="padding-top: 1%">
              <input
                required
                matInput
                class="form-control mb-4"
                type="number"
                formControlName="amount"
              />
            </div>
          </div>

          <div class="col-lg-4">
            <label class="titrechamp">{{ languee.billoflading }} </label>

            <div style>
              <ng-select
                [items]="bill"
                formControlName="billoflading"
                (change)="changebillofloading($event)"
                bindLabel="name"
                placeholder=""
                [(ngModel)]="selectedbill"
              >
              </ng-select>
            </div>
          </div>
          <div class="col-lg-6" *ngIf="airway_bill_etat">
            <label class="titrechamp">{{ languee.airwaybillnumber }} </label>

            <div style="padding-top: 1%">
              <input
                matInput
                class="form-control mb-4"
                type="text"
                formControlName="airwaybillnumber"
              />
            </div>
          </div>

          <div class="col-lg-6" *ngIf="billoflading_etat">
            <label class="titrechamp">{{ languee.billoflading_1 }} </label>

            <div style="padding-top: 1%">
              <input
                matInput
                class="form-control mb-4"
                type="text"
                formControlName="billoflading_v"
              />
            </div>
          </div>

          <div class="col-lg-6">
            <label class="titrechamp"
              >{{ languee.applicantaccountnumber }}*
            </label>

            <div style="padding-top: 1%">
              <input
                required
                matInput
                class="form-control mb-4"
                type="number"
                formControlName="applicantaccountnumber"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="col-lg-12">
    <div class="col-lg-12 actions">
      <div class="btnmobile">
        <button
          type="button"
          [disabled]="Form1.invalid"
          class="btn buttonsave"
          (click)="Submit(submit_succes)"
        >
          <label class="labsave"> {{ languee.submit }}</label>
        </button>
      </div>
      <div class="btnmobile">
        <button
          type="button"
          [disabled]="Form1.invalid"
          class="btn buttonsave"
          (click)="Save(save_succes)"
        >
          <label class="labsave"> {{ languee.save }}</label>
        </button>
      </div>

      <div class="btnmobile">
        <button (click)="Go_list()" type="button" class="btn buttonlist">
          <div style="padding-top: 3px">
            <label class="lablist"> {{ languee.list }} </label>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #submit_succes>
  <div class="modal-body rounded-8" [ngStyle]="{ direction: direction }">
    <div style="text-align: center">
      <div>
        <span
          class="material-icons green-color"
          style="
            font-size: 100px;
            color: #41d96f;
            text-align: center;
            font-weight: 800;
          "
          >check_circle
        </span>
      </div>
      <div>
        <label
          style="
            font-family: Montserrat;
            font-size: 26px;
            padding-top: 2%;
            font-weight: bold;
            color: #073866;
          "
        >
          {{ languet.titremodal }}</label
        >
      </div>
      <div>
        <label
          style="
            width: 310px;
            font-size: 16px;
            font-weight: bold;
            padding-top: 40px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.77;
            letter-spacing: normal;
            text-align: center;
          "
        >
          {{ languet.descriptionsubmitsg }}</label
        >
      </div>
    </div>
  </div>
</ng-template>

<ng-template #save_succes>
  <div class="modal-body rounded-8" [ngStyle]="{ direction: direction }">
    <div style="text-align: center">
      <div>
        <span
          class="material-icons green-color"
          style="
            font-size: 100px;
            color: #41d96f;
            text-align: center;
            font-weight: 800;
          "
          >check_circle
        </span>
      </div>
      <div>
        <label
          style="
            font-family: Montserrat;
            font-size: 26px;
            padding-top: 2%;
            font-weight: bold;
            color: #073866;
          "
        >
          {{ languet.titremodal }}</label
        >
      </div>
      <div>
        <label
          style="
            width: 310px;
            font-size: 16px;
            font-weight: bold;
            padding-top: 40px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.77;
            letter-spacing: normal;
            text-align: center;
          "
        >
          {{ languet.descriptionsavesg }}</label
        >
      </div>
      <div class="text-center" style="padding-bottom: 2%; padding-top: 6%">
        <button type="button" class="btn button2" (click)="Go_list()">
          <span class="Save"> {{ languet.list }}</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.5.1/jspdf.umd.min.js"></script>

<notifier-container></notifier-container>
