export const environment = {
  production: true,
  Url: 'http://146.59.241.63:8011/',
  UrlSession: 'http://146.59.241.63:9095/',//135
  UrlCms: 'https://www.tunisia-export.tn/',
  UrlPort: 'http://146.59.241.63:9991/',
  urlGestionUser: 'http://146.59.241.63:5001/',
  urlEservice: 'http://146.59.241.63:4201/',
  urllogin: 'http://146.59.241.63:9095/',//135
  UrlBil: 'http://146.59.241.63:8011/',
  //UrlGetway: 'http://146.59.241.63:8011/',
  UrlGetway: 'http://146.59.241.63:8011/',
  UrlFde: 'http://146.59.241.63:8011/fde/api/',
  UrlFde1: 'http://146.59.241.63:8011/fde/api/',
  //UrlFde : 'http://146.59.241.63:9105/api/',
  mainUrlAttacemnts: 'http://146.59.241.63/attachments/',
  urlAgence: 'http://146.59.241.63:9696/api/gestion_code/agences',
  urlBanque: 'http://146.59.241.63:9696/api/gestion_code/banques',
  urlCaution: 'http://146.59.241.63:9024/api/',
  UrlGestionUser: 'http://146.59.241.63:9095/',//135
  UrlAgrement: 'http://146.59.241.63:2024/api/agrements',
  //UrlAgrementMessageUser: 'http://146.59.241.63:2024/api/',

};
