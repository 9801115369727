import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TestComponent } from './Modules/test/test.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/landing-page',
    pathMatch: 'full',
  },

  {
    path: 'Auth',
    loadChildren: () =>
      import('./Modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'landing-page',
    loadChildren: () =>
      import('./Modules/landing-page/landing-page-routing.module').then((m) => m.LandingPageRoutingModule),
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('./Modules/contact/contact-routing.module').then((m) => m.ContactRoutingModule),
  },

  {
    path: '',
    loadChildren: () =>
      import('./Modules/modules.module').then((m) => m.Modules),
  },

  { path: '**', redirectTo: 'Error/notfound' },
  {
    path: 'test',
    component: TestComponent,
  }



];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
