<link
  rel="stylesheet"
  href="https://use.fontawesome.com/releases/v5.5.0/css/all.css"
  integrity="sha384-B4dIYHKNBt8Bc12p+WXckhzcICo0wtJAoU8YZTY5qE0Id1GSseTk6S+L3BlXeVIU"
  crossorigin="anonymous"
/>

<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/css/all.css"
/>

<div class="c1" [ngStyle]="{ direction: direction }">
  <div class="Trac-2081"></div>

  <div>
    <h2 class="titre-page">{{ languea.titre }}</h2>

    <div class="about-border"></div>
  </div>
</div>

<div class="row" [ngStyle]="{ direction: direction }" style="padding-left: 4%">
  <div class="col-lg-2 divmenu" style="padding-top: 3%">
    <div style="text-align: center; padding-top: 2%">
      <div class="bloc_menu">
        <div>
          <mat-icon style="color: rgba(71, 178, 228, 1); font-size: 35px"
            >group_work</mat-icon
          >
        </div>
        <div
          [ngStyle]="{
            'background-color': BlocB1 ? '#d4f1ff' : '#ffffff'
          }"
          (click)="changebloc(1, languea.shipdockinginformation)"
          class="titre_bloc"
        >
          <label style="cursor: pointer" class="vessel_call_information">{{
            languea.shipdockinginformation
          }}</label>

          <div>
            <mat-icon *ngIf="Formship.valid" class="icon-menu"
              >check_circle</mat-icon
            >
            <mat-icon
              *ngIf="Formship.invalid && BlocB1 == false"
              class="icon-menu1"
              >new_releases</mat-icon
            >

            <mat-icon *ngIf="Formship.invalid && BlocB1" class="icon-menu1"
              >edit</mat-icon
            >
          </div>
        </div>
      </div>
      <div class="line">
        <div class="vl"></div>
      </div>
      <div class="bloc_menu">
        <div>
          <mat-icon style="color: rgba(71, 178, 228, 1); font-size: 35px"
            >directions_boat</mat-icon
          >
        </div>
        <div
          (click)="changebloc(2, languea.Vesselinformation)"
          [ngStyle]="{
            'background-color': BlocB2 ? '#d4f1ff' : '#ffffff'
          }"
          class="titre_bloc"
        >
          <label style="cursor: pointer" class="vessel_call_information"
            >{{ languea.Vesselinformation }}
          </label>
          <div>
            <mat-icon class="icon-menu">check_circle</mat-icon>
          </div>
        </div>
      </div>
      <div class="line">
        <div class="vl"></div>
      </div>
      <div class="bloc_menu">
        <div>
          <mat-icon style="color: rgba(71, 178, 228, 1); font-size: 35px"
            >directions_boat</mat-icon
          >
        </div>
        <div
          (click)="changebloc(3, languea.Vesselcallinformation)"
          [ngStyle]="{
            'background-color': BlocB3 ? '#d4f1ff' : '#ffffff'
          }"
          class="titre_bloc"
        >
          <label style="cursor: pointer" class="vessel_call_information"
            >{{ languea.Vesselcallinformation }}
          </label>
          <div>
            <mat-icon class="icon-menu">check_circle</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-8 blocform">
    <div
      [ngStyle]="{
        display: BlocB1 ? 'block' : 'none'
      }"
    >
      <div class="header-border" style="min-height: 600px">
        <h2 class="heading">
          <div style="display: flex">
            <div class="padding_class">
              <h1 style="padding-right: 60%">
                <div
                  class="div1"
                  [ngStyle]="{ 'background-color': btn_backround1 }"
                >
                  <span class="header_titre" [ngStyle]="{ color: color1 }">{{
                    titre_bloc
                  }}</span>
                </div>
              </h1>
            </div>
          </div>
        </h2>
        <div class="class_form">
          <form
            [formGroup]="Formship"
            autocomplete="off"
            (ngSubmit)="Next(languea.Vesselinformation)"
            style="padding: 1%; min-height: 440px"
          >
            <div class="row" style="padding-top: 5%">
              <div class="col-lg-6">
                <mat-form-field class="full-width">
                  <input
                    readonly
                    matInput
                    type="text"
                    placeholder="{{ languea.numescale }}"
                    formControlName="vesselcallnumber"
                  />
                </mat-form-field>
              </div>

              <div class="col-lg-6">
                <mat-form-field class="example-full-width">
                  <input
                    type="text"
                    required
                    placeholder="{{ languea.birthstatus }}"
                    matInput
                    [formControl]="birthstatus"
                    [matAutocomplete]="auto"
                  />
                  <mat-autocomplete
                    #auto="matAutocomplete"
                    [displayWith]="displayFn"
                  >
                    <mat-option *ngFor="let option of options" [value]="option">
                      {{ option.name }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>

              <div class="col-lg-6">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    required
                    type="number"
                    placeholder="{{ languea.gangsnumber }}"
                    formControlName="gangsnumber"
                  />
                </mat-form-field>
              </div>

              <div class="col-lg-6">
                <ng-select
                  formControlName="dischargingport"
                  [items]="portlistofcall"
                  (change)="changedischargingport($event)"
                  bindLabel="name"
                  placeholder="{{ languea.portofdeclaration }}"
                  [(ngModel)]="selecteddischargingport"
                >
                </ng-select>
              </div>
            </div>
            <div>
              <div
                class="div_next"
                [ngStyle]="{
                  float: langue === 'AR' ? 'left' : 'right'
                }"
              >
                <button type="submit" class="btn button1">
                  <span class="next"> {{ languea.next }} </span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      [ngStyle]="{
        display: BlocB2 ? 'block' : 'none'
      }"
    >
      <div class="header-border" style="min-height: 600px">
        <h2 class="heading">
          <div style="display: flex">
            <div class="padding_class">
              <h1 style="padding-right: 60%">
                <div
                  class="div1"
                  [ngStyle]="{ 'background-color': btn_backround1 }"
                >
                  <span class="header_titre" [ngStyle]="{ color: color1 }">{{
                    titre_bloc
                  }}</span>
                </div>
              </h1>
            </div>
          </div>
        </h2>
        <div class="class_form">
          <div class="row" style="padding: 1%; min-height: 440px">
            <div class="col-lg-6 labeldetail">
              <div>
                <label class="lab1"> {{ languea.vesselname }}</label>
              </div>
              <div>
                <label class="lab2">{{ vessel.vessel }}</label>
              </div>
            </div>
            <div class="col-lg-6 labeldetail">
              <div>
                <label class="lab1"> {{ languea.country }} </label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label
                  >{{ vessel.vesselcountryname }}</label
                >
              </div>
            </div>

            <div class="col-lg-6 labeldetail">
              <div>
                <label class="lab1"> {{ languea.vesselbuildyear }} </label>
              </div>
              <div>
                <label class="lab2">{{ vessel.vesselbuildyear }}</label>
              </div>
            </div>

            <div class="col-lg-6 labeldetail">
              <div>
                <label class="lab1"> {{ languea.vesselregport }} </label>
              </div>
              <div>
                <label class="lab2">
                  <label style="color: #e8effc">.</label
                  >{{ vessel.vesselregport }}</label
                >
              </div>
            </div>

            <div class="col-lg-6 labeldetail">
              <div>
                <label class="lab1"> {{ languea.vesseldraft }} </label>
              </div>
              <div>
                <label class="lab2">
                  <label style="color: #e8effc">.</label
                  >{{ callofinfromation.callsign }}</label
                >
              </div>
            </div>

            <div class="col-lg-6 labeldetail">
              <div>
                <label class="lab1"> {{ languea.vessellenght }} </label>
              </div>
              <div>
                <label class="lab2">{{ vessel.vessellength }}</label>
              </div>
            </div>

            <div class="col-lg-6 labeldetail">
              <div>
                <label class="lab1"> {{ languesa.vesselgrossweight }}</label>
              </div>
              <div>
                <label class="lab2">{{ vessel.vesselgrossweight }}</label>
              </div>
            </div>

            <div class="col-lg-6 labeldetail">
              <div>
                <label class="lab1"> {{ languea.vesselnetweight }} </label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label
                  >{{ vessel.vesselnetweight }}</label
                >
              </div>
            </div>
          </div>
          <div>
            <div
              class="div_back"
              [ngStyle]="{
                float: langue === 'AR' ? 'right' : 'left'
              }"
            >
              <button
                type="button"
                class="btn buttonp"
                (click)="Preview(languea.shipdockinginformation)"
              >
                <span class="next"> {{ languea.previous }} </span>
              </button>
            </div>
            <div
              class="div_next"
              [ngStyle]="{
                float: langue === 'AR' ? 'left' : 'right'
              }"
            >
              <button
                type="Button"
                class="btn button1"
                (click)="Next(languea.Vesselcallinformation)"
              >
                <span class="next"> {{ languea.next }} </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      [ngStyle]="{
        display: BlocB3 ? 'block' : 'none'
      }"
    >
      <div class="header-border" style="min-height: 600px">
        <h2 class="heading">
          <div style="display: flex">
            <div class="padding_class">
              <h1 style="padding-right: 60%">
                <div
                  class="div1"
                  [ngStyle]="{ 'background-color': btn_backround1 }"
                >
                  <span class="header_titre" [ngStyle]="{ color: color1 }">{{
                    titre_bloc
                  }}</span>
                </div>
              </h1>
            </div>
          </div>
        </h2>
        <div class="class_form">
          <div class="row" style="padding: 1%; min-height: 440px">
            <div class="col-lg-6 labeldetail">
              <div>
                <label class="lab1"> {{ languea.voyageno }} </label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label
                  >{{ callofinfromation.voyageno }}</label
                >
              </div>
            </div>
            <div class="col-lg-6 labeldetail">
              <div>
                <label class="lab1"> {{ languea.callsign }} </label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label
                  >{{ callofinfromation.callsign }}</label
                >
              </div>
            </div>

            <div class="col-lg-6 labeldetail">
              <div>
                <label class="lab1"> {{ languea.eta }} </label>
              </div>
              <div>
                <label class="lab2">{{ callofinfromation.eta }}</label>
              </div>
            </div>

            <div class="col-lg-6 labeldetail">
              <div>
                <label class="lab1"> {{ languea.etd }} </label>
              </div>
              <div>
                <label class="lab2">
                  <label style="color: #e8effc">.</label
                  >{{ callofinfromation.etd }}</label
                >
              </div>
            </div>

            <div class="col-lg-6 labeldetail">
              <div>
                <label class="lab1"> {{ languea.portofcall }} </label>
              </div>
              <div>
                <label class="lab2">
                  <label style="color: #e8effc">.</label
                  >{{ callofinfromation.portofcallname }}</label
                >
              </div>
            </div>

            <div class="col-lg-6 labeldetail">
              <div>
                <label class="lab1"> {{ languea.nextport }} </label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label
                  >{{ callofinfromation.nextportname }}</label
                >
              </div>
            </div>

            <div class="col-lg-6 labeldetail">
              <div>
                <label class="lab1"> {{ languea.previousport }} </label>
              </div>
              <div>
                <label class="lab2"
                  ><label style="color: #e8effc">.</label
                  >{{ callofinfromation.previousportname }}</label
                >
              </div>
            </div>

            <div class="col-lg-6 labeldetail">
              <div>
                <label class="lab1"> {{ languea.shipmaster }} </label>
              </div>
              <div>
                <label class="lab2">
                  <label style="color: #e8effc">.</label
                  >{{ callofinfromation.shipmaster }}</label
                >
              </div>
            </div>
          </div>
          <div>
            <div
              class="div_back"
              [ngStyle]="{
                float: langue === 'AR' ? 'right' : 'left'
              }"
            >
              <button
                type="button"
                class="btn buttonp"
                (click)="Preview(languea.Vesselinformation)"
              >
                <span class="next"> {{ languea.previous }} </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-1" style="padding-top: 3%">
    <div class="blocaction">
      <div *ngIf="saveandfinish_etat" style="padding: 3%">
        <div class="row">
          <div (click)="finishaction(content3)" class="div_action">
            <mat-icon
              class="icon_action"
              [ngStyle]="{
                color: Formship.valid ? '#1671b3' : '#b1d9f5'
              }"
              style="direction: revert"
              >save</mat-icon
            >
          </div>
        </div>
        <div>
          <label class="labaction" style="width: 130px">
            {{ languet.finishaction }}
          </label>
        </div>
      </div>

      <div style="padding-top: 3%">
        <div class="row">
          <div class="div_action" (click)="Save(content3)">
            <mat-icon
              style="direction: revert"
              class="icon_action"
              [ngStyle]="{
                color: Formship.valid ? '#1671b3' : '#b1d9f5'
              }"
              >save_alt</mat-icon
            >
          </div>
        </div>
        <div>
          <label class="labaction"> {{ languea.save }} </label>
        </div>
      </div>
      <div *ngIf="submit_etat" style="padding: 3%">
        <div class="row">
          <div (click)="submit12(content4)" class="div_action">
            <mat-icon
              style="direction: revert"
              class="icon_action"
              [ngStyle]="{ color: color_submit }"
              >save_alt</mat-icon
            >
          </div>
        </div>
        <div>
          <label class="labaction"> {{ languea.submit }} </label>
        </div>
      </div>
      <div style="padding-top: 3%">
        <div class="row" style="padding-top: 5%">
          <div (click)="Go_list()" class="div_action">
            <mat-icon class="icon_action" style="direction: revert"
              >format_list_bulleted</mat-icon
            >
          </div>
        </div>
        <div>
          <label class="labaction"> {{ languea.list }} </label>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content3>
  <div class="modal-body rounded-8">
    <div style="text-align: center">
      <div>
        <span
          class="material-icons green-color"
          style="
            font-size: 100px;
            color: #41d96f;
            text-align: center;
            font-weight: 800;
          "
          >check_circle
        </span>
      </div>
      <div>
        <label
          style="
            font-family: Montserrat;
            font-size: 26px;
            padding-top: 2%;
            font-weight: bold;
            color: #073866;
          "
        >
          {{ languet.titremodal }}</label
        >
      </div>
      <div>
        <label
          style="
            width: 310px;
            font-size: 16px;
            font-weight: bold;
            padding-top: 40px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.77;
            letter-spacing: normal;
            text-align: center;
          "
        >
          {{ languet.descriptionsave }}</label
        >
      </div>
      <div class="text-center" style="padding-bottom: 2%; padding-top: 6%">
        <button type="button" class="btn button2" (click)="Go_list()">
          <span class="Save"> {{ languet.list }}</span>
        </button>
      </div>
      <!--   <div class="text-center" style="padding-bottom: 2%; padding-top: 3%">
        <button type="button" class="btn button4" (click)="generate_pdf()">
    
          <span class="print"> Print</span>
        </button>
      </div> -->
    </div>
  </div>
</ng-template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.5.1/jspdf.umd.min.js"></script>

<ng-template #content4>
  <div class="modal-body rounded-8">
    <div style="text-align: center">
      <div>
        <span
          class="material-icons green-color"
          style="
            font-size: 100px;
            color: #41d96f;
            text-align: center;
            font-weight: 800;
          "
          >check_circle
        </span>
      </div>
      <div>
        <label
          style="
            font-family: Montserrat;
            font-size: 26px;
            padding-top: 2%;
            font-weight: bold;
            color: #073866;
          "
        >
          {{ languet.titremodal }}</label
        >
      </div>
      <div>
        <label
          style="
            width: 310px;
            font-size: 16px;
            font-weight: bold;
            padding-top: 40px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.77;
            letter-spacing: normal;
            text-align: center;
          "
        >
          {{ languet.descriptionsubmit }}</label
        >
      </div>
    </div>
  </div>
</ng-template>
