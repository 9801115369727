import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import jsPDF from 'jspdf';
import { MessageUserService } from 'src/Services/MessagesUser/message-user.service';
import { CustomsService } from '../Services/Customs/customs.service';
import Langue from './../../../../../assets/langue.json';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { AffectationService } from 'src/Services/Affectation/affectation.service';
import { UserService } from 'src/Services/User/user.service';
import { OrganisationService } from 'src/Services/Organisation/organisation.service';
import { NotifierService } from 'angular-notifier';
import { NotificationService } from 'src/Services/Notification/notification.service';
@Component({
  selector: 'app-liste',
  templateUrl: './liste.component.html',
  styleUrls: ['./liste.component.css'],
})
export class ListeComponent implements OnInit {
  index: number = 0;
  displayedColumns: string[] = [
    'select',
    'id',
    'date',
    'banque',
    'codebank',
    'importateur',
    'exportateur',
    'pays',
    'assign',
    'etat',
    'action',
  ];

  displayedColumnsArticles: string[] = [
    'no',
    'ndp',
    'des',
    'pfn',
    'codeqcs',
    'brute',
    'net',
    'pays',
  ];

  itemlist = [
    { name: 'Date Of Declaration', code: 'dateOfDeclaration' },
    { name: 'Date Assement', code: 'assessmentDate' },
    { name: 'Date Signature', code: 'signatureDate' },
    { name: 'Guarantee Date', code: 'guaranteeDate' },
  ];

  displayedColumnsActions: string[] = [
    'id',
    'status',
    'startDate',
    'affectedto',
    'action',
  ];
  onchangedatestart(event: any) {
    this.startdate = this.datePipe.transform(event, 'yyyy-MM-dd');
  }
  typerecherche: any;
  date_disabled: boolean = true;
  changedate(event: any) {
    if (event != undefined) {
      this.date_disabled = false;
      this.typerecherche = event.code;
    } else {
      this.id_search = 0;
      this.startDate = '';
      this.endDate = '';
      this.date_disabled = true;
      this.GetCustomsDeclaration();
    }
  }
  startdate: any;
  enddate: any;
  selecteddate: any;
  startDate: any;
  endDate: any;
  selectedstatus: any;
  statuslist = ['Save', 'For confirmation', 'Confirm', 'Error', 'Payed'];
  itemslist: any[] = [
    { name: 'Year Of Declaration', code: 'yearOfDeclaration', id: 1 },
    { name: 'bl No', code: 'blNo', id: 2 },
    { name: 'Manifest', code: 'manifest', id: 1 },
    { name: 'Number Of Packages', code: 'numberOfPackages', id: 1 },
    { name: 'Load List', code: 'loadList', id: 3 },
    { name: 'Type Of Declaration', code: 'typeOfDeclaration', id: 4 },
    { name: 'Office Of Declaration', code: 'officeOfDeclaration', id: 1 },
    { name: 'Exporter Company Name', code: 'exporterCompanyName', id: 1 },
    { name: 'Importer Company Name', code: 'importerCompanyName', id: 1 },
    { name: 'Tax payer Name', code: 'taxpayerName', id: 1 },
    { name: 'First Destination', code: 'firstDestination', id: 1 },
    { name: 'Trading Country', code: 'tradingCountry', id: 1 },
    { name: 'Value Details', code: 'valueDetails', id: 1 },
    { name: 'Export Country Nm', code: 'exportCountryNm', id: 1 },
    { name: 'Destination Country Nm', code: 'destinationCountryNm', id: 1 },
    { name: 'Transport Means Nm', code: 'transportMeansNm', id: 1 },
    { name: 'Tax', code: 'Border Transport Nm', id: 1 },
    { name: 'Declarant Name', code: 'declarantName', id: 1 },
    {
      name: 'Mode Transport Crossing The Border',
      code: 'modeTransportCrossingTheBorder',
      id: 1,
    },
    { name: 'Container', code: 'container', id: 1 },
    { name: 'Office Of Entry Name', code: 'officeOfEntryName', id: 1 },
    { name: 'Nature Of Transaction', code: 'natureOfTransactionCode1', id: 1 },
    { name: 'Bank Name', code: 'bankName', id: 1 },
    { name: 'Bank Branch', code: 'bankBranch', id: 1 },
    { name: 'Payment Description', code: 'payment_escription', id: 1 },
    { name: 'Warehouse Delay', code: 'warehouseDelay', id: 1 },
    { name: 'Signature Place', code: 'signaturePlace', id: 1 },
    { name: 'Forwarder Name', code: 'forwarderName', id: 1 },
    { name: 'Warehouse Delay', code: 'warehouseDelay', id: 1 },
    { name: 'Total Costs', code: 'totalCosts', id: 1 },
    { name: 'Cif Value', code: 'cifValue', id: 1 },
    { name: 'Guarantee Amount', code: 'guaranteeAmount', id: 1 },
    { name: 'Total Fees', code: 'totalFees', id: 1 },
  ];
  selectedcondition: any;
  valeurcondition: any;
  changeconditionitem(event: any) {
    if (event == undefined) {
      this.id_search = 0;
      this.valeurcondition = '';
      this.GetCustomsDeclaration();
    } else {
      this.attr = event.code;
    }
  }
  etat: any;
  changestatus(event: any) {
    if (event != undefined) {
      this.etat = event;
      this.search_status();
    } else {
      this.id_search = 0;
      this.GetCustomsDeclaration();
    }
  }
  onchangedateend(event: any) {
    this.enddate = this.datePipe.transform(event, 'yyyy-MM-dd');

    this.search_date();
  }

  search_status() {
    this.id_search = 3;
    this.ListCustoms_f = this.ListCustoms_1.filter((e) => e.etat == this.etat);
    setTimeout(() => {
      this.dataSource = new MatTableDataSource(this.ListCustoms_f);
      this.dataSource.paginator = this.paginator;
    }, 100);
  }

  search_date() {
    this.id_search = 2;

    if (this.typerecherche == 'dateOfDeclaration') {
      this.ListCustoms_f = this.ListCustoms_1.filter(
        (e) =>
          e.dateOfDeclaration > this.startdate &&
          e.dateOfDeclaration < this.enddate
      );

      setTimeout(() => {
        this.dataSource = new MatTableDataSource(this.ListCustoms_f);
        this.dataSource.paginator = this.paginator;
      }, 100);
    } else if (this.typerecherche == 'assessmentDate') {
      this.ListCustoms_f = this.ListCustoms_1.filter(
        (e) =>
          e.assessmentDate > this.startdate && e.assessmentDate < this.enddate
      );

      setTimeout(() => {
        this.dataSource = new MatTableDataSource(this.ListCustoms_f);
        this.dataSource.paginator = this.paginator;
      }, 100);
    } else if (this.typerecherche == 'signatureDate') {
      this.ListCustoms_f = this.ListCustoms_1.filter(
        (e) =>
          e.signatureDate > this.startdate && e.signatureDate < this.enddate
      );

      setTimeout(() => {
        this.dataSource = new MatTableDataSource(this.ListCustoms_f);
        this.dataSource.paginator = this.paginator;
      }, 100);
    } else {
      this.GetCustomsDeclaration();
    }
  }
  dataSource: MatTableDataSource<any>;

  displayedColumnsP: string[] = ['file', 'actions'];
  dataSourcep: MatTableDataSource<any>;
  ListCustoms: any[] = [];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;

  langue: any;
  direction: any;
  languef: any;
  langue_list: any[] = Langue;
  languea: any;
  languem: any;
  languet: any;

  constructor(
    private userService: UserService,
    private affecationService: AffectationService,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private router: Router,
    private messageuser: MessageUserService,
    public sanitizer: DomSanitizer,
    private customsService: CustomsService,
    private titleService: Title,
    private organisationService: OrganisationService,
    private notifier: NotifierService,
    private notificationApi: NotificationService
  ) {
    if (localStorage.getItem('langue') != null) {
      this.langue = localStorage.getItem('langue') + '';
    } else {
      this.langue = 'AR';
    }

    if (this.langue == 'AR') {
      this.direction = 'rtl';
      this.languef = this.langue_list[0]['AR'].tablelist;
      this.languea = this.langue_list[0]['AR'].CD;
      this.languem = this.langue_list[0]['AR'].messageuser;
      this.languet = this.langue_list[0]['AR'].modal_text;
    }

    if (this.langue == 'EN') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['EN'].tablelist;
      this.languea = this.langue_list[0]['EN'].CD;
      this.languem = this.langue_list[0]['EN'].messageuser;
      this.languet = this.langue_list[0]['EN'].modal_text;
    }

    if (this.langue == 'FR') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['FR'].tablelist;
      this.languea = this.langue_list[0]['FR'].CD;
      this.languem = this.langue_list[0]['FR'].messageuser;
      this.languet = this.langue_list[0]['FR'].modal_text;
    }

    this.titleService.setTitle(this.languet.entete);
    this.dataSource = new MatTableDataSource(this.ListCustoms);
    this.dataSourceArticles = new MatTableDataSource(this.Article_DATA);
    this.dataSourcep = new MatTableDataSource(this.Files_list);
    this.dataSource = new MatTableDataSource(this.Messages_list);

    this.RoleTeammanager = this.userService.ValidRole('Team manager');
    this.RoleTrading = this.userService.ValidRole('Trading Company');

    this.actionlist = [
      { id: '1', name: this.languef.save },
      { id: '2', name: this.languef.submit },
    ];
  }
  actionlist: any[];
  Files_list: any[] = [];
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  list_delete: any[] = [];

  Arr_remove() {
    this.modalService.dismissAll();
    this.list_delete = [];
    this.selection.selected.forEach((s) => this.list_delete.push(s));
    for (let customs of this.list_delete) {
      let customs_f = this.ListCustoms_1.find((x) => x.id == customs.id);
      if (customs_f.etat == 'Save') {
        this.customsService.delete(customs_f.id).subscribe((res: any) => {
          this.GetCustomsDeclaration();
        });
      }
    }
    setTimeout(() => {
      this.ClearAll();
    }, 300);
  }
  document: any;
  Arr_view(content: any, id: any) {
    this.customsService.GetByid(id).subscribe((res: any) => {
      this.document = res;
      this.Article_DATA = this.document.items;
      this.dataSourceArticles = new MatTableDataSource(this.Article_DATA);
      setTimeout(() => {
        this.modalService.open(content, {
          size: 'xl',
          windowClass: 'dark-modal',
        });
      }, 300);
    });
  }

  getPieces(id: any) { }

  open(content: any) {
    this.modalService.open(content);
  }
  Arr_update() { }
  ListCustoms_1: any[] = [];
  ListCustoms_2: any[] = [];
  ListCustoms_f: any[] = [];
  GetCustomsDeclaration() {
    if (this.RoleTeammanager) {
      console.log("role team ")
      if (this.role_organisation == 'Trading_Company') {
        console.log("role tc ")
        this.customsService.GetByTeam().subscribe((res: any) => {
          this.remplir_list(res);
        });
      } else {
        console.log("role else ")
        this.customsService.GetByOrg().subscribe((res: any) => {
          console.log(" res customs " + res)
          this.remplir_list(res);
        });
      }
    } else {
      console.log("role else 1 ")
      this.customsService.GetByUser().subscribe((res: any) => {
        this.remplir_list(res);
      });
    }
  }

  remplir_list(list: any) {
    this.ListCustoms_2 = list;

    let resultA = this.ListCustoms_2.filter(
      (elm) =>
        !this.ListCustoms_1.map((elm) => JSON.stringify(elm)).includes(
          JSON.stringify(elm)
        )
    );

    let resultB = this.ListCustoms_1.filter(
      (elm) =>
        !this.ListCustoms_2.map((elm) => JSON.stringify(elm)).includes(
          JSON.stringify(elm)
        )
    );

    if (resultA.length > 0 || resultB.length > 0) {
      this.ListCustoms_1 = this.ListCustoms_2;
      this.ListCustoms_f = this.ListCustoms_2;
      this.dataSource = new MatTableDataSource(this.ListCustoms_1);
      this.dataSource.paginator = this.paginator;
      this.ListCustoms_2 = [];
    }
  }

  Article_DATA: any[] = [];
  dataSourceArticles: MatTableDataSource<any>;
  ngOnInit(): void {
    this.dataSourceArticles = new MatTableDataSource(this.Article_DATA);
    this.GetIdUser();
    //this.Websocket_cd();

    if (this.profil == 'DOUANES') {
      this.premission = 'CD_A2.1_LE';
      this.actionlist = [{ id: '2', name: this.languef.submit }];
    } else {
      this.premission = 'CD_A1.1_LE';
      this.actionlist = [
        // { id: '1', name: this.languef.save },
        { id: '2', name: this.languef.submit },
      ];
    }
  }

  selection = new SelectionModel<any>(true, []);
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  ClearAll() {
    this.selection.clear();
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1
      }`;
  }

  list_print: any[] = [];
  printdoc() {
    this.list_print = [];
    this.selection.selected.forEach((s) => this.list_print.push(s));

    for (let tce of this.list_print) {
      let titre = this.ListCustoms_1.find((x) => x.id == tce.id);
      this.generate_pdf(titre);
    }

    setTimeout(() => {
      this.ClearAll();
    }, 300);
  }

  dimision() {
    this.modalService.dismissAll();
  }

  reason: any;
  open_reason(content: any, reason: any) {
    this.reason = reason;
    this.open(content);
  }

  tcesend: any;
  tceidsend: any;
  opensend(content: any, tce: any) {
    this.tcesend = tce;
    this.tceidsend = tce.id;

    this.open(content);
  }

  document_src: any;
  openpdf(pdf: any, content: any) {
    this.document_src = pdf;
    setTimeout(() => {
      this.modalService.open(content, {
        size: 'xl',
        windowClass: 'dark-modal',
      });
    }, 100);
  }
  dataSourceMessages: MatTableDataSource<any>;

  Messages_list: any[] = [];
  Arr_messages(content: any, id: any) {
    this.messageuser.GetCustom(id, this.customsService.Spring_serveur).subscribe((res: any) => {
      this.Messages_list = res;

      setTimeout(() => {
        this.dataSourceMessages = new MatTableDataSource(this.Messages_list);
        this.dataSourceMessages.paginator = this.paginator;
        this.modalService.open(content, {
          size: 'xl',
          windowClass: 'dark-modal',
        });
      }, 100);
    });
  }

  displayedColumnsMessages: string[] = [
    'id',
    'status',
    'startDate',
    'endDate',
    'typeFlux',
    'etatFlux',
    'idDossier',
    'typeMessage',
    'typeScenario',
  ];
  y: any = 0;

  generate_pdf(cutoms: any) {
    this.y = 20;
    var doc = new jsPDF();
    var img = new Image();
    // img.src = './../../../../assets/logo_dark.png';
    doc.setFont('italic');
    // doc.addImage(img, 'png', 150, 5, 50, 20);
    doc.setFontSize(20);

    doc.setDrawColor('#058A0D');
    doc.setLineWidth(0.5);

    doc.line(5, 18, 110, 18);
    doc.line(5, 18, 5, 150);
    doc.line(15, 18, 15, 150);
    doc.line(25, 18, 25, 150);
    doc.line(110, 18, 110, 150);
    doc.line(210, 18, 210, 150);
    doc.line(5, 25, 25, 25);
    doc.line(110, 10, 145, 10);
    doc.line(205, 18, 205, 150);
    doc.line(110, 10, 110, 18);

    doc.line(127, 18, 127, 50);
    doc.line(110, 22, 145, 22);
    doc.line(150, 35, 150, 50);

    doc.line(145, 10, 145, 35);
    doc.line(110, 35, 205, 35);

    doc.line(25, 50, 205, 50);
    doc.line(25, 70, 205, 70);
    doc.line(25, 90, 205, 90);
    doc.line(25, 105, 205, 105);
    doc.line(25, 120, 205, 120);
    doc.line(25, 135, 110, 135);
    doc.line(5, 150, 205, 150);

    doc.line(7, 153, 205, 153);
    doc.line(7, 180, 205, 180);
    doc.line(7, 205, 205, 205);
    doc.line(7, 240, 205, 240);
    doc.line(7, 255, 205, 255);
    doc.line(7, 270, 205, 270);

    doc.line(110, 61, 205, 61);

    doc.line(130, 61, 130, 70);
    doc.line(150, 61, 150, 70);
    doc.line(180, 61, 180, 70);

    doc.line(205, 153, 205, 290);
    doc.line(7, 290, 205, 290);
    doc.line(110, 80, 205, 80);

    doc.line(157, 70, 157, 90);
    doc.line(170, 75, 170, 80);
    doc.line(182, 70, 182, 80);
    doc.line(193, 75, 193, 80);

    doc.line(150, 105, 150, 120);
    doc.line(175, 105, 175, 120);

    doc.line(50, 120, 50, 135);
    doc.line(75, 120, 75, 135);

    doc.line(75, 135, 75, 150);

    doc.line(110, 153, 110, 160);
    doc.line(120, 160, 205, 160);

    doc.line(110, 160, 205, 160);

    doc.line(115, 160, 127, 160);
    doc.line(127, 153, 127, 180);
    doc.line(127, 170, 205, 170);
    doc.line(127, 190, 205, 190);

    doc.line(127, 180, 127, 205);

    doc.line(160, 160, 160, 205);
    doc.line(183, 160, 183, 205);

    doc.line(40, 205, 40, 232);
    doc.line(70, 205, 70, 232);
    doc.line(100, 205, 100, 232);
    doc.line(120, 205, 120, 232);

    doc.line(25, 212, 127, 212);
    doc.line(25, 232, 127, 232);

    doc.line(127, 205, 127, 240);

    doc.line(127, 215, 205, 215);

    doc.line(165, 205, 165, 215);

    doc.setLineWidth(0.7);
    doc.line(25, 153, 25, 255);

    doc.setLineDashPattern([1.5], 5);

    doc.line(140, 240, 140, 290);

    doc.setFontSize(9);
    doc.setTextColor('#058A0D');
    doc.text('2 Exporter', 28, 25);
    doc.text('1 DECLARATION', 112, 14);
    doc.text('NO. ', 70, 25);
    doc.text('A OFFiCE OF DESTINATION ', 150, 12);

    doc.text('Customs', 148, 27);
    doc.text('Manifest', 172, 27);

    doc.text('3 Forms', 112, 25);
    doc.text('4 Load List', 128, 25);

    doc.text('5 Items', 112, 38);
    doc.text('6 Nbr packages', 128, 38);
    doc.text('7 Reference', 152, 38);

    doc.text('Number', 175, 38);

    doc.text('8 Conseigne', 28, 55);
    doc.text('No.', 70, 55);

    doc.text('9 Financial', 112, 55);
    doc.text('No.', 166, 55);

    doc.text('Cty.s last', 112, 64);
    doc.text('11 Trading', 132, 64);

    doc.text('12 Value Details', 152, 64);
    doc.text('13 C.A.P', 182, 64);

    doc.text('14 Declarant', 28, 75);
    doc.text('No.', 70, 75);

    doc.text('15 Country of export', 112, 73);
    doc.text('C.E', 159, 73);
    doc.text('Code', 172, 73);
    doc.text('17 C.D', 184, 73);
    doc.text('Code', 195, 73);

    doc.text('16 Country of origin', 112, 83);
    doc.text('17 Country of destination', 159, 83);

    doc.text('18 identity and nationalaty of active means', 28, 93);

    doc.text('of transport at departure', 30, 96);

    doc.text('18 Ctr', 95, 93);

    doc.text('20 Delivery terms', 112, 93);
    doc.text('21 identity and nationalaty of active means of', 28, 108);
    doc.text(' transport crosing the border', 30, 111);

    doc.text('22 Currency & total amount', 112, 108);
    doc.text('23 Exch. rate', 152, 108);
    doc.text('24 Nature of transac', 177, 108);

    doc.text('25 Mode transport', 26, 123);

    doc.text('26 Inland mode', 53, 123);
    doc.text('27 Place of discharge', 78, 123);
    doc.text('28 Financial and banking data', 112, 123);
    doc.text('Bank Code', 172, 123);
    doc.text('Terms of payment', 112, 140);

    doc.text('29 Office of entry', 28, 139);
    doc.text('30 Location of goods', 78, 139);

    doc.text('31 Packages', 7, 157);
    doc.text('and', 8, 160);
    doc.text('description', 8, 163);
    doc.text('of goods', 8, 166);

    doc.text('Marks and numbers - containers No(s) - Number and kind', 27, 157);
    doc.text('Marks & no', 28, 162);
    doc.text('of packages', 28, 167);
    doc.text('Nr & kind', 28, 172);
    doc.setFontSize(7);
    doc.text('32 Item', 111, 156);
    doc.text('33 Commodity code', 128, 156);

    doc.text('34 cty', 128, 163);
    doc.text('35 Gross Mass(KG)', 162, 163);
    doc.text('36 Prefer', 184, 163);

    doc.text('37 Procedure', 128, 173);
    doc.text('38 Net Mass(KG)', 162, 173);
    doc.text('39 Quata', 184, 173);

    doc.text('40 Summmary Declaration', 128, 183);
    doc.text('41 Supp units', 162, 183);
    doc.text('42 Item Prise', 184, 183);

    doc.text('45 Adjustment', 184, 193);
    doc.text('46 Statistical value', 162, 193);
    doc.text('49 Identification of warehouse', 128, 193);
    doc.setFontSize(9);
    doc.text('31 Add info', 7, 183);
    doc.text('Documents', 8, 186);
    doc.text('produced', 8, 189);
    doc.text('certificats', 8, 192);
    doc.text('and', 8, 195);
    doc.text('authorization', 8, 198);

    doc.text('Licence No', 28, 183);
    doc.text('A.D', 28, 195);
    doc.text('D.Val', 60, 183);
    doc.text('D.Qty', 91, 183);

    doc.text('47 Calculation', 7, 210);
    doc.text('of taxes', 8, 214);

    doc.text('Type', 26, 210);
    doc.text('Tax base', 42, 210);
    doc.text('Rate', 72, 210);
    doc.text('Amount', 102, 210);
    doc.text('MP', 121, 210);

    doc.text('Total', 66, 237);

    doc.text('48 Deferred payement', 129, 208);
    doc.text('49 Identification of warehouse', 167, 208);

    doc.text('Mode of payement', 129, 218);
    doc.text('Assement Number', 129, 223);

    doc.text('Date', 172, 223);
    // doc.text('Receipt Number', 129, 223);
    doc.text('Guarantee', 129, 228);

    doc.text('Date', 172, 228);
    doc.text('Total fees', 129, 233);
    doc.text('National M.U', 172, 233);
    doc.text('Total decelaraton', 129, 238);

    doc.text('National M.U', 172, 238);

    doc.text('Intended', 7, 243);
    doc.text('Offices', 8, 246);
    doc.text('of transit', 8, 249);
    doc.text('and country', 8, 252);

    doc.text('50 Principal', 28, 244);
    doc.text('No', 73, 244);
    doc.text('Signature', 98, 244);

    doc.text('Represented by', 28, 248);
    doc.text('Place and date', 28, 252);

    doc.text('COFFICE OF DEPARTURE', 142, 244);

    doc.text('52 Guarantee', 7, 259);
    doc.text('not valid for', 8, 262);
    doc.text('53 Office of destination and country', 143, 262);

    doc.text('Dcontrol By Office Of Destination', 8, 274);

    doc.text('Stamp', 92, 273);
    doc.text('Place and date', 143, 274);
    doc.text('Signature', 35, 283);

    ///valeur

    doc.setFontSize(10);
    doc.setTextColor('#000');

    if (cutoms.exporterCompanyName != null) {
      doc.text(cutoms.exporterCompanyName + '', 31, 34);
    }

    let x = Math.floor(Math.random() * 1000000);
    doc.text(x + '', 79, 25);
    if (cutoms.destinationCountryNm != null) {
      doc.text(cutoms.destinationCountryNm + '', 148, 17);
    }

    if (cutoms.companyOfDestination != null) {
      doc.text(cutoms.companyOfDestination + '', 148, 22);
    }

    if (cutoms.customsReferenceData != null) {
      doc.text(cutoms.customsReferenceData + '', 148, 32);
    }
    if (cutoms.manifest != null) {
      doc.text(cutoms.manifest + '', 174, 32);
    }

    if (cutoms.loadList != null) {
      doc.text(cutoms.loadList + '', 132, 30);
    }
    if (cutoms.totalItems != null) {
      doc.text(cutoms.totalItems + '', 120, 43);
    }

    if (cutoms.numberOfPackages != null) {
      doc.text(cutoms.numberOfPackages + '', 131, 43);
    }

    doc.text('_', 156, 43);
    doc.text('_', 181, 43);

    if (cutoms.importerCompanyName != null) {
      doc.text(cutoms.importerCompanyName + '', 31, 64);
    }

    let x1 = Math.floor(Math.random() * 1000000);
    doc.text(x1 + '', 79, 55);
    if (cutoms.taxpayerName != null) {
      doc.text(cutoms.taxpayerName + '', 129, 55);
    }

    let x2 = Math.floor(Math.random() * 1000000);
    doc.text(x2 + '', 175, 55);

    if (cutoms.valueDetails != null) {
      doc.text(cutoms.valueDetails + '', 157, 68);
    }
    doc.text('_', 120, 68);
    doc.text('_', 137, 68);
    doc.text('_', 185, 68);

    if (cutoms.declarantName != null) {
      doc.text(cutoms.declarantName + '', 31, 84);
    }

    let x3 = Math.floor(Math.random() * 1000000);
    doc.text(x3 + '', 79, 75);

    if (cutoms.dateOfDeclaration != null) {
      doc.text(cutoms.dateOfDeclaration + '', 188, 20);
    }

    if (cutoms.exportCountryNm != null) {
      doc.text(cutoms.exportCountryNm + '', 117, 78);
    }

    if (cutoms.exportCountryCd != null) {
      doc.text(cutoms.exportCountryCd + '', 159, 78);
    }

    if (cutoms.exportCountryCd != null) {
      doc.text(cutoms.exportCountryCd + '', 173, 78);
    }

    if (cutoms.destinationCountryCd != null) {
      doc.text(cutoms.destinationCountryCd + '', 186, 78);
    }

    if (cutoms.destinationCountryCd != null) {
      doc.text(cutoms.destinationCountryCd + '', 196, 78);
    }

    if (cutoms.countryOfOrigin != null) {
      doc.text(cutoms.countryOfOrigin + '', 115, 88);
    }

    if (cutoms.destinationCountryNm != null) {
      doc.text(cutoms.destinationCountryNm + '', 163, 88);
    }

    if (cutoms.transportMeansNm != null) {
      doc.text(cutoms.transportMeansNm + '', 32, 102);
    }

    doc.text('_', 95, 98);

    if (cutoms.deliveryDesc != null) {
      doc.text(cutoms.deliveryDesc + '', 125, 99);
    }

    if (cutoms.borderTransportNm != null) {
      doc.text(cutoms.borderTransportNm + '', 32, 114);
    }

    if (cutoms.invoiceCurrencyNm != null) {
      doc.text(cutoms.invoiceCurrencyNm + '', 113, 112);
    }

    if (cutoms.invoiceValueAmount != null) {
      doc.text(cutoms.invoiceValueAmount + '', 123, 112);
    }

    if (cutoms.invoiceValueExchangeRate != null) {
      doc.text(cutoms.invoiceValueExchangeRate + '', 153, 112);
    }
    if (cutoms.natureOfTransactionCode1 != null) {
      doc.text(cutoms.natureOfTransactionCode1 + '', 178, 112);
    }
    if (cutoms.modeTransportCrossingTheBorder != null) {
      doc.text(cutoms.modeTransportCrossingTheBorder + '', 29, 127);
    }
    if (cutoms.inlandTransportMode != null) {
      doc.text(cutoms.inlandTransportMode + '', 55, 127);
    }

    if (cutoms.loadingPlaceLocation != null) {
      doc.text(cutoms.loadingPlaceLocation + '', 82, 127);
    }

    if (cutoms.bankCode != null) {
      doc.text(cutoms.bankCode + '', 183, 127);
    }

    if (cutoms.bankCode != null) {
      doc.text('_terme', 127, 140);
    }

    if (cutoms.officeOfEntryName != null) {
      doc.text(cutoms.officeOfEntryName + '', 31, 144);
    }

    if (cutoms.locationOfGoods != null) {
      doc.text(cutoms.locationOfGoods + '', 81, 144);
    }

    doc.text('____________', 55, 162);
    doc.text('____________', 55, 167);
    doc.text('____________', 55, 172);
    doc.text('________', 115, 159);
    doc.text('________', 131, 159);
    doc.text('________', 130, 166);
    doc.text('________', 186, 166);
    doc.text('________', 186, 177);
    doc.text('________', 131, 177);
    doc.text('________', 131, 187);
    doc.text('________', 165, 187);
    doc.text('________', 186, 187);

    if (cutoms.totalGrossMass != null) {
      doc.text(cutoms.totalGrossMass + '', 165, 168);
    }

    if (cutoms.totalGrossMass != null) {
      doc.text(cutoms.totalGrossMass - 50 + '', 165, 168);
    }

    if (cutoms.modeOfPayment != null) {
      doc.text(cutoms.modeOfPayment + '', 142, 218);
    }

    if (cutoms.warehouseDelay != null) {
      doc.text(cutoms.warehouseDelay + '', 171, 212);
    }

    if (cutoms.assessmentNumber != null) {
      doc.text(cutoms.assessmentNumber + '', 154, 223);
    }

    if (cutoms.assessmentDate != null) {
      doc.text(cutoms.assessmentDate + '', 180, 223);
    }

    if (cutoms.guaranteeAmount != null) {
      doc.text(cutoms.guaranteeAmount + '', 145, 228);
    }
    if (cutoms.guaranteeDate != null) {
      doc.text(cutoms.guaranteeDate + '', 180, 228);
    }

    if (cutoms.totalFees != null) {
      doc.text(cutoms.totalFees + '', 145, 233);
    }

    if (cutoms.totalDeclaration != null) {
      doc.text(cutoms.totalDeclaration + '', 151, 238);
    }

    doc.text('_________________', 102, 250);
    doc.text('_________________', 40, 285);
    doc.text('__________________', 147, 280);

    doc.save('Customs Decelaration num' + '.pdf');
  }

  getInformationOrg(id: any): Promise<string> {
    return new Promise((resolve, reject) => {
      this.organisationService.GetInformationOrganisationByIdOrg(id)
        .subscribe({
          next: (response: any) => resolve(response.nameOrg),
          error: (error) => reject(error)
        });
    });
  }

  Id_org: any;
  CustomDocument: any;
  nomOrgSender: String;
  nomOrgReciever: String;
  submit(content: any, document: any) {
    this.CustomDocument = document;
    this.CustomDocument.etat = 'For confirmation';
    this.Id_org = this.CustomDocument.customsReferenceNo;
    this.customsService
      .submit(this.CustomDocument, this.Id_org)
      .subscribe(async (res: any) => {
        this.open(content);

        try {

          const [nomOrgSender, nomOrgReceiver] = await Promise.all([
            this.getInformationOrg(this.CustomDocument.messages[0].organizationSender),
            this.getInformationOrg(this.CustomDocument.messages[0].organizationReceiver)
          ]);

          this.nomOrgSender = nomOrgSender;
          this.nomOrgReciever = nomOrgReceiver;

          const formdata = {
            action: 'SUBMIT',
            idOrgSender: this.CustomDocument.messages[0].organizationSender,
            nomOrgSender: this.nomOrgSender,
            idOrgReceiver: this.CustomDocument.messages[0].organizationReceiver,
            nomOrgReceiver: this.nomOrgReciever,
            idScenario: this.CustomDocument.id,
          };

          this.notificationApi.post(formdata).subscribe((res: any) => {
          });

        } catch (error) {
          console.error("Error fetching organization information:", error);
        }
      });
  }

  open_pay(document: any) {
    this.router.navigateByUrl('/Customs/update/' + document.id);
  }
  open_submit(content: any, document: any) {
    this.Id_org = document.customsReferenceNo;
    this.CustomDocument = document;
    this.open(content);
  }

  id_search: any = 0;
  attr: any;
  search() {
    this.id_search = 1;
    this.customsService
      .Search(this.attr, this.valeurcondition)
      .subscribe((res: any) => {
        this.ListCustoms_f = res;
        setTimeout(() => {
          this.dataSource = new MatTableDataSource(this.ListCustoms_1);
          this.dataSource.paginator = this.paginator;
        }, 100);
      });
  }

  private socket: any;
  web_socket: boolean = false;

  Websocket_cd() {
    var host = 'wss://ws.coincap.io/prices?assets=bitcoin,ethereum,litecoin';

    this.socket = new WebSocketSubject(host);

    this.socket.subscribe(
      (message: any) => { },
      (err: any) => console.log(err)
    );

    this.socket = new WebSocket(host);
    this.socket.onmessage = (e: any) => {
      if (this.id_search == 0) {
        this.GetCustomsDeclaration();
      }
    };
  }

  method2(): void {
    this.web_socket = true;
    if (this.selection.selected.length > 0) {
      this.web_socket = true;
    }
  }
  premission: any;
  @Input() profil: string;
  changeaction(event: any) {
    this.userslist = [];
    this.selectetuser = null;
    if (event == undefined) {
      this.userslist = [];
    }

    if (event.id == '1') {
      this.getUser(this.TeamId, this.premission);
      this.acions_t = 'SAVE';
    } else if (event.id == '2') {
      this.getUser(this.TeamId, this.premission + 'S');
      this.acions_t = 'SUBMIT';
    }
  }
  dataSourceActions: MatTableDataSource<any>;
  listactions: any[];
  id_folder: any;
  message_user: any;
  reason_assign: any;
  messages_actions: any[] = [];
  message_sender: any;
  message_receiver: any;
  Arr_assign(content: any, id_folder: any, document: any) {
    this.transactiontype = 'Save';
    this.status_sc = false;
    this.id_folder = id_folder;
    let row_message = document.messages.length - 1;
    let status_message = document.messages[row_message].status;
    this.message_user = document.messages[row_message];
    this.id_message = document.messages[row_message].id;

    if (this.message_user.organizationSender != null) {
      this.message_sender = this.message_user.organizationSender;
    }

    if (this.message_user.organizationReceiver != null) {
      this.message_receiver = this.message_user.organizationReceiver;
    }

    let conditionReceiver =
      document.etat == 'For confirmation' &&
      this.message_receiver == this.id_Org_en;

    let conditionSender =
      document.etat == 'Save' && this.message_sender == this.id_Org_en;

    if (status_message == 'SUCCESS' || status_message == 'INPROGRESS') {
      if (conditionReceiver || conditionSender) {
        this.status_sc = false;
        this.messages_actions = this.message_user.actions;

        if (conditionReceiver) {
          this.messages_actions = this.message_user.actions.filter(
            (e: any) => e.message.organizationSender == this.id_Org_en
          );
        }

        if (conditionSender) {
          this.messages_actions = this.message_user.actions.filter(
            (e: any) => e.message.organizationSender == this.id_Org_en
          );
        }
        let row_actions = this.messages_actions.length;
        if (row_actions > 0) {
          if (this.messages_actions[row_actions - 1].status != 'FINISHED') {
            this.status_sc = true;
            this.reason_assign = this.languet.text_assign_2;
          }
        }

        setTimeout(() => {
          this.dataSourceActions = new MatTableDataSource(
            this.messages_actions
          );
        }, 200);
      } else {
        this.status_sc = true;
        this.reason_assign = this.languet.text_assign_1;
      }
    } else {
      this.status_sc = true;
      this.reason_assign = this.languet.text_assign_1;
    }

    setTimeout(() => {
      this.modalService.open(content, {
        size: 'xl',
        windowClass: 'dark-modal',
      });
    }, 500);
  }

  selectetassign: any;

  selectetuser: any;
  Id_user: any;
  acions_t: any;
  userslist: any[];

  changeuser(event: any) {
    this.Id_user = event.id;
  }

  getUser(team: any, sc: any) {
    this.userService.GetAllUsers(team, sc).subscribe((res: any) => {
      this.userslist = res;
    });
  }
  Assign() {
    if (this.transactiontype == 'Save') {
      this.actiondata = {
        code: this.acions_t,
        affectedToUser: this.Id_user,
        designation: this.designation,
      };

      this.affecationService
        .assignm(this.customsService.Spring_serveur, this.actiondata, this.id_folder, 'cd')
        .subscribe((res: any) => {
          this.designation = '';
          this.selectetassign = null;
          this.selectetuser = null;
          this.dimision();
          this.notifier.notify(
            'success',
            this.languet.titremodal + ' ' + this.languet.task
          );
        });
    } else {
      this.actiondata.code = this.acions_t;
      this.actiondata.affectedToUser.id = this.Id_user;
      this.actiondata.designation = this.designation;

      this.affecationService
        .reassign(this.customsService.Spring_serveur, this.actiondata, this.id_Action)
        .subscribe((res: any) => {
          this.designation = '';
          this.selectetassign = null;
          this.selectetuser = null;
          this.dimision();
          this.notifier.notify(
            'success',
            this.languet.titremodal + ' ' + this.languet.task
          );
        });
    }
  }
  TeamId: any;
  GetIdUser() {
    this.userService.Getuserbyusername().subscribe((res: any) => {
      this.TeamId = res.team.id;
      this.Getorganisation_send(res.id);
    });
  }
  designation: any;
  id_message: any;

  color_active = '#77838e';
  status_sc: boolean = false;
  RoleTeammanager: boolean = false;
  RoleTrading: boolean = false;
  id_Org_en: any;

  role_organisation: any;
  Getorganisation_send(id: any) {
    this.organisationService.Get_org_byID(id).subscribe((res: any) => {
      this.role_organisation = res['profil'];
      this.id_Org_en = res.id;
      this.GetCustomsDeclaration();
    });
  }

  transactiontype = 'Save';
  id_Action: any;
  action: any;
  useraction: any;
  actiondata: any;

  reassign(action: any) {
    this.transactiontype = 'Update';
    this.action = action;
    this.actiondata = action;
    this.id_Action = action.id;
    this.useraction = action.affectedToUser;
    this.selectetuser = this.useraction.userName;
    this.Id_user = this.selectetuser.id;
    this.selectetassign = this.action.code;
    this.designation = this.action.designation;
    this.status_sc = false;
  }
}
