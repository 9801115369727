<div class="bloc" [ngStyle]="{ direction: direction }">
  <div class="c1">
    <div class="Trac-2081"></div>

    <div>
      <h2 class="titre-page">{{ languef.titrelist }}</h2>
      <div class="about-border"></div>
    </div>
  </div>

  <div class="blocrecherche">
    <div class="row" style="width: 100%">
      <div class="col-lg-3">
        <ng-select
          [items]="itemlist"
          (change)="changetype($event)"
          bindLabel="name"
          placeholder="{{ languef.typedate }}"
          [(ngModel)]="selectedtype"
        >
        </ng-select>
      </div>

      <div class="col-lg-4">
        <mat-form-field appearance="fill">
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              [(ngModel)]="startDate"
              placeholder="{{ languef.startdate }}"
              (dateInput)="onchangedatestart($event.value)"
            />
            <input
              matEndDate
              [(ngModel)]="endDate"
              placeholder="{{ languef.enddate }}"
              (dateInput)="onchangedateend($event.value)"
            />
          </mat-date-range-input>

          <mat-datepicker-toggle
            [disabled]="date_disabled"
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>

      <div class="col-lg-3" style="height: 90px">
        <ng-select
          [items]="statuslist"
          (change)="changestatus($event)"
          bindLabel="name"
          placeholder="{{ languef.etat }}"
          [(ngModel)]="selectedstatus"
        >
        </ng-select>
      </div>
      <div class="col-lg-1"></div>
      <div class="col-lg-3" style="height: 90px; padding-top: 3px">
        <ng-select
          [items]="itemslist"
          (change)="changeconditionitem($event)"
          bindLabel="name"
          placeholder="{{ languef.condition }}"
          [(ngModel)]="selectedcondition"
        >
        </ng-select>
      </div>
      <div class="col-lg-4" style="padding-top: 5px">
        <input
          type="text"
          [(ngModel)]="valeurcondition"
          mdbInput
          class="form-control"
          placeholder="{{ languef.conditionvalue }}"
        />
      </div>

      <div class="col-lg-3 search">
        <div class="div_action1" (click)="search()">
          <div>
            <span style="color: #fff">{{ languet.search }}</span>
          </div>
          <div style="padding: 0% 4% 0% 4%">
            <mat-icon style="color: #ffffff; font-size: 30px">search</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div style="padding-top: 3%">
    <div
      style="padding: 1%"
      [ngStyle]="{
        float: langue === 'AR' ? 'right' : 'left'
      }"
    >
      <button
        [disabled]="ecriture == false || RoleAgent == false"
        routerLink="/ShippingArrival/new"
        type="button"
        class="btn button3"
        style="display: flex"
      >
        <div>
          <mat-icon class="iconadd">add</mat-icon>
        </div>
        <div style="padding-top: 3px">
          <span class="add"> {{ languef.addnew }} </span>
        </div>
      </button>
    </div>
    <div
      style="display: flex; padding: 0% 1% 0% 1%"
      [ngStyle]="{
        float: langue === 'AR' ? 'left' : 'right'
      }"
    >
      <div style="padding: 1%">
        <button
          [disabled]="selection.selected.length == 0"
          type="button"
          class="btn button8"
          style="display: flex"
          (click)="printdoc()"
        >
          <div>
            <mat-icon class="iconprint">print</mat-icon>
          </div>
          <div style="padding-top: 3px">
            <span class="print"> {{ languef.print }} </span>
          </div>
        </button>
      </div>
      <div style="padding: 1%">
        <button
          type="button"
          [disabled]="selection.selected.length == 0"
          class="btn button8"
          (click)="open(modal_delete)"
          style="display: flex"
        >
          <div>
            <mat-icon class="icondelete">delete</mat-icon>
          </div>
          <div style="padding-top: 3px">
            <span class="print"> {{ languef.delete }} </span>
          </div>
        </button>
      </div>
    </div>

    <div style="width: 100%; padding-top: 2%">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="$event ? toggleAllRows() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>
        <!-- Progress Column -->
        <ng-container matColumnDef="progress">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell"
          >
            {{ langued.vesselname }}
          </th>
          <td mat-cell class="tdcell" *matCellDef="let row">
            {{ row.vessel.vessel }}
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell"
          >
            {{ langued.voyageno }}
          </th>
          <td mat-cell class="tdcell" *matCellDef="let row">
            {{ row.voyageno }}
          </td>
        </ng-container>

        <ng-container matColumnDef="portd">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell"
          >
            {{ langued.portofdeclaration }}
          </th>
          <td mat-cell class="tdcell" *matCellDef="let row">
            {{ row.portofcallname }}
          </td>
        </ng-container>
        <ng-container matColumnDef="eta">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell"
          >
            {{ langued.eta }}
          </th>
          <td mat-cell class="tdcell" *matCellDef="let row">
            {{ row.eta }}
          </td>
        </ng-container>
        <ng-container matColumnDef="previous">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell1"
          >
            {{ langued.previousport }}
          </th>
          <td mat-cell class="tdcell1" *matCellDef="let row">
            {{ row.previousportname }}
          </td>
        </ng-container>

        <ng-container matColumnDef="etat">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell"
          >
            {{ langued.status }}
          </th>
          <td
            mat-cell
            class="tdcell"
            *matCellDef="let row"
            style="padding-left: 1%"
          >
            <div *ngIf="row.etat == 'Accept'" style="display: flex">
              <div>
                <b style="width: 100%">{{ languef.accept }}</b>
              </div>
              <div style="padding-left: 1%">
                <mat-icon style="color: #73ff01; font-size: 25px"
                  >check_circle</mat-icon
                >
              </div>
            </div>
            <div *ngIf="row.etat == 'Save'" style="display: flex">
              <div>
                <b style="width: 100%">{{ languef.save }}</b>
              </div>
              <div style="padding-left: 1%">
                <mat-icon style="color: #000000; font-size: 25px"
                  >save_alt</mat-icon
                >
              </div>
            </div>

            <div *ngIf="row.etat == 'Reject'" style="display: flex">
              <div>
                <b style="width: 100%">
                  {{ languef.reject }}
                </b>
              </div>
              <div style="padding-left: 1%">
                <mat-icon style="color: red; font-size: 22px">cancel</mat-icon>
              </div>
            </div>
            <div *ngIf="row.etat == 'For confirmation'" style="display: flex">
              <div>
                <b style="width: 100%">
                  {{ languef.forconfirmation }}
                </b>
              </div>
              <div style="padding-left: 1%">
                <mat-icon style="color: rgb(27, 202, 255); font-size: 22px"
                  >error</mat-icon
                >
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="assign">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell1"
          >
            {{ languef.affectedto }}
          </th>
          <td mat-cell class="tdcell1" *matCellDef="let row">
            <p *ngIf="row.messages[row.messages.length - 1].actions.length > 0">
              {{
                row.messages[row.messages.length - 1].actions[
                  row.messages[row.messages.length - 1].actions.length - 1
                ].affectedToUser.userName
              }}
            </p>
          </td>
        </ng-container>

        <!-- Fruit Column -->
        <ng-container matColumnDef="action">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell_actions"
          >
            {{ langued.actions }}
          </th>
          <td mat-cell *matCellDef="let row">
            <button
              matTooltip="{{ languef.view }}"
              (click)="Arr_view(modal_view, row.id)"
              style="border: 0px solid; background-color: #ffffff"
            >
              <div class="btn-action">
                <mat-icon style="color: rgb(110, 110, 110)"
                  >remove_red_eye</mat-icon
                >
              </div>
            </button>
            <a
              *ngIf="
                RoleAgent &&
                row.etat == 'Save' &&
                row.messages[row.messages.length - 1].actions.length > 0
              "
            >
              <button
                *ngIf="
                  row.messages[row.messages.length - 1].actions[
                    row.messages[row.messages.length - 1].actions.length - 1
                  ].code == 'SAVE' ||
                  row.messages[row.messages.length - 1].actions[
                    row.messages[row.messages.length - 1].actions.length - 1
                  ].status != 'FINISHED'
                "
                matTooltip="{{ languef.edit }}"
                routerLink="/ShippingArrival/update/{{ row.id }}"
                style="border: 0px solid; background-color: #ffffff"
              >
                <div class="btn-action">
                  <mat-icon style="color: #1671b3">edit</mat-icon>
                </div>
              </button>
            </a>

            <button
              *ngIf="RoleAgent"
              matTooltip="{{ languef.copy }}"
              (click)="open_copy(row.vessel.id, row.id, modal_copy)"
              style="border: 0px solid; background-color: #ffffff"
            >
              <div class="btn-action">
                <mat-icon style="color: #19ffb2">file_copy</mat-icon>
              </div>
            </button>
            <a
              *ngIf="
                RoleAgent &&
                row.etat == 'Save' &&
                row.messages[row.messages.length - 1].actions.length > 0
              "
            >
              <button
                *ngIf="
                  row.messages[row.messages.length - 1].actions[
                    row.messages[row.messages.length - 1].actions.length - 1
                  ].code == 'SUBMIT'
                "
                matTooltip="{{ languef.submit }}"
                (click)="submit(SAsubmit, row)"
                style="border: 0px solid; background-color: #ffffff"
              >
                <div class="btn-action">
                  <mat-icon style="color: #0bec30">send</mat-icon>
                </div>
              </button>
            </a>

            <button
              matTooltip="{{ languef.messageuser }}"
              (click)="Arr_messages(modal_messageuser, row.id)"
              style="border: 0px solid; background-color: #ffffff"
            >
              <div class="btn-action">
                <mat-icon style="color: #b812ce">message</mat-icon>
              </div>
            </button>

            <button
              *ngIf="row.etat == 'Reject'"
              matTooltip="{{ languef.rejection }}"
              (click)="open_reason(modal_reason, row.rejectionReason)"
              style="border: 0px solid; background-color: #ffffff"
            >
              <div class="btn-action">
                <mat-icon style="color: #ee0d0d">message</mat-icon>
              </div>
            </button>

            <button
              *ngIf="RoleTeammanager"
              matTooltip="{{ languef.assignment }}"
              (click)="Arr_assign(modal_assign, row.id, row)"
              style="border: 0px solid; background-color: #ffffff"
            >
              <div class="btn-action">
                <mat-icon style="color: #000000">reply</mat-icon>
              </div>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell tdcell" colspan="10" style="min-height: 400px">
            <div>
              <div style="display: block">
                <img
                  src="./../../../../assets/tablenotfound.PNG"
                  style="width: 250px; height: 250px"
                />
              </div>
              <div>
                <span class="spanmessage"> {{ langued.message }}</span>
              </div>
            </div>
          </td>
        </tr>
      </table>

      <mat-paginator [pageSizeOptions]="[20, 50, 100]"></mat-paginator>
    </div>
  </div>
</div>

<ng-template #modal_delete>
  <div class="modal-body rounded-8" [ngStyle]="{ direction: direction }">
    <div style="text-align: center">
      <div style="padding-top: 10%">
        <img
          style="width: 90px; height: 90px"
          src="./../../../assets/icons/list.PNG"
        />
      </div>

      <div style="padding-top: 5%">
        <label
          style="
            width: 270px;
            font-family: Poppins;
            font-size: 20px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.98;
            letter-spacing: normal;
            text-align: center;
            color: #1d3a66;
          "
        >
          {{ languet.delete }}
        </label>
      </div>
      <div style="padding-top: 3%; display: flex">
        <div class="container text-right nextbtn">
          <button type="button" class="btn button10" (click)="Arr_remove()">
            <span class="next"> {{ languet.btnsure }} </span>
          </button>
        </div>

        <div class="container text-right nextbtn">
          <button type="button" class="btn button9" (click)="dimision()">
            <span class="next"> {{ languet.btnno }} </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #modal_view class="modal-dialog" let-modal>
  <div class="modal-header">
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body" [ngStyle]="{ direction: direction }">
    <mat-tab-group>
      <mat-tab label="{{ langued.vessel }}"
        ><div class="row" style="width: 100%; padding: 2%">
          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1"> {{ langued.vesselname }} </label>
            </div>
            <div>
              <label class="lab2">{{ vesselname }} </label>
            </div>
          </div>
          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1"> {{ langued.vesseltype }} </label>
            </div>
            <div>
              <label class="lab2"> {{ vesseltype }} </label>
            </div>
          </div>

          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1"> {{ langued.vesselbuildyear }} </label>
            </div>
            <div>
              <label class="lab2"> {{ vesselbuildyear }} </label>
            </div>
          </div>

          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1"> {{ langued.vesselregport }} </label>
            </div>
            <div>
              <label class="lab2">
                <label style="color: #e8effc">.</label>{{ vesselregport }}
              </label>
            </div>
          </div>

          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1"> {{ langued.vesseldraft }} </label>
            </div>
            <div>
              <label class="lab2"> {{ vesseldraft }} </label>
            </div>
          </div>

          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1"> {{ langued.vessellength }} </label>
            </div>
            <div>
              <label class="lab2">{{ vessellength }} </label>
            </div>
          </div>

          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1"> {{ langued.vesselgrossweight }} </label>
            </div>
            <div>
              <label class="lab2"> {{ vesselgrossweight }} </label>
            </div>
          </div>

          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1"> {{ langued.vesselnetweight }} </label>
            </div>
            <div>
              <label class="lab2"> {{ vesselnetweight }} </label>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="{{ langued.callshipping }}">
        <div class="row" style="width: 90%; padding: 2%">
          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1"> {{ langued.callsigntype }} </label>
            </div>
            <div>
              <label class="lab2"> {{ callsigntype }} </label>
            </div>
          </div>
          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1"> {{ langued.callsign }} </label>
            </div>
            <div>
              <label class="lab2"> {{ callsign }} </label>
            </div>
          </div>

          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1"> {{ langued.ownercode }} </label>
            </div>
            <div>
              <label class="lab2"
                ><label style="color: #e8effc">.</label> {{ ownercode }}
              </label>
            </div>
          </div>

          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1"> {{ langued.ownername }} </label>
            </div>
            <div>
              <label class="lab2">
                <label style="color: #e8effc">.</label>{{ ownername }}
              </label>
            </div>
          </div>

          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1"> {{ langued.shippingagentcode }} </label>
            </div>
            <div>
              <label class="lab2"> {{ shippingagentcode }} </label>
            </div>
          </div>

          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1"> {{ langued.shippingagent }} </label>
            </div>
            <div>
              <label class="lab2">{{ shippingagent }} </label>
            </div>
          </div>

          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1"> {{ langued.shipmaster }} </label>
            </div>
            <div>
              <label class="lab2"> {{ shipmaster }} </label>
            </div>
          </div>

          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1"> {{ langued.eta }} </label>
            </div>
            <div>
              <label class="lab2"> {{ eta }} </label>
            </div>
          </div>

          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1"> {{ langued.etd }} </label>
            </div>
            <div>
              <label class="lab2"> {{ etd }} </label>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="{{ langued.port }}">
        <div class="row" style="width: 90%; padding: 2%">
          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1"> {{ langued.previousportcode }} </label>
            </div>
            <div>
              <label class="lab2"> {{ previousportcode }} </label>
            </div>
          </div>
          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1"> {{ langued.previousportname }} </label>
            </div>
            <div>
              <label class="lab2"> {{ previousportname }} </label>
            </div>
          </div>

          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1"> {{ langued.portofcallcode }} </label>
            </div>
            <div>
              <label class="lab2"> {{ portofcallcode }} </label>
            </div>
          </div>

          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1"> {{ langued.portofcallname }} </label>
            </div>
            <div>
              <label class="lab2"> {{ portofcallname }} </label>
            </div>
          </div>
          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1"> {{ langued.nextportcode }} </label>
            </div>
            <div>
              <label class="lab2">{{ nextportcode }} </label>
            </div>
          </div>

          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1"> {{ langued.nextportname }} </label>
            </div>
            <div>
              <label class="lab2"> {{ nextportname }} </label>
            </div>
          </div>
          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1"> {{ langued.preferreddock }} </label>
            </div>
            <div>
              <label class="lab2">
                <label style="color: #e8effc">.</label>{{ preferreddock }}
              </label>
            </div>
          </div>
          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1">{{ langued.berthtype }} </label>
            </div>
            <div>
              <label class="lab2">
                <label style="color: #e8effc">.</label>{{ berthtype }}
              </label>
            </div>
          </div>
          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1"> {{ langued.dangerousgoods }} </label>
            </div>
            <div>
              <label class="lab2">
                <label style="color: #e8effc">.</label>{{ dangerousgoods }}
              </label>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="{{ langued.visitpurpose }}">
        <div class="row" style="width: 90%; padding-top: 2%">
          <div
            class="col-lg-4"
            style="padding-top: 1%"
            *ngFor="let visitp of VisitPurposesList"
          >
            <mat-card class="card2" [ngStyle]="{ direction: direction }">
              <mat-card-content style="text-align: center">
                <h5 class="titre_card">{{ langued.visitpurpose }}</h5>
              </mat-card-content>
              <mat-card-subtitle>
                <label class="subtitle_card"
                  >{{ langued.reason }} : {{ visitp.reason }}
                </label>
              </mat-card-subtitle>
            </mat-card>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="{{ langued.assengerssummary }}">
        <div class="row" style="width: 90%; padding-top: 2%">
          <div
            class="col-lg-4"
            style="padding-top: 1%"
            *ngFor="let Passenger of PassengerSummarysList"
          >
            <mat-card class="card2" [ngStyle]="{ direction: direction }">
              <mat-card-content style="text-align: center">
                <h5 class="titre_card">{{ langued.assengerssummary }}</h5>
              </mat-card-content>
              <mat-card-subtitle>
                <label class="subtitle_card"
                  >{{ langued.numberofpassenge }} :
                  {{ Passenger.numberofpassenger }} </label
                ><br />
                <label class="subtitle_card"
                  >{{ langued.passengernationally }} :
                  {{ Passenger.passengernationally }}</label
                >
              </mat-card-subtitle>
            </mat-card>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="{{ langued.cargosumary }}">
        <div class="row" style="width: 90%; padding-top: 2%">
          <div
            class="col-lg-4"
            *ngFor="let Cargo of CargoSummarysList"
            style="padding-top: 1%"
          >
            <mat-card class="card2" [ngStyle]="{ direction: direction }">
              <mat-card-content style="text-align: center">
                <h5 class="titre_card">{{ langued.cargosumary }}</h5>
              </mat-card-content>
              <mat-card-subtitle>
                <label class="subtitle_card"
                  >{{ langued.cargocode }} : {{ Cargo.cargocode }} </label
                ><br />
                <label class="subtitle_card"
                  >{{ langued.cargoamount }} : {{ Cargo.cargoamount }} </label
                ><br />
                <label class="subtitle_card"
                  >{{ langued.operationcode }} : {{ Cargo.operationcode }}
                </label>
              </mat-card-subtitle>
            </mat-card>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</ng-template>

<ng-template #modal_messageuser class="modal-dialog" let-modal>
  <div class="modal-header">
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div [ngStyle]="{ direction: direction }">
    <table mat-table [dataSource]="dataSourceMessages">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>{{ languem.id }}</th>
        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>{{ languem.status }}</th>
        <td mat-cell *matCellDef="let element">{{ element.status }}</td>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef>{{ languem.startdate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.startDate }}</td>
      </ng-container>

      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef>{{ languem.enddate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.endDate }}</td>
      </ng-container>

      <ng-container matColumnDef="typeFlux">
        <th mat-header-cell *matHeaderCellDef>{{ languem.typeflux }}</th>
        <td mat-cell *matCellDef="let element">{{ element.typeFlux }}</td>
      </ng-container>

      <ng-container matColumnDef="etatFlux">
        <th mat-header-cell *matHeaderCellDef>{{ languem.etatflux }}</th>
        <td mat-cell *matCellDef="let element">{{ element.etatFlux }}</td>
      </ng-container>

      <ng-container matColumnDef="idDossier">
        <th mat-header-cell *matHeaderCellDef>{{ languem.iddossier }}</th>
        <td mat-cell *matCellDef="let element">{{ element.idDossier }}</td>
      </ng-container>

      <ng-container matColumnDef="typeMessage">
        <th mat-header-cell *matHeaderCellDef>{{ languem.typemessage }}</th>
        <td mat-cell *matCellDef="let element">{{ element.typeMessage }}</td>
      </ng-container>
      <ng-container matColumnDef="typeScenario">
        <th mat-header-cell *matHeaderCellDef>{{ languem.typescenario }}</th>
        <td mat-cell *matCellDef="let element">{{ element.typeScenario }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsMessages"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsMessages"></tr>
    </table>
  </div>
</ng-template>
<ng-template #modal_copy let-modal>
  <div class="modal-header text-center">
    <h4 class="modal-title w-100" id="modal-basic-title">
      {{ languet.copyshippingarrival }}
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div
    class="modal-body"
    style="z-index: 1"
    [ngStyle]="{ direction: direction }"
  >
    <div class="bodymodal">
      <div style="text-align: center">
        <div style="z-index: 1; text-align: center">
          <ng-select
            [items]="copylist"
            (change)="changestscenario($event)"
            bindLabel="name"
            placeholder="{{ languet.scenario }}"
            [(ngModel)]="selectetcopy"
          >
          </ng-select>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center" style="padding-bottom: 2%">
    <button
      [disabled]="
        selectetcopy == null ||
        selectetcopy == undefined ||
        selectetcopy == 'Ship Docking'
      "
      type="button"
      class="btn button2"
      (click)="Go_copy()"
    >
      <span class="Save"> {{ languet.gotocopy }}</span>
    </button>
  </div>
</ng-template>

<ng-template #modal_reason let-modal>
  <div class="modal-header text-center">
    <h4 class="modal-title w-100" id="modal-basic-title">Reason Reject</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>

  <div
    class="modal-body"
    style="z-index: 1"
    [ngStyle]="{ direction: direction }"
  >
    <div class="bodymodal">
      <div style="padding-left: 5%">
        <div style="z-index: 1">
          <label>
            {{ reason }}
          </label>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #modal_assign class="modal-dialog" let-modal>
  <div class="modal-header text-center">
    <h4 class="modal-title w-100" id="modal-basic-title">
      {{ languef.assignment }}
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body" [ngStyle]="{ direction: direction }">
    <div class="row" *ngIf="status_sc == false">
      <div class="col-lg-6" style="text-align: center">
        <div style="z-index: 1; text-align: center">
          <ng-select
            [items]="actionlist"
            (change)="changeaction($event)"
            bindLabel="name"
            placeholder="{{ languef.assignment }}"
            [(ngModel)]="selectetassign"
          >
          </ng-select>
        </div>
      </div>

      <div class="col-lg-6">
        <div style="z-index: 1; text-align: center">
          <ng-select
            [items]="userslist"
            (change)="changeuser($event)"
            placeholder="{{ languef.users }}"
            [(ngModel)]="selectetuser"
            bindLabel="userName"
            bindValue="userName"
            [disabled]="selectetassign == null"
          >
            <ng-template ng-label-tmp let-item="item" style="width: 160px">
              <img
                *ngIf="item.attachment != null"
                height="25"
                width="25"
                [src]="item.attachment"
                alt="Avatar"
                class="avatar"
              />
              <img
                *ngIf="item.attachment == null"
                height="25"
                width="25"
                src="https://bootdey.com/img/Content/avatar/avatar6.png"
                alt="Avatar"
                class="avatar"
              />
              <b>{{ item.userName }}</b>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <img
                *ngIf="item.attachment != null"
                height="25"
                width="25"
                [src]="item.attachment"
                alt="Avatar"
                class="avatar"
              />
              <img
                *ngIf="item.attachment == null"
                height="25"
                width="25"
                src="https://bootdey.com/img/Content/avatar/avatar6.png"
                alt="Avatar"
                class="avatar"
              />
              <b style="padding: 2%">{{ item.userName }}</b>
            </ng-template>
          </ng-select>
        </div>
      </div>

      <div class="col-lg-12" style="padding-top: 2%">
        <textarea
          matInput
          class="form-control"
          name="designation"
          style="width: 100%; text-indent: 10px"
          placeholder=" {{ languet.comment }} "
          [(ngModel)]="designation"
          required
        ></textarea>
      </div>
    </div>

    <div *ngIf="status_sc" style="text-align: center">
      <h5>{{ reason_assign }}</h5>
    </div>

    <div class="row" *ngIf="messages_actions.length > 0">
      <div class="col-lg-12" style="padding-top: 2%">
        <h4>
          {{ languet.assignlist }}
        </h4>
      </div>

      <div class="col-lg-12" style="padding-top: 2%">
        <table mat-table [dataSource]="dataSourceActions">
          <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

          <!-- Position Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef class="headercell">
              {{ languef.numero }}
            </th>
            <td mat-cell *matCellDef="let element" class="tdcell">
              {{ element.id }}
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef class="headercell">
              {{ languef.status }}
            </th>
            <td mat-cell *matCellDef="let element" class="tdcell">
              {{ element.code }}
            </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="startDate">
            <th mat-header-cell *matHeaderCellDef class="headercell">
              {{ languef.date }}
            </th>
            <td mat-cell *matCellDef="let element" class="tdcell">
              {{ element.affectedDate }}
            </td>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="affectedto">
            <th mat-header-cell *matHeaderCellDef class="headercell">
              {{ languef.affectedto }}
            </th>
            <td mat-cell *matCellDef="let element" class="tdcell">
              {{ element.affectedToUser.userName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="headercell">
              {{ langued.actions }}
            </th>
            <td mat-cell *matCellDef="let element" class="tdcell">
              <button
                *ngIf="element.status != 'FINISHED'"
                matTooltip="{{ languef.edit }}"
                (click)="reassign(element)"
                style="border: 0px solid; background-color: #ffffff"
              >
                <div class="btn-action">
                  <mat-icon style="color: #1671b3">edit</mat-icon>
                </div>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsActions"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumnsActions"
          ></tr>
        </table>
      </div>
    </div>
  </div>
  <div
    *ngIf="status_sc == false"
    class="modal-footer"
    [ngStyle]="{ direction: direction }"
  >
    <button
      [disabled]="selectetuser == null || selectetassign == null || status_sc"
      (click)="Assign()"
      type="button"
      class="btn button4"
    >
      <div style="padding-top: 3px">
        <span class="assign"> {{ languet.assign }}</span>
      </div>
    </button>
  </div>
</ng-template>
<ng-template #SAsubmit>
  <div class="modal-body rounded-8" [ngStyle]="{ direction: direction }">
    <div style="text-align: center">
      <div>
        <span
          class="material-icons green-color"
          style="
            font-size: 100px;
            color: #41d96f;
            text-align: center;
            font-weight: 800;
          "
          >check_circle
        </span>
      </div>
      <div>
        <label
          style="
            font-family: Montserrat;
            font-size: 26px;
            padding-top: 2%;
            font-weight: bold;
            color: #073866;
          "
        >
          {{ languet.titremodal }}</label
        >
      </div>
      <div>
        <label
          style="
            width: 310px;
            font-size: 16px;
            font-weight: bold;
            padding-top: 40px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.77;
            letter-spacing: normal;
            text-align: center;
          "
        >
          {{ languet.descriptionsubmit }}</label
        >
      </div>
    </div>
  </div>
</ng-template>
