import { Country } from '@angular-material-extensions/select-country';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'src/Services/User/user.service';
import * as XLSX from 'xlsx';
import { TechnicalService } from '../Service/technical.service';
import Langue from './../../../../../assets/langue.json';
import { OrganisationService } from 'src/Services/Organisation/organisation.service';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.css'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: true },
    },
  ],
})
export class NewComponent implements OnInit {
  langue: any;
  direction: any;
  languef: any;
  langue_list: any[] = Langue;
  languea: any;
  languem: any;
  languet: any;
  organisation: any;
  id_tradecompany: any;
  deviselist: any[] = ['EUR', 'USD', 'TND'];
  devise: any;
  currencyRates: { [key: string]: number } = { 
    'USD': 3.1,  
    'EUR': 3.3,
    'TND': 1
  };
  currencyexchange: number;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private userservice: UserService,
    private datePipe: DatePipe,
    private technicalControlService: TechnicalService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private OrganisationService: OrganisationService
  ) {
    this.userservice.Getuserbyusername().subscribe((res: any) => {
      this.organisation = res['team']['organization'].name;
      this.id_tradecompany = res['team']['organization'].id;
    });
    if (localStorage.getItem('langue') != null) {
      this.langue = localStorage.getItem('langue') + '';
    } else {
      this.langue = 'AR';
    }

    if (this.langue == 'AR') {
      this.direction = 'rtl';
      this.languef = this.langue_list[0]['AR'].tablelist;
      this.languea = this.langue_list[0]['AR'].TC;
      this.languem = this.langue_list[0]['AR'].messageuser;
      this.languet = this.langue_list[0]['AR'].modal_text;
    }

    if (this.langue == 'EN') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['EN'].tablelist;
      this.languea = this.langue_list[0]['EN'].TC;
      this.languem = this.langue_list[0]['EN'].messageuser;
      this.languet = this.langue_list[0]['EN'].modal_text;
    }

    if (this.langue == 'FR') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['FR'].tablelist;
      this.languea = this.langue_list[0]['FR'].TC;
      this.languem = this.langue_list[0]['FR'].messageuser;
      this.languet = this.langue_list[0]['FR'].modal_text;
    }

    this.titleService.setTitle(this.languet.entete);
    this.Id_technical_update = this.route.snapshot.params['id'];

    if (this.Id_technical_update != undefined) {
      this.spinner.show();
      this.transactiontype = 'Update';
      this.getttechnicalcontrol();
    } else {
      this.etat = 'Save';
    }
  }
  transactiontype: any;

  Id_technical_update: any;
  FormGeneralinformation = this.formBuilder.group({
    selectedfdcc: ['', Validators.required],
    selectedbranch: ['', Validators.required],
    selectedtype: ['', Validators.required],
    asnm: [''],
    ac: [''],
  });

  FormIteminformation = this.formBuilder.group({
    selecteddevision: ['', Validators.required],
    qu: ['', Validators.required],
    selectedcodehs: ['', Validators.required],
    in: ['', Validators.required],
    isa: [''],
    isacu: [''],
    ce: ['', Validators.required],
    da: ['', Validators.required],
    selectedcodemt: [''],
  });

  FormAdditioninformation = this.formBuilder.group({
    nu: [''],
    pdd: [''],
    and: [''],
    lc: [''],
    bln: [''],
    cdn: [''],
    re: [''],
    selectedarrival: [''],

    ano: [''],
  });
  ngOnInit(): void {
    this.Getorganisation();

    this.FormIteminformation.get('isa')?.valueChanges.subscribe(() => this.calculateDinarAmount());
    this.FormIteminformation.get('isacu')?.valueChanges.subscribe((currency) => this.changeCurrency(currency));
  
  }
  branch_list: any[] = [
    { code: '1', name: 'branch name 1' },
    { code: '2', name: 'brnach name 2' },
    { code: '3', name: 'brnach name 3' },
  ];

  selectedbranch: any;
  branch_name: any;
  brnach_id: any;
  changebranch(event: any) {
    this.branch_name = event.name;
    this.brnach_id = event.code;
  }

  type_list: any[] = ['Import', 'Export'];
  selectedtype: any;

  type_name: any;

  changetype(event: any) {
    this.type_name = event;
  }

  devision_list: any[] = [
    { id: 1, name: 'Foodstuff' },
    { id: 2, name: 'Medicine' },
  ];

  id_devision: any;
  nom_devision: any;
  selecteddevision: any;
  changedevision(event: any) {
    this.id_devision = event.id;
    this.nom_devision = event.name;
  }
  codehs_id: any;
  codehs_nom: any;
  codehs_ref: any;
  codehs_list: any[] = [
    { code: '1', name: 'code hs 1', reference: '9652' },
    { code: '2', name: 'code hs 2', reference: '52145' },
  ];
  selectedcodehs: any;
  changecodehs(event: any) {
    this.codehs_id = event.code;
    this.codehs_nom = event.name;
    this.codehs_ref = event.reference;
  }

  changeCurrency(currency: string): void {
    this.devise = currency;
    this.currencyexchange = this.currencyRates[currency];
    this.FormIteminformation.get('ce')?.setValue(this.currencyexchange);
    this.calculateDinarAmount();
  }

  calculateDinarAmount(): void {
    const itemAmount = this.FormIteminformation.get('isa')?.value || 0;
    const dinarAmount = itemAmount * this.currencyexchange;
    this.FormIteminformation.get('da')?.setValue(dinarAmount);
  }

  codemt_list: any[] = [
    { code: 'code 1', name: 'Method 1' },
    { code: 'code 2', name: 'Method 2' },
  ];

  codemt_id: any;
  codemt_name: any;

  selectedcodemt: any;
  changecodemt(event: any) {
    this.codemt_id = event.code;
    this.codemt_name = event.name;
  }

  fdcc_list: any[] = [];
  selectedfdcc: any;
  name_fdcc: any;
  Id_org: any;
  changefdcc(event: any) {
    this.Id_org = event.id;
    this.name_fdcc = event.name;
  }

  arrival_list: any[] = ['arrival 1', 'arrival 2', 'arrival 3'];
  selectedarrival: any;
  arrival_name: any;
  changearrival(event: any) {
    this.arrival_name = event;
  }
  codecountry: any;
  namecountry: any;
  onCountrySelected(event: any) {
    this.codecountry = event.alpha2Code;
    this.namecountry = event.name;
  }

  Submit(content: any) {
    if ((this.Document_technical.etat = 'Save')) {
      this.etat = 'For confirmation';

      this.technicalControlService
        .submit(this.CreateFromTechnicalControlUpdate(), this.Id_org)
        .subscribe((res: any) => {
          this.open(content);

          setTimeout(() => {
            this.Go_list();
          }, 400);
        });
    } else {
      this.etat = 'CompleteInfos';

      this.technicalControlService
        .submit(this.CreateFromTechnicalControlUpdate(), this.Id_org)
        .subscribe((res: any) => {
          this.open(content);

          setTimeout(() => {
            this.Go_list();
          }, 400);
        });
    }
  }
  open(content: any) {
    this.modalService.open(content);
  }
  Save(content: any) {
    console.log("test " + this.selectedfdcc)
    console.log("test " + this.selectedbranch)
    console.log("test " + this.selectedcodehs)
    console.log("test " + this.selectedcodemt)
    this.technicalControlService
      .post(this.CreateFromTechnicalControl(), this.Id_org)
      .subscribe((res: any) => {
        this.open(content);
        this.FormAdditioninformation.reset();
        this.FormGeneralinformation.reset();
        this.FormIteminformation.reset();
      });
  }

  Go_list() {
    this.router.navigateByUrl('Customs/liste');
    localStorage.setItem('redirecturlC', '1');
    this.dimmis();
  }

  dimmis() {
    this.modalService.dismissAll();
  }

  Valuedorigin: Country = {
    name: '',
    alpha2Code: '',
    alpha3Code: '',
    numericCode: '',
    callingCode: '',
  };

  onFileChange(event: any) {
    this.spinner.show();
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>event.target;
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      if (ws['H2'] != undefined) {
        this.FormGeneralinformation.controls['ac'].setValue(ws['H2']['v']);
      }
      if (ws['H5'] != undefined) {
        this.FormGeneralinformation.controls['asnm'].setValue(ws['H5']['v']);
      }
      if (ws['D9'] != undefined) {
        this.FormIteminformation.controls['lc'].setValue(ws['D9']['v']);
      }
      if (ws['D10'] != undefined) {
        this.FormIteminformation.controls['in'].setValue(ws['D10']['v']);
      }

      if (ws['D11'] != undefined) {
        this.FormIteminformation.controls['qu'].setValue(ws['D11']['v']);
      }

      if (ws['D12']['v'] != undefined) {
        this.Valuedorigin = {
          name: '',
          alpha2Code: ws['D12']['v'],
          alpha3Code: '',
          numericCode: '',
          callingCode: '',
        };

        this.codecountry = ws['D12']['v'];
        this.namecountry = ws['E12']['v'];
      }

      if (ws['D14'] != undefined) {
        this.FormIteminformation.controls['isa'].setValue(ws['D14']['v']);
      }

      if (ws['D15'] != undefined) {
        this.FormIteminformation.controls['isacu'].setValue(ws['D15']['v']);
      }

      if (ws['D16'] != undefined) {
        this.FormIteminformation.controls['ce'].setValue(ws['D16']['v']);
      }

      if (ws['D17'] != undefined) {
        this.FormIteminformation.controls['da'].setValue(ws['D17']['v']);
      }

      if (ws['H14'] != undefined) {
        this.FormAdditioninformation.controls['nu'].setValue(ws['H14']['v']);
      }
      if (ws['H15'] != undefined) {
        this.FormAdditioninformation.controls['ano'].setValue(ws['H15']['v']);
      }
      if (ws['H16'] != undefined) {
        this.FormAdditioninformation.controls['bln'].setValue(ws['H16']['v']);
      }
      if (ws['H17'] != undefined) {
        this.FormAdditioninformation.controls['cdn'].setValue(ws['H17']['v']);
      }
    };

    setTimeout(() => {
      this.spinner.hide();
    }, 300);
  }

  etat = 'Save';

  CreateFromTechnicalControl(): any {
    return {
      applicationNo: Math.floor(Math.random() * 10000000),
      invoiceNo: this.Id_org,
      recv: null,
      applicationDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      clearanceTypeCode: this.type_name,
      branchCode: this.brnach_id,
      branchName: this.branch_name,
      inspectorName: this.name_fdcc,
      tradeCompanyCode: Math.floor(Math.random() * 10000000),
      tradeCompanyId: this.id_tradecompany,
      tradeCompanyName: this.organisation,
      agentCompanyName: this.FormGeneralinformation.controls['ac'].value,
      agentStaffame: this.FormGeneralinformation.controls['asnm'].value,
      hsCode: this.codehs_id,
      hsReferenceCode: this.codehs_ref,
      hsName: this.codehs_nom,
      itemName: this.FormIteminformation.controls['in'].value,
      quantity: this.FormIteminformation.controls['qu'].value,
      quantityUnitCode: this.FormIteminformation.controls['qu'].value,
      methodCode: this.codemt_id,
      methodName: this.codemt_name,
      countryCode: this.codecountry,
      countryName: this.namecountry,
      itemShippingAmount: this.FormIteminformation.controls['isa'].value,
      itemShippingAmountCurrencyUnit:
        this.FormIteminformation.controls['isacu'].value,
      currencyExchange: this.FormIteminformation.controls['ce'].value,
      dinarAmount: this.FormIteminformation.controls['da'].value,
      presentationDocumentDate: this.datePipe.transform(
        new Date(),
        'yyyy-MM-dd'
      ),
      arrivalNoticeDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      arrivalNoticeNo: this.FormAdditioninformation.controls['ano'].value,
      itemArrivalDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      itemArrivalPlace: this.selectedarrival,
      blnumber: this.FormAdditioninformation.controls['bln'].value,
      clearanceDeclarationNumber:
        this.FormAdditioninformation.controls['cdn'].value,
      remark: this.FormAdditioninformation.controls['re'].value,
      etat: this.etat,
    };
  }

  CreateFromTechnicalControlUpdate(): any {
    return {
      id: this.Id_technical_update,
      applicationNo: this.Document_technical.applicationNo,
      invoiceNo: this.Id_org,
      recv: null,
      applicationDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      clearanceTypeCode: this.type_name,
      branchCode: this.brnach_id,
      branchName: this.branch_name,
      inspectorName: this.name_fdcc,
      tradeCompanyCode: this.Document_technical.tradeCompanyCode,
      tradeCompanyId: this.Document_technical.tradeCompanyId,
      tradeCompanyName: this.Document_technical.tradeCompanyName,
      agentCompanyName: this.FormGeneralinformation.controls['ac'].value,
      agentStaffame: this.FormGeneralinformation.controls['asnm'].value,
      hsCode: this.codehs_id,
      hsReferenceCode: this.codehs_ref,
      hsName: this.codehs_nom,
      itemName: this.FormIteminformation.controls['in'].value,
      quantity: this.FormIteminformation.controls['qu'].value,
      quantityUnitCode: this.FormIteminformation.controls['qu'].value,
      methodCode: this.codemt_id,
      methodName: this.codemt_name,
      countryCode: this.codecountry,
      countryName: this.namecountry,
      itemShippingAmount: this.FormIteminformation.controls['isa'].value,
      itemShippingAmountCurrencyUnit:
        this.FormIteminformation.controls['isacu'].value,
      currencyExchange: this.FormIteminformation.controls['ce'].value,
      dinarAmount: this.FormIteminformation.controls['da'].value,
      presentationDocumentDate: this.datePipe.transform(
        new Date(),
        'yyyy-MM-dd'
      ),
      arrivalNoticeDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      arrivalNoticeNo: this.FormAdditioninformation.controls['ano'].value,
      itemArrivalDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      itemArrivalPlace: this.selectedarrival,
      blnumber: this.FormAdditioninformation.controls['bln'].value,
      clearanceDeclarationNumber:
        this.FormAdditioninformation.controls['cdn'].value,
      remark: this.FormAdditioninformation.controls['re'].value,
      etat: this.etat,
    };
  }
  list_organsation: any[] = [];
  Getorganisation() {
    this.OrganisationService.GetAll().subscribe((res: any) => {
      this.list_organsation = [];
      this.fdcc_list = [];
      this.list_organsation = res;

      this.list_organsation = this.list_organsation.filter(
        (e: any) => e.profil == 'FDCC'
      );
      this.fdcc_list = this.list_organsation;
    });
  }

  remlirform() {
    this.Id_org = this.Document_technical.invoiceNo;

    this.FormAdditioninformation.controls['ano'].setValue(
      this.Document_technical.arrivalNoticeNo
    );
    this.FormGeneralinformation.controls['selectedfdcc'].setValue(
      this.Document_technical.inspectorName
    );
    this.FormGeneralinformation.controls['asnm'].setValue(
      this.Document_technical.agentStaffame
    );
    this.FormGeneralinformation.controls['ac'].setValue(
      this.Document_technical.agentCompanyName
    );
    this.FormGeneralinformation.controls['selectedtype'].setValue(
      this.Document_technical.clearanceTypeCode
    );
    this.type_name = this.Document_technical.clearanceTypeCode;

    this.FormGeneralinformation.controls['selectedbranch'].setValue(
      this.Document_technical.branchName
    );
    this.branch_name = this.Document_technical.branchName;
    this.brnach_id = this.Document_technical.branchCode;

    this.FormIteminformation.controls['selectedcodehs'].setValue(
      this.Document_technical.hsName
    );

    this.codehs_nom = this.Document_technical.hsName;
    this.codehs_id = this.Document_technical.hsCode;
    this.codehs_ref = this.Document_technical.hsReferenceCode;

    this.FormIteminformation.controls['in'].setValue(
      this.Document_technical.itemName
    );
    this.FormIteminformation.controls['qu'].setValue(
      this.Document_technical.quantity
    );
    this.FormIteminformation.controls['selectedcodemt'].setValue(
      this.Document_technical.methodName
    );

    this.codemt_name = this.Document_technical.methodName;
    this.codemt_id = this.Document_technical.methodCode;

    this.Valuedorigin = {
      name: '',
      alpha2Code: this.Document_technical.countryCode,
      alpha3Code: '',
      numericCode: '',
      callingCode: '',
    };

    this.codecountry = this.Document_technical.countryCode;

    this.namecountry = this.Document_technical.countryName;

    this.FormIteminformation.controls['isa'].setValue(
      this.Document_technical.itemShippingAmount
    );
    this.FormIteminformation.controls['isacu'].setValue(
      this.Document_technical.itemShippingAmountCurrencyUnit
    );
    this.FormIteminformation.controls['ce'].setValue(
      this.Document_technical.currencyExchange
    );
    this.FormIteminformation.controls['da'].setValue(
      this.Document_technical.dinarAmount
    );

    this.FormAdditioninformation.controls['ano'].setValue(
      this.Document_technical.arrivalNoticeNo
    );
    this.FormAdditioninformation.controls['selectedarrival'].setValue(
      this.Document_technical.actualArrivalPlace
    );

    this.arrival_name = this.Document_technical.actualArrivalPlace;

    this.FormAdditioninformation.controls['bln'].setValue(
      this.Document_technical.blnumber
    );
    this.FormAdditioninformation.controls['cdn'].setValue(
      this.Document_technical.clearanceDeclarationNumber
    );
    this.FormAdditioninformation.controls['re'].setValue(
      this.Document_technical.remark
    );
    this.FormAdditioninformation.controls['ano'].setValue(
      this.Document_technical.arrivalNoticeNo
    );
  }

  Document_technical: any;

  getttechnicalcontrol() {
    this.technicalControlService
      .GetByid(this.Id_technical_update)
      .subscribe((res: any) => {
        this.spinner.hide();
        this.Document_technical = res;

        this.remlirform();
      });
  }

  ngselectinvalid: any = '';
  formSubmitted: boolean = false;
  formSubmitted1: boolean = false;

  next(): void {
    this.ngselectinvalid = this.languet.required;

    if (this.FormGeneralinformation.invalid) {
      this.formSubmitted = true;
    } else {
      this.formSubmitted = false;
    }
  }

  next1(): void {
    this.ngselectinvalid = this.languet.required;

    if (this.FormIteminformation.invalid) {
      this.formSubmitted1 = true;
    } else {
      this.formSubmitted1 = false;
    }
  }
}
