import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VesselService {
  url: any;

  Spring_serveur: string = 'api/Vessel/';
  constructor(private router: Router, private http: HttpClient) {
    this.url = environment.Url + this.Spring_serveur;
  }
  public Get() {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.get<any>(`${this.url}`, {
      headers: headers1,
    });
  }

  public post(formData: any) {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(this.url + 'saveArrVessel', formData, {
      headers: headers1,
    });
  }
  public update(formData: any, id: any) {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.put<any>(this.url + 'updateVessel', formData, {
      headers: headers1,
    });
  }
  public delete(id: any) {
    return this.http.delete<any>(`${this.url + 'Vessel/' + '' + id + '/'}`);
  }
}
