import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Langue from "../../../assets/langue.json";
import { environment } from 'src/environments/environment';
import { ViewportScroller } from '@angular/common';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
  langue_list: any[] = Langue;
  @Input() item: string;

  languef: any;
  langue = 'AR';
  //direction = 'rtl';
  direction: any;
  id_langue: any;
  langue_nom: any;
  langueh: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private viewportScroller: ViewportScroller
  ) {

    if (localStorage.getItem('langue') != null) {
      this.langue = localStorage.getItem('langue') + '';
    } else {
      this.langue = 'EN';
    }

    if (this.langue == 'AR') {
      this.langue_nom = 'Ar';
      this.direction = 'rtl';
      this.languef = this.langue_list[0]['AR'].login;
      this.langueh = this.langue_list[0]['AR'].header;
    }

    if (this.langue == 'EN') {
      this.langue_nom = 'En';
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['EN'].login;
      this.langueh = this.langue_list[0]['EN'].header;
    }

    if (this.langue == 'FR') {
      this.langue_nom = 'Fr';
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['FR'].login;
      this.langueh = this.langue_list[0]['FR'].header;
    }
  }
  public onClickScroll(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }


  changelangue(langue: any) {
    localStorage.setItem('langue', langue);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }
  ngOnInit(): void {
  }
  
  navigate(route:any){
    this.router.navigateByUrl(route);
  }
  isMenuVisible = false;

  toggleMenu() {
    this.isMenuVisible = !this.isMenuVisible;
  }

}
