<link
  rel="stylesheet"
  href="https://use.fontawesome.com/releases/v5.5.0/css/all.css"
  integrity="sha384-B4dIYHKNBt8Bc12p+WXckhzcICo0wtJAoU8YZTY5qE0Id1GSseTk6S+L3BlXeVIU"
  crossorigin="anonymous"
/>
<script
  src="https://kit.fontawesome.com/8f702b3b95.js"
  crossorigin="anonymous"
></script>
<div class="c1" [ngStyle]="{ direction: direction }">
  <div class="Trac-2081"></div>

  <div>
    <h2 class="titre-page">{{ languee.titre_r }}</h2>
    <div class="about-border"></div>
  </div>
</div>

<div class="row" [ngStyle]="{ direction: direction }">
  <div class="col-lg-12">
    <mat-stepper linear #stepper>
      <mat-step
        [stepControl]="Form1"
        label="{{ languee.informationgeneral }} "
        state="form"
      >
        <ng-template matStepperIcon="edit">
          <mat-icon>check_circle</mat-icon>
        </ng-template>
        <div style="padding-top: 5%">
          <div
            class="titre_mobile"
            style="text-align: center; padding-bottom: 4%"
          >
            <label style="min-width: 100px; font-size: 18px; font-weight: 800"
              >{{ languee.informationgeneral }}
            </label>
          </div>
          <form [formGroup]="Form1">
            <div class="row">
              <div class="col-lg-4">
                <label class="titrechamp">{{ languee.invoicenumber }} </label>

                <div style="padding-top: 1%">
                  <input
                    required
                    matInput
                    class="form-control mb-4"
                    type="text"
                    formControlName="invoicenumber"
                  />
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp">{{ languee.invoicedate }} </label>

                <div style="padding-top: 1%">
                  <div>
                    <mat-form-field>
                      <input
                        required
                        matInput
                        formControlName="dateinvoice"
                        [matDatepicker]="picker1"
                        (dateInput)="onchangedateinvoice($event.value)"
                        style="color: #1d3a66"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker1"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <label class="titrechamp">
                  {{ languee.chamberofcommerce }}</label
                >

                <div>
                  <ng-select
                    required
                    [items]="chamberofcommerce_list"
                    (change)="changechamberofcommerce($event)"
                    bindLabel="name"
                    placeholder=""
                    [(ngModel)]="selectedchamberofcommerce"
                    formControlName="selectedchamberofcommerce"
                  >
                  </ng-select>
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp">{{ languee.consigneename }} </label>

                <div style="padding-top: 1%">
                  <input
                    required
                    matInput
                    class="form-control mb-4"
                    type="text"
                    formControlName="consigneename"
                  />
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp"
                  >{{ languee.consigneeaddress }}
                </label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    required
                    class="form-control mb-4"
                    type="text"
                    formControlName="consigneeaddress"
                  />
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp">{{ languee.producername }} </label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    required
                    class="form-control mb-4"
                    type="text"
                    formControlName="producername"
                  />
                </div>
              </div>
              <div class="col-lg-4">
                <label class="titrechamp">{{ languee.produceraddress }} </label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    required
                    class="form-control mb-4"
                    type="text"
                    formControlName="produceraddress"
                  />
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp">{{ languee.countryoforigin }} </label>

                <div style="padding-top: 1%">
                  <mat-select-country
                    [value]="Valuedorigin"
                    placeHolder=""
                    (onCountrySelected)="onCountrySelected($event)"
                  >
                  </mat-select-country>
                </div>
              </div>
              <div class="col-lg-4">
                <label class="titrechamp"
                  >{{ languee.countryofmanifacture }}
                </label>

                <div style="padding-top: 1%">
                  <mat-select-country
                    [value]="Valuedmanifacture"
                    placeHolder=""
                    (onCountrySelected)="onCountrySelectedmanifacture($event)"
                  >
                  </mat-select-country>
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp"
                  >{{ languee.meanstransportandroute }}
                </label>

                <div style="padding-top: 1%">
                  <input
                    required
                    matInput
                    class="form-control mb-4"
                    type="text"
                    formControlName="meanstransportandroute"
                  />
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp">{{ languee.totalvalue }} </label>

                <div style="padding-top: 1%">
                  <input
                    readonly
                    matInput
                    class="form-control mb-4"
                    type="Number"
                    formControlName="totalvalue"
                  />
                </div>
              </div>

              <div class="col-lg-4" style="padding-top: 3%">
                <label>{{ languee.cumulation }}</label>
                <mat-radio-group
                  style="padding: 0% 2% 0% 2%"
                  aria-label=" languee.cumulation "
                  (change)="changecumulation($event)"
                >
                  <mat-radio-button
                    style="padding: 0% 2% 0% 2%"
                    value="yes"
                    style="color: black"
                    checked="checked"
                    >{{ languee.yes }}
                  </mat-radio-button>
                  <mat-radio-button
                    value="no"
                    style="padding: 0% 2% 0% 2%; color: black"
                    >{{ languee.no }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </form>
        </div>
        <div>
          <div
            class="div_next"
            [ngStyle]="{
              float: langue === 'AR' ? 'left' : 'right'
            }"
          >
            <button class="buttonnext" mat-button matStepperNext>
              {{ languee.next }}
            </button>
          </div>
        </div>
      </mat-step>
      <mat-step label=" {{ languee.items }}">
        <ng-template matStepperIcon="edit">
          <mat-icon>check_circle</mat-icon>
        </ng-template>
        <div style="padding-top: 5%">
          <div
            class="titre_mobile"
            style="text-align: center; padding-bottom: 4%"
          >
            <label style="min-width: 100px; font-size: 18px; font-weight: 800"
              >{{ languee.items }}
            </label>
          </div>

          <div class="steppclass">
            <div style="text-align: center; padding: 2% 35% 1% 40%">
              <div style="padding-top: 1%">
                <button
                  (click)="open_items(content2)"
                  type="button"
                  class="btn button_file"
                  style="display: flex"
                >
                  <div>
                    <mat-icon class="iconadd">add</mat-icon>
                  </div>
                  <div style="padding-top: 3px; padding-left: 2%">
                    <span class="article"> {{ languee.newitem }} </span>
                  </div>
                </button>
              </div>
            </div>
            <div class="div_table">
              <table mat-table [dataSource]="dataSource">
                <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

                <!-- Name Column -->
                <ng-container
                  matColumnDef="marksandnumberofpackage"
                  class="mobile_container"
                >
                  <th mat-header-cell *matHeaderCellDef class="headercell">
                    {{ languee.marksandnumberofpackage }}
                  </th>
                  <td mat-cell *matCellDef="let element" class="tdcell">
                    {{ element.marksAndNumberOfPackage }}
                  </td>
                </ng-container>

                <!-- Désignation Column -->
                <ng-container matColumnDef="numberandkindofpackage">
                  <th mat-header-cell *matHeaderCellDef class="headercell1">
                    {{ languee.numberandkindofpackage }}
                  </th>
                  <td mat-cell *matCellDef="let element" class="tdcell1">
                    {{ element.numberAndKindOfPackage }}
                  </td>
                </ng-container>

                <!-- PFN Column -->
                <ng-container matColumnDef="netweight">
                  <th mat-header-cell *matHeaderCellDef class="headercell1">
                    {{ languee.netweight }}
                  </th>
                  <td mat-cell *matCellDef="let element" class="tdcell1">
                    {{ element.netWeight }}
                  </td>
                </ng-container>

                <!-- PFN Code QCS -->
                <ng-container matColumnDef="grossweight">
                  <th mat-header-cell *matHeaderCellDef class="headercell1">
                    {{ languee.grossweight }}
                  </th>
                  <td mat-cell *matCellDef="let element" class="tdcell1">
                    {{ element.grossWeight }}
                  </td>
                </ng-container>

                <!-- PFN QCS -->
                <ng-container matColumnDef="quantity">
                  <th mat-header-cell *matHeaderCellDef class="headercell">
                    {{ languee.quantity }}
                  </th>
                  <td mat-cell *matCellDef="let element" class="tdcell">
                    {{ element.quantity }}
                  </td>
                </ng-container>

                <!-- PFN QCS -->
                <ng-container matColumnDef="proceedsforeign">
                  <th mat-header-cell *matHeaderCellDef class="headercell1">
                    {{ languee.proceedsforeign }}
                  </th>
                  <td mat-cell *matCellDef="let element" class="tdcell1">
                    {{ element.proceedsForeign }}
                  </td>
                </ng-container>

                <!-- PFN Pays Origine -->
                <ng-container matColumnDef="currencyvalue">
                  <th mat-header-cell *matHeaderCellDef class="headercell">
                    {{ languee.currencyvalue }}
                  </th>
                  <td mat-cell *matCellDef="let element" class="tdcell">
                    {{ element.currencyValue }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef class="headercell">
                    {{ languee.currencyvalue }}
                  </th>
                  <td mat-cell *matCellDef="let row" class="tdcell_actions">
                    <button
                      matTooltip="{{ languef.view }}"
                      (click)="Arr_view_Elements(CoElements, row)"
                      style="border: 0px solid; background-color: #ffffff"
                    >
                      <div class="btn-action">
                        <mat-icon style="color: rgb(110, 110, 110)"
                          >remove_red_eye</mat-icon
                        >
                      </div>
                    </button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></tr>
              </table>
            </div>
          </div>
        </div>
        <div>
          <div
            class="div_back"
            [ngStyle]="{
              float: langue === 'AR' ? 'right' : 'left'
            }"
          >
            <button class="buttonback" mat-button matStepperPrevious>
              {{ languee.previous }}
            </button>
          </div>
          <div
            class="div_next"
            [ngStyle]="{
              float: langue === 'AR' ? 'left' : 'right'
            }"
          >
            <button class="buttonnext" mat-button matStepperNext>
              {{ languee.next }}
            </button>
          </div>
        </div>
      </mat-step>

      <mat-step label="{{ languee.attachments }}">
        <ng-template matStepperIcon="edit">
          <mat-icon>check_circle</mat-icon>
        </ng-template>
        <div style="padding-top: 5%">
          <div
            class="titre_mobile"
            style="text-align: center; padding-bottom: 4%"
          >
            <label style="min-width: 100px; font-size: 18px; font-weight: 800"
              >{{ languee.attachments }}
            </label>
          </div>
          <div style="text-align: center; padding: 2% 35% 1% 40%">
            <div style="padding-top: 1%">
              <button
                (click)="open(content1)"
                type="button"
                class="btn button_file"
                style="display: flex"
              >
                <div>
                  <mat-icon class="iconadd">add</mat-icon>
                </div>
                <div style="padding-top: 3px">
                  <span class="piece"> {{ languee.newfile }} </span>
                </div>
              </button>
            </div>
          </div>
          <div class="row" style="margin: 1% 1% 1% 1%">
            <div class="div_table">
              <table mat-table [dataSource]="dataSourcePieces">
                <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

                <!-- Position Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef class="headercell">
                    {{ languee.filename }}
                  </th>
                  <td mat-cell *matCellDef="let element" class="tdcell">
                    {{ element.name }}
                  </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="file">
                  <th mat-header-cell *matHeaderCellDef class="headercell">
                    {{ languee.file }}
                  </th>
                  <td mat-cell *matCellDef="let element" class="tdcell">
                    <a
                      *ngIf="
                        element.attachment && element.attachmentContentType
                      "
                      (click)="
                        (element.id != null &&
                          getattachmentsUpdateDeletedIds().indexOf(element.id) >
                            -1) ||
                        element.id == null
                          ? openFile(
                              element.attachment,
                              element.attachmentContentType
                            )
                          : openAttachment(element.attachment)
                      "
                      style="text-decoration: none; cursor: pointer"
                      >Open</a
                    >
                    <span *ngIf="element.attachment">
                      {{ element.originalFilename }},
                      {{ element.fileSize }}</span
                    >
                  </td>
                </ng-container>
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef class="headercell">
                    {{ languee.actions }}
                  </th>
                  <td mat-cell *matCellDef="let element" class="tdcell">
                    <!-- <button
                      matTooltip="{{ languef.view }}"
                      (click)="viewPDF(file, element)"
                      style="border: 0px solid; background-color: #ffffff"
                    >
                      <div class="btn-action">
                        <mat-icon style="color: rgb(110, 110, 110)"
                          >remove_red_eye</mat-icon
                        >
                      </div>
                    </button> -->
                    <button
                      matTooltip="{{ languef.edit }}"
                      (click)="open(content1); updateFileMode(element)"
                      style="border: 0px solid; background-color: #ffffff"
                    >
                      <div class="btn-action">
                        <mat-icon style="color: #2c7422">edit</mat-icon>
                      </div>
                    </button>
                    <button
                      matTooltip="{{ languef.delete }}"
                      (click)="deleteAttachment(element)"
                      style="border: 0px solid; background-color: #ffffff"
                    >
                      <div class="btn-action">
                        <mat-icon style="color: #742222"
                          >delete_forever</mat-icon
                        >
                      </div>
                    </button>
                  </td>
                </ng-container>
                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumnsPieces"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumnsPieces"
                ></tr>
              </table>
            </div>
          </div>
        </div>
        <div>
          <div
            class="div_back"
            [ngStyle]="{
              float: langue === 'AR' ? 'right' : 'left'
            }"
          >
            <button class="buttonback" mat-button matStepperPrevious>
              {{ languee.previous }}
            </button>
          </div>
        </div>
      </mat-step>
    </mat-stepper>
  </div>

  <div class="col-lg-12">
    <div class="col-lg-12 actions">
      <!--    <div class="btnmobile">
        <button
          type="button"
          [disabled]="Form1.invalid"
          class="btn buttonsave"
          (click)="Submit(content3)"
        >
          <label class="labsave"> {{ languee.submit }}</label>
        </button>
      </div> -->
      <div class="btnmobile">
        <button
          type="button"
          [disabled]="Form1.invalid"
          class="btn buttonsave"
          (click)="Save(content4)"
        >
          <label class="labsave"> {{ languee.save }}</label>
        </button>
      </div>

      <div class="btnmobile">
        <input
          style="display: none"
          type="file"
          #fileInput
          (change)="onFileChange($event)"
          name="uploadFile"
          multiple="multiple"
          #hiddenfileinput
        />
        <button
          type="button"
          class="btn buttonupload"
          (click)="hiddenfileinput.click()"
        >
          <label class="labupload"> {{ languee.upload }}</label>
        </button>
      </div>
      <div class="btnmobile">
        <button (click)="Go_list()" type="button" class="btn buttonlist">
          <div style="padding-top: 3px">
            <label class="lablist"> {{ languee.list }} </label>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #content3>
  <div class="modal-body rounded-8" [ngStyle]="{ direction: direction }">
    <div style="text-align: center">
      <div>
        <span
          class="material-icons green-color"
          style="
            font-size: 100px;
            color: #41d96f;
            text-align: center;
            font-weight: 800;
          "
          >check_circle
        </span>
      </div>
      <div>
        <label
          style="
            font-family: Montserrat;
            font-size: 26px;
            padding-top: 2%;
            font-weight: bold;
            color: #073866;
          "
        >
          {{ languet.titremodal }}</label
        >
      </div>
      <div>
        <label
          style="
            width: 310px;
            font-size: 16px;
            font-weight: bold;
            padding-top: 40px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.77;
            letter-spacing: normal;
            text-align: center;
          "
        >
          {{ languet.descriptionsubmitco }}</label
        >
      </div>
    </div>
  </div>
</ng-template>

<ng-template #content4>
  <div class="modal-body rounded-8" [ngStyle]="{ direction: direction }">
    <div style="text-align: center">
      <div>
        <span
          class="material-icons green-color"
          style="
            font-size: 100px;
            color: #41d96f;
            text-align: center;
            font-weight: 800;
          "
          >check_circle
        </span>
      </div>
      <div>
        <label
          style="
            font-family: Montserrat;
            font-size: 26px;
            padding-top: 2%;
            font-weight: bold;
            color: #073866;
          "
        >
          {{ languet.titremodal }}</label
        >
      </div>
      <div>
        <label
          style="
            width: 310px;
            font-size: 16px;
            font-weight: bold;
            padding-top: 40px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.77;
            letter-spacing: normal;
            text-align: center;
          "
        >
          {{ languet.descriptionsaveco }}</label
        >
      </div>
      <div class="text-center" style="padding-bottom: 2%; padding-top: 6%">
        <button type="button" class="btn button2" (click)="Go_list()">
          <span class="Save"> {{ languet.list }}</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.5.1/jspdf.umd.min.js"></script>
<ng-template #content1 let-modal>
  <div class="modal-header text-center">
    <h4 class="modal-title w-100" id="modal-basic-title">
      {{ languee.newfile }}
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click'); isUpdatingAttachment = false"
    ></button>
  </div>
  <div class="modal-body" [ngStyle]="{ direction: direction }">
    <div class="bodymodal_file">
      <form [formGroup]="FormPieces" autocomplete="off" novalidate form>
        <div class="row">
          <div class="col-lg-12">
            <div>
              <label class="titrechamp"> {{ languee.filename }} </label>
            </div>
            <div style="padding-top: 1%">
              <input
                required
                type="text"
                mdbInput
                class="form-control mb-4"
                formControlName="name"
              />
            </div>
          </div>

          <div class="col-lg-12">
            <div>
              <label class="titrechamp"> {{ languee.file }} </label>
            </div>
            <div
              class="col-3 mb-2 form-text text-danger clearfix"
              style="padding-right: 0"
              *ngIf="FormPieces.get('file')!.value != null"
            >
              <a
                style="
                  padding-bottom: 0;
                  margin-bottom: 0;
                  text-decoration: none;
                  cursor: pointer;
                "
                class="pull-start"
                (click)="
                  (FormPieces.get('id')!.value != null &&
                    getattachmentsUpdateDeletedIds().indexOf(
                      FormPieces.get('id')!.value
                    ) > -1) ||
                  FormPieces.get('id')!.value == null ||
                  currentFile
                    ? openFile(
                        FormPieces.get('file')!.value,
                        FormPieces.get('attachmentContentType')!.value
                      )
                    : openAttachment(FormPieces.get('file')!.value)
                "
                >Open</a
              >
              <br />
              <span class="pull-start">{{
                currentFile
                  ? currentFile.name
                  : FormPieces.get("originalFilename")!.value
              }}</span
              ><br />
              <span class="pull-start"
                >{{
                  currentFile
                    ? currentFile.type
                    : FormPieces.get("attachmentContentType")!.value
                }},
                {{
                  currentFile
                    ? currentFile.size
                    : FormPieces.get("fileSize")!.value
                }}</span
              >
              <button
                style="
                  border-radius: 33%;
                  width: 28px;
                  height: 28px;
                  padding: 0;
                  margin-left: 2px;
                  background-color: #f3969a;
                  border-color: #f3969a;
                "
                type="button"
                (click)="
                  FormPieces.patchValue({ file: null });
                  FormPieces.patchValue({ attachmentContentType: null })
                "
                class="btn btn-secondary btn-xs pull-end"
              >
                <div class="btn-action">
                  <mat-icon>clear</mat-icon>
                </div>
              </button>
            </div>
            <a
              class="dropdown-item"
              (click)="collapseNavbar(); loadRecentFiles()"
            >
              <mat-icon>file_copy</mat-icon>
              <span>Recent files</span>
            </a>
            <!-- <a
          class="dropdown-item"
          (click)="collapseNavbar(); loadAllAttachmentByOriginFilename(FormPieces.get('originalFilename')!.value)"
        >
          <span>Recent files By name</span>
        </a> -->
            <a
              class="dropdown-item"
              (click)="collapseNavbar(); loadFromComputer()"
            >
              <!-- <fa-icon icon="computer"></fa-icon>  -->
              <mat-icon>computer</mat-icon>
              <span>Load from your computer</span>
            </a>

            <div [hidden]="isRecentFiles == false" class="col-lg-4 mb-2">
              <!-- Recent file -->

              <ng-select
                formControlName="attachmentRecentlyUploaded"
                (change)="recentFileUpdateForm($event)"
                bindLabel="attachment"
                placeholder=""
              >
                <!-- [(ngModel)]="selectedattachment" -->
                <ng-option
                  *ngFor="
                    let attachmentRecentlyUploaded of attachmentsRecentlyUploaded
                  "
                  [value]="attachmentRecentlyUploaded"
                >
                  <span>{{ attachmentRecentlyUploaded.originalFilename }}</span>
                </ng-option>
              </ng-select>
              <!-- <ul class="navbar-nav ms-auto">
              <li
                ngbDropdown
                class="nav-item dropdown pointer"
                display="dynamic"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <a
                  #recentFiles
                  style="color: rgb(99, 97, 97)"
                  class="nav-link dropdown-toggle"
                  ngbDropdownToggle
                  href="javascript:void(0);"
                  id="entity-menu"
                  data-cy="entity"
                >
                  <span>
                    <span>Recent Files</span>
                  </span>
                </a>
                <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="entity-menu">
                  <li *ngIf="attachmentsRecentlyUploaded?.length === 0">
                    <span class="ms-2"> You don't have files!</span>
                  </li>
                  <li *ngFor="let attachmentRecentlyUploaded of attachmentsRecentlyUploaded">
                    <a class="dropdown-item" (click)="collapseNavbar(); recentFileUpdateForm(attachmentRecentlyUploaded)">
                      
                      <span>{{ attachmentRecentlyUploaded.originalFilename }}</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul> -->
              <!-- <select
            class="form-select"
            name="attachmentRecentlyUploaded"
            formControlName="attachmentRecentlyUploaded"
            id="field_attachmentRecentlyUploaded"
            data-cy="attachmentRecentlyUploaded"
          >
            <option *ngFor="let attachmentRecentlyUploaded of attachmentsRecentlyUploaded" [value]="attachmentRecentlyUploaded">
              <fa-icon icon="arrow-circle-down" style="color:#5cb85c;"></fa-icon>
              {{ attachmentRecentlyUploaded.originalFilename  }}
            </option>
          </select> -->
              <!-- Recent file -->
            </div>

            <div [hidden]="isRecentFilesByName == false" class="col-lg-3 mb-2">
              <!-- Recent file -->

              <ul class="navbar-nav ms-auto">
                <li
                  ngbDropdown
                  class="nav-item dropdown pointer"
                  display="dynamic"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <!-- #recentFilesByName -->
                  <a
                    style="color: rgb(99, 97, 97); padding: 0"
                    class="nav-link ngbDropdownToggle"
                    ngbDropdownToggle
                    href="javascript:void(0);"
                    id="entity-menu"
                    data-cy="entity"
                  >
                    <!-- <fa-icon icon="th-list"></fa-icon> -->
                    <input
                      (change)="
                        loadAllAttachmentByOriginFilename(
                          FormPieces.get('originalFilename')!.value
                        )
                      "
                      class="form-control"
                      style="display: inline-block"
                      name="originalFilename"
                      id="field_originalFilename"
                      formControlName="originalFilename"
                    />

                    <!-- (change)="loadAllAttachmentByOriginFilename(FormPieces.get('originalFilename')!.value)" -->

                    <!-- <button class="btn btn-secondary btn-xs pull-end" (click)="loadAllAttachmentByOriginFilename(FormPieces.get('originalFilename')!.value)"><fa-icon icon="times"></fa-icon></button> -->
                  </a>
                  <ul
                    class="dropdown-menu"
                    ngbDropdownMenu
                    aria-labelledby="entity-menu"
                  >
                    <li *ngIf="attachmentsRecentlyUploaded?.length === 0">
                      <span class="ms-2"> You don't have files!</span>
                    </li>
                    <li
                      *ngFor="
                        let attachmentRecentlyUploaded of attachmentsRecentlyUploadedByName
                      "
                    >
                      <a
                        class="dropdown-item"
                        (click)="
                          collapseNavbar();
                          recentFileUpdateForm(attachmentRecentlyUploaded)
                        "
                      >
                        <!-- <fa-icon
                        [icon]="getFileExtension(attachmentRecentlyUploaded.originalFilename ?? '')"
                        [fixedWidth]="true"
                      ></fa-icon> -->
                        <span>{{
                          attachmentRecentlyUploaded.originalFilename
                        }}</span>
                      </a>
                    </li>

                    <!-- jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here -->
                  </ul>
                </li>
              </ul>
              <!-- <select
            class="form-select"
            name="attachmentRecentlyUploaded"
            formControlName="attachmentRecentlyUploaded"
            id="field_attachmentRecentlyUploaded"
            data-cy="attachmentRecentlyUploaded"
          >
            <option *ngFor="let attachmentRecentlyUploaded of attachmentsRecentlyUploaded" [value]="attachmentRecentlyUploaded">
              <fa-icon icon="arrow-circle-down" style="color:#5cb85c;"></fa-icon>
              {{ attachmentRecentlyUploaded.originalFilename  }}
            </option>
          </select> -->
              <!-- Recent file -->
            </div>
            <div [hidden]="isLoadComputer == false" class="col-lg-4 mb-2">
              <!-- <label class="form-label" jhiTranslate="eserviceJHipsterApp.attachment.attachment" for="field_attachment">Attachment</label> -->
              <input
                #computer
                (change)="
                  setFileData($event, 'file', false); onChangefile($event)
                "
                type="file"
                class="form-control"
                id="file_file"
                data-cy="file"
                style="font-size: 15px; padding-left: 2%"
              />

              <input
                type="hidden"
                class="form-control"
                name="file"
                id="field_file"
                data-cy="file"
                formControlName="file"
              />
              <input
                type="hidden"
                class="form-control"
                name="attachmentContentType"
                id="field_attachmentContentType"
                formControlName="attachmentContentType"
              />
            </div>
            <!-- <div style="padding-top: 1%">
              <input
                (change)="
                  setFileData($event, 'file', false); onChangefile($event)
                "
                type="file"
                class="form-control"
                id="file_file"
                data-cy="file"
                style="font-size: 15px; padding-left: 2%"
              />
              <input
                type="hidden"
                class="form-control"
                name="file"
                id="field_file"
                data-cy="file"
                formControlName="file"
              />
              <input
                type="hidden"
                class="form-control"
                name="attachmentContentType"
                id="field_attachmentContentType"
                formControlName="attachmentContentType"
              />
              
            </div> -->
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="text-center" style="padding-bottom: 2%">
    <button
      [disabled]="FormPieces.invalid"
      type="button"
      class="btn button_file_save"
      (click)="isUpdatingAttachment == false ? file_add() : file_update()"
    >
      <span class="save"> {{ languee.save }}</span>
    </button>
  </div>
</ng-template>

<ng-template #content2 let-modal>
  <div class="modal-header text-center">
    <h4 class="modal-title w-100" id="modal-basic-title">
      {{ languee.newitem }}
    </h4>

    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body" [ngStyle]="{ direction: direction }">
    <div class="bodymodal_file">
      <form [formGroup]="FormArticle" autocomplete="off" novalidate form>
        <div class="row">
          <div style="padding-top: 2%" class="row">
            <div class="col-lg-4">
              <div>
                <label class="titrechamp">
                  {{ languee.marksandnumberofpackage }}</label
                >
              </div>
              <div style="padding-top: 1%">
                <input
                  required
                  type="text"
                  mdbInput
                  class="form-control mb-4"
                  formControlName="marksandnumberofpackage"
                />
              </div>
            </div>
            <div class="col-lg-4">
              <div>
                <label class="titrechamp"
                  >{{ languee.numberandkindofpackage }}
                </label>
              </div>
              <div style="padding-top: 1%">
                <input
                  required
                  type="text"
                  mdbInput
                  class="form-control mb-4"
                  formControlName="numberandkindofpackage"
                />
              </div>
            </div>

            <div class="col-lg-4">
              <div>
                <label class="titrechamp"> {{ languee.netweight }} </label>
              </div>
              <div style="padding-top: 1%">
                <input
                  required
                  type="number"
                  mdbInput
                  class="form-control mb-4"
                  formControlName="netweight"
                />
              </div>
            </div>
            <div class="col-lg-4">
              <div>
                <label class="titrechamp"> {{ languee.grossweight }} </label>
              </div>
              <div style="padding-top: 1%">
                <input
                  required
                  type="number"
                  mdbInput
                  class="form-control mb-4"
                  formControlName="grossweight"
                />
              </div>
            </div>
            <div class="col-lg-4">
              <div>
                <label class="titrechamp"> {{ languee.quantity }} </label>
              </div>
              <div style="padding-top: 1%">
                <input
                  required
                  type="number"
                  mdbInput
                  class="form-control mb-4"
                  formControlName="quantity"
                />
              </div>
            </div>

            <div class="col-lg-4">
              <div>
                <label class="titrechamp">
                  {{ languee.proceedsforeign }}
                </label>
              </div>
              <div style="padding-top: 1%">
                <input
                  required
                  type="number"
                  mdbInput
                  class="form-control mb-4"
                  formControlName="proceedsforeign"
                />
              </div>
            </div>

            <div class="col-lg-4">
              <div>
                <label class="titrechamp"> {{ languee.currencyvalue }} </label>
              </div>
              <div style="padding-top: 1%">
                <input
                  required
                  type="number"
                  mdbInput
                  class="form-control mb-4"
                  formControlName="currencyvalue"
                />
              </div>
            </div>

            <div class="col-lg-4">
              <div>
                <label class="titrechamp">
                  {{ languee.finalcostofproduction }}
                </label>
              </div>
              <div style="padding-top: 1%">
                <input
                  required
                  type="number"
                  mdbInput
                  class="form-control mb-4"
                  formControlName="finalcostofproduction"
                />
              </div>
            </div>

            <div class="col-lg-4">
              <div>
                <label class="titrechamp"> {{ languee.totalvalue }} </label>
              </div>
              <div style="padding-top: 1%">
                <input
                  required
                  type="number"
                  mdbInput
                  class="form-control mb-4"
                  formControlName="totalvalue"
                />
              </div>
            </div>
            <div class="col-lg-12">
              <div>
                <label class="titrechamp"
                  >{{ languee.descriptionofgoods }}
                </label>
                <div style="padding-top: 1%">
                  <div class="col-lg-8">
                    <div class="md-form">
                      <textarea
                        type="text"
                        id="form7"
                        formControlName="descriptionofgoods"
                        class="md-textarea form-control"
                        mdbInput
                      ></textarea>
                      <label for="form7"></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div style="text-align: center; padding: 2% 35% 1% 40%">
        <div style="padding-top: 1%">
          <button
            (click)="open(content5)"
            type="button"
            class="btn button_elemnt"
            style="display: flex"
          >
            <div>
              <mat-icon class="iconadd">add</mat-icon>
            </div>
            <div style="padding-top: 3px">
              <span class="piece"> {{ languee.newelement }} </span>
            </div>
          </button>
        </div>
      </div>
      <div class="row" style="margin: 1% 1% 1% 1%">
        <div class="div_table">
          <table mat-table [dataSource]="dataSourceElement">
            <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

            <!-- Position Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef class="headercell">
                {{ languee.name }}
              </th>
              <td mat-cell *matCellDef="let element" class="tdcell">
                {{ element.name }}
              </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="quantity">
              <th mat-header-cell *matHeaderCellDef class="headercell">
                {{ languee.quantity }}
              </th>
              <td mat-cell *matCellDef="let element" class="tdcell">
                {{ element.quantity }}
              </td>
            </ng-container>

            <ng-container matColumnDef="value">
              <th mat-header-cell *matHeaderCellDef class="headercell">
                {{ languee.value }}
              </th>
              <td mat-cell *matCellDef="let element" class="tdcell">
                {{ element.value }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsElement"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumnsElement"
            ></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center" style="padding-bottom: 2%">
    <button
      [disabled]="FormArticle.invalid"
      type="button"
      class="btn button_file_save"
      (click)="Add_article()"
    >
      <span class="save"> {{ languee.save }}</span>
    </button>
  </div>
</ng-template>

<ng-template #content5 let-modal>
  <div class="modal-header text-center">
    <h4 class="modal-title w-100" id="modal-basic-title">
      {{ languee.newelement }}
    </h4>

    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body" [ngStyle]="{ direction: direction }">
    <div class="bodymodal_file">
      <form [formGroup]="FormElement" autocomplete="off" novalidate form>
        <div class="row">
          <div style="padding-top: 2%" class="row">
            <div class="col-lg-6">
              <div>
                <label class="titrechamp"> {{ languee.name }}</label>
              </div>
              <div style="padding-top: 1%">
                <input
                  required
                  type="text"
                  mdbInput
                  class="form-control mb-4"
                  formControlName="name"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div>
                <label class="titrechamp">{{ languee.quantity1 }} </label>
              </div>
              <div style="padding-top: 1%">
                <input
                  required
                  type="number"
                  mdbInput
                  class="form-control mb-4"
                  formControlName="quantity"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div>
                <label class="titrechamp">
                  {{ languee.value }}
                </label>
              </div>
              <div style="padding-top: 1%">
                <input
                  required
                  type="number"
                  mdbInput
                  class="form-control mb-4"
                  formControlName="value"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="text-center" style="padding-bottom: 2%">
    <button
      [disabled]="FormElement.invalid"
      type="button"
      class="btn button_file_save"
      (click)="Add_element()"
      (dblclick)="modal.dismiss('Cross click')"
    >
      <span class="save"> {{ languee.save }}</span>
    </button>
  </div>
</ng-template>
<notifier-container></notifier-container>

<ng-template #CoElements class="modal-dialog" let-modal>
  <div class="modal-header">
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div [ngStyle]="{ direction: direction }">
    <table mat-table [dataSource]="dataSourceElement">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>{{ languee.id }}</th>
        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ languee.name }}</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="quantity">
        <th mat-header-cell *matHeaderCellDef>{{ languee.quantity1 }}</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef>{{ languee.value }}</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsElement"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsElement"></tr>
    </table>
  </div>
</ng-template>
