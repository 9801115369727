import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.css'],
})

export class ImageSliderComponent implements OnInit {
  constructor() { }
  ngOnInit(): void {
    console.log('ng on init.....');
  }

}
