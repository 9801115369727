import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrganisationService } from 'src/Services/Organisation/organisation.service';
import { UserService } from 'src/Services/User/user.service';
import { portofcall } from '../../Models/portofcall';
import { VesselCallInformationService } from '../../Shipping_Arrival/Service/VesselCall/vessel-call-information.service';
import { ShipdockingServiceService } from '../Service/shipdocking-service.service';

import Langue from '../../../../../assets/langue.json';

@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.css'],
})
export class NewComponent implements OnInit {
  options: any[] = [{ name: 'Berthing Request' }, { name: 'Hold Berthing' }];
  option: any;
  Formship: FormGroup;
  selecteddischargingport: String;
  birthstatus = new UntypedFormControl();
  Id_Ship: any;
  transactiontype = 'Save';
  Id_Arrshipdhocking: any;
  etat = 'Save';
  typeaction: boolean = false;

  langue: any;
  direction: any;
  langue_list: any[] = Langue;
  languea: any;
  languesa: any;
  languet: any;
  titre_bloc: any;
  btn_backround1 = 'rgba(71, 178, 228, 1)';
  color1 = '#fff';
  constructor(
    private formBuilder: FormBuilder,
    private organisation: OrganisationService,
    private callofinforamtions: VesselCallInformationService,
    private route: ActivatedRoute,
    private ShipdockingApi: ShipdockingServiceService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private router: Router,
    private titleService: Title,
    private Userauth: UserService
  ) {
    if (localStorage.getItem('langue') != null) {
      this.langue = localStorage.getItem('langue') + '';
    } else {
      this.langue = 'AR';
    }

    if (this.langue == 'AR') {
      this.direction = 'rtl';
      this.languea = this.langue_list[0]['AR'].SD;
      this.languesa = this.langue_list[0]['AR'].SA;
      this.languet = this.langue_list[0]['AR'].modal_text;
    }

    if (this.langue == 'EN') {
      this.direction = 'ltr';
      this.languea = this.langue_list[0]['EN'].SD;
      this.languesa = this.langue_list[0]['EN'].SA;
      this.languet = this.langue_list[0]['EN'].modal_text;
    }

    if (this.langue == 'FR') {
      this.direction = 'ltr';
      this.languea = this.langue_list[0]['FR'].SD;
      this.languesa = this.langue_list[0]['FR'].SA;
      this.languet = this.langue_list[0]['FR'].modal_text;
    }

    this.titre_bloc = this.languea.shipdockinginformation;
    this.titleService.setTitle(this.languet.entete);
    this.Formship = this.formBuilder.group({
      vesselcallnumber: [''],
      dischargingport: ['', Validators.required],
      gangsnumber: ['', Validators.required],
    });

    this.Id_Ship = this.route.snapshot.params['ship'];
    this.GetUser();
    this.Id_Arrshipdhocking = this.route.snapshot.params['id'];
    if (this.Id_Arrshipdhocking != undefined) {
      this.transactiontype = 'Update';
      setTimeout(() => {
        this.spinner.show();
        this.Getpremision();
        this.GetShipDockingtById();
      }, 300);
    }

    if (this.Id_Ship != undefined) {
      this.transactiontype = 'Save';
      setTimeout(() => {
        this.spinner.show();

        this.GetcallofinformationById();
      }, 300);
    }
  }
  displayFn(object?: any): any | undefined {
    return object ? object.name : undefined;
  }
  ngOnInit(): void {
    this.birthstatus.setValue(this.options[0]);
    this.Getportofcall();
  }
  contour: number = 1;
  color_submit = '#b1d9f5';
  color_save = '#b1d9f5';
  protected createformshipdocking(): any {
    return {
      vesselcallno: this.Id_Ship,
      numEscale: this.Formship.controls['vesselcallnumber'].value,
      portofdeclaration: this.idOrgR,
      berthingstatuscd: this.birthstatus.value.name,
      portofdeclarationNM: this.dischargingportName,
      gangscount: this.Formship.controls['gangsnumber'].value,
      etat: this.etat,
    };
  }

  protected createformshipdockingUdpate(): any {
    return {
      id: this.Id_Arrshipdhocking,
      vesselcallno: this.Id_Ship,
      numEscale: this.Formship.controls['vesselcallnumber'].value,
      portofdeclaration: this.idOrgR,
      berthingstatuscd: this.birthstatus.value.name,
      portofdeclarationNM: this.dischargingportName,
      gangscount: this.Formship.controls['gangsnumber'].value,
      etat: this.etat,
    };
  }

  Save(content: any) {
    if (this.transactiontype == 'Save') {
      this.RegistrationShip(content);
    } else if (this.transactiontype == 'Update') {
      this.UpdateSHip(content);
    }
  }

  RegistrationShip(content: any) {
    this.ShipdockingApi.post(
      this.createformshipdocking(),
      this.idOrgR
    ).subscribe((res: any) => {
      this.Id_Arrshipdhocking = res.id;
      this.Getpremision();
      this.open(content);
    });
  }

  UpdateSHip(content: any) {
    this.ShipdockingApi.post(
      this.createformshipdockingUdpate(),
      this.idOrgR
    ).subscribe((res: any) => {
      this.open(content);
    });
  }
  open(content: any) {
    this.modalService.open(content);
  }

  submit12(content: any) {
    if (this.typeaction == true) {
      this.etat = 'For confirmation';
      this.ShipdockingApi.submit(
        this.createformshipdockingUdpate(),
        this.idOrgR
      ).subscribe((res: any) => {
        this.open(content);
        setTimeout(() => {
          this.Go_list();
        }, 1000);
      });
    }
  }

  Go_list() {
    this.modalService.dismissAll();
    localStorage.setItem('redirecturl', '2');
    this.router.navigateByUrl('Logistic/liste');
  }
  Next(titre: any) {
    this.intialisebloc();
    if (this.contour < 4) {
      this.contour = this.contour + 1;
    }

    this.titre_bloc = titre;
    this.remlirbloc();
  }

  Preview(titre: any) {
    if (this.contour > 1) {
      this.contour = this.contour - 1;
    }
    this.titre_bloc = titre;
    this.intialisebloc();
    this.remlirbloc();
  }
  intialisebloc() {
    this.BlocB1 = false;
    this.BlocB2 = false;
    this.BlocB3 = false;
    this.BlocB4 = false;
  }

  BlocB1: boolean = true;
  BlocB2: boolean = false;
  BlocB3: boolean = false;
  BlocB4: boolean = false;
  remlirbloc() {
    if (this.contour == 1) {
      this.BlocB1 = true;
    }
    if (this.contour == 2) {
      this.BlocB2 = true;
    }
    if (this.contour == 3) {
      this.BlocB3 = true;
    }
    if (this.contour == 4) {
      this.BlocB4 = true;
    }
  }

  changebloc(i: any, text: any) {
    this.titre_bloc = text;
    this.contour = i;
    this.intialisebloc();
    this.remlirbloc();
  }

  portlistofcall: portofcall[] = [];
  dischargingportName: any;
  dischargingportcode: any;
  idOrgR: any;

  changedischargingport(event: any) {
    let port = this.portlistofcall.find((x) => x.id == event.id);
    this.dischargingportName = port?.nom;
    this.dischargingportcode = port?.id;
    this.idOrgR = event.id;
  }
  organisation_list: any[] = [];
  list_organsation: portofcall[] = [];
  Getportofcall() {
    this.organisation.GetAll().subscribe((res: any) => {
      this.portlistofcall = [];
      this.list_organsation = [];
      this.organisation_list = res;
      this.list_organsation = res;

      this.list_organsation = this.list_organsation.filter(
        (e: any) => e.profil == 'Port'
      );
      console.log(this.list_organsation);
      this.portlistofcall = this.list_organsation;
    });
  }

  callofinfromation: any = {
    numEscale: '',
    voyageno: '',
    rejectionReason: '',
    callsigntype: '',
    callsign: '',
    ownercode: '',
    ownername: '',
    shippingagentcode: '',
    shippingagent: '',
    shipmaster: '',
    eta: '',
    etd: '',
    previousportcode: '',
    previousportname: '',
    portofcallcode: '',
    portofcallname: '',
    nextportcode: '',
    nextportname: '',
    preferreddock: '',
    berthtype: '',
    dangerousgoods: '',
    etat: '',
    type: '',
  };
  vessel_call_information_list: any;
  dischargingport: any;
  vessel: any = {
    id: 5852,
    vessel: '',
    vesseltype: '',
    vesselbuildyear: 2002,
    vesselregport: '',
    vesseldraft: '',
    vessellength: '',
    vesselgrossweight: '',
    vesselnetweight: '',
    vesselcountryname: null,
    vesselcountrycode: null,
    generalInfos: [],
  };
  GetcallofinformationById() {
    this.callofinforamtions.GetById(this.Id_Ship).subscribe((res: any) => {
      this.vessel_call_information_list = res;
      this.callofinfromation = this.vessel_call_information_list;
      this.vessel = this.callofinfromation.vessel;
      this.Formship.controls['vesselcallnumber'].setValue(res.numEscale);
      this.dischargingport = this.portlistofcall.find(
        (x) => x.id == this.callofinfromation.portofcallcode
      );
      this.selecteddischargingport = this.callofinfromation.portofcallname;
      this.dischargingportName = this.callofinfromation.portofcallname;
      this.idOrgR = this.callofinfromation.portofcallcode;
      this.spinner.hide();
    });
  }

  shipdocking_json: any;
  saveandfinish_etat: boolean = false;
  submit_etat: boolean = false;
  GetShipDockingtById() {
    this.ShipdockingApi.GetById(this.Id_Arrshipdhocking).subscribe(
      (res: any) => {
        this.shipdocking_json = res;
        let birthstatus_f = this.shipdocking_json.berthingstatuscd;
        this.Formship.controls['gangsnumber'].setValue(res.gangscount);
        if (birthstatus_f == 'Berthing Request') {
          this.birthstatus.setValue(this.options[0]);
        } else {
          this.birthstatus.setValue(this.options[1]);
        }
        this.selecteddischargingport = res.portofdeclarationNM;
        this.Id_Ship = res.vesselcallno;
        this.GetcallofinformationById();
        this.spinner.hide();

        if (this.transactiontype == 'Update') {
          let messagerows = this.shipdocking_json.messages.length;
          let lastmessage = this.shipdocking_json.messages[messagerows - 1];
          let actionsrow = lastmessage.actions.length;
          let lastaction = lastmessage.actions[actionsrow - 1];

          if (
            lastaction != undefined &&
            lastaction.affectedToUser.id == this.idUser &&
            lastaction.code == 'SAVE' &&
            lastaction.status != 'FINISHED'
          ) {
            this.saveandfinish_etat = true;
          } else if (
            lastaction != undefined &&
            lastaction.affectedToUser.id == this.idUser &&
            lastaction.code == 'SUBMIT' &&
            lastaction.status != 'FINISHED'
          ) {
            this.submit_etat = true;
          } else {
            this.Go_list();
          }
        }
      }
    );
  }

  submit_flux: boolean = false;
  Getpremision() {
    let premission = localStorage.getItem('premission_SD');

    if (premission?.indexOf('S') != -1) {
      this.typeaction = true;
      this.color_submit = '#1671b3';
      this.submit_flux = true;
    }
  }

  finishaction(content: any) {
    this.ShipdockingApi.finishaction(
      this.createformshipdockingUdpate()
    ).subscribe((res: any) => {
      this.open(content);
      setTimeout(() => {
        this.Go_list();
      }, 400);
    });
  }
  idUser: any;
  GetUser() {
    this.Userauth.Getuserbyusername().subscribe((res: any) => {
      this.idUser = res.id;
    });
  }
}
