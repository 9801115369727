import { Country } from '@angular-material-extensions/select-country';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrganisationService } from 'src/Services/Organisation/organisation.service';
import { UserService } from 'src/Services/User/user.service';
import { CertificatService } from '../Services/certificat.service';
import Langue from '../../../../../assets/langue.json';
import * as XLSX from 'xlsx';
import { AttachmentService } from 'src/Services/AttchementTrade/attachment.service';
import {
  HttpErrorResponse,
  HttpEventType,
  HttpResponse,
} from '@angular/common/http';
import { DataUtils } from 'src/Services/AttchementTrade/data-util.service';
@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.css'],
})
export class NewComponent implements OnInit {
  FormPieces: FormGroup;

  langue: any;
  direction: any;
  languef: any;
  langue_list: any[] = Langue;
  languea: any;
  languem: any;
  languet: any;
  languee: any;

  constructor(
    protected attachmentService: AttachmentService,
    protected dataUtils: DataUtils,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private titleService: Title,
    private datePipe: DatePipe,
    private userrService: UserService,
    private OrganisationService: OrganisationService,
    private CertificatApi: CertificatService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private attchementsApi: AttachmentService
  ) {
    if (localStorage.getItem('langue') != null) {
      this.langue = localStorage.getItem('langue') + '';
    } else {
      this.langue = 'AR';
    }

    if (this.langue == 'AR') {
      this.direction = 'rtl';
      this.languef = this.langue_list[0]['AR'].tablelist;
      this.languem = this.langue_list[0]['AR'].messageuser;
      this.languet = this.langue_list[0]['AR'].modal_text;
      this.languee = this.langue_list[0]['AR'].CO;
    }

    if (this.langue == 'EN') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['EN'].tablelist;
      this.languem = this.langue_list[0]['EN'].messageuser;
      this.languet = this.langue_list[0]['EN'].modal_text;
      this.languee = this.langue_list[0]['EN'].CO;
    }

    if (this.langue == 'FR') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['FR'].tablelist;
      this.languem = this.langue_list[0]['FR'].messageuser;
      this.languet = this.langue_list[0]['FR'].modal_text;
      this.languee = this.langue_list[0]['FR'].CO;
    }
    this.titleService.setTitle(this.languet.entete);
    this.FormPieces = this.formBuilder.group({
      id: [],
      name: ['', Validators.required],
      file: ['', Validators.required],
      attachmentContentType: [],
      originalFilename: [],
      fileSize: [],
      lastModifiedDate: [],
      creditLetter: [],
      certificateOrigin: [],
      userId: [],
      attachmentRecentlyUploaded: [],
    });
    this.Certificat_ID = this.route.snapshot.params['id'];
    this.Certificat_ID_copy = this.route.snapshot.params['id1'];
    if (this.Certificat_ID != undefined) {
      this.spinner.show();
      this.transactiontype = 'Update';
      this.CertificatGetbyId(this.Certificat_ID);
      this.getAttchements(this.Certificat_ID);
    } else if (this.Certificat_ID_copy != undefined) {
      this.spinner.show();
      this.CertificatGetbyId(this.Certificat_ID_copy);
      this.getAttchements(this.Certificat_ID_copy);

      this.transactiontype = 'Copy';
    } else {
      this.transactiontype = 'Save';
    }
  }
  transactiontype = 'Save';
  Form1 = this.formBuilder.group({
    invoicenumber: [''],
    dateinvoice: [''],
    consigneename: [''],
    consigneeaddress: [''],
    producername: [''],
    produceraddress: [''],
    meanstransportandroute: [''],
    totalvalue: [''],
    selectedchamberofcommerce: ['']
  });

  Form2 = this.formBuilder.group({});

  dateinvoice: any;
  onchangedateinvoice(event: any) {
    this.dateinvoice = this.datePipe.transform(event, 'yyyy-MM-dd');
  }
  chamberofcommerce_list: any[] = [];
  selectedchamberofcommerce: any;

  id_chambreofcommerce: any;
  name_chambreofcommerce: any;
  changechamberofcommerce(event: any) {
    this.id_org = event.id;
    this.id_chambreofcommerce = event.id;
    this.name_chambreofcommerce = event.name;
  }

  Valuedorigin: Country = {
    name: '',
    alpha2Code: '',
    alpha3Code: '',
    numericCode: '',
    callingCode: '',
  };
  countryname: any;
  onCountrySelected(event: any) {
    this.countryname = event.name;
  }

  Valuedmanifacture: Country = {
    name: '',
    alpha2Code: '',
    alpha3Code: '',
    numericCode: '',
    callingCode: '',
  };
  countrynamemanifacture: any;
  onCountrySelectedmanifacture(event: any) {
    this.countrynamemanifacture = event.name;
  }

  cumulation: any = 'YES';
  changecumulation(event: any) {
    if (event.value == 'no') {
      this.cumulation = 'NO';
    } else {
      this.cumulation = 'YES';
    }
  }
  open(content: any) {
    this.FormPieces.reset();
    this.isRecentFiles = false;
    this.isLoadComputer = false;
    this.modalService.open(content);
  }
  dimmis() {
    this.modalService.dismissAll();
  }

  open_items(content: any) {
    this.modalService.open(content, {
      size: 'xl',
      windowClass: 'dark-modal',
    });
  }
  Pieces_DATA: any[] = [];

  displayedColumnsPieces: string[] = ['name', 'file', 'action'];
  dataSourcePieces: MatTableDataSource<any>;

  displayedColumnsElement: string[] = ['name', 'quantity', 'value'];
  dataSourceElement: MatTableDataSource<any>;

  attachmentsDeleted: any[] = [];
  attachmentsRecentlyUploaded: any[] = [];
  attachmentsRecentlyUploadedByName: any[] = [];
  attachmentsUpdateDeleted: any[] = [];
  uploadedFiles?: File[] = [];
  currentFile?: File;
  msg = '';
  errorMsg = '';
  onChangefile(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.currentFile = file;
    }
  }

  file_add() {
    this.dimmis();
    this.errorMsg = '';
    if (this.currentFile) {
      const file: File | null = this.currentFile;

      // eslint-disable-next-line no-console

      if (file) {
        this.currentFile = file;
        const attachment = this.createFromFormAttachment();
        const attachmentFile = this.createFromFormAttachmentFile();
        this.attachmentService.verifyFile(this.CertificatApi.Spring_serveur, attachmentFile).subscribe({
          next: (response: HttpResponse<any>) => {
            // eslint-disable-next-line no-console

            this.msg = response.body.responseMessage ?? '';
            // eslint-disable-next-line
            if (!this.Pieces_DATA.includes(attachment)) {
              // eslint-disable-next-line
              this.Pieces_DATA?.push(attachment);
            }
            if (!this.uploadedFiles?.map((u) => u.name).includes(file.name)) {
              // eslint-disable-next-line
              this.uploadedFiles?.push(file);
            }
            alert(this.msg);

            //addAtta

            //  this.deletedFiles?.push(attachment);

            //addAtta
          },
          error: (err: HttpErrorResponse) => {
            // eslint-disable-next-line no-console
            console.log(err);
            // eslint-disable-next-line
            if (err.error && err.error.detail) {
              this.errorMsg = err.error.detail;
            } else {
              this.errorMsg = 'Error occurred while uploading a file!';
            }
            alert(this.errorMsg);
            this.currentFile = undefined;
            // eslint-disable-next-line no-console
          },
        });
      }
      this.currentFile = undefined;
      // eslint-disable-next-line no-console
    } else {
      const attachment = this.FormPieces.get([
        'attachmentRecentlyUploaded',
      ])!.value;
      attachment.name = this.FormPieces.get(['name'])!.value;
      alert('Attachment added successfully');
      // eslint-disable-next-line
      if (!this.Pieces_DATA.includes(attachment)) {
        // eslint-disable-next-line
        this.Pieces_DATA?.push(attachment);
      }
      //obl
      this.loadAllAttachmentByUser(this.UserId!);

      //this.isSavingA = true;
      //addAtta

      //  this.deletedFiles?.push(attachment);
    }
    setTimeout(() => {
      this.dataSourcePieces = new MatTableDataSource(this.Pieces_DATA);
    }, 300);
    this.FormPieces.reset();

    // const el: HTMLElement = this.myDiv!.nativeElement;
    // el.click();
  }
  loadAllAttachmentByUser(id: number): void {
    this.attachmentService.findByUser(this.UserId).subscribe({
      next: (res: HttpResponse<any[]>) => {
        this.attachmentsRecentlyUploaded = (res.body ?? []).filter(
          (a) =>
            a.certificateOrigin == null &&
            this.Pieces_DATA.map((b) => b.id).indexOf(a.id) === -1
        );
        //.filter(e => e.creditLetter?.id === this.creditLetter?.id)
        // this.attachments = this.creditLetter?.attachments ?? [];
        // eslint-disable-next-line no-console
      },
      error: (error: HttpErrorResponse) => {
        //alert(error.message);
      },
    });
  }
  loadAllAttachmentByOriginFilename(name: string): void {
    if (name !== '') {
      this.attachmentService.findByOriginFilename(name).subscribe({
        next: (res: HttpResponse<any[]>) => {
          this.attachmentsRecentlyUploadedByName = (res.body ?? []).filter(
            (a) =>
              a.certificateOrigin == null &&
              this.Pieces_DATA.map((b) => b.id).indexOf(a.id) === -1
          );
          //.filter(e => e.creditLetter?.id === this.creditLetter?.id)
          // this.attachments = this.creditLetter?.attachments ?? [];
          // eslint-disable-next-line no-console

          this.loadRecentFilesByName();
        },
        error: (error: HttpErrorResponse) => {
          //alert(error.message);
        },
      });
    }
  }
  isNavbarCollapsed = true;
  collapseNavbar(): void {
    this.isNavbarCollapsed = true;
    //this.editForm3.reset();
  }
  isRecentFiles = false;
  isRecentFilesByName = false;
  isLoadComputer = false;
  loadFromComputer(): void {
    this.isRecentFiles = false;
    this.isRecentFilesByName = false;
    this.isLoadComputer = true;
    this.FormPieces.patchValue({ attachmentRecentlyUploaded: null });
    // const el: HTMLElement = this.computer!.nativeElement;
    // el.click();
  }
  loadRecentFiles(): void {
    this.isLoadComputer = false;
    this.isRecentFilesByName = false;
    this.isRecentFiles = true;

    // const el: HTMLElement = this.recentFiles!.nativeElement;
    // el.click();
  }
  loadRecentFilesByName(): void {
    this.isLoadComputer = false;
    this.isRecentFiles = false;
    this.isRecentFilesByName = true;
    //this.loadAllAttachmentByOriginFilename(this.editForm3.get('originalFilename')!.value)
    // const el: HTMLElement = this.recentFilesByName!.nativeElement;
    // el.click();
  }
  // selectedattachment: any;
  recentFileUpdateForm(attachmentRecentlyUploaded: any): void {
    this.currentFile = undefined;
    if (this.FormPieces.get(['name'])!.value) {
      attachmentRecentlyUploaded.name = this.FormPieces.get(['name'])!.value;
    }

    this.updateFormAttachment(attachmentRecentlyUploaded);
    // eslint-disable-next-line
    this.FormPieces.patchValue({
      attachmentRecentlyUploaded: attachmentRecentlyUploaded,
    });
  }

  oldAttachment: any;
  isUpdatingAttachment = false;
  updateFileMode(attachment: any) {
    this.oldAttachment = attachment;
    this.updateFormAttachment(attachment);
    this.isUpdatingAttachment = true;
  }

  file_update(): void {
    this.dimmis();
    // this.isSavingA = false;
    this.errorMsg = '';
    const oldAttachment = this.oldAttachment;
    if (this.currentFile) {
      const file: File | null = this.currentFile;

      // eslint-disable-next-line no-console

      if (file) {
        this.currentFile = file;
        const attachment = this.createFromFormAttachment();
        const attachmentFile = this.createFromFormAttachmentFile();
        this.attachmentService.verifyFile(this.CertificatApi.Spring_serveur, attachmentFile).subscribe({
          next: (response: HttpResponse<any>) => {
            // eslint-disable-next-line no-console

            this.msg = response.body.responseMessage ?? '';
            alert(this.msg);
            //this.addAlert();
            // eslint-disable-next-line
            this.Pieces_DATA?.splice(
              this.Pieces_DATA.indexOf(oldAttachment!),
              1
            );
            attachment.creditLetter = null;
            // eslint-disable-next-line
            this.Pieces_DATA.push(attachment);

            // eslint-disable-next-line
            // this.attachmentService.deleteFile(oldAttachment!.attachment ?? "").subscribe();
            //this.deletedFiles?.push(attachment);
            if (!this.uploadedFiles?.includes(file)) {
              this.uploadedFiles?.push(file);
            }
            if (!this.attachmentsUpdateDeleted.includes(oldAttachment!)) {
              this.attachmentsUpdateDeleted.push(oldAttachment!);
            }
            // eslint-disable-next-line no-console

            //this.isSavingA = true;

            //updateAtt
            //this.attachmentsUpdateDeleted.map(a => a.id)

            //updateAtt
          },
          error: (err: HttpErrorResponse) => {
            // eslint-disable-next-line no-console
            console.log(err);
            // eslint-disable-next-line
            if (err.error && err.error.detail) {
              this.errorMsg = err.error.detail;
            } else {
              this.errorMsg = 'Error occurred while uploading a file!';
            }
            // this.addErrorAlert();
            alert(this.errorMsg);

            // eslint-disable-next-line no-console
          },
        });
      }
      this.currentFile = undefined;
      // const el: HTMLElement = this.myDiv!.nativeElement;
      // el.click();
    } else if (this.isRecentFiles || this.isRecentFilesByName) {
      const attachment = this.FormPieces.get([
        'attachmentRecentlyUploaded',
      ])!.value;
      attachment.name = this.FormPieces.get(['name'])!.value;
      alert('Attachment updated successfully');
      //this.addAlert();
      // eslint-disable-next-line
      this.Pieces_DATA?.splice(this.Pieces_DATA.indexOf(oldAttachment!), 1);
      // eslint-disable-next-line
      if (!this.Pieces_DATA.includes(attachment)) {
        // eslint-disable-next-line
        this.Pieces_DATA?.push(attachment);
      }
      this.attachmentsDeleted.push(oldAttachment!);
      this.loadAllAttachmentByUser(this.UserId);
      //  this.isSavingA = true;
      //addAtta

      //  this.deletedFiles?.push(attachment);
    } else {
      // eslint-disable-next-line
      this.Pieces_DATA?.splice(this.Pieces_DATA.indexOf(oldAttachment!), 1);
      oldAttachment!.name = this.FormPieces.get(['name'])!.value;
      alert('Attachment updated successfully');
      // eslint-disable-next-line
      this.Pieces_DATA.push(oldAttachment!);
      //updateAtt
      //this.attachmentsUpdateDeleted.map(a => a.id)
      // this.isSavingA = true;
    }
    setTimeout(() => {
      this.dataSourcePieces = new MatTableDataSource(this.Pieces_DATA);
    }, 300);

    this.oldAttachment = undefined;
    this.isUpdatingAttachment = false;

    this.FormPieces.reset();
    // const el: HTMLElement = this.myDiv!.nativeElement;
    // el.click();
    // this.isAddingAttachment = true;
    // alert('Attachment Updated');
    // this.addAlert();
    //updateAtt
    // eslint-disable-next-line no-console
  }
  openAttachment(url: string): void {
    // eslint-disable-next-line
    // window.open("../../content/attachments/"+ a,'_blank');
    window.open(url, '_blank');
  }

  upload(file: File): void {
    this.errorMsg = '';

    this.currentFile = file;
    //this.account!.id
    this.attachmentService.upload(this.CertificatApi.Spring_serveur, this.currentFile, this.UserId).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          // eslint-disable-next-line no-console
        } else if (event instanceof HttpResponse) {
          this.msg = event.body.responseMessage;
          // this.addAlert();
        }
        // this.isSavingA = true;
      },
      (err: any) => {
        // eslint-disable-next-line no-console
        // eslint-disable-next-line
        if (err.error && err.error.detail) {
          this.errorMsg = err.error.detail;
        } else {
          this.errorMsg = 'Error occurred while uploading a file!';
        }
        //this.addErrorAlert();
        // this.currentFile = undefined;
        // eslint-disable-next-line no-console
      }
    );
    this.currentFile = undefined;
  }
  getattachmentsUpdateDeletedIds(): any[] {
    return this.attachmentsUpdateDeleted.map((a) => a.id);
  }
  deleteAttachment(attachment: any): void {
    // eslint-disable-next-line
    this.Pieces_DATA?.splice(this.Pieces_DATA.indexOf(attachment), 1);
    // if (this.attachments.length === 0) {
    //   this.isAddingAttachment = false;
    // }
    this.loadAllAttachmentByUser(this.UserId);
    // eslint-disable-next-line no-console
    setTimeout(() => {
      this.dataSourcePieces = new MatTableDataSource(this.Pieces_DATA);
    }, 300);
    //this.delete(attachment);
    this.attachmentsDeleted.push(attachment);
  }

  protected updateFormAttachment(attachment: any): void {
    this.FormPieces.patchValue({
      id: attachment.id,
      name: attachment.name,
      originalFilename: attachment.originalFilename,
      fileSize: attachment.fileSize,
      lastModifiedDate: attachment.lastModifiedDate,
      attachmentContentType: attachment.attachmentContentType,
      file: attachment.attachment,
      userId: attachment.userId,
      creditLetter: attachment.creditLetter,
      certificateOrigin: attachment.certificateOrigin,
    });
  }
  protected createFromFormAttachment(): any {
    return {
      id: this.FormPieces.controls['id'].value,
      name: this.FormPieces.controls['name'].value,
      originalFilename: this.currentFile?.name,
      attachment: this.FormPieces.controls['file'].value, //this.currentFile
      attachmentContentType: this.currentFile?.type,
      fileSize: this.currentFile?.size,
      lastModifiedDate: null,
      userId: this.UserId,
      creditLetter: this.FormPieces.get(['creditLetter'])!.value,
      certificateOrigin: this.FormPieces.get(['certificateOrigin'])!.value,
    };
  }

  protected createFromFormAttachmentFile(): any {
    return {
      originalFilename: this.currentFile?.name,
      fileSize: this.currentFile?.size,
      userId: this.UserId,
    };
  }

  openFile(base64String: string, contentType: string | null | undefined): void {
    this.dataUtils.openFile(base64String, contentType);
  }

  setFileData(event: Event, field: string, isImage: boolean): void {
    this.dataUtils
      .loadFileToForm(event, this.FormPieces, field, isImage)
      .subscribe();
  }

  ngOnInit(): void {
    this.Getorganisation();
    this.GetIdUser();
  }

  Submit(content: any) {
    this.Certificat_origin_document = this.CreateFormCertificat();
    this.Certificat_origin_document.attachments?.forEach((element: any) => {
      if (element.attachment != null) {
        element.attachment =
          this.attachmentService.uploadPathFront +
          'USER' +
          this.UserId +
          '_' +
          element.originalFilename;
      }
    });

    this.CertificatApi.submit(this.Certificat_origin_document).subscribe(
      (res: any) => {
        this.open(content);
        this.Go_list();
      }
    );

    this.attachmentOnSave();
  }

  Certificat_origin_document: any;
  Save(content: any) {
    this.Certificat_origin_document = this.CreateFormCertificat();
    if (this.Certificat_origin_document.attachments && !Array.isArray(this.Certificat_origin_document.attachments)) {
      this.Certificat_origin_document.attachments = [this.Certificat_origin_document.attachments];
  }
    if (Array.isArray(this.Certificat_origin_document.attachments)) {
    this.Certificat_origin_document.attachments?.forEach((element: any) => {
      if (element.attachment != null) {
        element.attachment =
          this.attachmentService.uploadPathFront +
          'USER' +
          this.UserId +
          '_' +
          element.originalFilename;
      }
    });}else {
      console.warn("attachments is not an array.");
  }
    if (this.transactiontype == 'Save' || this.transactiontype == 'Copy') {
      this.CertificatApi.post(
        this.Certificat_origin_document,
        this.id_org
      ).subscribe((res: any) => {
        this.open(content);
      });
    } else {
      this.CertificatApi.update(
        this.Certificat_origin_document,
        this.Certificat_ID
      ).subscribe((res: any) => {
        this.open(content);
      });
    }
    this.attachmentOnSave();
  }

  attachmentOnSave() {
    this.attachmentsDeleted.forEach((element) => {
      // eslint-disable-next-line
      if (element?.id != null && element.attachment != null) {
        // if (element.certificateOrigin == null) {
        //   // eslint-disable-next-line
        //   this.attachmentService.delete(element?.id).subscribe();
        //   // eslint-disable-next-line
        //   this.attachmentService.deleteFile(element!.attachment).subscribe();
        // } else {
        element.certificateOrigin = null;
        //element.lastModifiedDate=dayjs(element.lastModifiedDate);
        this.attachmentService.update(element).subscribe();
        // }
      }
    });
    // eslint-disable-next-line no-console

    this.attachmentsUpdateDeleted.forEach((element) => {
      // eslint-disable-next-line
      if (element.attachment != null) {
        if (element.creditLetter == null) {
          // eslint-disable-next-line
          this.attachmentService.deleteFile(element!.attachment).subscribe();
        } else {
          // const a1 = this.attachments.filter(a=> a.id===element.id)[0];
          // a1.creditLetter=null;
          // this.attachmentService.update(a1).subscribe();
          element.certificateOrigin = null;
          element.id = undefined;
          this.attachmentService.create(element).subscribe();
        }
      }
    });
    this.uploadedFiles!.forEach((element) => {
      // eslint-disable-next-line
      if (element != null) {
        this.upload(element);
      }
    });
  }

  onFileChange(event: any) {
    this.spinner.show();
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>event.target;
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      if (ws['H5'] != undefined) {
        this.Form1.controls['invoicenumber'].setValue(ws['H5']['v']);
      }

      if (ws['H2'] != undefined) {
        this.Form1.controls['consigneename'].setValue(ws['H2']['v']);
      }
      if (ws['H3'] != undefined) {
        this.Form1.controls['consigneeaddress'].setValue(ws['H3']['v']);
      }

      if (ws['J9'] != undefined) {
        this.Form1.controls['producername'].setValue(ws['J9']['v']);
      }

      if (ws['J11'] != undefined) {
        this.Form1.controls['produceraddress'].setValue(ws['J11']['v']);
      }

      if (ws['H15'] != undefined) {
        if (ws['H15'] == 'NO') {
          this.cumulation = 'NO';
        } else {
          this.cumulation = 'YES';
        }
      }
      if (ws['D15'] != undefined) {
        this.Form1.controls['totalvalue'].setValue(ws['D15']['v']);
      }

      if (ws['D14'] != undefined) {
        this.Form1.controls['meanstransportandroute'].setValue(ws['D14']['v']);
      }

      if (ws['F14']['v'] != undefined) {
        this.Valuedorigin = {
          name: '',
          alpha2Code: ws['F14']['v'],
          alpha3Code: '',
          numericCode: '',
          callingCode: '',
        };

        this.countryname = ws['F14']['v'];
      }

      if (ws['F16']['v'] != undefined) {
        this.Valuedmanifacture = {
          name: '',
          alpha2Code: ws['F16']['v'],
          alpha3Code: '',
          numericCode: '',
          callingCode: '',
        };

        this.countrynamemanifacture = ws['F16']['v'];
      }

      let j = 5;

      for (var x = 4; x < j; x++) {
        if (ws['L' + x] != undefined) {
          this.Elements_excel.push({
            name: ws['L' + x]['v'],
            value: ws['M' + x]['v'],
            quantity: ws['N' + x]['v'],
            item: ws['O' + x]['v'],
          });

          j = j + 1;
        }
      }

      let z = 22;

      for (var i = 21; i < z; i++) {
        if (ws['B' + i] != undefined) {
          let elements = this.Elements_excel.filter(
            (e) => e.item == ws['B' + i]['v']
          );
          this.Elements_excel_f = [];
          for (let element of elements) {
            this.Elements_excel_f.push({
              name: element.name,
              quantity: element.quantity,
              value: element.value,
            });
          }
          this.Items_excel.push({
            marksAndNumberOfPackage: ws['C' + i]['v'],
            descriptionOfGoods: ws['L' + i]['v'],
            netWeight: ws['E' + i]['v'],
            numberAndKindOfPackage: ws['D' + i]['v'],
            grossWeight: ws['F' + i]['v'],
            quantity: ws['H' + i]['v'],
            proceedsForeign: ws['G' + i]['v'],
            currencyValue: ws['K' + i]['v'],
            finalCostOfProduction: ws['I' + i]['v'],
            totalValue: ws['J' + i]['v'],
            elements: this.Elements_excel_f,
          });

          z = z + 1;
        }
      }
    };

    this.Article_DATA = this.Items_excel;

    setTimeout(() => {
      this.dataSource = new MatTableDataSource(this.Article_DATA);
      this.spinner.hide();
    }, 300);
  }

  Items_excel: any[] = [];
  Elements_excel: any[] = [];
  Elements_excel_f: any[] = [];

  Go_list() {
    this.dimmis();
    if (localStorage.getItem('activeexport') === 'active') {
      this.router.navigateByUrl('Export/liste');
    }
    if (localStorage.getItem('activetrade') === 'active') {
      this.router.navigateByUrl('Trade/liste');
    }
  }

  displayedColumns: string[] = [
    'marksandnumberofpackage',
    'numberandkindofpackage',
    'netweight',
    'grossweight',
    'quantity',
    'proceedsforeign',
    'currencyvalue',
    'actions',
  ];
  dataSource: MatTableDataSource<any>;

  FormArticle = this.formBuilder.group({
    marksandnumberofpackage: [''],
    numberandkindofpackage: [''],
    descriptionofgoods: [''],
    netweight: [''],
    grossweight: [''],
    quantity: [''],

    proceedsforeign: [''],
    currencyvalue: [''],
    finalcostofproduction: [''],
    totalvalue: [''],
  });

  FormElement = this.formBuilder.group({
    name: [''],
    quantity: [''],
    value: [''],
  });

  Article_DATA: any[] = [];
  total_item_value = 0;
  Add_article() {
    this.Article_DATA.push({
      marksAndNumberOfPackage:
        this.FormArticle.controls['marksandnumberofpackage'].value,
      descriptionOfGoods: this.FormArticle.controls['descriptionofgoods'].value,
      netWeight: this.FormArticle.controls['netweight'].value,
      numberAndKindOfPackage:
        this.FormArticle.controls['numberandkindofpackage'].value,
      grossWeight: this.FormArticle.controls['grossweight'].value,
      quantity: this.FormArticle.controls['quantity'].value,

      proceedsForeign: this.FormArticle.controls['proceedsforeign'].value,
      currencyValue: this.FormArticle.controls['currencyvalue'].value,
      finalCostOfProduction:
        this.FormArticle.controls['finalcostofproduction'].value,
      totalValue: this.FormArticle.controls['totalvalue'].value,
      elements: this.Element_DATA,
    });
    setTimeout(() => {
      this.total_item_value =
        this.total_item_value +
        Number(this.FormArticle.controls['totalvalue'].value);
      this.Form1.controls['totalvalue'].setValue(this.total_item_value + '');
      this.dimmis();
      this.FormArticle.reset();

      this.dataSource = new MatTableDataSource(this.Article_DATA);

      this.Element_DATA = [];
    }, 200);
  }
  Element_DATA: any[] = [];

  total_element_value = 0;
  Add_element() {
    this.Element_DATA.push({
      name: this.FormElement.controls['name'].value,
      value: this.FormElement.controls['value'].value,
      quantity: this.FormElement.controls['quantity'].value,
    });
    this.total_element_value =
      this.total_element_value +
      Number(this.FormElement.controls['value'].value);

    this.FormArticle.controls['totalvalue'].setValue(
      this.total_element_value + ''
    );
    setTimeout(() => {
      this.FormElement.reset();

      this.dataSourceElement = new MatTableDataSource(this.Element_DATA);
    }, 200);
  }

  id_org: any;

  CreateFormCertificat(): any {
    return {
      id: this.Certificat_ID,
      invoiceNumber: this.Form1.controls['invoicenumber'].value,
      invoiceDate: this.dateinvoice,
      exporter: this.id_Org_en,
      chamberOfCommerce: this.id_chambreofcommerce,
      consigneeName: this.Form1.controls['consigneename'].value,
      consigneeAddress: this.Form1.controls['consigneeaddress'].value,
      producerName: this.Form1.controls['producername'].value,
      producerAddress: this.Form1.controls['produceraddress'].value,
      countryOfOrigin: this.countryname,
      countryOfManifacture: this.countrynamemanifacture,
      meansTransportAndRoute:
        this.Form1.controls['meanstransportandroute'].value,
      cumulation: this.cumulation,
      cumulationCountries: null,
      totalValue: this.Form1.controls['totalvalue'].value,
      items: this.Article_DATA,
      status: this.status,
      messages: this.messages,
      attachments: this.Pieces_DATA,
    };
  }
  status: any;
  UserId: any;
  GetIdUser() {
    this.userrService.Getuserbyusername().subscribe((res: any) => {
      this.UserId = res.id;
      this.Getorganisation_send(this.UserId);
      this.loadAllAttachmentByUser(this.UserId);
    });
  }
  id_Org_en: any;

  Getorganisation_send(id: any) {
    this.OrganisationService.Get_org_byID(id).subscribe((res: any) => {
      this.id_Org_en = res.id;
    });
  }

  list_organsation: any[];
  Getorganisation() {
    this.OrganisationService.GetAll().subscribe((res: any) => {
      this.list_organsation = [];
      this.list_organsation = res;
      this.chamberofcommerce_list = this.list_organsation.filter(
        (e: any) => e.profil == 'Chamber_of_Commerce'
      );
    });
  }
  Certificat_ID: any;
  Certificat_ID_copy: any;
  CertificatGetbyId(id: any) {
    this.CertificatApi.GetByid(id).subscribe((res: any) => {
      this.Certificat_origin_document = res;

      this.remplirForm();
    });
  }

  getAttchements(id: any) {
    this.attchementsApi.findByCO(this.CertificatApi.Spring_serveur, id).subscribe((res: any) => {
      this.Pieces_DATA = res.body;

      setTimeout(() => {
        this.dataSourcePieces = new MatTableDataSource(this.Pieces_DATA);
      }, 300);
    });
  }


  getInformationOrg(res: any)  {
    if (res.chamberOfCommerce) {
        this.OrganisationService.GetInformationOrganisationByIdOrg(res.chamberOfCommerce)
            .subscribe((res: any) => {
              this.Form1.controls['selectedchamberofcommerce'].setValue(res.nameOrg);
            });
    } else {
        console.warn("chamberOfCommerce ID is missing.");
    }
}


  messages: any = [];
  remplirForm() {
    if (this.transactiontype != 'Copy') {


      this.messages = this.Certificat_origin_document.messages;
    }

    this.Certificat_origin_document.messages.sort(
      (a: any, b: any) => a.id - b.id
    );

    this.status = this.Certificat_origin_document.status;
    this.id_Org_en = this.Certificat_origin_document.exporter;
    /*this.selectedchamberofcommerce =
      this.Certificat_origin_document.messages[0].organizationReceiver.name;*/
      this.getInformationOrg(this.Certificat_origin_document);
    this.id_org =
      this.Certificat_origin_document.messages[0].idOrgReceiver;
    this.id_chambreofcommerce =
      this.Certificat_origin_document.messages[0].idOrgReceiver;
    this.name_chambreofcommerce =
      this.Certificat_origin_document.messages[0].idOrgSender;
    this.Form1.controls['invoicenumber'].setValue(
      this.Certificat_origin_document.invoiceNumber
    );

    this.Form1.controls['consigneename'].setValue(
      this.Certificat_origin_document.consigneeName
    );

    this.Form1.controls['consigneeaddress'].setValue(
      this.Certificat_origin_document.consigneeAddress
    );

    this.Form1.controls['producername'].setValue(
      this.Certificat_origin_document.producerName
    );

    this.Form1.controls['produceraddress'].setValue(
      this.Certificat_origin_document.producerAddress
    );

    this.cumulation = this.Certificat_origin_document.cumulation;

    this.Form1.controls['dateinvoice'].patchValue(
      this.formatDate(new Date(this.Certificat_origin_document.invoiceDate))
    );

    this.dateinvoice = this.Certificat_origin_document.invoiceDate;
    this.Form1.controls['totalvalue'].setValue(
      this.Certificat_origin_document.totalValue
    );

    this.Form1.controls['meanstransportandroute'].setValue(
      this.Certificat_origin_document.meansTransportAndRoute
    );

    this.Valuedorigin = {
      name: '',
      alpha2Code: this.Certificat_origin_document.countryOfOrigin,
      alpha3Code: '',
      numericCode: '',
      callingCode: '',
    };

    this.countryname = this.Certificat_origin_document.countryOfOrigin;

    this.Valuedmanifacture = {
      name: '',
      alpha2Code: this.Certificat_origin_document.countryOfManifacture,
      alpha3Code: '',
      numericCode: '',
      callingCode: '',
    };

    this.countrynamemanifacture =
      this.Certificat_origin_document.countryOfManifacture;

    if (this.transactiontype == 'Update') {
      this.Article_DATA = this.Certificat_origin_document.items;
    } else {
      this.remplirItems(this.Certificat_origin_document.items);
    }
    setTimeout(() => {
      this.dataSource = new MatTableDataSource(this.Article_DATA);
      this.spinner.hide();
    }, 300);
  }

  Elements_DATA: any;
  document_item: any;
  Arr_view_Elements(content: any, row: any) {
    this.document_item = row;
    this.Elements_DATA = this.document_item.elements;
    setTimeout(() => {
      this.dataSourceElement = new MatTableDataSource(this.Elements_DATA);
      this.open(content);
    }, 300);
  }

  formatDate(date: any) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  remplirItems(items: any) {
    for (let item of items) {
      let element_1 = item.elements;
      let element_f = [];
      for (let element of element_1) {
        element_f.push({
          name: element.name,
          value: element.value,
          quantity: element.quantity,
        });
      }

      this.Article_DATA.push({
        marksAndNumberOfPackage: item.marksAndNumberOfPackage,
        descriptionOfGoods: item.descriptionOfGoods,
        netWeight: item.netWeight,
        numberAndKindOfPackage: item.numberAndKindOfPackage,
        grossWeight: item.grossWeight,
        quantity: item.quantity,
        proceedsForeign: item.proceedsForeign,
        currencyValue: item.currencyValue,
        finalCostOfProduction: item.finalCostOfProduction,
        totalValue: item.totalValue,
        elements: element_f,
      });
    }
  }
}
