import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {
  currentSlide = 0;
  showModal = false; // État pour afficher ou masquer la popup
  intervalId: any;
  slides = [
    { src: "../../../assets/bof.png",tittle:"Bill Of Lidding",description:"A bill of lading (BL or BoL) is a legal document issued by a carrier (transportation company) to a shipper when the carrier delivers the goods at a predetermined destination." },
    { src: "../../../assets/bank-service.jpg",tittle:"FDE",description:"L’exportateur peut déposer la facture définitive, pour domiciliation, auprès d’un intermédiaire agréé soitdans le cadre du système intégré de traitement automatisé des formalités de commerce extérieur ou directementauprès de l’intermédiaire agréé" },
    { src: "../../../assets/lc.jpg",tittle:"CX5",description:"L'assurance rapatriement couvre le retour anticipé dans son pays du voyageur en cas d'accident ou de maladie survenus lors d'un séjour à l'étranger." },
    { src: "../../../assets/lc.jpg",tittle:"FoProdEx",description:"Le Fonds de Promotion des Exportations FOPRODEX, est un fonds spécial de trésor financé par le Ministère des Finances sous la tutelle du Ministère du Commerce et de l'Artisanat et géré par le CEPEX" }
  ];
  constructor() { }
  ngOnInit(): void {
    this.intervalId = setInterval(() => {
      this.onNextClick();
    }, 4000);
  }
  onPreviousClick() {
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
    console.log("previous clicked, new current slide is: ", this.currentSlide);
  }

  onNextClick() {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.slides.length ? 0 : next;
    console.log("next clicked, new current slide is: ", this.currentSlide);
  }
  // Fonction pour afficher la popup
  onSeeExampleClick() {
    this.showModal = true;
    this.stopAutoSlide();

  }

  // Fonction pour fermer la popup
  closeModal() {
    this.showModal = false;
    this.startAutoSlide();

  }
  stopAutoSlide() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
  startAutoSlide() {
    this.intervalId = setInterval(() => {
      this.onNextClick();
    }, 2000);
  }

}
