<section class="pt-5 pb-5">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div
          id="carouselExampleIndicators2"
          class="carousel slide"
          data-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row">
                <div class="col-md-4 mb-3">
                  <div class="card">
                    <div
                      class="image"
                      style="
                        background: url('../../../assets/e-fret/cmr.avif');
                        background-position: center;
                        background-size: cover;
                      "
                    ></div>
                    <div class="card-body">
                      <h4 class="card-title">CMR Quotation</h4>
                      <p class="card-text">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Perferendis quis molestiae vitae eligendi at.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-3">
                  <div class="card">
                    <div
                      class="image"
                      style="
                        background: url('../../../assets/e-fret/cmr1.avif');
                        background-position: center;
                        background-size: cover;
                      "
                    ></div>
                    <div class="card-body">
                      <h4 class="card-title">CMR Booking</h4>
                      <p class="card-text">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Perferendis quis molestiae vitae eligendi at.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-3">
                  <div class="card">
                    <div
                      class="image"
                      style="
                        background: url('../../../assets/e-fret/bill\ of\ leding.png');
                        background-position: center;
                        background-size: cover;
                      "
                    ></div>
                    <div class="card-body">
                      <h4 class="card-title">Bill Of Lading</h4>
                      <p class="card-text">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Perferendis quis molestiae vitae eligendi at.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row">
                <div class="col-md-4 mb-3">
                  <div class="card">
                    <div
                      class="image"
                      style="
                        background: url('../../../assets/e-fret/ship-arrival.jpg');
                        background-position: center;
                        background-size: cover;
                      "
                    ></div>
                    <div class="card-body">
                      <h4 class="card-title">Shipping Arrival</h4>
                      <p class="card-text">
                        With supporting text below as a natural lead-in to
                        additional content.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-3">
                  <div class="card">
                    <div
                      class="image"
                      style="
                        background: url('../../../assets/e-fret/Shipping\ Departure.jpg');
                        background-position: center;
                        background-size: cover;
                      "
                    ></div>

                    <div class="card-body">
                      <h4 class="card-title">Shipping Departure</h4>
                      <p class="card-text">
                        With supporting text below as a natural lead-in to
                        additional content.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-3">
                  <div class="card">
                    <div
                      class="image"
                      style="
                        background: url('../../../assets/e-fret/Ship\ Docking.jpg');
                        background-position: center;
                        background-size: cover;
                      "
                    ></div>

                    <div class="card-body">
                      <h4 class="card-title">Ship Docking</h4>
                      <p class="card-text">
                        With supporting text below as a natural lead-in to
                        additional content.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row">
                <div class="col-md-4 mb-3">
                  <div class="card">
                    <div
                      class="image"
                      style="
                        background: url('../../../assets/e-fret/Ship\ Docking.jpg');
                        background-position: center;
                        background-size: cover;
                      "
                    ></div>

                    <div class="card-body">
                      <h4 class="card-title">Ship Docking</h4>
                      <p class="card-text">
                        With supporting text below as a natural lead-in to
                        additional content.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-3">
                  <div class="card">
                    <div
                    class="image"
                    style="
                      background: url('../../../assets/bgg.jpg');
                      background-position: center;
                      background-size: cover;
                    "
                    >
                    </div>
                    <div class="card-body">
                      <h4 class="card-title">Cargo Manifes</h4>
                      <p class="card-text">
                        With supporting text below as a natural lead-in to
                        additional content.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-3">
                  <div class="card">
                    <div
                      class="image"
                      style="
                      background: url('../../../assets/air.jpg');
                      background-position: center;
                      background-size: cover;
                      "
                    >
                    </div>
                    <div class="card-body">
                      <h4 class="card-title">LTA</h4>
                      <p class="card-text">
                        With supporting text below as a natural lead-in to
                        additional content.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 text-center">
        <a
          class="btn btn-primary mb-3"
          style="margin: 2%"
          href="#carouselExampleIndicators2"
          role="button"
          data-slide="prev"
        >
          <i class="fa fa-arrow-left"></i>
        </a>
        <a
          class="btn btn-primary mb-3"
          href="#carouselExampleIndicators2"
          style="margin: 2%"
          role="button"
          data-slide="next"
        >
          <i class="fa fa-arrow-right"></i>
        </a>
      </div>
    </div>
  </div>
</section>