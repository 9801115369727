<app-navbar [item]="display_nav_link"></app-navbar>
<div class="containerbg">
  <div class="auth_container">
    <div class="left_form" style="padding: 10px;">
      <h3 class="title" style="color: #0d6dfdda;">Bienvenu dans Tunisia-Export-Services</h3>
      <p style="text-align: justify;  color: #FFFF; font-size: 16px;" >Tunisia-Export-Services a pour mission de faciliter vos transactions commerciales à l’international grâce à des canaux d’échanges électroniques directs avec les principaux acteurs du commerce extérieur: COTUNACE, BANQUES, CEPEX, DOUANES, CHAMBRES DE COMMERCE, TRANSPORTEURS, …</p>
      <p style="text-align: left; color: #FFFF; font-size: 16px;" class="resposive">Assurance Crédit; Financement ;Conformité règlementaire; Logistique ; Services d’Appui; ….  Nous vous offrons des solutions de transmission et d’échange en ligne de vos documents auprès des services de l’ensemble des acteurs du commerce extérieur connectés à notre plateforme.</p>
      <p style="text-align: left; color: #FFFF; font-size: 16px;" class="resposive">Tunisia-Export-Services vous offre des solutions métiers en mode SaaS et  vous offre l’opportunité d’un pilotage à distance , simplifié et à moindre cout, de l’ensemble de vos démarches à l’import et à l’export.</p>
      <input class="button"  style="width: 180px;" type="button" value="commencer"/>
    </div>
    <div class="right_img" style="background: none;">
      <!-- Indicateurs -->
  <div class="carousel-indicators">
    <span class="indicator" *ngFor="let image of carouselImages; let i = index" [class.active]="i === currentIndex"
          (click)="goToImage(i)"></span>
  </div>
  <!-- Carrousel -->
      <div class="main-carousel">
        <div class="main-carousel-item" *ngFor="let image of carouselImages; let i = index" [style.backgroundImage]="'url(' + image + ')'"
             [style.transform]="'translateX(' + (-currentIndex * 100) + '%)'">
        </div>
      </div>
      <!-- Flèches de navigation -->
      <div class="carousel-navigation">
        <button class="carousel-button prev" (click)="prevImage()"> <img src="../../../assets/icons/next.png" width="25px"/> </button>
        <button class="carousel-button next" (click)="nextImage()"> <img src="../../../assets/icons/next.png" width="25px"/> </button>
      </div>
    </div>
  </div>
</div>
<!-- Partenaires -->
 <div class="partenaires">
  <div class="img" style="">Nos Partenaires </div>
  <div style="width: 100%; height: 200px">
    <app-alliances-strategiques></app-alliances-strategiques>
  </div>
 </div>
<!-- why you need Us -->
<div class="needus-container">
  <div class="needus-image">
    <div class="experience">
      <p>25</p>
      <span>ans d’expérience</span>
    </div>
  </div>
  <div class="needus-content">
    <h2><img style="width: 100px; margin-right: 10px;" src="../../../assets/ceo.jpg" /> Quel est notre apport ?</h2>
    <p class="quote">" une Vision et une Connaissance Globale du parcours procédural des chaines de l’export et de l’import , combinées à la bonne volonté et à l’engagement des acteurs institutionnels et privés de la chaine export, nous a permis de concevoir et de vous proposer des solutions de services en ligne efficientes.
      "</p>
    <p class="def">Nos solutions vous permettrons d’anticiper vos démarches et d’adopter une stratégie de gestion proactive de votre chaine d’approvisionnement et de vos procédures d’exportation,</p>
    <p class="def">Notre approche innovante,  et l’évolution continue de nos services et des domaines d’expertise , vous aideront à être réactif pour fidéliser vos clients et à acceder plus efficament aux marchés internationaux.
    <p class="def">Un annuaire des conseillers à l’export et d’acteurs logistique sera à votre disposition pour vous accompagner dans votre expansion à l’international</p>
    <p class="author"><span>  Gharbi Karim -</span> CEO de MSS Consulting</p>
  </div>
</div>

<!-- Nos Domaines d’Intervention-->
<div class="oup-container">
  <h2 style="color: #007bff; font-weight: 800;">Nos Domaines d’Intervention</h2>
  <div class="oup-container-grid">
    <div class="grid-item grid-item1">
      <div class="head-item">
        <div class="icon-oup">
          <img src="../../../assets/icons/fleches.png" />
        </div>
        <h4>E-Export</h4>
      </div>
      <p class="centered-text">Toutes les formalités requises pour mener une 
        opération...
      </p>
      <!-- suite paragraphe de E-export 
       <p> d’export sont digitalisées sur notre 
        plateforme: certificat d’origine, couverture de 
        risque & assurance crédit, caution douanière, 
        Domiciliation de Facture, CX5, Foprodex, E-fret, 
        e-BoL, ...</p>
      -->
      <div class="btn-div-right ">
        <button >Read more <img src="../../../assets/icons/show-more.png" width="10px" style="margin-left: 0%;"/></button>
      </div>
    </div>
    <div class="grid-item grid-item2">
      <div class="head-item">
        <div class="icon-oup">
          <img src="../../../assets/icons/trade.png" />
        </div>
        <h4 >E-Trade</h4>  
      </div>
      <p class="centered-text">Votre demande d’ouverture de Lettre de Crédit au profit de votre...
      </p>
      <!-- suite de pargraphe de e-trade 
       <p> de votre fournisseur et votre demande de garantie bancaire pour activer la réception de vos marchandises sont réunies dans ce bouquet de services en ligne qui vous relie à votre Banque,</p>
      -->
      <div class="btn-div-right ">
        <button >Read more <img src="../../../assets/icons/show-more.png" width="15px;" /></button>
      </div>
    </div>
    <!--<div class="grid-item grid-item3">
      <h2>Get Free Information </h2>
      <p>Bring to the table win-win survival strategies to ensure proactive domination.</p>
      <form  class="fromulair">
        <div class="button_group" >
          <label>Your name</label>
          <input type="text"/>
        </div>
        <div class="button_group">
          <label>Your name</label>
          <input type="text"/>
        </div>
        <div class="button_group">
          <label>Your name</label>
          <input type="text"/>
        </div>
        <div class="button_group">
          <label>Your name</label>
          <textarea></textarea>   
        </div>
        <div class="button_group">
          <button class="button_message">
            Send Message
          </button>
        </div>
      </form>
    </div>-->
    <div class="grid-item grid-item4">
      <div class="head-item">
        <div class="icon-oup">
          <img src="../../../assets/icons/transport-maritime.png" />
        </div>
        <h4>E-Costum</h4>
      </div>
      <p class="centered-text">Tous les formulaires liés à vos démarches avec les services ...
      </p>
      <!-- suite de paragraphe de  E-costum 
       <p>de contrôle aux frontières seront exposés dans ce bouquet de services pour vous éviter les déplacements multiples et recevoir les autorisations par voie électronique </p>
       -->
      <div class="btn-div-right ">
        <button >Read more <img src="../../../assets/icons/show-more.png" width="15px" /></button>
      </div>
    </div>
    <div class="grid-item grid-item5">
      <div class="head-item">
        <img src="../../../assets/icons/eceive-dollar.png" class="icon-oup"/>
        <h4>E-Fret</h4>
      </div>
      <p class="centered-text">Trouver votre prestataire logistique qui acheminera vos marchandises aux meilleures conditions
      </p>
      <div class="btn-div-right ">
        <button >Read more <img src="../../../assets/icons/show-more.png" width="15px"  /></button>
      </div>
    </div>
  </div>
</div>

<!-- assurence-credit  -->
<div id="assurance-credit">
  <h2 style="color: #007bff; font-weight: 800; text-align: center; padding-top: 5%;">Assurance Credit</h2>
    <!-- dhamen fianance -->
    <div class="flex-div" id="dhamen-f">
      <div class="second-div">
        <h2>DHAMEN FINANCE</h2>
        <p>
          La garantie «Dhamen Finance» est votre garant auprès de votre banque pour préfinancer vos exportations
        </p>
          <div class="btn-div-right ">
            <button >Learn more <img src="../../../assets/icons/show-more.png" width="15px" style="margin-left: 5%;"/></button>
          </div>
      </div>
      <div class="first-div dhamen-bg">
      </div>
    </div>

  <!-- Etude cotunace  -->
  <div class="flex-div " id="e-cotunace">
    <div class="first-div cotunace-bg">
    </div>
    <div class="second-div">
      <h2>Etude Cotunace</h2>
      <p>
        Our professional leadership team is truly committed to producing the best results for our clients very successfully..
        Bring to the table win-win survival strategies to ensure proactive domination. At the end of the day, going forward,
        a new normal that has evolved from generation.
        Our professional leadership team is truly committed to producing the best results for our clients very successfully..
        Bring to the table win-win survival strategies to ensure proactive domination. At the end of the day, going forward,
        a new normal that has evolved from generation.
        Our professional leadership team is truly committed to producing the best results for our clients very successfully..
        Bring to the table win-win survival strateg
        </p>
        <div class="btn-div">
          <button >Learn more <img src="../../../assets/icons/show-more.png" width="15px" style="margin-left: 5%;"/></button>
        </div>
    </div>
  </div>

  <!-- agrement -->
  <div class="flex-div" id="dhamen-f">
    <div class="second-div">
      <h2> Demande D'agrement</h2>
      <p>
        Vous êtes une entreprise exportatrice résidente ou non résidente en possession d’un contrat ou d’un bon de commande 
        à l’exportation de biens ou/et de services, et vous souhaitez préfinancer vos exportations en sollicitant un crédit auprès de votre banque
         : La garantie «Dhamen Finance» est votre garant auprès de votre banque émettrice du crédit en question.
        </p>
        <div class="btn-div-right ">
          <button >Learn more <img src="../../../assets/icons/show-more.png" width="15px" style="margin-left: 5%;"/></button>
        </div>
    </div>
    <div class="first-div dhamen-bg">
    </div>
  </div>
    
  <!-- Mandat -->
    <div class="flex-div" id="mandat">
      <div class="first-div agrement-bg">
      </div>
      <div class="second-div">
        <h2> COTUNACE Mandat</h2>
        <p>
          Vous êtes une entreprise exportatrice résidente ou non résidente en possession d’un contrat ou d’un bon de commande 
          à l’exportation de biens ou/et de services, et vous souhaitez préfinancer vos exportations en sollicitant un crédit auprès de votre banque
           : La garantie «Dhamen Finance» est votre garant auprès de votre banque émettrice du crédit en question.
          </p>
          <div class="btn-div ">
            <button >Learn more <img src="../../../assets/icons/show-more.png" width="15px" style="margin-left: 5%;"/></button>
          </div>
      </div>
    </div>
  <!-- transfert COTUNACE Transfert-->
  <div class="flex-div" id="dhamen-f">
    <div class="second-div">
      <h2> COTUNACE Transfert</h2>
      <p>
        Vous êtes une entreprise exportatrice résidente ou non résidente en possession d’un contrat ou d’un bon de commande 
        à l’exportation de biens ou/et de services, et vous souhaitez préfinancer vos exportations en sollicitant un crédit auprès de votre banque
         : La garantie «Dhamen Finance» est votre garant auprès de votre banque émettrice du crédit en question.
        </p>
        <div class="btn-div-right ">
          <button >Learn more <img src="../../../assets/icons/show-more.png" width="15px" style="margin-left: 5%;"/></button>
        </div>
    </div>
    <div class="first-div dhamen-bg">
    </div>
  </div>
  <!-- Caution douanière -->
    <div class="flex-div" id="transfert">
      <div class="first-div agrement-bg">
      </div>
      <div class="second-div">
        <h2>Caution douanière</h2>
        <p>
          Vous êtes une entreprise exportatrice résidente ou non résidente en possession d’un contrat ou d’un bon de commande 
          à l’exportation de biens ou/et de services, et vous souhaitez préfinancer vos exportations en sollicitant un crédit auprès de votre banque
           : La garantie «Dhamen Finance» est votre garant auprès de votre banque émettrice du crédit en question.
          </p>
          <div class="btn-div ">
            <button >Learn more <img src="../../../assets/icons/show-more.png" width="15px" style="margin-left: 5%;"/></button>
          </div>
      </div>
    </div>    
   </div>

<!-- Trade Finance -->
<div class="e-trade-sec" id="Trade Finance">
  <h2 style="color: #007bff; font-weight: 800;">Trade Finance</h2>
  <p class="def">À travers ce bouquet de services vous pouvez (1) soumettre vos documents pour bénéficier du soutien 
    du Foprodex, (2) acceder aux instruments financiers qui vous sont nécessaires pour opérer vos 
    formalités bancaires et de contrôle de change en toute quiétude.</p>
  <div>
<!-- carousel -->
 <div class="carousel" id="carousel">
  <div class="first-sec" id="first-sec">
    <h1 class="title-bank" id="title-bank">Service Bancaire</h1>
  </div>
  <div class="second-sec" id="carousel-sec">
    <app-carousel></app-carousel>  
  </div>
 </div>
 <!-- E-trade section -->
  <div class="e-trade-sec" id="e-trade">
    <h2 style="color: #007bff; font-weight: 800;">E-Trade</h2>
    <p class="def">Les services en ligne présentés sous cette rubrique concernent pour le moment les formalités nécessaires à 
      accomplir (1) auprès de votre Banque pour initier une importation de marchandise en procédant à l’ouverture d’une 
      lettre de crédit ou pour obtenir une garantie bancaire pour vous faire livrer votre marchandise en souffrance chez 
      votre transporteur (2) auprès de votre chambre de commerce pour obtenir votre certificat d’origine afin de la 
      remettre à votre client.</p>
    <div class="custom-card">

    <!-- LOC -->
     <div class="e-trade-container-flex">
      <div class="trade-item-flex">
        <div class="img-bg" style="background: url('../../../assets/lettreofcredit.jpg'); background-position: center;background-size: cover;"></div>
        <div class="description-content">
           <h4 class="card-title">
            Lettre de Credit  </h4>
            <p class="card-text">Vous pouvez adresser à votre banque une...</p>
            <!--suite de paragraphe : demande d’ouverture de L/C en version 
              électronique. Vous pourrez ensuite 
              envoyer la réponse de la banque au 
              vendeur pour preuve que vous avez 
              garanti le paiement de votre transaction.-->
            <input type="button" class="read-more" value="Read More"/>
        </div>
      </div>
      <div class="trade-item-flex">
        <div class="img-bg" style="background: url('../../../assets/cof.jpg'); background-position: center;background-size: cover;"></div>
        <div class="description-content">
           <h4 class="card-title">
            Certificat d’origine</h4>
            <p class="card-text">Déposez votre demande et obtenezvotre certificat d’origine en ligne...
            </p>
            <!-- suite de paragraphe :Le 
              certificat d’origine sert à prouver 
              l’origine des marchandises pour 
              satisfaire les exigences douanières ou 
              commerciales et atteste qu’elles sont 
              originaires de la Tunisie, ou, sur 
              justificatifs, d’un pays tiers..-->
            <input type="button" class="read-more" value="Read More"/>
        </div>
      </div>
      <div class="trade-item-flex">
        <div class="img-bg" style="background: url('../../../assets/shipping-g.jpg'); background-position: center;background-size: cover;"></div>
        <div class="description-content">
           <h4 class="card-title">
            Garantie Bancaire</h4>
            <p class="card-text">Obtenez votre lettre de garantie bancaire en ligne sans vous déplacer...
            </p>
            <!--suite de paragraphe : La lettre de garantie à l’arrivée vous 
              permettra de prendre livraison de votre 
              marchandise en l’absence du 
              connaissement original. Elle garanti le 
              transporteur contre toute réclamation 
              dont il pourrait faire l'objet de la part du 
              porteur régulier du connaissement.-->
            <input type="button" class="read-more" value="Read More"/>
        </div>
      </div>
     </div>
    <!-- <div class="card">


    <div class="card">
        <img class="img-fluid" alt="100%x280"
            src="../../../assets/shipping-g.jpg">
        <div class="card-body">
            <h4 class="card-title">
              shipping guarante</h4>
            <p class="card-text">Bring to the table win-win survival strategies to ensure proactive domination.</p>
            <input type="button" class="read-more" value="Read More"/>


        </div>
    </div> -->

   
    </div>
  
    
  </div>
<!-- E-Fret -->
  <div class="what-w-o" id="e-fret" >
    <h2 style="color: #007bff; font-weight: 800;">E-Fret</h2>
    <p>Lorem ipsum dolor sit amet.</p>

    <div class="container-what-w-o" id="container-what-w-o">
      <div class="flex-card second-card"> 
        <img src="../../../assets/icons/Air Freight.png"  width="60px"/>
         <div>
          <h5 style="text-align: left; font-family: 'Poppins';">TransportAérien</h5>      
          <p style="color: #111111; font-size:14px; text-align:left">
            Demander un devis et Réserver votre transport avec votre compagnie aérienne
          </p>
            <div class="learn-more">
              <p style="margin-top: 18%; color: #007bff; text-align: left;">Learn More</p>
            </div>
          </div>
      </div>


      <div class="flex-card second-card"> 
        <img src="../../../assets/icons/Ocean_Freight.png"  width="60px"/>
         <div>
          <h5 style="text-align: left; font-family: 'Poppins';">Transport Maritime</h5>      
          <p style="color: #111111; font-size:14px; text-align:left">
            Toutes les formalités Portuaires de l’Agent Maritime et toutes les formalités de reservation de transport 
            (BoL) sont disponibles sur notre plateforme
          </p>
            <div class="learn-more">
              <p style="margin-top: 6%; color: #007bff; text-align: left;">Learn More</p>
            </div>
          </div>
      </div>
      <div class="flex-card therd-card"> 
        <img src="../../../assets/icons/Ground Shipping.png"  width="50px"/>
         <div>
          <h5 style="text-align: left; font-family: 'Poppins';">TransportTerrestre</h5>
            <p style="color: #111111; font-size:13px; text-align:justify">
              Demander un devis, reserver votre 
              transport et suivre votre marchandise 
              par GPS jusqu’à sa livraison...
            </p>
            <div class="learn-more">
              <p style="margin-top: 2%; color: #007bff; text-align: left;">Learn More</p>
            </div>
          </div>
      </div>
    </div>
  </div>
  <!-- E-Fret section -->
  <div class="efret-sec">
  <app-image-slider></app-image-slider>
  </div>
  <!-- E-costum -->
   <div class="costum-sec" id="costum-sec">
    <h2 style="color: #007bff; font-weight: 800;">E-Douanes</h2>
    <div class="flex-item">
      <div class="item">
        <h4>Déclaration en Douanes</h4>
        <p class="card-text">Bientôt il vous sera possible de soumettre vos 
          cautions douanières associées à vos 
          declarations en douanes pour les régimes 
          suspensifs.</p>
        <div class="btn-card">
          <input type="button" class="read-more" value="Read more"/>
        </div>
      </div>
      <div class="item">
        <h4>Contrôle Technique</h4>
        <p class="card-text">Les formalités d’échanges des documents 
          de contrôle technique sont implémentées 
          et seront opérationnelles dès l’achèvement 
          des tests avec les structures concernées.</p>
        <div class="btn-card">
          <input type="button" class="read-more" value="Read more"/>
        </div>
      </div>
      <div class="item">
        <h4> FDE </h4>
        <p class="card-text">Les sites marchands grâce à notre plateforme 
          seront désormais connectés à leurs banques 
          pour domicilier leurs transactions à l’export.</p>
        <div class="btn-card">
          <input type="button" class="read-more" value="Read more"/>
        </div>
      </div>      
    </div>
   </div>
<app-footer></app-footer>
<app-level-up></app-level-up>
