import {
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { WebSocketSubject } from 'rxjs/webSocket';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';

import { VisitPurposeService } from '../../Shipping_Arrival/Service/VisitPurpose/visit-purpose.service';
import { CargoSummaryService } from '../../Shipping_Arrival/Service/CargoSummary/cargo-summary.service';
import { PassengerSummaryService } from '../../Shipping_Arrival/Service/PassengerSummary/passenger-summary.service';
import { WaybillsService } from '../Service/Waylbills/waybills.service';
import { ManifestService } from '../Service/Manifest/manifest.service';
import { DatePipe } from '@angular/common';
import { MessageUserService } from 'src/Services/MessagesUser/message-user.service';
import jsPDF from 'jspdf';
import { Router } from '@angular/router';
import { OrganisationService } from 'src/Services/Organisation/organisation.service';
import { SelectionModel } from '@angular/cdk/collections';

import Langue from '../../../../../assets/langue.json';
import { Title } from '@angular/platform-browser';
import { UserService } from 'src/Services/User/user.service';
import { AffectationService } from 'src/Services/Affectation/affectation.service';
import { NotificationService } from 'src/Services/Notification/notification.service';
import { NotifierService } from 'angular-notifier';
@Component({
  selector: 'app-liste-cargo',
  templateUrl: './liste.component.html',
  styleUrls: ['./liste.component.css'],
})
export class ListeComponent implements OnInit {
  langue: any;
  direction: any;
  languef: any;
  langue_list: any[] = Langue;
  languea: any;
  languem: any;
  languet: any;
  displayedColumns: string[] = [
    'select',
    'no',
    'vessel',
    'dateofarrival',
    'assign',
    'dischargingport',
    'etat',
    'action',
  ];

  displayedColumnsActions: string[] = [
    'id',
    'status',
    'startDate',
    'affectedto',
    'action',
  ];
  dataSource: MatTableDataSource<any>;
  itemlist: any[] = ['Date of arrival', 'Date of departure'];
  statuslist: any[] = ['Save', 'For confirmation', 'Confirmed'];
  selectedtype: String | any;
  selectedstatus: String | any;
  CellList: any[] = [];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;

  vesselname: any;
  vesseltype: any;
  vesselbuildyear: any;
  vesselregport: any;
  vesseldraft: any;
  vessellength: any;
  vesselgrossweight: any;
  vesselnetweight: any;
  voyageno: any;
  callsigntype: any;
  callsign: any;
  ownercode: any;
  ownername: any;
  shippingagentcode: any;
  shippingagent: any;
  shipmaster: any;
  eta: any;
  etd: any;
  previousportcode: any;
  previousportname: any;
  portofcallcode: any;
  portofcallname: any;
  nextportcode: any;
  nextportname: any;
  preferreddock: any;
  berthtype: any;
  dangerousgoods: any;

  vessel: any;
  voyagenumber: any;
  dateofarrival: any;
  timeofarrival: any;
  customoffice: any;
  dateofdeparture: any;
  placeofdeparture: any;
  placeofdistination: any;
  dischargingport: any;
  loadingport: any;
  finaldestination: any;
  carrier: any;
  transportationcompanycode: any;
  mode: any;
  identity: any;
  nationality: any;
  place: any;
  registration: any;
  date: any;
  master: any;
  bills: any;
  packages: any;
  containers: any;
  grossmass: any;
  blreferencenumber: any;
  blnumber: any;
  bltype: any;
  exportername: any;
  consigneename: any;

  constructor(
    private userService: UserService,
    private http: HttpClient,
    private VisitPurposeApi: VisitPurposeService,
    private CargoSummaryApi: CargoSummaryService,
    private PassengerSummaryApi: PassengerSummaryService,
    private WaybillsApi: WaybillsService,
    private ManifestApi: ManifestService,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private MessagesUser: MessageUserService,
    private router: Router,
    private organisationService: OrganisationService,
    private titleService: Title,
    private affecationService: AffectationService,
    private notificationApi: NotificationService,
    private notifier: NotifierService
  ) {
    if (localStorage.getItem('langue') != null) {
      this.langue = localStorage.getItem('langue') + '';
    } else {
      this.langue = 'AR';
    }

    if (this.langue == 'AR') {
      this.direction = 'rtl';
      this.languef = this.langue_list[0]['AR'].tablelist;
      this.languea = this.langue_list[0]['AR'].CM;
      this.languem = this.langue_list[0]['AR'].messageuser;
      this.languet = this.langue_list[0]['AR'].modal_text;

      this.copylist = this.copylist_AR;
    }

    if (this.langue == 'EN') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['EN'].tablelist;
      this.languea = this.langue_list[0]['EN'].CM;
      this.languem = this.langue_list[0]['EN'].messageuser;
      this.languet = this.langue_list[0]['EN'].modal_text;

      this.copylist = this.copylist_EN;
    }

    if (this.langue == 'FR') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['FR'].tablelist;
      this.languea = this.langue_list[0]['FR'].CM;
      this.languem = this.langue_list[0]['FR'].messageuser;
      this.languet = this.langue_list[0]['FR'].modal_text;

      this.copylist = this.copylist_EN;
    }

    this.titleService.setTitle(this.languet.entete);
    this.dataSource = new MatTableDataSource(this.CellList);

    this.RoleTeammanager = this.userService.ValidRole('Team manager');
    this.RoleAgent = this.userService.ValidRole('Agent');
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  list_delete: any[] = [];
  Arr_remove() {
    this.list_delete = [];
    this.selection.selected.forEach((s) => this.list_delete.push(s));

    for (let cell of this.list_delete) {
      let cel = this.CellList_1.find((x) => x.id == cell.id);
      if (cel.etat == 'Save') {
        setTimeout(() => {
          this.ManifestApi.delete(cel.id).subscribe((res: any) => {
            setTimeout(() => {
              this.GetCargoManifest();
            }, 700);
          });
        }, 500);
      }
    }
    setTimeout(() => {
      this.modalService.dismissAll();
      this.ClearAll();
    }, 300);
  }
  document: any;
  Arr_view(content: any, cargomanifest: any) {
    this.document = cargomanifest;
    this.modalService.open(content, { size: 'xl', windowClass: 'dark-modal' });
  }
  Arr_update() { }
  CellList_1: any[] = [];

  Create_form(call: any) {
    let vessel = call.vessel;
    this.vesselname = vessel.vessel;
    this.vesseltype = vessel.vesseltype;
    this.vesselbuildyear = vessel.vesselbuildyear;
    this.vesselregport = vessel.vesselregport;
    this.vesseldraft = vessel.vesseldraft;
    this.vessellength = vessel.vessellength;
    this.vesselgrossweight = vessel.vesselgrossweight;
    this.vesselnetweight = vessel.vesselnetweight;
    this.voyageno = vessel.voyageno;
    this.voyageno = call.voyageno;
    this.callsigntype = call.callsigntype;
    this.callsign = call.callsign;
    this.ownercode = call.ownercode;
    this.ownername = call.ownername;
    this.shippingagentcode = call.shippingagentcode;
    this.shippingagent = call.shippingagent;
    this.shipmaster = call.shipmaster;
    this.eta = call.eta;
    this.etd = call.etd;
    this.previousportcode = call.previousportcode;
    this.previousportname = call.previousportname;
    this.portofcallcode = call.portofcallcode;
    this.portofcallname = call.portofcallname;
    this.nextportcode = call.nextportcode;
    this.nextportname = call.nextportname;
    this.preferreddock = call.preferreddock;
    this.berthtype = call.berthtype;
    this.dangerousgoods = call.dangerousgoods;

    this.vessel = call.vessel;
    this.voyagenumber = call.voyagenumber;
    this.dateofarrival = call.dateofarrival;
    this.timeofarrival = call.timeofarrival;
    this.customoffice = call.customoffice;
    this.dateofdeparture = call.dateofdeparture;
    this.placeofdeparture = call.placeofdeparture;
    this.placeofdistination = call.placeofdistination;
    this.dischargingport = call.dischargingport;
    this.loadingport = call.loadingport;
    this.finaldestination = call.finaldestination;
    this.carrier = call.carrier;
    this.transportationcompanycode = call.transportationcompanycode;
    this.mode = call.mode;
    this.identity = call.identity;
    this.nationality = call.nationality;
    this.place = call.place;
    this.registration = call.registration;
    this.date = call.date;
    this.master = call.master;
    this.bills = call.bills;
    this.packages = call.packages;
    this.containers = call.containers;
    this.grossmass = call.grossmass;
    this.blreferencenumber = call.blreferencenumber;
    this.blnumber = call.blnumber;
    this.bltype = call.bltype;
    this.exportername = call.exportername;
    this.consigneename = call.consigneename;

    this.Waybills = call.Waybills;
  }

  CellList_2: any[] = [];

  GetCargoManifest() {
    if (this.RoleTeammanager) {
      if (this.role_organisation == 'Shipping_agent') {
        this.ManifestApi.GetByteam().subscribe((res: any) => {
          this.Remplir_list(res);
        });
      } else {
        this.ManifestApi.GetByOrg().subscribe((res: any) => {
          this.Remplir_list(res);
        });
      }
    } else {
      this.ManifestApi.GetByUser().subscribe((res: any) => {
        this.Remplir_list(res);
      });
    }
  }

  Remplir_list(list: any) {
    this.CellList_2 = list;

    let resultA = this.CellList_2.filter(
      (elm) =>
        !this.CellList_1.map((elm) => JSON.stringify(elm)).includes(
          JSON.stringify(elm)
        )
    );

    let resultB = this.CellList_1.filter(
      (elm) =>
        !this.CellList_2.map((elm) => JSON.stringify(elm)).includes(
          JSON.stringify(elm)
        )
    );

    if (resultA.length > 0 || resultB.length > 0) {
      this.CellList_1 = this.CellList_2;
      this.CellList = this.CellList_2;
      this.dataSource = new MatTableDataSource(this.CellList_1);
      this.dataSource.paginator = this.paginator;
      this.CellList_2 = [];
    }
  }

  createnewCell(id: any, voyageno: any, vessel: any) {
    return {
      id: id,
      voyageno: voyageno,
      vessel: vessel,
    };
  }
  premission: any;
  @Input() profil: string;
  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.Getorganisation();
    this.GetPremmision();
    //this.Websocket_cm();
    this.GetIdUser();

    if (this.profil == 'Customs') {
      this.premission = 'CM_A2.1_LE';
      this.actionlist = [{ id: '2', name: this.languef.submit }];
    } else {
      this.premission = 'CM_A1.1_LE';
      this.actionlist = [
        // { id: '1', name: this.languef.save },
        { id: '2', name: this.languef.submit },
      ];
    }
  }

  changestatus(event: any) {
    if (event == undefined) {
      this.GetCargoManifest();
      this.id_search1 = 0;
    } else {
      this.id_search1 = 1;

      this.CellList = [];

      this.listrecherchr = this.CellList_1.filter((e) => e.etat == event);

      setTimeout(() => {
        this.dataSource = new MatTableDataSource(this.listrecherchr);
        this.dataSource.paginator = this.paginator;
      }, 500);
    }
  }

  valeurcondition: String;
  id_search: any;
  valeur: any = '';
  attr: any = '';
  changeconditionitem(event: any) {
    if (event == undefined) {
      this.valeurcondition = '';
      this.GetCargoManifest();
      this.id_search1 = 0;
    } else {
      this.id_search = event.id;
      this.attr = event.code;
    }
  }

  search() {
    this.id_search1 = 2;
    if (this.id_search == 1) {
      this.ManifestApi.Serach(this.attr, this.valeurcondition).subscribe(
        (res: any) => {
          this.listrecherchr = res;
        }
      );
    } else if (this.id_search == 2) {
      this.listrecherchr = this.CellList_1.filter(
        (e) => e.vessel.vessel == this.valeurcondition
      );
    }

    if (this.id_search != undefined) {
      setTimeout(() => {
        this.dataSource = new MatTableDataSource(this.listrecherchr);
        this.dataSource.paginator = this.paginator;
      }, 800);
    }
  }
  changetype(event: any) {
    this.typerecherche = event;
    if (event == undefined || event == null) {
      this.GetCargoManifest();
      this.endDate = '';
      this.startDate = '';
      this.date_disabled = true;
    } else {
      this.date_disabled = false;
    }
  }
  Manifest: any[] = [];
  Waybills: any[] = [];
  PassengerSummarysList: any[] = [];
  VisitPurposesList: any[] = [];
  VisitPurpose_Delete(id: any) {
    this.VisitPurposeApi.delete(id).subscribe((res: any) => { });
  }

  TWaybills_Delete(id: any) {
    this.WaybillsApi.delete(id).subscribe((res: any) => { });
  }

  Manifest_Delete(id: any) {
    this.ManifestApi.delete(id).subscribe((res: any) => { });
  }
  CargoSummary_Delete(id: any) {
    this.CargoSummaryApi.delete(id).subscribe((res: any) => { });
  }
  PassengerSummary_Delete(id: any) {
    this.PassengerSummaryApi.delete(id).subscribe((res: any) => { });
  }

  onchangedatestart(event: any) {
    this.startdate = this.datePipe.transform(event, 'yyyy-MM-dd');
  }
  startdate: any;

  typerecherche: any;
  startDate = '';
  endDate = '';
  enddate: any;
  listrecherchr: any;
  date_disabled: boolean = true;
  onchangedateend(event: any) {
    this.id_search1 = 3;
    this.CellList = [];
    this.enddate = this.datePipe.transform(event, 'yyyy-MM-dd');

    if (this.typerecherche == 'Date of arrival') {
      this.listrecherchr = this.CellList_1.filter(
        (e) =>
          e.dateofarrival > this.startdate && e.dateofarrival < this.enddate
      );

      setTimeout(() => {
        this.dataSource = new MatTableDataSource(this.listrecherchr);
        this.dataSource.paginator = this.paginator;
      }, 100);
    } else if (this.typerecherche == 'Date of departure') {
      this.listrecherchr = this.CellList_1.filter(
        (e) =>
          e.dateofdeparture > this.startdate && e.dateofdeparture < this.enddate
      );

      setTimeout(() => {
        this.dataSource = new MatTableDataSource(this.listrecherchr);
        this.dataSource.paginator = this.paginator;
      }, 100);
    } else {
      this.GetCargoManifest();
    }
  }
  itemslist: any[] = [
    { name: 'Voyage Number', code: 'voyagenumber', id: 1 },
    { name: 'Vessel NAME', code: 'vessel', id: 2 },
    { name: 'Customs office', code: 'customsoffice', id: 1 },
    { name: 'Place of Deparature', code: 'placeofdeparature', id: 1 },
    { name: 'Place Of Destination', code: 'placeofdestination', id: 1 },
    { name: 'final Destination', code: 'finaldestination', id: 1 },
    { name: 'Nationality', code: 'nationality', id: 1 },
    { name: 'Registration', code: 'registration', id: 1 },
  ];
  selectedcondition: String | any;

  selection = new SelectionModel<any>(true, []);
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  open(content: any) {
    this.modalService.open(content);
  }

  dimision() {
    this.modalService.dismissAll();
  }

  displayedColumnsMessages: string[] = [
    'id',
    'status',
    'startDate',
    'endDate',
    'typeFlux',
    'etatFlux',
    'idDossier',
    'typeMessage',
    'typeScenario',
  ];
  dataSourceMessages: MatTableDataSource<any>;

  list_message: any;
  Arr_messages(content: any, id: any) {
    this.MessagesUser.GetCargoMessage(id).subscribe((res: any) => {
      this.list_message = res;

      setTimeout(() => {
        this.dataSourceMessages = new MatTableDataSource(this.list_message);
        this.dataSourceMessages.paginator = this.paginator;
        this.modalService.open(content, {
          size: 'xl',
          windowClass: 'dark-modal',
        });
      }, 100);
    });
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1
      }`;
  }

  ClearAll() {
    this.selection.clear();
  }

  y: any = 0;

  generate_pdf1(document: any) {
    this.y = 0;
    var doc = new jsPDF();
    let cargo_final_name = this.organisation_list.find(
      (x) => x.id == document.dischargingport
    );
    doc.setFontSize(11);
    this.y = 40;

    doc.setDrawColor('#0000FF');
    doc.setFontSize(11);

    doc.line(5, 10, 5, 290);
    doc.line(5, 10, 205, 10);
    doc.line(205, 10, 205, 290);
    doc.line(5, 290, 205, 290);

    doc.line(5, 32, 205, 32);
    doc.line(5, 47, 180, 47);
    doc.line(5, 62, 205, 62);
    doc.line(5, 72, 205, 72);

    doc.line(30, 32, 30, 47);
    doc.line(55, 32, 55, 47);
    doc.line(80, 32, 80, 40);
    doc.line(105, 32, 105, 47);
    doc.line(130, 32, 130, 47);
    doc.line(155, 32, 155, 47);
    doc.line(180, 32, 180, 47);

    doc.line(30, 47, 30, 62);
    doc.line(50, 47, 50, 62);
    doc.line(75, 47, 75, 62);
    doc.line(100, 47, 100, 62);
    doc.line(130, 47, 130, 62);
    doc.line(155, 47, 155, 62);
    doc.line(180, 47, 180, 62);

    doc.line(25, 62, 25, 290);
    doc.line(55, 62, 55, 290);
    doc.line(75, 62, 75, 290);
    doc.line(95, 62, 95, 290);
    doc.line(115, 62, 115, 290);
    //  doc.line(130, 62, 130, 290);
    doc.line(140, 62, 140, 290);
    doc.line(165, 62, 165, 290);
    doc.setFontSize(10);
    doc.setFont('bold');
    doc.text('LIBYAN CUSTOMS', 20, 15);
    doc.setFont('italic');
    doc.setFontSize(7);
    doc.text(
      'MANIFESTO concerning the loading of the means of transport designated below, which manifests the accuracy of the',
      7,
      25
    );

    doc.text(
      'contenu est affirmée sous les peines de droit, est fourni et a été établi conformément aux lois et réglements en vigueur',
      7,
      30
    );

    doc.setFontSize(6);

    doc.text('TYPE DE MANIFESTE', 6, 36);

    doc.text('CUSTOMS OFFICE', 31, 36);

    doc.text('VOYAGE NUMBER', 56, 36);

    doc.text('DATE OF ARRIVAL', 81, 36);

    doc.text('REGISTRATION', 106, 36);

    doc.text('DATE', 132, 36);

    doc.text('PLACE OF DESTINATION', 155, 36);
    doc.text('SIGNATURE', 181, 36);
    ///2
    doc.text('VESSEL NAME', 6, 51);

    doc.text('COUNTRY', 31, 51);

    doc.text('J.B', 51, 51);

    doc.text('J.N', 76, 51);

    doc.text('PLACE OF DEPARTURE', 101, 51);

    doc.text('LOADING PORT', 131, 51);

    doc.text('DISCHARGING PORT', 156, 51);
    //3

    doc.text('REFRENCE', 6, 66);

    doc.text('BL REFERENCE NUMBER', 26, 66);

    doc.text('BL NUMBER', 56, 66);

    doc.text('GROSS MASS', 96, 66);
    doc.text('CONTAINERS', 76, 66);
    //doc.text('GROSS MASS', 131, 66);
    doc.text('PACKAGES', 116, 66);
    doc.text('EXPORTER NAME', 141, 66);

    doc.text('CONSIGNEE NAME', 166, 66);

    doc.setTextColor('#000000');
    doc.setFontSize(9);

    doc.text(document.vessel.vessel, 7, 56);

    if (document.nationality != null) {
      doc.text(document.nationality + '', 32, 56);
    }

    doc.text(document.vessel.vesselnetweight + '', 52, 56);

    doc.text(document.vessel.vesselgrossweight + '', 77, 56);

    doc.text('' + document.placeofdestination, 102, 56);

    if (document.loadingport) {
      doc.text('' + document.loadingport, 131, 56);
    }
    doc.text('' + cargo_final_name.name, 157, 56);
    doc.text('', 7, 41);

    doc.text(document.customsoffice + '', 32, 41);

    doc.text(document.voyagenumber + '', 57, 41);

    doc.text(document.dateofarrival + ' ' + document.timeofarrival, 81, 41);

    doc.text(document.registration + '', 106, 41);

    doc.text(document.date, 132, 41);

    doc.text(document.placeofdestination + '', 156, 41);
    this.y = 80;

    for (let waylbis of document.waybills) {
      doc.text(waylbis.id + '', 6, this.y);

      doc.text(waylbis.blreferencenumber, 26, this.y);

      doc.text(waylbis.blnumber, 56, this.y);

      doc.text(waylbis.grossMass + '', 101, this.y);

      doc.text(waylbis.exportername, 141, this.y);

      doc.text(waylbis.consigneename, 166, this.y);
      doc.text(waylbis.packages + '', 116, this.y);
      doc.text(waylbis.containers + '', 76, this.y);
      // doc.text(waylbis.grossMass + '', 131, this.y);
      this.y = this.y + 7;
    }

    doc.save('cargomanifest_num' + document.id + '.pdf');
  }

  list_print: any[] = [];
  printdoc() {
    this.list_print = [];
    this.selection.selected.forEach((s) => this.list_print.push(s));

    for (let cargo of this.list_print) {
      let document = this.CellList_1.find((x) => x.id == cargo.id);

      this.generate_pdf1(document);
    }

    setTimeout(() => {
      this.ClearAll();
    }, 300);
  }
  selectetcopy: String | any;
  id_vessel_copy: any;
  id_ship_copy: any;
  open_copy(id_vessel: any, id_ship: any, content: any) {
    this.open(content);

    this.id_ship_copy = id_ship;
  }
  changestscenario(event: any) { }

  copylist: any[] = [];

  copylist_EN: any[] = [
    { name: 'Shipping Arrival', id: 1 },
    { name: 'Shipping Departure', id: 2 },
    { name: 'Cargo Manifest', id: 3 },
  ];
  copylist_AR: any[] = [
    { name: 'وصول السفينة', id: 1 },
    { name: 'مغادرة السفينة', id: 2 },
    { name: 'بيان السفينة', id: 3 },
  ];

  Go_copy() {
    if (this.selectetcopy.id == 2) {
      this.router.navigateByUrl(
        'ShippingDeparture/copy_cargo_manifest/' + this.id_ship_copy + '/1'
      );
    }

    if (this.selectetcopy.id == 1) {
      this.router.navigateByUrl(
        'ShippingArrival/copy_cargo_manifest/' + this.id_ship_copy + '/1'
      );
    }
    if (this.selectetcopy.id == 3) {
      this.router.navigateByUrl('CargoManifest/copy/' + this.id_ship_copy);
    }

    this.dimision();
  }

  organisation_list: any[] = [];
  Getorganisation() {
    this.organisationService.GetAll().subscribe((res: any) => {
      this.organisation_list = res;
    });
  }

  ecriture: boolean = false;
  GetPremmision() {
    let premission = localStorage.getItem('premission_CO');

    if (premission?.indexOf('E') != -1) {
      this.ecriture = true;
    }
  }

  private socket: any;
  web_socket: boolean = false;
  id_search1: any = 0;
  Websocket_cm() {
    var host = 'wss://ws.coincap.io/prices?assets=bitcoin,ethereum,litecoin';

    this.socket = new WebSocketSubject(host);

    this.socket.subscribe(
      (message: any) => { },
      (err: any) => console.log(err)
    );

    this.socket = new WebSocket(host);
    this.socket.onmessage = (e: any) => {
      if (this.id_search1 == 0) {
        this.GetCargoManifest();
      }
    };
  }

  changeaction(event: any) {
    this.userslist = [];
    this.selectetuser = null;
    if (event == undefined) {
      this.userslist = [];
    }
    if (event.id == '1') {
      this.getUser(this.TeamId, this.premission);
      this.acions_t = 'SAVE';
    } else if (event.id == '2') {
      this.getUser(this.TeamId, this.premission);
      this.acions_t = 'SUBMIT';
    }
  }

  dataSourceActions: MatTableDataSource<any>;
  listactions: any[];
  id_folder: any;
  message_user: any;
  reason_assign: any;
  messages_actions: any[] = [];
  message_sender: any;
  message_receiver: any;
  Arr_assign(content: any, id_folder: any, document: any) {
    this.transactiontype = 'Save';
    this.status_sc = false;
    this.id_folder = id_folder;
    let row_message = document.messages.length - 1;
    let status_message = document.messages[row_message].status;
    this.message_user = document.messages[row_message];
    this.id_message = document.messages[row_message].id;
    if (this.message_user.organizationSender != null) {
      this.message_sender = this.message_user.organizationSender.id;
    }

    if (this.message_user.organizationReceiver != null) {
      this.message_receiver = this.message_user.organizationReceiver.id;
    }
    let conditionReceiver =
      document.etat == 'For confirmation' &&
      this.message_receiver == this.id_Org_en;

    let conditionSender =
      document.etat == 'Save' && this.message_sender == this.id_Org_en;

    if (status_message == 'SUCCESS' || status_message == 'INPROGRESS') {
      if (conditionReceiver || conditionSender) {
        this.status_sc = false;
        this.messages_actions = this.message_user.actions;

        if (conditionReceiver) {
          this.messages_actions = this.message_user.actions.filter(
            (e: any) => e.message.organizationSender.id == this.id_Org_en
          );
        }

        if (conditionSender) {
          this.messages_actions = this.message_user.actions.filter(
            (e: any) => e.message.organizationSender.id == this.id_Org_en
          );
        }
        let row_actions = this.messages_actions.length;
        if (row_actions > 0) {
          if (this.messages_actions[row_actions - 1].status != 'FINISHED') {
            this.status_sc = true;
            this.reason_assign = this.languet.text_assign_2;
          }
        }

        setTimeout(() => {
          this.dataSourceActions = new MatTableDataSource(
            this.messages_actions
          );
        }, 200);
      } else {
        this.status_sc = true;
        this.reason_assign = this.languet.text_assign_1;
      }
    } else {
      this.status_sc = true;
      this.reason_assign = this.languet.text_assign_1;
    }

    setTimeout(() => {
      this.modalService.open(content, {
        size: 'xl',
        windowClass: 'dark-modal',
      });
    }, 500);
  }

  selectetassign: any;

  actionlist: any[];

  selectetuser: any;
  Id_user: any;
  acions_t: any;
  userslist: any[];

  changeuser(event: any) {
    this.Id_user = event.id;
  }

  getUser(team: any, sc: any) {
    this.userService.GetAllUsers(team, sc).subscribe((res: any) => {
      this.userslist = res;
    });
  }
  Assign() {
    if (this.transactiontype == 'Save') {
      this.actiondata = {
        code: this.acions_t,
        affectedToUser: { id: this.Id_user },
        designation: this.designation,
      };

      this.affecationService
        .assignm(this.CargoSummaryApi.Spring_serveur, this.actiondata, this.id_folder, 'cm')
        .subscribe((res: any) => {
          this.designation = '';
          this.selectetassign = null;
          this.selectetuser = null;
          this.notifier.notify(
            'success',
            this.languet.titremodal + ' ' + this.languet.task
          );
          this.dimision();
        });
    } else {
      this.actiondata.code = this.acions_t;
      this.actiondata.affectedToUser.id = this.Id_user;
      this.actiondata.designation = this.designation;

      this.affecationService
        .reassign(this.CargoSummaryApi.Spring_serveur, this.actiondata, this.id_Action)
        .subscribe((res: any) => {
          this.designation = '';
          this.selectetassign = null;
          this.selectetuser = null;
          this.dimision();
          this.notifier.notify(
            'success',
            this.languet.titremodal + ' ' + this.languet.task
          );
        });
    }
  }
  TeamId: any;
  GetIdUser() {
    this.userService.Getuserbyusername().subscribe((res: any) => {
      this.TeamId = res.team.id;
      this.Getorganisation_send(res.id);
    });
  }
  designation: any;
  id_message: any;

  changeactions(event: any) {
    this.id_message = event.id;
    this.listactions = event.actions;
    setTimeout(() => {
      this.dataSourceActions = new MatTableDataSource(this.listactions);
    }, 200);
  }

  color_active = '#77838e';
  status_sc: boolean = false;
  RoleTeammanager: boolean = false;
  RoleAgent: boolean = false;
  id_Org_en: any;
  role_organisation: any;
  Getorganisation_send(id: any) {
    this.organisationService.Get_org_byID(id).subscribe((res: any) => {
      this.role_organisation = res['profil'];
      this.id_Org_en = res.id;
      this.GetCargoManifest();
    });
  }

  submit(content: any, row: any) {
    this.document = row;

    this.document.etat = 'For confirmation';

    this.ManifestApi.submit(
      this.document,
      this.document.dischargingport
    ).subscribe((res: any) => {
      this.open(content);

      let formdata = {
        action: 'SUBMIT',

        idOrgSender: this.document.messages[0].organizationSender.id,
        nomOrgSender: this.document.messages[0].organizationSender.name,
        idOrgReceiver: this.document.messages[0].organizationReceiver.id,
        nomOrgReceiver: this.document.messages[0].organizationReceiver.name,
        idScenario: this.document.id,
      };

      this.notificationApi.post(formdata).subscribe((res: any) => { });
    });
  }

  transactiontype = 'Save';
  id_Action: any;
  action: any;
  useraction: any;
  actiondata: any;

  reassign(action: any) {
    this.transactiontype = 'Update';
    this.action = action;
    this.actiondata = action;
    this.id_Action = action.id;
    this.useraction = action.affectedToUser;
    this.selectetuser = this.useraction.userName;
    this.Id_user = this.selectetuser.id;
    this.selectetassign = this.action.code;
    this.designation = this.action.designation;
    this.status_sc = false;
  }
}
