import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

export type EntityResponseType = HttpResponse<any>;
export type EntityArrayResponseType = HttpResponse<any[]>;

@Injectable({ providedIn: 'root' })
export class AttachmentService {
  //http://51.68.84.74:2014/attachments/
  //http://141.95.150.170:8087/attachments/
  //../content/attachments/
  uploadPathFront = 'http://141.95.150.170:8087/attachments/';
  resourceUrl: any;
  resourceUrlGateway: any;

  Spring_serveur: string = 'api/attachments';
  constructor(private http: HttpClient) {
    this.resourceUrl = environment.Url + this.Spring_serveur;
    this.resourceUrlGateway = environment.UrlGetway;
  }

  create(attachment: any): Observable<EntityResponseType> {
   
    return this.http
      .post<any>(this.resourceUrl, attachment, { observe: 'response' })
      
  }

  update(attachment: any): Observable<EntityResponseType> {
   
    return this.http
      .put<any>(`${this.resourceUrl}/${attachment.id}`, attachment, { observe: 'response' })
      
  }

  upload(spring_server: string, file: File, UserId: number): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);

    const req = new HttpRequest(
      'POST',
      `${this.resourceUrlGateway + spring_server}/attachments/files?UserId=${UserId}`,
      formData,
      {
        reportProgress: true,
        responseType: 'json',
      }
    );

    return this.http.request(req);
  }

  verifyFile(spring_server: string, attachment: any): Observable<EntityResponseType> {
    // const formData: FormData = new FormData();

    // formData.append('file', file);

    // const req = new HttpRequest('POST', `${this.resourceUrl}/files/verify?UserId=${UserId}`, formData, {
    //   reportProgress: true,
    //   responseType: 'json'
    // });
    return this.http.post<any>(`${this.resourceUrlGateway + spring_server}/attachments/files/verify`, attachment, {
      observe: 'response',
    });
  }

  // verifyFile2(file: File, UserId: number): Observable<EntityResponseType> {
  //   const formData: FormData = new FormData();

  //   formData.append('file', file);

  //   const req = new HttpRequest('POST', `${this.resourceUrl}/files/verify?UserId=${UserId}`, formData, {
  //     reportProgress: true,
  //     responseType: 'json'
  //   });

  //   return this.http.request(req);
  // }

  deleteFile(file: string): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);

    const req = new HttpRequest(
      'POST',
      `${this.resourceUrl}/files/delete`,
      formData,
      {
        reportProgress: true,
        responseType: 'json',
      }
    );

    return this.http.request(req);
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<any>(`${this.resourceUrl}/${id}`, {
      observe: 'response',
    });
  }

  findByUser(id: number): Observable<EntityArrayResponseType> {
    return this.http.get<any[]>(`${this.resourceUrl}/user/${id}`, {
      observe: 'response',
    });
  }

  findByOriginFilename(name: string): Observable<EntityArrayResponseType> {
    return this.http.get<any[]>(`${this.resourceUrl}/originFilename/${name}`, {
      observe: 'response',
    });
  }

  findByLC(id: number): Observable<EntityArrayResponseType> {
    return this.http.get<any[]>(`${this.resourceUrl}/credit-letter/${id}`, {
      observe: 'response',
    });
  }

  findByCO(spring_serveur: string, id: number): Observable<EntityArrayResponseType> {
    return this.http.get<any[]>(
      `${this.resourceUrlGateway + spring_serveur}/certificate-origins/${id}`,
      { observe: 'response' }
    );
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, {
      observe: 'response',
    });
  }
}
