import { Directive, ElementRef, Injectable, Input, OnInit } from '@angular/core';
import Langue from './../../assets/langue.json';

@Directive({
  selector: '[appTranslation]'
})

@Injectable({
  providedIn: 'root',
})
export class TranslationDirective  implements OnInit{
  @Input('appTranslation') key: string;
  lang : any = "EN"
  langue_list: any[] = Langue;
  constructor(  private el: ElementRef) { 
    console.log("Translation for key ");
  }
  ngOnInit(): void {
    
    if (localStorage.getItem('langue') != null) {
      this.lang = localStorage.getItem('langue') + '';
    } else {
      this.lang= 'EN';
    }
   // const translation = this.langue_list[0][this.lang].modal_text.key
    const translation = "mahdi2541"

    this.el.nativeElement.textContent = translation;



  }

}
