import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ArticleService {
  url: any;

  Spring_serveur: string = '';

  headers: any;
  constructor(private router: Router, private http: HttpClient) {
    this.url = environment.UrlGetway + this.Spring_serveur;
  }
  public Get() {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.get<any>(this.url + '', { headers: headers1 });
  }

  public post(formData: any) {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(this.url + '', formData, {
      headers: headers1,
    });
  }

  public update(formData: any) {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(this.url + 'submit', {
      headers: headers1,
    });
  }
}
