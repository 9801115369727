import { Injectable } from '@angular/core';
import { UserService } from '../User/user.service';
import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root',
})
export class PremissionService {
  generadtoken: any;
  description: any;
  constructor(private userauth: UserService) {
    let token = localStorage.getItem('token');

    if (token != null) {
      token = CryptoJS.AES.decrypt(
        token + '',
        'dfggf4257ttert1DFGS5451EDZ542ZAE777ds'
      ).toString(CryptoJS.enc.Utf8);
    }

    this.generadtoken = this.userauth.getDecodedAccessToken(token);
    if (this.generadtoken != null) {
      this.description = this.generadtoken['Description'];
    }
  }

  GetShipArrival() {
    let reponse = false;

    this.ValCD = [];

    if (this.description != null) {
      if (this.description.indexOf(',') != -1) {
        this.ValCD = this.description.split(',');
      } else {
        this.ValCD[0] = this.description;
      }
    }

    for (let flux of this.ValCD) {
      const flux_r = flux.split('_');
      if (flux_r[0] == 'SA') {
        localStorage.setItem('premission_SA', flux_r[2]);
        reponse = true;
      }
    }

    return reponse;
  }

  GetShipDeparture() {
    let reponse = false;
    this.ValCD = [];

    if (this.description != null) {
      if (this.description.indexOf(',') != -1) {
        this.ValCD = this.description.split(',');
      } else {
        this.ValCD[0] = this.description;
      }
    }

    for (let flux of this.ValCD) {
      const flux_r = flux.split('_');
      if (flux_r[0] == 'SDP') {
        localStorage.setItem('premission_SDP', flux_r[2]);
        reponse = true;
      }
    }
    return reponse;
  }

  GetShipDocking() {
    let reponse = false;
    this.ValCD = [];

    if (this.description != null) {
      if (this.description.indexOf(',') != -1) {
        this.ValCD = this.description.split(',');
      } else {
        this.ValCD[0] = this.description;
      }
    }

    for (let flux of this.ValCD) {
      const flux_r = flux.split('_');
      if (flux_r[0] == 'SD') {
        localStorage.setItem('premission_SD', flux_r[2]);
        reponse = true;
      }
    }
    return reponse;
  }

  GetShipCargo() {
    let reponse = false;
    this.ValCD = [];

    if (this.description != null) {
      if (this.description.indexOf(',') != -1) {
        this.ValCD = this.description.split(',');
      } else {
        this.ValCD[0] = this.description;
      }
    }

    for (let flux of this.ValCD) {
      const flux_r = flux.split('_');
      if (flux_r[0] == 'CM') {
        localStorage.setItem('premission_CO', flux_r[2]);
        reponse = true;
      }
    }
    return reponse;
  }

  GetTechnicalControl() {
    let reponse = false;
    this.ValCD = [];

    if (this.description != null) {
      if (this.description.indexOf(',') != -1) {
        this.ValCD = this.description.split(',');
      } else {
        this.ValCD[0] = this.description;
      }
    }

    for (let flux of this.ValCD) {
      const flux_r = flux.split('_');
      if (flux_r[0] == 'TM') {
        localStorage.setItem('premission_TM', flux_r[2]);
        reponse = true;
      }
    }
    return reponse;
  }

  ValCD: any = [];
  ValSA: any = [];
  ValSDP: any = [];
  ValSD: any = [];
  ValCM: any = [];
  ValTO: any = [];
  ValFD: any = [];
  ValBOL: any = [];

  GetCustomsDeclaration() {
    let reponse = false;
    this.ValCD = [];

    if (this.description != null) {
      if (this.description.indexOf(',') != -1) {
        this.ValCD = this.description.split(',');
      } else {
        this.ValCD[0] = this.description;
      }
    }

    for (let flux of this.ValCD) {
      const flux_r = flux.split('_');
      if (flux_r[0] == 'CD') {
        localStorage.setItem('premission_CD', flux_r[2]);
        reponse = true;
      }
    }
    return reponse;
  }
  GetFde() {
    let reponse = false;
    this.ValFD = [];

    if (this.description != null) {
      if (this.description.indexOf(',') != -1) {
        this.ValFD = this.description.split(',');
      } else {
        this.ValFD[0] = this.description;
      }
    }

    for (let flux of this.ValFD) {
      const flux_r = flux.split('_');
      if (flux_r[0] == 'FDE') {
        localStorage.setItem('premission_FDE', flux_r[2]);
        reponse = true;

        localStorage.setItem('reponse_FDE', reponse.toString());
      }
    }
    return reponse;
  }
  ValLC: any = [];
  ValCO: any = [];
  ValSG: any = [];

  GetCreditLetter() {
    let reponse = false;
    this.ValLC = [];

    if (this.description != null) {
      if (this.description.indexOf(',') != -1) {
        this.ValLC = this.description.split(',');
      } else {
        this.ValLC[0] = this.description;
      }
    }

    for (let flux of this.ValLC) {
      const flux_r = flux.split('_');
      if (flux_r[0] == 'LC') {
        localStorage.setItem('premission_LC', flux_r[2]);
        reponse = true;
        //localStorage.setItem('reponse_LC', reponse.toString());
      }
    }
    return reponse;
  }

  GetCertificatOrigin() {
    let reponse = false;
    this.ValCO = [];

    if (this.description != null) {
      if (this.description.indexOf(',') != -1) {
        this.ValCO = this.description.split(',');
      } else {
        this.ValCO[0] = this.description;

      }
    }

    for (let flux of this.ValCO) {
      const flux_r = flux.split('_');
      if (flux_r[0] == 'CO') {
        localStorage.setItem('premission_CO', flux_r[2]);
        reponse = true;
        localStorage.setItem('reponse_CO', reponse.toString());
      }
    }
    return reponse;
  }

  GetShippingGarante() {
    let reponse = false;
    this.ValSG = [];

    if (this.description != null) {
      if (this.description.indexOf(',') != -1) {
        this.ValSG = this.description.split(',');
      } else {
        this.ValSG[0] = this.description;
      }
    }

    for (let flux of this.ValSG) {
      const flux_r = flux.split('_');
      if (flux_r[0] == 'SG') {
        localStorage.setItem('premission_SG', flux_r[2]);
        reponse = true;
        localStorage.setItem('reponse_SG', reponse.toString());
      }
    }

    return reponse;
  }


  ValCMRQu: any = [];
  ValCMRBo: any = [];

  GetCMRquotation() {
    let reponse = false;

    this.ValCMRQu = [];

    if (this.description != null) {
      if (this.description.indexOf(',') != -1) {
        this.ValCMRQu = this.description.split(',');
      } else {
        this.ValCMRQu[0] = this.description;
      }
    }

    for (let flux of this.ValCMRQu) {
      const flux_r = flux.split('_');
      if (flux_r[0] == 'LC') {
        localStorage.setItem('premission_LC', flux_r[2]);
        reponse = true;
        localStorage.setItem('reponse_LC', reponse.toString());
      }
    }
    return reponse;
  }

  GetCMRbooking() {
    let reponse = true;

    return reponse;
  }
  Val: any = [];
  GetActiviteScenario() {
    if (!this.description) {
      return false;
    }
    console.log(" this.description " + this.description);
    const Val = this.description.split(',');

    // Rechercher la valeur
    let searchTerm = "ACD_A1.1";
    let result = this.description.includes(searchTerm);

    if (result) {
      console.log(`"${searchTerm}" trouvé dans la chaîne.`);
    } else {
      console.log(`"${searchTerm}" non trouvé dans la chaîne.`);
    }

    for (const flux of Val) {
      const [type, , permission] = flux.split('_');
      if (type === 'ACD') {
        return true;
      }
    }

    return false;
  }

  GetActiviteScenarioTransfert() {
    let reponse = false;

    this.Val = [];

    if (this.description != null) {
      if (this.description.indexOf(',') != -1) {
        this.Val = this.description.split(',');
      } else {
        this.Val[0] = this.description;
      }
    }

    for (let flux of this.Val) {
      const flux_r = flux.split('_');
      if (flux_r[0] == 'TRANSF') {
        localStorage.setItem('premission_TRANSF', flux_r[2]);
        reponse = true;
        //localStorage.setItem('reponse_TRANSF', reponse.toString());
      }
    }
    return reponse;
  }

  GetActiviteScenarioDECOTU() {
    let reponse = false;

    this.Val = [];

    if (this.description != null) {
      if (this.description.indexOf(',') != -1) {
        this.Val = this.description.split(',');
      } else {
        this.Val[0] = this.description;
      }
    }

    for (let flux of this.Val) {
      const flux_r = flux.split('_');
      if (flux_r[0] == 'DECOTU') {
        localStorage.setItem('premission_DECOTU', flux_r[2]);
        reponse = true;
       // localStorage.setItem('reponse_DECOTU', reponse.toString());
      }
    }
    return reponse;
  }

  GetActiviteScenarioAgrement() {
    let reponse = false;

    this.Val = [];

    if (this.description != null) {
      if (this.description.indexOf(',') != -1) {
        this.Val = this.description.split(',');
      } else {
        this.Val[0] = this.description;
      }
    }

    for (let flux of this.Val) {
      const flux_r = flux.split('_');
      if (flux_r[0] == 'AGR') {
        localStorage.setItem('premission_AGR', flux_r[2]);
        reponse = true;
        //localStorage.setItem('reponse_AGR', reponse.toString());
      }
    }
    return reponse;
  }

}
