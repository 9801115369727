<link
  rel="stylesheet"
  href="https://use.fontawesome.com/releases/v5.5.0/css/all.css"
  integrity="sha384-B4dIYHKNBt8Bc12p+WXckhzcICo0wtJAoU8YZTY5qE0Id1GSseTk6S+L3BlXeVIU"
  crossorigin="anonymous"
/>
<script
  src="https://kit.fontawesome.com/8f702b3b95.js"
  crossorigin="anonymous"
></script>
<div class="c1" [ngStyle]="{ direction: direction }">
  <div class="Trac-2081"></div>

  <div>
    <h2 class="titre-page">{{ languee.titre_r }}</h2>
    <div class="about-border"></div>
  </div>
</div>

<div class="row" [ngStyle]="{ direction: direction }">
  <div class="col-lg-12">
    <mat-stepper linear #stepper>
      <mat-step
        [stepControl]="Form1"
        label="{{ languee.bloc1 }} "
        [ngStyle]="{ direction: direction }"
      >
        <ng-template matStepperIcon="edit">
          <mat-icon>check_circle</mat-icon>
        </ng-template>

        <div style="padding-top: 5%">
          <div
            class="titre_mobile"
            style="text-align: center; padding-bottom: 4%"
          >
            <label style="min-width: 100px; font-size: 18px"
              >{{ languee.bloc1 }}
            </label>
          </div>
          <form [formGroup]="Form1" (ngSubmit)="next()">
            <div class="row">
              <div class="col-lg-4">
                <div>
                  <label class="titrechamp"> {{ languee.issuingbank }}*</label>
                  <div>
                    <ng-select
                      [items]="issuingbank_list"
                      (change)="changeissuingbank($event)"
                      bindLabel="nom"
                      [(ngModel)]="selectedissuingbank"
                      placeholder="{{ ngselectinvalid }}"
                      [ngClass]="{ 'is-invalid': formSubmitted }"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp"
                  >{{ languee.applicationnumber }}
                </label>

                <div style="padding-top: 1%">
                  <input
                    required
                    matInput
                    class="form-control mb-4"
                    type="text"
                    formControlName="applicationnumber"
                    name="applicationnumber"
                    id="applicationnumber"
                    [ngStyle]="{
                      'border-color':
                        (formSubmitted ||
                          Form1.controls.applicationnumber.touched) &&
                        Form1.controls.applicationnumber.errors?.required
                          ? 'red'
                          : '#47b2e4'
                    }"
                  />
                </div>
              </div>

              <div class="col-lg-4">
                <div>
                  <label class="titrechamp"> {{ languee.form }} *</label>
                  <div>
                    <ng-select
                      formControlName="selectedform"
                      [items]="form_list"
                      (change)="changeform($event)"
                      bindLabel="name"
                      placeholder=""
                      [(ngModel)]="selectedform"
                      placeholder="{{ ngselectinvalid }}"
                      [ngClass]="{ 'is-invalid': formSubmitted }"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp">{{ languee.beneficiaryname }} </label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    class="form-control mb-4"
                    type="text"
                    formControlName="beneficiaryname"
                    [ngStyle]="{
                      'border-color':
                        (formSubmitted ||
                          Form1.controls.beneficiaryname.touched) &&
                        Form1.controls.beneficiaryname.errors?.required
                          ? 'red'
                          : '#47b2e4'
                    }"
                  />
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp"
                  >{{ languee.beneficiaryaddresse }}
                </label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    class="form-control mb-4"
                    type="text"
                    formControlName="beneficiaryaddresse"
                    [ngStyle]="{
                      'border-color':
                        (formSubmitted ||
                          Form1.controls.beneficiaryaddresse.touched) &&
                        Form1.controls.beneficiaryaddresse.errors?.required
                          ? 'red'
                          : '#47b2e4'
                    }"
                  />
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp"
                  >{{ languee.beneficiarybankname }}
                </label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    required
                    class="form-control mb-4"
                    type="text"
                    formControlName="beneficiarybankname"
                    [ngStyle]="{
                      'border-color':
                        (formSubmitted ||
                          Form1.controls.beneficiarybankname.touched) &&
                        Form1.controls.beneficiarybankname.errors?.required
                          ? 'red'
                          : '#47b2e4'
                    }"
                  />
                </div>
              </div>

              <div class="col-lg-6">
                <label class="titrechamp"
                  >{{ languee.beneficiarybankaccountnumber }}
                </label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    required
                    class="form-control mb-4"
                    type="text"
                    formControlName="beneficiarybankaccountnumber"
                    [ngStyle]="{
                      'border-color':
                        (formSubmitted ||
                          Form1.controls.beneficiarybankaccountnumber
                            .touched) &&
                        Form1.controls.beneficiarybankaccountnumber.errors
                          ?.required
                          ? 'red'
                          : '#47b2e4'
                    }"
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <label class="titrechamp"> {{ languee.expirydate }} *</label>

                <mat-form-field>
                  <input
                    formControlName="dateexpirydate"
                    matInput
                    [matDatepicker]="picker"
                    placeholder="{{ ngselectinvalid }}"
                    required
                    (dateInput)="onchangedateexpirydate($event.value)"
                    style="color: #1d3a66"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>

              <div
                class="col-lg-12"
                *ngIf="status_request && transactiontype == 'Update'"
              >
                <label class="titrechamp">{{ languet.comment }} </label>
                <div style="padding-top: 1%">
                  <div class="col-lg-8">
                    <div class="md-form">
                      <textarea
                        type="text"
                        formControlName="messageresponse"
                        class="md-textarea form-control"
                        mdbInput
                        style="width: 100%"
                      ></textarea>
                      <label for="form7"></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                class="div_next"
                [ngStyle]="{
                  float: langue === 'AR' ? 'left' : 'right'
                }"
              >
                <button
                  (click)="next()"
                  class="buttonnext"
                  mat-button
                  matStepperNext
                >
                  {{ languee.next }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </mat-step>
      <mat-step [stepControl]="Form2" label="{{ languee.bloc2 }} ">
        <ng-template matStepperIcon="edit">
          <mat-icon>check_circle</mat-icon>
        </ng-template>

        <div style="padding-top: 5%">
          <div
            class="titre_mobile"
            style="text-align: center; padding-bottom: 4%"
          >
            <label style="min-width: 100px; font-size: 18px"
              >{{ languee.bloc2 }}
            </label>
          </div>
          <form [formGroup]="Form2" (ngSubmit)="next()">
            <div class="row">
              <div class="col-lg-3">
                <div>
                  <label class="titrechamp">
                    {{ languee.currencycode }} *</label
                  >
                  <div>
                    <ng-select
                      formControlName="selectedcurrencycode"
                      [items]="currencycode_list"
                      (change)="changedcurrencycode($event)"
                      bindLabel="name"
                      placeholder=""
                      [(ngModel)]="selectedcurrencycode"
                      placeholder="{{ ngselectinvalid }}"
                      [ngClass]="{ 'is-invalid': formSubmitted1 }"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>

              <div class="col-lg-3">
                <label class="titrechamp">{{ languee.amount }}</label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    class="form-control mb-4"
                    type="number"
                    formControlName="amount"
                    [ngStyle]="{
                      'border-color':
                        (formSubmitted1 || Form2.controls.amount.touched) &&
                        Form2.controls.amount.errors?.required
                          ? 'red'
                          : '#47b2e4'
                    }"
                  />
                </div>
              </div>
              <div class="col-lg-3">
                <label class="titrechamp">{{ languee.allowancerate }} *</label>
                <div style="display: flex">
                  <div style="padding-top: 1%">
                    <input
                      matInput
                      class="form-control mb-4"
                      type="number"
                      placeholder="{{ languee.from }}"
                      formControlName="rate1"
                      required
                      [ngStyle]="{
                        'border-color':
                          (formSubmitted1 || Form2.controls.rate1.touched) &&
                          Form2.controls.rate1.errors?.required
                            ? 'red'
                            : '#47b2e4'
                      }"
                    />
                  </div>
                  <div style="padding: 1%">
                    <input
                      matInput
                      class="form-control mb-4"
                      placeholder="{{ languee.to }}"
                      type="number"
                      formControlName="rate2"
                      required
                      [ngStyle]="{
                        'border-color':
                          (formSubmitted1 || Form2.controls.rate2.touched) &&
                          Form2.controls.rate2.errors?.required
                            ? 'red'
                            : '#47b2e4'
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <label class="titrechamp">{{ languee.payment }} *</label>

                <div>
                  <ng-select
                    formControlName="selectedpayment"
                    [items]="payment_list"
                    (change)="changepayment($event)"
                    bindLabel="name"
                    placeholder=""
                    [(ngModel)]="selectedpayment"
                    placeholder="{{ ngselectinvalid }}"
                    [ngClass]="{ 'is-invalid': formSubmitted1 }"
                  >
                  </ng-select>
                </div>
              </div>

              <div
                class="col-lg-4"
                *ngIf="
                  selectedpayment == 'DEFERRED_PAYMENT_BL' ||
                  selectedpayment == 'DEFERRED_PAYMENT_PD'
                "
              >
                <label class="titrechamp">
                  {{ languee.deferredpaymentperiod }}</label
                >

                <div style="padding-top: 1%">
                  <input
                    matInput
                    class="form-control mb-4"
                    type="number"
                    formControlName="deferredpaymentperiod"
                    [ngStyle]="{
                      'border-color':
                        (formSubmitted1 ||
                          Form2.controls.deferredpaymentperiod.touched) &&
                        Form2.controls.deferredpaymentperiod.errors?.required
                          ? 'red'
                          : '#47b2e4'
                    }"
                  />
                </div>
              </div>
              <div class="col-lg-4">
                <label class="titrechamp">{{ languee.portofloading }} *</label>

                <div>
                  <ng-select
                    formControlName="selectedportofloading"
                    [items]="portofloading_list"
                    (change)="changeportofloading($event)"
                    bindLabel="name"
                    placeholder=""
                    [(ngModel)]="selectedportofloading"
                    placeholder="{{ ngselectinvalid }}"
                    [ngClass]="{ 'is-invalid': formSubmitted1 }"
                  >
                  </ng-select>
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp"
                  >{{ languee.partialshipment }} *</label
                >

                <div>
                  <ng-select
                    formControlName="selectedpartialshipment"
                    [items]="partialshipment_list"
                    (change)="changepartialshipment($event)"
                    bindLabel="name"
                    placeholder=""
                    [(ngModel)]="selectedpartialshipment"
                    placeholder="{{ ngselectinvalid }}"
                    [ngClass]="{ 'is-invalid': formSubmitted1 }"
                  >
                  </ng-select>
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp">{{ languee.transhipment }} *</label>

                <div>
                  <ng-select
                    formControlName="selectedtranshipment"
                    [items]="transhipment_list"
                    (change)="changetranshipment($event)"
                    bindLabel="name"
                    placeholder=""
                    [(ngModel)]="selectedtranshipment"
                    placeholder="{{ ngselectinvalid }}"
                    [ngClass]="{ 'is-invalid': formSubmitted1 }"
                  >
                  </ng-select>
                </div>
              </div>
              <div class="col-lg-4">
                <label class="titrechamp"
                  >{{ languee.portofdischarge }} *</label
                >

                <div>
                  <ng-select
                    formControlName="selectedportofdischarge"
                    [items]="portofdischarge_list"
                    (change)="changeportofdischarge($event)"
                    bindLabel="name"
                    placeholder=""
                    [(ngModel)]="selectedportofdischarge"
                    placeholder="{{ ngselectinvalid }}"
                    [ngClass]="{ 'is-invalid': formSubmitted1 }"
                  >
                  </ng-select>
                </div>
              </div>
              <div class="col-lg-4">
                <label class="titrechamp">
                  {{ languee.placeoffinaldischarge }}</label
                >

                <div style="padding-top: 1%">
                  <input
                    matInput
                    class="form-control mb-4"
                    type="text"
                    formControlName="placeoffinaldischarge"
                  />
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp">{{
                  languee.shippingcompanyname
                }}</label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    class="form-control mb-4"
                    type="text"
                    formControlName="shippingcompanyname"
                    [ngStyle]="{
                      'border-color':
                        (formSubmitted1 ||
                          Form2.controls.shippingcompanyname.touched) &&
                        Form2.controls.shippingcompanyname.errors?.required
                          ? 'red'
                          : '#47b2e4'
                    }"
                  />
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp">
                  {{ languee.lastshippingdate }}
                </label>

                <div style="padding-top: 1%">
                  <div>
                    <mat-form-field>
                      <input
                        required
                        matInput
                        formControlName="lastshippingdate"
                        [matDatepicker]="picker2"
                        (dateInput)="onchangelastshippingdate($event.value)"
                        style="color: #1d3a66"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker2"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp"
                  >{{ languee.priceconditioncode }} *</label
                >

                <div>
                  <ng-select
                    formControlName="selectedpriceconditioncode"
                    [items]="priceconditioncode_list"
                    (change)="changepriceconditioncode($event)"
                    bindLabel="name"
                    placeholder=""
                    [(ngModel)]="selectedpriceconditioncode"
                    placeholder="{{ ngselectinvalid }}"
                    [ngClass]="{ 'is-invalid': formSubmitted1 }"
                  >
                  </ng-select>
                </div>
              </div>
              <div class="col-lg-12">
                <label class="titrechamp"
                  >{{ languee.additionaconditions }} *</label
                >
                <div style="padding-top: 1%">
                  <div class="col-lg-8">
                    <div class="md-form">
                      <textarea
                        type="text"
                        id="form7"
                        formControlName="additionaconditions"
                        class="md-textarea form-control"
                        mdbInput
                        style="width: 100%"
                        [ngStyle]="{
                          'border-color':
                            (formSubmitted1 ||
                              Form2.controls.additionaconditions.touched) &&
                            Form2.controls.additionaconditions.errors?.required
                              ? 'red'
                              : '#47b2e4'
                        }"
                      ></textarea>
                      <label for="form7"></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                class="div_back"
                [ngStyle]="{
                  float: langue === 'AR' ? 'right' : 'left'
                }"
              >
                <button class="buttonback" mat-button matStepperPrevious>
                  {{ languee.previous }}
                </button>
              </div>
              <div
                class="div_next"
                [ngStyle]="{
                  float: langue === 'AR' ? 'left' : 'right'
                }"
              >
                <button
                  (click)="next1()"
                  class="buttonnext"
                  mat-button
                  matStepperNext
                >
                  {{ languee.next }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </mat-step>

      <mat-step [stepControl]="Form3" label="{{ languee.bloc3 }} ">
        <ng-template matStepperIcon="edit">
          <mat-icon>check_circle</mat-icon>
        </ng-template>

        <div style="padding-top: 5%">
          <div
            class="titre_mobile"
            style="text-align: center; padding-bottom: 4%"
          >
            <label style="min-width: 100px; font-size: 18px"
              >{{ languee.bloc3 }}
            </label>
          </div>
          <form [formGroup]="Form3" (ngSubmit)="next()">
            <div class="row">
              <div class="col-lg-4">
                <label class="titrechamp"
                  >{{ languee.originnationname }}
                </label>

                <div style="padding-top: 1%">
                  <mat-select-country
                    [value]="Valuedorigin"
                    placeHolder=""
                    (onCountrySelected)="onCountrySelected($event)"
                  >
                  </mat-select-country>
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp"> {{ languee.asper }}</label>

                <div>
                  <ng-select
                    formControlName="selectedasper"
                    [items]="asper_list"
                    (change)="changeasper($event)"
                    bindLabel="name"
                    placeholder=""
                    [(ngModel)]="selectedasper"
                    placeholder="{{ ngselectinvalid }}"
                    [ngClass]="{ 'is-invalid': formSubmitted2 }"
                  >
                  </ng-select>
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp">
                  {{ languee.number }}
                </label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    class="form-control mb-4"
                    type="number"
                    formControlName="number"
                    required
                    [ngStyle]="{
                      'border-color':
                        (formSubmitted2 || Form3.controls.number.touched) &&
                        Form3.controls.number.errors?.required
                          ? 'red'
                          : '#47b2e4'
                    }"
                  />
                </div>
              </div>

              <div class="col-lg-12">
                <div>
                  <label class="titrechamp"
                    >{{ languee.descriptionofgoods }}
                  </label>
                  <div style="padding-top: 1%">
                    <div class="col-lg-8">
                      <div class="md-form">
                        <textarea
                          type="text"
                          id="form7"
                          formControlName="descriptionofgoods"
                          class="md-textarea form-control"
                          mdbInput
                          style="width: 100%"
                          [ngStyle]="{
                            'border-color':
                              (formSubmitted2 ||
                                Form3.controls.descriptionofgoods.touched) &&
                              Form3.controls.descriptionofgoods.errors?.required
                                ? 'red'
                                : '#47b2e4'
                          }"
                        ></textarea>
                        <label for="form7"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp"> {{ languee.date }} </label>

                <div style="padding-top: 1%">
                  <div>
                    <mat-form-field>
                      <input
                        matInput
                        required
                        formControlName="date"
                        [matDatepicker]="picker3"
                        (dateInput)="onchangedate($event.value)"
                        style="color: #1d3a66"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker3"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker3></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp">
                  {{ languee.charges }}
                </label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    class="form-control mb-4"
                    type="text"
                    formControlName="charges"
                    required
                    [ngStyle]="{
                      'border-color':
                        (formSubmitted2 || Form3.controls.charges.touched) &&
                        Form3.controls.charges.errors?.required
                          ? 'red'
                          : '#47b2e4'
                    }"
                  />
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp">
                  {{ languee.confirminstructiontype }}</label
                >

                <div>
                  <ng-select
                    formControlName="selectedconfirminstructiontype"
                    [items]="confirminstructiontype_list"
                    (change)="changeconfirminstructiontype($event)"
                    bindLabel="name"
                    placeholder=""
                    [(ngModel)]="selectedconfirminstructiontype"
                    placeholder="{{ ngselectinvalid }}"
                    [ngClass]="{ 'is-invalid': formSubmitted2 }"
                  >
                  </ng-select>
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp">
                  {{ languee.signatoryname }}
                </label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    class="form-control mb-4"
                    type="text"
                    formControlName="signatoryname"
                    required
                    [ngStyle]="{
                      'border-color':
                        (formSubmitted2 ||
                          Form3.controls.signatoryname.touched) &&
                        Form3.controls.signatoryname.errors?.required
                          ? 'red'
                          : '#47b2e4'
                    }"
                  />
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp">
                  {{ languee.signatoryfunction }}
                </label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    class="form-control mb-4"
                    type="text"
                    formControlName="signatoryfunction"
                    required
                    [ngStyle]="{
                      'border-color':
                        (formSubmitted2 ||
                          Form3.controls.signatoryfunction.touched) &&
                        Form3.controls.signatoryfunction.errors?.required
                          ? 'red'
                          : '#47b2e4'
                    }"
                  />
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp"> {{ languee.signaturedate }} </label>

                <div style="padding-top: 1%">
                  <div>
                    <mat-form-field>
                      <input
                        matInput
                        required
                        formControlName="signaturedate"
                        [matDatepicker]="picker4"
                        (dateInput)="onchangesignaturedate($event.value)"
                        style="color: #1d3a66"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker4"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker4></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                class="div_next"
                [ngStyle]="{
                  float: langue === 'AR' ? 'left' : 'right'
                }"
              >
                <button
                  click="next2()"
                  class="buttonnext"
                  mat-button
                  matStepperNext
                >
                  {{ languee.next }}
                </button>
              </div>
              <div
                class="div_back"
                [ngStyle]="{
                  float: langue === 'AR' ? 'right' : 'left'
                }"
              >
                <button class="buttonback" mat-button matStepperPrevious>
                  {{ languee.previous }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </mat-step>

      <mat-step label="{{ languee.attachment }} ">
        <ng-template matStepperIcon="edit">
          <mat-icon>check_circle</mat-icon>
        </ng-template>
        <div style="padding-top: 5%">
          <div
            class="titre_mobile"
            style="text-align: center; padding-bottom: 4%"
          >
            <label style="min-width: 100px; font-size: 18px"
              >{{ languee.attachment }}
            </label>
          </div>

          <div style="text-align: center; padding: 2% 35% 1% 40%">
            <div style="padding-top: 1%">
              <button
                (click)="open(content1)"
                type="button"
                class="btn button_file"
                style="display: flex"
              >
                <div>
                  <mat-icon class="iconadd">add</mat-icon>
                </div>
                <div style="padding-top: 3px">
                  <span class="piece"> {{ languee.newfile }} </span>
                </div>
              </button>
            </div>
          </div>
          <div class="row" style="margin: 1% 1% 1% 1%">
            <div class="div_table">
              <table mat-table [dataSource]="dataSourcePieces">
                <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

                <!-- Position Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef class="headercell">
                    {{ languee.filename }}
                  </th>
                  <td mat-cell *matCellDef="let element" class="tdcell">
                    {{ element.name }}
                  </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="file">
                  <th mat-header-cell *matHeaderCellDef class="headercell">
                    {{ languee.file }}
                  </th>
                  <td mat-cell *matCellDef="let element" class="tdcell">
                    <a
                      *ngIf="
                        element.attachment && element.attachmentContentType
                      "
                      (click)="
                        (element.id != null &&
                          getattachmentsUpdateDeletedIds().indexOf(element.id) >
                            -1) ||
                        element.id == null
                          ? openFile(
                              element.attachment,
                              element.attachmentContentType
                            )
                          : openAttachment(element.attachment)
                      "
                      style="text-decoration: none; cursor: pointer"
                      >Open</a
                    >
                    <span *ngIf="element.attachment">
                      {{ element.originalFilename }},
                      {{ element.fileSize }}</span
                    >
                  </td>
                </ng-container>

                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef class="headercell">
                    {{ languee.actions }}
                  </th>
                  <td mat-cell *matCellDef="let element" class="tdcell">
                    <button
                      matTooltip="{{ languef.view }}"
                      (click)="viewPDF(file, element)"
                      style="border: 0px solid; background-color: #ffffff"
                    >
                      <div class="btn-action">
                        <mat-icon style="color: rgb(110, 110, 110)"
                          >remove_red_eye</mat-icon
                        >
                      </div>
                    </button>
                    <button
                      matTooltip="{{ languef.edit }}"
                      (click)="open(content1); updateFileMode(element)"
                      style="border: 0px solid; background-color: #ffffff"
                    >
                      <div class="btn-action">
                        <mat-icon style="color: #2c7422">edit</mat-icon>
                      </div>
                    </button>
                    <button
                      matTooltip="{{ languef.delete }}"
                      (click)="deleteAttachment(element)"
                      style="border: 0px solid; background-color: #ffffff"
                    >
                      <div class="btn-action">
                        <mat-icon style="color: #742222"
                          >delete_forever</mat-icon
                        >
                      </div>
                    </button>
                  </td>
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumnsPieces"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumnsPieces"
                ></tr>
              </table>
            </div>
          </div>
        </div>
        <div>
          <div
            class="div_back"
            [ngStyle]="{
              float: langue === 'AR' ? 'right' : 'left'
            }"
          >
            <button class="buttonback" mat-button matStepperPrevious>
              {{ languee.previous }}
            </button>
          </div>
        </div>
      </mat-step>
    </mat-stepper>
  </div>

  <div class="col-lg-12">
    <div class="col-lg-12 actions">
      <!--    <div class="btnmobile">
        <button
          type="button"
          [disabled]="Form1.invalid || Form2.invalid || Form3.invalid"
          class="btn buttonsave"
          (click)="Submit(submit_lc)"
        >
          <label class="labsave"> {{ languee.submit }}</label>
        </button>
      </div> -->
      <div class="btnmobile">
        <button
          type="button"
          [disabled]="Form1.invalid || Form2.invalid || Form3.invalid"
          class="btn buttonsave"
          (click)="Save(save_lc)"
        >
          <label class="labsave"> {{ languee.save }}</label>
        </button>
      </div>

      <div class="btnmobile">
        <input
          style="display: none"
          type="file"
          #fileInput
          (change)="onFileChange($event)"
          name="uploadFile"
          multiple="multiple"
          #hiddenfileinput
        />
        <button
          type="button"
          class="btn buttonupload"
          (click)="hiddenfileinput.click()"
        >
          <label class="labupload"> {{ languee.upload }}</label>
        </button>
      </div>
      <div class="btnmobile">
        <button (click)="Go_list()" type="button" class="btn buttonlist">
          <div style="padding-top: 3px">
            <label class="lablist"> {{ languee.list }} </label>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #submit_lc>
  <div class="modal-body rounded-8" [ngStyle]="{ direction: direction }">
    <div style="text-align: center">
      <div>
        <span
          class="material-icons green-color"
          style="
            font-size: 100px;
            color: #41d96f;
            text-align: center;
            font-weight: 800;
          "
          >check_circle
        </span>
      </div>
      <div>
        <label
          style="
            font-family: Montserrat;
            font-size: 26px;
            padding-top: 2%;
            font-weight: bold;
            color: #073866;
          "
        >
          {{ languet.titremodal }}</label
        >
      </div>
      <div>
        <label
          style="
            width: 310px;
            font-size: 16px;
            font-weight: bold;
            padding-top: 40px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.77;
            letter-spacing: normal;
            text-align: center;
          "
        >
          {{ languet.descriptionsubmitlc }}</label
        >
      </div>
    </div>
  </div>
</ng-template>

<ng-template #save_lc>
  <div class="modal-body rounded-8" [ngStyle]="{ direction: direction }">
    <div style="text-align: center">
      <div>
        <span
          class="material-icons green-color"
          style="
            font-size: 100px;
            color: #41d96f;
            text-align: center;
            font-weight: 800;
          "
          >check_circle
        </span>
      </div>
      <div>
        <label
          style="
            font-family: Montserrat;
            font-size: 26px;
            padding-top: 2%;
            font-weight: bold;
            color: #073866;
          "
        >
          {{ languet.titremodal }}</label
        >
      </div>
      <div>
        <label
          style="
            width: 310px;
            font-size: 16px;
            font-weight: bold;
            padding-top: 40px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.77;
            letter-spacing: normal;
            text-align: center;
          "
        >
          {{ languet.descriptionsavelc }}</label
        >
      </div>
      <div class="text-center" style="padding-bottom: 2%; padding-top: 6%">
        <button type="button" class="btn button2" (click)="Go_list()">
          <span class="Save"> {{ languet.list }}</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.5.1/jspdf.umd.min.js"></script>
<ng-template #content1 let-modal>
  <div class="modal-header text-center">
    <h4 class="modal-title w-100" id="modal-basic-title">
      {{ languee.newfile }}
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click'); isUpdatingAttachment = false"
    ></button>
  </div>
  <div class="modal-body" [ngStyle]="{ direction: direction }">
    <div class="bodymodal_file">
      <form [formGroup]="FormPieces" autocomplete="off" novalidate form>
        <div class="row">
          <div class="col-lg-12">
            <div>
              <label class="titrechamp"> {{ languee.filename }} </label>
            </div>
            <div style="padding-top: 1%">
              <input
                required
                type="text"
                mdbInput
                class="form-control mb-4"
                formControlName="name"
              />
            </div>
          </div>

          <div class="col-lg-12">
            <div>
              <label class="titrechamp"> {{ languee.file }} </label>
            </div>
            <div
              class="col-3 mb-2 form-text text-danger clearfix"
              style="padding-right: 0"
              *ngIf="FormPieces.get('file')!.value != null"
            >
              <a
                style="
                  padding-bottom: 0;
                  margin-bottom: 0;
                  text-decoration: none;
                  cursor: pointer;
                "
                class="pull-start"
                (click)="
                  (FormPieces.get('id')!.value != null &&
                    getattachmentsUpdateDeletedIds().indexOf(
                      FormPieces.get('id')!.value
                    ) > -1) ||
                  FormPieces.get('id')!.value == null ||
                  currentFile
                    ? openFile(
                        FormPieces.get('file')!.value,
                        FormPieces.get('attachmentContentType')!.value
                      )
                    : openAttachment(FormPieces.get('file')!.value)
                "
                >Open</a
              >
              <br />
              <span class="pull-start">{{
                currentFile
                  ? currentFile.name
                  : FormPieces.get("originalFilename")!.value
              }}</span
              ><br />
              <span class="pull-start"
                >{{
                  currentFile
                    ? currentFile.type
                    : FormPieces.get("attachmentContentType")!.value
                }},
                {{
                  currentFile
                    ? currentFile.size
                    : FormPieces.get("fileSize")!.value
                }}</span
              >
              <button
                style="
                  border-radius: 33%;
                  width: 28px;
                  height: 28px;
                  padding: 0;
                  margin-left: 2px;
                  background-color: #f3969a;
                  border-color: #f3969a;
                "
                type="button"
                (click)="
                  FormPieces.patchValue({ file: null });
                  FormPieces.patchValue({ attachmentContentType: null })
                "
                class="btn btn-secondary btn-xs pull-end"
              >
                <div class="btn-action">
                  <mat-icon>clear</mat-icon>
                </div>
              </button>
            </div>
            <a
              class="dropdown-item"
              (click)="collapseNavbar(); loadRecentFiles()"
            >
              <mat-icon>file_copy</mat-icon>
              <span>Recent files</span>
            </a>
            <!-- <a
          class="dropdown-item"
          (click)="collapseNavbar(); loadAllAttachmentByOriginFilename(FormPieces.get('originalFilename')!.value)"
        >
          <span>Recent files By name</span>
        </a> -->
            <a
              class="dropdown-item"
              (click)="collapseNavbar(); loadFromComputer()"
            >
              <!-- <fa-icon icon="computer"></fa-icon>  -->
              <mat-icon>computer</mat-icon>
              <span>Load from your computer</span>
            </a>

            <div [hidden]="isRecentFiles == false" class="col-lg-4 mb-2">
              <!-- Recent file -->

              <ng-select
                formControlName="attachmentRecentlyUploaded"
                (change)="recentFileUpdateForm($event)"
                bindLabel="attachment"
                placeholder="{{ ngselectinvalid }}"
                [ngClass]="{ 'is-invalid': formSubmitted }"
              >
                <!-- [(ngModel)]="selectedattachment" -->
                <ng-option
                  *ngFor="
                    let attachmentRecentlyUploaded of attachmentsRecentlyUploaded
                  "
                  [value]="attachmentRecentlyUploaded"
                >
                  <span>{{ attachmentRecentlyUploaded.originalFilename }}</span>
                </ng-option>
              </ng-select>
              <!-- <ul class="navbar-nav ms-auto">
              <li
                ngbDropdown
                class="nav-item dropdown pointer"
                display="dynamic"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <a
                  #recentFiles
                  style="color: rgb(99, 97, 97)"
                  class="nav-link dropdown-toggle"
                  ngbDropdownToggle
                  href="javascript:void(0);"
                  id="entity-menu"
                  data-cy="entity"
                >
                  <span>
                    <span>Recent Files</span>
                  </span>
                </a>
                <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="entity-menu">
                  <li *ngIf="attachmentsRecentlyUploaded?.length === 0">
                    <span class="ms-2"> You don't have files!</span>
                  </li>
                  <li *ngFor="let attachmentRecentlyUploaded of attachmentsRecentlyUploaded">
                    <a class="dropdown-item" (click)="collapseNavbar(); recentFileUpdateForm(attachmentRecentlyUploaded)">
                      
                      <span>{{ attachmentRecentlyUploaded.originalFilename }}</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul> -->
              <!-- <select
            class="form-select"
            name="attachmentRecentlyUploaded"
            formControlName="attachmentRecentlyUploaded"
            id="field_attachmentRecentlyUploaded"
            data-cy="attachmentRecentlyUploaded"
          >
            <option *ngFor="let attachmentRecentlyUploaded of attachmentsRecentlyUploaded" [value]="attachmentRecentlyUploaded">
              <fa-icon icon="arrow-circle-down" style="color:#5cb85c;"></fa-icon>
              {{ attachmentRecentlyUploaded.originalFilename  }}
            </option>
          </select> -->
              <!-- Recent file -->
            </div>

            <div [hidden]="isRecentFilesByName == false" class="col-lg-3 mb-2">
              <!-- Recent file -->

              <ul class="navbar-nav ms-auto">
                <li
                  ngbDropdown
                  class="nav-item dropdown pointer"
                  display="dynamic"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <!-- #recentFilesByName -->
                  <a
                    style="color: rgb(99, 97, 97); padding: 0"
                    class="nav-link ngbDropdownToggle"
                    ngbDropdownToggle
                    href="javascript:void(0);"
                    id="entity-menu"
                    data-cy="entity"
                  >
                    <!-- <fa-icon icon="th-list"></fa-icon> -->
                    <input
                      (change)="
                        loadAllAttachmentByOriginFilename(
                          FormPieces.get('originalFilename')!.value
                        )
                      "
                      class="form-control"
                      style="display: inline-block"
                      name="originalFilename"
                      id="field_originalFilename"
                      formControlName="originalFilename"
                    />

                    <!-- (change)="loadAllAttachmentByOriginFilename(FormPieces.get('originalFilename')!.value)" -->

                    <!-- <button class="btn btn-secondary btn-xs pull-end" (click)="loadAllAttachmentByOriginFilename(FormPieces.get('originalFilename')!.value)"><fa-icon icon="times"></fa-icon></button> -->
                  </a>
                  <ul
                    class="dropdown-menu"
                    ngbDropdownMenu
                    aria-labelledby="entity-menu"
                  >
                    <li *ngIf="attachmentsRecentlyUploaded?.length === 0">
                      <span class="ms-2"> You don't have files!</span>
                    </li>
                    <li
                      *ngFor="
                        let attachmentRecentlyUploaded of attachmentsRecentlyUploadedByName
                      "
                    >
                      <a
                        class="dropdown-item"
                        (click)="
                          collapseNavbar();
                          recentFileUpdateForm(attachmentRecentlyUploaded)
                        "
                      >
                        <!-- <fa-icon
                        [icon]="getFileExtension(attachmentRecentlyUploaded.originalFilename ?? '')"
                        [fixedWidth]="true"
                      ></fa-icon> -->
                        <span>{{
                          attachmentRecentlyUploaded.originalFilename
                        }}</span>
                      </a>
                    </li>

                    <!-- jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here -->
                  </ul>
                </li>
              </ul>
              <!-- <select
            class="form-select"
            name="attachmentRecentlyUploaded"
            formControlName="attachmentRecentlyUploaded"
            id="field_attachmentRecentlyUploaded"
            data-cy="attachmentRecentlyUploaded"
          >
            <option *ngFor="let attachmentRecentlyUploaded of attachmentsRecentlyUploaded" [value]="attachmentRecentlyUploaded">
              <fa-icon icon="arrow-circle-down" style="color:#5cb85c;"></fa-icon>
              {{ attachmentRecentlyUploaded.originalFilename  }}
            </option>
          </select> -->
              <!-- Recent file -->
            </div>
            <div [hidden]="isLoadComputer == false" class="col-lg-4 mb-2">
              <!-- <label class="form-label" jhiTranslate="eserviceJHipsterApp.attachment.attachment" for="field_attachment">Attachment</label> -->
              <input
                #computer
                (change)="
                  setFileData($event, 'file', false); onChangefile($event)
                "
                type="file"
                class="form-control"
                id="file_file"
                data-cy="file"
                style="font-size: 15px; padding-left: 2%"
              />

              <input
                type="hidden"
                class="form-control"
                name="file"
                id="field_file"
                data-cy="file"
                formControlName="file"
              />
              <input
                type="hidden"
                class="form-control"
                name="attachmentContentType"
                id="field_attachmentContentType"
                formControlName="attachmentContentType"
              />
            </div>
            <!-- <div style="padding-top: 1%">
              <input
                (change)="
                  setFileData($event, 'file', false); onChangefile($event)
                "
                type="file"
                class="form-control"
                id="file_file"
                data-cy="file"
                style="font-size: 15px; padding-left: 2%"
              />
              <input
                type="hidden"
                class="form-control"
                name="file"
                id="field_file"
                data-cy="file"
                formControlName="file"
              />
              <input
                type="hidden"
                class="form-control"
                name="attachmentContentType"
                id="field_attachmentContentType"
                formControlName="attachmentContentType"
              />
              
            </div> -->
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="text-center" style="padding-bottom: 2%">
    <button
      [disabled]="FormPieces.invalid"
      type="button"
      class="btn button_file_save"
      (click)="isUpdatingAttachment == false ? file_add() : file_update()"
    >
      <span class="save"> {{ languee.save }}</span>
    </button>
  </div>
</ng-template>
<notifier-container></notifier-container>

<ng-template #file>
  <div style="height: 600px">
    <div id="outerContainer" *ngIf="type_source == 'pdf'">
      <pdf-viewer
        [src]="file_source"
        [rotation]="0"
        [original-size]="false"
        [show-all]="true"
        [fit-to-page]="false"
        [zoom]="1"
        [zoom-scale]="'page-width'"
        [stick-to-page]="false"
        [render-text]="true"
        [external-link-target]="'blank'"
        [autoresize]="true"
        [show-borders]="false"
        style="width: 100%; height: 600px"
      ></pdf-viewer>
    </div>

    <div *ngIf="type_source == 'image'">
      <img [src]="file_source" width="100%" height="100%" />
    </div>
  </div>
</ng-template>
