import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from 'src/Services/User/user.service';

@Injectable({
  providedIn: 'root',
})
export class TradeGuard implements CanActivate {
  constructor(private userauth: UserService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (
      !this.userauth.ValidRole('Trading Company') &&
      !this.userauth.ValidRole('Agent') && !this.userauth.ValidRole('Exporter')
    ) {
      this.router.navigateByUrl('/Error/notfound');
    }
    return (
      this.userauth.ValidRole('Agent') ||
      this.userauth.ValidRole('Trading Company') ||
      this.userauth.ValidRole('Exporter')
    );
  }
}
