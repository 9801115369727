<link
  rel="stylesheet"
  href="https://use.fontawesome.com/releases/v5.5.0/css/all.css"
  integrity="sha384-B4dIYHKNBt8Bc12p+WXckhzcICo0wtJAoU8YZTY5qE0Id1GSseTk6S+L3BlXeVIU"
  crossorigin="anonymous"
/>

<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/css/all.css"
/>

<div *ngIf="!skeleton">
  <div>
    <h2 class="titre-page">{{ languef.logistic }}</h2>
  </div>

  <div class="row">
    <div class="col-lg-6">
      <div class="card1">
      <div class="header">
        <span class="titre-card"> {{ languef.sa }}</span>
        <div class="header1" >
          <mat-radio-group
          class="radio-group"
            [(ngModel)]="selectedTypeSa"
          >
            <mat-radio-button
            class="radio-button"
            
              value="2"
         
              >  {{ languef.chart }}
            </mat-radio-button>
            <mat-radio-button      
            value="1" 
            class="radio-button"
              >
              {{ languef.text }}
            </mat-radio-button>
          </mat-radio-group>
        </div>

      </div>
      
      <div *ngIf="selectedTypeSa == 1" class="row">

        
        <div class="col-lg-6">
          <div class="sous_card1">

          
          <div>
            <span class="titre_sous_card"> {{ languef.op }}</span>
          </div>
          <div>
            <span class="titre_sous_card">
              {{ shippignArrival_save }}
              <b style="font-size: 11px">
                ({{ shippignArrival_save_moy }}%)
              </b>
            </span>
          </div>
        </div>
      </div>
        <div class="col-lg-6">
          <div  class="sous_card2">

         
          <div>
            <span class="titre_sous_card"> {{ languef.fl }} </span>
          </div>
          <div>
            <span class="titre_sous_card">
              {{ shippingArrival_refuse }}

              <b style="font-size: 11px">
                ({{ shippingArrival_refuse }}%)
              </b>
            </span>
          </div>
        </div>
      </div>
        <div class="col-lg-6" >
          <div class="sous_card3">
          <div>
            <span class="titre_sous_card"> {{ languef.fp }} </span>
          </div>
          <div>
            <span class="titre_sous_card">
              {{ shippingArrival_forconf }}

              <b style="font-size: 11px">
                ({{ shippingArrival_forconf_moy }}%)
              </b>
            </span>
          </div>
        </div>
      </div>
        <div class="col-lg-6">
          <div class="sous_card4">

         
          <div>
            <span class="titre_sous_card"> {{ languef.cc }} </span>
          </div>
          <div>
            <span class="titre_sous_card">
              {{ shippingArrival_accept }}

              <b style="font-size: 11px">
                ({{ shippingArrival_accept_moy}}%)
              </b>
            </span>
          </div>
          </div>
        </div>
        
      </div>

      <div *ngIf="selectedTypeSa == 2" style="text-align: center;">

        <canvas 
          height="300"
          width="500"
          baseChart
          [type]="'pie'"
          [datasets]="pieChartDatasets_SA"
          [labels]="pieChartLabels"
          [options]="pieChartOptions"
          [plugins]="pieChartPlugins"
          [legend]="pieChartLegend">
        </canvas>

      </div>

      <div
      class="footer"
        [ngStyle]="{ direction: direction }"
      >
        <div style="display: flex">
          <div class="spantotal">
            <span class="total">{{ languef.tf }} : </span>
          </div>
          <div>
            <span class="res">{{ shippingArrival_total }} </span>
          </div>
        </div>
        <div class="more">
          <button
            mat-button
            class="btn btn-light shadow-lg"
            style="display: flex; float: left"
            class="button_show"
            (click)="Go_list(0)"
            [ngStyle]="{
              float: langue === 'AR' ? 'left' : 'right'
            }"
          >
            <div>
              <label class="document"> {{ languef.sm }} </label>
            </div>
            <div>
              <mat-icon
                *ngIf="langue == 'EN' || langue == 'FR'"
                style="color: #47aee1; padding-top: 4%; cursor: pointer"
                >chevron_right</mat-icon
              >
              <mat-icon
                *ngIf="langue == 'AR'"
                style="color: #47aee1; padding-top: 4%; cursor: pointer"
                >chevron_left</mat-icon
              >
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
    <div class="col-lg-6">
      <div class="card1">
      <div class="header">
        <span class="titre-card"> {{ languef.sdp }}</span>

        <div class="header1">
          <mat-radio-group
          class="radio-group"
            [(ngModel)]="selectedTypeSDP"
          >
            <mat-radio-button
            class="radio-button"
              value="2"
         
              >  {{ languef.chart }}
            </mat-radio-button>
            <mat-radio-button      
            value="1" 
            class="radio-button"
              >
              {{ languef.text }}
            </mat-radio-button>
          </mat-radio-group>
        </div>

      </div>
      <div *ngIf="selectedTypeSDP == 1" class="row mobile_card">
        <div class="col-lg-6">
          <div class="sous_card1">
          <div>
            <span class="titre_sous_card"> {{ languef.op }}</span>
          </div>
          <div style="padding-top: 3%">
            <span class="titre_sous_card">
              {{ shippignDeparture_save }}
              <b style="font-size: 11px">
                ({{
                  shippignDeparture_save_moy
                }}%)
              </b>
            </span>
          </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="sous_card2">
          <div>
            <span class="titre_sous_card"> {{ languef.fl }} </span>
          </div>
          <div style="padding-top: 3%">
            <span class="titre_sous_card">
              {{ shippingDeparture_refuse }}

              <b style="font-size: 11px">
                ({{
                  shippingDeparture_refuse_moy
                }}%)
              </b>
            </span>
          </div>
        </div>
        </div>
        <div class="col-lg-6">
          <div class="sous_card3">
          <div>
            <span class="titre_sous_card"> {{ languef.fp }} </span>
          </div>
          <div style="padding-top: 3%">
            <span class="titre_sous_card">
              {{ shippingDeparture_forconf }}

              <b style="font-size: 11px">
                ({{
                  shippingDeparture_forconf_moy
                }}%)
              </b>
            </span>
          </div>
        </div>
</div>
        <div class="col-lg-6 ">
          <div class="sous_card4">
          <div>
            <span class="titre_sous_card"> {{ languef.cc }} </span>
          </div>
          <div style="padding-top: 3%">
            <span class="titre_sous_card">
              {{ shippingDeparture_accept }}

              <b style="font-size: 11px">
                ({{
                  shippingDeparture_accept_moy
                }}%)
              </b>
            </span>
          </div>
        </div>
        </div>
      </div>

      <div *ngIf="selectedTypeSDP == 2">

        <canvas baseChart
          height="300"
          width="500"
          [type]="'pie'"
          [datasets]="pieChartDatasets_SDP"
          [labels]="pieChartLabels"
          [options]="pieChartOptions"
          [plugins]="pieChartPlugins"
          [legend]="pieChartLegend">
        </canvas>

      </div>

      <div
        style="display: flex; padding-top: 6%"
        [ngStyle]="{ direction: direction }"
      >
        <div style="display: flex">
          <div class="spantotal">
            <span class="total">{{ languef.tf }} : </span>
          </div>
          <div>
            <span class="res">{{ shippingDeparture_total }} </span>
          </div>
        </div>
        <div class="more">
          <button
            mat-button
            class="btn btn-light shadow-lg"
            style="display: flex"
            class="button_show"
            (click)="Go_list(1)"
            [ngStyle]="{
              float: langue === 'AR' ? 'left' : 'right'
            }"
          >
            <div>
              <label class="document"> {{ languef.sm }} </label>
            </div>
            <div>
              <mat-icon
                *ngIf="langue == 'EN' || langue == 'FR'"
                style="color: #47aee1; padding-top: 4%; cursor: pointer"
                >chevron_right</mat-icon
              >
              <mat-icon
                *ngIf="langue == 'AR'"
                style="color: #47aee1; padding-top: 4%; cursor: pointer"
                >chevron_left</mat-icon
              >
            </div>
          </button>
        </div>
      </div>
    </div>
</div>
    <div class="col-lg-6" style="padding-top: 2%;">
      <div class="card1">
      <div class="header">
        <span class="titre-card"> {{ languef.sd }}</span>

    
        <div class="header1">
          <mat-radio-group
          class="radio-group"
            [(ngModel)]="selectedTypeSD"
          >
            <mat-radio-button
            class="radio-button"
              value="2"
         
              >  {{ languef.chart }}
            </mat-radio-button>
            <mat-radio-button      
            value="1" 
            class="radio-button"
              >
              {{ languef.text }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
     
      </div>
      <div *ngIf="selectedTypeSD == 1" class="row mobile_card">
        <div class="col-lg-6">
          <div class="sous_card1">
          <div>
            <span class="titre_sous_card"> {{ languef.op }}</span>
          </div>
          <div style="padding-top: 6%">
            <span class="titre_sous_card">
              {{ Ship_Docking_save }}

              <b style="font-size: 11px">
                ({{ Ship_Docking_save_moy }}%)
              </b>
            </span>
          </div>
        </div>
        </div>

        <div class="col-lg-6">
          <div class="sous_card2">

          <div>
            <span class="titre_sous_card"> {{ languef.fl }} </span>
          </div>
          <div style="padding-top: 3%">
            <span class="titre_sous_card">
              {{ Ship_Docking_refuse }}

              <b style="font-size: 11px">
                ({{ Ship_Docking_refuse_moy }}%)
              </b>
            </span>
          </div>
        </div>
        </div>
        <div class="col-lg-6">
          <div class="sous_card3">

          <div>
            <span class="titre_sous_card"> {{ languef.fp }} </span>
          </div>
          <div style="padding-top: 3%">
            <span class="titre_sous_card">
              {{ Ship_Docking_forconf }}

              <b style="font-size: 11px">
                ({{ Ship_Docking_forconf_moy }}%)
              </b>
            </span>
          </div>
        </div>
        </div>

        <div class="col-lg-6">
          <div class="sous_card4">
          <div>
            <span class="titre_sous_card"> {{ languef.cc }} </span>
          </div>
          <div style="padding-top: 3%">
            <span class="titre_sous_card">
              {{ Ship_Docking_accept }}

              <b style="font-size: 11px">
                ({{ Ship_Docking_accept_moy }}%)
              </b>
            </span>
          </div>
        </div>
        </div>
      </div>
      <div *ngIf="selectedTypeSD == 2">

        <canvas baseChart
           height="300"
           width="500"
           [type]="'pie'"
           [datasets]="pieChartDatasets_SD"
           [labels]="pieChartLabels"
           [options]="pieChartOptions"
           [plugins]="pieChartPlugins"
           [legend]="pieChartLegend">
        </canvas>

      </div>
      <div
        style="display: flex; padding-top: 6%"
        [ngStyle]="{ direction: direction }"
      >
        <div style="display: flex">
          <div class="spantotal">
            <span class="total">{{ languef.tf }} : </span>
          </div>
          <div>
            <span class="res">{{ Ship_Docking_total }} </span>
          </div>
        </div>
        <div class="more">
          <button
            mat-button
            class="btn btn-light shadow-lg"
            style="display: flex"
            class="button_show"
            (click)="Go_list(2)"
            [ngStyle]="{
              float: langue === 'AR' ? 'left' : 'right'
            }"
          >
            <div>
              <label class="document"> {{ languef.sm }}</label>
            </div>
            <div>
              <mat-icon
                *ngIf="langue == 'EN' || langue == 'FR'"
                style="color: #47aee1; padding-top: 4%; cursor: pointer"
                >chevron_right</mat-icon
              >
              <mat-icon
                *ngIf="langue == 'AR'"
                style="color: #47aee1; padding-top: 4%; cursor: pointer"
                >chevron_left</mat-icon
              >
            </div>
          </button>
        </div>
      </div>
    </div>
    </div>

   <div  class="col-lg-6" style="padding-top: 2%;">
      <div class="card1">
      <div class="header">
        <span class="titre-card"> {{ languef.cm }}</span>
       
        <div class="header1">
          <mat-radio-group
          class="radio-group"
            [(ngModel)]="selectedTypeCM"
          >
            <mat-radio-button
            class="radio-button"
              value="2"
         
              >  {{ languef.chart }}
            </mat-radio-button>
            <mat-radio-button      
            value="1" 
            class="radio-button"
              >
              {{ languef.text }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div *ngIf="selectedTypeCM == 1" class="row mobile_card">
        <div class="col-lg-6">
          <div class="sous_card1">
          <div>
            <span class="titre_sous_card"> {{ languef.op }}</span>
          </div>
          <div style="padding-top: 3%">
            <span class="titre_sous_card">
              {{ Cargo_Manifest_save }}

              <b style="font-size: 11px">
                ({{ Cargo_Manifest_save_moy }}%)
              </b>
            </span>
          </div>
        </div>
</div>
        <div class="col-lg-6">
          <div class="sous_card2">
          <div>
            <span class="titre_sous_card"> {{ languef.fl }} </span>
          </div>
          <div style="padding-top: 3%">
            <span class="titre_sous_card">
              {{ Cargo_Manifest_refuse }}
              <b style="font-size: 11px">
                ({{ Cargo_Manifest_refuse_moy }}%)
              </b>
            </span>
          </div>
        </div>
        </div>
        <div class="col-lg-6">
          <div class="sous_card3">
          <div>
            <span class="titre_sous_card"> {{ languef.fp }} </span>
          </div>
          <div style="padding-top: 3%">
            <span class="titre_sous_card">
              {{ Cargo_Manifest_forconf }}

              <b style="font-size: 11px">
                ({{ Cargo_Manifest_forconf_moy }}%)
              </b>
            </span>
          </div>
        </div>
        </div>

        <div class="col-lg-6">
          <div class="sous_card4">
          <div>
            <span class="titre_sous_card"> {{ languef.cc }} </span>
          </div>
          <div style="padding-top: 3%">
            <span class="titre_sous_card">
              {{ Cargo_Manifest_accept }}

              <b style="font-size: 11px">
                ({{ Cargo_Manifest_accept_moy }}%)
              </b>
            </span>
          </div>
        </div>
        </div>
      </div>
      <div *ngIf="selectedTypeCM == 2">

        <canvas baseChart
                height="300"
                width="500"
                [type]="'pie'"
                [datasets]="pieChartDatasets_CM"
                [labels]="pieChartLabels"
                [options]="pieChartOptions"
                [plugins]="pieChartPlugins"
                [legend]="pieChartLegend">
        </canvas>

      </div>
      <div
        style="display: flex; padding-top: 6%"
        [ngStyle]="{ direction: direction }"
      >
        <div style="display: flex">
          <div class="spantotal">
            <span class="total">{{ languef.tf }} : </span>
          </div>
          <div>
            <span class="res">{{ Cargo_Manifest_total }} </span>
          </div>
        </div>
        <div class="more">
          <button
            mat-button
            class="btn btn-light shadow-lg"
            style="display: flex"
            class="button_show"
            (click)="Go_list(3)"
            [ngStyle]="{
              float: langue === 'AR' ? 'left' : 'right'
            }"
          >
            <div>
              <label class="document">{{ languef.sm }} </label>
            </div>
            <div>
              <mat-icon
                *ngIf="langue == 'EN' || langue == 'FR'"
                style="color: #47aee1; padding-top: 4%; cursor: pointer"
                >chevron_right</mat-icon
              >
              <mat-icon
                *ngIf="langue == 'AR'"
                style="color: #47aee1; padding-top: 4%; cursor: pointer"
                >chevron_left</mat-icon
              >
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>



<div *ngIf="skeleton">
  <div style="text-align: center;">
  
    <ngx-skeleton-loader
    [theme]="{
      width: '250px',
      'border-radius': '15px',
      height: '45px',
      'margin-bottom': '10px'
    }"
    ></ngx-skeleton-loader>
  </div>
  
  <div class="row">
  
    <div class="col-lg-6">
  
  <div class=" card1">
      <div class="row ">
        <div class="col-lg-6 ">
          <ngx-skeleton-loader
          [theme]="{
            'width': '160px',
            'height': '50px',
            'margin-top': '40.2px',
            'margin-right': '14.3px',
            'margin-bottom': '9.7px',
            'margin-left': '0',
            'padding-top': '3%',
            'padding-right':'3%',
            'padding-bottom': '3%',
            'padding-left': '2%',
            'border-radius': '20px'
  
  
          }"
          ></ngx-skeleton-loader>
        </div>
  
        <div class="col-lg-6 sous_card2f">
          <ngx-skeleton-loader
          [theme]="{
            'width': '160px',
            'height': '50px',
            'margin-top': '40.2px',
            'margin-right': '14.3px',
            'margin-bottom': '9.7px',
            'margin-left': '0',
            'padding-top': '3%',
            'padding-right':'3%',
            'padding-bottom': '3%',
            'padding-left': '2%',
            'border-radius': '20px'
  
  
          }"
          ></ngx-skeleton-loader>
        </div>
        <div class="col-lg-6 sous_card3f">
          <ngx-skeleton-loader
          [theme]="{
            'width': '160px',
            'height': '50px',
            'margin-top': '40.2px',
            'margin-right': '14.3px',
            'margin-bottom': '9.7px',
            'margin-left': '0',
            'padding-top': '3%',
            'padding-right':'3%',
            'padding-bottom': '3%',
            'padding-left': '2%',
            'border-radius': '20px'
  
  
          }"
          ></ngx-skeleton-loader>
        </div>
  
        <div class="col-lg-6 sous_card4f">
          <ngx-skeleton-loader
          [theme]="{
            'width': '160px',
            'height': '50px',
            'margin-top': '40.2px',
            'margin-right': '14.3px',
            'margin-bottom': '9.7px',
            'margin-left': '0',
            'padding-top': '3%',
            'padding-right':'3%',
            'padding-bottom': '3%',
            'padding-left': '2%',
            'border-radius': '20px'
  
  
          }"
          ></ngx-skeleton-loader>
        </div>
      </div>
     </div>
  
    </div>
  
    <div class="col-lg-6">
  
      <div class=" card1">
      <div class="row ">
        <div class="col-lg-6 ">
          <ngx-skeleton-loader
          [theme]="{
            'width': '160px',
            'height': '50px',
            'margin-top': '40.2px',
            'margin-right': '14.3px',
            'margin-bottom': '9.7px',
            'margin-left': '0',
            'padding-top': '3%',
            'padding-right':'3%',
            'padding-bottom': '3%',
            'padding-left': '2%',
            'border-radius': '20px'
  
  
          }"
          ></ngx-skeleton-loader>
        </div>
  
        <div class="col-lg-6 sous_card2f">
          <ngx-skeleton-loader
          [theme]="{
            'width': '160px',
            'height': '50px',
            'margin-top': '40.2px',
            'margin-right': '14.3px',
            'margin-bottom': '9.7px',
            'margin-left': '0',
            'padding-top': '3%',
            'padding-right':'3%',
            'padding-bottom': '3%',
            'padding-left': '2%',
            'border-radius': '20px'
  
  
          }"
          ></ngx-skeleton-loader>
        </div>
        <div class="col-lg-6 sous_card3f">
          <ngx-skeleton-loader
          [theme]="{
            'width': '160px',
            'height': '50px',
            'margin-top': '40.2px',
            'margin-right': '14.3px',
            'margin-bottom': '9.7px',
            'margin-left': '0',
            'padding-top': '3%',
            'padding-right':'3%',
            'padding-bottom': '3%',
            'padding-left': '2%',
            'border-radius': '20px'
  
  
          }"
          ></ngx-skeleton-loader>
        </div>
  
        <div class="col-lg-6 sous_card4f">
          <ngx-skeleton-loader
          [theme]="{
            'width': '160px',
            'height': '50px',
            'margin-top': '40.2px',
            'margin-right': '14.3px',
            'margin-bottom': '9.7px',
            'margin-left': '0',
            'padding-top': '3%',
            'padding-right':'3%',
            'padding-bottom': '3%',
            'padding-left': '2%',
            'border-radius': '20px'
  
  
          }"
          ></ngx-skeleton-loader>
        </div>
      </div>
     </div>
  
    </div>
    <div class="col-lg-6">
  
      <div class=" card1">
      <div class="row ">
        <div class="col-lg-6 ">
          <ngx-skeleton-loader
          [theme]="{
            'width': '160px',
            'height': '50px',
            'margin-top': '40.2px',
            'margin-right': '14.3px',
            'margin-bottom': '9.7px',
            'margin-left': '0',
            'padding-top': '3%',
            'padding-right':'3%',
            'padding-bottom': '3%',
            'padding-left': '2%',
            'border-radius': '20px'
  
  
          }"
          ></ngx-skeleton-loader>
        </div>
  
        <div class="col-lg-6 sous_card2f">
          <ngx-skeleton-loader
          [theme]="{
            'width': '160px',
            'height': '50px',
            'margin-top': '40.2px',
            'margin-right': '14.3px',
            'margin-bottom': '9.7px',
            'margin-left': '0',
            'padding-top': '3%',
            'padding-right':'3%',
            'padding-bottom': '3%',
            'padding-left': '2%',
            'border-radius': '20px'
  
  
          }"
          ></ngx-skeleton-loader>
        </div>
        <div class="col-lg-6 sous_card3f">
          <ngx-skeleton-loader
          [theme]="{
            'width': '160px',
            'height': '50px',
            'margin-top': '40.2px',
            'margin-right': '14.3px',
            'margin-bottom': '9.7px',
            'margin-left': '0',
            'padding-top': '3%',
            'padding-right':'3%',
            'padding-bottom': '3%',
            'padding-left': '2%',
            'border-radius': '20px'
  
  
          }"
          ></ngx-skeleton-loader>
        </div>
  
        <div class="col-lg-6 sous_card4f">
          <ngx-skeleton-loader
          [theme]="{
            'width': '160px',
            'height': '50px',
            'margin-top': '40.2px',
            'margin-right': '14.3px',
            'margin-bottom': '9.7px',
            'margin-left': '0',
            'padding-top': '3%',
            'padding-right':'3%',
            'padding-bottom': '3%',
            'padding-left': '2%',
            'border-radius': '20px'
  
  
          }"
          ></ngx-skeleton-loader>
        </div>
      </div>
     </div>
  
    </div>
    <div class="col-lg-6">
      <div class=" card1">
  
      <div class="row ">
        <div class="col-lg-6 ">
          <ngx-skeleton-loader
          [theme]="{
            'width': '160px',
            'height': '50px',
            'margin-top': '40.2px',
            'margin-right': '14.3px',
            'margin-bottom': '9.7px',
            'margin-left': '0',
            'padding-top': '3%',
            'padding-right':'3%',
            'padding-bottom': '3%',
            'padding-left': '2%',
            'border-radius': '20px'
  
  
          }"
          ></ngx-skeleton-loader>
        </div>
  
        <div class="col-lg-6 sous_card2f">
          <ngx-skeleton-loader
          [theme]="{
            'width': '160px',
            'height': '50px',
            'margin-top': '40.2px',
            'margin-right': '14.3px',
            'margin-bottom': '9.7px',
            'margin-left': '0',
            'padding-top': '3%',
            'padding-right':'3%',
            'padding-bottom': '3%',
            'padding-left': '2%',
            'border-radius': '20px'
  
  
          }"
          ></ngx-skeleton-loader>
        </div>
        <div class="col-lg-6 sous_card3f">
          <ngx-skeleton-loader
          [theme]="{
            'width': '160px',
            'height': '50px',
            'margin-top': '40.2px',
            'margin-right': '14.3px',
            'margin-bottom': '9.7px',
            'margin-left': '0',
            'padding-top': '3%',
            'padding-right':'3%',
            'padding-bottom': '3%',
            'padding-left': '2%',
            'border-radius': '20px'
  
  
          }"
          ></ngx-skeleton-loader>
        </div>
  
        <div class="col-lg-6 sous_card4f">
          <ngx-skeleton-loader
          [theme]="{
            'width': '160px',
            'height': '50px',
            'margin-top': '40.2px',
            'margin-right': '14.3px',
            'margin-bottom': '9.7px',
            'margin-left': '0',
            'padding-top': '3%',
            'padding-right':'3%',
            'padding-bottom': '3%',
            'padding-left': '2%',
            'border-radius': '20px'
  
  
          }"
          ></ngx-skeleton-loader>
        </div>
      </div>
     </div>
  
    </div>
  
  </div>
  </div>
  
