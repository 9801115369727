import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar.component';
import { LandingPageComponent } from '../landing-page/landing-page.component';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    // LandingPageComponent
  ]
})
export class NavbarModule { }
