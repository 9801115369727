<link
  rel="stylesheet"
  href="https://use.fontawesome.com/releases/v5.5.0/css/all.css"
  integrity="sha384-B4dIYHKNBt8Bc12p+WXckhzcICo0wtJAoU8YZTY5qE0Id1GSseTk6S+L3BlXeVIU"
  crossorigin="anonymous"
/>
<script
  src="https://kit.fontawesome.com/8f702b3b95.js"
  crossorigin="anonymous"
></script>
<div class="c1" [ngStyle]="{ direction: direction }">
  <div class="Trac-2081"></div>

  <div>
    <h2 class="titre-page">{{ languea.titre }}</h2>
    <div class="about-border"></div>
  </div>
</div>

<div class="row" [ngStyle]="{ direction: direction }">
  <div class="col-lg-12">
    <mat-stepper linear #stepper>
      <mat-step
        *ngIf="etat == 'Save' || etat == 'Error'"
        [stepControl]="IdentificationForm"
      >
        <ng-template matStepLabel role="tab" aria-selected="true">
          <div style="display: flex">
            <label style="min-width: 100px">{{ languea.titre }} </label>
          </div>
        </ng-template>
        <div style="padding-top: 5%">
          <div
            class="titre_mobile"
            style="text-align: center; padding-bottom: 4%"
          >
            <label style="min-width: 100px; font-size: 18px"
              >{{ languea.titre }}
            </label>
          </div>
          <form [formGroup]="IdentificationForm" (ngSubmit)="next()">
            <div class="row">
              <div class="col-lg-4">
                <div>
                  <label class="titrechamp"> {{ languea.customs }}</label>
                  <div style="padding-top: 4px">
                    <ng-select
                      formControlName="selectedcustom"
                      [items]="customs_list"
                      (change)="changecustom($event)"
                      bindLabel="name"
                      [(ngModel)]="selectedcustom"
                      placeholder="{{ ngselectinvalid }}"
                      [ngClass]="{
                        'is-invalid': formSubmitted,
                        formerror: formSubmitted
                      }"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <div>
                  <label class="titrechamp">
                    {{ languea.typeofdeclaration }}*</label
                  >
                  <div style="padding-top: 4px">
                    <ng-select
                      formControlName="typeofdecalaration"
                      [items]="typeofdecalarationlist"
                      (change)="changetypeofdecalaration($event)"
                      bindLabel="name"
                      [(ngModel)]="selectettypeofdecalaration"
                      placeholder="{{ ngselectinvalid }}"
                      [ngClass]="{
                        'is-invalid': formSubmitted,
                        formerror: formSubmitted
                      }"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <div>
                  <label class="titrechamp">
                    {{ languea.officeofdeclaration }}*</label
                  >
                  <div style="padding-top: 4px">
                    <ng-select
                      formControlName="officeofdecalaration"
                      [items]="officeofeclarationlist"
                      (change)="changeofficeofeclaration($event)"
                      bindLabel="name"
                      placeholder=""
                      [(ngModel)]="selectetofficeofeclaration"
                      placeholder="{{ ngselectinvalid }}"
                      [ngClass]="{
                        'is-invalid': formSubmitted,
                        formerror: formSubmitted
                      }"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp">{{ languea.bl }} </label>

                <div style="padding-top: 1%">
                  <input
                    required
                    matInput
                    class="form-control"
                    type="number"
                    min="0"
                    formControlName="bl"
                    [ngStyle]="{
                      'border-color':
                        (formSubmitted ||
                          IdentificationForm.controls.bl.touched) &&
                        IdentificationForm.controls.bl.errors?.required
                          ? 'red'
                          : '#47b2e4'
                    }"
                  />
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp">
                  {{ languea.manifest }}
                </label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    class="form-control mb-4"
                    type="number"
                    min="0"
                    formControlName="manifest"
                    [ngStyle]="{
                      'border-color':
                        (formSubmitted ||
                          IdentificationForm.controls.manifest.touched) &&
                        IdentificationForm.controls.manifest.errors?.required
                          ? 'red'
                          : '#47b2e4'
                    }"
                  />
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp">
                  {{ languea.numberofpackages }}*
                </label>

                <div style="padding-top: 1%">
                  <input
                    class="form-control mb-4"
                    matInput
                    type="number"
                    min="0"
                    formControlName="numberofpackages"
                    readonly
                  />
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp"> {{ languea.loadlist }}* </label>

                <div style="padding-top: 1%">
                  <input
                    class="form-control mb-4"
                    matInput
                    type="number"
                    min="0"
                    formControlName="loadlist"
                  />
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp"> {{ languea.totalitems }} </label>

                <div style="padding-top: 1%">
                  <input
                    readonly
                    matInput
                    class="form-control mb-4"
                    type="number"
                    min="0"
                    formControlName="totalitems"
                  />
                </div>
              </div>
            </div>
            <div>
              <div
                [ngStyle]="{
                  float: langue === 'AR' ? 'left' : 'right'
                }"
                class="div_next"
              >
                <button
                  class="buttonnext"
                  mat-button
                  (click)="next()"
                  matStepperNext
                >
                  {{ languea.next }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </mat-step>
      <mat-step
        *ngIf="etat == 'Save' || etat == 'Error'"
        [stepControl]="secondFormGroup"
      >
        <ng-template matStepLabel>
          <div style="display: flex">
            <label style="min-width: 100px">
              {{ languea.trades }} & {{ languea.destination }}
            </label>
          </div>
        </ng-template>
        <form [formGroup]="secondFormGroup" (ngSubmit)="next1()">
          <div style="padding-top: 2%">
            <div class="componentWrapper">
              <div class="header">{{ languea.trades }}</div>
              <div style="padding-top: 2%">
                <div class="row">
                  <div class="col-lg-4">
                    <label class="titrechamp">{{ languea.exportername }}</label>

                    <div style="padding-top: 1%">
                      <input
                        matInput
                        class="form-control mb-4"
                        type="text"
                        formControlName="exportername"
                        required
                        [ngStyle]="{
                          'border-color':
                            (formSubmitted1 ||
                              secondFormGroup.controls.exportername.touched) &&
                            secondFormGroup.controls.exportername.errors
                              ?.required
                              ? 'red'
                              : '#47b2e4'
                        }"
                      />
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <label class="titrechamp"
                      >{{ languea.consigneename }}
                    </label>

                    <div style="padding-top: 1%">
                      <input
                        matInput
                        class="form-control mb-4"
                        type="text"
                        formControlName="consigneename"
                        required
                        [ngStyle]="{
                          'border-color':
                            (formSubmitted1 ||
                              secondFormGroup.controls.consigneename.touched) &&
                            secondFormGroup.controls.consigneename.errors
                              ?.required
                              ? 'red'
                              : '#47b2e4'
                        }"
                      />
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <label class="titrechamp"
                      >{{ languea.financialname }}
                    </label>

                    <div style="padding-top: 1%">
                      <input
                        matInput
                        class="form-control mb-4"
                        type="text"
                        formControlName="financialname"
                        required
                        [ngStyle]="{
                          'border-color':
                            (formSubmitted1 ||
                              secondFormGroup.controls.financialname.touched) &&
                            secondFormGroup.controls.financialname.errors
                              ?.required
                              ? 'red'
                              : '#47b2e4'
                        }"
                      />
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <label class="titrechamp">{{
                      languea.declarantname
                    }}</label>

                    <div style="padding-top: 1%">
                      <input
                        matInput
                        class="form-control mb-4"
                        type="text"
                        formControlName="declarantname"
                        required
                        [ngStyle]="{
                          'border-color':
                            (formSubmitted1 ||
                              secondFormGroup.controls.declarantname.touched) &&
                            secondFormGroup.controls.declarantname.errors
                              ?.required
                              ? 'red'
                              : '#47b2e4'
                        }"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="padding-top: 2%">
            <div class="componentWrapper">
              <div class="header">{{ languea.destination }}</div>
              <div style="padding-top: 2%">
                <div class="row">
                  <div class="col-lg-6">
                    <div>
                      <label class="titrechamp">{{
                        languea.countryofexport
                      }}</label>
                    </div>
                    <div>
                      <mat-select-country
                        [value]="Valuepexp"
                        placeHolder=""
                        (onCountrySelected)="onCountrySelected($event)"
                      >
                      </mat-select-country>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div>
                      <label class="titrechamp"
                        >{{ languea.countryofdestination }}
                      </label>
                    </div>
                    <div>
                      <mat-select-country
                        [value]="Valuedes"
                        placeHolder=""
                        (onCountrySelected)="
                          onCountrySelected_destination($event)
                        "
                      >
                      </mat-select-country>
                    </div>
                  </div>
                  <div class="col-lg-6" style="padding-top: 1%">
                    <div>
                      <label class="titrechamp"
                        >{{ languea.countryoforigin }}*</label
                      >
                    </div>
                    <div>
                      <mat-select-country
                        [value]="Valuedorigin"
                        placeHolder=""
                        (onCountrySelected)="onCountrySelected_origin($event)"
                      >
                      </mat-select-country>
                    </div>
                  </div>

                  <div class="col-lg-6" style="padding-top: 1%">
                    <label class="titrechamp"
                      >{{ languea.valuedetails }}
                    </label>

                    <input
                      matInput
                      class="form-control mb-4"
                      type="number"
                      [(ngModel)]="message"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style="padding-top: 6%">
            <div
              class="div_back"
              [ngStyle]="{
                float: langue === 'AR' ? 'right' : 'left'
              }"
            >
              <button class="buttonback" mat-button matStepperPrevious>
                {{ languea.previous }}
              </button>
            </div>
            <div
              class="div_next"
              [ngStyle]="{
                float: langue === 'AR' ? 'left' : 'right'
              }"
            >
              <button
                class="buttonnext"
                (click)="next1()"
                mat-button
                matStepperNext
              >
                {{ languea.next }}
              </button>
            </div>
          </div>
        </form>
      </mat-step>

      <mat-step
        [stepControl]="TransportForm"
        *ngIf="etat == 'Save' || etat == 'Error'"
      >
        <ng-template matStepLabel>
          <div style="display: flex">
            <label style="min-width: 100px"> {{ languea.transport }} </label>
          </div>
        </ng-template>
        <div style="padding-top: 5%">
          <div
            class="titre_mobile"
            style="text-align: center; padding-bottom: 4%"
          >
            <label style="min-width: 100px; font-size: 18px"
              >{{ languea.transport }}
            </label>
          </div>
          <form [formGroup]="TransportForm" (ngSubmit)="next2()">
            <div class="row">
              <div class="col-lg-4">
                <div>
                  <label class="titrechamp"
                    >{{ languea.nationalitycrossingtheborder }}
                  </label>
                </div>
                <div>
                  <mat-select-country
                    [value]="Valuepcro"
                    placeHolder=""
                    (onCountrySelected)="onCountrySelected_Crossing($event)"
                  >
                  </mat-select-country>
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp">
                  {{ languea.modetransportcrossing }}
                </label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    class="form-control mb-4"
                    type="text"
                    formControlName="mode_transport_crossing_the_border"
                    [ngStyle]="{
                      'border-color':
                        (formSubmitted1 ||
                          TransportForm.controls
                            .mode_transport_crossing_the_border.touched) &&
                        TransportForm.controls
                          .mode_transport_crossing_the_border.errors?.required
                          ? 'red'
                          : '#47b2e4'
                    }"
                  />
                </div>
              </div>

              <div class="col-lg-4">
                <div>
                  <label class="titrechamp">{{ languea.officeofentry }} </label>
                  <div style="padding-top: 4px">
                    <ng-select
                      formControlName="officeofentry"
                      [items]="officeofentrylist"
                      (change)="changeofficeofentry($event)"
                      bindLabel="name"
                      placeholder=""
                      [(ngModel)]="selectedofficeofentry"
                      [ngClass]="{
                        'is-invalid': formSubmitted2,
                        formerror: formSubmitted2
                      }"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp">
                  {{ languea.inlandtransportmode }}
                </label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    class="form-control mb-4"
                    type="text"
                    formControlName="inland_transport_mode"
                    [ngStyle]="{
                      'border-color':
                        (formSubmitted1 ||
                          TransportForm.controls.inland_transport_mode
                            .touched) &&
                        TransportForm.controls.inland_transport_mode.errors
                          ?.required
                          ? 'red'
                          : '#47b2e4'
                    }"
                  />
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp">
                  {{ languea.locationofgoods }}
                </label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    class="form-control mb-4"
                    type="text"
                    formControlName="locationofGoods"
                    [ngStyle]="{
                      'border-color':
                        (formSubmitted1 ||
                          TransportForm.controls.locationofGoods.touched) &&
                        TransportForm.controls.locationofGoods.errors?.required
                          ? 'red'
                          : '#47b2e4'
                    }"
                  />
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp"> {{ languea.container }} </label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    class="form-control mb-4"
                    type="number"
                    formControlName="container"
                    [ngStyle]="{
                      'border-color':
                        (formSubmitted1 ||
                          TransportForm.controls.container.touched) &&
                        TransportForm.controls.container.errors?.required
                          ? 'red'
                          : '#47b2e4'
                    }"
                  />
                </div>
              </div>
            </div>
            <div [ngStyle]="{ direction: direction }">
              <div
                class="div_back"
                [ngStyle]="{
                  float: langue === 'AR' ? 'right' : 'left'
                }"
              >
                <button class="buttonback" mat-button matStepperPrevious>
                  {{ languea.previous }}
                </button>
              </div>
              <div
                class="div_next"
                [ngStyle]="{
                  float: langue === 'AR' ? 'left' : 'right'
                }"
              >
                <button class="buttonnext" mat-button matStepperNext>
                  {{ languea.next }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </mat-step>
      <mat-step *ngIf="etat == 'Confirm'">
        <ng-template matStepLabel>
          <div style="display: flex">
            <label style="min-width: 100px"> {{ languea.financial }} </label>
          </div>
        </ng-template>
        <div style="padding-top: 5%">
          <div
            class="titre_mobile"
            style="text-align: center; padding-bottom: 4%"
          >
            <label style="min-width: 100px; font-size: 18px"
              >{{ languea.financial }}
            </label>
          </div>
          <form [formGroup]="FinancialForm">
            <div class="row">
              <div class="col-lg-4">
                <div>
                  <label class="titrechamp"
                    >{{ languea.natureoftransaction }}
                  </label>
                  <div style="padding-top: 4px">
                    <ng-select
                      formControlName="naturetransaction"
                      [items]="natureoftransactionlist"
                      (change)="changenatureoftransaction($event)"
                      bindLabel="name"
                      placeholder=""
                      [(ngModel)]="naturetransaction"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div>
                  <label class="titrechamp"> {{ languea.bank }} </label>
                  <div style="padding-top: 4px">
                    <ng-select
                      formControlName="bank"
                      [items]="Banklist"
                      (change)="changebank($event)"
                      bindLabel="name"
                      [(ngModel)]="bank"
                      placeholder=""
                    >
                    </ng-select>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <div>
                  <label class="titrechamp"> {{ languea.bankbranch }}</label>
                  <div style="padding-top: 4px">
                    <ng-select
                      formControlName="bankbranch"
                      [items]="Bankbranchlist"
                      (change)="changebankbranch($event)"
                      bindLabel="name"
                      [(ngModel)]="bankbranch"
                      placeholder=""
                    >
                    </ng-select>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <div>
                  <label class="titrechamp">
                    {{ languea.termsofpayment }}
                  </label>
                  <div style="padding-top: 4px">
                    <ng-select
                      formControlName="codepayment"
                      [items]="termpaymentlist"
                      (change)="changetermeofpayement($event)"
                      bindLabel="name"
                      [(ngModel)]="codepayment"
                      placeholder=""
                    >
                    </ng-select>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp"> {{ languea.modeofpayment }}</label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    class="form-control mb-4"
                    type="text"
                    formControlName="modelofpayment"
                    required
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div>
          <div
            class="div_back"
            [ngStyle]="{
              float: langue === 'AR' ? 'right' : 'left'
            }"
          >
            <button class="buttonback" mat-button matStepperPrevious>
              {{ languea.previous }}
            </button>
          </div>
          <div
            class="div_next"
            [ngStyle]="{
              float: langue === 'AR' ? 'left' : 'right'
            }"
          >
            <button class="buttonnext" mat-button matStepperNext>
              {{ languea.next }}
            </button>
          </div>
        </div>
      </mat-step>
      <mat-step
        [stepControl]="TransitForm"
        *ngIf="etat == 'Save' || etat == 'Error'"
      >
        <ng-template matStepLabel>
          <div style="display: flex">
            <label style="min-width: 100px"> {{ languea.transit }} </label>
          </div>
        </ng-template>
        <div style="padding-top: 5%">
          <div
            class="titre_mobile"
            style="text-align: center; padding-bottom: 4%"
          >
            <label style="min-width: 100px; font-size: 18px"
              >{{ languea.transit }}
            </label>
          </div>
          <form [formGroup]="TransitForm" (ngSubmit)="next3()">
            <div class="row">
              <div class="col-lg-4">
                <div>
                  <label class="titrechamp">{{ languea.warehousecd }} </label>

                  <div style="padding-top: 1%">
                    <input
                      matInput
                      class="form-control mb-4"
                      type="text"
                      formControlName="warehousecd"
                      [ngStyle]="{
                        'border-color':
                          (formSubmitted3 ||
                            TransitForm.controls.warehousecd.touched) &&
                          TransitForm.controls.warehousecd.errors?.required
                            ? 'red'
                            : '#47b2e4'
                      }"
                    />
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <div>
                  <label class="titrechamp"
                    >{{ languea.warehousedelay }}
                  </label>

                  <div style="padding-top: 1%">
                    <input
                      matInput
                      class="form-control mb-4"
                      type="number"
                      formControlName="warehousede"
                      [ngStyle]="{
                        'border-color':
                          (formSubmitted3 ||
                            TransitForm.controls.warehousede.touched) &&
                          TransitForm.controls.warehousede.errors?.required
                            ? 'red'
                            : '#47b2e4'
                      }"
                    />
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <div>
                  <label class="titrechamp"> {{ languea.forwarder }}</label>
                  <div style="padding-top: 4px">
                    <ng-select
                      formControlName="forwarder"
                      [items]="Forwarderlist"
                      (change)="changeForwarder($event)"
                      bindLabel="name"
                      placeholder=""
                      [(ngModel)]="forwarder"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp">{{ languea.representative }} </label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    class="form-control mb-4"
                    type="text"
                    formControlName="representative"
                    required
                    [ngStyle]="{
                      'border-color':
                        (formSubmitted3 ||
                          TransitForm.controls.representative.touched) &&
                        TransitForm.controls.representative.errors?.required
                          ? 'red'
                          : '#47b2e4'
                    }"
                  />
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp">{{ languea.signatureplace }} </label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    class="form-control mb-4"
                    type="text"
                    formControlName="signatureplace"
                    required
                    [ngStyle]="{
                      'border-color':
                        (formSubmitted3 ||
                          TransitForm.controls.signatureplace.touched) &&
                        TransitForm.controls.signatureplace.errors?.required
                          ? 'red'
                          : '#47b2e4'
                    }"
                  />
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp"> {{ languea.signaturedate }}</label>

                <div>
                  <mat-form-field>
                    <input
                      matInput
                      [matDatepicker]="picker11"
                      [formControl]="dateofsignature"
                      (dateInput)="onchangedateofsignature($event.value)"
                      style="color: #1d3a66"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker11"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker11></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp"
                  >{{ languea.officesofdestination }}
                </label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    class="form-control mb-4"
                    type="text"
                    formControlName="companyofdestination"
                    [ngStyle]="{
                      'border-color':
                        (formSubmitted3 ||
                          TransitForm.controls.companyofdestination.touched) &&
                        TransitForm.controls.companyofdestination.errors
                          ?.required
                          ? 'red'
                          : '#47b2e4'
                    }"
                  />
                </div>
              </div>

              <div class="col-lg-4">
                <div>
                  <label class="titrechamp"
                    >{{ languea.countrycompanyofdestination }}
                  </label>
                </div>
                <div>
                  <mat-select-country
                    style="color: #1d3a66"
                    placeHolder=""
                    (onCountrySelected)="onCountrySelectedtransit($event)"
                  >
                  </mat-select-country>
                </div>
              </div>
            </div>
            <div>
              <div
                class="div_back"
                [ngStyle]="{
                  float: langue === 'AR' ? 'right' : 'left'
                }"
              >
                <button class="buttonback" mat-button matStepperPrevious>
                  {{ languea.previous }}
                </button>
              </div>
              <div
                class="div_next"
                [ngStyle]="{
                  float: langue === 'AR' ? 'left' : 'right'
                }"
              >
                <button class="buttonnext" mat-button matStepperNext>
                  {{ languea.next }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </mat-step>
      <mat-step *ngIf="etat == 'Confirm'">
        <ng-template matStepLabel>
          <div style="display: flex">
            <label style="min-width: 100px"> {{ languea.segment }} </label>
          </div>
        </ng-template>
        <div style="padding-top: 5%">
          <div
            class="titre_mobile"
            style="text-align: center; padding-bottom: 4%"
          >
            <label style="min-width: 100px; font-size: 18px"
              >{{ languea.segment }}
            </label>
          </div>
          <form [formGroup]="GeneralsegmentForm">
            <div class="row">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-2"></div>
                  <div class="col-lg-3">
                    <label class="titre_table">{{ languea.amount }}</label>
                  </div>
                  <div class="col-lg-1">
                    <label class="titre_table"> {{ languea.fcxcode }}</label>
                  </div>
                  <div class="col-lg-3">
                    <label class="titre_table">{{
                      languea.exchangerate
                    }}</label>
                  </div>
                  <div class="col-lg-3">
                    <label class="titre_table">{{
                      languea.amountinnationalymonetaryunits
                    }}</label>
                  </div>
                </div>
              </div>
              <div class="col-lg-12" style="padding-top: 5px">
                <div class="row">
                  <div class="col-lg-2">{{ languea.invoicevalue }}</div>
                  <div class="col-lg-3">
                    <div style="padding-top: 1%">
                      <input
                        matInput
                        class="form-control mb-4"
                        type="number"
                        formControlName="invoicevalue"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-1">
                    <ng-select
                      [items]="devisfcxlist"
                      (change)="changedevisefcx($event, 'Invoice')"
                      bindLabel="name"
                      placeholder=""
                    >
                    </ng-select>
                  </div>
                  <div class="col-lg-3">
                    <div style="padding-top: 1%">
                      <input
                        matInput
                        class="form-control mb-4"
                        type="number"
                        formControlName="invoicevaluerate"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div style="padding-top: 1%">
                      <input
                        matInput
                        class="form-control mb-4"
                        type="number"
                        formControlName="invoicevalueamount"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-12" style="padding-top: 5px">
                <div class="row">
                  <div class="col-lg-2">{{ languea.externelfreight }}</div>
                  <div class="col-lg-3">
                    <div style="padding-top: 1%">
                      <input
                        matInput
                        class="form-control mb-4"
                        type="number"
                        formControlName="externalfreight"
                      />
                    </div>
                  </div>
                  <div class="col-lg-1">
                    <ng-select
                      [items]="devisfcxlist"
                      (change)="changedevisefcx($event, 'Externel')"
                      bindLabel="name"
                      placeholder=""
                    >
                    </ng-select>
                  </div>
                  <div class="col-lg-3">
                    <div style="padding-top: 1%">
                      <input
                        matInput
                        class="form-control mb-4"
                        type="number"
                        formControlName="externalfreightrate"
                      />
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div style="padding-top: 1%">
                      <input
                        matInput
                        class="form-control mb-4"
                        type="number"
                        formControlName="externalfreightamount"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12" style="padding-top: 5px">
                <div class="row">
                  <div class="col-lg-2">{{ languea.internalfreight }}</div>
                  <div class="col-lg-3">
                    <div style="padding-top: 1%">
                      <input
                        matInput
                        class="form-control mb-4"
                        type="number"
                        formControlName="internalfreight"
                      />
                    </div>
                  </div>
                  <div class="col-lg-1">
                    <ng-select
                      [items]="devisfcxlist"
                      (change)="changedevisefcx($event, 'Internal')"
                      bindLabel="name"
                      placeholder=""
                    >
                    </ng-select>
                  </div>
                  <div class="col-lg-3">
                    <div style="padding-top: 1%">
                      <input
                        matInput
                        class="form-control mb-4"
                        type="number"
                        formControlName="internalfreightrate"
                      />
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div style="padding-top: 1%">
                      <input
                        matInput
                        class="form-control mb-4"
                        type="number"
                        formControlName="internalfreightamount"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-12" style="padding-top: 5px">
                <div class="row">
                  <div class="col-lg-2">{{ languea.insurance }}</div>
                  <div class="col-lg-3">
                    <div style="padding-top: 1%">
                      <input
                        matInput
                        class="form-control mb-4"
                        type="number"
                        formControlName="insurancevalue"
                      />
                    </div>
                  </div>
                  <div class="col-lg-1">
                    <ng-select
                      [items]="devisfcxlist"
                      (change)="changedevisefcx($event, 'Insurance')"
                      bindLabel="name"
                      placeholder=""
                    >
                    </ng-select>
                  </div>
                  <div class="col-lg-3">
                    <div style="padding-top: 1%">
                      <input
                        matInput
                        class="form-control mb-4"
                        type="number"
                        formControlName="insurancevaluerate"
                      />
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div style="padding-top: 1%">
                      <input
                        matInput
                        class="form-control mb-4"
                        type="number"
                        formControlName="insurancevalueamount"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-12" style="padding-top: 5px">
                <div class="row">
                  <div class="col-lg-2">{{ languea.othercost }}</div>
                  <div class="col-lg-3">
                    <div style="padding-top: 1%">
                      <input
                        matInput
                        class="form-control mb-4"
                        type="number"
                        formControlName="othercostvalue"
                      />
                    </div>
                  </div>
                  <div class="col-lg-1">
                    <ng-select
                      [items]="devisfcxlist"
                      (change)="changedevisefcx($event, 'Other')"
                      bindLabel="name"
                      placeholder=""
                    >
                    </ng-select>
                  </div>
                  <div class="col-lg-3">
                    <div style="padding-top: 1%">
                      <input
                        matInput
                        class="form-control mb-4"
                        type="number"
                        formControlName="othercostvaluerate"
                      />
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div style="padding-top: 1%">
                      <input
                        matInput
                        class="form-control mb-4"
                        type="number"
                        formControlName="othercostvalueamount"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-12" style="padding-top: 5px">
                <div class="row">
                  <div class="col-lg-2">{{ languea.deductions }}</div>
                  <div class="col-lg-3">
                    <div style="padding-top: 1%">
                      <input
                        matInput
                        class="form-control mb-4"
                        type="number"
                        formControlName="deductionsvalue"
                      />
                    </div>
                  </div>
                  <div class="col-lg-1">
                    <ng-select
                      [items]="devisfcxlist"
                      (change)="changedevisefcx($event, 'Deductions')"
                      bindLabel="name"
                      placeholder=""
                    >
                    </ng-select>
                  </div>
                  <div class="col-lg-3">
                    <div style="padding-top: 1%">
                      <input
                        matInput
                        class="form-control mb-4"
                        type="number"
                        formControlName="deductionsvaluerate"
                      />
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div style="padding-top: 1%">
                      <input
                        matInput
                        class="form-control mb-4"
                        type="number"
                        formControlName="deductionsvalueamount"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <label class="titrechamp">{{ languea.totalgrossmass }}* </label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    class="form-control mb-4"
                    type="number"
                    formControlName="totalgrossmass"
                  />
                </div>
              </div>

              <div class="col-lg-6">
                <label class="titrechamp">{{ languea.totalcosts }}* </label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    class="form-control mb-4"
                    type="number"
                    formControlName="totalcosts"
                  />
                </div>
              </div>

              <div class="col-lg-6">
                <label class="titrechamp">{{ languea.deliveryterms }}* </label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    class="form-control mb-4"
                    type="text"
                    formControlName="deliveryterms"
                  />
                </div>
              </div>

              <div class="col-lg-6">
                <label class="titrechamp">{{ languea.cifvalue }}* </label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    class="form-control mb-4"
                    type="number"
                    formControlName="cifvalue"
                  />
                </div>
              </div>

              <div class="col-lg-6">
                <label class="titrechamp">{{ languea.guaranteeamount }} </label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    class="form-control mb-4"
                    type="number"
                    formControlName="guaranteeamount"
                  />
                </div>
              </div>

              <div class="col-lg-6">
                <label class="titrechamp">{{ languea.guaranteedate }} </label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    class="form-control mb-4"
                    type="date"
                    formControlName="guaranteedate"
                  />
                </div>
              </div>

              <div class="col-lg-6">
                <label class="titrechamp">{{ languea.totalfees }} </label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    class="form-control mb-4"
                    type="number"
                    formControlName="totalfees"
                  />
                </div>
              </div>

              <div class="col-lg-6">
                <label class="titrechamp"
                  >{{ languea.totaldeclaration }}
                </label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    class="form-control mb-4"
                    type="number"
                    formControlName="totaldeclaration"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div>
          <div
            class="div_back"
            [ngStyle]="{
              float: langue === 'AR' ? 'right' : 'left'
            }"
          >
            <button class="buttonback" mat-button matStepperPrevious>
              {{ languea.previous }}
            </button>
          </div>
          <div
            class="div_next"
            [ngStyle]="{
              float: langue === 'AR' ? 'left' : 'right'
            }"
          >
            <button class="buttonnext" mat-button matStepperNext>
              {{ languea.next }}
            </button>
          </div>
        </div>
      </mat-step>

      <mat-step *ngIf="etat == 'Confirm'">
        <ng-template matStepLabel>
          <div style="display: flex">
            <label style="min-width: 100px"> {{ languea.payment }} </label>
          </div>
        </ng-template>
        <div
          class="titre_mobile"
          style="text-align: center; padding-bottom: 4%"
        >
          <label style="min-width: 100px; font-size: 18px"
            >{{ languea.payment }}
          </label>
        </div>
        <div>
          <div style="text-align: center">
            <div style="padding-top: 2%">
              <label
                style="
                  width: 100%;
                  font-family: Poppins;
                  font-size: 20px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.5;
                  letter-spacing: normal;
                  text-align: center;
                  color: #1d3a66;
                "
              >
                {{ languet.messagefiledrop }}
              </label>
            </div>
            <div style="padding-top: 2%">
              <div
                class="dropzone"
                fileDragDrop
                (filesChangeEmiter)="onFileChange2($event.target.files)"
              >
                <div class="text-wrapper">
                  <div class="centered">
                    <input
                      type="file"
                      name="file"
                      id="file"
                      (change)="onFileChange2($event.target.files)"
                    />
                    <label for="file">
                      <span class="textLink">
                        <mat-icon> attach_file</mat-icon>
                        {{ languet.selectfile }}</span
                      >
                      {{ languet.dropfile }}</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            class="div_back"
            [ngStyle]="{
              float: langue === 'AR' ? 'right' : 'left'
            }"
          >
            <button class="buttonback" mat-button matStepperPrevious>
              {{ languea.previous }}
            </button>
          </div>
          <div
            class="div_next"
            [ngStyle]="{
              float: langue === 'AR' ? 'left' : 'right'
            }"
          >
            <button class="buttonnext" mat-button matStepperNext>
              {{ languea.next }}
            </button>
          </div>
        </div>
      </mat-step>
      <mat-step
        *ngIf="etat == 'Save' || etat == 'Error'"
        label=" {{ languea.articles }}"
      >
        <ng-template matStepperIcon="edit">
          <mat-icon>check_circle</mat-icon>
        </ng-template>
        <div
          class="titre_mobile"
          style="text-align: center; padding-bottom: 4%"
        >
          <label style="min-width: 100px; font-size: 18px"
            >{{ languea.articles }}
          </label>
        </div>
        <div class="steppclass">
          <div style="text-align: center; padding: 2% 35% 1% 40%">
            <div style="padding-top: 1%">
              <button
                (click)="open(content2)"
                type="button"
                class="btn button_file"
                style="display: flex"
              >
                <div>
                  <mat-icon class="iconadd">add</mat-icon>
                </div>
                <div style="padding-top: 3px; padding-left: 2%">
                  <span class="article"> {{ languea.newarticle }} </span>
                </div>
              </button>
            </div>
          </div>

          <div class="div_table">
            <table mat-table [dataSource]="dataSource">
              <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

              <!-- Position Column -->
              <ng-container matColumnDef="no">
                <th mat-header-cell *matHeaderCellDef class="headercell">
                  {{ languet.id }}.
                </th>
                <td mat-cell *matCellDef="let element" class="tdcell">
                  {{ element.no }}
                </td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="ndp">
                <th mat-header-cell *matHeaderCellDef class="headercell">
                  {{ languea.preferencecode }}
                </th>
                <td mat-cell *matCellDef="let element" class="tdcell">
                  {{ element.pc }}
                </td>
              </ng-container>

              <!-- Désignation Column -->
              <ng-container matColumnDef="des">
                <th mat-header-cell *matHeaderCellDef class="headercell">
                  {{ languea.summarydeclarationsi }}
                </th>
                <td mat-cell *matCellDef="let element" class="tdcell">
                  {{ element.sds }}
                </td>
              </ng-container>

              <!-- PFN Column -->
              <ng-container matColumnDef="pfn">
                <th mat-header-cell *matHeaderCellDef class="headercell">
                  {{ languea.itemprice }}
                </th>
                <td mat-cell *matCellDef="let element" class="tdcell">
                  {{ element.prix }}
                </td>
              </ng-container>

              <!-- PFN Code QCS -->
              <ng-container matColumnDef="codeqcs">
                <th mat-header-cell *matHeaderCellDef class="headercell">
                  {{ languea.quota }}
                </th>
                <td mat-cell *matCellDef="let element" class="tdcell">
                  {{ element.quota }}
                </td>
              </ng-container>

              <!-- PFN QCS -->
              <ng-container matColumnDef="brute">
                <th mat-header-cell *matHeaderCellDef class="headercell">
                  {{ languea.brutemass }}
                </th>
                <td mat-cell *matCellDef="let element" class="tdcell">
                  {{ element.brute }}
                </td>
              </ng-container>

              <!-- PFN QCS -->
              <ng-container matColumnDef="net">
                <th mat-header-cell *matHeaderCellDef class="headercell">
                  {{ languea.netmass }}
                </th>
                <td mat-cell *matCellDef="let element" class="tdcell">
                  {{ element.net }}
                </td>
              </ng-container>

              <!-- PFN Pays Origine -->
              <ng-container matColumnDef="pays">
                <th mat-header-cell *matHeaderCellDef class="headercell">
                  {{ languea.paysorigine }}
                </th>
                <td mat-cell *matCellDef="let element" class="tdcell">
                  {{ element.pays }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
        </div>
        <div>
          <div
            class="div_back"
            [ngStyle]="{
              float: langue === 'AR' ? 'right' : 'left'
            }"
          >
            <button class="buttonback" mat-button matStepperPrevious>
              {{ languea.previous }}
            </button>
          </div>
        </div>
      </mat-step>
    </mat-stepper>
  </div>

  <div class="col-lg-12">
    <div class="col-lg-12 actions">
      <div class="btnmobile" *ngIf="etat != 'Confirm'">
        <button
          type="button"
          [disabled]="
            IdentificationForm.invalid ||
            TransitForm.invalid ||
            TransportForm.invalid ||
            secondFormGroup.invalid
          "
          class="btn buttonsave"
          (click)="Submit(content4)"
        >
          <label class="labsave"> {{ languea.submit }}</label>
        </button>
      </div>
      <div class="btnmobile" *ngIf="etat != 'Confirm'">
        <button
          type="button"
          [disabled]="
            IdentificationForm.invalid ||
            TransitForm.invalid ||
            TransportForm.invalid ||
            secondFormGroup.invalid ||
            selectedcustom == null ||
            etat != 'Save'
          "
          class="btn buttonsave"
          (click)="Save(content3)"
        >
          <label class="labsave"> {{ languea.save }}</label>
        </button>
      </div>

      <div class="btnmobile" *ngIf="etat == 'Confirm'">
        <button
          type="button"
          [disabled]="
            FinancialForm.invalid ||
            GeneralsegmentForm.invalid ||
            recu_payement == null
          "
          class="btn buttonsave"
          (click)="Submit(content4)"
        >
          <label class="labsave"> {{ languea.submit }}</label>
        </button>
      </div>

      <div class="btnmobile">
        <input
          style="display: none"
          type="file"
          #fileInput
          (change)="onFileChange($event)"
          name="uploadFile"
          multiple="multiple"
          #hiddenfileinput
        />
        <button
          type="button"
          class="btn buttonupload"
          (click)="hiddenfileinput.click()"
        >
          <label class="labupload"> {{ languea.upload }}</label>
        </button>
      </div>
      <div class="btnmobile">
        <button (click)="Go_list()" type="button" class="btn buttonlist">
          <div style="padding-top: 3px">
            <label class="lablist"> {{ languea.list }} </label>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #content3>
  <div class="modal-body rounded-8" [ngStyle]="{ direction: direction }">
    <div style="text-align: center">
      <div>
        <span
          class="material-icons green-color"
          style="
            font-size: 100px;
            color: #41d96f;
            text-align: center;
            font-weight: 800;
          "
          >check_circle
        </span>
      </div>
      <div>
        <label
          style="
            font-family: Montserrat;
            font-size: 26px;
            padding-top: 2%;
            font-weight: bold;
            color: #073866;
          "
        >
          {{ languet.titremodal }}</label
        >
      </div>
      <div>
        <label
          style="
            width: 310px;
            font-size: 16px;
            font-weight: bold;
            padding-top: 40px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.77;
            letter-spacing: normal;
            text-align: center;
          "
        >
          {{ languet.descriptionsavecustoms }}</label
        >
      </div>
      <div class="text-center" style="padding-bottom: 2%; padding-top: 6%">
        <button type="button" class="btn button2" (click)="Go_list()">
          <span class="Save"> {{ languea.list }}</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.5.1/jspdf.umd.min.js"></script>

<notifier-container></notifier-container>

<ng-template #content2 let-modal>
  <div class="modal-header text-center">
    <h4 class="modal-title w-100" id="modal-basic-title">
      {{ languea.newarticle }}
    </h4>

    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body" [ngStyle]="{ direction: direction }">
    <div class="bodymodal_file">
      <form [formGroup]="FormArticle" autocomplete="off" novalidate form>
        <div class="row">
          <div style="padding-top: 2%" class="row">
            <div class="col-lg-6">
              <div>
                <label class="titrechamp"> {{ languea.preferencecode }}</label>
              </div>
              <div style="padding-top: 1%">
                <input
                  required
                  type="text"
                  mdbInput
                  class="form-control mb-4"
                  formControlName="ngp"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div>
                <label class="titrechamp"
                  >{{ languea.summarydeclarationsi }}
                </label>
              </div>
              <div style="padding-top: 1%">
                <input
                  required
                  type="text"
                  mdbInput
                  class="form-control mb-4"
                  formControlName="libelle"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div>
                <label class="titrechamp"> {{ languea.brutemass }} </label>
              </div>
              <div style="padding-top: 1%">
                <input
                  required
                  type="number"
                  mdbInput
                  class="form-control mb-4"
                  formControlName="quantitebrute"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div>
                <label class="titrechamp"> {{ languea.netmass }} </label>
              </div>
              <div style="padding-top: 1%">
                <input
                  required
                  type="number"
                  mdbInput
                  class="form-control mb-4"
                  formControlName="quantitenet"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div>
                <label class="titrechamp"> {{ languea.quota }} </label>
              </div>
              <div style="padding-top: 1%">
                <input
                  required
                  type="text"
                  mdbInput
                  class="form-control mb-4"
                  formControlName="codeqcs"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div>
                <label class="titrechamp"> {{ languea.itemprice }} </label>
              </div>
              <div style="padding-top: 1%">
                <input
                  required
                  type="text"
                  mdbInput
                  class="form-control mb-4"
                  formControlName="pfn"
                />
              </div>
            </div>
            <div class="col-lg-12">
              <div>
                <label class="titrechamp"> {{ languea.paysorigine }} </label>
              </div>
              <div style="padding-top: 1%">
                <mat-select-country
                  style="color: #1d3a66"
                  placeHolder=""
                  (onCountrySelected)="onCountrySelectedarticle($event)"
                >
                </mat-select-country>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="text-center" style="padding-bottom: 2%">
    <button
      [disabled]="FormArticle.invalid"
      type="button"
      class="btn button_file_save"
      (click)="Add_article()"
    >
      <span class="save"> {{ languea.save }}</span>
    </button>
  </div>
</ng-template>

<ng-template #content4>
  <div class="modal-body rounded-8" [ngStyle]="{ direction: direction }">
    <div style="text-align: center">
      <div>
        <span
          class="material-icons green-color"
          style="
            font-size: 100px;
            color: #41d96f;
            text-align: center;
            font-weight: 800;
          "
          >check_circle
        </span>
      </div>
      <div>
        <label
          style="
            font-family: Montserrat;
            font-size: 26px;
            padding-top: 2%;
            font-weight: bold;
            color: #073866;
          "
        >
          {{ languet.titremodal }}</label
        >
      </div>
      <div>
        <label
          style="
            width: 310px;
            font-size: 16px;
            font-weight: bold;
            padding-top: 40px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.77;
            letter-spacing: normal;
            text-align: center;
          "
        >
          {{ languet.descriptionsubmitcustoms }}</label
        >
      </div>
      <div class="text-center" style="padding-bottom: 2%; padding-top: 6%">
        <button type="button" class="btn button2" (click)="Go_list()">
          <span class="Save"> {{ languea.list }}</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>
