import { Country } from '@angular-material-extensions/select-country';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrganisationService } from 'src/Services/Organisation/organisation.service';
import { UserService } from 'src/Services/User/user.service';
import Langue from '../../../../../assets/langue.json';

import * as XLSX from 'xlsx';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { CreditLetterService } from '../Services/credit-letter.service';
import {
  HttpErrorResponse,
  HttpEventType,
  HttpResponse,
} from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { AttachmentService } from 'src/Services/AttchementTrade/attachment.service';
import { DataUtils } from 'src/Services/AttchementTrade/data-util.service';

@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.css'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: true },
    },
  ],
})
export class NewComponent implements OnInit {
  langue: any;
  direction: any;
  languef: any;
  langue_list: any[] = Langue;
  languea: any;
  languem: any;
  languet: any;
  languee: any;

  constructor(
    protected attachmentService: AttachmentService,
    protected dataUtils: DataUtils,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private userrService: UserService,
    private OrganisationService: OrganisationService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private creditLetterApi: CreditLetterService,
    private titleService: Title
  ) {
    if (localStorage.getItem('langue') != null) {
      this.langue = localStorage.getItem('langue') + '';
    } else {
      this.langue = 'AR';
    }

    if (this.langue == 'AR') {
      this.direction = 'rtl';
      this.languef = this.langue_list[0]['AR'].tablelist;
      this.languem = this.langue_list[0]['AR'].messageuser;
      this.languet = this.langue_list[0]['AR'].modal_text;
      this.languee = this.langue_list[0]['AR'].LC;
    }

    if (this.langue == 'EN') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['EN'].tablelist;
      this.languem = this.langue_list[0]['EN'].messageuser;
      this.languet = this.langue_list[0]['EN'].modal_text;
      this.languee = this.langue_list[0]['EN'].LC;
    }

    if (this.langue == 'FR') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['FR'].tablelist;
      this.languem = this.langue_list[0]['FR'].messageuser;
      this.languet = this.langue_list[0]['FR'].modal_text;
      this.languee = this.langue_list[0]['FR'].LC;
    }

    this.FormPieces = this.formBuilder.group({
      id: [],
      name: ['', Validators.required],
      file: ['', Validators.required],
      attachmentContentType: [],
      originalFilename: [],
      fileSize: [],
      lastModifiedDate: [],
      creditLetter: [],
      certificateOrigin: [],
      userId: [],
      attachmentRecentlyUploaded: [],
    });
    this.titleService.setTitle(this.languet.entete);
    this.id_credit_letter = this.route.snapshot.params['id'];
    this.id_credit_letter_copy = this.route.snapshot.params['id1'];
    if (this.id_credit_letter != undefined) {
      this.spinner.show();
      this.transactiontype = 'Update';
      this.GetCreditLetterby(this.id_credit_letter);
      this.getAttchements(this.id_credit_letter);
    } else if (this.id_credit_letter_copy != undefined) {
      this.spinner.show();
      this.GetCreditLetterby(this.id_credit_letter_copy);
      this.getAttchements(this.id_credit_letter_copy);

      this.transactiontype = 'Save';
    } else {
      this.transactiontype = 'Save';
    }
  }

  Form1 = this.formBuilder.group({
    applicationnumber: ['', Validators.required],
    selectedform: ['', Validators.required],
    beneficiaryname: ['', Validators.required],
    beneficiaryaddresse: ['', Validators.required],
    beneficiarybankswiftcode: ['',],
    beneficiarybankname: ['', Validators.required],
    beneficiarybankaccountnumber: ['', Validators.required],
    dateexpirydate: ['', Validators.required],
    messageresponse: [''],
  });
  submitted = false;
  Form2 = this.formBuilder.group({
    selectedcurrencycode: ['', Validators.required],
    selectedpriceconditioncode: ['', Validators.required],
    additionaconditions: ['', Validators.required],
    lastshippingdate: ['', Validators.required],
    amount: [''],
    rate1: [''],
    rate2: [''],
    selectedpayment: [''],
    selectedportofloading: [''],
    selectedportofdischarge: [''],
    placeoffinaldischarge: [''],
    shippingcompanyname: [''],
    selectedpartialshipment: [''],
    selectedtranshipment: [''],
    deferredpaymentperiod: [''],
  });

  Form3 = this.formBuilder.group({
    originnationname: [''],
    descriptionofgoods: [''],
    selectedasper: [''],
    number: [''],
    signaturedate: [''],
    date: [''],
    charges: [''],
    selectedconfirminstructiontype: [''],
    signatoryname: [''],
    signatoryfunction: [''],
  });
  get f(): { [key: string]: AbstractControl } {
    return this.Form1.controls;
  }
  id_Org: any;
  name_Org: any;
  selectedissuingbank: any;
  issuingbank_list: any[] = [];
  changeissuingbank(event: any) {
    console.log("event  " + JSON.stringify(event))
    this.id_Org = event.id;
    this.name_Org = event.nom;
  }

  selectedform: any;
  form_list: any[] = [
    { id: 1, name: 'IRREVOCABLE' },
    { id: 2, name: 'IRREVOCABLE TRANSFER' },
  ];
  transactiontype = 'Save';
  name_form: any;
  changeform(event: any) {
    this.name_form = event.id;
  }
  expirydate: any;
  onchangedateexpirydate(event: any) {
    this.expirydate = this.datePipe.transform(event, 'yyyy-MM-dd');
  }

  shippingdate: any;
  onchangelastshippingdate(event: any) {
    this.shippingdate = this.datePipe.transform(event, 'yyyy-MM-dd');
  }

  date_f: any;
  onchangedate(event: any) {
    this.date_f = this.datePipe.transform(event, 'yyyy-MM-dd');
  }

  signatureDate: any;
  onchangesignaturedate(event: any) {
    this.signatureDate = this.datePipe.transform(event, 'yyyy-MM-dd');
  }

  selectedcurrencycode: any;
  currencycode_list: any[] = ['USD', 'EUR', 'LYD'];
  currencycode: any;
  changedcurrencycode(event: any) {
    this.currencycode = event;
  }

  selectedpayment: any;
  payment_list: any[] = [
    'AT_SIGHT',
    'DEFERRED_PAYMENT_BL',
    'DEFERRED_PAYMENT_PD',
    'OTHER',
  ];

  paymentTerm: any;
  changepayment(event: any) {
    this.paymentTerm = event;
  }

  selectedportofloading: any;
  portofloading_list: any[] = [];

  portofcallname: any;
  portofcallid: any;
  changeportofloading(event: any) {
    this.portofcallname = event.nom;
    this.portofcallid = event.id;
  }
  portofdischargename: any;
  portofdischargeid: any;

  selectedportofdischarge: any;
  partialshipment_list: any[] = ['ALLOWED', 'PROHIBITED'];
  partialshipname: any;

  changepartialshipment(event: any) {
    this.partialshipname = event;
  }

  selectedtranshipment: any;
  transhipment_list: any[] = ['ALLOWED', 'PROHIBITED'];
  transhipmentname: any;

  changetranshipment(event: any) {
    this.transhipmentname = event;
  }

  selectedpartialshipment: any;
  portofdischarge_list: any[] = [];
  changeportofdischarge(event: any) {
    this.portofdischargename = event.nom;
    this.portofdischargeid = event.id;
  }

  selectedasper: any;
  asper_list: any[] = ['ProFormaInvoice', 'PurchaseOrder', 'Contract'];

  Asper_name: any;
  changeasper(event: any) {
    this.Asper_name = event;
  }

  selectedconfirminstructiontype: any;
  confirminstructiontype_list: any[] = ['CONFIRM', 'WITHOUT', 'MAY_ADD'];

  selectedpriceconditioncode: any;
  priceconditioncode_list: any[] = [
    { id: 'EXW', name: 'EXW (Ex Works)' },
    { id: 'FCA', name: 'FCA (Free Carrier)' },
    { id: 'FOB', name: 'FOB (Free On Board)' },
    { id: 'FAS', name: 'FAS (Free Alongside Ship)' },
    { id: 'CFR', name: 'CFR (Cost and Freight)' },
    { id: 'CIF', name: 'CIF (cost, insurance and freight)' },
    { id: 'CPT', name: 'CPT (Carriage Paid To)' },
    { id: 'CIP', name: 'CIP (Carriage and Insurance Paid To)' },
    { id: 'DAF', name: 'DAF (Delivered At Frontier)' },
    { id: 'DES', name: 'DES (Delivered Ex Ship)' },
    { id: 'DEQ', name: 'DEQ (Delivered Ex Quay)' },
    { id: 'DDU', name: 'DDU (Delivered Duty Unpaid)' },
    { id: 'DDP', name: 'DDP (Delivered Duty Paid)' },
  ];

  priceconditionname: any;

  changepriceconditioncode(event: any) {
    this.priceconditionname = event.id;
  }

  instructionTypename: any;
  changeconfirminstructiontype(event: any) {
    this.instructionTypename = event.name;
  }
  Valuedorigin: Country = {
    name: '',
    alpha2Code: '',
    alpha3Code: '',
    numericCode: '',
    callingCode: '',
  };
  countryname: any;
  onCountrySelected(event: any) {
    this.countryname = event.name;
  }

  open(content: any) {
    this.FormPieces.reset();
    this.isRecentFiles = false;
    this.isLoadComputer = false;
    this.modalService.open(content);
  }
  dimmis() {
    this.modalService.dismissAll();
  }

  Pieces_DATA: any[] = [];
  FormPieces: FormGroup;
  displayedColumnsPieces: string[] = ['name', 'file', 'action'];
  dataSourcePieces: MatTableDataSource<any>;

  attachmentsDeleted: any[] = [];
  attachmentsRecentlyUploaded: any[] = [];
  attachmentsRecentlyUploadedByName: any[] = [];
  attachmentsUpdateDeleted: any[] = [];
  uploadedFiles?: File[] = [];
  currentFile?: File;
  msg = '';
  errorMsg = '';
  onChangefile(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.currentFile = file;
    }
  }

  file_add2() {
    this.dimmis();
    const attachment = this.createFromFormAttachment();
    this.Pieces_DATA.push(attachment);
    this.uploadedFiles?.push(this.currentFile!);
    setTimeout(() => {
      this.dataSourcePieces = new MatTableDataSource(this.Pieces_DATA);
    }, 300);

    this.FormPieces.reset();
  }

  file_add() {
    this.dimmis();
    this.errorMsg = '';
    if (this.currentFile) {
      const file: File | null = this.currentFile;

      // eslint-disable-next-line no-console

      if (file) {
        this.currentFile = file;
        const attachment = this.createFromFormAttachment();
        const attachmentFile = this.createFromFormAttachmentFile();
        this.attachmentService.verifyFile(this.creditLetterApi.Spring_serveur, attachmentFile).subscribe({
          next: (response: HttpResponse<any>) => {
            // eslint-disable-next-line no-console

            this.msg = response.body.responseMessage ?? '';
            // eslint-disable-next-line
            if (!this.Pieces_DATA.includes(attachment)) {
              // eslint-disable-next-line
              this.Pieces_DATA?.push(attachment);
            }
            if (!this.uploadedFiles?.map((u) => u.name).includes(file.name)) {
              // eslint-disable-next-line
              this.uploadedFiles?.push(file);
            }
            alert(this.msg);

            //addAtta

            //  this.deletedFiles?.push(attachment);

            //addAtta
          },
          error: (err: HttpErrorResponse) => {
            // eslint-disable-next-line no-console
            console.log(err);
            // eslint-disable-next-line
            if (err.error && err.error.detail) {
              this.errorMsg = err.error.detail;
            } else {
              this.errorMsg = 'Error occurred while uploading a file!';
            }
            alert(this.errorMsg);
            this.currentFile = undefined;
            // eslint-disable-next-line no-console
          },
        });
      }
      this.currentFile = undefined;
      // eslint-disable-next-line no-console
    } else {
      const attachment = this.FormPieces.get([
        'attachmentRecentlyUploaded',
      ])!.value;
      attachment.name = this.FormPieces.get(['name'])!.value;
      alert('Attachment added successfully');
      // eslint-disable-next-line
      if (!this.Pieces_DATA.includes(attachment)) {
        // eslint-disable-next-line
        this.Pieces_DATA?.push(attachment);
      }
      //obl
      this.loadAllAttachmentByUser(this.UserId!);

      //this.isSavingA = true;
      //addAtta

      //  this.deletedFiles?.push(attachment);
    }
    setTimeout(() => {
      this.dataSourcePieces = new MatTableDataSource(this.Pieces_DATA);
    }, 300);
    this.FormPieces.reset();

    // const el: HTMLElement = this.myDiv!.nativeElement;
    // el.click();
  }
  loadAllAttachmentByUser(id: number): void {
    this.attachmentService.findByUser(this.UserId).subscribe({
      next: (res: HttpResponse<any[]>) => {
        this.attachmentsRecentlyUploaded = (res.body ?? []).filter(
          (a) =>
            a.creditLetter == null &&
            this.Pieces_DATA.map((b) => b.id).indexOf(a.id) === -1
        );
        //.filter(e => e.creditLetter?.id === this.creditLetter?.id)
        // this.attachments = this.creditLetter?.attachments ?? [];
        // eslint-disable-next-line no-console
      },
      error: (error: HttpErrorResponse) => {
        //alert(error.message);
      },
    });
  }
  loadAllAttachmentByOriginFilename(name: string): void {
    if (name !== '') {
      this.attachmentService.findByOriginFilename(name).subscribe({
        next: (res: HttpResponse<any[]>) => {
          this.attachmentsRecentlyUploadedByName = (res.body ?? []).filter(
            (a) =>
              a.creditLetter == null &&
              this.Pieces_DATA.map((b) => b.id).indexOf(a.id) === -1
          );
          //.filter(e => e.creditLetter?.id === this.creditLetter?.id)
          // this.attachments = this.creditLetter?.attachments ?? [];
          // eslint-disable-next-line no-console
          this.loadRecentFilesByName();
        },
        error: (error: HttpErrorResponse) => {
          //alert(error.message);
        },
      });
    }
  }
  isNavbarCollapsed = true;
  collapseNavbar(): void {
    this.isNavbarCollapsed = true;
    //this.editForm3.reset();
  }
  isRecentFiles = false;
  isRecentFilesByName = false;
  isLoadComputer = false;
  loadFromComputer(): void {
    this.isRecentFiles = false;
    this.isRecentFilesByName = false;
    this.isLoadComputer = true;
    this.FormPieces.patchValue({ attachmentRecentlyUploaded: null });
    // const el: HTMLElement = this.computer!.nativeElement;
    // el.click();
  }
  loadRecentFiles(): void {
    this.isLoadComputer = false;
    this.isRecentFilesByName = false;
    this.isRecentFiles = true;

    // const el: HTMLElement = this.recentFiles!.nativeElement;
    // el.click();
  }
  loadRecentFilesByName(): void {
    this.isLoadComputer = false;
    this.isRecentFiles = false;
    this.isRecentFilesByName = true;
    //this.loadAllAttachmentByOriginFilename(this.editForm3.get('originalFilename')!.value)
    // const el: HTMLElement = this.recentFilesByName!.nativeElement;
    // el.click();
  }
  // selectedattachment: any;
  recentFileUpdateForm(attachmentRecentlyUploaded: any): void {
    this.currentFile = undefined;
    if (this.FormPieces.get(['name'])!.value) {
      attachmentRecentlyUploaded.name = this.FormPieces.get(['name'])!.value;
    }

    this.updateFormAttachment(attachmentRecentlyUploaded);
    // eslint-disable-next-line
    this.FormPieces.patchValue({
      attachmentRecentlyUploaded: attachmentRecentlyUploaded,
    });
  }

  oldAttachment: any;
  isUpdatingAttachment = false;
  updateFileMode(attachment: any) {
    this.oldAttachment = attachment;
    this.updateFormAttachment(attachment);
    this.isUpdatingAttachment = true;
  }

  file_update2() {
    const attachment = this.createFromFormAttachment();
    this.Pieces_DATA.splice(this.Pieces_DATA.indexOf(this.oldAttachment!), 1);
    this.Pieces_DATA.push(attachment);
    if (!this.uploadedFiles?.includes(this.currentFile!)) {
      this.uploadedFiles?.push(this.currentFile!);
    }
    if (!this.attachmentsUpdateDeleted.includes(this.oldAttachment!)) {
      this.attachmentsUpdateDeleted.push(this.oldAttachment!);
    }

    setTimeout(() => {
      this.dataSourcePieces = new MatTableDataSource(this.Pieces_DATA);
    }, 300);

    this.FormPieces.reset();
    this.oldAttachment = undefined;
    this.isUpdatingAttachment = false;
  }
  file_update(): void {
    this.dimmis();
    // this.isSavingA = false;
    this.errorMsg = '';
    const oldAttachment = this.oldAttachment;
    if (this.currentFile) {
      const file: File | null = this.currentFile;

      // eslint-disable-next-line no-console

      if (file) {
        this.currentFile = file;
        const attachment = this.createFromFormAttachment();
        const attachmentFile = this.createFromFormAttachmentFile();
        this.attachmentService.verifyFile(this.creditLetterApi.Spring_serveur, attachmentFile).subscribe({
          next: (response: HttpResponse<any>) => {
            // eslint-disable-next-line no-console
            this.msg = response.body.responseMessage ?? '';
            alert(this.msg);
            //this.addAlert();
            // eslint-disable-next-line
            this.Pieces_DATA?.splice(
              this.Pieces_DATA.indexOf(oldAttachment!),
              1
            );
            attachment.certificateOrigin = null;
            // eslint-disable-next-line
            this.Pieces_DATA.push(attachment);

            // eslint-disable-next-line
            // this.attachmentService.deleteFile(oldAttachment!.attachment ?? "").subscribe();
            //this.deletedFiles?.push(attachment);
            if (!this.uploadedFiles?.includes(file)) {
              this.uploadedFiles?.push(file);
            }
            if (!this.attachmentsUpdateDeleted.includes(oldAttachment!)) {
              this.attachmentsUpdateDeleted.push(oldAttachment!);
            }
            // eslint-disable-next-line no-console
            //this.isSavingA = true;

            //updateAtt
            //this.attachmentsUpdateDeleted.map(a => a.id)

            //updateAtt
          },
          error: (err: HttpErrorResponse) => {
            // eslint-disable-next-line no-console
            console.log(err);
            // eslint-disable-next-line
            if (err.error && err.error.detail) {
              this.errorMsg = err.error.detail;
            } else {
              this.errorMsg = 'Error occurred while uploading a file!';
            }
            // this.addErrorAlert();
            alert(this.errorMsg);

            // eslint-disable-next-line no-console
          },
        });
      }
      this.currentFile = undefined;
      // const el: HTMLElement = this.myDiv!.nativeElement;
      // el.click();
    } else if (this.isRecentFiles || this.isRecentFilesByName) {
      const attachment = this.FormPieces.get([
        'attachmentRecentlyUploaded',
      ])!.value;
      attachment.name = this.FormPieces.get(['name'])!.value;
      alert('Attachment updated successfully');
      //this.addAlert();
      // eslint-disable-next-line
      this.Pieces_DATA?.splice(this.Pieces_DATA.indexOf(oldAttachment!), 1);
      // eslint-disable-next-line
      if (!this.Pieces_DATA.includes(attachment)) {
        // eslint-disable-next-line
        this.Pieces_DATA?.push(attachment);
      }
      this.attachmentsDeleted.push(oldAttachment!);
      this.loadAllAttachmentByUser(this.UserId);
      //  this.isSavingA = true;
      //addAtta

      //  this.deletedFiles?.push(attachment);
    } else {
      // eslint-disable-next-line
      this.Pieces_DATA?.splice(this.Pieces_DATA.indexOf(oldAttachment!), 1);
      oldAttachment!.name = this.FormPieces.get(['name'])!.value;
      alert('Attachment updated successfully');
      // eslint-disable-next-line
      this.Pieces_DATA.push(oldAttachment!);
      //updateAtt
      //this.attachmentsUpdateDeleted.map(a => a.id)
      // this.isSavingA = true;
    }
    setTimeout(() => {
      this.dataSourcePieces = new MatTableDataSource(this.Pieces_DATA);
    }, 300);

    this.oldAttachment = undefined;
    this.isUpdatingAttachment = false;

    this.FormPieces.reset();
    // const el: HTMLElement = this.myDiv!.nativeElement;
    // el.click();
    // this.isAddingAttachment = true;
    // alert('Attachment Updated');
    // this.addAlert();
    //updateAtt
    // eslint-disable-next-line no-console
  }
  openAttachment(url: string): void {
    // eslint-disable-next-line
    // window.open("../../content/attachments/"+ a,'_blank');
    window.open(url, '_blank');
  }

  upload(file: File): void {
    this.errorMsg = '';

    this.currentFile = file;
    //this.account!.id
    this.attachmentService.upload(this.creditLetterApi.Spring_serveur, this.currentFile, this.UserId).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          // eslint-disable-next-line no-console
        } else if (event instanceof HttpResponse) {
          this.msg = event.body.responseMessage;
          // this.addAlert();
        }
        // this.isSavingA = true;
      },
      (err: any) => {
        // eslint-disable-next-line no-console
        // eslint-disable-next-line
        if (err.error && err.error.detail) {
          this.errorMsg = err.error.detail;
        } else {
          this.errorMsg = 'Error occurred while uploading a file!';
        }
        //this.addErrorAlert();
        // this.currentFile = undefined;
        // eslint-disable-next-line no-console
      }
    );
    this.currentFile = undefined;
  }
  getattachmentsUpdateDeletedIds(): any[] {
    return this.attachmentsUpdateDeleted.map((a) => a.id);
  }
  deleteAttachment(attachment: any): void {
    // eslint-disable-next-line
    this.Pieces_DATA?.splice(this.Pieces_DATA.indexOf(attachment), 1);
    // if (this.attachments.length === 0) {
    //   this.isAddingAttachment = false;
    // }
    this.loadAllAttachmentByUser(this.UserId);
    // eslint-disable-next-line no-console
    setTimeout(() => {
      this.dataSourcePieces = new MatTableDataSource(this.Pieces_DATA);
    }, 300);
    //this.delete(attachment);
    this.attachmentsDeleted.push(attachment);
  }

  protected updateFormAttachment(attachment: any): void {
    this.FormPieces.patchValue({
      id: attachment.id,
      name: attachment.name,
      originalFilename: attachment.originalFilename,
      fileSize: attachment.fileSize,
      lastModifiedDate: attachment.lastModifiedDate,
      attachmentContentType: attachment.attachmentContentType,
      file: attachment.attachment,
      userId: attachment.userId,
      creditLetter: attachment.creditLetter,
      certificateOrigin: attachment.certificateOrigin,
    });
  }
  protected createFromFormAttachment(): any {
    return {
      id: this.FormPieces.controls['id'].value,
      name: this.FormPieces.controls['name'].value,
      originalFilename: this.currentFile?.name,
      attachment: this.FormPieces.controls['file'].value, //this.currentFile
      attachmentContentType: this.currentFile?.type,
      fileSize: this.currentFile?.size,
      lastModifiedDate: null,
      userId: this.UserId,
      creditLetter: this.FormPieces.get(['creditLetter'])!.value,
      certificateOrigin: this.FormPieces.get(['certificateOrigin'])!.value,
    };
  }

  protected createFromFormAttachmentFile(): any {
    return {
      originalFilename: this.currentFile?.name,
      fileSize: this.currentFile?.size,
      userId: this.UserId,
    };
  }

  ngOnInit(): void {
    this.Getorganisation();
    this.GetIdUser();
    // this.loadAllAttachmentByUser(33);
  }
  UserId: any;
  GetIdUser() {
    this.userrService.Getuserbyusername().subscribe((res: any) => {
      this.UserId = res.id;
      this.Getorganisation_send();
      this.loadAllAttachmentByUser(this.UserId);
    });
  }

  Submit(content: any) {
    if (this.transactiontype == 'Update') {
      this.document_credit = this.CreateFormLCUpdate();
    } else {
      this.document_credit = this.CreateFormLC();
    }

    this.document_credit.attachments?.forEach((element: any) => {
      if (element.attachment != null) {
        element.attachment =
          this.attachmentService.uploadPathFront +
          'USER' +
          this.UserId +
          '_' +
          element.originalFilename;
      }
    });

    this.creditLetterApi.submit(this.document_credit).subscribe((res: any) => {
      this.open(content);

      setTimeout(() => {
        this.Go_list();
      }, 400);
    });

    this.attachmentsDeleted.forEach((element) => {
      // eslint-disable-next-line
      if (element?.id != null && element.attachment != null) {
        // if (element.certificateOrigin == null) {
        //   // eslint-disable-next-line
        //   this.attachmentService.delete(element?.id).subscribe();
        //   // eslint-disable-next-line
        //   this.attachmentService.deleteFile(element!.attachment).subscribe();
        // } else {
        element.creditLetter = null;
        //element.lastModifiedDate=dayjs(element.lastModifiedDate);
        this.attachmentService.update(element).subscribe();
        // }
      }
    });
    // eslint-disable-next-line no-console
    this.attachmentsUpdateDeleted.forEach((element) => {
      // eslint-disable-next-line
      if (element.attachment != null) {
        if (element.certificateOrigin == null) {
          // eslint-disable-next-line
          this.attachmentService.deleteFile(element!.attachment).subscribe();
        } else {
          // const a1 = this.attachments.filter(a=> a.id===element.id)[0];
          // a1.creditLetter=null;
          // this.attachmentService.update(a1).subscribe();
          element.creditLetter = null;
          element.id = undefined;
          this.attachmentService.create(element).subscribe();
        }
      }
    });
    this.uploadedFiles!.forEach((element) => {
      // eslint-disable-next-line
      if (element != null) {
        this.upload(element);
      }
    });
  }

  Save(content: any) {
    if (this.transactiontype == 'Update') {
      this.document_credit = this.CreateFormLCUpdate();
      this.document_credit.attachments?.forEach((element: any) => {
        if (element.attachment != null) {
          element.attachment =
            this.attachmentService.uploadPathFront +
            'USER' +
            this.UserId +
            '_' +
            element.originalFilename;
        }
      });

      this.creditLetterApi
        .update(this.document_credit, this.id_credit_letter)
        .subscribe((res: any) => {
          this.open(content);
        });
    } else {
      this.document_credit = this.CreateFormLC();
      this.document_credit.attachments?.forEach((element: any) => {
        if (element.attachment != null) {
          element.attachment =
            this.attachmentService.uploadPathFront +
            'USER' +
            this.UserId +
            '_' +
            element.originalFilename;
        }
      });
      
      this.creditLetterApi
        .post(this.document_credit, this.id_Org)
        .subscribe((res: any) => {
          this.open(content);
        });
    }

    this.attachmentsDeleted.forEach((element) => {
      // eslint-disable-next-line
      if (element?.id != null && element.attachment != null) {
        // if (element.certificateOrigin == null) {
        //   // eslint-disable-next-line
        //   this.attachmentService.delete(element?.id).subscribe();
        //   // eslint-disable-next-line
        //   this.attachmentService.deleteFile(element!.attachment).subscribe();
        // } else {
        element.creditLetter = null;
        //element.lastModifiedDate=dayjs(element.lastModifiedDate);
        this.attachmentService.update(element).subscribe();
        // }
      }
    });
    // eslint-disable-next-line no-console

    this.attachmentsUpdateDeleted.forEach((element) => {
      // eslint-disable-next-line
      if (element.attachment != null) {
        if (element.certificateOrigin == null) {
          // eslint-disable-next-line
          this.attachmentService.deleteFile(element!.attachment).subscribe();
        } else {
          // const a1 = this.attachments.filter(a=> a.id===element.id)[0];
          // a1.creditLetter=null;
          // this.attachmentService.update(a1).subscribe();
          element.creditLetter = null;
          element.id = undefined;
          this.attachmentService.create(element).subscribe();
        }
      }
    });
    this.uploadedFiles!.forEach((element) => {
      // eslint-disable-next-line
      if (element != null) {
        this.upload(element);
      }
    });
  }
  openFile(base64String: string, contentType: string | null | undefined): void {
    this.dataUtils.openFile(base64String, contentType);
  }

  setFileData(event: Event, field: string, isImage: boolean): void {
    this.dataUtils
      .loadFileToForm(event, this.FormPieces, field, isImage)
      .subscribe();
  }

  onFileChange(event: any) {
    this.spinner.show();
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>event.target;
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      if (ws['H5'] != undefined) {
        this.Form1.controls['applicationnumber'].setValue(ws['H5']['v']);
      }
      if (ws['H2'] != undefined) {
        this.selectedform = ws['H2']['v'];
        this.name_form = ws['H2']['v'];
      }
      if (ws['M1'] != undefined) {
        this.Form1.controls['beneficiaryname'].setValue(ws['M1']['v']);
      }
      if (ws['M2'] != undefined) {
        this.Form1.controls['beneficiarybankswiftcode'].setValue(ws['M2']['v']);
      }

      if (ws['M3'] != undefined) {
        this.Form1.controls['beneficiarybankname'].setValue(ws['M3']['v']);
      }

      if (ws['M4'] != undefined) {
        this.Form1.controls['beneficiaryaddresse'].setValue(ws['M4']['v']);
      }

      if (ws['M5'] != undefined) {
        this.Form1.controls['beneficiarybankaccountnumber'].setValue(
          ws['M5']['v']
        );
      }
      if (ws['H3'] != undefined) {
        this.Form1.controls['dateexpirydate'].patchValue(
          this.formatDate(new Date(ws['H3']['w']))
        );

        this.expirydate = ws['H3']['w'];
      }

      if (ws['D9'] != undefined) {
        this.selectedcurrencycode = ws['D9']['v'];
        this.currencycode = ws['D9']['v'];
      }

      if (ws['D10'] != undefined) {
        this.Form2.controls['amount'].setValue(ws['D10']['v']);
      }

      if (ws['D11'] != undefined) {
        this.paymentTerm = ws['D11']['v'];
        this.selectedpayment = ws['D11']['v'];
      }

      if (ws['D12'] != undefined) {
        this.Form2.controls['deferredpaymentperiod'].setValue(ws['D12']['v']);
      }

      if (ws['D15'] != undefined) {
        this.partialshipname = ws['D15']['v'];
        this.selectedpartialshipment = ws['D15']['v'];
      }

      if (ws['D16'] != undefined) {
        this.portofcallname = ws['D16']['v'];
        this.selectedportofloading = ws['D16']['v'];
      }

      if (ws['D17'] != undefined) {
        this.portofdischargename = ws['D17']['v'];
        this.selectedportofdischarge = ws['D17']['v'];
      }

      if (ws['D14'] != undefined) {
        this.transhipmentname = ws['D14']['v'];
        this.selectedtranshipment = ws['D14']['v'];
      }

      if (ws['F17'] != undefined) {
        this.Form2.controls['placeoffinaldischarge'].setValue(ws['F17']['v']);
      }

      if (ws['F14'] != undefined) {
        this.Form2.controls['shippingcompanyname'].setValue(ws['F14']['v']);
      }

      if (ws['E21'] != undefined) {
        this.Form2.controls['additionaconditions'].setValue(ws['E21']['v']);
      }

      if (ws['M9'] != undefined) {
        this.priceconditionname = ws['M9']['v'];
        this.selectedpriceconditioncode = ws['M9']['v'];
      }

      if (ws['F15'] != undefined) {
        this.Form2.controls['lastshippingdate'].patchValue(
          this.formatDate(new Date(ws['F15']['v']))
        );
        this.shippingdate = ws['F15']['v'];
      }

      if (ws['F16']['v'] != undefined) {
        this.Valuedorigin = {
          name: '',
          alpha2Code: ws['F16']['v'],
          alpha3Code: '',
          numericCode: '',
          callingCode: '',
        };

        this.countryname = ws['F16']['v'];
        this.countryname = ws['F16']['v'];
      }

      if (ws['J15'] != undefined) {
        this.Asper_name = ws['J15']['v'];
        this.selectedasper = ws['J15']['v'];
      }

      if (ws['J16'] != undefined) {
        this.Form3.controls['number'].setValue(ws['J16']['v']);
      }

      if (ws['J17'] != undefined) {
        this.Form3.controls['date'].patchValue(
          this.formatDate(new Date(ws['J17']['v']))
        );
        this.date_f = ws['J17']['v'];
      }

      if (ws['M11'] != undefined) {
        this.Form3.controls['charges'].setValue(ws['M11']['v']);
      }

      if (ws['D21'] != undefined) {
        this.Form3.controls['descriptionofgoods'].setValue(ws['D21']['v']);
      }

      if (ws['G21'] != undefined) {
        this.instructionTypename = ws['G21']['v'];
        this.selectedconfirminstructiontype = ws['G21']['v'];
      }
      if (ws['M15'] != undefined) {
        this.Form3.controls['signatoryname'].setValue(ws['M15']['v']);
      }
      if (ws['M17'] != undefined) {
        this.Form3.controls['signatoryfunction'].setValue(ws['M17']['v']);
      }
      if (ws['M19'] != undefined) {
        this.Form3.controls['signaturedate'].patchValue(
          this.formatDate(new Date(ws['M19']['v']))
        );
        this.signatureDate = ws['M19']['v'];
      }
    };

    setTimeout(() => {
      this.spinner.hide();
    }, 300);
  }

  Go_list() {
    this.router.navigateByUrl('Trade/liste');
    this.dimmis();
  }

  list_organsation: any[] = [];
  bank_list: any[] = [];
  id_Org_en: any;
  Getorganisation() {
    this.OrganisationService.GetAll().subscribe((res: any) => {
      this.list_organsation = [];
      this.bank_list = [];
      this.list_organsation = res;
      this.issuingbank_list = this.list_organsation.filter(
        (e: any) => e.profil == 'Banque'
      );
    });
  }

  Getorganisation_send() {
    this.OrganisationService.Get_org_byID(this.UserId).subscribe((res: any) => {
      this.id_Org_en = res.id;
    });
  }
  CreateFormLC(): any {
    return {
      issuingBank: this.id_Org,
      applicant: this.id_Org_en,
      lcApplicationNumber: '',
      applicantAccountNumber: this.Form1.controls['applicationnumber'].value,
      lcTypeCode: this.name_form,
      expiryDate: this.expirydate,
      beneficiaryName: this.Form1.controls['beneficiaryname'].value,
      beneficiaryAddress: this.Form1.controls['beneficiaryaddresse'].value,
      beneficiaryBankSwiftCode:
        this.Form1.controls['beneficiarybankswiftcode'].value,
      beneficiaryBankName: this.Form1.controls['beneficiarybankname'].value,
      beneficiaryBankAccountNumber:
        this.Form1.controls['beneficiarybankaccountnumber'].value,
      currencyCode: this.currencycode,
      amount: this.Form2.controls['amount'].value,
      allowanceRateFrom: this.Form2.controls['rate1'].value,
      allowanceRateTo: this.Form2.controls['rate2'].value,
      paymentTerms: this.paymentTerm,
      deferredPaymentPeriod: this.Form2.controls['deferredpaymentperiod'].value,
      partialShipment: this.partialshipname,
      transhipment: this.transhipmentname,
      portOfLoading: this.portofcallname,
      portOfDischarge: this.portofdischargename,
      placeOfFinalDischarge: this.Form2.controls['placeoffinaldischarge'].value,
      shippingCompanyName: this.Form2.controls['shippingcompanyname'].value,
      lastShippingDate: this.shippingdate,
      descriptionOfGoods: this.Form3.controls['descriptionofgoods'].value,
      originNationName: this.countryname,
      asPer: this.Asper_name,
      asPerNumber: this.Form3.controls['number'].value,
      asPerDate: this.date_f,
      priceConditionCode: this.priceconditionname,
      additionalConditions: this.Form2.controls['additionaconditions'].value,
      charges: this.Form3.controls['charges'].value,
      confirmInstructionType: this.instructionTypename,
      signatoryName: this.Form3.controls['signatoryname'].value,
      signatoryFunction: this.Form3.controls['signatoryfunction'].value,
      signatureDate: this.signatureDate,
      attachments: this.Pieces_DATA,
    };
  }
  CreateFormLCUpdate(): any {
    return {
      approvalStatus: this.approvalStatus,
      id: this.id_credit_letter,
      issuingBank: this.id_Org,
      applicant: this.applicant,
      lcApplicationNumber: '',
      applicantAccountNumber: this.Form1.controls['applicationnumber'].value,
      lcTypeCode: this.name_form,
      expiryDate: this.expirydate,
      beneficiaryName: this.Form1.controls['beneficiaryname'].value,
      beneficiaryAddress: this.Form1.controls['beneficiaryaddresse'].value,
      beneficiaryBankSwiftCode:
        this.Form1.controls['beneficiarybankswiftcode'].value,
      beneficiaryBankName: this.Form1.controls['beneficiarybankname'].value,
      beneficiaryBankAccountNumber:
        this.Form1.controls['beneficiarybankaccountnumber'].value,
      currencyCode: this.currencycode,
      amount: this.Form2.controls['amount'].value,
      allowanceRateFrom: this.Form2.controls['rate1'].value,
      messages: this.messagesuser,
      allowanceRateTo: this.Form2.controls['rate2'].value,
      paymentTerms: this.paymentTerm,
      deferredPaymentPeriod: this.Form2.controls['deferredpaymentperiod'].value,
      partialShipment: this.partialshipname,
      transhipment: this.transhipmentname,
      portOfLoading: this.portofcallname,
      portOfDischarge: this.portofdischargename,
      placeOfFinalDischarge: this.Form2.controls['placeoffinaldischarge'].value,
      shippingCompanyName: this.Form2.controls['shippingcompanyname'].value,
      lastShippingDate: this.shippingdate,
      descriptionOfGoods: this.Form3.controls['descriptionofgoods'].value,
      originNationName: this.countryname,
      asPer: this.Asper_name,
      asPerNumber: this.Form3.controls['number'].value,
      asPerDate: this.date_f,
      moreInformationResponse: this.Form1.controls['messageresponse'].value,
      priceConditionCode: this.priceconditionname,
      additionalConditions: this.Form2.controls['additionaconditions'].value,
      charges: this.Form3.controls['charges'].value,
      confirmInstructionType: this.instructionTypename,
      signatoryName: this.Form3.controls['signatoryname'].value,
      signatoryFunction: this.Form3.controls['signatoryfunction'].value,
      signatureDate: this.signatureDate,
      attachments: this.Pieces_DATA,
      status: this.status,
    };
  }
  ngselectinvalid: any = '';
  formSubmitted: boolean = false;
  formSubmitted1: boolean = false;
  formSubmitted2: boolean = false;
  next(): void {
    this.ngselectinvalid = this.languet.required;
    this.formSubmitted = true;
    if (this.Form1.valid) {
      return;
    }
  }
  next1(): void {
    this.ngselectinvalid = this.languet.required;
    this.formSubmitted1 = true;
    if (this.Form2.valid) {
      return;
    }
  }
  next2(): void {
    this.ngselectinvalid = this.languet.required;
    this.formSubmitted2 = true;
    if (this.Form3.valid) {
      return;
    }
  }
  id_credit_letter: any;
  id_credit_letter_copy: any;
  document_credit: any;
  GetCreditLetterby(id: any) {
    this.creditLetterApi.GetByid(id).subscribe((res: any) => {
      this.document_credit = res;
      this.remplirForm();
    });
  }

  status_request: boolean = false;
  messagesuser: any[];
  approvalStatus: any;
  moreInformationRequest: any;
  applicant: any;

  status: any;
  remplirForm() {
    this.messagesuser = this.document_credit.messages;
    if (this.document_credit.approvalStatus == 'INFORMATION_REQUEST') {
      this.status_request = true;
    }

    this.status = this.document_credit.status;
    this.name_Org = this.document_credit.messages[0].organizationReceiver.name;
    this.selectedissuingbank =
      this.document_credit.messages[0].organizationReceiver.name;
    this.id_Org = this.document_credit.messages[0].organizationReceiver;

    this.applicant = this.document_credit.applicant;
    this.approvalStatus = this.document_credit.approvalStatus;
    this.moreInformationRequest = this.document_credit.moreInformationRequest;

    this.id_Org = this.document_credit.issuingBank;
    this.Form1.controls['applicationnumber'].setValue(
      this.document_credit.applicantAccountNumber
    );

    this.selectedform = this.document_credit.lcTypeCode;
    this.name_form = this.document_credit.lcTypeCode;

    this.Form1.controls['beneficiaryname'].setValue(
      this.document_credit.beneficiaryName
    );

    this.Form1.controls['beneficiarybankswiftcode'].setValue(
      this.document_credit.beneficiaryBankSwiftCode
    );

    this.Form1.controls['beneficiarybankname'].setValue(
      this.document_credit.beneficiaryBankName
    );

    this.Form1.controls['beneficiaryaddresse'].setValue(
      this.document_credit.beneficiaryAddress
    );

    this.Form1.controls['beneficiarybankaccountnumber'].setValue(
      this.document_credit.beneficiaryBankAccountNumber
    );
    this.Form1.controls['dateexpirydate'].patchValue(
      this.formatDate(new Date(this.document_credit.expiryDate))
    );
    this.expirydate = this.document_credit.expiryDate;

    this.selectedcurrencycode = this.document_credit.currencyCode;
    this.currencycode = this.document_credit.currencyCode;

    this.Form2.controls['amount'].setValue(this.document_credit.amount);

    this.paymentTerm = this.document_credit.paymentTerms;
    this.selectedpayment = this.document_credit.paymentTerms;

    this.Form2.controls['deferredpaymentperiod'].setValue(
      this.document_credit.deferredPaymentPeriod
    );

    this.partialshipname = this.document_credit.partialShipment;
    this.selectedpartialshipment = this.document_credit.partialShipment;

    this.portofcallname = this.document_credit.portOfLoading;
    this.selectedportofloading = this.document_credit.portOfLoading;

    this.portofdischargename = this.document_credit.portOfDischarge;
    this.selectedportofdischarge = this.document_credit.portOfDischarge;

    this.transhipmentname = this.document_credit.transhipment;
    this.selectedtranshipment = this.document_credit.transhipment;

    this.Form2.controls['placeoffinaldischarge'].setValue(
      this.document_credit.placeOfFinalDischarge
    );

    this.Form2.controls['shippingcompanyname'].setValue(
      this.document_credit.shippingCompanyName
    );

    this.Form2.controls['additionaconditions'].setValue(
      this.document_credit.additionalConditions
    );

    this.priceconditionname = this.document_credit.priceConditionCode;
    this.selectedpriceconditioncode = this.document_credit.priceConditionCode;

    this.Form2.controls['lastshippingdate'].patchValue(
      this.formatDate(new Date(this.document_credit.lastShippingDate))
    );
    this.shippingdate = this.document_credit.lastShippingDate;

    this.Valuedorigin = {
      name: '',
      alpha2Code: this.document_credit.originNationName,
      alpha3Code: '',
      numericCode: '',
      callingCode: '',
    };

    this.countryname = this.document_credit.originNationName;
    this.countryname = this.document_credit.originNationName;

    this.Asper_name = this.document_credit.asPer;
    this.selectedasper = this.document_credit.asPer;

    this.Form3.controls['number'].setValue(this.document_credit.asPerNumber);

    this.Form3.controls['date'].patchValue(
      this.formatDate(new Date(this.document_credit.asPerDate))
    );
    this.date_f = this.document_credit.asPerDate;

    this.Form3.controls['charges'].setValue(this.document_credit.charges);

    this.Form3.controls['descriptionofgoods'].setValue(
      this.document_credit.descriptionOfGoods
    );

    this.instructionTypename = this.document_credit.confirmInstructionType;
    this.selectedconfirminstructiontype =
      this.document_credit.confirmInstructionType;

    this.Form3.controls['signatoryname'].setValue(
      this.document_credit.signatoryName
    );

    this.Form3.controls['signatoryfunction'].setValue(
      this.document_credit.signatoryFunction
    );

    this.Form2.controls['rate1'].setValue(
      this.document_credit.allowanceRateFrom
    );
    this.Form2.controls['rate2'].setValue(this.document_credit.allowanceRateTo);

    this.Form3.controls['signaturedate'].patchValue(
      this.formatDate(new Date(this.document_credit.signatureDate))
    );

    this.signatureDate = this.document_credit.signatureDate;
    setTimeout(() => {
      this.spinner.hide();
    }, 300);
  }

  formatDate(date: any) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  getAttchements(id: any) {
    this.attachmentService.findByLC(id).subscribe((res: any) => {
      this.Pieces_DATA = res.body;

      setTimeout(() => {
        this.dataSourcePieces = new MatTableDataSource(this.Pieces_DATA);
      }, 300);
    });
  }

  file_source: any;
  type_source: any;
  viewPDF(content: any, file: any) {
    this.file_source = file.attachment;

    if (file.attachmentContentType.indexOf('image') != -1) {
      this.type_source = 'image';
    } else if (file.attachmentContentType.indexOf('pdf') != -1) {
      this.type_source = 'pdf';
    } else {
      this.type_source = 'no';
    }

    if (this.type_source != 'no') {
      setTimeout(() => {
        this.modalService.open(content, {
          size: 'xl',
          windowClass: 'dark-modal',
        });
      }, 400);
    }
  }
}
