import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx';
import Langue from './../../../../../assets/langue.json';
import {
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Country } from '@angular-material-extensions/select-country';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { CustomsService } from '../Services/Customs/customs.service';
import { OrganisationService } from 'src/Services/Organisation/organisation.service';
import { AttchementService } from 'src/Services/Attchement/attchement.service';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.css'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: true },
    },
  ],
})
export class NewComponent implements OnInit {
  dateofdeclaration = new FormControl();
  dateofsignature = new FormControl();
  @ViewChild('fileInput') fileInput: ElementRef;
  fileAttr = 'Choose File';

  Article_DATA: any[] = [];

  displayedColumns: string[] = [
    'no',
    'ndp',
    'des',
    'pfn',
    'codeqcs',
    'brute',
    'net',
    'pays',
  ];
  dataSource: MatTableDataSource<any>;

  FormArticle = this.formBuilder.group({
    ngp: [''],
    libelle: [''],
    quantitenet: [''],
    quantitebrute: [''],
    pfn: [''],
    codeqcs: [''],
  });
  IdentificationForm: FormGroup;
  TransitForm = this.formBuilder.group({
    identwarehouse: [''],
    namewarehouse: [''],
    representative: [''],
    signatureplace: [''],
    companyofdestination: [''],
    warehousecd: [''],
    warehousede: [''],
    forwarder: [''],
  });
  TransportForm = this.formBuilder.group({
    container: [''],
    mode_transport_crossing_the_border: [''],
    officeofentry: [''],
    inland_transport_mode: [''],
    locationofGoods: [''],
  });

  FinancialForm = this.formBuilder.group({
    modelofpayment: [''],
    naturetransaction: [''],
    codepayment: [''],
    bankbranch: [''],
    bank: [''],
  });
  secondFormGroup = this.formBuilder.group({
    exportercode: [''],
    exporterno: [''],
    exportername: [''],
    consigneecode: [''],
    consigneeno: [''],
    consigneename: [''],
    financialcode: [''],
    financialno: [''],
    financialname: [''],
    declarantcode: [''],
    declarantno: [''],
    declarantname: [''],
  });

  GeneralsegmentForm = this.formBuilder.group({
    invoicevalue: [''],
    invoicevaluecode: [''],
    invoicevaluerate: [''],
    invoicevalueamount: [''],

    externalfreight: [''],
    externalfreightcode: [''],
    externalfreightrate: [''],
    externalfreightamount: [''],

    internalfreight: [''],
    internalfreightcode: [''],
    internalfreightrate: [''],
    internalfreightamount: [''],

    insurancevalue: [''],
    insurancevaluecode: [''],
    insurancevaluerate: [''],
    insurancevalueamount: [''],

    othercostvalue: [''],
    othercostvaluecode: [''],
    othercostvaluerate: [''],
    othercostvalueamount: [''],

    deductionsvalue: [''],
    deductionsvaluecode: [''],
    deductionsvaluerate: [''],
    deductionsvalueamount: [''],

    totalgrossmass: [''],
    totalcosts: [''],
    deliveryterms: [''],
    cifvalue: [''],

    guaranteeamount: [''],
    guaranteedate: [''],
    totalfees: [''],
    totaldeclaration: [''],
  });
  typedeclaration: any;

  Valuepexp: Country = {
    name: '',
    alpha2Code: '',
    alpha3Code: '',
    numericCode: '',
    callingCode: '',
  };
  Valuedes: Country = {
    name: '',
    alpha2Code: '',
    alpha3Code: '',
    numericCode: '',
    callingCode: '',
  };
  Valuedorigin: Country = {
    name: '',
    alpha2Code: '',
    alpha3Code: '',
    numericCode: '',
    callingCode: '',
  };

  Valuepcro: Country = {
    name: '',
    alpha2Code: '',
    alpha3Code: '',
    numericCode: '',
    callingCode: '',
  };

  onFileChange(event: any) {
    this.spinner.show();
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>event.target;
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      if (ws['K4'] != undefined) {
        this.typeofdecalaration_name = ws['K4']['v'];
        this.selectettypeofdecalaration = ws['K4']['v'];
      }
      if (ws['H5'] != undefined) {
        this.typedeclaration = ws['H5']['v'];
      }
      if (ws['K5'] != undefined) {
        this.IdentificationForm.controls['totalitems'].setValue(ws['K5']['v']);
      }
      if (ws['K6'] != undefined) {
        this.IdentificationForm.controls['numberofpackages'].setValue(
          ws['K6']['v']
        );
      }

      if (ws['H2'] != undefined) {
        this.secondFormGroup.controls['exportername'].setValue(ws['H2']['v']);
      }
      if (ws['D9'] != undefined) {
        this.secondFormGroup.controls['consigneename'].setValue(ws['D9']['v']);
      }
      if (ws['M1'] != undefined) {
        this.secondFormGroup.controls['declarantname'].setValue(ws['M1']['v']);
      }

      if (ws['D14']['v'] != undefined) {
        this.Valuedorigin = {
          name: '',
          alpha2Code: ws['D14']['v'],
          alpha3Code: '',
          numericCode: '',
          callingCode: '',
        };

        this.code_country_origin = ws['D14']['v'];
        this.name_country_origin = ws['D15']['v'];
      }

      if (ws['F14']['v'] != undefined) {
        this.Valuepexp = {
          name: '',
          alpha2Code: ws['F14']['v'],
          alpha3Code: '',
          numericCode: '',
          callingCode: '',
        };
        this.code_country = ws['F14']['v'];
        this.name_country = ws['F15']['v'];
      }

      if (ws['F16']['v'] != undefined) {
        this.Valuedes = {
          name: '',
          alpha2Code: ws['F16']['v'],
          alpha3Code: '',
          numericCode: '',
          callingCode: '',
        };
        this.code_country_destination = ws['F16']['v'];
        this.name_country_destination = ws['F17']['v'];
      }

      if (ws['Y8'] != undefined) {
        this.TransportForm.controls[
          'mode_transport_crossing_the_border'
        ].setValue(ws['Y8']['v']);
      }

      if (ws['S6'] != undefined) {
        this.TransportForm.controls['locationofGoods'].setValue(ws['S6']['v']);
      }

      if (ws['M2'] != undefined) {
        this.TransitForm.controls['representative'].setValue(ws['M2']['v']);
      }

      let z = 22;

      for (var i = 21; i < z; i++) {
        if (ws['B' + i] != undefined) {
          this.Article_DATA.push({
            no: ws['B' + i]['v'],
            pc: ws['C' + i]['v'],
            sds: ws['D' + i]['v'],
            quantite: ws['I' + i]['v'],
            prix: ws['G' + i]['v'],
            pays: ws['J' + i]['v'],
            quota: ws['H' + i]['v'],
            brute: ws['E' + i]['v'],
            net: ws['F' + i]['v'],
          });

          z = z + 1;
        }
      }

      setTimeout(() => {
        this.dataSource = new MatTableDataSource(this.Article_DATA);
        this.spinner.hide();
      }, 800);

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
    };
  }

  Id_customs_update: any;
  transactiontype: any;

  langue: any;
  direction: any;

  langue_list: any[] = Langue;
  languea: any;

  languet: any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private OrganisationService: OrganisationService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private router: Router,
    private Customsservice: CustomsService,
    private titleService: Title,
    private spinner: NgxSpinnerService,
    private attchementServices: AttchementService
  ) {
    if (localStorage.getItem('langue') != null) {
      this.langue = localStorage.getItem('langue') + '';
    } else {
      this.langue = 'AR';
    }

    if (this.langue == 'AR') {
      this.direction = 'rtl';
      this.languea = this.langue_list[0]['AR'].CD;
      this.languet = this.langue_list[0]['AR'].modal_text;
    }

    if (this.langue == 'EN') {
      this.direction = 'ltr';
      this.languea = this.langue_list[0]['EN'].CD;
      this.languet = this.langue_list[0]['EN'].modal_text;
    }

    if (this.langue == 'FR') {
      this.direction = 'ltr';
      this.languea = this.langue_list[0]['FR'].CD;
      this.languet = this.langue_list[0]['FR'].modal_text;
    }
    this.titleService.setTitle(this.languet.entete);
    this.IdentificationForm = this.formBuilder.group({
      manifest: [''],
      numberofpackages: [''],
      loadlist: [''],
      totalitems: [''],
      bl: [''],
      typeofdecalaration: [''],
      officeofdecalaration: [''],
      customs: [''],
      selectedcustom: ['', Validators.required],
    });
    this.dataSource = new MatTableDataSource(this.Article_DATA);

    this.Id_customs_update = this.route.snapshot.params['id'];

    if (this.Id_customs_update != undefined) {
      this.spinner.show();
      this.transactiontype = 'Update';
      this.getCustomsDeclarationByid();
    } else {
      this.etat = 'Save';
      this.transactiontype = 'Save';
    }
  }
  list_ports: any = [];
  customs_list: any = [];
  ngOnInit(): void {
    this.Getorganisation();
  }

  name_org: any;
  changecustom(event: any) {
    this.name_org = event.name;
    this.Id_org = event.id;
  }
  closeResult = '';
  open(content: any) {
    this.modalService
      .open(content, {
        centered: true,
        windowClass: 'modal-rounded',
        ariaLabelledBy: 'modal-basic-title',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason: any) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  Article_list: any[] = [];
  Id_org: any;
  etat: any;
  Save(content: any) {
    this.etat = 'Save';
    if (this.transactiontype == 'Save') {
      this.Article_Remplir();
      this.Customsservice.post(
        this.createFromCustomDeclaration(),
        this.Id_org
      ).subscribe((res: any) => {
        this.open(content);
      });
    } else {
      this.delete_items();
      this.Article_Remplir_update();

      this.Customsservice.post(
        this.createFromCustomDeclarationUpdate(),
        this.Id_org
      ).subscribe((res: any) => {
        this.open(content);
      });
    }
  }

  Article_Remplir() {
    this.Article_list = [];
    for (let article of this.Article_DATA) {
      this.Article_list.push({
        itemNo: article.no,
        grossMass: article.brute,
        netMass: article.net,
        quota: article.quota,
        itemPrice: article.prix,
        countryOfOrigin: article.pays,
        preferenceCode: article.pc,
        summaryDeclaration: article.sds,
      });
    }
  }

  Article_Remplir_update() {
    this.Article_list = [];
    for (let article of this.Article_DATA) {
      this.Article_list.push({
        id: article.id,
        itemNo: article.no,
        grossMass: article.brute,
        netMass: article.net,
        quota: article.quota,
        itemPrice: article.prix,
        countryOfOrigin: article.pays,
        preferenceCode: article.pc,
        summaryDeclaration: article.sds,
      });
    }
  }

  GetArticles(list: any) {
    for (let article of list) {
      this.Article_DATA.push({
        id: article.id,
        no: article.itemNo,
        brute: article.grossMass,
        net: article.netMass,
        quota: article.quota,
        prix: article.itemPrice,
        pays: article.countryOfOrigin,
        pc: article.preferenceCode,
        sds: article.summaryDeclaration,
      });
    }
  }

  protected createFromCustomDeclaration(): any {
    return {
      yearOfDeclaration: null,
      serialNo: Math.floor(Math.random() * 10000000),
      dateOfDeclaration: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      blNo: this.IdentificationForm.controls['bl'].value,
      manifest: this.IdentificationForm.controls['manifest'].value,
      numberOfPackages:
        this.IdentificationForm.controls['numberofpackages'].value,
      loadList: this.IdentificationForm.controls['loadlist'].value,
      typeOfDeclaration: this.typeofdecalaration_name,
      officeOfDeclaration: 1,
      customsReferenceDivision: Math.floor(Math.random() * 10000000),
      customsReferenceNo: this.Id_org,
      customsReferenceData: this.name_org,
      assessmentSerialNumber: Math.floor(Math.random() * 10000000),
      assessmentNumber: Math.floor(Math.random() * 100),
      assessmentDate: null,
      receiptNumberDivision: null,
      receiptNumber: null,
      receiptNumberDate: null,
      countryCode: null,
      totalItems: this.IdentificationForm.controls['totalitems'].value,
      exporterCompanyCode: 'E_' + Math.floor(Math.random() * 10000000),
      exporterRegistrationNo: Math.floor(Math.random() * 10000000),
      exporterCompanyName: this.secondFormGroup.controls['exportername'].value,
      importerCode: 'I_' + Math.floor(Math.random() * 10000000),
      importerRegistrationNo: Math.floor(Math.random() * 10000000),
      importerCompanyName: this.secondFormGroup.controls['consigneename'].value,
      taxpayerCode: 'F_' + Math.floor(Math.random() * 10000000),
      taxpayerRegistrationNo: Math.floor(Math.random() * 10000000),
      taxpayerName: this.secondFormGroup.controls['financialname'].value,
      declarantCode: 'D_' + Math.floor(Math.random() * 10000000),
      declarantRegistrationNumber: Math.floor(Math.random() * 10000000),
      declarantName: this.secondFormGroup.controls['declarantname'].value,
      firstDestination: this.countryfirstdestination,
      tradingCountry: this.name_country_origin,
      valueDetails: this.message,
      exportCountryCd: this.code_country,
      exportCountryNm: this.name_country,
      exportCountryRegionCd: null,
      destinationCountryCd: this.code_country_destination,
      destinationCountryNm: this.name_country_destination,
      destinationCountryRegionCd: null,
      countryOfOrigin: this.name_country_origin,
      transportMeansNm: this.country_crossing_name,
      transportCountryCd: this.country_crossing_code,
      borderTransportNm: this.country_crossing_name,
      borderTransportCountryCd: this.country_crossing_code,
      modeTransportCrossingTheBorder:
        this.TransportForm.controls['mode_transport_crossing_the_border'].value,
      inlandTransportMode:
        this.TransportForm.controls['inland_transport_mode'].value,
      container: this.TransportForm.controls['container'].value,
      loadingPlaceCd: null,
      loadingPlaceLocation: null,
      loadingPlaceCountryCd: null,
      locationOfGoods: this.TransportForm.controls['locationofGoods'].value,
      officeOfEntryCode: this.officeofentrycode,
      officeOfEntryName: this.officeofentryname,
      natureOfTransactionCode1: this.naturetransactioncode,
      natureOfTransactionCode2: null,
      bankCode: this.bankcode,
      bankName: this.bankname,
      branch: this.bankbranchname,
      reference: null,
      code: this.bankbranchcode,
      description: null,
      deferredPayementReference: null,
      modeOfPayment: this.FinancialForm.controls['modelofpayment'].value,
      warehouseCd: this.TransitForm.controls['warehousecd'].value,
      warehouseDelay: this.TransitForm.controls['warehousede'].value,
      forwarderCode: this.forwardercode,
      forwarderName: this.forwardername,
      representative: this.TransitForm.controls['representative'].value,
      signaturePlace: this.TransitForm.controls['signatureplace'].value,
      signatureDate: this.date_ofsignature,
      companyOfDestination:
        this.TransitForm.controls['companyofdestination'].value,
      timeLimit: null,

      invoiceValueAmount:
        this.GeneralsegmentForm.controls['invoicevalue'].value,
      invoiceValueFcxCode: this.FCXcodevalue,
      invoiceValueExchangeRate:
        this.GeneralsegmentForm.controls['invoicevaluerate'].value,
      invoiceValueAmountInNationalMonetaryUnits:
        this.GeneralsegmentForm.controls['invoicevalueamount'].value,
      externalFreightAmount:
        this.GeneralsegmentForm.controls['externalfreight'].value,
      externalFreightFcxCode: this.FCXcodeExternelFreight,
      externalFreightExchangeRate:
        this.GeneralsegmentForm.controls['externalfreightrate'].value,
      externalCurrencyNm: '',
      externalFreightAmountInNationalMonetaryUnits:
        this.GeneralsegmentForm.controls['externalfreightamount'].value,
      internalFreightAmount:
        this.GeneralsegmentForm.controls['internalfreight'].value,
      internalFreightFcxCode: this.FCXcodeInternalfreight,
      internalFreightEchangeRate:
        this.GeneralsegmentForm.controls['internalfreightrate'].value,
      internalCurrencyNm: '',
      internalFreightAmountInNationalMonetaryUnits:
        this.GeneralsegmentForm.controls['internalfreightamount'].value,
      insuranceAmount: this.GeneralsegmentForm.controls['insurancevalue'].value,
      insuranceFcxCode: this.FCXcodeInsurance,
      insuranceExchangeRate:
        this.GeneralsegmentForm.controls['insurancevaluerate'].value,
      insuranceCurrencyNm: '',
      insuranceAmountInNationalMonetaryUnits:
        this.GeneralsegmentForm.controls['insurancevalueamount'].value,
      otherCostsAmount:
        this.GeneralsegmentForm.controls['othercostvalue'].value,
      otherCostsFcxCode: this.FCXcodeOtherCost,
      otherCostsExchangeRate:
        this.GeneralsegmentForm.controls['othercostvaluerate'].value,
      othercostCurrencyNm: '',
      otherCostsAmountInNationalMonetaryUnits:
        this.GeneralsegmentForm.controls['othercostvalueamount'].value,
      deductionsAmount:
        this.GeneralsegmentForm.controls['deductionsvalue'].value,
      deductionsFcxCode: this.FCXcodeDeductions,
      deductionsExchangeRate:
        this.GeneralsegmentForm.controls['deductionsvaluerate'].value,
      deductionCurrencyNm: '',
      deductionsAmountInNationalMonetaryUnits:
        this.GeneralsegmentForm.controls['deductionsvalueamount'].value,
      totalGrossMass: this.GeneralsegmentForm.controls['totalgrossmass'].value,
      totalCosts: this.GeneralsegmentForm.controls['totalcosts'].value,
      deliveryCd: null,
      deliveryDesc: this.GeneralsegmentForm.controls['deliveryterms'].value,
      cifValue: this.GeneralsegmentForm.controls['cifvalue'].value,
      guaranteeAmount:
        this.GeneralsegmentForm.controls['guaranteeamount'].value,
      guaranteeDate: this.GeneralsegmentForm.controls['guaranteedate'].value,
      totalFees: this.GeneralsegmentForm.controls['totalfees'].value,
      totalDeclaration:
        this.GeneralsegmentForm.controls['totaldeclaration'].value,
      etat: this.etat,
      items: this.Article_list,
    };
  }

  protected createFromCustomDeclarationUpdate(): any {
    return {
      id: this.Id_customs_update,
      dateOfDeclaration: this.CustomDeclaration.dateOfDeclaration,
      blNo: this.IdentificationForm.controls['bl'].value,
      manifest: this.IdentificationForm.controls['manifest'].value,
      numberOfPackages:
        this.IdentificationForm.controls['numberofpackages'].value,
      loadList: this.IdentificationForm.controls['loadlist'].value,
      typeOfDeclaration: this.typeofdecalaration_name,
      officeOfDeclaration: 1,
      customsReferenceDivision: this.CustomDeclaration.customsReferenceDivision,
      customsReferenceNo: this.CustomDeclaration.customsReferenceNo,
      customsReferenceData: this.CustomDeclaration.customsReferenceData,
      totalItems: this.IdentificationForm.controls['totalitems'].value,
      exporterCompanyCode: this.CustomDeclaration.exporterCompanyCode,
      exporterRegistrationNo: this.CustomDeclaration.exporterRegistrationNo,
      exporterCompanyName: this.secondFormGroup.controls['exportername'].value,
      importerCode: this.CustomDeclaration.importerCode,
      importerRegistrationNo: this.CustomDeclaration.importerRegistrationNo,
      importerCompanyName: this.secondFormGroup.controls['consigneename'].value,
      taxpayerCode: this.CustomDeclaration.taxpayerCode,
      taxpayerRegistrationNo: this.CustomDeclaration.taxpayerRegistrationNo,
      taxpayerName: this.secondFormGroup.controls['financialname'].value,
      declarantCode: this.CustomDeclaration.declarantCode,
      declarantRegistrationNumber:
        this.CustomDeclaration.declarantRegistrationNumber,
      declarantName: this.secondFormGroup.controls['declarantname'].value,
      firstDestination: this.countryfirstdestination,
      tradingCountry: this.name_country_origin,
      valueDetails: this.message,
      exportCountryCd: this.code_country,
      exportCountryNm: this.name_country,
      exportCountryRegionCd: null,
      destinationCountryCd: this.code_country_destination,
      destinationCountryNm: this.name_country_destination,
      countryOfOrigin: this.name_country_origin,
      transportMeansNm: this.country_crossing_name,
      transportCountryCd: this.country_crossing_code,
      borderTransportNm: this.country_crossing_name,
      borderTransportCountryCd: this.country_crossing_code,
      modeTransportCrossingTheBorder:
        this.TransportForm.controls['mode_transport_crossing_the_border'].value,
      inlandTransportMode:
        this.TransportForm.controls['inland_transport_mode'].value,
      container: this.TransportForm.controls['container'].value,

      locationOfGoods: this.TransportForm.controls['locationofGoods'].value,
      officeOfEntryCode: this.officeofentrycode,
      officeOfEntryName: this.officeofentryname,
      natureOfTransactionCode1: this.naturetransactioncode,
      bankCode: this.bankcode,
      bankName: this.bankname,
      branch: this.bankbranchname,
      code: this.bankbranchcode,
      modeOfPayment: this.FinancialForm.controls['modelofpayment'].value,
      warehouseCd: this.TransitForm.controls['warehousecd'].value,
      warehouseDelay: this.TransitForm.controls['warehousede'].value,
      forwarderCode: this.forwardercode,
      forwarderName: this.forwardername,
      representative: this.TransitForm.controls['representative'].value,
      signaturePlace: this.TransitForm.controls['signatureplace'].value,
      signatureDate: this.date_ofsignature,
      companyOfDestination:
        this.TransitForm.controls['companyofdestination'].value,
      timeLimit: null,
      countryCode: null,
      invoiceValueAmount:
        this.GeneralsegmentForm.controls['invoicevalue'].value,
      invoiceValueFcxCode: this.FCXcodevalue,
      invoiceValueExchangeRate:
        this.GeneralsegmentForm.controls['invoicevaluerate'].value,
      invoiceValueAmountInNationalMonetaryUnits:
        this.GeneralsegmentForm.controls['invoicevalueamount'].value,
      externalFreightAmount:
        this.GeneralsegmentForm.controls['externalfreight'].value,
      externalFreightFcxCode: this.FCXcodeExternelFreight,
      externalFreightExchangeRate:
        this.GeneralsegmentForm.controls['externalfreightrate'].value,
      externalCurrencyNm: '',
      externalFreightAmountInNationalMonetaryUnits:
        this.GeneralsegmentForm.controls['externalfreightamount'].value,
      internalFreightAmount:
        this.GeneralsegmentForm.controls['internalfreight'].value,
      internalFreightFcxCode: this.FCXcodeInternalfreight,
      internalFreightEchangeRate:
        this.GeneralsegmentForm.controls['internalfreightrate'].value,
      internalCurrencyNm: '',
      internalFreightAmountInNationalMonetaryUnits:
        this.GeneralsegmentForm.controls['internalfreightamount'].value,
      insuranceAmount: this.GeneralsegmentForm.controls['insurancevalue'].value,
      insuranceFcxCode: this.FCXcodeInsurance,
      insuranceExchangeRate:
        this.GeneralsegmentForm.controls['insurancevaluerate'].value,
      insuranceCurrencyNm: '',
      insuranceAmountInNationalMonetaryUnits:
        this.GeneralsegmentForm.controls['insurancevalueamount'].value,
      otherCostsAmount:
        this.GeneralsegmentForm.controls['othercostvalue'].value,
      otherCostsFcxCode: this.FCXcodeOtherCost,
      otherCostsExchangeRate:
        this.GeneralsegmentForm.controls['othercostvaluerate'].value,
      othercostCurrencyNm: '',
      otherCostsAmountInNationalMonetaryUnits:
        this.GeneralsegmentForm.controls['othercostvalueamount'].value,
      deductionsAmount:
        this.GeneralsegmentForm.controls['deductionsvalue'].value,
      deductionsFcxCode: this.FCXcodeDeductions,
      deductionsExchangeRate:
        this.GeneralsegmentForm.controls['deductionsvaluerate'].value,
      deductionCurrencyNm: '',
      deductionsAmountInNationalMonetaryUnits:
        this.GeneralsegmentForm.controls['deductionsvalueamount'].value,
      totalGrossMass: this.GeneralsegmentForm.controls['totalgrossmass'].value,
      totalCosts: this.GeneralsegmentForm.controls['totalcosts'].value,
      deliveryCd: null,
      deliveryDesc: this.GeneralsegmentForm.controls['deliveryterms'].value,
      cifValue: this.GeneralsegmentForm.controls['cifvalue'].value,
      guaranteeAmount:
        this.GeneralsegmentForm.controls['guaranteeamount'].value,
      guaranteeDate: this.GeneralsegmentForm.controls['guaranteedate'].value,
      totalFees: this.GeneralsegmentForm.controls['totalfees'].value,
      totalDeclaration:
        this.GeneralsegmentForm.controls['totaldeclaration'].value,
      etat: this.etat,
      items: this.Article_list,
      RecuPayement: this.recupayment,
    };
  }
  message: any = '';

  protected GetCellCustomDeclarationById(id: any) { }
  id_customs: any;

  Go_list() {
    this.modalService.dismissAll();
    this.router.navigateByUrl('Customs/liste');
    //localStorage.setItem()
  }

  code_country: any;
  name_country: any;
  onCountrySelected(country: Country) {
    this.code_country = country.alpha2Code;
    this.name_country = country.name;
  }

  code_country_destination: any;
  name_country_destination: any;
  onCountrySelected_destination(country: Country) {
    this.code_country_destination = country.alpha2Code;
    this.name_country_destination = country.name;
  }
  code_country_origin: any;
  name_country_origin: any;
  onCountrySelected_origin(country: Country) {
    this.code_country_origin = country.alpha2Code;
    this.name_country_origin = country.name;
  }

  code_country_border: any;
  name_country_border: any;
  onCountrySelected_border(country: Country) {
    this.code_country_border = country.alpha2Code;
    this.name_country_border = country.name;
  }

  code_country_Departure: any;
  name_country_Departure: any;
  onCountrySelected_Departure(country: Country) {
    this.code_country_Departure = country.alpha2Code;
    this.name_country_Departure = country.name;
  }

  date_ofdeclaration: any;
  public onchangedateofdecalaration(event: any): void {
    this.date_ofdeclaration = this.datePipe.transform(event, 'yyyy-MM-dd');
  }
  date_ofsignature: any;
  public onchangedateofsignature(event: any): void {
    this.date_ofsignature = this.datePipe.transform(event, 'yyyy-MM-dd');
  }

  changetypeofdecalaration(event: any) {
    this.typeofdecalaration_name = event.name;
  }
  typeofdecalaration_name: any;
  typeofdecalaration_id: any;

  selectettypeofdecalaration: any;
  typeofdecalarationlist: any[] = [
    { id: 1, name: 'Import' },
    { id: 2, name: 'Export' },
  ];

  office_of_decalration_code: any;

  yofficeofeclaration_name: any;
  yofficeofeclaration_id: any;

  changeofficeofeclaration(event: any) {
    this.office_of_decalration_code = event.name;
    this.yofficeofeclaration_id = event.id;
  }
  selectetofficeofeclaration: any;
  officeofeclarationlist: any[] = [
    { id: 1, name: 'office1' },
    { id: 2, name: 'office2' },
  ];
  placeofloading_id: any;
  placeofloading_name: any;
  changeplaceofloading(event: any) { }
  selectetplaceofloading: any;
  placeofloadinglist: any[] = [
    { id: 1, name: 'place1' },
    { id: 2, name: 'place2' },
  ];
  country_crossing_code: any;
  country_crossing_name: any;
  onCountrySelected_Crossing(event: any) {
    this.country_crossing_code = event.alpha2Code;
    this.country_crossing_name = event.name;
  }
  officeofentryname: any;
  officeofentrycode: any;

  changeofficeofentry(event: any) {
    this.officeofentryname = event.name;
    this.officeofentrycode = event.id;
  }
  selectetofficeofentry: any;
  officeofentrylist: any[] = [
    { id: 1, name: 'office1' },
    { id: 2, name: 'office2' },
  ];
  naturetransactionname: any;
  naturetransactioncode: any;

  changenatureoftransaction(event: any) {
    this.naturetransactionname = event.name;
    this.naturetransactioncode = event.id;
  }

  natureoftransactionlist: any[] = [
    { id: 1, name: 'Nature of Transaction 1' },
    { id: 2, name: 'Nature of Transaction 2' },
  ];

  bankcode: any;
  bankname: any;
  changebank(event: any) {
    this.bankname = event.name;
    this.bankcode = event.id;
  }

  Banklist: any[] = [
    { id: 1, name: 'Bank 1' },
    { id: 2, name: 'Bank 2' },
  ];
  bankbranchname: any;
  bankbranchcode: any;
  changebankbranch(event: any) {
    this.bankbranchname = event.name;
    this.bankbranchcode = event.id;
  }

  Bankbranchlist: any[] = [
    { id: 1, name: 'Bank branch 1' },
    { id: 2, name: 'Bank branch 2' },
  ];

  termepaymentname: any;
  termepaymentcode: any;

  changetermeofpayement(event: any) {
    this.termepaymentname = event.name;
    this.termepaymentcode = event.id;
  }

  termpaymentlist: any[] = [
    { id: 1, name: 'Term of payment 1' },
    { id: 2, name: 'Term of payment  2' },
  ];

  forwardercode: any;
  forwardername: any;

  changeForwarder(event: any) {
    this.forwardercode = event.id;
    this.forwardername = event.name;
  }

  Forwarderlist: any[] = [
    { id: 1, name: 'Forwarder 1' },
    { id: 2, name: 'Forwarder  2' },
  ];
  countryfirstdestination: any;
  onCountrySelectedtransit(event: any) {
    this.countryfirstdestination = event.name;
  }
  i = 0;
  Add_article() {
    this.i = this.i + 1;
    this.Article_DATA.push({
      no: this.i,
      pc: this.FormArticle.controls['ngp'].value,
      sds: this.FormArticle.controls['libelle'].value,
      brute: this.FormArticle.controls['quantitebrute'].value,
      net: this.FormArticle.controls['quantitenet'].value,
      prix: this.FormArticle.controls['pfn'].value,
      pays: this.isopaysarticle,
      quota: this.FormArticle.controls['codeqcs'].value,
    });
    setTimeout(() => {
      this.dimiss();
      this.dataSource = new MatTableDataSource(this.Article_DATA);
    }, 200);
  }

  dimiss() {
    this.modalService.dismissAll();
  }

  codepaysarticle: any;
  namepaysarticle: any;
  isopaysarticle: any;

  onCountrySelectedarticle(event: any) {
    this.codepaysarticle = event.alpha2Code;
    this.namepaysarticle = event.name;
    this.isopaysarticle = event.numericCode;
  }

  FCXcodevalue: any;
  FCXcodeExternelFreight: any;
  FCXcodeInternalfreight: any;
  FCXcodeInsurance: any;
  FCXcodeOtherCost: any;
  FCXcodeDeductions: any;

  changedevisefcx(event: any, clonne: any) {
    switch (clonne) {
      case 'Invoice':
        this.FCXcodevalue = event.name;
        break;
      case 'Externel ':
        this.FCXcodeExternelFreight = event.name;

        break;
      case 'Internal ':
        this.FCXcodeInternalfreight = event.name;

        break;
      case 'Insurance':
        this.FCXcodeInsurance = event.name;

        break;

      case 'Other':
        this.FCXcodeOtherCost = event.name;
        break;
      case 'Deductions':
        this.FCXcodeDeductions = event.name;
        break;
    }
  }

  devisfcxlist: any[] = [
    { id: 1, name: 'USD' },
    { id: 2, name: 'EURO' },
    { id: 2, name: 'Dinar' },
  ];
  list_organsation: any[] = [];
  Getorganisation() {
    this.OrganisationService.GetAll().subscribe((res: any) => {
      this.list_organsation = [];
      this.customs_list = [];
      this.list_organsation = res;
      this.list_organsation = this.list_organsation.filter(
        (e: any) => e.profil == 'DOUANES'
      );
      this.customs_list = this.list_organsation;
    });
  }
  selectedcustom: any;
  CustomDeclaration: any;
  getCustomsDeclarationByid() {
    this.Customsservice.GetByid(this.Id_customs_update).subscribe(
      (res: any) => {
        this.CustomDeclaration = res;
        this.GetArticles(res.items);
        this.Id_org = res.customsReferenceNo;

        this.etat = this.CustomDeclaration.etat;

        this.Remplir_Form();

        setTimeout(() => {
          this.spinner.hide();
        }, 400);
      }
    );
  }

  selectedofficeofentry: any;

  Remplir_Form() {
    //bloc 1
    this.selectedcustom = this.CustomDeclaration.customsReferenceData;
    let dateString = this.CustomDeclaration.dateOfDeclaration;
    let momentVariable = moment(dateString, 'MM-DD-YYYY');
    let stringvalue = momentVariable.format('YYYY-MM-DD');
    this.dateofdeclaration.setValue(stringvalue);

    this.selectettypeofdecalaration = this.CustomDeclaration.typeOfDeclaration;
    this.selectetofficeofeclaration =
      this.CustomDeclaration.officeOfDeclaration;

    this.IdentificationForm.controls['bl'].setValue(
      this.CustomDeclaration.blNo
    );
    this.IdentificationForm.controls['manifest'].setValue(
      this.CustomDeclaration.manifest
    );
    this.IdentificationForm.controls['numberofpackages'].setValue(
      this.CustomDeclaration.numberOfPackages
    );
    this.IdentificationForm.controls['loadlist'].setValue(
      this.CustomDeclaration.loadList
    );

    this.IdentificationForm.controls['totalitems'].setValue(
      this.CustomDeclaration.totalItems
    );

    //bloc2

    this.secondFormGroup.controls['exportername'].setValue(
      this.CustomDeclaration.exporterCompanyName
    );
    this.secondFormGroup.controls['consigneename'].setValue(
      this.CustomDeclaration.importerCompanyName
    );
    this.secondFormGroup.controls['financialname'].setValue(
      this.CustomDeclaration.taxpayerName
    );
    this.secondFormGroup.controls['declarantname'].setValue(
      this.CustomDeclaration.declarantName
    );

    //bloc2 save+
    this.countryfirstdestination = this.CustomDeclaration.firstDestination;
    this.name_country_origin = this.CustomDeclaration.tradingCountry;
    this.secondFormGroup.controls['exportercode'].setValue(
      this.CustomDeclaration.exporterCompanyCode
    );
    this.secondFormGroup.controls['exporterno'].setValue(
      this.CustomDeclaration.exporterRegistrationNo
    );

    this.secondFormGroup.controls['consigneecode'].setValue(
      this.CustomDeclaration.importerCode
    );
    this.secondFormGroup.controls['consigneeno'].setValue(
      this.CustomDeclaration.importerRegistrationNo
    );

    this.secondFormGroup.controls['financialcode'].setValue(
      this.CustomDeclaration.taxpayerCode
    );
    this.secondFormGroup.controls['financialno'].setValue(
      this.CustomDeclaration.taxpayerRegistrationNo
    );

    this.secondFormGroup.controls['declarantcode'].setValue(
      this.CustomDeclaration.declarantCode
    );
    this.secondFormGroup.controls['declarantno'].setValue(
      this.CustomDeclaration.declarantRegistrationNumber
    );

    //bloc3

    this.Valuedorigin = {
      name: this.CustomDeclaration.countryOfOrigin,
      alpha2Code: this.CustomDeclaration.countryCode,
      alpha3Code: '',
      numericCode: '',
      callingCode: '',
    };

    this.code_country_origin = this.CustomDeclaration.countryCode;
    this.name_country_origin = this.CustomDeclaration.tradingCountry;

    this.Valuepexp = {
      name: this.CustomDeclaration.exportCountryNm,
      alpha2Code: this.CustomDeclaration.exportCountryCd,
      alpha3Code: '',
      numericCode: this.CustomDeclaration.exportCountryNm,
      callingCode: '',
    };
    this.code_country = this.CustomDeclaration.exportCountryCd;
    this.name_country = this.CustomDeclaration.exportCountryNm;

    this.Valuedes = {
      name: this.CustomDeclaration.destinationCountryNm,
      alpha2Code: this.CustomDeclaration.destinationCountryCd,
      alpha3Code: '',
      numericCode: this.CustomDeclaration.destinationCountryNm,
      callingCode: '',
    };
    this.code_country_destination = this.CustomDeclaration.destinationCountryCd;
    this.name_country_destination = this.CustomDeclaration.destinationCountryNm;

    this.message = this.CustomDeclaration.valueDetails;
    //bloc 4

    this.Valuepcro = {
      name: this.CustomDeclaration.transportMeansNm,
      alpha2Code: this.CustomDeclaration.transportCountryCd,
      alpha3Code: '',
      numericCode: this.CustomDeclaration.transportMeansNm,
      callingCode: '',
    };
    this.country_crossing_code = this.CustomDeclaration.transportCountryCd;
    this.country_crossing_name = this.CustomDeclaration.transportMeansNm;

    this.TransportForm.controls['mode_transport_crossing_the_border'].setValue(
      this.CustomDeclaration.modeTransportCrossingTheBorder
    );
    this.selectedofficeofentry = this.CustomDeclaration.officeOfEntryName;

    this.TransportForm.controls['inland_transport_mode'].setValue(
      this.CustomDeclaration.inlandTransportMode
    );

    this.TransportForm.controls['locationofGoods'].setValue(
      this.CustomDeclaration.locationOfGoods
    );

    this.TransportForm.controls['container'].setValue(
      this.CustomDeclaration.container
    );

    //bloc 5
    this.naturetransaction = this.CustomDeclaration.natureOfTransactionCode1;
    this.codepayment = this.CustomDeclaration.code;
    this.bank = this.CustomDeclaration.bankName;
    this.bankbranch = this.CustomDeclaration.branch;

    this.FinancialForm.controls['modelofpayment'].setValue(
      this.CustomDeclaration.modeOfPayment
    );

    //bloc 6
    this.warehousecd = this.CustomDeclaration.warehouseCd;
    this.warehousede = this.CustomDeclaration.warehouseDelay;
    this.forwarder = this.CustomDeclaration.forwarderName;

    this.TransitForm.controls['warehousecd'].setValue(
      this.CustomDeclaration.warehouseCd
    );

    this.TransitForm.controls['warehousede'].setValue(
      this.CustomDeclaration.warehouseDelay
    );

    this.TransitForm.controls['representative'].setValue(
      this.CustomDeclaration.representative
    );

    this.TransitForm.controls['signatureplace'].setValue(
      this.CustomDeclaration.signaturePlace
    );
    this.TransitForm.controls['companyofdestination'].setValue(
      this.CustomDeclaration.companyOfDestination
    );

    let dateString1 = this.CustomDeclaration.signatureDate;
    if (dateString1 != null) {
      let momentVariable1 = moment(dateString1, 'DD-MM-YYYY');
      let stringvalue1 = momentVariable1.format('YYYY-MM-DD');
      this.dateofsignature.setValue(stringvalue1);
      this.date_ofsignature = stringvalue1;
    }
  }

  naturetransaction: any;
  bank: any;
  bankbranch: any;
  codepayment: any;
  warehousecd: any;
  warehousede: any;
  forwarder: any;

  listattchments: any[] = [];

  Submit(content: any) {
    this.Article_Remplir_update();
    if (this.CustomDeclaration.etat == 'Save') {
      this.delete_items();
      this.etat = 'For confirmation';
      this.Customsservice.submit(
        this.createFromCustomDeclarationUpdate(),
        this.Id_org
      ).subscribe((res: any) => {
        this.open(content);

        setTimeout(() => {
          this.Go_list();
        }, 400);
      });
    } else if (this.CustomDeclaration.etat == 'Error') {
      this.delete_items();
      this.etat = 'CompleteInfos';
      this.Customsservice.response(
        this.createFromCustomDeclarationUpdate()
      ).subscribe((res: any) => {
        this.open(content);
        setTimeout(() => {
          this.Go_list();
        }, 400);
      });
    }

    if (this.CustomDeclaration.etat == 'Confirm') {
      this.etat = 'Payment';

      this.pay_recu(this.Id_customs_update);

      this.Customsservice.response(
        this.createFromCustomDeclarationUpdate()
      ).subscribe((res: any) => {
        this.open(content);
        setTimeout(() => {
          this.Go_list();
        }, 400);
      });
    }
  }

  recupayment: any;
  onFileChange2(file: any) {
    this.recu_payement = file;
  }
  recu_payement: any;
  pay_recu(id_c: any) {
    let formData = new FormData();
    formData.append('attachment', this.recu_payement[0]);

    this.attchementServices.post(formData, id_c).subscribe((res: any) => { });
  }

  delete_items() {
    for (let article of this.Article_DATA) {
      this.Customsservice.delete_items(article.id).subscribe((res: any) => { });
    }
  }

  ngselectinvalid: any = '';
  formSubmitted: boolean = false;
  formSubmitted1: boolean = false;
  formSubmitted2: boolean = false;
  formSubmitted3: boolean = false;
  next(): void {
    this.ngselectinvalid = this.languet.required;

    if (this.IdentificationForm.invalid) {
      this.formSubmitted = true;
    } else {
      this.formSubmitted = false;
    }
  }
  next1(): void {
    this.ngselectinvalid = this.languet.required;

    if (this.secondFormGroup.invalid) {
      this.formSubmitted1 = true;
    } else {
      this.formSubmitted1 = false;
    }
  }

  next2(): void {
    this.ngselectinvalid = this.languet.required;

    if (this.TransportForm.invalid) {
      this.formSubmitted2 = true;
    } else {
      this.formSubmitted2 = false;
    }
  }

  next3(): void {
    this.ngselectinvalid = this.languet.required;

    if (this.TransitForm.invalid) {
      this.formSubmitted3 = true;
    } else {
      this.formSubmitted3 = false;
    }
  }
}
