import {
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HttpClient } from "@angular/common/http";
import { SelectionModel } from "@angular/cdk/collections";
import { jsPDF } from "jspdf";
import { DatePipe } from "@angular/common";

import { Router } from "@angular/router";
import { CommerceService } from "../Services/commerce.service";
import { DomSanitizer } from "@angular/platform-browser";
import { PiecesJService } from "../Services/pieces/pieces-j.service";
import Langue from './../../../../../assets/langue.json';
import { MessageUserService } from 'src/Services/MessagesUser/message-user.service';
import { NotificationService } from "src/Services/Notification/notification.service";
import { ArticleService } from "../Services/article/article.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-liste-f",
  templateUrl: "./liste.component.html",
  styleUrls: ["./liste.component.css"],
})
export class ListeComponent implements OnInit {
  index: number = 0;
  displayedColumns: string[] = [
    "select",
    "id",
    "idDossier",
    "date",



    "exportateur",
    "pays",
    "numeroContratCommercial",
    "montant",
    "modeReglement",
    "delaisDeReglemnt",
    "etat",
    "action",
  ];

  displayedColumnsArticles: string[] = [
    "no",
    "ngp",
    "des",
    "pfn",
    "codeqcs",
    "qcs",
    "pays",
  ];
  ListFde_1: any[] = [];
  ListFde_2: any[] = [];
  ListFde_f: any[] = [];
  etat: any;
  id_search: any = 0;

  selectedcondition: String | any;

  changestatus(event: any) {
    if (event != undefined) {
      this.etat = event;
      this.search_status();
    } else {
      this.id_search = 0;
      this.GetFde();
    }
  }

  search_status() {
    this.id_search = 3;
    this.ListFde_f = this.ListFde_1.filter((e) => e.etat == this.etat);
    setTimeout(() => {
      this.dataSource = new MatTableDataSource(this.ListFde_f);
      this.dataSource.paginator = this.paginator;
    }, 100);
  }

  onchangedatestart(event: any) {
    this.startdate = this.datePipe.transform(event, 'yyyy-MM-dd');
  }
  typerecherche: any;
  date_disabled: boolean = true;
  changedate(event: any) {
    if (event != undefined) {
      this.date_disabled = false;
      this.typerecherche = event.code;
    } else {
      //  this.id_search = 0;
      this.startDate = '';
      this.endDate = '';
      this.date_disabled = true;
      //this.GetCustomsDeclaration();
    }
  }
  remplir_list(list: any) {
    this.ListFde_2 = list;

    let resultA = this.ListFde_2.filter(
      (elm) =>
        !this.ListFde_1.map((elm) => JSON.stringify(elm)).includes(
          JSON.stringify(elm)
        )
    );

    let resultB = this.ListFde_1.filter(
      (elm) =>
        !this.ListFde_2.map((elm) => JSON.stringify(elm)).includes(
          JSON.stringify(elm)
        )
    );

    if (resultA.length > 0 || resultB.length > 0) {
      this.ListFde_1 = this.ListFde_2;
      this.ListFde_f = this.ListFde_2;
      this.dataSource = new MatTableDataSource(this.ListFde_1);
      this.dataSource.paginator = this.paginator;
      this.ListFde_2 = [];
    }
  }
  displayedColumnsMessages: string[] = [
    'id',
    'status',
    'startDate',
    'endDate',
    'typeFlux',
    'etatFlux',
    'idDossier',
    'typeMessage',
    'typeScenario',
  ];
  dataSourceMessages: MatTableDataSource<any>;

  list_message: any;

  Arr_messages(content: any, id: any) {
    this.MessagesUser.GetFdeMessage(id).subscribe((res: any) => {
      this.list_message = res;

      setTimeout(() => {
        this.dataSourceMessages = new MatTableDataSource(this.list_message);
        this.dataSourceMessages.paginator = this.paginator;
        this.modalService.open(content, {
          size: 'xl',
          windowClass: 'dark-modal',
        });
      }, 100);
    });
  }
  startdate: any;
  enddate: any;
  selecteddate: any;
  startDate: any;
  endDate: any;
  selectedstatus: any;
  dataSource: MatTableDataSource<any>;
  role: String
  displayedColumnsP: string[] = ["file", "actions"];
  dataSourcep: MatTableDataSource<any>;
  ListTCE: any[] = [];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;


  langue: any;
  direction: any;
  languef: any;
  langue_list: any[] = Langue;
  languea: any;
  languem: any;
  languet: any;
  statuslist = ['Save', 'For confirmation', 'Confirm', 'Error', 'Payed'];
  itemslist: any[] = [];
  itemlist = [
    { name: 'Date Of Declaration', code: 'dateOfDeclaration' },
    { name: 'Date Assement', code: 'assessmentDate' },
    { name: 'Date Signature', code: 'signatureDate' },
    { name: 'Guarantee Date', code: 'guaranteeDate' },
  ];
  constructor(
    private commerceservice: CommerceService,
    private http: HttpClient,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private router: Router,
    public sanitizer: DomSanitizer,
    private pieceService: PiecesJService,
    private MessagesUser: MessageUserService,
    private notificationApi: NotificationService,
    private articleservice: ArticleService,
    private spinner: NgxSpinnerService,

  ) {
    this.dataSource = new MatTableDataSource(this.ListTCE);
    this.dataSourceArticles = new MatTableDataSource(this.Article_DATA);
    this.dataSourcep = new MatTableDataSource(this.Files_list);
    this.role = localStorage.getItem('role') + ""

    if (localStorage.getItem('langue') != null) {
      this.langue = localStorage.getItem('langue') + '';
    } else {
      this.langue = 'AR';
    }

    if (this.langue == 'AR') {
      this.direction = 'rtl';
      this.languef = this.langue_list[0]['AR'].tablelist;
      this.languea = this.langue_list[0]['AR'].Fde;
      this.languem = this.langue_list[0]['AR'].messageuser;
      this.languet = this.langue_list[0]['AR'].modal_text;
    }

    if (this.langue == 'EN') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['EN'].tablelist;
      this.languea = this.langue_list[0]['EN'].Fde;
      this.languem = this.langue_list[0]['EN'].messageuser;
      this.languet = this.langue_list[0]['EN'].modal_text;
    }

    if (this.langue == 'FR') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['FR'].tablelist;
      this.languea = this.langue_list[0]['FR'].Fde;
      this.languem = this.langue_list[0]['FR'].messageuser;
      this.languet = this.langue_list[0]['FR'].modal_text;
    }

    this.itemslist = [
      {
        id: 4,
        name: "ID (dossier)",
        code: 'ID_Dossier',
      },
      {
        id: 4,
        name: "RS Importateur",
        code: 'RSImportateur',
      },
      {
        id: 4,
        name: "Pays Achat/Destination",
        code: 'Pays_Achat_Destination',
      },
      {
        id: 4,
        name: "Numéro Contrat Commercial",
        code: 'NumeroContratCommercial',
      },
      {
        id: 4,
        name: "Mode reglement",
        code: 'modeReglement',
      },
      {
        id: 4,
        name: "Delais de reglement",
        code: 'Delais_reglement',
      },
      {
        id: 4,
        name: "Montant En devises",
        code: 'Montant_En_devises',
      },
    ];
    
    /*this.idFde = this.route.snapshot.params['id'];
    this.idFde_copy = this.route.snapshot.params['id_c'];
    if (this.idFde != undefined) {
      this.transactiontype = 'Update';
      this.getfde(this.idFde)
      //  this.idsc=this.idFde
    }*/

  }

  
  Files_list: any[] = [];
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  @Input() profil: string;
  list_delete: any[] = [];

  Arr_remove(content: any) {
    this.modalService.dismissAll();
    this.list_delete = [];
    this.selection.selected.forEach((s) => this.list_delete.push(s));

    for (let titre of this.list_delete) {
      let fde = this.ListTCE.find((x) => x.id == titre.id);
      setTimeout(() => {
        this.commerceservice.delete(fde.id).subscribe((res: any) => {
          if (res == null) {
          }

          setTimeout(() => {
            this.GetFde();
          }, 700);
        });
      }, 500);
    }
    setTimeout(() => {
      this.ClearAll();
    }, 300);
  }
  document: any;
  Arr_view(content: any, id: any) {
    this.commerceservice.GetById(id).subscribe((res: any) => {
      this.document = res;
      this.Article_DATA = res.articleFdes;
      this.Files_list = res.pieceJointes;

      setTimeout(() => {
        this.dataSourceArticles = new MatTableDataSource(this.Article_DATA);
        this.dataSourcep = new MatTableDataSource(this.Files_list);
        this.modalService.open(content, {
          size: "xl",
          windowClass: "dark-modal",
        });
      }, 500);
    });
  }

  getPieces(id: any) {
    this.pieceService.GetByTce(id).subscribe((res: any) => {
      this.Files_list = res;
    });
  }

  open(content: any) {
    this.modalService.open(content);
  }
  Arr_update() { }
  titre_comerce_list: any[] = [];
  titre_comerce_list_f: any[] = [];
  GetFde() {
    this.titre_comerce_list = [];
    this.commerceservice.Get().subscribe((res: any) => {
      this.ListTCE = res;
      //console.log(this.ListTCE)
      setTimeout(() => {
        this.dataSource = new MatTableDataSource(this.ListTCE);
        this.dataSource.paginator = this.paginator;
      }, 500);
    });
  }
  private socket: any;
  web_socket: boolean = false;

  Article_DATA: any[] = [];

  dataSourceArticles: MatTableDataSource<any>;
  ngOnInit(): void {
    this.dataSourceArticles = new MatTableDataSource(this.Article_DATA);
    this.GetFde();
  }

  selection = new SelectionModel<any>(true, []);
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  ClearAll() {
    this.selection.clear();
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? "deselect" : "select"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${row.position + 1
      }`;
  }

  list_print: any[] = [];
  printdoc() {
    this.list_print = [];
    this.selection.selected.forEach((s) => this.list_print.push(s));

    for (let fde of this.list_print) {
      let titre = this.ListTCE.find((x) => x.id == fde.id);
      this.generate_pdf1(titre);
    }

    setTimeout(() => {
      this.ClearAll();
    }, 300);
  }

  dimision() {
    this.modalService.dismissAll();
  }

  reason: any;
  open_reason(content: any, reason: any) {
    this.reason = reason;
    this.open(content);
  }

  Article_list: any[] = [];
  Id_tce: any;
  Register_Article() {
    for (let article of this.Article_DATA) {
      this.Article_list.push({
        codePaysOrigine: article.pays,
        designation: article.designation,
        nomPaysOrigine: article.nomPaysOrigine,
        nomenclature: article.nomenclature,
        numeroArticle: article.numeroArticle,
        prix: article.prix,
        quantite: article.quantite,
        tceId: this.Id_tce,
        uniteMesure: article.uniteMesure,
      });
    }
  }

 /* protected createFromFdetotalUpdate(): any {
    return {
      id: this.idFde,
      adresseExportateur: this.FormExp.controls["add1"].value,
      adresseImportateur: this.FormExp.controls["add1"].value,
      codeDeviseMontant: this.devismont,
      codeExportateur: "",
      codeImportateur: "",
      codeModeLivraison: this.modelivraison,
      codeNatureAutorisation: this.numnature,
      codeOrganismeDomicilation: this.code_agence_dom,
      codePaysAchat: this.codepaysachat,
      codePaysDestinationDefinitive: this.codepaysDF,
      codePaysPremiereDestination: this.codepaysD1,
      codePaysProvenance: this.codepaysP,
      dateContratCommercial: this.date_contrat,
      dateDeclarant: this.date_signd,
      dateDomiciliation: this.date_domiciliation,
      codeobjet: this.codecontrat,

      delaiReglement: this.delaireg,
      designationModeLivraison: this.modelivraison,
      designationNatureAutorisation: this.FormFde.controls["libellenature"].value,
      designationOrganismeDomiciliation: this.nom_agence_dom,
      designationPaysAchat: this.nompaysachat,
      designationPaysDestinationDefinitive: this.nompaysDF,
      designationPaysPremiereDestination: this.nompaysD1,
      designationPaysProvenance: this.nompaysP,
      designationReglement: this.moderegg,
      deviseReglement: this.devisreg,
      fcbDevise: this.FormReg.controls["fob"].value,
      fcbDinars: this.FormReg.controls["fobd"].value,
      modeReglement: this.moderegg,
      nomDeclarant: this.FormSign.controls["nomsignd"].value,
      nomOrganismeBc: "",
      nomPrenomExportateur: this.FormExp.controls["nomexportateur"].value,
      nomPrenomImportateur: this.FormExp.controls["nomimportateur"].value,
      nomSignataireBanque: "",
      nomSignataireBc: "",
      numeroContratCommercial: "",
      numeroDomiciliation: "",
      processInstanceId: "",
      ptfnDevise: this.FormReg.controls["pten"].value,
      ptfnDinars: this.FormReg.controls["ptend"].value,
      raisonSocialExportateur: this.FormExp.controls["rs"].value,
      raisonSocialImportateur: this.FormExp.controls["rs"].value,
      reponseBanque: "",
      signatureBanque: "",
      signatureBc: "",
      typeDocumentContrat: "",
      etat: this.etat,
      phase: this.phase,
      articleFdes: this.Article_list,
    };
  }*/
  listeFDE : any
  Submit(row: any ,content: any) {
    //this.etat = "For Confirmation";
    this.articleservice;
    this.listeFDE = row
    
    if (this.listeFDE.etat == 'Save') {
      this.listeFDE.etat = 'For Confirmation';
      setTimeout(() => {
        this.spinner.show();
        this.commerceservice.submit(this.listeFDE, this.listeFDE.messages[0].idOrgReceiver).subscribe(
          (res: any) => {
            this.spinner.hide();
            this.Id_tce = res.id;
            //this.open(content);
            let formdata = {
              //action: 'SUBMIT',
      
              idOrgSender: this.listeFDE.messages[0].idOrgSender,
              nomOrgSender: this.listeFDE.messages[0].nomOrgSender,
              idOrgReceiver: this.listeFDE.messages[0].idOrgReceiver,
              nomOrgReceiver: this.listeFDE.messages[0].nomOrgReceiver,
              idScenario: this.listeFDE.id,
              status: this.listeFDE.messages[0].status,
            };
            this.notificationApi.post(formdata).subscribe((res: any) => { });
            setTimeout(() => {
              this.open(content);
            }, 200);
          },
          (error) => {
            this.spinner.hide(); //Error callback
          }
        );
      }, 500);
    }

    else {
      this.document.etat = 'Fournir les informations necessaires';
      this.commerceservice.response(this.document).subscribe((res: any) => {
        //this.Save_notification(res.idMessage);
        this.notificationApi.post(this.document).subscribe((res: any) => { });
        this.open(content);
      })

  }


    /*this.Register_Article();

    setTimeout(() => {
      this.spinner.show();
      this.commerceservice.submit1(row).subscribe(
        (res: any) => {
          this.spinner.hide();
          this.Id_tce = res.id;

          setTimeout(() => {
            //this.Register_pices();
          }, 200);
          this.open(content);
          setTimeout(() => {
            //this.dimmis();
            //this.Go_list()
          }, 700);
        },
        (error) => {
          this.spinner.hide(); //Error callback
        }
      );
    }, 500);*/
  }


 
 /* submit(content: any, row: any) {
    console.log("submit")
    this.commerceservice.submit(row).subscribe((res: any) => {
      this.open(content);
      this.document = row;
      let formdata = {
        action: 'SUBMIT',

        idOrgSender: this.document.messages[0].organizationSender.id,
        nomOrgSender: this.document.messages[0].organizationSender.name,
        idOrgReceiver: this.document.messages[0].organizationReceiver.id,
        nomOrgReceiver: this.document.messages[0].organizationReceiver.name,
        idScenario: this.document.id,
      };

      this.notificationApi.post(row.id).subscribe((res: any) => { });
    });
  }*/

  generate_pdf1(document: any) {
    var doc = new jsPDF();

    doc.setFontSize(11);

    doc.setDrawColor("#0000FF");
    doc.setFontSize(11);

    doc.line(5, 40, 5, 290);
    doc.line(5, 40, 205, 40);
    doc.line(205, 40, 205, 290);
    doc.line(5, 290, 205, 290);

    doc.setFontSize(18);
    doc.setFont("bold");
    doc.text("FDE", 80, 15);
    var img = new Image();

    //  img.src = "./../../../assets/logobc.PNG";

    doc.setFont("italic");
    
    //doc.addImage(img, "png", 150, 0, 50, 30);

    doc.line(5, 50, 205, 50);
    doc.line(105, 40, 105, 50);
    doc.setFontSize(14);
    doc.text("Exportateur", 40, 47);
    doc.text("Imporateur", 140, 47);
    doc.text("Pays", 100, 92);
    doc.text("Régelement", 95, 122);
    doc.text("Divers", 98, 157);
    doc.text("Signataires", 94, 203);
    doc.text("Pièces jointes", 40, 227);
    doc.text("Etat", 160, 227);

    doc.line(5, 65, 205, 65);
    doc.line(105, 50, 105, 85);

    doc.line(25, 50, 25, 58);
    doc.line(60, 50, 60, 58);

    doc.line(125, 50, 125, 58);
    doc.line(160, 50, 160, 58);

    doc.setFontSize(9);
    doc.text("Code", 6, 55);
    doc.text("Nom&Penom", 26, 55);
    doc.text("Raison sociale", 61, 55);
    doc.text("Adresse", 6, 70);

    doc.text("Code", 106, 55);
    doc.text("Nom&Penom", 126, 55);
    doc.text("Raison sociale", 161, 55);
    doc.text("Adresse", 106, 70);

    doc.line(5, 85, 205, 85);
    doc.line(5, 95, 205, 95);

    doc.line(40, 95, 40, 103);

    doc.line(80, 95, 80, 103);
    doc.line(130, 95, 130, 103);

    doc.text("Achat", 6, 100);
    doc.text("Provenance", 41, 100);
    doc.text("1ér destination", 81, 100);
    doc.text("Destination difinitive", 131, 100);

    doc.setFontSize(8);

    doc.text("code :", 8, 105);
    doc.text("Nom :", 8, 110);
    doc.text("code :", 43, 105);
    doc.text("Nom :", 43, 110);
    doc.text("code :", 83, 105);
    doc.text("Nom :", 83, 110);
    doc.text("code :", 133, 105);
    doc.text("Nom :", 133, 110);
    doc.line(5, 115, 205, 115);
    doc.line(5, 125, 205, 125);

    doc.line(100, 125, 100, 150);
    doc.line(145, 125, 145, 150);

    doc.setFontSize(10);

    doc.text("Reglement Financier", 8, 130);
    doc.text("Montant en Dinars", 103, 130);
    doc.text("Montants en Devis", 148, 130);

    doc.text("Contrat Commercial", 40, 177);
    doc.text("Nature autorisation", 140, 177);

    doc.line(5, 133, 205, 133);

    doc.line(25, 133, 25, 140);
    doc.line(60, 133, 60, 140);

    doc.line(123, 133, 123, 140);

    doc.line(160, 133, 160, 140);
    doc.line(180, 133, 180, 140);

    doc.line(5, 150, 205, 150);
    doc.setFontSize(9);

    doc.text("Mode", 6, 138);
    doc.text("Delai", 26, 138);
    doc.text("Devis reglement", 61, 138);

    doc.text("PTFN", 101, 138);
    doc.text("FOB", 124, 138);

    doc.text("Devise", 146, 138);
    doc.text("PTFN", 161, 138);
    doc.text("FOB", 181, 138);

    doc.line(5, 160, 205, 160);
    doc.line(5, 170, 205, 170);

    doc.text("Mode livraison :", 10, 165);

    doc.line(5, 180, 205, 180);
    doc.line(105, 170, 105, 195);

    doc.line(45, 180, 45, 187);

    doc.line(150, 180, 150, 187);

    doc.text("Code", 6, 185);
    doc.text("Date Contrat", 46, 185);

    doc.text("Numero", 106, 185);
    doc.text("Libelle", 151, 185);

    doc.line(5, 195, 205, 195);

    doc.line(5, 205, 205, 205);

    doc.line(50, 205, 50, 212);
    doc.line(100, 205, 100, 212);
    doc.line(150, 205, 150, 212);

    doc.text("Nom  domciliation", 6, 210);
    doc.text("Date", 51, 210);

    doc.text("Nom  Declarant", 101, 210);
    doc.text("Date", 151, 210);

    doc.line(5, 220, 205, 220);
    doc.line(5, 230, 205, 230);
    doc.line(5, 240, 130, 240);
    doc.line(130, 220, 130, 290);

    doc.line(50, 230, 50, 290);

    doc.text("Nom", 6, 237);
    doc.text("Fichier", 51, 237);

    doc.setTextColor("#0000FF");
    doc.setFontSize(8);

    if (document.codeExportateur != null) {
      doc.text(document.codeExportateur + "", 8, 60);
    }
    if (document.nomPrenomExportateur != null) {
      doc.text(document.nomPrenomExportateur + "", 28, 60);
    }
    if (document.raisonSocialExportateur != null) {
      doc.text(document.raisonSocialExportateur + "", 63, 60);
    }

    if (document.adresseExportateur != null) {
      doc.text(document.adresseExportateur + "", 25, 78);
    }

    if (document.codeImportateur != null) {
      doc.text(document.codeImportateur + "", 108, 60);
    }
    if (document.nomPrenomImportateur != null) {
      doc.text(document.nomPrenomImportateur + "", 128, 60);
    }
    if (document.raisonSocialImportateur != null) {
      doc.text(document.raisonSocialImportateur + "", 163, 60);
    }
    if (document.adresseImportateur != null) {
      doc.text(document.adresseImportateur + "", 125, 78);
    }

    if (document.codePaysAchat != null) {
      doc.text(document.codePaysAchat + "", 16, 105);
    }
    if (document.codePaysProvenance != null) {
      doc.text(document.codePaysProvenance + "", 51, 105);
    }
    if (document.codePaysPremiereDestination != null) {
      doc.text(document.codePaysPremiereDestination + "", 91, 105);
    }
    if (document.codePaysDestinationDefinitive != null) {
      doc.text(document.codePaysDestinationDefinitive + "", 141, 105);
    }

    if (document.designationPaysAchat != null) {
      doc.text(document.designationPaysAchat + "", 16, 110);
    }
    if (document.designationPaysProvenance != null) {
      doc.text(document.designationPaysProvenance + "", 51, 110);
    }
    if (document.designationPaysPremiereDestination != null) {
      doc.text(document.designationPaysPremiereDestination + "", 91, 110);
    }
    if (document.designationPaysDestinationDefinitive != null) {
      doc.text(document.designationPaysDestinationDefinitive + "", 141, 110);
    }

    if (document.codeModeLivraison != null) {
      doc.text(document.codeModeLivraison + "", 8, 145);
    }
    if (document.delaiReglement != null) {
      doc.text(document.delaiReglement + "", 28, 145);
    }
    if (document.deviseReglement != null) {
      doc.text(document.deviseReglement + "", 63, 145);
    }
    if (document.ptfnDinars != null) {
      doc.text(document.ptfnDinars + "", 103, 145);
    }
    if (document.fcbDinars != null) {
      doc.text(document.fcbDinars + "", 126, 145);
    }
    if (document.codeDeviseMontant != null) {
      doc.text(document.codeDeviseMontant + "", 148, 145);
    }

    if (document.ptfnDevise != null) {
      doc.text(document.ptfnDevise + "", 163, 145);
    }

    if (document.fcbDevise != null) {
      doc.text(document.fcbDevise + "", 183, 145);
    }

    if (document.designationModeLivraison != null) {
      doc.text(document.designationModeLivraison + "", 20, 165);
    }

    if (document.dateContratCommercial != null) {
      doc.text(document.dateContratCommercial + "", 8, 190);
    }
    if (document.dateContratCommercial != null) {
      doc.text(document.dateContratCommercial + "", 48, 190);
    }

    if (document.codeNatureAutorisation != null) {
      doc.text(document.codeNatureAutorisation + "", 108, 190);
    }
    if (document.designationNatureAutorisation != null) {
      doc.text(document.designationNatureAutorisation + "", 153, 190);
    }

    //5

    if (document.designationOrganismeDomiciliation != null) {
      doc.text(document.designationOrganismeDomiciliation + "", 8, 215);
    }

    if (document.dateDomiciliation != null) {
      doc.text(document.dateDomiciliation + "", 53, 215);
    }

    if (document.nomDeclarant != null) {
      doc.text(document.nomDeclarant + "", 103, 215);
    }

    if (document.dateDeclarant != null) {
      doc.text(document.dateDeclarant + "", 153, 215);
    }

    doc.setFontSize(17);
    doc.text(document.etat, 137, 260);

    doc.addPage("", "p");
    doc.line(5, 5, 5, 290);
    doc.line(5, 5, 205, 5);
    doc.line(205, 5, 205, 290);
    doc.line(5, 290, 205, 290);

    doc.line(5, 15, 205, 15);

    doc.setFontSize(14);
    doc.setTextColor("#000000");
    doc.text("Articles", 95, 12);

    doc.line(5, 25, 205, 25);

    doc.line(25, 15, 25, 290);

    doc.line(55, 15, 55, 290);
    doc.line(125, 15, 125, 290);
    doc.line(145, 15, 145, 290);
    doc.line(165, 15, 165, 290);
    doc.line(185, 15, 185, 290);

    doc.setFontSize(9);
    doc.text("No.", 6, 20);
    doc.text("NGP", 26, 20);
    doc.text("Désignation", 56, 20);
    doc.text("PFN", 126, 20);
    doc.text("Code QCS", 146, 20);
    doc.text("QCS", 166, 20);
    doc.text("Pays Origine", 186, 20);

    let e = 30;

    doc.setTextColor("#0000FF");
    doc.setFontSize(8);

    for (let article of document.articleFdes) {
      doc.text(article.numeroArticle + "", 6, e);
      doc.text(article.uniteMesure + "", 26, e);
      doc.text(article.designation + "", 56, e);
      doc.text(article.prix + "", 126, e);
      doc.text(article.nomenclature + "", 146, e);
      doc.text(article.quantite + "", 166, e);
      doc.text(article.codePaysOrigine + "", 186, e);
      e = e + 7;
    }

    doc.save("FDE" + document.id + ".pdf");
  }

  fdesend: any;
  fdeidsend: any;
  opensend(content: any, fde: any) {
    this.fdesend = fde;
    this.fdeidsend = fde.id;

    this.open(content);
  }
  send(content: any) {
    this.dimision();
    this.fdesend.etat = "Envoyé"
    this.fdesend.reponseBc = true;
    this.commerceservice
      .update(this.fdesend)
      .subscribe((res: any) => {
        this.open(content);

        this.GetFde();
      });
  }

  document_src: any;
  openpdf(pdf: any, content: any) {
    this.document_src = pdf;
    setTimeout(() => {
      this.modalService.open(content, {
        size: "xl",
        windowClass: "dark-modal",
      });
    }, 100);
  }

  valeurcondition: String;
  id_serach: any;
  id_serach1: any = 0;
  valeur: any = '';
  attr: any = '';
  changeconditionitem(event: any) {
    if (event == undefined) {
      this.valeurcondition = '';
      this.id_serach1 = 0;
      this.GetFde();
    } else {
      this.id_serach = event.id;
      this.attr = event.code;
      this.id_serach1 = 1;
    }
  }

 
  search() {
    this.id_serach1 = 1;
    this.commerceservice
    .Search(this.attr, this.valeurcondition)
    .subscribe((res: any) => {
      this.ListFde_f = res.filter(
        (item: any, index: number, self: any[]) =>
          index === self.findIndex((t) => t.id === item.id)
      );;
      this.dataSource = new MatTableDataSource(this.ListFde_f);
      this.dataSource.paginator = this.paginator;
    });
    /*if (this.id_serach == 1) {
      this.commerceservice
        .Serach(this.attr, this.valeurcondition)
        .subscribe((res: any) => {
          this.listrecherchr = res;
        });
    } else if (this.id_serach == 2) {
      this.listrecherchr = this.caution_list.filter(
        (e) => e.vessel.vessel == this.valeurcondition
      );
    }

    if (this.id_serach != undefined) {
      setTimeout(() => {
        this.dataSource = new MatTableDataSource(this.listrecherchr);
        this.dataSource.paginator = this.paginator;
      }, 800);
    }*/
  }

  onchangedateend(event: any) { }

}
