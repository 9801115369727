import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css', '../auth/Login/login.component.css'],
})

export class LandingPageComponent implements OnInit {
  carouselImages: string[] = [
    '../../../assets/bgg.jpg',
    '../../../assets/air.png',
    '../../../assets/air2.png',
    '../../../assets/ter.png',
    '../../../assets/ter2.png',
    '../../../assets/team.png'
  ];
  currentIndex: number = 0;
  interval: any;
  display_nav_link: string = "no"

  constructor() { }
  ngOnInit(): void {
    this.startCarousel();
  }
  startCarousel() {
    this.interval = setInterval(() => {
      this.currentIndex = (this.currentIndex + 1) % this.carouselImages.length;
    }, 3000); // Change l'image toutes les 3 secondes
  }

  ngOnDestroy() {
    // Assurez-vous de nettoyer l'intervalle lorsque le composant est détruit
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
  prevImage() {
    this.currentIndex = (this.currentIndex - 1 + this.carouselImages.length) % this.carouselImages.length;
    clearInterval(this.interval);
    this.startCarousel();
  }

  nextImage() {
    this.currentIndex = (this.currentIndex + 1) % this.carouselImages.length;
    clearInterval(this.interval);
    this.startCarousel();
  }
  goToImage(index: number) {
    this.currentIndex = index;
    clearInterval(this.interval); // Arrêter l'automatisation lorsqu'un indicateur est cliqué
    this.startCarousel();
  }
}
