import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PremissionService } from 'src/Services/Premission/premission.service';
import { ManifestService } from '../../Cargo_manifest/Service/Manifest/manifest.service';
import { VesselCallInformationService } from '../../Shipping_Arrival/Service/VesselCall/vessel-call-information.service';
import { ShipdockingServiceService } from '../../Ship_docking/Service/shipdocking-service.service';
import Langue from '../../../../../assets/langue.json';

import { ChartOptions } from 'chart.js';

@Component({
  selector: 'app-dashboard-logistic',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  langue: any;
  direction: any;
  languef: any;
  langue_list: any[] = Langue;
  constructor(
    private router: Router,
    private vesselcalApi: VesselCallInformationService,
    private cargoManifestApi: ManifestService,
    private shipdockingApi: ShipdockingServiceService,
    private PremisionApi: PremissionService
  ) {
    if (localStorage.getItem('langue') != null) {
      this.langue = localStorage.getItem('langue') + '';
    } else {
      this.langue = 'AR';
    }

    if (this.langue == 'AR') {
      this.direction = 'rtl';
      this.languef = this.langue_list[0]['AR'].dashboard;
    }

    if (this.langue == 'EN') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['EN'].dashboard;
    }

    if (this.langue == 'FR') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['FR'].dashboard;
    }
  }

  ShippingArrival_etat: boolean = false;
  Cargo_Manifest_etat: boolean = false;
  Shipping_Departure_etat: boolean = false;
  Ship_Docking_etat: boolean = false;
  ngOnInit(): void {
    this.ShippingArrival_etat = this.PremisionApi.GetShipArrival();
    this.Ship_Docking_etat = this.PremisionApi.GetShipDocking();
    this.Shipping_Departure_etat = this.PremisionApi.GetShipDeparture();
    this.Cargo_Manifest_etat = this.PremisionApi.GetShipCargo();
    this.Shipping_arrival();
    this.Shipping_departure();
    this.Cargo_Manifest();
    this.Ship_Docking();


    setTimeout(() => {
      this.skeleton = false
    }, 3000);
  }
  Go_list(route: any) {
    this.route(route);
    this.router.navigateByUrl('Logistic/liste');
  }
  shippignArrival_save: any = 0;
  shippignArrival_save_moy: any = 0;
  shippingArrival_forconf: any = 0;
  shippingArrival_forconf_moy: any = 0;
  shippingArrival_accept: any = 0;
  shippingArrival_accept_moy: any = 0;
  shippingArrival_refuse: any = 0;
  shippingArrival_refuse_moy: any = 0;
  shippingArrival_total: any = 0;
  Shipping_arrival_List: any[] = [];

  public pieChartOptions: ChartOptions<'pie'> = {
    responsive: false,
  };
  public pieChartLabels = [' Save', 'For confirmation', 'Accept', 'Refus'];
  public pieChartLegend = true;
  public pieChartPlugins = [];

  public pieChartDatasets_SA = [{
    data: [0, 0, 0, 0]
  }];
  public pieChartDatasets_SDP = [{
    data: [0, 0, 0, 0]
  }];
  public pieChartDatasets_SD = [{
    data: [0, 0, 0, 0]
  }];
  public pieChartDatasets_CM = [{
    data: [0, 0, 0, 0]
  }];


  Shipping_arrival() {
    this.vesselcalApi.GetByType('Ship_Arrival').subscribe((res: any) => {
      this.Shipping_arrival_List = res;
      this.shippingArrival_total = this.Shipping_arrival_List.length;

      if (this.shippingArrival_total > 0) {
        this.shippignArrival_save = this.Shipping_arrival_List.filter(
          (e: any) => e.etat == 'Save'
        ).length;

        this.shippignArrival_save_moy = (this.shippignArrival_save / this.shippingArrival_total) * 100;
        this.shippignArrival_save_moy = this.shippignArrival_save_moy.toFixed(2);

        this.shippingArrival_forconf = this.Shipping_arrival_List.filter(
          (e: any) => e.etat == 'For confirmation'
        ).length;

        this.shippingArrival_forconf_moy = (this.shippingArrival_forconf / this.shippingArrival_total) * 100;
        this.shippingArrival_forconf_moy = this.shippingArrival_forconf_moy.toFixed(2);

        this.shippingArrival_accept = this.Shipping_arrival_List.filter(
          (e: any) => e.etat == 'Accept'
        ).length;

        this.shippingArrival_accept_moy = (this.shippingArrival_accept / this.shippingArrival_total) * 100;
        this.shippingArrival_accept_moy = this.shippingArrival_accept_moy.toFixed(2);



        this.shippingArrival_refuse = this.Shipping_arrival_List.filter(
          (e: any) => e.etat == 'Reject'
        ).length;

        this.shippingArrival_refuse_moy = (this.shippingArrival_refuse / this.shippingArrival_total) * 100;
        this.shippingArrival_refuse_moy = this.shippingArrival_refuse_moy.toFixed(2);

        this.pieChartDatasets_SA = [{
          data: [this.shippignArrival_save_moy, this.shippingArrival_forconf_moy, this.shippingArrival_accept_moy, this.shippingArrival_refuse_moy]
        }];


      }





      setTimeout(() => {
        this.skeleton = false
      }, 1000);
    });
  }

  selectedTypeSa = 1
  selectedTypeSDP = 1
  selectedTypeCM = 1
  selectedTypeSD = 1

  shippignDeparture_save: any = 0;
  shippignDeparture_save_moy: any = 0;
  shippingDeparture_forconf: any = 0;
  shippingDeparture_forconf_moy: any = 0;
  shippingDeparture_accept: any = 0;
  shippingDeparture_accept_moy: any = 0;
  shippingDeparture_refuse: any = 0;
  shippingDeparture_refuse_moy: any = 0;
  shippingDeparture_total: any = 0;
  Shipping_departure_List: any[] = [];
  Shipping_departure() {
    this.vesselcalApi.GetByType('Ship_Departure').subscribe((res: any) => {
      this.Shipping_departure_List = res;
      this.shippingDeparture_total = this.Shipping_departure_List.length;
      if (this.shippingDeparture_total > 0) {
        this.shippignDeparture_save = this.Shipping_departure_List.filter(
          (e: any) => e.etat == 'Save'
        ).length;

        this.shippignDeparture_save_moy = (this.shippignDeparture_save / this.shippingDeparture_total) * 100;
        this.shippignDeparture_save_moy = this.shippignDeparture_save_moy.toFixed(2);



        this.shippingDeparture_forconf = this.Shipping_departure_List.filter(
          (e: any) => e.etat == 'For confirmation'
        ).length;

        this.shippingDeparture_forconf_moy = (this.shippingDeparture_forconf / this.shippingDeparture_total) * 100;
        this.shippingDeparture_forconf_moy = this.shippingDeparture_forconf_moy.toFixed(2);


        this.shippingDeparture_accept = this.Shipping_departure_List.filter(
          (e: any) => e.etat == 'Accept'
        ).length;

        this.shippingDeparture_accept_moy = (this.shippingDeparture_accept / this.shippingDeparture_total) * 100;
        this.shippingDeparture_accept_moy = this.shippingDeparture_accept_moy.toFixed(2);



        this.shippingDeparture_refuse = this.Shipping_departure_List.filter(
          (e: any) => e.etat == 'Reject'
        ).length;

        this.shippingDeparture_refuse_moy = (this.shippingDeparture_refuse / this.shippingDeparture_total) * 100;
        this.shippingDeparture_refuse_moy = this.shippingDeparture_refuse_moy.toFixed(2);


        this.pieChartDatasets_SDP = [{
          data: [this.shippignDeparture_save_moy, this.shippingDeparture_forconf_moy, this.shippingDeparture_accept_moy, this.shippingDeparture_refuse_moy]
        }];

        setTimeout(() => {
          this.skeleton = false
        }, 1000);

      }


    });
  }
  Cargo_Manifest_save: any = 0;
  Cargo_Manifest_save_moy: any = 0;
  Cargo_Manifest_forconf: any = 0;
  Cargo_Manifest_forconf_moy: any = 0;
  Cargo_Manifest_accept: any = 0;
  Cargo_Manifest_accept_moy: any = 0;
  Cargo_Manifest_refuse: any = 0;
  Cargo_Manifest_refuse_moy: any = 0;
  Cargo_Manifest_total: any = 0;
  Cargo_Manifest_List: any[] = [];
  Cargo_Manifest() {
    this.cargoManifestApi.GetByteam().subscribe((res: any) => {
      this.Cargo_Manifest_List = res;
      this.Cargo_Manifest_total = this.Cargo_Manifest_List.length;

      if (this.Cargo_Manifest_total > 0) {
        this.Cargo_Manifest_save = this.Cargo_Manifest_List.filter(
          (e: any) => e.etat == 'Save'
        ).length;

        this.Cargo_Manifest_save_moy = (this.Cargo_Manifest_save / this.Cargo_Manifest_total) * 100;
        this.Cargo_Manifest_save_moy = this.Cargo_Manifest_save_moy.toFixed(2);

        this.Cargo_Manifest_forconf = this.Cargo_Manifest_List.filter(
          (e: any) => e.etat == 'For confirmation'
        ).length;

        this.Cargo_Manifest_forconf_moy = (this.Cargo_Manifest_forconf / this.Cargo_Manifest_total) * 100;
        this.Cargo_Manifest_forconf_moy = this.Cargo_Manifest_forconf_moy.toFixed(2);

        this.Cargo_Manifest_accept = this.Cargo_Manifest_List.filter(
          (e: any) => e.etat == 'Confirm'
        ).length;

        this.Cargo_Manifest_accept_moy = (this.Cargo_Manifest_accept / this.Cargo_Manifest_total) * 100;
        this.Cargo_Manifest_accept_moy = this.Cargo_Manifest_accept_moy.toFixed(2);

        this.Cargo_Manifest_refuse = this.Cargo_Manifest_List.filter(
          (e: any) => e.etat == 'Reject'
        ).length;

        this.Cargo_Manifest_refuse_moy = (this.Cargo_Manifest_refuse / this.Cargo_Manifest_total) * 100;
        this.Cargo_Manifest_refuse_moy = this.Cargo_Manifest_refuse_moy.toFixed(2);


        this.pieChartDatasets_CM = [{
          data: [this.Cargo_Manifest_save_moy, this.Cargo_Manifest_forconf_moy, this.Cargo_Manifest_accept_moy, this.Cargo_Manifest_refuse_moy]
        }];

        setTimeout(() => {
          this.skeleton = false
        }, 1000);

      }

    });
  }
  Ship_Docking_save: any = 0;
  Ship_Docking_save_moy: any = 0;
  Ship_Docking_forconf: any = 0;
  Ship_Docking_forconf_moy: any = 0;
  Ship_Docking_accept: any = 0;
  Ship_Docking_accept_moy: any = 0;
  Ship_Docking_refuse: any = 0;
  Ship_Docking_refuse_moy: any = 0;
  Ship_Docking_total: any = 0;
  Ship_Docking_List: any[] = [];
  Ship_Docking() {
    this.shipdockingApi.GetByOrg__agent().subscribe((res: any) => {
      this.Ship_Docking_List = res;
      this.Ship_Docking_total = this.Ship_Docking_List.length;
      if (this.Ship_Docking_total > 0) {
        this.Ship_Docking_save = this.Ship_Docking_List.filter(
          (e: any) => e.etat == 'Save'
        ).length;

        this.Ship_Docking_save_moy = (this.Ship_Docking_save / this.Ship_Docking_total) * 100;
        this.Ship_Docking_save_moy = this.Ship_Docking_save_moy.toFixed(2);



        this.Ship_Docking_forconf = this.Ship_Docking_List.filter(
          (e: any) => e.etat == 'For confirmation'
        ).length;

        this.Ship_Docking_forconf_moy = (this.Ship_Docking_forconf / this.Ship_Docking_total) * 100;
        this.Ship_Docking_forconf_moy = this.Ship_Docking_forconf_moy.toFixed(2);

        this.Ship_Docking_accept = this.Ship_Docking_List.filter(
          (e: any) => e.etat == 'Accept'
        ).length;

        this.Ship_Docking_accept_moy = (this.Ship_Docking_accept / this.Ship_Docking_total) * 100;
        this.Ship_Docking_accept_moy = this.Ship_Docking_accept_moy.toFixed(2);

        this.Ship_Docking_refuse = this.Ship_Docking_List.filter(
          (e: any) => e.etat == 'Reject'
        ).length;

        this.Ship_Docking_refuse_moy = (this.Ship_Docking_refuse / this.Ship_Docking_total) * 100;
        this.Ship_Docking_refuse_moy = this.Ship_Docking_refuse_moy.toFixed(2);

        this.pieChartDatasets_SD = [{
          data: [this.Ship_Docking_save_moy, this.Ship_Docking_forconf_moy, this.Ship_Docking_accept_moy, this.Ship_Docking_refuse_moy]
        }];

        setTimeout(() => {
          this.skeleton = false
        }, 1000);


      }



    });
  }

  route(id_route: any) {
    if (id_route == 0) {
      localStorage.setItem('redirecturl', id_route);
    }

    if (id_route == 1) {
      if (this.ShippingArrival_etat == false) {
        localStorage.setItem('redirecturl', '0');
      } else {
        localStorage.setItem('redirecturl', '1');
      }
    }

    if (id_route == 2) {
      if (
        this.ShippingArrival_etat == false &&
        this.Shipping_Departure_etat == false
      ) {
        localStorage.setItem('redirecturl', '0');
      } else if (
        this.ShippingArrival_etat == false ||
        this.Shipping_Departure_etat == false
      ) {
        localStorage.setItem('redirecturl', '1');
      } else {
        localStorage.setItem('redirecturl', '2');
      }
    }

    if (id_route == 3) {
      if (
        this.ShippingArrival_etat == false &&
        this.Shipping_Departure_etat == false &&
        this.Ship_Docking_etat == false
      ) {
        localStorage.setItem('redirecturl', '0');
      } else if (
        this.ShippingArrival_etat == false &&
        (this.Shipping_Departure_etat == false ||
          this.Ship_Docking_etat == false)
      ) {
        localStorage.setItem('redirecturl', '1');
      } else if (
        this.Shipping_Departure_etat == false &&
        (this.ShippingArrival_etat == false || this.Ship_Docking_etat == false)
      ) {
        localStorage.setItem('redirecturl', '1');
      } else if (
        this.Ship_Docking_etat == false &&
        (this.ShippingArrival_etat == false ||
          this.Shipping_Departure_etat == false)
      ) {
        localStorage.setItem('redirecturl', '1');
      } else if (
        this.ShippingArrival_etat == false ||
        this.Shipping_Departure_etat == false ||
        this.Ship_Docking_etat == false
      ) {
        localStorage.setItem('redirecturl', '2');
      } else {
        localStorage.setItem('redirecturl', '3');
      }
    }
  }


  skeleton: boolean = true


}
