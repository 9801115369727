import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/Services/User/user.service';
import Langue from '../../../assets/langue.json';
import { OrganisationService } from 'src/Services/Organisation/organisation.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-affectation',
  templateUrl: './affectation.component.html',
  styleUrls: ['./affectation.component.css'],
})
export class AffectationElogisticComponent implements OnInit {
  langue: any;
  direction: any;
  languef: any;
  languea: any;
  langue_list: any[] = Langue;
  constructor(
    private organisationService: OrganisationService,
    private userService: UserService,
    private spinner: NgxSpinnerService
  ) {
    this.selectedIndex = localStorage.getItem('redirecturl');

    if (localStorage.getItem('langue') != null) {
      this.langue = localStorage.getItem('langue') + '';
    } else {
      this.langue = 'AR';
    }

    if (this.langue == 'AR') {
      this.direction = 'rtl';
      this.languef = this.langue_list[0]['AR'].header;
    }

    if (this.langue == 'EN') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['EN'].header;
    }

    if (this.langue == 'FR') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['FR'].header;
    }
    this.spinner.show();
  }
  selectedIndex: any = 0;

  public ngOnInit(): void {
    this.GetIdUser();
  }

  logistic_etat: boolean = false;
  trade_etat: boolean = false;
  customs_etat: boolean = false;
  trading_customs_sc: boolean = false;
  trading_trade_sc: boolean = false;
  trading_logistic_sc: boolean = false;
  UserId: any;
  profil: any;
  Getorganisation_send(id: any) {
    this.organisationService.Get_org_byID(id).subscribe((res: any) => {
      this.profil = res['profil'];

      this.logistic_etat =
        this.profil == 'Shipping_agent' ||
        this.profil == 'Port' ||
        this.profil == 'Customs';
      this.trade_etat =
        this.profil == 'Bank' ||
        this.profil == 'Trading_Company' ||
        this.profil == 'Chamber_of_Commerce';

      this.customs_etat =
        this.profil == 'Trading_Company' ||
        this.profil == 'Customs' ||
        this.profil == 'FDCC';

      if (this.profil == 'Trading_Company') {
        this.trading_customs_sc = true;
        this.trading_trade_sc = false;
      }

      if (this.profil == 'Customs') {
        this.trading_customs_sc = false;
        this.trading_logistic_sc = true;
      }

      if (this.profil == 'FDCC') {
        this.trading_customs_sc = true;
      }

      if (this.profil == 'Shipping_agent' || 'Port') {
        this.trading_logistic_sc = true;
      }

      if (this.profil == 'Bank') {
        this.trading_trade_sc = true;
      }

      if (this.profil == 'Chamber_of_Commerce') {
        this.trading_trade_sc = true;
      }
      this.spinner.hide();
    });
  }

  GetIdUser() {
    this.userService.Getuserbyusername().subscribe((res: any) => {
      this.UserId = res.id;
      this.Getorganisation_send(this.UserId);
    });
  }

  onchangetrade(event: any) {
    if (event.value == '1') {
      this.trading_customs_sc = true;
      this.trading_trade_sc = false;
    } else {
      this.trading_trade_sc = true;
      this.trading_customs_sc = false;
    }
  }

  onchangecustoms(event: any) {
    if (event.value == '1') {
      this.trading_customs_sc = false;
      this.trading_logistic_sc = true;
    } else {
      this.trading_customs_sc = true;
      this.trading_logistic_sc = false;
    }
  }
}
