import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PremissionService } from 'src/Services/Premission/premission.service';
import { UserService } from 'src/Services/User/user.service';
import Langue from '../../../../../assets/langue.json';
@Component({
  selector: 'app-page-accueil-trade',
  templateUrl: './page-accueil.component.html',
  styleUrls: ['./page-accueil.component.css'],
})
export class PageAccueilComponent implements OnInit {
  @Input() profil: string;
  langue: any;
  direction: any;
  languef: any;
  langue_list: any[] = Langue;
  selectedIndex: any = 0;

  constructor(
    private userauth: UserService,
    private premissionService: PremissionService,
    private route: Router
  ) {
    console.log("profil", this.profil)

    this.Getroles();
    if (localStorage.getItem('langue') != null) {
      this.langue = localStorage.getItem('langue') + '';
    } else {
      this.langue = 'AR';
    }

    if (this.langue == 'AR') {
      this.direction = 'rtl';
      this.languef = this.langue_list[0]['AR'].header;
    }

    if (this.langue == 'EN') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['EN'].header;
    }
    if (this.langue == 'FR') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['FR'].header;
    }

    this.selectedIndex = localStorage.getItem('redirecturl_t');

  }

  ngOnInit(): void {
    let profilco =
      this.profil == 'Chamber_of_Commerce' || this.profil == 'Trading_Company';
    let profillc = this.profil == 'Banque' || this.profil == 'Trading_Company';

    /*this.CO_etat = this.premissionService.GetCertificatOrigin() || profilco;
    this.LC_etat = this.premissionService.GetCreditLetter() || profillc;
    this.SG_etat = this.premissionService.GetShippingGarante() || profillc;*/
    this.CO_etat = JSON.parse(localStorage.getItem('reponse_CO') || 'false') || profilco;
    this.LC_etat = JSON.parse(localStorage.getItem('reponse_LC') || 'false') || profillc;
    this.SG_etat = JSON.parse(localStorage.getItem('reponse_SG') || 'false') || profillc;

    let etat = !this.CO_etat && !this.LC_etat && !this.SG_etat

    if (etat) {
      this.route.navigateByUrl('/Error/notfound');
    }
  }
  
  RoleTrading: boolean = false;

  Getroles() {
    if (this.userauth.ValidRole('Trading Company')) {

      this.RoleTrading = true;
    }
  }

  SG_etat: boolean = false;
  LC_etat: boolean = false;
  CO_etat: boolean = false;

  changesc(event: any) {
    localStorage.setItem('redirecturl_t', event.index);
  }
}
