<div>
  <mat-tab-group
    mat-align-tabs="center"
    style="width: 100%; min-height: 600px; background-color: #fdfdfd"
    (selectedTabChange)="changesc($event)"
    [selectedIndex]="selectedIndex"
  >
    <mat-tab *ngIf="LC_etat" label="{{ languef.lc }}">
      <app-liste_credit [profil]="profil"></app-liste_credit>
    </mat-tab>
    <mat-tab *ngIf="CO_etat" label="{{ languef.co }}">
      <app-liste-certificat [profil]="profil"></app-liste-certificat>
    </mat-tab>
    <mat-tab *ngIf="SG_etat" label="{{ languef.sg }}"
      ><app-liste-shipping [profil]="profil"></app-liste-shipping>
    </mat-tab>
  </mat-tab-group>
</div>
