<link
  rel="stylesheet"
  href="https://use.fontawesome.com/releases/v5.5.0/css/all.css"
  integrity="sha384-B4dIYHKNBt8Bc12p+WXckhzcICo0wtJAoU8YZTY5qE0Id1GSseTk6S+L3BlXeVIU"
  crossorigin="anonymous"
/>
<form
  [formGroup]="Form1"
  autocomplete="off"
  (ngSubmit)="Save(content3)"
  novalidate
  form
  style="padding-left: 2%"
  [ngStyle]="{ direction: direction }"
>
  <div class="c1">
    <div class="Trac-2081"></div>

    <div style="text-align: center">
      <h2 class="titre-page">{{ languee.titre1 }}</h2>
      <div class="about-border"></div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-2 divmenu">
      <div class="bloc_menu">
        <div>
          <mat-icon class="icon_menu">directions_boat</mat-icon>
        </div>

        <div
          (click)="changebloc(1)"
          class="titre_bloc"
          [ngStyle]="{
            'background-color': BlocB1 ? '#d4f1ff' : '#ffffff'
          }"
        >
          <div>
            <label style="cursor: pointer" class="vessel_call_information"
              >{{ languee.vesselcallinformation }}
            </label>
          </div>
          <div style="padding: 2%">
            <mat-icon
              *ngIf="
                Form1.valid &&
                selectedportcall != undefined &&
                selectedportcall != null
              "
              class="icon-menu"
              >check_circle</mat-icon
            >
            <mat-icon
              *ngIf="
                (Form1.invalid ||
                  selectedportcall == undefined ||
                  selectedportcall == null) &&
                BlocB1 == false
              "
              class="icon-menu1"
              >new_releases</mat-icon
            >
            <mat-icon
              *ngIf="
                (Form1.invalid ||
                  selectedportcall == undefined ||
                  selectedportcall == null) &&
                BlocB1
              "
              class="icon-menu1"
              >edit</mat-icon
            >
          </div>
        </div>
      </div>

      <div class="line">
        <div class="vl"></div>
      </div>

      <div class="bloc_menu">
        <div>
          <mat-icon class="icon_menu">my_location</mat-icon>
        </div>
        <div
          (click)="changebloc(2)"
          class="titre_bloc"
          [ngStyle]="{
            'background-color': BlocB2 ? '#d4f1ff' : '#ffffff'
          }"
        >
          <label style="cursor: pointer" class="vessel_call_information"
            >{{ languee.visitpurpose }}
          </label>

          <div style="padding: 2%">
            <mat-icon *ngIf="VisitPurposelist.length > 0" class="icon-menu"
              >check_circle</mat-icon
            >
            <mat-icon
              *ngIf="VisitPurposelist.length == 0 && BlocB2 == false"
              class="icon-menu1"
              >new_releases</mat-icon
            >
            <mat-icon
              *ngIf="VisitPurposelist.length == 0 && BlocB2"
              class="icon-menu1"
              >edit</mat-icon
            >
          </div>
        </div>
      </div>
      <div class="line">
        <div class="vl"></div>
      </div>
      <div class="bloc_menu">
        <div>
          <i class="fas fa-box-open iconcargo"></i>
        </div>
        <div
          (click)="changebloc(3)"
          class="titre_bloc"
          [ngStyle]="{
            'background-color': BlocB3 ? '#d4f1ff' : '#ffffff'
          }"
        >
          <label style="cursor: pointer" class="vessel_call_information">{{
            languee.cargosummary
          }}</label>

          <div style="padding: 2%">
            <mat-icon *ngIf="CargoSummarylist.length > 0" class="icon-menu"
              >check_circle</mat-icon
            >
            <mat-icon
              *ngIf="CargoSummarylist.length == 0 && BlocB3 == false"
              class="icon-menu1"
              >new_releases</mat-icon
            >
            <mat-icon
              *ngIf="CargoSummarylist.length == 0 && BlocB3"
              class="icon-menu1"
              >edit</mat-icon
            >
          </div>
        </div>
      </div>
      <div class="line">
        <div class="vl"></div>
      </div>

      <div class="bloc_menu">
        <div>
          <mat-icon class="icon_menu">person</mat-icon>
        </div>
        <div
          (click)="changebloc(4)"
          class="titre_bloc"
          [ngStyle]="{
            'background-color': BlocB4 ? '#d4f1ff' : '#ffffff'
          }"
        >
          <label style="cursor: pointer" class="vessel_call_information">{{
            languee.passengersummary
          }}</label>

          <div style="padding: 2%">
            <mat-icon *ngIf="PassengerSummarylist.length > 0" class="icon-menu"
              >check_circle</mat-icon
            >
            <mat-icon
              *ngIf="PassengerSummarylist.length == 0 && BlocB4 == false"
              class="icon-menu1"
              >new_releases</mat-icon
            >
            <mat-icon
              *ngIf="PassengerSummarylist.length == 0 && BlocB4"
              class="icon-menu1"
              >edit</mat-icon
            >
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-8 blocform" [ngStyle]="{ direction: direction }">
      <div
        [ngStyle]="{
          display: BlocB1 ? 'block' : 'none'
        }"
        class="div3"
      >
        <div class="header-border" style="min-height: 600px">
          <h2 class="heading">
            <div style="display: flex">
              <div class="padding_class">
                <h1>
                  <div
                    (click)="ChangeForm(form1)"
                    class="div1"
                    [ngStyle]="{ 'background-color': btn_backround1 }"
                  >
                    <span class="header_titre" [ngStyle]="{ color: color1 }">{{
                      languee.generalinformation
                    }}</span>
                  </div>
                </h1>
              </div>
              <div style="width: 50%">
                <h1>
                  <div
                    (click)="ChangeForm(form2)"
                    class="div1"
                    [ngStyle]="{ 'background-color': btn_backround2 }"
                  >
                    <span class="header_titre" [ngStyle]="{ color: color2 }">{{
                      languee.shippingdetail
                    }}</span>
                  </div>
                </h1>
              </div>
            </div>
          </h2>

          <div style="min-height: 600px; padding: 2% 2% 2% 2%">
            <div [ngStyle]="{ display: blockform_1 }" style="min-height: 440px">
              <div class="row" style="padding: 1%">
                <div class="col-lg-6">
                  <mat-form-field class="full-width">
                    <input
                      matInput
                      (keyup)="style($event)"
                      placeholder="{{ languee.voyageno }}"
                      formControlName="VoyageNo"
                      maxlength="17"
                      required
                    />
                  </mat-form-field>
                </div>

                <div class="col-lg-6">
                  <mat-form-field class="full-width">
                    <input
                      matInput
                      placeholder="{{ languee.vesselname }}"
                      formControlName="VesselName"
                      maxlength="40"
                      required
                    />
                  </mat-form-field>
                </div>
                <div class="col-lg-6">
                  <mat-form-field class="full-width">
                    <input
                      type="text"
                      matInput
                      placeholder="{{ languee.vesseltype }}"
                      formControlName="VesselType"
                      maxlength="30"
                      required
                    />
                  </mat-form-field>
                </div>
                <div class="col-lg-6">
                  <mat-form-field class="full-width">
                    <input
                      matInput
                      type="number"
                      min="1950"
                      max="2022"
                      placeholder="{{ languee.vesselbuildyear }}"
                      formControlName="VesselbuildYear"
                      required
                    />
                  </mat-form-field>
                </div>
                <div class="col-lg-6">
                  <mat-form-field class="full-width">
                    <input
                      matInput
                      type="text"
                      maxlength="30"
                      placeholder="{{ languee.vesselregport }}"
                      formControlName="VesselRegPort"
                    />
                  </mat-form-field>
                </div>
                <div class="col-lg-6">
                  <mat-form-field class="full-width">
                    <input
                      matInput
                      type="number"
                      min="0"
                      max="1000"
                      placeholder="{{ languee.vessellenght }}"
                      formControlName="VesselLength"
                      required
                    />
                  </mat-form-field>
                </div>
                <div class="col-lg-6">
                  <mat-form-field class="full-width">
                    <input
                      matInput
                      type="number"
                      min="0"
                      placeholder="{{ languee.vesselgrossweigh }}"
                      formControlName="VesselGrossWeight"
                      required
                    />
                  </mat-form-field>
                </div>
                <div class="col-lg-6">
                  <mat-form-field class="full-width">
                    <input
                      matInput
                      type="number"
                      min="0"
                      placeholder="{{ languee.vesselnetweight }}"
                      formControlName="VesselNetWeight"
                      required
                    />
                  </mat-form-field>
                </div>
                <div class="col-lg-6">
                  <mat-form-field class="full-width">
                    <input
                      matInput
                      type="number"
                      min="0"
                      placeholder="{{ languee.vesseldraft }}"
                      formControlName="VesselDraft"
                      required
                    />
                  </mat-form-field>
                </div>
                <div class="col-lg-6">
                  <mat-form-field class="example-full-width">
                    <input
                      type="text"
                      required
                      placeholder="{{ languee.callsigntype }}"
                      aria-label="Call Sign Type"
                      matInput
                      [formControl]="CallSignType"
                      [matAutocomplete]="auto"
                    />
                    <mat-autocomplete
                      #auto="matAutocomplete"
                      [displayWith]="displayFn"
                    >
                      <mat-option
                        *ngFor="let option of options"
                        [value]="option"
                      >
                        {{ option.name }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
                <div class="col-lg-6">
                  <mat-form-field class="full-width">
                    <input
                      matInput
                      type="text"
                      maxlength="20"
                      placeholder="{{ languee.ownercode }}"
                      formControlName="OwnerCode"
                      required
                    />
                  </mat-form-field>
                </div>
                <div class="col-lg-6">
                  <mat-form-field class="full-width">
                    <input
                      matInput
                      type="text"
                      maxlength="40"
                      placeholder="{{ languee.ownername }}"
                      formControlName="OwnerName"
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div [ngStyle]="{ display: blockform_2 }" style="min-height: 440px">
              <div class="row" style="padding-top: 1%">
                <div class="col-lg-6">
                  <mat-select-country
                    style="color: #1d3a66"
                    placeHolder="{{ languee.country }}"
                    (onCountrySelected)="onCountrySelected($event)"
                    >>
                  </mat-select-country>
                </div>

                <div class="col-lg-6">
                  <mat-form-field class="full-width">
                    <input
                      matInput
                      required
                      type="text"
                      maxlength="30"
                      placeholder="{{ languee.callsign }}"
                      formControlName="CallSign"
                    />
                  </mat-form-field>
                </div>

                <div class="col-lg-6">
                  <mat-form-field class="full-width">
                    <input
                      readonly
                      matInput
                      required
                      type="text"
                      maxlength="30"
                      placeholder="{{ languee.shippingagentcode }}"
                      formControlName="ShippingAgentCode"
                    />
                  </mat-form-field>
                </div>

                <div class="col-lg-6">
                  <mat-form-field class="full-width">
                    <input
                      readonly
                      matInput
                      type="text"
                      maxlength="40"
                      placeholder="{{ languee.shippingagent }}"
                      formControlName="ShippingAgent"
                    />
                  </mat-form-field>
                </div>
                <div class="col-lg-6">
                  <mat-form-field class="full-width">
                    <input
                      matInput
                      required
                      maxlength="40"
                      type="text"
                      placeholder="{{ languee.shipmaster }}"
                      formControlName="ShipMaster"
                    />
                  </mat-form-field>
                </div>

                <div class="col-lg-6">
                  <mat-form-field>
                    <input
                      matInput
                      [matDatepicker]="picker"
                      [formControl]="dateETA"
                      [min]="date_start"
                      [max]="date_ETD"
                      placeholder="{{ languee.eta }}"
                      required
                      (dateInput)="onchangedateETA($event.value)"
                      style="color: #1d3a66"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div>

                <div class="col-lg-6">
                  <mat-form-field>
                    <input
                      matInput
                      [matDatepicker]="picker1"
                      placeholder="{{ languee.etd }}"
                      [min]="date_ETA"
                      [formControl]="dateETD"
                      (dateInput)="onchangedateETD($event.value)"
                      style="color: #1d3a66"
                      required
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker1"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col-lg-6">
                  <mat-form-field class="full-width">
                    <input
                      matInput
                      placeholder="{{ languee.preferreddock }}"
                      formControlName="PreferredDock"
                      required
                    />
                  </mat-form-field>
                </div>
                <div class="col-lg-6" style="padding-top: 3%">
                  <label>{{ languee.dangerousgoods }}</label>
                  <mat-radio-group
                    style="padding: 0% 2% 0% 2%"
                    aria-label=" languee.dangerousgoods "
                    (change)="DangerousGoodsGange($event)"
                  >
                    <mat-radio-button
                      style="padding: 0% 2% 0% 2%"
                      value="yes"
                      style="color: black"
                      checked="descriptionofgoods == 'Y'"
                      >{{ languee.yes }}
                    </mat-radio-button>
                    <mat-radio-button
                      checked="descriptionofgoods == 'N'"
                      value="no"
                      style="padding: 0% 2% 0% 2%; color: black"
                      >{{ languee.no }}
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="col-lg-6">
                  <ng-select
                    formControlName="PreviousPortPrevious"
                    [items]="portlist"
                    (change)="changeportPrevious($event)"
                    bindLabel="name"
                    placeholder="{{ languee.previousport }}"
                    [(ngModel)]="selectedportPrevious"
                  >
                  </ng-select>
                </div>
                <div class="col-lg-6">
                  <ng-select
                    required
                    formControlName="PortofCall"
                    [items]="portlistofcall"
                    (change)="changeportcall($event)"
                    bindLabel="name"
                    placeholder="{{ languee.portofcall }}*"
                    [(ngModel)]="selectedportcall"
                  >
                  </ng-select>
                  <li
                    style="color: rgb(255, 0, 0)"
                    *ngIf="selectedportcall == null && Form1.valid"
                  >
                    {{ languet.required }}
                  </li>
                </div>
                <div class="col-lg-6">
                  <ng-select
                    formControlName="portNext"
                    [items]="portlist"
                    (change)="changeportNext($event)"
                    bindLabel="name"
                    placeholder="{{ languee.nextport }}"
                    [(ngModel)]="selectedportNext"
                  >
                  </ng-select>
                </div>
              </div>
            </div>

            <div class="container text-right nextbtn">
              <button type="button" (click)="Next()" class="btn button1">
                <span class="next"> {{ languee.next }} </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        [ngStyle]="{
          display: BlocB2 ? 'block' : 'none'
        }"
      >
        <div
          *ngIf="VisitPurposelist.length == 0"
          style="text-align: center; display: block"
        >
          <div>
            <img
              src="./../../../../assets/icon.png"
              style="width: 285px; height: 205px"
            />
          </div>
          <div style="padding-top: 2%">
            <span class="span1"> {{ languee.emptylist }} ! </span>
          </div>
          <div style="padding-top: 3%">
            <span class="span2"> {{ languee.textmessage1 }} </span>
          </div>
          <div style="padding-top: 2%">
            <button type="button" class="btn button3" (click)="open(content)">
              <div style="display: flex">
                <div>
                  <mat-icon
                    style="color: #ffffff; font-size: 30px; font-weight: 900"
                    >add</mat-icon
                  >
                </div>
                <div style="padding-top: 3px">
                  <span class="add"> {{ languee.new }} </span>
                </div>
              </div>
            </button>
          </div>
        </div>
        <div *ngIf="VisitPurposelist.length > 0" style="min-height: 600px">
          <div class="col-lg-2" style="padding-top: 3%">
            <button type="button" class="btn button3" (click)="open(content)">
              <div style="display: flex">
                <div>
                  <mat-icon
                    style="color: #ffffff; font-size: 30px; font-weight: 900"
                    >add</mat-icon
                  >
                </div>
                <div style="padding-top: 3px">
                  <span class="add"> {{ languee.new }} </span>
                </div>
              </div>
            </button>
          </div>
          <div class="row" style="padding: 3%; min-height: 530px">
            <div
              class="col-lg-4"
              style="padding-top: 1%"
              *ngFor="let visitp of VisitPurposelist"
            >
              <mat-card class="card2">
                <mat-card-content style="text-align: center">
                  <h5 class="titre_card">Visit purpose</h5>
                </mat-card-content>
                <mat-card-subtitle>
                  <label class="subtitle_card"
                    >{{ languee.reason }} : {{ visitp.reason }}
                  </label>
                </mat-card-subtitle>

                <mat-card-actions
                  style="padding-left: 25%; position: absolute; bottom: 20px"
                >
                  <button
                    mat-button
                    (click)="VisitPurpose_remove(visitp)"
                    class="btn btn-light shadow-lg"
                    style="font-size: 14px"
                    class="button_delete"
                  >
                    <mat-icon style="color: rgba(71, 178, 228, 1)"
                      >delete</mat-icon
                    >
                    {{ languee.delete }}
                  </button>
                </mat-card-actions>
              </mat-card>
            </div>
          </div>
        </div>
        <div>
          <div
            class="div_back"
            [ngStyle]="{
              float: langue === 'AR' ? 'right' : 'left'
            }"
          >
            <button type="button" class="btn buttonp" (click)="Preview()">
              <span class="next"> {{ languee.previous }} </span>
            </button>
          </div>
          <div
            class="div_next"
            [ngStyle]="{
              float: langue === 'AR' ? 'left' : 'right'
            }"
          >
            <button type="button" class="btn button1" (click)="Next()">
              <span class="next"> {{ languee.next }} </span>
            </button>
          </div>
        </div>
      </div>

      <div
        [ngStyle]="{
          display: BlocB3 ? 'block' : 'none'
        }"
      >
        <div
          *ngIf="CargoSummarylist.length == 0"
          style="text-align: center; display: block"
        >
          <div>
            <img
              src="./../../../../assets/icon.png"
              style="width: 285px; height: 205px"
            />
          </div>
          <div style="padding-top: 2%">
            <span class="span1"> {{ languee.emptylist }} ! </span>
          </div>
          <div style="padding-top: 3%">
            <span class="span2"> {{ languee.textmessage2 }}</span>
          </div>
          <div style="padding-top: 2%">
            <button type="button" class="btn button3" (click)="open(content1)">
              <div style="display: flex">
                <div>
                  <mat-icon
                    style="color: #ffffff; font-size: 30px; font-weight: 900"
                    >add</mat-icon
                  >
                </div>
                <div style="padding-top: 3px">
                  <span class="add"> {{ languee.new }} </span>
                </div>
              </div>
            </button>
          </div>
        </div>
        <div *ngIf="CargoSummarylist.length > 0" style="min-height: 600px">
          <div class="col-lg-4" style="padding-top: 3%">
            <button type="button" class="btn button3" (click)="open(content1)">
              <div style="display: flex">
                <div>
                  <mat-icon
                    style="color: #ffffff; font-size: 30px; font-weight: 900"
                    >add</mat-icon
                  >
                </div>
                <div style="padding-top: 3px">
                  <span class="add"> {{ languee.new }} </span>
                </div>
              </div>
            </button>
          </div>
          <div class="row" style="padding: 3%; min-height: 530px">
            <div
              class="col-lg-4"
              style="padding-top: 1%"
              *ngFor="let Cargo of CargoSummarylist"
            >
              <mat-card class="card2">
                <mat-card-content style="text-align: center">
                  <h5 class="titre_card">{{ languee.cargosummary }}</h5>
                </mat-card-content>
                <mat-card-subtitle>
                  <label class="subtitle_card">
                    {{ languee.cargocode }} : {{ Cargo.CargoCode }} </label
                  ><br />
                  <label class="subtitle_card">
                    {{ languee.cargoamount }} : {{ Cargo.CargoAmount }} </label
                  ><br />
                  <label class="subtitle_card">
                    {{ languee.operationcode }} : {{ Cargo.OperationCode }}
                  </label>
                </mat-card-subtitle>

                <mat-card-actions
                  style="
                    padding-left: 25%;
                    position: absolute;
                    bottom: 20px;
                    padding-top: 5%;
                  "
                >
                  <button
                    mat-button
                    (click)="CargoSummary_remove(Cargo)"
                    class="btn btn-light shadow-lg"
                    style="font-size: 14px"
                    class="button_delete"
                  >
                    <mat-icon style="color: rgba(71, 178, 228, 1)"
                      >delete</mat-icon
                    >
                    {{ languee.delete }}
                  </button>
                </mat-card-actions>
              </mat-card>
            </div>
          </div>
        </div>
        <div>
          <div
            class="div_back"
            [ngStyle]="{
              float: langue === 'AR' ? 'right' : 'left'
            }"
          >
            <button type="button" class="btn buttonp" (click)="Preview()">
              <span class="next"> {{ languee.previous }} </span>
            </button>
          </div>
          <div
            class="div_next"
            [ngStyle]="{
              float: langue === 'AR' ? 'left' : 'right'
            }"
          >
            <button type="button" class="btn button1" (click)="Next()">
              <span class="next"> {{ languee.next }} </span>
            </button>
          </div>
        </div>
      </div>

      <div
        [ngStyle]="{
          display: BlocB4 ? 'block' : 'none'
        }"
      >
        <div
          *ngIf="PassengerSummarylist.length == 0"
          style="text-align: center; display: block"
        >
          <div>
            <img
              src="./../../../../assets/icon.png"
              style="width: 285px; height: 205px"
            />
          </div>
          <div style="padding-top: 2%">
            <span class="span1"> {{ languee.emptylist }} ! </span>
          </div>
          <div style="padding-top: 3%">
            <span class="span2">
              {{ languee.textmessage }}
            </span>
          </div>
          <div style="padding-top: 2%">
            <button type="button" class="btn button3" (click)="open(content2)">
              <div style="display: flex">
                <div>
                  <mat-icon
                    style="color: #ffffff; font-size: 30px; font-weight: 900"
                    >add</mat-icon
                  >
                </div>
                <div style="padding-top: 3px">
                  <span class="add"> {{ languee.new }} </span>
                </div>
              </div>
            </button>
          </div>
        </div>
        <div *ngIf="PassengerSummarylist.length > 0" style="min-height: 600px">
          <div class="row" style="min-height: 530px">
            <div class="col-lg-12">
              <form [formGroup]="passeger">
                <div class="row">
                  <div class="col-lg-5">
                    <div>
                      <label> {{ languee.total1 }}</label>
                    </div>
                    <div style="padding-top: 3%">
                      <input
                        readonly
                        type="text"
                        formControlName="totla_foreigner"
                        mdbInput
                        class="form-control"
                      />
                    </div>
                  </div>

                  <div class="col-lg-5">
                    <div>
                      <label> {{ languee.total2 }}</label>
                    </div>
                    <div style="padding-top: 3%">
                      <input
                        readonly
                        style="padding-top: 2%"
                        type="text"
                        formControlName="totla_passenger"
                        id="defaultContactFormEmail"
                        mdbInput
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-lg-2" style="padding-top: 3%">
                    <button
                      type="button"
                      class="btn button3"
                      (click)="open(content2)"
                    >
                      <div style="display: flex">
                        <div>
                          <mat-icon
                            style="
                              color: #ffffff;
                              font-size: 30px;
                              font-weight: 900;
                            "
                            >add</mat-icon
                          >
                        </div>
                        <div style="padding-top: 3px">
                          <span class="add"> {{ languee.new }} </span>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div
              class="col-lg-4"
              style="padding-top: 1%"
              *ngFor="let PassengerS of PassengerSummarylist"
            >
              <mat-card class="card2">
                <mat-card-content style="text-align: center">
                  <h5 class="titre_card">{{ languee.passengersummary }}</h5>
                </mat-card-content>
                <mat-card-subtitle>
                  <label class="subtitle_card"
                    >{{ languee.numberofpassenge }} :
                    {{ PassengerS.NumberofPassenger }} </label
                  ><br />
                  <label class="subtitle_card"
                    >{{ languee.passengernationally }} :
                    {{ PassengerS.PassengerNationally }}</label
                  >
                </mat-card-subtitle>

                <mat-card-actions
                  style="padding-left: 25%; position: absolute; bottom: 20px"
                >
                  <button
                    mat-button
                    (click)="PassengerSummary_remove(PassengerS)"
                    class="btn btn-light shadow-lg"
                    style="font-size: 14px"
                    class="button_delete"
                  >
                    <mat-icon style="color: rgba(71, 178, 228, 1)"
                      >delete</mat-icon
                    >
                    {{ languee.delete }}
                  </button>
                </mat-card-actions>
              </mat-card>
            </div>
          </div>
        </div>
        <div>
          <div
            class="div_back"
            [ngStyle]="{
              float: langue === 'AR' ? 'right' : 'left'
            }"
          >
            <button type="button" class="btn buttonp" (click)="Preview()">
              <span class="next"> {{ languee.previous }} </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-1" [ngStyle]="{ direction: direction }">
      <div class="blocaction">
        <div *ngIf="saveandfinish_etat" style="padding: 3%">
          <div class="row">
            <div (click)="finishaction(content3)" class="div_action">
              <mat-icon
                class="icon_action"
                [ngStyle]="{
                  color:
                    Form1.valid && selectedportcall != null
                      ? '#1671b3'
                      : '#b1d9f5'
                }"
                style="direction: revert"
                >save</mat-icon
              >
            </div>
          </div>
          <div>
            <label class="labaction" style="width: 130px">
              {{ languet.finishaction }}
            </label>
          </div>
        </div>
        <div style="padding: 3%">
          <div class="row">
            <div class="div_action" (click)="fileInput1.click()">
              <mat-icon
                class="icon_action"
                [ngStyle]="{
                  color:
                    Form1.valid && selectedportcall != null
                      ? '#1671b3'
                      : '#b1d9f5'
                }"
                style="direction: revert"
                >save_alt</mat-icon
              >
            </div>
          </div>
          <input style="display: none" type="submit" #fileInput1 />
          <div>
            <label class="labaction"> {{ languee.save }} </label>
          </div>
        </div>
        <div *ngIf="submit_etat" style="padding: 3%">
          <div class="row">
            <div (click)="submit12(content4)" class="div_action">
              <mat-icon
                style="direction: revert"
                class="icon_action"
                [ngStyle]="{ color: color_submit }"
                >send</mat-icon
              >
            </div>
          </div>
          <div>
            <label class="labaction"> {{ languee.submit }} </label>
          </div>
        </div>
        <div style="padding: 3%">
          <div class="row">
            <div (click)="Go_list()" class="div_action">
              <mat-icon class="icon_action" style="direction: revert"
                >format_list_bulleted</mat-icon
              >
            </div>
          </div>
          <div>
            <label class="labaction">
              {{ languee.list }}
            </label>
          </div>
        </div>
        <div style="padding: 3%">
          <div class="row">
            <div (click)="fileInput.click()" class="div_action">
              <mat-icon class="icon_action" style="direction: revert"
                >cloud_upload</mat-icon
              >
            </div>
          </div>
          <input
            style="display: none"
            type="file"
            #fileInput
            (change)="onFileChange($event)"
            name="uploadFile"
            multiple="multiple"
          />
          <div>
            <label class="labaction"> {{ languee.upload }} </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<ng-template #content let-modal>
  <div class="modal-header text-center">
    <h4 class="modal-title w-100" id="modal-basic-title">
      {{ languee.visitpurpose }}
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body" [ngStyle]="{ direction: direction }">
    <div class="bodymodal_visit">
      <form [formGroup]="FormVisitPurpose">
        <div class="mb-3 formmodal">
          <mat-form-field class="full-width">
            <input
              matInput
              required
              type="text"
              placeholder="{{ languee.reason }}"
              formControlName="reason"
            />
          </mat-form-field>
        </div>
      </form>
    </div>
  </div>
  <div class="text-center" style="padding-bottom: 2%">
    <button
      [disabled]="FormVisitPurpose.invalid"
      type="button"
      class="btn button2"
      (click)="VisitPurpose_add()"
    >
      <span class="Save"> {{ languee.save }}</span>
    </button>
  </div>
</ng-template>

<ng-template #content1 let-modal>
  <div class="modal-header text-center">
    <h4 class="modal-title w-100" id="modal-basic-title">
      {{ languee.cargosummary }}
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body" [ngStyle]="{ direction: direction }">
    <div class="bodymodal">
      <form [formGroup]="FormCargoSummary">
        <div class="mb-3">
          <mat-form-field class="full-width">
            <input
              matInput
              required
              type="text"
              placeholder="{{ languee.operationcode }}"
              formControlName="OperationCode"
            />
          </mat-form-field>
        </div>
        <div class="mb-3">
          <mat-form-field class="full-width">
            <input
              matInput
              required
              type="text"
              placeholder="{{ languee.cargocode }}"
              formControlName="CargoCode"
            />
          </mat-form-field>
        </div>
        <div class="mb-3">
          <mat-form-field class="full-width">
            <input
              matInput
              required
              type="number"
              min="0"
              placeholder="{{ languee.cargoamount }}"
              formControlName="CargoAmount"
            />
          </mat-form-field>
        </div>
      </form>
    </div>
  </div>
  <div class="text-center" style="padding-bottom: 2%">
    <button
      [disabled]="FormCargoSummary.invalid"
      type="button"
      class="btn button2"
      (click)="CargoSummary_add()"
    >
      <span class="Save"> {{ languee.save }}</span>
    </button>
  </div>
</ng-template>

<ng-template #content2 let-modal>
  <div class="modal-header text-center">
    <h4 class="modal-title w-100" id="modal-basic-title">
      {{ languee.passengersummary }}
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div
    class="modal-body"
    style="z-index: 1"
    [ngStyle]="{ direction: direction }"
  >
    <div class="bodymodal" [ngStyle]="{ direction: direction }">
      <form [formGroup]="FormPassengerSummary">
        <div class="mb-3">
          <mat-form-field class="full-width">
            <input
              matInput
              required
              type="number"
              min="0"
              placeholder="{{ languee.numberofpassenge }}"
              formControlName="NumberofPassenger"
            />
          </mat-form-field>
        </div>
        <div class="mb-3" style="z-index: 1">
          <mat-select-country
            style="color: #1d3a66"
            placeHolder="{{ languee.country }}"
            (onCountrySelected)="onCountrySelected_passenger($event)"
            >>
          </mat-select-country>
        </div>
      </form>
    </div>
  </div>
  <div class="text-center" style="padding-bottom: 2%">
    <button
      type="button"
      [disabled]="FormPassengerSummary.invalid"
      class="btn button2"
      (click)="PassengerSummary_add()"
    >
      <span class="Save"> {{ languee.save }}</span>
    </button>
  </div>
</ng-template>

<ng-template #content3>
  <div class="modal-body rounded-8" [ngStyle]="{ direction: direction }">
    <div style="text-align: center">
      <div>
        <span
          class="material-icons green-color"
          style="
            font-size: 100px;
            color: #41d96f;
            text-align: center;
            font-weight: 800;
          "
          >check_circle
        </span>
      </div>
      <div>
        <label
          style="
            font-family: Montserrat;
            font-size: 26px;
            padding-top: 2%;
            font-weight: bold;
            color: #073866;
          "
        >
          {{ languet.titremodal }}</label
        >
      </div>
      <div>
        <label
          style="
            width: 310px;
            font-size: 16px;
            font-weight: bold;
            padding-top: 40px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.77;
            letter-spacing: normal;
            text-align: center;
          "
        >
          {{ languet.descriptionsave }}</label
        >
      </div>
      <div class="text-center" style="padding-bottom: 2%; padding-top: 6%">
        <button type="button" class="btn button2" (click)="Go_list()">
          <span class="Save"> {{ languet.list }}</span>
        </button>
      </div>
      <div class="text-center" style="padding-bottom: 2%; padding-top: 3%">
        <button type="button" class="btn button4" (click)="generate_pdf3()">
          <!-- <mat-icon style="color: #a9a9a9; font-size: 30px; padding-top: 2%"
            >print</mat-icon
          > -->
          <span class="print"> {{ languet.print }}</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.5.1/jspdf.umd.min.js"></script>

<ng-template #content4>
  <div class="modal-body rounded-8" [ngStyle]="{ direction: direction }">
    <div style="text-align: center">
      <div>
        <span
          class="material-icons green-color"
          style="
            font-size: 100px;
            color: #41d96f;
            text-align: center;
            font-weight: 800;
          "
          >check_circle
        </span>
      </div>
      <div>
        <label
          style="
            font-family: Montserrat;
            font-size: 26px;
            padding-top: 2%;
            font-weight: bold;
            color: #073866;
          "
        >
          {{ languet.titremodal }}</label
        >
      </div>
      <div>
        <label
          style="
            width: 310px;
            font-size: 16px;
            font-weight: bold;
            padding-top: 40px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.77;
            letter-spacing: normal;
            text-align: center;
          "
        >
          {{ languet.descriptionsubmit }}</label
        >
      </div>
    </div>
  </div>
</ng-template>

<ng-template #content5>
  <div class="modal-body rounded-8" [ngStyle]="{ direction: direction }">
    <div style="text-align: center">
      <div>
        <span
          class="material-icons green-color"
          style="
            font-size: 100px;
            color: #ff0000;
            text-align: center;
            font-weight: 800;
          "
          >close
        </span>
      </div>
      <div>
        <label
          style="
            font-family: Montserrat;
            font-size: 26px;
            padding-top: 2%;
            font-weight: bold;
            color: #fc0505;
          "
        >
          {{ languet.invalidform }}</label
        >
      </div>
    </div>
  </div>
</ng-template>

<notifier-container></notifier-container>
