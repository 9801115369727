import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrganisationService } from 'src/Services/Organisation/organisation.service';
import { PremissionService } from 'src/Services/Premission/premission.service';
import { UserService } from 'src/Services/User/user.service';
import { CertificatService } from '../../Certificat_origin/Services/certificat.service';
import { CreditLetterService } from '../../lettre_de_credit/Services/credit-letter.service';
import { ShippinggaranteService } from '../../Shipping_garante/Services/shippinggarante.service';
import Langue from '../../../../../assets/langue.json';
@Component({
  selector: 'app-dashboard-trade',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  langue: any;
  direction: any;
  languef: any;
  langue_list: any[] = Langue;
  skeleton: boolean = true
  CO_etat: boolean = false;
  LC_etat: boolean = false;
  SG_etat: boolean = false;

  constructor(
    private router: Router,
    private premissionApi: PremissionService,
    private creditLetterService: CreditLetterService,
    private shippinggaranteService: ShippinggaranteService,
    private userrService: UserService,
    private certificatoriginService: CertificatService,
    private OrganisationService: OrganisationService
  ) {


    if (localStorage.getItem('langue') != null) {
      this.langue = localStorage.getItem('langue') + '';
    } else {
      this.langue = 'AR';
    }

    if (this.langue == 'AR') {
      this.direction = 'rtl';
      this.languef = this.langue_list[0]['AR'].dashboard;
    }

    if (this.langue == 'EN') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['EN'].dashboard;
    }

    if (this.langue == 'FR') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['FR'].dashboard;
    }

    this.CO_etat = this.premissionApi.GetCertificatOrigin();
    this.LC_etat = this.premissionApi.GetCreditLetter();
    this.SG_etat = this.premissionApi.GetShippingGarante();
  }

  ngOnInit(): void {
    this.GetIdUser();

  }
  skeletonlooding: boolean = true
  navigationLc() {
    this.router.navigateByUrl('Trade/liste');
    localStorage.setItem('redirecturl_t', '0');
  }
  navigationCo() {
    this.router.navigateByUrl('Trade/liste');
    if (this.premissionApi.GetCreditLetter() == false) {
      localStorage.setItem('redirecturl_t', '0');
    } else {
      localStorage.setItem('redirecturl_t', '1');
    }
  }

  navigationSg() {
    this.router.navigateByUrl('Trade/liste');
    localStorage.setItem('redirecturl_t', '2');
  }

  LC_save: any = 0;
  LC_save_moy: any;
  LC_forconf: any = 0;
  LC_forconf_moy: any;
  LC_accept: any = 0;
  LC_accept_moy: any;
  LC_refuse: any = 0;
  LC_refuse_moy: any;
  LC_total: any = 0;
  LC_List: any[] = [];
  CreditLetter(id: any) {
    this.creditLetterService.GetByIdorg(id).subscribe((res: any) => {
      this.LC_List = res;
      this.LC_total = this.LC_List.length;

      this.LC_save = this.LC_List.filter(
        (e: any) => e.status == 'INPROGRESS_1T2'
      ).length;

      this.LC_save_moy = (this.LC_save / this.LC_total) * 100;
      this.LC_save_moy = this.LC_save_moy.toFixed(2);

      this.LC_forconf = this.LC_List.filter(
        (e: any) => e.status == 'SUBMITTED'
      ).length;

      this.LC_forconf =
        this.LC_forconf +
        this.LC_List.filter(
          (e: any) => e.approvalStatus == 'INFORMATION_REQUEST'
        ).length;

      this.LC_forconf_moy = (this.LC_forconf / this.LC_total) * 100;
      this.LC_forconf_moy = this.LC_forconf_moy.toFixed(2);

      this.LC_accept = this.LC_List.filter(
        (e: any) => e.approvalStatus == 'ACCEPT'
      ).length;

      this.LC_accept_moy = (this.LC_accept / this.LC_total) * 100;
      this.LC_accept_moy = this.LC_accept_moy.toFixed(2);

      this.LC_refuse = this.LC_List.filter(
        (e: any) => e.approvalStatus == 'REJECT'
      ).length;

      this.LC_refuse_moy = (this.LC_refuse / this.LC_total) * 100;
      this.LC_refuse_moy = this.LC_refuse_moy.toFixed(2);
    });
    setTimeout(() => {
      this.skeleton = false
    }, 1000);
  }
  CO_save: any = 0;
  CO_save_moy: any;
  CO_forconf: any = 0;
  CO_forconf_moy: any;
  CO_accept: any = 0;
  CO_accept_moy: any;
  CO_refuse: any = 0;
  CO_refuse_moy: any;
  CO_total: any = 0;
  CO_List: any[] = [];

  /*   { id: 1, name: this.languet.save, code: 'INPROGRESS_1T2' },
  { id: 2, name: this.languet.forconfirmation, code: 'SUBMITTED' },
  { id: 3, name: this.languet.accept, code: 'ACCEPT' },
  { id: 4, name: this.languet.rejectd, code: 'REJECT' },
  { id: 5, name: this.languet.completed, code: 'INFORMATION_REQUEST' }, */
  Certificat_origin(id: any) {
    this.certificatoriginService.GetByIdorg(id).subscribe((res: any) => {
      this.CO_List = res;
      this.CO_total = this.CO_List.length;
      this.CO_save = this.CO_List.filter(
        (e: any) => e.status == 'INPROGRESS'
      ).length;

      this.CO_save_moy = (this.CO_save / this.CO_total) * 100;
      this.CO_save_moy = this.CO_save_moy.toFixed(2);

      this.CO_forconf = this.CO_List.filter(
        (e: any) => e.status == 'SUBMITTED'
      ).length;

      this.CO_forconf_moy = (this.CO_forconf / this.CO_total) * 100;
      this.CO_forconf_moy = this.CO_forconf_moy.toFixed(2);

      this.CO_accept = this.CO_List.filter(
        (e: any) => e.approvalStatus == 'ACCEPT'
      ).length;

      this.CO_accept_moy = (this.CO_accept / this.CO_total) * 100;
      this.CO_accept_moy = this.CO_accept_moy.toFixed(2);

      this.CO_refuse = this.CO_List.filter(
        (e: any) => e.approvalStatus == 'REJECT'
      ).length;

      this.CO_refuse_moy = (this.CO_refuse / this.CO_total) * 100;
      this.CO_refuse_moy = this.CO_refuse_moy.toFixed(2);
    });
    setTimeout(() => {
      this.skeleton = false
    }, 1000);
  }

  SG_save: any = 0;
  SG_save_moy: any = 0;
  SG_forconf: any = 0;
  SG_forconf_moy: any = 0;
  SG_accept: any = 0;
  SG_accept_moy: any = 0;
  SG_refuse: any = 0;
  SG_refuse_moy: any = 0;
  SG_total: any = 0;
  SG_List: any[] = [];
  Shippping_garante(id: any) {
    this.shippinggaranteService.GetByIdorg(id).subscribe((res: any) => {
      this.SG_List = res;
      this.SG_total = this.SG_List.length;
      this.SG_save = this.SG_List.filter(
        (e: any) => e.status == 'INPROGRESS'
      ).length;

      this.SG_save_moy = (this.SG_save / this.SG_total) * 100;
      this.SG_save_moy = this.SG_save_moy.toFixed(2);

      this.SG_forconf = this.SG_List.filter(
        (e: any) => e.status == 'SUBMITTED'
      ).length;

      this.SG_forconf_moy = (this.SG_forconf / this.SG_total) * 100;
      this.SG_forconf_moy = this.SG_forconf_moy.toFixed(2);

      this.SG_accept = this.SG_List.filter(
        (e: any) => e.approvalStatus == 'ACCEPT'
      ).length;

      this.SG_accept_moy = (this.SG_accept / this.SG_total) * 100;
      this.SG_accept_moy = this.SG_accept_moy.toFixed(2);

      this.SG_refuse = this.SG_List.filter(
        (e: any) => e.approvalStatus == 'REJECT'
      ).length;

      this.SG_refuse_moy = (this.SG_refuse / this.SG_total) * 100;
      this.SG_refuse_moy = this.SG_refuse_moy.toFixed(2);

      if (this.SG_total == 0) {
        this.SG_refuse_moy = 0.0;
        this.SG_accept_moy = 0.0;
        this.SG_forconf_moy = 0.0;
        this.SG_save_moy = 0.0;
      }
    });

    setTimeout(() => {
      this.skeleton = false
    }, 1000);
  }

  UserId: any;
  GetIdUser() {
    this.userrService.Getuserbyusername().subscribe((res: any) => {
      this.UserId = res.id;
      this.Getorganisation_send();
    });
  }
  id_Org_en: any;
  Getorganisation_send() {
    this.OrganisationService.Get_org_byID(this.UserId).subscribe((res: any) => {
      this.id_Org_en = res.id;

      this.CreditLetter(this.id_Org_en);
      this.Certificat_origin(this.id_Org_en);
      this.Shippping_garante(this.id_Org_en);
    });
  }


  animation = 'pulse';
  contentLoaded = false;
  count = 2;
  widthHeightSizeInPixels = 50;
}
