import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  url_session: any;
  url_users: any;

  constructor(private http: HttpClient, private router: Router) {
    this.url_session = environment.Url + 'api/auth/';
    this.url_users = environment.Url + '';
    //this.url_users = environment.urllogin + 'api/';
  }

  getDecodedAccessToken(token: any): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  IsUserLogged() {
    if (localStorage.getItem('token') != undefined) {
      return true;
    } else {

      return false;
    }
  }

  Validtoken(): boolean {
    let token = localStorage.getItem('token');
    if (token != undefined) {
      token = this.decrypte(token);
      let generadtoken = this.getDecodedAccessToken(token);

      const date1 = moment();
      let todayDate = date1.format('YYYY-MM-DD HH:mm:ss');

      const dateexpired = moment(generadtoken['exp'] * 1000).format(
        'YYYY-MM-DD HH:mm:ss'
      );
      if (dateexpired > todayDate) {
        return true;
      } else {
        this.logout(localStorage.getItem('203'));
        localStorage.clear();
        this.router.navigateByUrl('/Auth/login');

        return false;
      }
    } else {
      return false;
    }
  }

  Validtoken_v1(token: any): boolean {
    if (token != undefined) {
      token = this.decrypte(token);
      let generadtoken = this.getDecodedAccessToken(token);

      const date1 = moment();
      let todayDate = date1.format('YYYY-MM-DD HH:mm:ss');

      const dateexpired = moment(generadtoken['exp'] * 1000).format(
        'YYYY-MM-DD HH:mm:ss'
      );
      if (dateexpired > todayDate) {
        return true;
      } else {

        localStorage.clear();
        this.router.navigateByUrl('/Auth/login');
        return false;
      }
    } else {
      return false;
    }
  }

  GetUsername() {
    let token = localStorage.getItem('token') + '';

    if (token != null) {
      token = this.decrypte(token);
      let generadtoken = this.getDecodedAccessToken(token);

      if (generadtoken != null) {
        return generadtoken['sub'];
      }
      else
        return ""


    }
    else { return null }

  }

  Getuserbyusername() {
    let token = localStorage.getItem('token');

    if (token != null) {
      token = this.decrypte(token);
    }


    return this.http.get<any>(
      `${this.url_users + 'Userbyusername/' + this.GetUsername()}`
    );
  }

  ValidRole(role: any) {
    let token = localStorage.getItem('token');
    let reponse = false;
    if (token != null) {
      token = this.decrypte(token);

      let generadtoken = this.getDecodedAccessToken(token);

      let roles = generadtoken['Roles'].filter((e: any) => e == role);

      if (roles.length > 0) {
        reponse = true;
      }
    }

    return reponse;
  }

  Getroles() {
    let roles: never[] = [];
    let token = localStorage.getItem('token');
    if (token != null) {
      token = this.decrypte(token);

      let generadtoken = this.getDecodedAccessToken(token);

      roles = generadtoken['Roles'];
    }

    return roles;
  }
  SaveUser() {
    let token = localStorage.getItem('token');
    token = this.decrypte(token);
    this.Getuserbyusername().subscribe((res: any) => {
      localStorage.setItem('user_des', res[0].description);
    });
  }

  GetSession(id: any): Observable<any> {
    return this.http.get(this.url_session + 'anonymous/sessions/by-id/' + id);
  }

  login(data: any): Observable<any> {
    console.log('login service')
    return this.http.post(this.url_users + 'authenticate', data);
  }

  logout(idSessions: any) {
    return this.http.put(this.url_session + 'sessions/' + idSessions, {});
  }
  deleteSession(idSessions: any) {
    return this.http.delete(this.url_session + 'sessions/' + idSessions);
  }
  crypte(valeur: any) {
    console.log('crypte')
    return CryptoJS.AES.encrypt(valeur, 'dfggf4257ttert1DFGS5451EDZ542ZAE777ds');
  }

  decrypte(valeur: any) {
    return CryptoJS.AES.decrypt(valeur, 'dfggf4257ttert1DFGS5451EDZ542ZAE777ds').toString(CryptoJS.enc.Utf8);
  }

  verifyUserName(username: any): Observable<any> {
    return this.http.get(
      this.url_session + 'users/verify/username/' + username
    );
  }

  verifyPassword(userName: any, pwd: any): Observable<any> {
    return this.http.get(this.url_session + 'users/verify/pwd', {
      params: { userName: userName, pwd: pwd },
    });
  }

  GetAllUsers(teamuser: any, sc: any) {
    return this.http.get<any>(
      `${this.url_users + 'user/' + teamuser + '/' + sc}`
    );
  }

  GetUserSender(token: any) {
    let generadtoken = this.getDecodedAccessToken(token);
    let username = generadtoken['sub'];

    return this.http.get<any>(
      `${this.url_users + 'Userbyusername/' + username}`
    );
  }

  getSessionByUser(idUser: any) {
    return this.http.get(this.url_session + 'sessions/by-user/' + idUser);
  }
  getSessionByTeam() {
    return this.http.get(this.url_session + 'sessions/by-team/');
  }
}
