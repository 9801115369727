import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AttchementService {
  url: any;

  Spring_serveur: string = 'api/';
  constructor(private http: HttpClient) {
    this.url = environment.Url + this.Spring_serveur;
  }

  public post(formData: any, id_c: any) {
    return this.http.post(
      this.url + `attachments/recu-payemnt/${id_c}`,
      formData
    );
  }
}
