import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { jsPDF } from 'jspdf';
import { HttpClient } from '@angular/common/http';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import * as XLSX from 'xlsx';
import Langue from '../../../../../assets/langue.json';
import {
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Country } from '@angular-material-extensions/select-country';
import { VesselCallInformationService } from '../Service/VesselCall/vessel-call-information.service';
import { PassengerSummaryService } from '../Service/PassengerSummary/passenger-summary.service';
import { CargoSummaryService } from '../Service/CargoSummary/cargo-summary.service';
import { VisitPurposeService } from '../Service/VisitPurpose/visit-purpose.service';
import { VesselService } from '../Service/Vessel/vessel.service';
import { ActivatedRoute, Router } from '@angular/router';
import { portofcall } from '../../Models/portofcall';
import { OrganisationService } from 'src/Services/Organisation/organisation.service';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { port } from '../../Models/port';
import { ManifestService } from '../../Cargo_manifest/Service/Manifest/manifest.service';
import { UserService } from 'src/Services/User/user.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.css'],
})
export class NewComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  fileAttr = 'Choose File';

  onFileChange(event: any) {
    this.spinner.show();
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>event.target;
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      setTimeout(() => {
        if (ws['C9'] != undefined) {
          this.Form1.controls['VoyageNo'].setValue(ws['C9']['w']);
        }
        if (ws['C40'] != undefined) {
          this.Form1.controls['VesselName'].setValue(ws['C40']['v']);
        }
        if (ws['E40'] != undefined) {
          this.Form1.controls['VesselType'].setValue(ws['E40']['w']);
        }

        if (ws['G40'] != undefined) {
          this.Form1.controls['OwnerCode'].setValue(ws['G40']['w']);
        }
        if (ws['C41'] != undefined) {
          this.Form1.controls['CallSign'].setValue(ws['C41']['v']);
        }
        if (ws['E41'] != undefined) {
          this.Form1.controls['PreferredDock'].setValue(ws['E41']['w']);
        }
        if (ws['G41'] != undefined) {
          this.Form1.controls['ShipMaster'].setValue(ws['G41']['w']);
        }

        if (ws['E13'] != undefined) {
          this.dateETA.setValue(new Date(ws['E13']['w']));
        }
        if (ws['G13'] != undefined) {
          this.dateETD.setValue(new Date(ws['G13']['w']));
        }

        ///port

        if (ws['F30'] != undefined) {
          this.port_next_u = this.portlist.find((x) => x.nom == ws['F30']['v']);
          this.selectedportNext = this.port_next_u;
          this.PortNextName = this.port_next_u.nom;
          this.PortNextcode = this.port_next_u.code;
        }
        if (ws['F22'] != undefined) {
          this.port_perivious_u = this.portlist.find(
            (x) => x.nom == ws['F22']['v']
          );
          this.selectedportPrevious = this.port_perivious_u;
          this.PortPreviousName = this.port_perivious_u.nom;
          this.PortPreviouscode = this.port_perivious_u.code;
        }

        if (ws['C18'] != undefined) {
          this.Form1.controls['VesselLength'].setValue(Number(ws['C18']['v']));
        }
        if (ws['F18'] != undefined) {
          this.Form1.controls['VesselbuildYear'].setValue(
            Number(ws['F18']['v'])
          );
        }

        if (ws['H18'] != undefined) {
          this.Form1.controls['VesselDraft'].setValue(Number(ws['H18']['v']));
        }
        if (ws['F19'] != undefined) {
          this.Form1.controls['VesselNetWeight'].setValue(
            Number(ws['F19']['v'])
          );
        }

        if (ws['H19'] != undefined) {
          this.Form1.controls['VesselGrossWeight'].setValue(
            Number(ws['H19']['v'])
          );
        }

        this.spinner.hide();
      }, 500);
      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
    };
  }
  btn_backround1 = 'rgba(71, 178, 228, 1)';
  btn_backround2 = '#fff';
  color1 = '#fff';
  color2 = '#073866';
  Form1: FormGroup;
  Form2bool: boolean = false;
  passeger: FormGroup | any;
  BlocB1: boolean = true;
  BlocB2: boolean = false;
  BlocB3: boolean = false;
  BlocB4: boolean = false;
  form2Bool: boolean = false;
  FormVisitPurpose: FormGroup;
  FormCargoSummary: FormGroup;
  FormPassengerSummary: FormGroup;
  option: any;
  national: any[] = [
    { value: 'Foreigner', viewValue: 'Foreigner' },
    { value: 'Libyan', viewValue: 'Libyan' },
    { value: 'Libyan', viewValue: 'Libyan' },
  ];
  blockform_1: any = 'block';
  blockform_2: any = 'none';
  createView() { }
  onStepChange(event: any) { }
  options: any[] = [
    { name: 'I (IMO No. Used)' },
    { name: 'C  (Call Sign used)' },
  ];

  /*   @ViewChild(MatStepper, { static: true }) myStepper: MatStepper | any;
   */ ngAfterViewInit() {
    // this.myStepper._getIndicatorType = () => 'number';
  }

  CallSignType = new UntypedFormControl();
  selectedportPrevious: any;
  selectedportNext: any;
  selectedportcall: any;
  portlistPrevious = [];
  portlistNext = [];
  VisitPurposelist: any[] = [];
  CargoSummarylist: any[] = [];
  PassengerSummarylist: any[] = [];
  portlist: port[] = [];
  portlistofcall: portofcall[] = [];
  Id_ArrCell: any;
  dateETA = new FormControl();
  dateETD = new FormControl();
  transactiontype: any = 'Submit';
  Id_ArrCell_copy: any;
  date_start: any;
  role_autorise_save: boolean = false;
  role_autorise_submit: boolean = false;
  idOrgR: any;
  color_submit = '#b1d9f5';
  typeaction: boolean = false;
  Id_cargo_copy: any;
  Id_ship_copy: any;

  langue: any;
  direction: any;
  languef: any;
  langue_list: any[] = Langue;
  languet: any;
  languem: any;
  languee: any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private httpClient: HttpClient,
    private modalService: NgbModal,
    private VesselCallApi: VesselCallInformationService,
    private VisitPurposeApi: VisitPurposeService,
    private CargoSummaryApi: CargoSummaryService,
    private PassengerSummaryApi: PassengerSummaryService,
    private VesselApi: VesselService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private router: Router,
    private Organisation: OrganisationService,
    private notifier: NotifierService,
    private spinner: NgxSpinnerService,
    private ManifestApi: ManifestService,
    private Userauth: UserService,
    private titleService: Title
  ) {
    if (localStorage.getItem('langue') != null) {
      this.langue = localStorage.getItem('langue') + '';
    } else {
      this.langue = 'AR';
    }

    if (this.langue == 'AR') {
      this.direction = 'rtl';
      this.languee = this.langue_list[0]['AR'].SA;
      this.languet = this.langue_list[0]['AR'].modal_text;
    }

    if (this.langue == 'EN') {
      this.direction = 'ltr';
      this.languee = this.langue_list[0]['EN'].SA;
      this.languet = this.langue_list[0]['EN'].modal_text;
    }

    if (this.langue == 'FR') {
      this.direction = 'ltr';
      this.languee = this.langue_list[0]['FR'].SA;
      this.languet = this.langue_list[0]['FR'].modal_text;
    }

    this.titleService.setTitle(this.languet.entete);
    this.Id_ArrCell = this.route.snapshot.params['id'];
    this.date_ETA = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.date_start = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.GetUser();
    if (this.Id_ArrCell != undefined) {
      this.Getpremision();
      this.spinner.show();
      this.transactiontype = 'Update';
      this.form2Bool = true;
      setTimeout(() => {
        this.GetCellInformationById(this.Id_ArrCell);
      }, 300);
    } else {
      this.remplirSystem();
    }

    this.Id_ArrCell_copy = this.route.snapshot.params['copy'];
    if (this.Id_ArrCell_copy != undefined) {
      this.spinner.show();
      this.transactiontype = 'Copy';
      this.form2Bool = true;
      setTimeout(() => {
        this.GetCellInformationById(this.Id_ArrCell_copy);
      }, 300);
    }

    this.Id_ship_copy = this.route.snapshot.params['ship'];
    this.Id_cargo_copy = this.route.snapshot.params['cargo'];
    if (this.Id_cargo_copy != undefined && this.Id_ship_copy != undefined) {
      this.spinner.show();
      this.transactiontype = 'Copy';

      setTimeout(() => {
        this.Getcargobyid(this.Id_ship_copy);
      }, 500);
    }

    if (this.Id_ship_copy != undefined && this.Id_cargo_copy == undefined) {
      this.spinner.show();
      this.transactiontype = 'Copy';

      setTimeout(() => {
        this.GetCellInformationById(this.Id_ship_copy);
      }, 500);
    }

    this.Form1 = this.formBuilder.group({
      VoyageNo: ['', Validators.required],
      VesselName: ['', Validators.required],
      VesselCountry: [''],
      VesselType: [''],
      VesselbuildYear: [''],
      VesselRegPort: [''],
      VesselLength: [''],
      VesselGrossWeight: [''],
      VesselNetWeight: [''],
      VesselDraft: [''],
      OwnerCode: [''],
      OwnerName: [''],
      CallSign: [''],
      ShippingAgentCode: [''],
      ShippingAgent: [''],
      ShipMaster: [''],
      PreviousPortPrevious: [''],
      PortofCall: [''],
      portNext: [''],
      BerthType: [''],
      PreferredDock: [''],
    });
    this.passeger = this.formBuilder.group({
      totla_foreigner: [''],
      totla_passenger: [''],
    });

    this.FormVisitPurpose = this.formBuilder.group({
      reason: ['', Validators.required],
    });
    this.FormCargoSummary = this.formBuilder.group({
      OperationCode: ['', Validators.required],
      CargoCode: ['', Validators.required],
      CargoAmount: ['', Validators.required],
    });
    this.FormPassengerSummary = this.formBuilder.group({
      NumberofPassenger: ['', Validators.required],
      PassengerNationally: [''],
    });
  }

  User: any;

  remplirSystem() {
    this.Userauth.Getuserbyusername().subscribe((res: any) => {
      this.User = res;

      this.Form1.controls['ShippingAgent'].setValue(this.User.userName);
      this.Form1.controls['ShippingAgentCode'].setValue(this.User.id);
    });
  }
  list_ports: any = [];

  ngOnInit(): void {
    this.Getports();
    this.Getorganisation();

    this.CallSignType.setValue(this.options[0]);
  }
  displayFn(object?: any): any | undefined {
    return object ? object.name : undefined;
  }

  PortPreviousName: any;
  PortPreviouscode: any;
  changeportPrevious(event: any) {
    let port = this.portlist.find((x) => x.id == event.id);
    this.PortPreviousName = port?.nom;
    this.PortPreviouscode = port?.code;
  }
  PortCallName: any;
  PortCallcode: any;
  changeportcall(event: any) {
    this.PortCallName = event.nom;
    this.PortCallcode = event.id;
    this.idOrgR = event.id;
  }
  PortNextName: any;
  PortNextcode: any;
  changeportNext(event: any) {
    let port = this.portlist.find((x) => x.id == event.id);
    this.PortNextName = port?.nom;
    this.PortNextcode = port?.code;
  }
  DangerousGood: any = 'Y';
  DangerousGoodsGange(event: any) {
    if (event.value == 'no') {
      this.DangerousGood = 'N';
    } else {
      this.DangerousGood = 'Y';
    }
  }

  Getports() {
    this.httpClient.get('./../../../../assets/data.json').subscribe((data) => {
      this.list_ports = data;
      this.portlist = [];
      for (let i = 0; i < this.list_ports.length; i++) {
        this.portlist.push({
          id: this.list_ports[i].id,
          name:
            this.list_ports[i].name +
            '(Code : ' +
            this.list_ports[i].code +
            ')',
          nom: this.list_ports[i].name,
          code: this.list_ports[i].code,
        });
      }
    });
  }
  closeResult = '';
  open(content: any) {
    this.modalService
      .open(content, {
        centered: true,
        windowClass: 'modal-rounded',
        ariaLabelledBy: 'modal-basic-title',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason: any) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  VisitPurpose_add() {
    let reason = this.FormVisitPurpose.controls['reason'].value;
    this.modalService.dismissAll();
    this.VisitPurposelist.push({ reason: reason });
    this.FormVisitPurpose.reset();
  }

  VisitPurpose_remove(reason: any) {
    let result = this.VisitPurposelist.find((x) => x == reason);

    const index: number = this.VisitPurposelist.indexOf(reason);
    if (index !== -1) {
      this.VisitPurposelist.splice(index, 1);
    }
  }

  number_e: any = 0;
  number_l: any = 0;

  PassengerSummary_add() {
    let NumberofPassenger =
      this.FormPassengerSummary.controls['NumberofPassenger'].value;
    let PassengerNationally = this.name_country_passenger;
    this.modalService.dismissAll();
    this.PassengerSummarylist.push({
      NumberofPassenger: NumberofPassenger,
      PassengerNationally: PassengerNationally,
    });
    setTimeout(() => {
      this.CalculPasseger();
    }, 500);

    this.FormPassengerSummary.reset();
  }

  PassengerSummary_remove(NumberofPassenger: any) {
    const index: number = this.PassengerSummarylist.indexOf(NumberofPassenger);
    if (index !== -1) {
      this.PassengerSummarylist.splice(index, 1);
      this.CalculPasseger();
    }
  }

  CargoSummary_add() {
    let CargoAmount = this.FormCargoSummary.controls['CargoAmount'].value;
    let CargoCode = this.FormCargoSummary.controls['CargoCode'].value;
    let OperationCode = this.FormCargoSummary.controls['OperationCode'].value;
    this.modalService.dismissAll();
    this.CargoSummarylist.push({
      CargoAmount: CargoAmount,
      CargoCode: CargoCode,
      OperationCode: OperationCode,
    });
    this.FormCargoSummary.reset();
  }

  CargoSummary_remove(reason: any) {
    const index: number = this.CargoSummarylist.indexOf(reason);
    if (index !== -1) {
      this.CargoSummarylist.splice(index, 1);
    }
  }
  code_country: any;
  name_country: any;
  onCountrySelected(country: Country) {
    this.code_country = country.alpha2Code;
    this.name_country = country.name;
  }

  style(event: any) {
    if (event.target.value > 0) {
    }
  }
  CallSignType_f: any = 'I';
  Save(content: any) {
    this.etat = 'Save';

    if (
      this.Form1.status != 'INVALID' &&
      this.selectedportcall != undefined &&
      this.selectedportcall != null
    ) {
      this.Vessel_Call_Infomation_add(content);
    } else {
      this.notifier.notify('error', 'Save Opps !   Form Invalid !');
    }
  }

  Vessel_Call_Infomation_add(content: any) {
    if (this.CallSignType.value.name == 'C  (Call Sign used)') {
      this.CallSignType_f = 'C';
    } else {
      this.CallSignType_f = 'I';
    }
    if (this.transactiontype == 'Copy') {
      this.add_Vessel(content);
    } else if (this.transactiontype == 'Update') {
      this.update_vessel(this.id_vessel, content);
    } else {
      this.add_Vessel(content);
    }
  }
  date_ETA: any;
  date_ETD: any;
  public onchangedateETA(event: any): void {
    this.date_ETA = this.datePipe.transform(event, 'yyyy-MM-dd');
  }
  public onchangedateETD(event: any): void {
    this.date_ETD = this.datePipe.transform(event, 'yyyy-MM-dd');
  }

  add_VisitPurpose(call: any) {
    for (let i = 0; i < this.VisitPurposelist.length; i++) {
      const VisitPurpose = this.createFromVisitPurpose(i, call);
      this.VisitPurposeApi.post(VisitPurpose).subscribe((res: any) => { });
    }
  }
  add_PassengerSummary(call: any) {
    for (let i = 0; i < this.PassengerSummarylist.length; i++) {
      const PassengerSummary = this.createPassengerSummary(i, call);

      this.PassengerSummaryApi.post(PassengerSummary).subscribe(
        (res: any) => { }
      );
    }
  }
  add_CargoSummary(call: any) {
    for (let i = 0; i < this.CargoSummarylist.length; i++) {
      const CargoSummary = this.createFromCargoSummary(i, call);

      this.CargoSummaryApi.post(CargoSummary).subscribe((res: any) => { });
    }
  }
  shipping_arrival: any;
  add_Vessel(content: any) {
    this.onchangedateETA(this.dateETA.value);
    this.onchangedateETD(this.dateETD.value);

    const Vessel = this.createFromVessel();
    this.VesselApi.post(Vessel).subscribe((res: any) => {
      this.id_vessel = res.id;
      const call_information = this.createFromVessel_Call_Infomation(res);
      this.VesselCallApi.post(call_information, this.idOrgR).subscribe(
        (res1: any) => {
          console.log(" res1 " + res1)
          this.Getpremision();
          this.Form1.reset();
          this.Id_ArrCell = res1.id;
          this.shipping_arrival = res1;
          setTimeout(() => {
            this.add_VisitPurpose(res1);
            this.add_CargoSummary(res1);
            this.add_PassengerSummary(res1);

            this.modalService.open(content);
          }, 300);
        }
      );
    });
  }
  etat: any;

  update_vessel(id: any, content: any) {
    this.deletebloc();
    this.date_ETA = this.datePipe.transform(this.dateETA.value, 'yyyy-MM-dd');
    this.date_ETD = this.datePipe.transform(this.dateETD.value, 'yyyy-MM-dd');
    const Vessel = this.createFromVesselUpdate(id);
    this.VesselApi.update(Vessel, id).subscribe((res: any) => {
      const call_information = this.createFromVessel_Call_InfomationUpdate(
        res,
        this.etat
      );
      this.VesselCallApi.post(call_information, this.idOrgR).subscribe(
        (res: any) => {
          this.modalService.open(content);

          this.add_CargoSummary(res);
          this.add_PassengerSummary(res);
          this.add_VisitPurpose(res);
        }
      );
    });
  }

  type_f: any;
  protected createFromVessel_Call_Infomation(vessel: any): any {
    return {
      voyageno: this.Form1.controls['VoyageNo'].value,
      ownercode: this.Form1.controls['OwnerCode'].value,
      ownername: this.Form1.controls['OwnerName'].value,
      callsign: this.Form1.controls['CallSign'].value,
      shippingagentcode: this.Form1.controls['ShippingAgentCode'].value,
      shippingagent: this.Form1.controls['ShippingAgent'].value,
      shipmaster: this.Form1.controls['ShipMaster'].value,
      preferreddock: this.Form1.controls['PreferredDock'].value,
      berthtype: this.Form1.controls['BerthType'].value,
      portofcallname: this.PortCallName,
      portofcallcode: this.PortCallcode,
      nextportname: this.PortNextName,
      nextportcode: this.PortNextcode,
      etat: this.etat,
      type: this.type,
      previousportname: this.PortPreviousName,
      previousportcode: this.PortPreviouscode,
      callsigntype: this.CallSignType_f,
      eta: this.date_ETA,
      etd: this.date_ETD,
      dangerousgoods: this.DangerousGood,
      vessel: vessel,
    };
  }

  protected createFromVessel_Call_InfomationUpdate(
    vessel: any,
    etat: any
  ): any {
    return {
      id: this.Id_ArrCell,
      voyageno: this.Form1.controls['VoyageNo'].value,
      ownercode: this.Form1.controls['OwnerCode'].value,
      ownername: this.Form1.controls['OwnerName'].value,
      callsign: this.Form1.controls['CallSign'].value,
      shippingagentcode: this.Form1.controls['ShippingAgentCode'].value,
      shippingagent: this.Form1.controls['ShippingAgent'].value,
      shipmaster: this.Form1.controls['ShipMaster'].value,
      preferreddock: this.Form1.controls['PreferredDock'].value,
      berthtype: this.Form1.controls['BerthType'].value,
      portofcallname: this.PortCallName,
      portofcallcode: this.PortCallcode,
      nextportname: this.PortNextName,
      nextportcode: this.PortNextcode,
      previousportname: this.PortPreviousName,
      previousportcode: this.PortPreviouscode,
      callsigntype: this.CallSignType_f,
      etd: this.date_ETD,
      etat: etat,
      type: this.type_f,
      dangerousgoods: this.DangerousGood,
      vessel: vessel,
    };
  }

  protected createFromVessel(): any {
    return {
      vesseldraft: this.Form1.controls['VesselDraft'].value,
      vesselgrossweight: this.Form1.controls['VesselGrossWeight'].value,
      vessellength: this.Form1.controls['VesselLength'].value,
      vessel: this.Form1.controls['VesselName'].value,
      vesselnetweight: this.Form1.controls['VesselNetWeight'].value,
      vesselregport: this.Form1.controls['VesselRegPort'].value,
      vesseltype: this.Form1.controls['VesselType'].value,
      vesselbuildyear: this.Form1.controls['VesselbuildYear'].value,
      vesselcountrycode: this.code_country,
      vesselcountryname: this.name_country,
    };
  }

  protected createFromVesselUpdate(id: any): any {
    return {
      id: id,
      vesseldraft: this.Form1.controls['VesselDraft'].value,
      vesselgrossweight: this.Form1.controls['VesselGrossWeight'].value,
      vessellength: this.Form1.controls['VesselLength'].value,
      vessel: this.Form1.controls['VesselName'].value,
      vesselnetweight: this.Form1.controls['VesselNetWeight'].value,
      vesselregport: this.Form1.controls['VesselRegPort'].value,
      vesseltype: this.Form1.controls['VesselType'].value,
      vesselbuildyear: this.Form1.controls['VesselbuildYear'].value,
      vesselcountrycode: this.code_country,
      vesselcountryname: this.name_country,
    };
  }

  protected createFromCargoSummary(i: any, call: any): any {
    return {
      cargoamount: this.CargoSummarylist[i].CargoAmount,
      cargocode: this.CargoSummarylist[i].CargoCode,
      operationcode: this.CargoSummarylist[i].OperationCode,
      arrVesselCallInformation: call,
    };
  }

  protected createFromVisitPurpose(i: any, call: any): any {
    return {
      reason: this.VisitPurposelist[i].reason,
      arrVesselCallInformation: call,
    };
  }

  protected createPassengerSummary(i: any, call: any): any {
    return {
      numberofpassenger: this.PassengerSummarylist[i].NumberofPassenger,
      passengernationally: this.PassengerSummarylist[i].PassengerNationally,
      arrVesselCallInformation: call,
    };
  }

  type: any = 'Ship_Departure';

  saveandfinish_etat: boolean = false;
  submit_etat: boolean = false;

  protected GetCellInformationById(id: any) {
    this.VesselCallApi.GetById(id).subscribe((res: any) => {
      this.code_country = res.vessel.vesselcountrycode;
      this.name_country = res.vessel.vesselcountryname;
      this.type_f = res.type;
      this.shipping_arrival = res;
      this.Create_Form1_update(res, res.vessel);
      this.Create_Form2_update(res);
      this.Create_Form4_update(res);

      if (this.transactiontype == 'Update') {
        let messagerows = this.shipping_arrival.messages.length;
        let lastmessage = this.shipping_arrival.messages[messagerows - 1];
        let actionsrow = lastmessage.actions.length;
        let lastaction = lastmessage.actions[actionsrow - 1];

        if (
          lastaction != undefined &&
          lastaction.affectedToUser.id == this.idUser &&
          lastaction.code == 'SAVE' &&
          lastaction.status != 'FINISHED'
        ) {
          this.saveandfinish_etat = true;
        } else if (
          lastaction != undefined &&
          lastaction.affectedToUser.id == this.idUser &&
          lastaction.code == 'SUBMIT' &&
          lastaction.status != 'FINISHED'
        ) {
          this.submit_etat = true;
        } else {
          this.Go_list();
        }
      }

      setTimeout(() => {
        this.Create_Form3_update(res);
        this.spinner.hide();
      }, 1000);
    });
  }
  id_vessel: any;
  Create_Form1_update(Cell: any, Vessel: any) {
    //Cell
    this.Form1.controls['VoyageNo'].setValue(Cell.voyageno);
    this.Form1.controls['OwnerCode'].setValue(Cell.ownercode);
    this.Form1.controls['OwnerName'].setValue(Cell.ownername);
    //vessel
    this.Form1.controls['VesselName'].setValue(Vessel.vessel);
    this.Form1.controls['VesselType'].setValue(Vessel.vesseltype);
    this.Form1.controls['VesselbuildYear'].setValue(Vessel.vesselbuildyear);
    this.Form1.controls['VesselRegPort'].setValue(Vessel.vesselregport);
    this.Form1.controls['VesselLength'].setValue(Vessel.vessellength);
    this.Form1.controls['VesselGrossWeight'].setValue(Vessel.vesselgrossweight);
    this.Form1.controls['VesselNetWeight'].setValue(Vessel.vesselnetweight);
    this.Form1.controls['VesselDraft'].setValue(Vessel.vesseldraft);

    this.code_country = Vessel.vesselcountrycode;
    this.name_country = Vessel.vesselcountryname;
    this.id_vessel = Vessel.id;
  }

  Create_Form2_update(Cell: any) {
    if (Cell.callSignType == 'I') {
      this.CallSignType.setValue(this.options[0]);
    } else {
      this.CallSignType.setValue(this.options[1]);
    }

    this.dateETA.setValue(new Date(Cell.eta));
    this.dateETD.setValue(new Date(Cell.etd));

    this.Form1.controls['CallSign'].setValue(Cell.callsign);
    this.Form1.controls['ShippingAgentCode'].setValue(Cell.shippingagentcode);
    this.Form1.controls['ShippingAgent'].setValue(Cell.shippingagent);
    this.Form1.controls['ShipMaster'].setValue(Cell.shipmaster);
  }
  port_next_u: any;
  port_perivious_u: any;
  port_ofcall_u: any;
  Create_Form3_update(Cell: any) {
    this.port_next_u = this.portlist.find((x) => x.code == Cell.nextportcode);
    this.port_perivious_u = this.portlist.find(
      (x) => x.code == Cell.previousportcode
    );
    this.port_ofcall_u = this.portlistofcall.find(
      (x) => x.id == Cell.portofcallcode
    );

    this.Form1.controls['BerthType'].setValue(Cell.berthtype);
    this.Form1.controls['PreferredDock'].setValue(Cell.preferreddock);
    this.selectedportNext = this.port_next_u;
    this.selectedportcall = this.port_ofcall_u;
    this.selectedportPrevious = this.port_perivious_u;

    this.PortCallName = Cell.portofcallname;
    this.PortCallcode = Cell.portofcallcode;
    this.idOrgR = Cell.portofcallcode;
    this.PortNextName = Cell.nextportname;
    this.PortNextcode = Cell.nextportcode;
    this.PortPreviousName = Cell.previousportname;
    this.PortPreviouscode = Cell.nextportcode;
  }
  Create_Form4_update(Cell: any) {
    this.number_l = 0;
    this.number_e = 0;
    for (let arrVciPassengerSummarys of Cell.arrVciPassengerSummarys) {
      this.PassengerSummarylist.push({
        id: arrVciPassengerSummarys.id,
        NumberofPassenger: arrVciPassengerSummarys.numberofpassenger,
        PassengerNationally: arrVciPassengerSummarys.passengernationally,
      });
    }
    setTimeout(() => {
      this.CalculPasseger();
    }, 500);

    for (let arrVciVisitPurposes of Cell.arrVciVisitPurposes) {
      this.VisitPurposelist.push({
        id: arrVciVisitPurposes.id,
        reason: arrVciVisitPurposes.reason,
      });
    }

    for (let arrVciCargoSummarys of Cell.arrVciCargoSummarys) {
      this.CargoSummarylist.push({
        id: arrVciCargoSummarys.id,
        CargoAmount: arrVciCargoSummarys.cargoamount,
        CargoCode: arrVciCargoSummarys.cargocode,
        OperationCode: arrVciCargoSummarys.operationcode,
      });
    }
  }

  CargoSummarydelete(id: any) {
    this.CargoSummaryApi.delete(id).subscribe((res: any) => { });
  }
  VisitPurposesdelete(id: any) {
    this.VisitPurposeApi.delete(id).subscribe((res: any) => { });
  }
  PassengerSummarysdelete(id: any) {
    this.PassengerSummaryApi.delete(id).subscribe((res: any) => { });
  }
  form2 = 'form2';
  form1 = 'form1';
  selectionChange() { }
  Formbool1: boolean = true;
  Formbool2: boolean = false;
  ChangeForm(form: any) {
    if (form == 'form1') {
      this.Formbool1 = true;
      this.Formbool2 = false;
      this.btn_backround1 = 'rgba(71, 178, 228, 1)';
      this.btn_backround2 = '#fff';
      this.color1 = '#fff';
      this.color2 = '#073866';
      this.blockform_1 = 'block';
      this.blockform_2 = 'none';
    } else {
      this.blockform_1 = 'none';
      this.blockform_2 = 'block';
      this.form2Bool = true;
      this.Formbool1 = false;
      this.Formbool2 = true;
      this.btn_backround2 = 'rgba(71, 178, 228, 1)';
      this.btn_backround1 = '#fff';
      this.color2 = '#fff';
      this.color1 = '#073866';
    }
  }

  contour: number = 1;

  Next() {
    this.intialisebloc();
    if (this.contour < 4) {
      this.contour = this.contour + 1;
    }
    this.remlirbloc();
  }

  Preview() {
    if (this.contour > 1) {
      this.contour = this.contour - 1;
    }

    this.intialisebloc();
    this.remlirbloc();
  }
  intialisebloc() {
    this.BlocB1 = false;
    this.BlocB2 = false;
    this.BlocB3 = false;
    this.BlocB4 = false;
  }

  remlirbloc() {
    if (this.contour == 1) {
      this.BlocB1 = true;
    }
    if (this.contour == 2) {
      this.BlocB2 = true;
    }
    if (this.contour == 3) {
      this.BlocB3 = true;
    }
    if (this.contour == 4) {
      this.BlocB4 = true;
    }
  }

  changebloc(i: any) {
    this.contour = i;
    this.intialisebloc();
    this.remlirbloc();
  }

  Go_list() {
    this.modalService.dismissAll();
    localStorage.setItem('redirecturl', '1');
    setTimeout(() => {
      this.router.navigateByUrl('Logistic/liste');
    }, 500);
  }

  Submit(content: any, content1: any) {
    this.etat = 'For confirmation';
    this.update_vessel(this.id_vessel, content);

    if (this.Form1.status != 'INVALID') {
      this.Vessel_Call_Infomation_add(content);
    } else {
      this.notifier.notify('error', 'Submit Opps !   Form Invalid !');
    }
  }

  submit12(content: any) {
    if (this.typeaction == true) {
      this.shipping_arrival.etat = 'For confirmation';

      this.VesselCallApi.submit(this.shipping_arrival, this.idOrgR).subscribe(
        (res: any) => {
          this.open(content);

          setTimeout(() => {
            this.Go_list();
          }, 1000);
        }
      );
    } else {
    }
  }

  code_country_passenger: any;
  name_country_passenger: any;
  onCountrySelected_passenger(country: Country) {
    this.code_country_passenger = country.alpha2Code;
    this.name_country_passenger = country.name;
  }

  y: any = 0;

  total_pass1: any = 0;
  total_pass2: any = 0;

  generate_pdf2() {
    this.total_pass1 = 0;
    this.total_pass2 = 0;
    let cell = this.shipping_arrival;
    this.y = 0;
    var doc = new jsPDF();
    var img = new Image();
    img.src = './../../../../assets/logo_dark.png';
    doc.setFont('italic');
    doc.addImage(img, 'png', 150, 5, 50, 20);
    doc.setFontSize(10);
    doc.setTextColor('#46B1E3');
    doc.text('Voyage No :' + cell.voyageno, 5, 10);
    doc.text('Shipping Company :' + '_', 5, 15);
    doc.setFontSize(20);
    doc.text('Shipping Arrival', 75, 15);
    doc.setFontSize(11);
    this.y = 40;
    let x1 = 10;
    let x2 = 60;
    let x3 = 110;
    let x4 = 150;
    doc.setFontSize(11);
    doc.setTextColor('#55EC7A');
    doc.text('Vessel Call Information', 10, 40);

    doc.line(5, 43, 5, 65);
    doc.line(5, 43, 190, 43);
    doc.line(190, 43, 190, 65);
    doc.line(5, 65, 190, 65);

    doc.setTextColor('#1D3A66');
    this.y = 50;
    doc.setFontSize(9);

    doc.text('Vessel Name : ' + cell.vessel.vessel, x1, this.y);
    doc.text('Vessel Reg Port : ' + cell.vessel.vesselregport, x2, this.y);
    doc.text('Vessel Type : ' + cell.vessel.vesseltype, x3, this.y);
    doc.text('Vessel Build Year : ' + cell.vessel.vesselbuildyear, x4, this.y);

    doc.line(5, 73, 5, 105);
    doc.line(5, 73, 190, 73);
    doc.line(190, 73, 190, 105);
    doc.line(5, 105, 190, 105);
    this.y = 60;
    doc.setFontSize(9);
    doc.text('Vessel Length : ' + cell.vessel.vessellength, x1, this.y);
    doc.text(
      'Vessel Gross weight : ' + cell.vessel.vesselgrossweight,
      x2,
      this.y
    );
    doc.text('Vessel Net Weight : ' + cell.vessel.vesselnetweight, x3, this.y);
    doc.text('Vessel Draft : ' + cell.vessel.vesseldraft, x4, this.y);

    doc.setFontSize(11);
    doc.setTextColor('#55EC7A');
    doc.text('Ship Agent', 10, 70);
    doc.setTextColor('#1D3A66');

    this.y = 80;
    doc.setFontSize(9);
    doc.text('Shipping Agent : ' + cell.shippingagent, x1, this.y);
    doc.text('Shipping Agent Code : ' + cell.shippingagentcode, x2, this.y);
    doc.text('Owner Name : ' + cell.vessel.ownername + ' ', x3, this.y);
    doc.text('Owner Code : ' + cell.ownercode, x4, this.y);

    this.y = 90;
    doc.setFontSize(9);
    doc.text('Ship Master : ' + cell.shipmaster, x1, this.y);
    doc.text('ETA : ' + cell.eta + ' ', x2, this.y);
    doc.text('ETD : ' + cell.etd, x3, this.y);
    doc.text('Berth Type : ' + cell.berthtype, x4, this.y);

    this.y = 100;
    doc.setFontSize(9);
    doc.text('Preferred Dock : ' + cell.preferreddock, x1, this.y);
    doc.text('Country : ' + cell.vessel.vesselcountryname + ' ', x2, this.y);
    doc.text('Call Sign : ' + cell.callsign, x3, this.y);
    doc.text('Call Sign Type : ' + cell.callsigntype, x4, this.y);

    doc.setFontSize(11);
    doc.setTextColor('#55EC7A');
    doc.text('Port', 10, 110);
    doc.setTextColor('#1D3A66');
    doc.line(5, 113, 5, 145);
    doc.line(5, 113, 190, 113);
    doc.line(190, 113, 190, 145);
    doc.line(5, 145, 190, 145);
    this.y = 120;
    doc.setFontSize(9);
    doc.text('Port Call Name : ' + cell.portofcallname, x1, this.y);
    doc.text('Port Call Code : ' + cell.portofcallcode + ' ', x2, this.y);

    this.y = 130;
    doc.setFontSize(9);
    doc.text('Port Previous Name : ' + cell.previousportname, x1, this.y);
    doc.text('Port Previous Code : ' + cell.previousportcode + ' ', x2, this.y);

    this.y = 140;
    doc.setFontSize(9);
    doc.text('Port Next Name : ' + cell.nextportname, x1, this.y);
    doc.text('Port Next Code : ' + cell.nextportcode, x2, this.y);

    doc.setFontSize(10);
    doc.setTextColor('#55EC7A');
    doc.text('Visit Purpose', 10, 150);
    doc.text('Cargo Summary', 10, 190);
    doc.text('Passenger Summary', 10, 230);

    doc.line(5, 155, 5, 185);
    doc.line(5, 155, 190, 155);
    doc.line(190, 155, 190, 185);
    doc.line(5, 185, 190, 185);

    doc.line(5, 195, 5, 225);
    doc.line(5, 195, 190, 195);
    doc.line(190, 195, 190, 225);
    doc.line(5, 225, 190, 225);

    doc.line(5, 235, 5, 280);
    doc.line(5, 235, 190, 235);
    doc.line(190, 235, 190, 280);
    doc.line(5, 280, 190, 280);
    let i = 0;
    let y = 0;
    let f = 0;
    doc.setFontSize(9);
    doc.setTextColor('#1D3A66');
    for (let cell1 of this.VisitPurposelist) {
      doc.text('reason : ' + cell1.reason + ' ', 15, 160 + i);
      i = i + 7;
    }

    for (let cell2 of this.CargoSummarylist) {
      doc.text('Cargo Code : ' + cell2.CargoCode + ' ', 15, 200 + y);
      doc.text('Cargo Amount : ' + cell2.CargoAmount + ' ', 60, 200 + y);
      doc.text('Operation Code : ' + cell2.OperationCode + ' ', 100, 200 + y);
      y = y + 7;
    }
    for (let cell3 of this.PassengerSummarylist) {
      doc.text(
        'Number of Passenger : ' + cell3.NumberofPassenger + ' ',
        15,
        250 + f
      );
      doc.text(
        'Passenger nationally  : ' + cell3.PassengerNationally + ' ',
        70,
        250 + f
      );

      if (cell3.PassengerNationally == 'Libya') {
        this.total_pass1 = this.total_pass1 + Number(cell3.NumberofPassenger);
      } else {
        this.total_pass2 = this.total_pass2 + Number(cell3.NumberofPassenger);
      }
      f = f + 7;
    }

    doc.text('Total Libyan Passenger : ' + this.total_pass1 + ' ', 15, 240);
    doc.text('Total foreigner Passenger  : ' + this.total_pass2 + ' ', 70, 240);

    doc.save('SHipping_Arrival num' + cell.voyageno + '.pdf');
  }
  list_organsation: portofcall[] = [];
  Getorganisation() {
    this.Organisation.GetAll().subscribe((res: any) => {
      this.portlistofcall = [];
      this.list_organsation = [];
      this.list_organsation = res;
      this.list_organsation = this.list_organsation.filter(
        (e: any) => e.profil == 'Port'
      );
      this.portlistofcall = this.list_organsation;
    });
  }
  CalculPasseger() {
    this.number_l = 0;
    this.number_e = 0;
    for (let arrVciPassengerSummarys of this.PassengerSummarylist) {
      if (arrVciPassengerSummarys.PassengerNationally == 'Libya') {
        this.number_l =
          this.number_l + Number(arrVciPassengerSummarys.NumberofPassenger);
      } else {
        this.number_e =
          this.number_e + Number(arrVciPassengerSummarys.NumberofPassenger);
      }
    }

    this.passeger.controls['totla_passenger'].setValue(this.number_l);
    this.passeger.controls['totla_foreigner'].setValue(this.number_e);
  }

  deletebloc() {
    for (let arrVciPassengerSummarys of this.shipping_arrival
      .arrVciPassengerSummarys) {
      this.PassengerSummarysdelete(arrVciPassengerSummarys.id);
    }

    for (let arrVciVisitPurposes of this.shipping_arrival.arrVciVisitPurposes) {
      this.VisitPurposesdelete(arrVciVisitPurposes.id);
    }

    for (let arrVciCargoSummarys of this.shipping_arrival.arrVciCargoSummarys) {
      this.CargoSummarydelete(arrVciCargoSummarys.id);
    }
  }

  Getcargobyid(id: any) {
    this.ManifestApi.GetById(id).subscribe((res: any) => {
      this.Create_copy_cargo(res, res.vessel);
      setTimeout(() => {
        this.spinner.hide();
      }, 300);
    });
  }

  Create_copy_cargo(cargo: any, Vessel: any) {
    //Cell
    this.Form1.controls['VoyageNo'].setValue(cargo.voyagenumber);
    //vessel
    this.Form1.controls['VesselName'].setValue(Vessel.vessel + '');
    this.Form1.controls['VesselType'].setValue(Vessel.vesseltype + '');
    this.Form1.controls['VesselbuildYear'].setValue(
      Vessel.vesselbuildyear + ''
    );
    this.Form1.controls['VesselRegPort'].setValue(Vessel.vesselregport);
    this.Form1.controls['VesselLength'].setValue(Vessel.vessellength + '');
    this.Form1.controls['VesselGrossWeight'].setValue(
      Vessel.vesselgrossweight + ''
    );
    this.Form1.controls['VesselNetWeight'].setValue(
      Vessel.vesselnetweight + ''
    );
    this.Form1.controls['VesselDraft'].setValue(Number(Vessel.vesseldraft));
    this.code_country = Vessel.vesselcountrycode;
    this.name_country = Vessel.vesselcountryname;
    this.id_vessel = Vessel.id;
  }

  generate_pdf3() {
    let cell = this.shipping_arrival;
    this.total_pass1 = 0;
    this.total_pass2 = 0;
    this.y = 0;
    var doc = new jsPDF();
    var img = new Image();
    img.src = './../../../../assets/logo_dark.png';
    doc.setFont('italic');
    doc.addImage(img, 'png', 150, 5, 50, 20);
    doc.setFontSize(10);
    doc.setTextColor('#46B1E3');
    doc.text('Voyage No :' + cell.voyageno, 5, 10);
    doc.text('Shipping Company :' + '_', 5, 15);
    doc.setFontSize(20);
    doc.text('Shipping Departure', 75, 15);
    doc.setFontSize(11);
    this.y = 40;
    let x1 = 10;
    let x2 = 60;
    let x3 = 110;
    let x4 = 150;
    /*   doc.setFontSize(11);
    doc.setTextColor('#55EC7A');
    doc.text('Vessel Call Information', 10, 40); */

    doc.setDrawColor('#0000FF');
    doc.line(5, 43, 5, 290);
    doc.line(5, 43, 205, 43);
    doc.line(205, 43, 205, 290);
    doc.line(5, 290, 205, 290);

    doc.line(105, 43, 105, 52);
    doc.line(5, 52, 205, 52);
    doc.setFontSize(8);
    doc.setTextColor('#1D3A66');
    doc.text('Vessel Information' + cell.vessel.vesselregport, 40, 50);
    doc.text('General Information' + cell.vessel.vesselregport, 140, 50);

    doc.line(105, 52, 105, 67);
    doc.line(45, 52, 45, 67);
    doc.line(75, 52, 75, 67);

    doc.line(165, 52, 165, 67);
    doc.line(135, 52, 135, 67);

    doc.line(5, 67, 205, 67);

    doc.line(105, 67, 105, 82);

    doc.line(55, 67, 55, 82);

    doc.line(165, 67, 165, 82);
    doc.line(135, 67, 135, 82);

    doc.line(5, 82, 205, 82);

    doc.line(45, 82, 45, 97);
    doc.line(75, 82, 75, 97);

    doc.line(5, 97, 205, 97);

    doc.line(105, 82, 105, 97);
    doc.line(170, 82, 170, 97);
    doc.line(130, 82, 130, 97);

    doc.line(105, 97, 105, 112);
    doc.line(55, 97, 55, 112);

    doc.line(140, 97, 140, 112);
    doc.line(165, 97, 165, 112);
    doc.line(5, 112, 205, 112);
    //bloc1
    doc.text('Vessel Name', 7, 57);
    doc.text('Vessel Type ', 47, 57);
    doc.text('Vessel Build Year', 77, 57);
    doc.text('Voyage no', 107, 57);
    doc.text('Ship Agent', 137, 57);
    doc.text('Port Of Call', 167, 57);

    //bloc2

    doc.text('Vessel Country Name', 7, 71);
    doc.text('Vessel Country Code', 57, 71);
    doc.text('Owner Name', 107, 71);
    doc.text('Previous Port Name', 167, 71);
    doc.text('Next Port Name', 137, 71);

    //bloc3
    doc.text('Vessel Draft', 7, 86);
    doc.text('Vessel Length ', 47, 86);
    doc.text('Vessel Reg Port', 77, 86);
    doc.text('ETA', 107, 86);
    doc.text('ETD', 135, 86);
    doc.text('Call sign', 171, 86);

    //bloc4

    doc.text('Vessel Gross Weight', 7, 101);
    doc.text('Vessel Net Weight', 57, 101);

    doc.text('Dangerous goods', 107, 101);
    doc.text('Preferred Dock', 141, 101);
    doc.text('Etat', 167, 101);

    doc.line(5, 125, 205, 125);
    doc.line(5, 135, 205, 135);
    doc.line(40, 125, 40, 170);
    doc.line(85, 125, 85, 170);
    doc.line(5, 170, 205, 170);
    doc.setTextColor('#1D3A66');
    doc.setFontSize(10);

    doc.text('Passenger Summary', 95, 118);
    doc.setFontSize(8);
    doc.text('reference', 8, 131);
    doc.text('Passenger Nationally', 45, 131);
    doc.text('Number of Passenger', 90, 131);
    doc.setTextColor('#000');
    doc.setFontSize(9);

    //Ecriture !
    //bloc1
    doc.text(cell.vessel.vessel + '', 9, 64);
    doc.text(cell.vessel.vesseltype + '', 49, 64);
    doc.text(cell.vessel.vesselbuildyear + '', 79, 64);
    doc.text(cell.voyageno + '', 109, 64);
    doc.text(cell.shippingagent + '', 139, 64);
    doc.text(cell.portofcallname + '', 169, 64);

    //bloc2

    doc.text(cell.vessel.vesselcountryname + '', 9, 78);
    doc.text(cell.vessel.vesselcountrycode + '', 59, 78);
    doc.text(cell.ownername + '', 109, 78);
    doc.text(cell.nextportname + '', 169, 78);
    doc.text(cell.previousportname + '', 139, 78);

    //bloc3
    doc.text(cell.vessel.vesseldraft + '', 9, 93);
    doc.text(cell.vessel.vessellength + '', 49, 93);
    doc.text(cell.vessel.vesselregport + '', 79, 93);
    doc.text(cell.eta + '', 109, 93);
    doc.text(cell.etd + '', 137, 93);
    doc.text(cell.callsign + '', 173, 93);

    //bloc4

    doc.text(cell.vessel.vesselgrossweight + '', 9, 108);
    doc.text(cell.vessel.vesselnetweight + '', 59, 108);

    doc.text(cell.dangerousgoods + '', 109, 108);
    doc.text(cell.preferreddock + '', 143, 108);
    doc.text(cell.etat + '', 169, 108);
    doc.line(5, 180, 205, 180);
    doc.line(5, 190, 205, 190);
    doc.line(5, 200, 205, 200);
    doc.line(40, 190, 40, 230);
    doc.line(5, 230, 205, 230);

    doc.line(5, 245, 205, 245);
    doc.line(5, 255, 205, 255);
    doc.line(40, 245, 40, 290);
    doc.line(80, 245, 80, 290);
    doc.line(130, 245, 130, 290);
    doc.line(5, 290, 205, 290);

    doc.setTextColor('#1D3A66');
    doc.setFontSize(10);

    doc.text('Visit Purpose', 95, 185);
    doc.setFontSize(8);
    doc.text('reference', 8, 195);
    doc.text('Reason', 105, 195);

    doc.setTextColor('#1D3A66');
    doc.setFontSize(10);

    doc.text('Cargo Summay', 95, 240);
    doc.setFontSize(8);
    doc.text('reference', 8, 250);
    doc.text('Cargo Code', 45, 250);
    doc.text('Cargo Amount', 85, 250);
    doc.text('Operation Code ', 135, 250);

    doc.line(105, 170, 105, 180);
    doc.setTextColor('#000');
    doc.setFontSize(9);

    let f = 260;
    let r = 205;
    let e = 140;

    let g = 0;
    for (let cell1 of this.VisitPurposelist) {
      g = g + 1;
      doc.text(g + ' ', 15, r);
      doc.text(cell1.reason + ' ', 45, r);
      r = r + 7;
    }

    let z = 0;
    for (let cell3 of this.PassengerSummarylist) {
      z = z + 1;
      doc.text(z + ' ', 9, e);
      doc.text(cell3.PassengerNationally + ' ', 47, e);

      doc.text(cell3.NumberofPassenger + ' ', 92, e);

      if (cell3.PassengerNationally == 'Libya') {
        this.total_pass1 = this.total_pass1 + Number(cell3.NumberofPassenger);
      } else {
        this.total_pass2 = this.total_pass2 + Number(cell3.NumberofPassenger);
      }
      e = e + 7;
    }

    doc.text('Total Libyan Passenger : ' + this.total_pass1 + ' ', 10, 175);
    doc.text(
      'Total foreigner Passenger  : ' + this.total_pass2 + ' ',
      115,
      175
    );

    let i = 0;
    for (let cell2 of this.CargoSummarylist) {
      i = i + 1;
      doc.text(i + ' ', 15, f);
      doc.text(cell2.CargoCode + ' ', 45, f);
      doc.text(cell2.CargoAmount + ' ', 85, f);
      doc.text(cell2.OperationCode + ' ', 135, f);
      f = f + 7;
    }

    doc.save('SHipping_Arrival_num' + cell.voyageno + '.pdf');
  }

  submit_flux: boolean = false;
  Getpremision() {
    let premission = localStorage.getItem('premission_SDP');

    if (premission?.indexOf('S') != -1) {
      this.typeaction = true;
      this.color_submit = '#1671b3';
      this.submit_flux = true;
    }
  }

  finishaction(content: any) {
    this.VesselCallApi.finishaction(
      this.Vessel_Call_Infomation_add(content)
    ).subscribe((res: any) => {
      setTimeout(() => {
        this.Go_list();
      }, 400);
    });
  }
  idUser: any;
  GetUser() {
    this.Userauth.Getuserbyusername().subscribe((res: any) => {
      this.idUser = res.id;
    });
  }
}
