<link
  rel="stylesheet"
  href="https://use.fontawesome.com/releases/v5.5.0/css/all.css"
  integrity="sha384-B4dIYHKNBt8Bc12p+WXckhzcICo0wtJAoU8YZTY5qE0Id1GSseTk6S+L3BlXeVIU"
  crossorigin="anonymous"
/>

<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/css/all.css"
/>

<div class="c1" [ngStyle]="{ direction: direction }">
  <div class="Trac-2081"></div>

  <div>
    <h2 class="titre-page">{{ languea.titre1 }}</h2>

    <div class="about-border"></div>
  </div>
</div>

<div
  class="row"
  style="padding-bottom: 2%; padding-left: 2%"
  [ngStyle]="{ direction: direction }"
>
  <div class="col-lg-2 divmenu">
    <div class="bloc_menu">
      <div>
        <mat-icon style="color: rgba(71, 178, 228, 1); font-size: 35px"
          >find_in_page</mat-icon
        >
      </div>
      <div
        (click)="changebloc(1, languea.generalinformation)"
        class="titre_bloc"
        [ngStyle]="{
          'background-color': BlocB1 ? '#d4f1ff' : '#ffffff'
        }"
      >
        <label style="cursor: pointer" class="vessel_call_information"
          >{{ languea.generalinformation }}
        </label>

        <div style="padding: 2%">
          <mat-icon
            *ngIf="Form1.valid && selecteddischargingport != null"
            class="icon-menu"
            >check_circle</mat-icon
          >
          <mat-icon
            *ngIf="
              (Form1.invalid || selecteddischargingport == null) &&
              BlocB1 == false
            "
            class="icon-menu1"
            >new_releases</mat-icon
          >

          <mat-icon
            *ngIf="(Form1.invalid || selecteddischargingport == null) && BlocB1"
            class="icon-menu1"
            >edit</mat-icon
          >
        </div>
      </div>

      <div class="line">
        <div class="vl"></div>
      </div>
    </div>
    <div class="bloc_menu">
      <div>
        <mat-icon style="color: rgba(71, 178, 228, 1); font-size: 35px"
          >directions_boat</mat-icon
        >
      </div>
      <div
        (click)="changebloc(2, languea.transport)"
        class="titre_bloc"
        [ngStyle]="{
          'background-color': BlocB2 ? '#d4f1ff' : '#ffffff'
        }"
      >
        <label style="cursor: pointer" class="vessel_call_information"
          >{{ languea.transport }}
        </label>

        <div style="padding: 2%">
          <mat-icon *ngIf="FormTransport.valid" class="icon-menu"
            >check_circle</mat-icon
          >
          <mat-icon
            *ngIf="FormTransport.invalid && BlocB2 == false"
            class="icon-menu1"
            >new_releases</mat-icon
          >
          <mat-icon *ngIf="FormTransport.invalid && BlocB2" class="icon-menu1"
            >edit</mat-icon
          >
        </div>
      </div>
    </div>
    <div class="line">
      <div class="vl"></div>
    </div>
    <div class="bloc_menu">
      <div>
        <mat-icon style="color: rgba(71, 178, 228, 1); font-size: 35px"
          >group_work</mat-icon
        >
      </div>
      <div
        (click)="changebloc(3, languea.totals)"
        class="titre_bloc"
        [ngStyle]="{
          'background-color': BlocB3 ? '#d4f1ff' : '#ffffff'
        }"
      >
        <label style="cursor: pointer" class="vessel_call_information">{{
          languea.totals
        }}</label>
        <div>
          <mat-icon *ngIf="FormTotals.valid" class="icon-menu"
            >check_circle</mat-icon
          >
          <mat-icon
            *ngIf="FormTotals.invalid && BlocB3 == false"
            class="icon-menu1"
            >new_releases</mat-icon
          >
          <mat-icon *ngIf="FormTotals.invalid && BlocB3" class="icon-menu1"
            >edit</mat-icon
          >
        </div>
      </div>
    </div>
    <div class="line">
      <div class="vl"></div>
    </div>
    <div class="bloc_menu">
      <div>
        <mat-icon style="color: rgba(71, 178, 228, 1); font-size: 35px"
          >assignment</mat-icon
        >
      </div>
      <div
        (click)="changebloc(4, languea.waybills)"
        class="titre_bloc"
        [ngStyle]="{
          'background-color': BlocB4 ? '#d4f1ff' : '#ffffff'
        }"
      >
        <label style="cursor: pointer" class="vessel_call_information">{{
          languea.waybills
        }}</label>

        <div>
          <mat-icon *ngIf="Waybills.length > 0" class="icon-menu"
            >check_circle</mat-icon
          >
          <mat-icon
            *ngIf="Waybills.length == 0 && BlocB4 == false"
            class="icon-menu1"
            >new_releases</mat-icon
          >

          <mat-icon *ngIf="Waybills.length == 0 && BlocB4" class="icon-menu1"
            >edit</mat-icon
          >
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-8 blocform">
    <div
      [ngStyle]="{
        display: BlocB1 ? 'block' : 'none'
      }"
      class="div3"
    >
      <div class="header-border" style="min-height: 600px">
        <h2 class="heading">
          <div style="display: flex">
            <div class="padding_class">
              <h1 style="padding-right: 60%">
                <div
                  class="div1"
                  [ngStyle]="{ 'background-color': btn_backround1 }"
                >
                  <span class="header_titre" [ngStyle]="{ color: color1 }">{{
                    titre_bloc
                  }}</span>
                </div>
              </h1>
            </div>
          </div>
        </h2>
        <div class="class_form">
          <form
            [formGroup]="Form1"
            (ngSubmit)="Next(languea.transport)"
            autocomplete="off"
            form
          >
            <div class="row" style="padding: 1%; min-height: 440px">
              <div class="col-lg-6">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    (keyup)="style($event)"
                    type="number"
                    min="0"
                    placeholder="{{ languea.customsoffice }}"
                    formControlName="customoffice"
                    required
                  />
                </mat-form-field>
              </div>

              <div class="col-lg-6">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="{{ languea.vesselname }}"
                    formControlName="vessel"
                    maxlength="40"
                    required
                  />
                </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-form-field class="full-width">
                  <input
                    type="text"
                    matInput
                    type="number"
                    min="0"
                    placeholder="{{ languea.voyageno }}"
                    formControlName="voyagenumber"
                    required
                  />
                </mat-form-field>
              </div>

              <div class="col-lg-6">
                <mat-form-field>
                  <input
                    matInput
                    [matDatepicker]="picker"
                    [formControl]="dateETA"
                    placeholder="{{ languea.dateofdeparture }}"
                    required
                    (dateInput)="onchangedateETA($event.value)"
                    style="color: #1d3a66"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="col-lg-6">
                <mat-form-field>
                  <input
                    matInput
                    [matDatepicker]="picker1"
                    [formControl]="dateETD"
                    placeholder="{{ languea.dateofarrival }}"
                    required
                    (dateInput)="onchangedateETD($event.value)"
                    style="color: #1d3a66"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker1"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="col-lg-6">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    type="time"
                    placeholder="{{ languea.timeofarrival }}"
                    formControlName="timeofarrival"
                    required
                  />
                </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    type="number"
                    min="0"
                    placeholder="{{ languea.placeofdeparture }}"
                    formControlName="placeofdeparture"
                    required
                  />
                </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    type="number"
                    min="0"
                    placeholder="{{ languea.placeofdestination }}"
                    formControlName="placeofdistination"
                    required
                  />
                </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    type="number"
                    min="0"
                    placeholder="{{ languea.carrier }}"
                    formControlName="carrier"
                    required
                  />
                </mat-form-field>
              </div>

              <div class="col-lg-6">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    type="text"
                    placeholder="{{ languea.finaldestination }}"
                    formControlName="finaldestination"
                    required
                  />
                </mat-form-field>
              </div>

              <div class="col-lg-6">
                <ng-select
                  [items]="portlistofcall"
                  (change)="changedischargingport($event)"
                  bindLabel="name"
                  placeholder="{{ languea.discharginport }}"
                  [(ngModel)]="selecteddischargingport"
                >
                </ng-select>
                <li
                  style="color: rgb(255, 0, 0)"
                  *ngIf="selecteddischargingport == null && Form1.valid"
                >
                  {{ languet.required }}
                </li>
              </div>

              <div class="col-lg-6">
                <ng-select
                  formControlName="loadingport"
                  [items]="portlist"
                  (change)="changeloadingport($event)"
                  bindLabel="name"
                  placeholder="{{ languea.loadingport }}"
                  [(ngModel)]="selectedportPrevious"
                >
                </ng-select>
              </div>
            </div>
            <div class="container text-right nextbtn">
              <button type="submit" class="btn button1">
                <span class="next"> {{ languea.next }} </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      [ngStyle]="{
        display: BlocB2 ? 'block' : 'none'
      }"
    >
      <div class="header-border" style="min-height: 600px">
        <h2 class="heading">
          <div style="display: flex">
            <div class="padding_class">
              <h1 style="padding-right: 60%">
                <div
                  class="div1"
                  [ngStyle]="{ 'background-color': btn_backround1 }"
                >
                  <span class="header_titre" [ngStyle]="{ color: color1 }">{{
                    titre_bloc
                  }}</span>
                </div>
              </h1>
            </div>
          </div>
        </h2>
        <div class="class_form">
          <form
            [formGroup]="FormTransport"
            (ngSubmit)="Next(languea.totals)"
            autocomplete="off"
            FormTransport
          >
            <div class="row" style="padding: 1%; min-height: 440px">
              <div class="col-lg-6">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    type="text"
                    placeholder="{{ languea.mode }}"
                    formControlName="mode"
                    required
                  />
                </mat-form-field>
              </div>

              <div class="col-lg-6">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="{{ languea.identity }}"
                    formControlName="identity"
                    required
                  />
                </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-select-country
                  style="color: #1d3a66"
                  placeHolder="{{ languea.country }}"
                  (onCountrySelected)="onCountrySelected($event)"
                  >>
                </mat-select-country>
              </div>

              <div class="col-lg-6">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    type="text"
                    placeholder="{{ languea.place }}"
                    formControlName="place"
                    required
                  />
                </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    type="text"
                    placeholder="{{ languea.registration }}"
                    formControlName="registration"
                    required
                  />
                </mat-form-field>
              </div>

              <div class="col-lg-6">
                <mat-form-field>
                  <input
                    matInput
                    placeholder="{{ languea.dateregistration }}"
                    [formControl]="datef"
                    [matDatepicker]="picker3"
                    style="color: #1d3a66"
                    (dateInput)="onchangedate($event.value)"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker3"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker3></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="col-lg-8">
                <div class="md-form">
                  <textarea
                    type="text"
                    id="form7"
                    placeholder="{{ languea.description }}"
                    formControlName="master"
                    class="md-textarea form-control"
                    mdbInput
                    style="width: 150%"
                  ></textarea>
                  <label for="form7"></label>
                </div>
              </div>
            </div>
            <div>
              <div
                class="div_back"
                [ngStyle]="{
                  float: langue === 'AR' ? 'right' : 'left'
                }"
              >
                <button
                  type="button"
                  class="btn buttonp"
                  (click)="Preview(languea.generalinformation)"
                >
                  <span class="next"> {{ languea.previous }} </span>
                </button>
              </div>
              <div
                class="div_next"
                [ngStyle]="{
                  float: langue === 'AR' ? 'left' : 'right'
                }"
              >
                <button
                  type="submit"
                  class="btn button1"
                  (click)="Next(languea.totals)"
                >
                  <span class="next"> {{ languea.next }} </span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      [ngStyle]="{
        display: BlocB3 ? 'block' : 'none'
      }"
    >
      <div class="header-border" style="min-height: 600px">
        <h2 class="heading">
          <div style="display: flex">
            <div class="padding_class">
              <h1 style="padding-right: 60%">
                <div
                  class="div1"
                  [ngStyle]="{ 'background-color': btn_backround1 }"
                >
                  <span class="header_titre" [ngStyle]="{ color: color1 }">{{
                    titre_bloc
                  }}</span>
                </div>
              </h1>
            </div>
          </div>
        </h2>
        <div class="class_form">
          <form
            [formGroup]="FormTotals"
            (ngSubmit)="Next(languea.waybills)"
            autocomplete="off"
            FormTotals
          >
            <div class="row" style="padding: 1%; min-height: 440px">
              <div class="col-lg-6">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    type="number"
                    min="0"
                    placeholder="{{ languea.bills }}"
                    formControlName="bills"
                    required
                  />
                </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-form-field class="full-width">
                  <input
                    type="text"
                    matInput
                    type="number"
                    min="0"
                    placeholder="{{ languea.packages }}"
                    formControlName="packages"
                    required
                  />
                </mat-form-field>
              </div>

              <div class="col-lg-6">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    type="number"
                    min="0"
                    placeholder="{{ languea.containers }}"
                    formControlName="containers"
                    required
                  />
                </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    type="number"
                    min="0"
                    placeholder="{{ languea.grossmass }}"
                    formControlName="grossmass"
                    required
                  />
                </mat-form-field>
              </div>
            </div>

            <div>
              <div
                class="div_back"
                [ngStyle]="{
                  float: langue === 'AR' ? 'right' : 'left'
                }"
              >
                <button
                  type="button"
                  class="btn buttonp"
                  (click)="Preview(languea.transport)"
                >
                  <span class="next"> {{ languea.previous }} </span>
                </button>
              </div>
              <div
                class="div_next"
                [ngStyle]="{
                  float: langue === 'AR' ? 'left' : 'right'
                }"
              >
                <button type="submit" class="btn button1">
                  <span class="next"> {{ languea.next }} </span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      [ngStyle]="{
        display: BlocB4 ? 'block' : 'none'
      }"
    >
      <div
        *ngIf="Waybills.length == 0"
        style="text-align: center; display: block"
      >
        <div>
          <img
            src="./../../../../assets/icon.png"
            style="width: 285px; height: 205px"
          />
        </div>
        <div style="padding-top: 2%">
          <span class="span1"> {{ languea.emptylist }} </span>
        </div>
        <div style="padding-top: 3%">
          <span class="span2"> {{ languea.textmessage3 }} </span>
        </div>
        <div style="padding-top: 2%">
          <button type="button" class="btn button3" (click)="open(content2)">
            <div style="display: flex">
              <div>
                <mat-icon
                  style="color: #ffffff; font-size: 30px; font-weight: 900"
                  >add</mat-icon
                >
              </div>
              <div style="padding-top: 3px">
                <span class="add"> {{ languea.new }} </span>
              </div>
            </div>
          </button>
        </div>
      </div>
      <div *ngIf="Waybills.length > 0" style="min-height: 600px">
        <div style="padding-top: 2%">
          <button type="button" class="btn button3" (click)="open(content2)">
            <div style="display: flex">
              <div>
                <mat-icon
                  style="color: #ffffff; font-size: 30px; font-weight: 900"
                  >add</mat-icon
                >
              </div>
              <div style="padding-top: 3px">
                <span class="add"> {{ languea.new }} </span>
              </div>
            </div>
          </button>
        </div>
        <div class="row" style="padding: 3%; min-height: 530px">
          <div
            class="col-lg-4"
            style="padding-top: 1%"
            *ngFor="let cargo of Waybills"
          >
            <mat-card class="card2">
              <mat-card-content style="text-align: center">
                <h5 class="titre_card">Waybills</h5>
              </mat-card-content>
              <mat-card-subtitle>
                <label class="subtitle_card"
                  >{{ languea.blreferencenumber }} :
                  {{ cargo.blreferencenumber }} </label
                ><br />
                <label class="subtitle_card"
                  >{{ languea.blnumber }}: {{ cargo.blnumber }}</label
                ><br />
                <label class="subtitle_card">Bl type : {{ cargo.bltype }}</label
                ><br />
                <label class="subtitle_card"
                  >{{ languea.exportername }}: {{ cargo.exportername }}</label
                ><br />
                <label class="subtitle_card"
                  >{{ languea.consigneename }} :
                  {{ cargo.consigneename }}</label
                >
              </mat-card-subtitle>

              <mat-card-actions
                style="padding-left: 25%; position: absolute; bottom: 20px"
              >
                <button
                  mat-button
                  (click)="Waybills_remove(cargo)"
                  class="btn btn-light shadow-lg"
                  style="font-size: 14px"
                  class="button_delete"
                >
                  <mat-icon style="color: rgba(71, 178, 228, 1)"
                    >delete</mat-icon
                  >
                  {{ languea.delete }}
                </button>
              </mat-card-actions>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-1">
    <div class="blocaction">
      <div *ngIf="saveandfinish_etat" style="padding: 3%">
        <div class="row">
          <div (click)="finishaction(content3)" class="div_action">
            <mat-icon
              class="icon_action"
              [ngStyle]="{
                color:
                  Form1.valid &&
                  FormTransport.valid &&
                  FormTotals.valid &&
                  selecteddischargingport != null
                    ? '#1671b3'
                    : '#b1d9f5'
              }"
              style="direction: revert"
              >save</mat-icon
            >
          </div>
        </div>
        <div>
          <label class="labaction" style="width: 130px">
            {{ languet.finishaction }}
          </label>
        </div>
      </div>

      <div style="padding-top: 3%">
        <div class="row">
          <div class="div_action" (click)="Save(content3)">
            <mat-icon
              class="icon_action"
              [ngStyle]="{
                color:
                  Form1.valid &&
                  FormTransport.valid &&
                  FormTotals.valid &&
                  selecteddischargingport != null
                    ? '#1671b3'
                    : '#b1d9f5'
              }"
              style="direction: revert"
              >save_alt</mat-icon
            >
          </div>
        </div>
        <div>
          <label class="labaction"> {{ languea.save }} </label>
        </div>
      </div>
      <div *ngIf="submit_etat" style="padding-top: 3%">
        <div class="row">
          <div (click)="submit12(content4)" class="div_action">
            <mat-icon
              class="icon_action"
              [ngStyle]="{ color: color_submit }"
              style="direction: revert"
              >send</mat-icon
            >
          </div>
        </div>
        <div>
          <label class="labaction"> {{ languea.submit }} </label>
        </div>
      </div>
      <div style="padding-top: 3%">
        <div class="row">
          <div (click)="Go_list()" class="div_action">
            <mat-icon class="icon_action" style="direction: revert"
              >format_list_bulleted</mat-icon
            >
          </div>
        </div>
        <div>
          <label class="labaction"> {{ languea.list }} </label>
        </div>
      </div>
      <div style="padding-top: 3%">
        <div class="row">
          <div (click)="fileInput.click()" class="div_action">
            <mat-icon class="icon_action" style="direction: revert"
              >cloud_upload</mat-icon
            >
          </div>
        </div>
        <input
          style="display: none"
          type="file"
          #fileInput
          (change)="onFileChange($event)"
          name="uploadFile"
          multiple="multiple"
        />
        <div>
          <label class="labaction"> {{ languea.upload }} </label>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content2 let-modal>
  <div class="modal-header text-center">
    <h4 class="modal-title w-100" id="modal-basic-title">
      {{ languea.waybills }}
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>

  <div class="modal-body" [ngStyle]="{ direction: direction }">
    <div class="bodymodal">
      <form [formGroup]="FormWaybills" autocomplete="off" FormWaybills>
        <div class="row">
          <div class="col-lg-6">
            <mat-form-field class="full-width">
              <input
                matInput
                required
                type="text"
                placeholder="{{ languea.blreferencenumber }}"
                formControlName="blreferencenumber"
              />
            </mat-form-field>
          </div>

          <div class="col-lg-6">
            <mat-form-field class="full-width">
              <input
                matInput
                required
                type="text"
                placeholder="{{ languea.blnumber }}"
                formControlName="blnumber"
              />
            </mat-form-field>
          </div>

          <div class="col-lg-6">
            <mat-form-field class="full-width">
              <input
                matInput
                required
                type="text"
                placeholder="{{ languea.bltype }}"
                formControlName="bltype"
              />
            </mat-form-field>
          </div>

          <div class="col-lg-6">
            <mat-form-field class="full-width">
              <input
                matInput
                required
                type="text"
                placeholder="{{ languea.exportername }}"
                formControlName="exportername"
              />
            </mat-form-field>
          </div>

          <div class="col-lg-6">
            <mat-form-field class="full-width">
              <input
                matInput
                required
                type="text"
                placeholder="{{ languea.consigneename }}"
                formControlName="consigneename"
              />
            </mat-form-field>
          </div>

          <div class="col-lg-6">
            <mat-form-field class="full-width">
              <input
                matInput
                required
                type="number"
                min="0"
                placeholder="{{ languea.containers }}"
                formControlName="containers"
              />
            </mat-form-field>
          </div>

          <div class="col-lg-6">
            <mat-form-field class="full-width">
              <input
                matInput
                required
                type="number"
                min="0"
                placeholder="{{ languea.packages }}"
                formControlName="packages"
              />
            </mat-form-field>
          </div>

          <div class="col-lg-6">
            <mat-form-field class="full-width">
              <input
                matInput
                required
                type="number"
                min="0"
                placeholder="{{ languea.grossmass }}"
                formControlName="grossmass"
              />
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="text-center" style="padding-bottom: 2%">
    <button
      [disabled]="FormWaybills.invalid"
      type="button"
      class="btn button2"
      (click)="Waybills_add()"
    >
      <span class="Save"> {{ languea.save }}</span>
    </button>
  </div>
</ng-template>

<ng-template #content3>
  <div class="modal-body rounded-8">
    <div style="text-align: center">
      <div>
        <span
          class="material-icons green-color"
          style="
            font-size: 100px;
            color: #41d96f;
            text-align: center;
            font-weight: 800;
          "
          >check_circle
        </span>
      </div>
      <div>
        <label
          style="
            font-family: Montserrat;
            font-size: 26px;
            padding-top: 2%;
            font-weight: bold;
            color: #073866;
          "
        >
          {{ languet.titremodal }}</label
        >
      </div>
      <div>
        <label
          style="
            width: 310px;
            font-size: 16px;
            font-weight: bold;
            padding-top: 40px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.77;
            letter-spacing: normal;
            text-align: center;
          "
        >
          {{ languet.descriptionsave }}</label
        >
      </div>
      <div class="text-center" style="padding-bottom: 2%; padding-top: 6%">
        <button type="button" class="btn button2" (click)="Go_list()">
          <span class="Save"> {{ languea.list }}</span>
        </button>
      </div>
      <div class="text-center" style="padding-bottom: 2%; padding-top: 3%">
        <button type="button" class="btn button4" (click)="generate_pdf()">
          <!-- <mat-icon style="color: #a9a9a9; font-size: 30px; padding-top: 2%"
            >print</mat-icon
          > -->
          <span class="print"> {{ languet.print }}</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.5.1/jspdf.umd.min.js"></script>

<ng-template #content4>
  <div class="modal-body rounded-8">
    <div style="text-align: center">
      <div>
        <span
          class="material-icons green-color"
          style="
            font-size: 100px;
            color: #41d96f;
            text-align: center;
            font-weight: 800;
          "
          >check_circle
        </span>
      </div>
      <div>
        <label
          style="
            font-family: Montserrat;
            font-size: 26px;
            padding-top: 2%;
            font-weight: bold;
            color: #073866;
          "
        >
          {{ languet.titremodal }}</label
        >
      </div>
      <div>
        <label
          style="
            width: 310px;
            font-size: 16px;
            font-weight: bold;
            padding-top: 40px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.77;
            letter-spacing: normal;
            text-align: center;
          "
        >
          {{ languet.descriptionsubmit }}</label
        >
      </div>
    </div>
  </div>
</ng-template>
