import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class PiecesJService {
  url: any;

  Spring_serveur: string = "api/";

  headers: any;
  constructor(private router: Router, private http: HttpClient) {
    this.url = environment.UrlGetway + this.Spring_serveur;
  }
  public Get() {
    const headers1 = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http.get<any>(this.url + "", { headers: headers1 });
  }

  public GetByTce(id: any) {
    const headers1 = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http.get<any>(this.url + "files/" + id + "/tce", {
      headers: headers1,
    });
  }

  public post(id: any, formdata: any) {
    const headers1 = new HttpHeaders({
      "Content-Type": "application/json",
    });

    return this.http.post(this.url + "upload/" + id, formdata);
  }

  public update(formData: any) {
    const headers1 = new HttpHeaders({
      "Content-Type": "application/json",
    });

    return this.http.post(this.url + "submit", {
      headers: headers1,
    });
  }
  public delete(id: any) {
    return this.http.delete<any>(`${this.url + "" + "/" + id + "/"}`);
  }
}
