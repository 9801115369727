import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { WebSocketSubject } from 'rxjs/webSocket';
import {
  FormBuilder,
  FormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import jsPDF from 'jspdf';
import { MessageUserService } from 'src/Services/MessagesUser/message-user.service';
import { OrganisationService } from 'src/Services/Organisation/organisation.service';
import { portofcall } from '../../Models/portofcall';
import { VesselCallInformationService } from '../../Shipping_Arrival/Service/VesselCall/vessel-call-information.service';
import { ShipdockingServiceService } from '../Service/shipdocking-service.service';
import Langue from '../../../../../assets/langue.json';
import { Title } from '@angular/platform-browser';
import { UserService } from 'src/Services/User/user.service';
import { AffectationService } from 'src/Services/Affectation/affectation.service';
import { NotifierService } from 'angular-notifier';
import { NotificationService } from 'src/Services/Notification/notification.service';

@Component({
  selector: 'app-liste-shipdocking',
  templateUrl: './liste.component.html',
  styleUrls: ['./liste.component.css'],
})
export class ListeComponent implements OnInit {
  langue: any;
  direction: any;
  languef: any;
  langue_list: any[] = Langue;
  languea: any;
  languem: any;
  languet: any;

  statuslist: any[];
  itemslist: any[];
  displayedColumns: string[] = [
    'select',
    'vesselcallnumber',
    'portofdeclaration',
    'assign',
    'birthstatus',
    'gangsnumber',
    'etat',
    'action',
  ];

  displayedColumnsActions: string[] = [
    'id',
    'status',
    'startDate',
    'affectedto',
    'action',
  ];

  options: any[] = [{ name: 'Berthing Request' }, { name: 'Hold Berthing' }];
  option: any;
  dataSource: MatTableDataSource<any>;
  startdate: any;
  birthstatus = new UntypedFormControl();
  typerecherche: any;
  startDate = '';
  endDate = '';
  enddate: any;
  listrecherchr: any;
  selectedstatus: String | any;
  selectedcondition: String | any;

  Formship: FormGroup;
  displayFn(object?: any): any | undefined {
    return object ? object.name : undefined;
  }
  constructor(
    private modalService: NgbModal,
    private MessagesUser: MessageUserService,
    private organisationService: OrganisationService,
    private router: Router,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private ShipdockingApi: ShipdockingServiceService,
    private callofinforamtions: VesselCallInformationService,
    private titleService: Title,
    private affecationService: AffectationService,
    private notifier: NotifierService,
    private notificationApi: NotificationService
  ) {
    this.Formship = this.formBuilder.group({
      vesselcallnumber: ['', Validators.required],
      dischargingport: ['', Validators.required],
      gangsnumber: ['', Validators.required],
    });

    if (localStorage.getItem('langue') != null) {
      this.langue = localStorage.getItem('langue') + '';
    } else {
      this.langue = 'AR';
    }

    if (this.langue == 'AR') {
      this.direction = 'rtl';
      this.languef = this.langue_list[0]['AR'].tablelist;
      this.languea = this.langue_list[0]['AR'].SD;
      this.languem = this.langue_list[0]['AR'].messageuser;
      this.languet = this.langue_list[0]['AR'].modal_text;
    }

    if (this.langue == 'EN') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['EN'].tablelist;
      this.languea = this.langue_list[0]['EN'].SD;
      this.languem = this.langue_list[0]['EN'].messageuser;
      this.languet = this.langue_list[0]['EN'].modal_text;
    }

    if (this.langue == 'FR') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['FR'].tablelist;
      this.languea = this.langue_list[0]['FR'].SD;
      this.languem = this.langue_list[0]['FR'].messageuser;
      this.languet = this.langue_list[0]['FR'].modal_text;
    }

    this.itemslist = [
      { name: this.languea.numescale, code: 'num_escale', id: 1 },
      {
        name: this.languea.portofdeclaration,
        code: 'portofdeclarationnm',
        id: 1,
      },
    ];

    this.statuslist = [
      { id: 1, name: this.languet.save, code: 'Save' },
      { id: 2, name: this.languet.forconfirmation, code: 'For confirmation' },
      { id: 3, name: this.languet.confirm, code: 'Confirmed' },
    ];
    this.titleService.setTitle(this.languet.entete);
    this.dataSource = new MatTableDataSource(this.ship_dockinglist);

    this.RoleTeammanager = this.userService.ValidRole('Team manager');
    this.RoleAgent = this.userService.ValidRole('Agent');
  }

  ship_dockinglist: any[] = [];
  selection = new SelectionModel<any>(true, []);
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  @Input() profil: string;
  premission: any;
  ngOnInit(): void {
    this.birthstatus.setValue(this.options[0]);

    // this.Websocket_sd();
    this.GetIdUser();

    if (this.profil == 'Port') {
      this.premission = 'SD_A2.1_LE';
      this.actionlist = [{ id: '2', name: this.languef.submit }];
    } else {
      this.premission = 'SD_A1.1_LE';
      this.actionlist = [
        // { id: '1', name: this.languef.save },
        { id: '2', name: this.languef.submit },
      ];
    }
  }
  selectedtype: any;
  changetype(event: any) { }
  onchangedatestart(event: any) { }
  onchangedateend(event: any) { }

  changestatus(event: any) {
    if (event == undefined) {
      this.GetShipDocking();
    } else {
      this.id_search1 = 3;
      this.ship_dockinglist_1 = this.ship_dockinglist.filter(
        (e) => e.etat == event.code
      );
      setTimeout(() => {
        this.dataSource = new MatTableDataSource(this.ship_dockinglist_1);
        this.dataSource.paginator = this.paginator;
      }, 500);
    }
  }

  list_print: any[] = [];
  list_print_f: any[] = [];
  printdoc() {
    this.list_print = [];
    this.list_print_f = [];
    this.selection.selected.forEach((s) => this.list_print.push(s));

    for (let ship of this.list_print) {
      this.callofinforamtions
        .GetById(ship.vesselcallno)
        .subscribe((res: any) => {
          let document = {
            vesselcallno: ship.vesselcallno,
            numEscal: ship.numEscale,
            gangscount: ship.gangscount,
            portofdeclarationCD: ship.portofdeclarationNM,
            berthingstatuscd: ship.berthingstatuscd,
            rejectionReason: ship.rejectionReason,
            etat: ship.etat,
            voyageno: ship.voyageno,
            vesselcountryname: res.vessel.vesselcountryname,
            vesselcountrycode: res.vessel.vesselcountrycode,
            vessel: res.vessel.vessel,
            shippingagent: res.shippingagent,
            vesselLength: res.vessel.vessellength,
            vesselDraft: res.vessel.vesseldraft,
            vesselNet: res.vessel.vesselnetweight,
            vesselBrut: res.vessel.vesselgrossweight,
            preferreddock: res.preferreddock,
          };
          this.list_print_f.push(document);
        });
    }

    setTimeout(() => {
      for (let shipdock of this.list_print_f) {
        this.generate_pdf2(shipdock);
      }
      this.ClearAll();
    }, 800);
  }
  document: any;
  Arr_view(content: any, cargomanifest: any) {
    this.document = cargomanifest;
    this.modalService.open(content, { size: 'xl', windowClass: 'dark-modal' });
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1
      }`;
  }

  ClearAll() {
    this.selection.clear();
  }
  open(content: any) {
    this.modalService.open(content);
  }

  selectetcopy: String | any;
  id_vessel_copy: any;
  id_ship_copy: any;
  open_copy(id_ship: any, content: any) {
    this.open(content);

    this.id_ship_copy = id_ship;
  }

  dimision() {
    this.modalService.dismissAll();
  }

  displayedColumnsMessages: string[] = [
    'id',
    'status',
    'startDate',
    'endDate',
    'typeFlux',
    'etatFlux',
    'idDossier',
    'typeMessage',
    'typeScenario',
  ];
  dataSourceMessages: MatTableDataSource<any>;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  list_message: any;
  Arr_messages(content: any, id: any) {
    this.MessagesUser.GetShipdocking(id, this.ShipdockingApi.Spring_serveur).subscribe((res: any) => {
      this.list_message = res;

      setTimeout(() => {
        this.dataSourceMessages = new MatTableDataSource(this.list_message);
        this.dataSourceMessages.paginator = this.paginator;
        this.modalService.open(content, {
          size: 'xl',
          windowClass: 'dark-modal',
        });
      }, 100);
    });
  }

  list_delete: any[] = [];
  Arr_remove() {
    this.list_delete = [];
    this.selection.selected.forEach((s) => this.list_delete.push(s));

    for (let ship of this.list_delete) {
      let dock = this.ship_dockinglist.find((x) => x.id == ship.id);
      if (ship.etat == 'Save') {
        setTimeout(() => {
          this.ShipdockingApi.delete(dock.id).subscribe((res: any) => {
            setTimeout(() => {
              this.GetShipDocking();
            }, 700);
          });
        }, 500);
      }
    }
    setTimeout(() => {
      this.modalService.dismissAll();
      this.ClearAll();
    }, 300);
  }

  changestscenario(event: any) { }

  copylist: any[] = [
    'Cargo Manifest',
    'Shipping Arrival',
    'Shipping Departure',
    'Ship Docking',
  ];
  Go_copy() {
    if (this.selectetcopy == 'Shipping Departure') {
      this.router.navigateByUrl(
        'ShippingDeparture/copy_cargo_manifest/' + this.id_ship_copy + '/1'
      );
    }

    if (this.selectetcopy == 'Shipping Arrival') {
      this.router.navigateByUrl(
        'ShippingArrival/copy_cargo_manifest/' + this.id_ship_copy + '/1'
      );
    }
    if (this.selectetcopy == 'Cargo Manifest') {
      this.router.navigateByUrl('CargoManifest/copy/' + this.id_ship_copy);
    }

    this.dimision();
  }

  organisation_list: any[] = [];
  Getorganisation() {
    this.organisationService.GetAll().subscribe((res: any) => {
      this.organisation_list = res;
    });
  }
  vessel_call_information_list: any[] = [];
  selectedvesselcall: string;
  id_new_ship_docking: any;
  document_f: any;
  changeessel_call_information(event: any) {
    if (event != undefined) {
      this.id_new_ship_docking = event.id;
      this.document_f = this.vessel_call_information_list.filter(
        (e) => e.id == event.id
      );
      this.document_f = this.document_f[0];

      setTimeout(() => {
        this.active = true;
      }, 500);
    } else {
      this.active = false;
    }
  }

  go_new() {
    // this.modalService.open(content, { size: 'xl', windowClass: 'dark-modal' });
    this.router.navigateByUrl('ShipDocking/new/' + this.id_new_ship_docking);
    this.dimision();
  }
  portlistofcall: portofcall[] = [];
  dischargingportName: any;
  dischargingportcode: any;
  idOrgR: any;
  selecteddischargingport: any;
  changedischargingport(event: any) {
    let port = this.portlistofcall.find((x) => x.id == event.id);
    this.dischargingportName = port?.nom;
    this.dischargingportcode = port?.id;
    this.idOrgR = event.id;
  }
  list_organsation: portofcall[] = [];
  Getportofcall() {
    this.organisationService.GetAll().subscribe((res: any) => {
      this.portlistofcall = [];
      this.list_organsation = [];
      this.organisation_list = res;
      this.list_organsation = res;

      this.list_organsation = this.list_organsation.filter(
        (e: any) => e.profil == 'Port'
      );

      for (let port of this.list_organsation) {
        this.portlistofcall.push({
          id: port.id,
          nom: port.nom,
        });
      }
    });
  }
  id_shipdocking: any;
  etat = 'Save';
  protected createformshipdocking(): any {
    return {
      vesselcallno: this.Formship.controls['vesselcallnumber'].value,
      portofdeclaration: this.idOrgR,
      berthingstatuscd: this.birthstatus.value.name,
      gangscount: this.Formship.controls['gangsnumber'].value,
      etat: this.etat,
    };
  }

  protected createformshipdockingUdpate(): any {
    return {
      id: this.id_shipdocking,
      vesselcallno: this.Formship.controls['vesselcallnumber'].value,
      portofdeclaration: this.idOrgR,
      berthingstatuscd: this.birthstatus.value.name,
      gangscount: this.Formship.controls['gangsnumber'].value,
      etat: this.etat,
    };
  }

  ship_dockinglist_1: any[] = [];
  ship_dockinglist_2: any[] = [];
  GetShipDocking() {
    if (this.RoleTeammanager) {
      if (this.role_organisation == 'Shipping_agent') {
        this.ShipdockingApi.GetByOrg__agent().subscribe((res: any) => {
          this.remplir_list(res);
        });
      } else {
        this.ShipdockingApi.GetByOrg__port().subscribe((res: any) => {
          this.remplir_list(res);
        });
      }
    } else {
      this.ShipdockingApi.GetByUser().subscribe((res: any) => {
        this.remplir_list(res);
      });
    }
  }

  affectedToUserName: any;
  remplir_list(list: any) {
    this.ship_dockinglist_2 = list;

    let resultA = this.ship_dockinglist_2.filter(
      (elm) =>
        !this.ship_dockinglist_1
          .map((elm) => JSON.stringify(elm))
          .includes(JSON.stringify(elm))
    );

    let resultB = this.ship_dockinglist_1.filter(
      (elm) =>
        !this.ship_dockinglist_2
          .map((elm) => JSON.stringify(elm))
          .includes(JSON.stringify(elm))
    );

    if (resultA.length > 0 || resultB.length > 0) {
      this.ship_dockinglist_1 = this.ship_dockinglist_2;
      this.ship_dockinglist = this.ship_dockinglist_2;
      this.ship_dockinglist_1.forEach(item => {
        const lastMessage = item.messages?.[item.messages.length - 1];
        if (lastMessage?.actions?.length > 0) {
          const lastAction = lastMessage.actions[lastMessage.actions.length - 1];
          if (lastAction.affectedToUser) {
            this.organisationService.GetOrgByID(lastAction.affectedToUser).subscribe((res: any) => {
              this.affectedToUserName = res.userName
            });
          }
        }
      });
      this.ship_dockinglist_2 = [];
      this.dataSource = new MatTableDataSource(this.ship_dockinglist_1);
      this.dataSource.paginator = this.paginator;
    }
  }
  active: boolean = false;

  reason: any;
  open_reason(content: any, reason: any) {
    this.reason = reason;
    this.open(content);
  }

  Getcallofinformation() {
    this.callofinforamtions.GetByDocking().subscribe((res: any) => {
      this.vessel_call_information_list = res;

      // this.vessel_call_information_list =
      //   this.vessel_call_information_list.filter((e) => e.etat == 'Accept');
    });
  }

  valeurcondition: String;
  id_search: any;
  valeur: any = '';
  attr: any = '';
  changeconditionitem(event: any) {
    if (event == undefined) {
      this.valeurcondition = '';
      this.id_search1 = 0;
      this.GetShipDocking();
    } else {
      this.id_search = event.id;
      this.attr = event.code;
      this.id_search1 = 1;
    }
  }

  search() {
    this.id_search1 = 1;
    if (this.id_search == 1) {
      this.ShipdockingApi.Serach(this.attr, this.valeurcondition).subscribe(
        (res: any) => {
          this.listrecherchr = res;
        }
      );
    } else if (this.id_search == 2) {
      this.listrecherchr = this.ship_dockinglist.filter(
        (e) => e.vessel.vessel == this.valeurcondition
      );
    }

    if (this.id_search != undefined) {
      setTimeout(() => {
        this.dataSource = new MatTableDataSource(this.listrecherchr);
        this.dataSource.paginator = this.paginator;
      }, 800);
    }
  }

  generate_pdf2(cell: any) {
    var doc = new jsPDF();
    var img = new Image();
    img.src = './../../../../assets/nexgen.png';
    doc.setFont('italic');
    doc.addImage(img, 'png', 150, 5, 50, 20);
    doc.setFontSize(10);
    doc.setTextColor('#46B1E3');
    doc.text('Shipping Company :' + '_', 5, 15);
    doc.setFontSize(20);
    doc.text('Ship Docking', 75, 15);
    doc.setFontSize(11);

    doc.setDrawColor('#0000FF');
    doc.line(5, 43, 5, 112);
    doc.line(5, 43, 205, 43);
    doc.line(205, 43, 205, 112);
    doc.line(5, 112, 205, 112);

    doc.line(105, 43, 105, 52);
    doc.line(5, 52, 205, 52);
    doc.setFontSize(8);
    doc.setTextColor('#1D3A66');
    doc.text('Vessel Information', 40, 50);
    doc.text('General Information', 140, 50);

    doc.line(105, 52, 105, 67);

    doc.line(165, 52, 165, 67);
    doc.line(135, 52, 135, 67);

    doc.line(5, 67, 205, 67);

    doc.line(105, 67, 105, 82);

    doc.line(55, 67, 55, 82);

    doc.line(165, 67, 165, 82);
    doc.line(135, 67, 135, 82);

    doc.line(5, 82, 205, 82);

    doc.line(45, 82, 45, 97);

    doc.line(5, 97, 205, 97);

    doc.line(105, 82, 105, 97);
    doc.line(170, 82, 170, 97);

    doc.line(105, 97, 105, 112);
    doc.line(55, 97, 55, 112);

    doc.line(5, 112, 205, 112);
    //bloc1
    doc.text('Vessel Name', 7, 57);
    doc.text('N° Escale', 107, 57);
    doc.text('Ship Agent', 137, 57);
    doc.text('Port Of Call', 167, 57);

    //bloc2

    doc.text('Vessel Country Name', 7, 71);
    doc.text('Vessel Country Code', 57, 71);
    doc.text('Gangs Count', 107, 71);
    doc.text('Berthing status', 137, 71);
    doc.text('Number Assignment', 167, 71);

    //bloc3
    doc.text('Vessel Draft', 7, 86);
    doc.text('Vessel Length ', 47, 86);

    doc.text('Preferred Dock', 107, 86);

    doc.text('Etat', 171, 86);
    doc.text('Rejection Reason', 107, 101);
    //bloc4

    doc.text('Vessel Gross Weight', 7, 101);
    doc.text('Vessel Net Weight', 57, 101);

    doc.setTextColor('#000');
    doc.setFontSize(9);

    //Ecriture !
    //bloc1
    doc.setFontSize(16);
    doc.text(cell.vessel + '', 45, 61);
    doc.setFontSize(9);
    doc.text(cell.numEscal + '', 109, 64);
    doc.text(cell.shippingagent + '', 139, 64);
    doc.text(cell.portofdeclarationCD + '', 169, 64);

    //bloc2

    doc.text(cell.vesselcountryname + '', 9, 78);
    doc.text(cell.vesselcountrycode + '', 59, 78);
    doc.text(cell.gangscount + '', 109, 78);
    doc.text('1', 169, 78);
    doc.text(cell.berthingstatuscd + '', 139, 78);

    //bloc3
    doc.text(cell.vesselDraft + '', 9, 93);
    doc.text(cell.vesselLength + '', 49, 93);
    doc.text(cell.preferreddock + '', 109, 93);

    if (cell.rejectionReason != null) {
      doc.text(cell.rejectionReason + '', 109, 108);
    }

    doc.text(cell.etat + '', 173, 93);

    //bloc4

    doc.text(cell.vesselBrut + '', 9, 108);
    doc.text(cell.vesselNet + '', 59, 108);

    doc.save('SHip_Docking_num' + cell.id + '.pdf');
  }

  ecriture: boolean = false;
  GetPremmision() {
    let premission = localStorage.getItem('premission_SD');

    if (premission?.indexOf('E') != -1) {
      this.ecriture = true;
    }
  }

  id_search1: any = 0;

  private socket: any;
  web_socket: boolean = false;

  Websocket_sd() {
    var host = 'wss://ws.coincap.io/prices?assets=bitcoin,ethereum,litecoin';

    this.socket = new WebSocketSubject(host);

    this.socket.subscribe(
      (message: any) => { },
      (err: any) => console.log(err)
    );

    this.socket = new WebSocket(host);
    this.socket.onmessage = (e: any) => {
      if (this.id_search1 == 0) {
        this.GetShipDocking();
      }
    };
  }

  changeaction(event: any) {
    this.userslist = [];
    this.selectetuser = null;
    if (event == undefined) {
      this.userslist = [];
    }

    if (event.id == '1') {
      this.getUser(this.TeamId, this.premission);
      this.acions_t = 'SAVE';
    } else if (event.id == '2') {
      this.getUser(this.TeamId, this.premission);
      this.acions_t = 'SUBMIT';
    }
  }

  dataSourceActions: MatTableDataSource<any>;
  listactions: any[];
  id_folder: any;
  message_user: any;
  reason_assign: any;
  messages_actions: any[] = [];
  message_sender: any;
  message_receiver: any;
  Arr_assign(content: any, id_folder: any, document: any) {
    this.transactiontype = 'Save';
    this.status_sc = false;
    this.id_folder = id_folder;
    let row_message = document.messages.length - 1;
    let status_message = document.messages[row_message].status;
    this.message_user = document.messages[row_message];
    this.id_message = document.messages[row_message].id;
    if (this.message_user.organizationSender != null) {
      this.message_sender = this.message_user.organizationSender;
    }

    if (this.message_user.organizationReceiver != null) {
      this.message_receiver = this.message_user.organizationReceiver;
    }
    let conditionReceiver =
      document.etat == 'For confirmation' &&
      this.message_receiver == this.id_Org_en;

    let conditionSender =
      document.etat == 'Save' && this.message_sender == this.id_Org_en;

    if (status_message == 'SUCCESS' || status_message == 'INPROGRESS') {
      if (conditionReceiver || conditionSender) {
        this.status_sc = false;
        this.messages_actions = this.message_user.actions;

        if (conditionReceiver) {
          this.messages_actions = this.message_user.actions.filter(
            (e: any) => e.message.organizationSender == this.id_Org_en
          );
        }

        if (conditionSender) {
          this.messages_actions = this.message_user.actions.filter(
            (e: any) => e.message.organizationSender == this.id_Org_en
          );
        }
        let row_actions = this.messages_actions.length;
        if (row_actions > 0) {
          if (this.messages_actions[row_actions - 1].status != 'FINISHED') {
            this.status_sc = true;
            this.reason_assign = this.languet.text_assign_2;
          }
        }

        setTimeout(() => {
          this.dataSourceActions = new MatTableDataSource(
            this.messages_actions
          );
        }, 200);
      } else {
        this.status_sc = true;
        this.reason_assign = this.languet.text_assign_1;
      }
    } else {
      this.status_sc = true;
      this.reason_assign = this.languet.text_assign_1;
    }

    setTimeout(() => {
      this.modalService.open(content, {
        size: 'xl',
        windowClass: 'dark-modal',
      });
    }, 500);
  }

  selectetassign: any;

  actionlist: any[];

  selectetuser: any;
  Id_user: any;
  acions_t: any;
  userslist: any[];

  changeuser(event: any) {
    this.Id_user = event.id;
  }

  getUser(team: any, sc: any) {
    this.userService.GetAllUsers(team, sc).subscribe((res: any) => {
      this.userslist = res;
    });
  }
  Assign() {
    if (this.transactiontype == 'Save') {
      this.actiondata = {
        code: this.acions_t,
        affectedToUser: this.Id_user,
        designation: this.designation,
      };

      this.affecationService
        .assignm(this.ShipdockingApi.Spring_serveur, this.actiondata, this.id_folder, 'sd')
        .subscribe((res: any) => {
          this.designation = '';
          this.selectetassign = null;
          this.selectetuser = null;
          this.dimision();
          this.notifier.notify(
            'success',
            this.languet.titremodal + ' ' + this.languet.task
          );
        });
    } else {
      this.actiondata.code = this.acions_t;
      this.actiondata.affectedToUser.id = this.Id_user;
      this.actiondata.designation = this.designation;

      this.affecationService
        .reassign(this.ShipdockingApi.Spring_serveur, this.actiondata, this.id_Action)
        .subscribe((res: any) => {
          this.designation = '';
          this.selectetassign = null;
          this.selectetuser = null;
          this.dimision();
          this.notifier.notify(
            'success',
            this.languet.titremodal + ' ' + this.languet.task
          );
        });
    }
  }
  TeamId: any;
  GetIdUser() {
    this.userService.Getuserbyusername().subscribe((res: any) => {
      this.TeamId = res.team.id;
      this.Getorganisation_send(res.id);
    });
  }
  designation: any;
  id_message: any;

  changeactions(event: any) {
    this.id_message = event.id;
    this.listactions = event.actions;
    setTimeout(() => {
      this.dataSourceActions = new MatTableDataSource(this.listactions);
    }, 200);
  }

  color_active = '#77838e';
  status_sc: boolean = false;
  RoleTeammanager: boolean = false;
  RoleAgent: boolean = false;
  id_Org_en: any;
  role_organisation: any;
  Getorganisation_send(id: any) {
    this.organisationService.Get_org_byID(id).subscribe((res: any) => {
      this.role_organisation = res['profil'];
      this.Getcallofinformation();
      this.Getportofcall();
      this.GetShipDocking();
      this.GetPremmision();
      this.id_Org_en = res.id;
    });
  }

  submit(content: any, row: any) {
    this.document = row;
    this.document.etat = 'For confirmation';

    this.ShipdockingApi.submit(
      this.document,
      this.document.portofdeclarationCD
    ).subscribe((res: any) => {
      this.open(content);

      let formdata = {
        action: 'SUBMIT',

        idOrgSender: this.document.messages[0].organizationSender.id,
        nomOrgSender: this.document.messages[0].organizationSender.name,
        idOrgReceiver: this.document.messages[0].organizationReceiver.id,
        nomOrgReceiver: this.document.messages[0].organizationReceiver.name,
        idScenario: this.document.id,
      };

      this.notificationApi.post(formdata).subscribe((res: any) => { });
    });
  }

  transactiontype = 'Save';
  id_Action: any;
  action: any;
  useraction: any;
  actiondata: any;

  reassign(action: any) {
    this.transactiontype = 'Update';
    this.action = action;
    this.actiondata = action;
    this.id_Action = action.id;
    this.useraction = action.affectedToUser;
    this.selectetuser = this.useraction.userName;
    this.Id_user = this.selectetuser.id;
    this.selectetassign = this.action.code;
    this.designation = this.action.designation;
    this.status_sc = false;
  }
}
