import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from '../User/user.service';

@Injectable({
  providedIn: 'root',
})
export class OrganisationService {
  url: any;
  url2: any;
  url_Gestion: any;
  url_Getway_Gestion_User: any;
  UrlGestionUser: any;

  Spring_serveur: string = 'api/organization/';
  Spring_serveur1: string = 'api/';

  constructor(private http: HttpClient, private userService: UserService) {
    this.url = environment.Url + this.Spring_serveur;
    this.url_Gestion = environment.UrlSession + this.Spring_serveur;
    this.url_Getway_Gestion_User = environment.UrlGetway + 'gestionuser/api/';
    this.UrlGestionUser = environment.UrlGestionUser ;
    

    this.url2 = environment.Url + this.Spring_serveur1;
  }

  public Get() {
    return this.http.get<any>(this.url_Gestion + 'organizations/all');
  }



  public GetAll() {
    return this.http.get<any>(this.url2 + 'organizations');
  }

  public GetByprofil(profil: any) {
    return this.http.get<any>(this.url + 'profil/' + profil);
  }
  public GetById(id: any) {
    return this.http.get<any>(this.url + 'organizations/' + id);
  }

  public Get_org_byID(user_id: any) {
    return this.http.get<any>(this.url2 + 'organizations/user/' + user_id);
  }

  public GetbyProfil(profil: any) {
    return this.http.get<any>(this.url_Gestion + 'organizations/profil?profil=' + profil);
  }

  public GetOrgByID(user_id: any) {
    return this.http.get<any>(this.url_Getway_Gestion_User + 'users/by-id/' + user_id);
  }

  public GetInformationUser(username: any) {
    return this.http.get<any>(this.UrlGestionUser + 'api/users/model/' + username);
  }

  public GetInformationByUsername(username: any) {
    return this.http.get<any>(this.UrlGestionUser + 'api/organization/organizations/by-user-name' + '?userName=' + username );
  }

  public GetInformationOrganisationByIdOrg(idOrg: any) {
    return this.http.get<any>(this.url_Getway_Gestion_User + 'organization/organizations/by-id/' + idOrg );
  }

}
