import { Component, OnInit } from '@angular/core';
import { jsPDF } from 'jspdf';

import { HttpClient } from '@angular/common/http';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import * as XLSX from 'xlsx';
import {
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Country } from '@angular-material-extensions/select-country';

import { ActivatedRoute, Router } from '@angular/router';
import { WaybillsService } from '../Service/Waylbills/waybills.service';
import { ManifestService } from '../Service/Manifest/manifest.service';
import { port } from '../../Models/port';
import { NgxSpinnerService } from 'ngx-spinner';
import { portofcall } from '../../Models/portofcall';
import { OrganisationService } from 'src/Services/Organisation/organisation.service';
import { VesselService } from '../../Shipping_Arrival/Service/Vessel/vessel.service';
import { VesselCallInformationService } from '../../Shipping_Arrival/Service/VesselCall/vessel-call-information.service';

import Langue from '../../../../../assets/langue.json';
import { Title } from '@angular/platform-browser';
import { UserService } from 'src/Services/User/user.service';
@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.css'],
})
export class NewComponent implements OnInit {
  btn_backround1 = 'rgba(71, 178, 228, 1)';
  btn_backround2 = '#fff';
  color1 = '#fff';
  color2 = '#073866';
  Form1: FormGroup;
  Form2: FormGroup;
  Form3: FormGroup;
  Form2bool: boolean = false;
  passeger: FormGroup | any;
  BlocB1: boolean = true;
  BlocB2: boolean = false;
  BlocB3: boolean = false;
  BlocB4: boolean = false;
  form2Bool: boolean = false;
  FormTransport: FormGroup;
  FormTotals: FormGroup;
  FormWaybills: FormGroup;

  option: any;
  color_submit = '#b1d9f5';
  createView() { }
  onStepChange(event: any) { }

  /*   @ViewChild(MatStepper, { static: true }) myStepper: MatStepper | any;
   */ ngAfterViewInit() {
    // this.myStepper._getIndicatorType = () => 'number';
  }

  CallSignType = new UntypedFormControl();
  selectedportPrevious: any;
  selectedportNext: any;
  selecteddischargingport: any;
  selectedloadingport: any;
  portlistPrevious = [];
  portlistNext = [];
  Waybills: any[] = [];
  Manifest: any[] = [];
  VisitPurposelist: any[] = [];
  CargoSummarylist: any[] = [];
  PassengerSummarylist: any[] = [];
  portlist: port[] = [];
  Id_ArrManifest: any;
  dateETA = new FormControl();
  dateETD = new FormControl();
  datef = new FormControl();
  Id_Manifest_copy: any;
  Id_ship_copy: any;
  Id_vessel_copy: any;
  transactiontype: any = 'Submit';

  langue: any;
  direction: any;
  languef: any;
  langue_list: any[] = Langue;
  languea: any;
  languem: any;
  languet: any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private httpClient: HttpClient,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private WaybillsApi: WaybillsService,
    private ManifestApi: ManifestService,
    private router: Router,
    private Organisation: OrganisationService,
    private VesselApi: VesselService,
    private shipping_service: VesselCallInformationService,
    private Userauth: UserService,
    private titleService: Title
  ) {
    if (localStorage.getItem('langue') != null) {
      this.langue = localStorage.getItem('langue') + '';
    } else {
      this.langue = 'AR';
    }

    if (this.langue == 'AR') {
      this.direction = 'rtl';
      this.languef = this.langue_list[0]['AR'].tablelist;
      this.languea = this.langue_list[0]['AR'].CM;
      this.languem = this.langue_list[0]['AR'].messageuser;
      this.languet = this.langue_list[0]['AR'].modal_text;
    }

    if (this.langue == 'EN') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['EN'].tablelist;
      this.languea = this.langue_list[0]['EN'].CM;
      this.languem = this.langue_list[0]['EN'].messageuser;
      this.languet = this.langue_list[0]['EN'].modal_text;
    }

    if (this.langue == 'FR') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['FR'].tablelist;
      this.languea = this.langue_list[0]['FR'].CM;
      this.languem = this.langue_list[0]['FR'].messageuser;
      this.languet = this.langue_list[0]['FR'].modal_text;
    }
    this.GetUser();
    this.titleService.setTitle(this.languet.entete);
    this.titre_bloc = this.languea.generalinformation;
    this.Id_ArrManifest = this.route.snapshot.params['id'];
    if (this.Id_ArrManifest != undefined) {
      this.transactiontype = 'Update';
      setTimeout(() => {
        this.spinner.show();
        this.Getpremision();
        this.GetCargoManifestById(this.Id_ArrManifest);
      }, 300);
    }
    this.Id_Manifest_copy = this.route.snapshot.params['copy'];

    if (this.Id_Manifest_copy != undefined) {
      this.spinner.show();
      this.transactiontype = 'Copy';

      setTimeout(() => {
        this.GetCargoManifestById(this.Id_Manifest_copy);
      }, 300);
    }

    this.Id_ship_copy = this.route.snapshot.params['ship'];

    if (this.Id_ship_copy != undefined) {
      this.spinner.show();
      this.transactiontype = 'Copy';

      setTimeout(() => {
        this.getshipbyid();
      }, 500);
    }

    this.Form1 = this.formBuilder.group({
      vessel: [''],
      voyagenumber: [''],
      dateofarrival: [''],
      timeofarrival: [''],
      customoffice: [''],

      placeofdeparture: [''],
      placeofdistination: [''],
      loadingport: [''],
      finaldestination: [''],
      carrier: [''],
    });

    this.FormTotals = this.formBuilder.group({
      bills: ['', Validators.required],
      packages: ['', Validators.required],
      containers: ['', Validators.required],
      grossmass: ['', Validators.required],
    });
    this.FormTransport = this.formBuilder.group({
      mode: ['', Validators.required],
      identity: ['', Validators.required],

      place: ['', Validators.required],
      registration: ['', Validators.required],

      master: [''],
    });
    this.FormWaybills = this.formBuilder.group({
      blreferencenumber: ['', Validators.required],
      blnumber: ['', Validators.required],
      bltype: ['', Validators.required],
      exportername: ['', Validators.required],
      consigneename: ['', Validators.required],
      containers: ['', Validators.required],
      packages: ['', Validators.required],
      grossmass: ['', Validators.required],
    });
  }

  list_ports: any = [];

  ngOnInit(): void {
    this.Getports();

    this.Getorganisation();
  }
  displayFn(object?: any): any | undefined {
    return object ? object.name : undefined;
  }

  loadingportName: any;
  loadingportcode: any;
  changeloadingport(event: any) {
    let port = this.portlist.find((x) => x.id == event.id);
    this.loadingportName = port?.nom;
    this.loadingportcode = port?.code;
  }
  dischargingportName: any;
  dischargingportcode: any;
  idOrgR: any;
  changedischargingport(event: any) {
    this.dischargingportName = event.name;
    this.dischargingportcode = event.id;
    this.idOrgR = event.id;
  }
  PortNextName: any;
  PortNextcode: any;
  changeportNext(event: any) {
    let port = this.portlist.find((x) => x.code == event.code);
    this.PortNextName = port?.nom;
    this.PortNextcode = port?.code;
  }

  Getports() {
    this.httpClient.get('./../../../../assets/data.json').subscribe((data) => {
      this.list_ports = data;
      this.portlist = [];
      for (let i = 0; i < this.list_ports.length; i++) {
        this.portlist.push({
          id: this.list_ports[i].id,
          name:
            this.list_ports[i].name +
            '(Code : ' +
            this.list_ports[i].code +
            ')',
          nom: this.list_ports[i].name,
          code: this.list_ports[i].code,
        });
      }
    });
  }
  closeResult = '';
  open(content: any) {
    this.modalService
      .open(content, {
        centered: true,
        windowClass: 'modal-rounded',
        ariaLabelledBy: 'modal-basic-title',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason: any) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  containers_totals: number = 0;
  packages_totals: number = 0;
  grosmasstotal: number = 0;

  Waybills_add() {
    let exportername = this.FormWaybills.controls['exportername'].value;
    let consigneename = this.FormWaybills.controls['consigneename'].value;
    let blreferencenumber =
      this.FormWaybills.controls['blreferencenumber'].value;
    let blnumber = this.FormWaybills.controls['blnumber'].value;
    let bltype = this.FormWaybills.controls['bltype'].value;
    let containers = this.FormWaybills.controls['containers'].value;
    let packages = this.FormWaybills.controls['packages'].value;
    let grossmass = this.FormWaybills.controls['grossmass'].value;

    this.modalService.dismissAll();

    this.Waybills.push({
      blreferencenumber: blreferencenumber,
      blnumber: blnumber,
      bltype: bltype,
      exportername: exportername,
      consigneename: consigneename,
      containers: containers,
      packages: packages,
      grossmass: grossmass,
    });

    this.grosmasstotal = this.grosmasstotal + grossmass;
    this.containers_totals = this.containers_totals + containers;
    this.packages_totals = this.packages_totals + packages;

    this.FormTotals.controls['packages'].setValue(this.packages_totals);
    this.FormTotals.controls['containers'].setValue(this.containers_totals);
    this.FormTotals.controls['grossmass'].setValue(this.grosmasstotal);
    this.FormWaybills.reset();
  }

  Waybills_remove(blreferencenumber: any) {
    const index: number = this.Waybills.indexOf(blreferencenumber);
    if (index !== -1) {
      this.Waybills.splice(index, 1);
    }
  }

  code_country: any;
  name_country: any;
  onCountrySelected(country: Country) {
    this.code_country = country.alpha2Code;
    this.name_country = country.name;
  }

  style(event: any) {
    if (event.target.value > 0) {
    }
  }

  date_ETA: any;
  date_ETD: any;
  date: any;

  public onchangedateETA(event: any): void {
    this.date_ETA = this.datePipe.transform(event, 'yyyy-MM-dd');
  }
  public onchangedate(event: any): void {
    this.date = this.datePipe.transform(event, 'yyyy-MM-dd');
  }
  public onchangedateETD(event: any): void {
    this.date_ETD = this.datePipe.transform(event, 'yyyy-MM-dd');
  }

  add_Waybills(manifest: any) {
    for (let i = 0; i < this.Waybills.length; i++) {
      const Waybills = this.createWaybills(i, manifest);

      this.WaybillsApi.post(Waybills).subscribe((res: any) => { });
    }
  }

  add_Vessel(content: any) { }

  update_vessel(id: any, content: any) { }

  protected createFormGeneralInformation(vessel: any): any {
    return {
      voyagenumber: this.Form1.controls['voyagenumber'].value,
      dateofarrival: this.date_ETD,
      timeofarrival: this.Form1.controls['timeofarrival'].value,
      customsoffice: this.Form1.controls['customoffice'].value,
      dateofdeparture: this.date_ETA,
      placeofdeparature: this.Form1.controls['placeofdeparture'].value,
      placeofdestination: this.Form1.controls['placeofdistination'].value,
      dischargingport: this.idOrgR,
      bltype: this.dischargingportName,
      loadingport: this.loadingportName,
      finaldestination: this.Form1.controls['finaldestination'].value,
      carrier: this.Form1.controls['carrier'].value,
      etat: this.etat,
      mode: this.FormTransport.controls['mode'].value,
      identity: this.FormTransport.controls['identity'].value,
      nationality: this.name_country,
      place: this.FormTransport.controls['place'].value,
      registration: this.FormTransport.controls['registration'].value,
      date: this.date,
      master: this.FormTransport.controls['master'].value,
      bills: this.FormTotals.controls['bills'].value,
      packages: this.FormTotals.controls['packages'].value,
      containers: this.FormTotals.controls['containers'].value,
      grossmass: this.FormTotals.controls['grossmass'].value,
      vessel: vessel,
    };
  }

  protected createFormGeneralInformationUpdate(vessel: any): any {
    return {
      id: this.Id_ArrManifest,
      vessel: vessel,
      voyagenumber: this.Form1.controls['voyagenumber'].value,
      dateofarrival: this.date_ETD,
      timeofarrival: this.Form1.controls['timeofarrival'].value,
      customsoffice: this.Form1.controls['customoffice'].value,
      dateofdeparture: this.date_ETA,
      placeofdeparature: this.Form1.controls['placeofdeparture'].value,
      placeofdestination: this.Form1.controls['placeofdistination'].value,
      dischargingport: this.idOrgR,
      bltype: this.dischargingportName,
      loadingport: this.loadingportName,
      finaldestination: this.Form1.controls['finaldestination'].value,
      carrier: this.Form1.controls['carrier'].value,
      etat: this.etat,
      mode: this.FormTransport.controls['mode'].value,
      identity: this.FormTransport.controls['identity'].value,
      nationality: this.name_country,
      place: this.FormTransport.controls['place'].value,
      registration: this.FormTransport.controls['registration'].value,
      date: this.date,
      master: this.FormTransport.controls['master'].value,
      bills: this.FormTotals.controls['bills'].value,
      packages: this.FormTotals.controls['packages'].value,
      containers: this.FormTotals.controls['containers'].value,
      grossmass: this.FormTotals.controls['grossmass'].value,
    };
  }

  protected createFormWaybillsgeneralinformationUpdate(): any {
    return {
      id: this.Id_ArrManifest,
      vessel: this.Form1.controls['vessel'].value,
      voyagenumber: this.Form1.controls['voyagenumber'].value,
      dateofarrival: this.Form1.controls['dateofarrival'].value,
      timeofarrival: this.Form1.controls['timeofarrival'].value,
      customsoffice: this.Form1.controls['customsoffice'].value,
      dateofdeparture: this.Form1.controls['dateofdeparture'].value,
      placeofdeparature: this.Form1.controls['placeofdeparture'].value,
      placeofdestination: this.Form1.controls['placeofdistination'].value,
      dischargingport: this.Form1.controls['dischargingport'].value,
      loadingport: this.Form1.controls['loadingport'].value,
      finaldestination: this.Form1.controls['finaldestination'].value,
      carrier: this.Form1.controls['carrier'].value,
      transportationcompanycode:
        this.FormTransport.controls['transportationcompanycode'].value,
      mode: this.FormTransport.controls['mode'].value,
      identity: this.FormTransport.controls['identity'].value,
      nationality: this.name_country,
      place: this.FormTransport.controls['place'].value,
      registration: this.FormTransport.controls['registration'].value,
      date: this.datef,
      master: this.FormTransport.controls['master'].value,
      bills: this.Form3.controls['bills'].value,
      packages: this.Form3.controls['packages'].value,
      containers: this.Form3.controls['containers'].value,
      grossmass: this.Form3.controls['grossmass'].value,
    };
  }

  protected createWaybills(i: any, manifest: any): any {
    return {
      blreferencenumber: this.Waybills[i].blreferencenumber,
      blnumber: this.Waybills[i].blnumber,
      bltype: this.Waybills[i].bltype,
      exportername: this.Waybills[i].exportername,
      consigneename: this.Waybills[i].consigneename,
      generalinformation: manifest,
      containers: this.Waybills[i].containers,
      packages: this.Waybills[i].packages,
      grossMass: this.Waybills[i].grossmass,
    };
  }
  saveandfinish_etat: boolean = false;
  submit_etat: boolean = false;

  protected GetCargoManifestById(id: any) {
    this.ManifestApi.GetById(id).subscribe((res: any) => {
      this.cargomanifest = res;
      this.Create_Form1_update(res);
      this.Create_Form2_update(res);
      this.Create_Form3_update(res);
      this.Create_Form4_update(res);

      setTimeout(() => {
        this.spinner.hide();
      }, 700);

      if (this.transactiontype == 'Update') {
        let messagerows = this.cargomanifest.messages.length;
        let lastmessage = this.cargomanifest.messages[messagerows - 1];
        let actionsrow = lastmessage.actions.length;
        let lastaction = lastmessage.actions[actionsrow - 1];

        if (
          lastaction != undefined &&
          lastaction.affectedToUser.id == this.idUser &&
          lastaction.code == 'SAVE' &&
          lastaction.status != 'FINISHED'
        ) {
          this.saveandfinish_etat = true;
        } else if (
          lastaction != undefined &&
          lastaction.affectedToUser.id == this.idUser &&
          lastaction.code == 'SUBMIT' &&
          lastaction.status != 'FINISHED'
        ) {
          this.submit_etat = true;
        } else {
          this.Go_list();
        }
      }
    });
  }
  id_vessel: any;
  Create_Form1_update(manifest: any) {
    let time = new Date();

    let timeofarrival = manifest.timeofarrival;

    const val = timeofarrival.split(':');

    time.setHours(val[0]);
    time.setMinutes(val[1]);
    let value = this.datePipe.transform(time, 'HH:mm');
    this.Form1.controls['customoffice'].setValue(manifest.customsoffice);
    this.Form1.controls['vessel'].setValue(manifest.vessel.vessel);
    this.Form1.controls['voyagenumber'].setValue(manifest.voyagenumber);
    this.Form1.controls['timeofarrival'].setValue(value);
    this.Form1.controls['placeofdeparture'].setValue(
      manifest.placeofdeparature
    );
    this.Form1.controls['placeofdistination'].setValue(
      manifest.placeofdestination
    );
    this.Form1.controls['carrier'].setValue(manifest.carrier);
    this.Form1.controls['finaldestination'].setValue(manifest.finaldestination);
    // this.Form1.controls['dischargingport'].setValue(manifest.dischargingport);
    this.Form1.controls['loadingport'].setValue(manifest.loadingport);
    this.dateETA.setValue(new Date(manifest.dateofarrival));
    this.dateETD.setValue(new Date(manifest.dateofdeparture));
    this.idOrgR = manifest.dischargingport;
    this.port_loading = this.portlist.find(
      (x) => x.code == manifest.loadingport
    );
    this.port_discharging = this.portlistofcall.find(
      (x) => x.id == manifest.dischargingport
    );

    this.selecteddischargingport = this.port_discharging;
    this.selectedloadingport = this.port_loading;
    this.dischargingportName = this.port_discharging.name;
    // this.dischargingportcode = this.port_discharging.code;

    // this.loadingportName = this.selectedloadingport.name;
    // this.loadingportcode = this.selectedloadingport.code;
  }

  Create_Form2_update(manifest: any) {
    this.FormTransport.controls['mode'].setValue(manifest.mode);
    this.FormTransport.controls['identity'].setValue(manifest.identity);
    // this.Form1.controls['ShippingAgent'].setValue(manifest.shippingagent);
    this.FormTransport.controls['place'].setValue(manifest.place);
    this.FormTransport.controls['registration'].setValue(manifest.registration);
    this.FormTransport.controls['master'].setValue(manifest.master);

    this.datef.setValue(new Date(manifest.date));
  }
  port_next_u: any;
  port_discharging: any;
  port_loading: any;
  Create_Form3_update(manifest: any) {
    this.FormTotals.controls['bills'].setValue(manifest.bills);
    this.FormTotals.controls['packages'].setValue(manifest.packages);
    this.FormTotals.controls['containers'].setValue(manifest.containers);
    this.FormTotals.controls['grossmass'].setValue(manifest.grossmass);
  }
  Create_Form4_update(manifest: any) {
    for (let cargo of manifest.waybills) {
      this.Waybills.push({
        id: cargo.id,
        blreferencenumber: cargo.blreferencenumber,
        blnumber: cargo.blnumber,
        bltype: cargo.bltype,
        exportername: cargo.exportername,
        consigneename: cargo.consigneename,
        containers: cargo.containers,
        packages: cargo.packages,
        grossMass: cargo.grossmass,
      });
    }
  }

  Waybillsdelete() {
    for (let Waybill of this.cargomanifest.waybills) {
      this.WaybillsApi.delete(Waybill.id).subscribe((res: any) => { });
    }
  }

  contour: number = 1;

  Preview(titre: any) {
    if (this.contour > 1) {
      this.contour = this.contour - 1;
    }

    this.intialisebloc();
    if (this.contour == 1) {
      this.intialisebloc();
      this.BlocB1 = true;
      this.titre_bloc = titre;
    }
    if (
      this.contour == 2 &&
      this.Form1.valid &&
      this.selecteddischargingport != null
    ) {
      this.intialisebloc();
      this.BlocB2 = true;
      this.titre_bloc = titre;
    }
    if (this.contour == 3 && this.FormTransport.valid) {
      this.intialisebloc();
      this.BlocB3 = true;
      this.titre_bloc = titre;
    }
    if (this.contour == 4 && this.FormTotals.valid) {
      this.intialisebloc();
      this.BlocB4 = true;
      this.titre_bloc = titre;
    }
  }
  intialisebloc() {
    this.BlocB1 = false;
    this.BlocB2 = false;
    this.BlocB3 = false;
    this.BlocB4 = false;
  }
  titre_bloc: any;
  Next(titre: any) {
    let contour_1 = this.contour + 1;
    if (contour_1 == 1) {
      this.intialisebloc();
      this.BlocB1 = true;
      this.titre_bloc = titre;
      if (this.contour < 4) {
        this.contour = contour_1;
      }
    }
    if (
      contour_1 == 2 &&
      this.Form1.valid &&
      this.selecteddischargingport != null
    ) {
      this.intialisebloc();
      this.titre_bloc = titre;
      this.BlocB2 = true;
      if (this.contour < 4) {
        this.contour = contour_1;
      }
    }
    if (contour_1 == 3 && this.FormTransport.valid && this.Form1.valid) {
      this.intialisebloc();
      this.BlocB3 = true;
      this.titre_bloc = titre;
      if (this.contour < 4) {
        this.contour = contour_1;
      }
    }
    if (
      contour_1 == 4 &&
      this.FormTotals.valid &&
      this.FormTransport.valid &&
      this.Form1.valid
    ) {
      this.intialisebloc();
      this.titre_bloc = titre;
      this.BlocB4 = true;
      if (this.contour < 4) {
        this.contour = contour_1;
      }
    }
  }

  changebloc(i: any, titre: any) {
    let contour_1 = i;

    if (contour_1 == 1) {
      this.contour = contour_1;
      this.intialisebloc();
      this.titre_bloc = titre;
      this.BlocB1 = true;
    }
    if (contour_1 == 2 && this.Form1.valid) {
      this.contour = contour_1;
      this.titre_bloc = titre;
      this.intialisebloc();
      this.BlocB2 = true;
    }
    if (contour_1 == 3 && this.FormTransport.valid && this.Form1.valid) {
      this.contour = contour_1;
      this.titre_bloc = titre;
      this.intialisebloc();
      this.BlocB3 = true;
    }
    if (
      contour_1 == 4 &&
      this.FormTotals.valid &&
      this.FormTransport.valid &&
      this.Form1.valid
    ) {
      this.contour = contour_1;
      this.titre_bloc = titre;
      this.intialisebloc();
      this.BlocB4 = true;
    }
  }

  json = {};

  Save(content: any) {
    this.onchangedateETA(this.dateETA.value);
    this.onchangedateETD(this.dateETD.value);
    this.onchangedate(this.datef.value);

    if (this.transactiontype == 'Update') {
      this.ManifestUpdate(content);
    } else {
      this.ManifestSave(content);
    }
  }

  ManifestSave(content: any) {
    this.VesselApi.post(this.createFromVessel()).subscribe((res: any) => {
      this.ManifestRegistration(content, res);
    });
  }

  ManifestRegistration(content: any, vessel: any) {
    this.ManifestApi.post(
      this.createFormGeneralInformation(vessel),
      this.idOrgR
    ).subscribe((res: any) => {
      setTimeout(() => {
        this.cargomanifest = res;
        this.add_Waybills(res);
        this.Getpremision();
        this.open(content);
      }, 400);
    });
  }

  ManifestUpdate(content: any) {
    this.Waybillsdelete();
    this.ManifestApi.post(
      this.createFormGeneralInformationUpdate(this.createFromVesselUpdate()),
      this.idOrgR
    ).subscribe((res: any) => {
      this.cargomanifest = res;
      setTimeout(() => {
        this.add_Waybills(res);
        this.Getpremision();
        this.open(content);
      }, 400);
    });

    this.VesselApi.update(
      this.createFromVesselUpdate(),
      this.cargomanifest.vessel.id
    ).subscribe((res: any) => { });
  }

  etat: any = 'Save';
  i: number = 0;
  onFileChange(event: any) {
    this.spinner.show();
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>event.target;
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      let z = 19;
      this.Waybills = [];
      this.grosmasstotal = 0;
      this.containers_totals = 0;
      this.packages_totals = 0;
      for (var i = 18; i < z; i++) {
        if (ws['D' + i] != undefined) {
          this.Waybills.push({
            blreferencenumber: ws['D' + i]['w'],
            blnumber: ws['E' + i]['w'],
            bltype: '',
            exportername: ws['I' + i]['w'],
            consigneename: ws['J' + i]['w'],
            containers: ws['F' + i]['w'],
            packages: ws['H' + i]['w'],
            grossmass: ws['G' + i]['w'],
          });

          this.grosmasstotal = this.grosmasstotal + Number(ws['G' + i]['w']);
          this.containers_totals =
            this.containers_totals + Number(ws['F' + i]['w']);
          this.packages_totals =
            this.packages_totals + Number(ws['H' + i]['w']);

          this.FormTotals.controls['packages'].setValue(this.packages_totals);
          this.FormTotals.controls['containers'].setValue(
            this.containers_totals
          );
          this.FormTotals.controls['grossmass'].setValue(this.grosmasstotal);
          z = z + 1;
        }
      }

      setTimeout(() => {
        if (ws['B16'] != undefined) {
          this.Form1.controls['customoffice'].setValue(ws['B16']['w']);
        }

        if (ws['B19'] != undefined) {
          this.dateETA.setValue(new Date(ws['B19']['w']));
        }
        if (ws['B20'] != undefined) {
          this.dateETD.setValue(new Date(ws['B20']['w']));
        }

        if (ws['B21'] != undefined) {
          let time = new Date();
          let timeofarrival = ws['B21']['w'];

          const val = timeofarrival.split(':');

          time.setHours(val[0]);
          time.setMinutes(val[1]);
          let value = this.datePipe.transform(time, 'HH:mm');
          this.Form1.controls['timeofarrival'].setValue(value);
        }

        this.Form1.controls['placeofdeparture'].setValue(ws['B22']['w']);
        this.Form1.controls['placeofdistination'].setValue(ws['B23']['w']);
        this.Form1.controls['carrier'].setValue(ws['B24']['w']);
        this.Form1.controls['finaldestination'].setValue(ws['B25']['w']);

        //transport

        if (ws['B28'] != undefined) {
          this.FormTransport.controls['mode'].setValue(ws['B28']['w']);
        }
        if (ws['B29'] != undefined) {
          this.FormTransport.controls['identity'].setValue(ws['B29']['w']);
        }
        if (ws['B30'] != undefined) {
          this.FormTransport.controls['place'].setValue(ws['B30']['w']);
        }
        if (ws['B31'] != undefined) {
          this.FormTransport.controls['registration'].setValue(ws['B31']['w']);
        }
        if (ws['B32'] != undefined) {
          this.datef.setValue(new Date(ws['B32']['w']));
        }
        if (ws['B33'] != undefined) {
          this.FormTotals.controls['bills'].setValue(ws['B33']['w']);
        }

        this.spinner.hide();
      }, 500);
      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
    };
  }

  getTimeAsString(date: Date) {
    return `${date.getHours()}:${(date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
      }`;
  }
  typeaction: boolean = false;
  cargomanifest: any;
  submit12(content: any) {
    if (this.typeaction == true) {
      this.etat = 'For confirmation';
      this.cargomanifest.etat = 'For confirmation';

      this.ManifestApi.submit(this.cargomanifest, this.idOrgR).subscribe(
        (res: any) => {
          this.open(content);

          setTimeout(() => {
            this.dimision();
            this.Go_list();
          }, 1000);
        }
      );
    } else {
    }
  }

  list_organsation: portofcall[] = [];
  portlistofcall: portofcall[] = [];
  Getorganisation() {
    this.Organisation.GetAll().subscribe((res: any) => {
      this.portlistofcall = [];
      this.list_organsation = [];
      this.organisation_list = res;
      this.list_organsation = res;
      this.list_organsation = this.list_organsation.filter(
        (e: any) => e.profil == 'Port'
      );
      this.portlistofcall = this.list_organsation;
    });
  }

  vesseldraft: any = '';
  vesselgrossweight: any = '';
  vessellength: any = '';
  vesselnetweight: any = '';
  vesselregport: any = '';
  vesseltype: any = '';
  vesselbuildyear: any = '';

  protected createFromVessel(): any {
    return {
      vesseldraft: this.vesseldraft,
      vesselgrossweight: this.vesselgrossweight,
      vessellength: this.vessellength,
      vessel: this.Form1.controls['vessel'].value,
      vesselnetweight: this.vesselnetweight,
      vesselregport: this.vesselregport,
      vesseltype: this.vesseltype,
      vesselbuildyear: this.vesselbuildyear,
      vesselcountrycode: this.code_country,
      vesselcountryname: this.name_country,
    };
  }

  protected createFromVesselUpdate(): any {
    return {
      id: this.cargomanifest.vessel.id,
      vesseldraft: this.vesseldraft,
      vesselgrossweight: this.vesselgrossweight,
      vessellength: this.vessellength,
      vessel: this.Form1.controls['vessel'].value,
      vesselnetweight: this.vesselnetweight,
      vesselregport: this.vesselregport,
      vesseltype: this.vesseltype,
      vesselbuildyear: this.vesselbuildyear,
      vesselcountrycode: this.code_country,
      vesselcountryname: this.name_country,
    };
  }

  vessel_copy_ship: any;
  getshipbyid() {
    this.shipping_service.GetById(this.Id_ship_copy).subscribe((res: any) => {
      this.vessel_copy_ship = res;

      this.remplirvessel(res.vessel);

      this.Form1.controls['vessel'].setValue(res.vessel.vessel);
      this.Form1.controls['voyagenumber'].setValue(res.voyageno);
      this.spinner.hide();
    });
  }

  dimision() {
    this.modalService.dismissAll();
  }

  Go_list() {
    this.modalService.dismissAll();
    localStorage.setItem('redirecturl', '3');
    this.router.navigateByUrl('Logistic/liste');
  }

  remplirvessel(vessel: any) {
    this.vesseldraft = vessel.vesseldraft;
    this.vesselgrossweight = vessel.vesselgrossweight;
    this.vessellength = vessel.vessellength;
    this.vesselnetweight = vessel.vesselnetweight;
    this.vesselregport = vessel.vesselregport;
    this.vesseltype = vessel.vesseltype;
    this.vesselbuildyear = vessel.vesselbuildyear;
    this.code_country = vessel.vesselcountrycode;
    this.name_country = vessel.vesselcountryname;
  }

  y: any;
  generate_pdf() {
    this.generate_pdf1(this.cargomanifest);
  }
  generate_pdf1(document: any) {
    this.y = 0;
    var doc = new jsPDF();
    let cargo_final_name = this.organisation_list.find(
      (x) => x.id == document.dischargingport
    );
    doc.setFontSize(11);
    this.y = 40;
    let x1 = 10;
    let x2 = 60;
    let x3 = 110;
    let x4 = 150;
    doc.setDrawColor('#0000FF');
    doc.setFontSize(11);
    doc.setTextColor('#0000FF');

    doc.line(5, 10, 5, 290);
    doc.line(5, 10, 205, 10);
    doc.line(205, 10, 205, 290);
    doc.line(5, 290, 205, 290);

    doc.line(5, 32, 205, 32);
    doc.line(5, 47, 180, 47);
    doc.line(5, 62, 205, 62);
    doc.line(5, 72, 205, 72);

    doc.line(30, 32, 30, 47);
    doc.line(55, 32, 55, 47);
    doc.line(80, 32, 80, 40);
    doc.line(105, 32, 105, 47);
    doc.line(130, 32, 130, 47);
    doc.line(155, 32, 155, 47);
    doc.line(180, 32, 180, 47);

    doc.line(30, 47, 30, 62);
    doc.line(50, 47, 50, 62);
    doc.line(75, 47, 75, 62);
    doc.line(100, 47, 100, 62);
    doc.line(130, 47, 130, 62);
    doc.line(155, 47, 155, 62);
    doc.line(180, 47, 180, 62);

    doc.line(25, 62, 25, 290);
    doc.line(55, 62, 55, 290);
    doc.line(75, 62, 75, 290);
    doc.line(95, 62, 95, 290);
    doc.line(115, 62, 115, 290);
    //  doc.line(130, 62, 130, 290);
    doc.line(140, 62, 140, 290);
    doc.line(165, 62, 165, 290);
    doc.setFontSize(10);
    doc.setFont('bold');
    doc.text('LIBYAN CUSTOMS', 20, 15);
    doc.setFont('italic');
    doc.setFontSize(7);
    doc.text(
      'MANIFESTO concerning the loading of the means of transport designated below, which manifests the accuracy of the',
      7,
      25
    );

    doc.text(
      'contenu est affirmée sous les peines de droit, est fourni et a été établi conformément aux lois et réglements en vigueur',
      7,
      30
    );

    doc.setFontSize(6);

    doc.text('TYPE DE MANIFESTE', 6, 36);

    doc.text('CUSTOMS OFFICE', 31, 36);

    doc.text('VOYAGE NUMBER', 56, 36);

    doc.text('DATE OF ARRIVAL', 81, 36);

    doc.text('REGISTRATION', 106, 36);

    doc.text('DATE', 132, 36);

    doc.text('PLACE OF DESTINATION', 155, 36);
    doc.text('SIGNATURE', 181, 36);
    ///2
    doc.text('VESSEL NAME', 6, 51);

    doc.text('COUNTRY', 31, 51);

    doc.text('J.B', 51, 51);

    doc.text('J.N', 76, 51);

    doc.text('PLACE OF DEPARTURE', 101, 51);

    doc.text('LOADING PORT', 131, 51);

    doc.text('DISCHARGING PORT', 156, 51);
    //3

    doc.text('REFRENCE', 6, 66);

    doc.text('BL REFERENCE NUMBER', 26, 66);

    doc.text('BL NUMBER', 56, 66);

    doc.text('GROSS MASS', 96, 66);
    doc.text('CONTAINERS', 76, 66);
    //doc.text('GROSS MASS', 131, 66);
    doc.text('PACKAGES', 116, 66);
    doc.text('EXPORTER NAME', 141, 66);

    doc.text('CONSIGNEE NAME', 166, 66);

    doc.setTextColor('#000000');
    doc.setFontSize(9);

    doc.text(document.vessel.vessel, 7, 56);

    if (document.nationality != null) {
      doc.text(document.nationality + '', 32, 56);
    }

    doc.text(document.vessel.vesselnetweight + '', 52, 56);

    doc.text(document.vessel.vesselgrossweight + '', 77, 56);

    doc.text('' + document.placeofdestination, 102, 56);

    if (document.loadingport) {
      doc.text('' + document.loadingport, 131, 56);
    }
    doc.text('' + cargo_final_name.name, 157, 56);
    doc.text('', 7, 41);

    doc.text(document.customsoffice + '', 32, 41);

    doc.text(document.voyagenumber + '', 57, 41);

    doc.text(document.dateofarrival + ' ' + document.timeofarrival, 81, 41);

    doc.text(document.registration + '', 106, 41);

    doc.text(document.date, 132, 41);

    doc.text(document.placeofdestination + '', 156, 41);
    this.y = 80;

    for (let waylbis of this.Waybills) {
      doc.text(waylbis.id + '', 6, this.y);

      doc.text(waylbis.blreferencenumber, 26, this.y);

      doc.text(waylbis.blnumber, 56, this.y);

      doc.text(waylbis.grossmass + '', 101, this.y);

      doc.text(waylbis.exportername, 141, this.y);

      doc.text(waylbis.consigneename, 166, this.y);
      doc.text(waylbis.packages + '', 116, this.y);
      doc.text(waylbis.containers + '', 76, this.y);
      // doc.text(waylbis.grossMass + '', 131, this.y);
      this.y = this.y + 7;
    }

    doc.save('cargomanifest_num' + document.id + '.pdf');
  }

  organisation_list: any[] = [];

  submit_flux: boolean = false;
  Getpremision() {
    let premission = localStorage.getItem('premission_CO');

    if (premission?.indexOf('S') != -1) {
      this.typeaction = true;
      this.color_submit = '#1671b3';
      this.submit_flux = true;
    }
  }

  finishaction(content: any) {
    this.ManifestApi.finishaction(
      this.createFormGeneralInformationUpdate(this.createFromVesselUpdate())
    ).subscribe((res: any) => {
      this.open(content);
      setTimeout(() => {
        this.Go_list();
      }, 400);
    });
  }
  idUser: any;
  GetUser() {
    this.Userauth.Getuserbyusername().subscribe((res: any) => {
      this.idUser = res.id;
    });
  }
}
