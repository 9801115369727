<div class="bloc" [ngStyle]="{ direction: direction }">
  <div class="c1">
    <div class="Trac-2081"></div>
    <div style="text-align: center">
      <h2 class="titre-page">{{ languea.titre_l }}</h2>
      <div class="about-border"></div>
    </div>
  </div>
  <div class="blocrecherche" [ngStyle]="{ direction: direction }">
    <div class="row" style="width: 100%">
      <div class="col-lg-3">
        <ng-select
          [items]="itemlist"
          (change)="changedate($event)"
          bindLabel="name"
          placeholder="{{ languef.typedate }}"
          [(ngModel)]="selecteddate"
        >
        </ng-select>
      </div>

      <div class="col-lg-4">
        <mat-form-field appearance="fill">
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              [(ngModel)]="startDate"
              placeholder="{{ languef.startdate }}"
              (dateInput)="onchangedatestart($event.value)"
            />
            <input
              matEndDate
              [(ngModel)]="endDate"
              placeholder="{{ languef.enddate }}"
              (dateInput)="onchangedateend($event.value)"
            />
          </mat-date-range-input>

          <mat-datepicker-toggle
            [disabled]="date_disabled"
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>

      <div class="col-lg-3" style="height: 90px">
        <ng-select
          [items]="statuslist"
          (change)="changestatus($event)"
          [clearable]="true"
          bindLabel="name"
          placeholder="{{ languef.etat }}"
          [(ngModel)]="selectedstatus"
        >
        </ng-select>
      </div>
      <div class="col-lg-1"></div>
      <div class="col-lg-3" style="height: 90px; padding-top: 3px">
        <ng-select
          [items]="itemslist"
          (change)="changeconditionitem($event)"
          bindLabel="name"
          placeholder="{{ languef.condition }}"
          [(ngModel)]="selectedcondition"
        >
        </ng-select>
      </div>
      <div class="col-lg-4" style="padding-top: 5px">
        <input
         *ngIf="selectedcondition?.code != 'userName'"
          type="text"
          [(ngModel)]="valeurcondition"
          mdbInput
          class="form-control"
          placeholder="{{ languef.conditionvalue }}"
        />
        <mat-form-field *ngIf="selectedcondition?.code == 'userName'">
          <mat-select [(ngModel)]="valeurcondition">
            <mat-option *ngFor="let usernameOfAffectedTo of usernamesOfAffectedTo" [value]="usernameOfAffectedTo">
              {{ usernameOfAffectedTo }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      
      <div class="col-lg-3 search">
        <div class="div_action1" (click)="search()">
          <div>
            <span style="color: #fff">{{ languet.search }}</span>
          </div>
          <div style="padding: 0% 4% 0% 4%">
            <mat-icon style="color: #ffffff; font-size: 30px">search</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div style="padding-top: 1%">
    
    <div style="float: right">
      <button [disabled]="!tradingcompany"
      routerLink="/CertificatOrigin/new" type="button" class="btn button3" style="display: flex">
        <div>
          <mat-icon class="iconadd">add</mat-icon>
        </div>
        <div style="padding-top: 3px">
          <span class="add"> Nouveau</span>
        </div>
      </button>
    </div>
    <div style="display: flex; padding: 0% 1% 0% 1%; float: left">
      <div style="padding: 0% 1% 0% 1%">
        <button [disabled]="false" type="button" class="btn button8" style="display: flex" (click)="printdoc()">
          <div>
            <mat-icon class="iconprint">print</mat-icon>
          </div>
          <div style="padding-top: 3px">
            <span class="print"> Imprimer</span>
          </div>
        </button>
      </div>
      <div>
        <button type="button" [disabled]="selection.selected.length == 0" class="btn button8" (click)="open(content1)"
          style="display: flex">
          <div>
            <mat-icon class="icondelete">delete</mat-icon>
          </div>
          <div style="padding-top: 3px">
            <span class="print"> Supprimer </span>
          </div>
        </button>
      </div>
    </div>

    <div
      style="width: 100%; padding-top: 4%"
      [ngStyle]="{ direction: direction }"
    >
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="$event ? toggleAllRows() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>
        <!-- ID Column -->
        <ng-container matColumnDef="id">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell"
          >
            {{ languea.id }}
          </th>
          <td mat-cell class="tdcell" *matCellDef="let row">
            {{ row.id }}
          </td>
        </ng-container>

        <ng-container matColumnDef="ID_Dossier">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell"
          >
            ID (dossier)
          </th>
          <td mat-cell class="tdcell" *matCellDef="let row">
            <ng-container
              *ngIf="
                row.messages &&
                row.messages.length > 0 &&
                row.messages[row.messages.length - 1].idDossier
              "
            >
              {{ row.messages[row.messages.length - 1].idDossier }}
            </ng-container>
            <ng-container
              *ngIf="
                !row.messages ||
                row.messages.length === 0 ||
                !row.messages[row.messages.length - 1].idDossier
              "
            >
              <!-- Gérer le cas où idDossier est vide -->
              <!-- Par exemple, afficher un message ou une chaîne vide -->
              <!-- Vous pouvez remplacer ceci par ce que vous voulez afficher dans ce cas -->
              {{ " " }}
            </ng-container>
          </td>
        </ng-container>

        <!-- date Column -->
        <ng-container matColumnDef="applicationdate">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell hidden"
          >
            {{ languea.applicationdate }}
          </th>
          <td mat-cell class="tdcell hidden" *matCellDef="let row">
            {{ row.applicationDate }}
          </td>
        </ng-container>

        <!-- banque Column -->
        <ng-container matColumnDef="invoicenumber">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell1 hidden"
          >
            {{ languea.invoicenumber }}
          </th>
          <td mat-cell class="tdcell1 hidden" *matCellDef="let row">
            {{ row.invoiceNumber }}
          </td>
        </ng-container>

        <!-- codeagence Column -->
        <ng-container matColumnDef="invoicedate">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell hidden"
          >
            {{ languea.invoicedate }}
          </th>
          <td mat-cell class="tdcell hidden" *matCellDef="let row">
            {{ row.invoiceDate }}
          </td>
        </ng-container>

        <!-- nomtce Column -->
        <ng-container matColumnDef="chamberofcommerce">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell"
          >
            {{ languea.chamberofcommerce }}
          </th>
          <td mat-cell class="tdcell" *matCellDef="let row">
            {{
              this.nameChamberOfCommerce
            }}
          </td>
        </ng-container>

        <!-- flux Column -->
        <ng-container matColumnDef="totalvalue">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell1 hidden"
          >
            {{ languea.totalvalue }}
          </th>
          <td mat-cell class="tdcell1 hidden" *matCellDef="let row">
            {{ row.totalValue }}
          </td>
        </ng-container>
        <!-- flux Column -->
        <ng-container matColumnDef="consignee">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell1 hidden"
          >
            {{ languea.consigneename }}
          </th>
          <td mat-cell class="tdcell1 hidden" *matCellDef="let row">
            {{ row.consigneeName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="etat">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell"
          >
            {{ languea.status }}
          </th>
          <td
            mat-cell
            class="tdcell"
            *matCellDef="let row"
            style="padding-left: 1%"
          >
            <div
              *ngIf="
                row.approvalStatus == 'ACCEPT' && row.status != 'SUBMITTED'
              "
              style="display: flex"
            >
              <div>
                <b style="width: 100%">{{ languet.accept }} </b>
              </div>

              <div style="padding-left: 1%">
                <mat-icon style="color: #73ff01; font-size: 25px"
                  >check_circle</mat-icon
                >
              </div>
            </div>

            <div *ngIf="row.status == 'INPROGRESS'" style="display: flex">
              <div>
                <b style="width: 100%">{{ languef.save }}</b>
              </div>
              <div style="padding-left: 1%">
                <mat-icon style="color: #000000; font-size: 21px"
                  >save_alt</mat-icon
                >
              </div>
            </div>

            <div
              *ngIf="
                row.approvalStatus == 'REJECT' && row.status != 'SUBMITTED'
              "
              style="display: flex"
            >
              <div>
                <b style="width: 100%"> {{ this.languet.rejectd }} </b>
              </div>
              <div style="padding-left: 1%">
                <mat-icon style="color: red; font-size: 22px">cancel</mat-icon>
              </div>
            </div>

            <div *ngIf="row.status == 'SUBMITTED'" style="display: flex">
              <div>
                <b style="width: 100%">
                  {{ languef.forconfirmation }}
                </b>
              </div>
              <div style="padding-left: 1%">
                <mat-icon style="color: rgb(27, 202, 255); font-size: 22px"
                  >error</mat-icon
                >
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="assign">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell1"
          >
            {{ languef.affectedto }}
          </th>
          <td mat-cell class="tdcell1" *matCellDef="let row">
            <p *ngIf="row.messages[row.messages.length - 1].actions.length > 0">
              {{
                row.messages[row.messages.length - 1].actions[
                  row.messages[row.messages.length - 1].actions.length - 1
                ].affectedToUser.userName
              }}
            </p>
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="headercell_actions"
          >
            {{ languea.actions }}
          </th>
          <td mat-cell *matCellDef="let row" class="tdcell_actions">
            <button
              matTooltip="{{ languef.view }}"
              (click)="Arr_view(CoView, row)"
              style="border: 0px solid; background-color: #ffffff"
            >
              <div class="btn-action">
                <mat-icon style="color: rgb(110, 110, 110)"
                  >remove_red_eye</mat-icon
                >
              </div>
            </button>

            <button
              *ngIf="row.status == 'INPROGRESS' && tradingcompany"
              matTooltip="{{ languef.edit }}"
              routerLink="/CertificatOrigin/update/{{ row.id }}"
              style="border: 0px solid; background-color: #ffffff"
            >
              <div class="btn-action">
                <mat-icon style="color: #1671b3">edit</mat-icon>
              </div>
            </button>
            <a
              *ngIf="
                row.status == 'INPROGRESS' &&
                tradingcompany &&
                row.messages[row.messages.length - 1].actions.length > 0
              "
            >
              <button
                *ngIf="
                  row.messages[row.messages.length - 1].actions[
                    row.messages[row.messages.length - 1].actions.length - 1
                  ].code == 'SUBMIT'
                "
                (click)="submit(CoSubmit, row)"
                style="border: 0px solid; background-color: #ffffff"
              >
                <div class="btn-action">
                  <mat-icon style="color: #0bec30">send</mat-icon>
                </div>
              </button>
            </a>
            <button
              *ngIf="tradingcompany"
              matTooltip="{{ languef.copy }}"
              routerLink="/CertificatOrigin/copy/{{ row.id }}"
              style="border: 0px solid; background-color: #ffffff"
            >
              <div class="btn-action">
                <mat-icon style="color: #19ffb2">file_copy</mat-icon>
              </div>
            </button>

            <button
              *ngIf="row.approvalStatus == 'REJECT'"
              matTooltip="{{ languef.rejection }}"
              (click)="open_reason(CoReason, row.rejectionReason)"
              style="border: 0px solid; background-color: #ffffff"
            >
              <div class="btn-action">
                <mat-icon style="color: #ee0d0d">message</mat-icon>
              </div>
            </button>

            <button
              matTooltip="{{ languef.messageuser }}"
              (click)="Arr_messages(CoMessageuser, row.messages)"
              style="border: 0px solid; background-color: #ffffff"
            >
              <div class="btn-action">
                <mat-icon style="color: #b812ce">message</mat-icon>
              </div>
            </button>

            <button
              *ngIf="RoleTeammanager"
              matTooltip="{{ languef.assignment }}"
              (click)="Arr_assign(modal_assign, row.id, row)"
              style="border: 0px solid; background-color: #ffffff"
            >
              <div class="btn-action">
                <mat-icon style="color: #000000">reply</mat-icon>
              </div>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell tdcell" colspan="20" style="min-height: 400px">
            <div>
              <div style="display: block; text-align: center">
                <img
                  src="./../../../../assets/tablenotfound.PNG"
                  style="width: 250px; height: 250px"
                />
              </div>
              <div>
                <span class="spanmessage"> {{ languea.message }} </span>
              </div>
            </div>
          </td>
        </tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[10, 25, 100]"
        aria-label="Select page of users"
      ></mat-paginator>
    </div>
  </div>
</div>

<ng-template #content1>
  <div class="modal-body rounded-8">
    <div style="text-align: center">
      <div style="padding-top: 10%">
        <img
          style="width: 90px; height: 90px"
          src="./../../../assets/icons/list.PNG"
        />
      </div>

      <div style="padding-top: 5%">
        <label
          style="
            width: 270px;
            font-family: Poppins;
            font-size: 20px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.98;
            letter-spacing: normal;
            text-align: center;
            color: #1d3a66;
          "
        >
          {{ languet.delete }}
        </label>
      </div>
      <div style="padding-top: 3%; display: flex">
        <div class="container text-right nextbtn">
          <button type="button" class="btn button10" (click)="Arr_remove()">
            <span class="next"> {{ languet.btnsure }} </span>
          </button>
        </div>

        <div class="container text-right nextbtn">
          <button type="button" class="btn button9" (click)="dimision()">
            <span class="next"> {{ languet.btnno }} </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #CoReason let-modal>
  <div class="modal-header">
    <h4 class="titre_modal" id="modal-basic-title">
      {{ languea.reasonreject }}
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>

  <div class="modal-body" style="z-index: 1">
    <div class="bodymodal">
      <div style="padding-left: 5%">
        <div style="z-index: 1">
          <label>
            {{ reason }}
          </label>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #content13>
  <div class="modal-body rounded-8">
    <embed
      width="100%"
      height="700"
      [src]="sanitizer.bypassSecurityTrustResourceUrl(document_src)"
      type="application/pdf"
    />
  </div>
</ng-template>

<ng-template #CoSubmit>
  <div class="modal-body rounded-8">
    <div style="text-align: center">
      <div>
        <span
          class="material-icons green-color"
          style="
            font-size: 100px;
            color: #41d96f;
            text-align: center;
            font-weight: 800;
          "
          >check_circle
        </span>
      </div>
      <div>
        <label
          style="
            font-family: Montserrat;
            font-size: 26px;
            padding-top: 2%;
            font-weight: bold;
            color: #073866;
          "
        >
          {{ languet.titremodal }}</label
        >
      </div>
      <div>
        <label
          style="
            width: 310px;
            font-size: 16px;
            font-weight: bold;
            padding-top: 40px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.77;
            letter-spacing: normal;
            text-align: center;
          "
        >
          {{ languet.descriptionsubmitco }}</label
        >
      </div>
    </div>
  </div>
</ng-template>

<ng-template #CoMessageuser class="modal-dialog" let-modal>
  <div class="modal-header">
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div [ngStyle]="{ direction: direction }">
    <table mat-table [dataSource]="dataSourceMessages">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>{{ languem.id }}</th>
        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>{{ languem.status }}</th>
        <td mat-cell *matCellDef="let element">{{ element.status }}</td>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef>{{ languem.startdate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.startDate }}</td>
      </ng-container>

      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef>{{ languem.enddate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.endDate }}</td>
      </ng-container>

      <ng-container matColumnDef="typeFlux">
        <th mat-header-cell *matHeaderCellDef>{{ languem.typeflux }}</th>
        <td mat-cell *matCellDef="let element">{{ element.typeFlux }}</td>
      </ng-container>

      <ng-container matColumnDef="etatFlux">
        <th mat-header-cell *matHeaderCellDef>{{ languem.etatflux }}</th>
        <td mat-cell *matCellDef="let element">{{ element.etatFlux }}</td>
      </ng-container>

      <ng-container matColumnDef="idDossier">
        <th mat-header-cell *matHeaderCellDef>{{ languem.iddossier }}</th>
        <td mat-cell *matCellDef="let element">{{ element.idDossier }}</td>
      </ng-container>

      <ng-container matColumnDef="typeMessage">
        <th mat-header-cell *matHeaderCellDef>{{ languem.typemessage }}</th>
        <td mat-cell *matCellDef="let element">{{ element.typeMessage }}</td>
      </ng-container>
      <ng-container matColumnDef="typeScenario">
        <th mat-header-cell *matHeaderCellDef>{{ languem.typescenario }}</th>
        <td mat-cell *matCellDef="let element">{{ element.typeScenario }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsMessages"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsMessages"></tr>
    </table>
  </div>
</ng-template>

<ng-template #CoView class="modal-dialog" let-modal>
  <div class="modal-header">
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body" [ngStyle]="{ direction: direction }">
    <mat-tab-group>
      <mat-tab label="{{ languea.informationgeneral }}"
        ><div class="row" style="width: 90%; padding: 2%">
          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1"> {{ languea.applicationdate }}</label>
            </div>
            <div>
              <label class="lab2"
                ><label style="color: #e8effc">.</label
                >{{ document.applicationDate }}
              </label>
            </div>
          </div>
          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1"> {{ languea.invoicenumber }}</label>
            </div>
            <div>
              <label class="lab2"
                ><label style="color: #e8effc">.</label
                >{{ document.invoiceNumber }}
              </label>
            </div>
          </div>
          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1"> {{ languea.invoicedate }} </label>
            </div>
            <div>
              <label class="lab2"
                ><label style="color: #e8effc">.</label
                >{{ document.invoiceDate }}
              </label>
            </div>
          </div>
          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1"> {{ languea.chamberofcommerce }} </label>
            </div>
            <div>
              <label class="lab2">
                <label style="color: #e8effc">.</label
                >{{ document.messages[0].idOrgReceiver }}</label
              >
            </div>
          </div>

          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1"> {{ languea.consigneename }}</label>
            </div>
            <div>
              <label class="lab2"
                ><label style="color: #e8effc">.</label>
                {{ document.consigneeName }}</label
              >
            </div>
          </div>

          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1">{{ languea.consigneeaddress }} </label>
            </div>
            <div>
              <label class="lab2"
                ><label style="color: #e8effc">.</label
                >{{ document.consigneeAddress }}</label
              >
            </div>
          </div>

          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1">{{ languea.producername }}</label>
            </div>
            <div>
              <label class="lab2"
                ><label style="color: #e8effc">.</label
                >{{ document.producerName }}</label
              >
            </div>
          </div>
          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1"> {{ languea.produceraddress }} </label>
            </div>
            <div>
              <label class="lab2"
                ><label style="color: #e8effc">.</label
                >{{ document.producerAddress }}</label
              >
            </div>
          </div>
          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1"> {{ languea.countryoforigin }}</label>
            </div>
            <div>
              <label class="lab2"
                ><label style="color: #e8effc">.</label
                >{{ document.countryOfOrigin }}</label
              >
            </div>
          </div>

          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1"> {{ languea.countryofmanifacture }} </label>
            </div>
            <div>
              <label class="lab2"
                ><label style="color: #e8effc">.</label
                >{{ document.countryOfManifacture }}</label
              >
            </div>
          </div>

          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1"> {{ languea.meanstransportandroute }}</label>
            </div>
            <div>
              <label class="lab2"
                ><label style="color: #e8effc">.</label
                >{{ document.meansTransportAndRoute }}</label
              >
            </div>
          </div>
          <div class="col-lg-4 labeldetail">
            <div>
              <label class="lab1">{{ languea.totalvalue }} </label>
            </div>
            <div>
              <label class="lab2"
                ><label style="color: #e8effc">.</label
                >{{ document.totalValue }}</label
              >
            </div>
          </div>

          <div class="col-lg-12 labeldetail">
            <div>
              <label class="lab1"> {{ languea.cumulation }} </label>
            </div>
            <div>
              <label class="lab2"
                ><label style="color: #e8effc">.</label
                >{{ document.cumulation }}</label
              >
            </div>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="{{ languea.items }}">
        <div class="div_table">
          <table mat-table [dataSource]="dataSourceItems">
            <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

            <!-- Name Column -->
            <ng-container matColumnDef="marksandnumberofpackage">
              <th mat-header-cell *matHeaderCellDef class="headercell">
                {{ languea.marksandnumberofpackage }}
              </th>
              <td mat-cell *matCellDef="let element" class="tdcell">
                {{ element.marksAndNumberOfPackage }}
              </td>
            </ng-container>

            <!-- Désignation Column -->
            <ng-container
              matColumnDef="numberandkindofpackage"
              class="mobile_container"
            >
              <th mat-header-cell *matHeaderCellDef class="headercell">
                {{ languea.numberandkindofpackage }}
              </th>
              <td mat-cell *matCellDef="let element" class="tdcell">
                {{ element.numberAndKindOfPackage }}
              </td>
            </ng-container>

            <!-- PFN Column -->
            <ng-container matColumnDef="netweight">
              <th mat-header-cell *matHeaderCellDef class="headercell">
                {{ languea.netweight }}
              </th>
              <td mat-cell *matCellDef="let element" class="tdcell">
                {{ element.netWeight }}
              </td>
            </ng-container>

            <!-- PFN Code QCS -->
            <ng-container matColumnDef="grossweight">
              <th mat-header-cell *matHeaderCellDef class="headercell1">
                {{ languea.grossweight }}
              </th>
              <td mat-cell *matCellDef="let element" class="tdcell1">
                {{ element.grossWeight }}
              </td>
            </ng-container>

            <!-- PFN QCS -->
            <ng-container matColumnDef="quantity">
              <th mat-header-cell *matHeaderCellDef class="headercell">
                {{ languea.quantity }}
              </th>
              <td mat-cell *matCellDef="let element" class="tdcell">
                {{ element.quantity }}
              </td>
            </ng-container>

            <!-- PFN QCS -->
            <ng-container matColumnDef="proceedsforeign">
              <th mat-header-cell *matHeaderCellDef class="headercell">
                {{ languea.proceedsforeign }}
              </th>
              <td mat-cell *matCellDef="let element" class="tdcell">
                {{ element.proceedsForeign }}
              </td>
            </ng-container>

            <!-- PFN Pays Origine -->
            <ng-container matColumnDef="currencyvalue">
              <th mat-header-cell *matHeaderCellDef class="headercell">
                {{ languea.currencyvalue }}
              </th>
              <td mat-cell *matCellDef="let element" class="tdcell">
                {{ element.currencyValue }}
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef class="headercell">
                {{ languea.currencyvalue }}
              </th>
              <td mat-cell *matCellDef="let row" class="tdcell_actions">
                <button
                  matTooltip="{{ languef.view }}"
                  (click)="Arr_view_Elements(CoElements, row)"
                  style="border: 0px solid; background-color: #ffffff"
                >
                  <div class="btn-action">
                    <mat-icon style="color: rgb(110, 110, 110)"
                      >remove_red_eye</mat-icon
                    >
                  </div>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsItems"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumnsItems"
            ></tr>
          </table>
        </div>
      </mat-tab>

      <mat-tab label="{{ languea.attachments }} ">
        <div class="row" style="width: 90%; padding: 2%">
          <div class="row" style="margin: 1% 1% 1% 1%">
            <div class="div_table">
              <table mat-table [dataSource]="dataSourcePieces">
                <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

                <!-- Position Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef class="headercell">
                    {{ languea.filename }}
                  </th>
                  <td mat-cell *matCellDef="let element" class="tdcell">
                    {{ element.name }}
                  </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="file">
                  <th mat-header-cell *matHeaderCellDef class="headercell">
                    {{ languea.file }}
                  </th>
                  <td mat-cell *matCellDef="let element" class="tdcell">
                    <a
                      *ngIf="
                        element.attachment && element.attachmentContentType
                      "
                      (click)="
                        openFile(
                          element.attachment,
                          element.attachmentContentType
                        )
                      "
                      style="text-decoration: none; cursor: pointer"
                      >Open</a
                    >
                    <span *ngIf="element.attachment">
                      {{ element.originalFilename }},
                      {{ element.fileSize }}</span
                    >
                  </td>
                </ng-container>

                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef class="headercell">
                    {{ languea.actions }}
                  </th>
                  <td mat-cell *matCellDef="let element" class="tdcell">
                    <button
                      matTooltip="{{ languef.view }}"
                      (click)="viewPDF(file, element)"
                      style="border: 0px solid; background-color: #ffffff"
                    >
                      <div class="btn-action">
                        <mat-icon style="color: rgb(110, 110, 110)"
                          >remove_red_eye</mat-icon
                        >
                      </div>
                    </button>
                  </td>
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumnsPieces"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumnsPieces"
                ></tr>
              </table>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</ng-template>

<ng-template #CoElements class="modal-dialog" let-modal>
  <div class="modal-header">
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div [ngStyle]="{ direction: direction }">
    <table mat-table [dataSource]="dataSourceElement">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef class="headercell">
          {{ languea.id }}
        </th>
        <td mat-cell *matCellDef="let element" class="tdcell">
          {{ element.id }}
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="headercell">
          {{ languea.name }}
        </th>
        <td mat-cell *matCellDef="let element" class="tdcell">
          {{ element.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="quantity">
        <th mat-header-cell *matHeaderCellDef class="headercell">
          {{ languea.quantity1 }}
        </th>
        <td mat-cell *matCellDef="let element" class="tdcell">
          {{ element.quantity }}
        </td>
      </ng-container>

      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef class="headercell">
          {{ languea.value }}
        </th>
        <td mat-cell *matCellDef="let element" class="tdcell">
          {{ element.value }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsElement"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsElement"></tr>
    </table>
  </div>
</ng-template>
<ng-template #file>
  <div style="height: 600px">
    <div id="outerContainer" *ngIf="type_source == 'pdf'">
      <pdf-viewer
        [src]="file_source"
        [rotation]="0"
        [original-size]="false"
        [show-all]="true"
        [fit-to-page]="false"
        [zoom]="1"
        [zoom-scale]="'page-width'"
        [stick-to-page]="false"
        [render-text]="true"
        [external-link-target]="'blank'"
        [autoresize]="true"
        [show-borders]="false"
        style="width: 100%; height: 600px"
      ></pdf-viewer>
    </div>

    <div *ngIf="type_source == 'image'">
      <img [src]="file_source" width="100%" height="100%" />
    </div>
  </div>
</ng-template>
<ng-template #modal_assign class="modal-dialog" let-modal>
  <div class="modal-header text-center">
    <h4 class="modal-title w-100" id="modal-basic-title">
      {{ languef.assignment }}
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body" [ngStyle]="{ direction: direction }">
    <div class="row" *ngIf="status_sc == false">
      <div class="col-lg-6" style="text-align: center">
        <div style="z-index: 1; text-align: center">
          <ng-select
            [items]="actionlist"
            (change)="changeaction($event)"
            bindLabel="name"
            placeholder="{{ languef.assignment }}"
            [(ngModel)]="selectetassign"
          >
          </ng-select>
        </div>
      </div>

      <div class="col-lg-6">
        <div style="z-index: 1; text-align: center">
          <ng-select
            [items]="userslist"
            (change)="changeuser($event)"
            placeholder="{{ languef.users }}"
            [(ngModel)]="selectetuser"
            bindLabel="userName"
            bindValue="userName"
            [disabled]="selectetassign == null"
          >
            <ng-template ng-label-tmp let-item="item">
              <img
                *ngIf="item.attachment != null"
                height="25"
                width="25"
                [src]="item.attachment"
                alt="Avatar"
                class="avatar"
              />
              <img
                *ngIf="item.attachment == null"
                height="25"
                width="25"
                src="https://bootdey.com/img/Content/avatar/avatar6.png"
                alt="Avatar"
                class="avatar"
              />
              <b>{{ item.userName }}</b>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <img
                *ngIf="item.attachment != null"
                height="25"
                width="25"
                [src]="item.attachment"
                alt="Avatar"
                class="avatar"
              />
              <img
                *ngIf="item.attachment == null"
                height="25"
                width="25"
                src="https://bootdey.com/img/Content/avatar/avatar6.png"
                alt="Avatar"
                class="avatar"
              />
              <b style="padding: 2%">{{ item.userName }}</b>
            </ng-template>
          </ng-select>
        </div>
      </div>

      <div class="col-lg-12" style="padding-top: 2%">
        <textarea
          matInput
          class="form-control"
          name="designation"
          style="width: 100%; text-indent: 10px"
          placeholder=" {{ languet.comment }} "
          [(ngModel)]="designation"
          required
        ></textarea>
      </div>
    </div>

    <div *ngIf="status_sc" style="text-align: center">
      <h5>{{ reason_assign }}</h5>
    </div>

    <div class="row" *ngIf="messages_actions.length > 0">
      <div class="col-lg-12" style="padding-top: 2%">
        <h4>
          {{ languet.assignlist }}
        </h4>
      </div>

      <div class="col-lg-12" style="padding-top: 2%">
        <table mat-table [dataSource]="dataSourceActions">
          <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

          <!-- Position Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef class="headercell">
              {{ languef.numero }}
            </th>
            <td mat-cell *matCellDef="let element" class="tdcell">
              {{ element.id }}
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef class="headercell">
              {{ languef.status }}
            </th>
            <td mat-cell *matCellDef="let element" class="tdcell">
              {{ element.code }}
            </td>
          </ng-container>

          <ng-container matColumnDef="startDate">
            <th mat-header-cell *matHeaderCellDef class="headercell">
              {{ languef.date }}
            </th>
            <td mat-cell *matCellDef="let element" class="tdcell">
              {{ element.affectedDate }}
            </td>
          </ng-container>

          <ng-container matColumnDef="affectedto">
            <th mat-header-cell *matHeaderCellDef class="headercell">
              {{ languef.affectedto }}
            </th>
            <td mat-cell *matCellDef="let element" class="tdcell">
              {{ affectedToUserName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="headercell">
              {{ languea.actions }}
            </th>
            <td mat-cell *matCellDef="let element" class="tdcell">
              <button
                *ngIf="true"
                matTooltip="{{ languef.edit }}"
                (click)="reassign(element)"
                style="border: 0px solid; background-color: #ffffff"
              >
                <div class="btn-action">
                  <mat-icon style="color: #1671b3">edit</mat-icon>
                </div>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsActions"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumnsActions"
          ></tr>
        </table>
      </div>
    </div>
  </div>
  <div
    *ngIf="status_sc == false"
    class="modal-footer"
    [ngStyle]="{ direction: direction }"
  >
    <button
      [disabled]="selectetuser == null || selectetassign == null || status_sc"
      (click)="Assign()"
      type="button"
      class="btn button4"
    >
      <div style="padding-top: 3px">
        <span class="assign"> {{ languet.assign }}</span>
      </div>
    </button>
  </div>
</ng-template>
