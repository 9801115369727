import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomsService {
  url: any;
  url_items: any;
  Spring_serveur: string = 'api/CustomsDeclaration/';
  Spring_serveur_item: string = 'api/';
  constructor(private http: HttpClient) {
    this.url = environment.Url + this.Spring_serveur;
    this.url_items = environment.Url + this.Spring_serveur_item;
  }

  public Get() {
    return this.http.get<any>(`${this.url + 'Listinfos-per-user'}`);
  }

  public GetByTeam() {
    return this.http.get<any>(`${this.url + 'Listinfos-per-team'}`);
  }

  public GetByOrg() {
    return this.http.get<any>(`${this.url + 'Listinfos-per-org'}`);
  }

  public GetByUser() {
    return this.http.get<any>(
      `${this.url + 'customs-declarations/for-user-affecte'}`
    );
  }

  public GetAttach(id: any) {
    return this.http.get<any>(`${this.url + 'customs-declarations-dto/' + id}`);
  }

  public GetByid(id: any) {
    return this.http.get<any>(`${this.url + 'customs-declarations/' + id}`);
  }

  public post(formData: any, idOrgR: any) {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(this.url + `save/${idOrgR}`, formData, {
      headers: headers1,
    });
  }

  public submit(formData: any, idOrgR: any) {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(this.url + `submit/${idOrgR}`, formData, {
      headers: headers1,
    });
  }

  public response(formData: any) {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(this.url + `response`, formData, {
      headers: headers1,
    });
  }

  public delete(id: any) {
    return this.http.delete<any>(
      `${this.url + 'customs-declarations' + '/' + id + '/'}`
    );
  }

  public delete_items(id: any) {
    return this.http.delete<any>(
      `${this.url_items + 'items' + '/' + id + '/'}`
    );
  }

  public Search(attribue: any, valeur: any) {
    return this.http.get<any>(
      `${
        this.url +
        'List-by-attribute?attribute=' +
        attribue +
        '&value=' +
        valeur
      }`
    );
  }
}
