import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import jsPDF from 'jspdf';
import Langue from '../../../../../assets/langue.json';
import { WebSocketSubject } from 'rxjs/webSocket';
import { CreditLetterService } from '../Services/credit-letter.service';
import { OrganisationService } from 'src/Services/Organisation/organisation.service';
import { UserService } from 'src/Services/User/user.service';
import { FormGroup } from '@angular/forms';
import { AttachmentService } from 'src/Services/AttchementTrade/attachment.service';
import { DataUtils } from 'src/Services/AttchementTrade/data-util.service';
import { AffectationService } from 'src/Services/Affectation/affectation.service';
import { NotifierService } from 'angular-notifier';
import { NotificationService } from 'src/Services/Notification/notification.service';

@Component({
  selector: 'app-liste_credit',
  templateUrl: './liste.component.html',
  styleUrls: ['./liste.component.css'],
})
export class ListeComponent implements OnInit {
  index: number = 0;
  displayedColumns: string[] = [
    'select',
    'id',
    'applicationdate',
    'applicationnumber',
    'expirydate',
    'issuingbank',
    'amount',
    'beneficiary',
    'assign',
    'etat',
    'action',
  ];

  itemlist: any[];

  displayedColumnsActions: string[] = [
    'id',
    'status',
    'startDate',
    'affectedto',
    'action',
  ];
  onchangedatestart(event: any) {
    this.startdate = this.datePipe.transform(event, 'yyyy-MM-dd');
  }

  id_search: any = 0;
  typerecherche: any;
  date_disabled: boolean = true;
  changedate(event: any) {
    if (event != undefined) {
      this.date_disabled = false;
      this.typerecherche = event.code;
    } else {
      this.id_search = 0;
      this.startDate = '';
      this.endDate = '';
      this.date_disabled = true;
      this.GetCreditLetter();
    }
  }
  startdate: any;
  enddate: any;

  selecteddate: any;
  startDate: any;
  endDate: any;
  selectedstatus: any;
  statuslist: any[] = [];
  itemslist: any[];
  selectedcondition: any;
  valeurcondition: any;
  changeconditionitem(event: any) {
    if (event == undefined) {
      this.id_search = 0;
      this.valeurcondition = '';
      this.GetCreditLetter();
    } else {
      this.attr = event.code;
    }
  }
  etat: any;
  changestatus(event: any) {
    if (event != undefined) {
      this.etat = event.code;
      this.search_status();
    } else {
      this.id_search = 0;
      this.GetCreditLetter();
    }
  }

  search_status() {
    this.id_search = 3;

    if (this.etat == 'SUBMITTED') {
      this.ListCredit_f = this.CreditLetter_list_t.filter(
        (e) => e.status == this.etat
      );
    } else {
      this.ListCredit_f = this.CreditLetter_list_t.filter(
        (e) => e.approvalStatus == this.etat
      );
    }

    setTimeout(() => {
      this.dataSource = new MatTableDataSource(this.ListCredit_f);
      this.dataSource.paginator = this.paginator;
    }, 100);
  }
  onchangedateend(event: any) {
    this.enddate = this.datePipe.transform(event, 'yyyy-MM-dd');

    this.serach_date();
  }

  serach_date() {
    this.id_search = 2;
    if (this.typerecherche == 'applicationDate') {
      this.ListCredit_f = this.CreditLetter_list_t.filter(
        (e) =>
          e.applicationDate >= this.startdate &&
          e.applicationDate <= this.enddate
      );

      this.dataSource = new MatTableDataSource(this.ListCredit_f);
      this.dataSource.paginator = this.paginator;
    } else if (this.typerecherche == 'expiryDate') {
      this.ListCredit_f = this.CreditLetter_list_t.filter(
        (e) => e.expiryDate >= this.startdate && e.expiryDate <= this.enddate
      );

      this.dataSource = new MatTableDataSource(this.ListCredit_f);
      this.dataSource.paginator = this.paginator;
    } else if (this.typerecherche == 'signatureDate') {
      this.ListCredit_f = this.CreditLetter_list_t.filter(
        (e) =>
          e.signatureDate >= this.startdate && e.signatureDate <= this.enddate
      );
      this.dataSource = new MatTableDataSource(this.ListCredit_f);
      this.dataSource.paginator = this.paginator;
    } else {
      this.GetCreditLetter();
    }
  }

  dataSource: MatTableDataSource<any>;

  displayedColumnsP: string[] = ['file', 'actions'];
  dataSourcep: MatTableDataSource<any>;
  ListCustoms: any[] = [];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;

  langue: any;
  direction: any;
  languef: any;
  langue_list: any[] = Langue;
  languea: any;
  languem: any;
  languet: any;

  constructor(
    private titleService: Title,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private attchementsApi: AttachmentService,
    private affecationService: AffectationService,
    protected dataUtils: DataUtils,
    public sanitizer: DomSanitizer,
    private creditLetterServices: CreditLetterService,
    private organisationService: OrganisationService,
    private userrService: UserService,
    private notifier: NotifierService,
    private notificationApi: NotificationService
  ) {
    this.dataSource = new MatTableDataSource(this.ListCredit_f);
    this.dataSource = new MatTableDataSource(this.Messages_list);

    if (localStorage.getItem('langue') != null) {
      this.langue = localStorage.getItem('langue') + '';
    } else {
      this.langue = 'AR';
    }

    if (this.langue == 'AR') {
      this.direction = 'rtl';
      this.languef = this.langue_list[0]['AR'].tablelist;
      this.languea = this.langue_list[0]['AR'].LC;
      this.languem = this.langue_list[0]['AR'].messageuser;
      this.languet = this.langue_list[0]['AR'].modal_text;
    }

    if (this.langue == 'EN') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['EN'].tablelist;
      this.languea = this.langue_list[0]['EN'].LC;
      this.languem = this.langue_list[0]['EN'].messageuser;
      this.languet = this.langue_list[0]['EN'].modal_text;
    }

    if (this.langue == 'FR') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['FR'].tablelist;
      this.languea = this.langue_list[0]['FR'].LC;
      this.languem = this.langue_list[0]['FR'].messageuser;
      this.languet = this.langue_list[0]['FR'].modal_text;
    }
    this.titleService.setTitle(this.languet.entete);
    this.statuslist = [
      { id: 1, name: this.languet.save, code: 'INPROGRESS_1T2' },
      { id: 2, name: this.languet.forconfirmation, code: 'SUBMITTED' },
      { id: 3, name: this.languet.accept, code: 'ACCEPT' },
      { id: 4, name: this.languet.rejectd, code: 'REJECT' },
      { id: 5, name: this.languet.completed, code: 'INFORMATION_REQUEST' },
    ];

    this.itemlist = [
      {
        id: 1,
        name: this.languea.applicationdate,
        code: 'applicationDate',
        code1: 'startDate',
      },
      {
        id: 1,
        name: this.languea.expirydate,
        code: 'expiryDate',
        code1: 'expiryDate',
      },
      {
        id: 1,
        name: this.languea.signaturedate,
        code: 'signatureDate',
        code1: 'signatureDate',
      },
    ];

    this.itemslist = [
      {
        name: this.languea.applicationnumber,
        code: 'applicantAccountNumber',
        id: 4,
      },
      {
        name: this.languea.amount,
        code: 'amount',
        id: 4,
      },
      {
        name: this.languea.asper,
        code: 'asPer',
        id: 4,
      },

      {
        name: this.languea.number,
        code: 'asPerNumber',
        id: 4,
      },

      {
        name: this.languea.beneficiaryaddresse,
        code: 'beneficiaryAddress',
        id: 4,
      },

      {
        name: this.languea.beneficiarybankaccountnumber,
        code: 'beneficiaryBankAccountNumber',
        id: 4,
      },

      {
        name: this.languea.beneficiarybankname,
        code: 'beneficiaryBankName',
        id: 4,
      },

      {
        name: this.languea.beneficiarybankswiftcode,
        code: 'beneficiaryBankSwiftCode',
        id: 4,
      },

      {
        name: this.languea.beneficiaryname,
        code: 'beneficiaryName',
        id: 4,
      },

      {
        name: this.languea.charges,
        code: 'charges',
        id: 4,
      },

      {
        name: this.languea.confirminstructiontype,
        code: 'confirmInstructionType',
        id: 4,
      },
      {
        name: this.languea.currencycode,
        code: 'currencyCode',
        id: 4,
      },

      {
        name: this.languea.lctypecode,
        code: 'lcTypeCode',
        id: 4,
      },
      {
        name: this.languea.partialshipment,
        code: 'partialShipment',
        id: 4,
      },

      {
        name: this.languea.payment,
        code: 'paymentTerms',
        id: 4,
      },
      {
        name: this.languea.placeoffinaldischarge,
        code: 'placeOfFinalDischarge',
        id: 4,
      },

      {
        name: this.languea.priceconditioncode,
        code: 'priceConditionCode',
        id: 4,
      },
      {
        name: this.languea.shippingcompanyname,
        code: 'shippingCompanyName',
        id: 4,
      },

      {
        name: this.languea.signatoryname,
        code: 'signatoryName',
        id: 4,
      },
      {
        name: this.languea.signatoryfunction,
        code: 'signatoryFunction',
        id: 4,
      },
    ];

    this.actionlist = [
      { id: '1', name: this.languef.save },
      { id: '2', name: this.languef.submit },
    ];
    this.id_Org_en = localStorage.getItem('idorg');
    this.RoleTeammanager = this.userrService.ValidRole('Team manager');
    this.tradingcompany = this.userrService.ValidRole('Trading Company');
  }

  list_delete: any[] = [];
  actionlist: any[];
  Arr_remove() {
    this.modalService.dismissAll();
    this.list_delete = [];
    this.selection.selected.forEach((s) => this.list_delete.push(s));

    for (let credit of this.list_delete) {
      let credit_d = this.ListCredit_f.find((x) => x.id == credit.id);
      if (credit_d.status == 'INPROGRESS_1T2') {
        this.creditLetterServices
          .delete(credit_d.id)
          .subscribe((res: any) => { });
      }
    }
    setTimeout(() => {
      this.GetCreditLetter();
      this.ClearAll();
    }, 300);
  }

  document: any;
  Arr_view(content: any, document: any) {
    this.document = document;
    this.getAttchements(document.id);
    setTimeout(() => {
      this.modalService.open(content, {
        size: 'xl',
        windowClass: 'dark-modal',
      });
    }, 300);
  }

  open(content: any) {
    this.modalService.open(content);
  }
  Arr_update() { }

  id_org_S: any;
  ListCredit_f: any[] = [];
  CreditLetter_list_1: any[] = [];
  CreditLetter_list_t: any[] = [];
  affectedToUserName: any;
  GetCreditLetter() {
    if (this.RoleTeammanager) {
      this.creditLetterServices
        .GetByIdorg(this.id_Org_en)
        .subscribe((res: any) => {
          res?.forEach((LC: any) => {
            if (LC?.messages?.length > 0) {
              const lastMessage = LC.messages[LC.messages.length - 1];
              if (lastMessage?.actions?.length > 0) {
                const lastAction = lastMessage.actions[lastMessage.actions.length - 1];
                this.organisationService.GetOrgByID(lastAction.affectedToUser).subscribe((orgRes: any) => {
                  this.affectedToUserName = orgRes.userName;
                });
              }
            }
          });
          this.remplir_list(res);
        });
    } else {
      this.creditLetterServices
        .GetByIdUser(this.UserId)
        .subscribe((res: any) => {
          res?.forEach((LC: any) => {
            if (LC?.messages?.length > 0) {
              const lastMessage = LC.messages[LC.messages.length - 1];
              if (lastMessage?.actions?.length > 0) {
                const lastAction = lastMessage.actions[lastMessage.actions.length - 1];
                this.organisationService.GetOrgByID(lastAction.affectedToUser).subscribe((orgRes: any) => {
                  this.affectedToUserName = orgRes.userName;
                });
              }
            }
          });
          this.remplir_list(res);
        });
    }
  }

  remplir_list(list: any) {
    this.CreditLetter_list_1 = list;

    let resultB = this.CreditLetter_list_1.filter(
      (elm) =>
        !this.ListCredit_f.map((elm) => JSON.stringify(elm)).includes(
          JSON.stringify(elm)
        )
    );
    let resultC = this.ListCredit_f.filter(
      (elm) =>
        !this.CreditLetter_list_1.map((elm) => JSON.stringify(elm)).includes(
          JSON.stringify(elm)
        )
    );

    if (resultB.length > 0 || resultC.length > 0) {
      this.ListCredit_f = this.CreditLetter_list_1;
      this.CreditLetter_list_t = this.CreditLetter_list_1;
      this.CreditLetter_list_1 = [];
      if (this.selection.selected.length == 0) {
        this.ListCredit_f.forEach((currentLc, index) => {
          currentLc.messages.sort((a: any, b: any) => a.id - b.id);
        });
        this.dataSource = new MatTableDataSource(this.ListCredit_f);
        this.dataSource.paginator = this.paginator;
      }
    }
  }

  @Input() profil: string;
  dataSourceArticles: MatTableDataSource<any>;
  ngOnInit(): void {
    this.GetIdUser();
    //this.Websocket_credit();
    if (this.profil == 'Banque') {
      this.premission = 'LC_A2.1_LE';
      this.actionlist = [{ id: '2', name: this.languef.submit }];
    } else {
      this.premission = 'LC_A1.1_LE';
      this.actionlist = [
        // { id: '1', name: this.languef.save },
        { id: '2', name: this.languef.submit },
      ];
    }
  }

  selection = new SelectionModel<any>(true, []);
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  ClearAll() {
    this.selection.clear();
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1
      }`;
  }

  list_print: any[] = [];
  printdoc() {
    this.list_print = [];
    this.selection.selected.forEach((s) => this.list_print.push(s));

    for (let letter of this.list_print) {
      let letter_f = this.ListCredit_f.find((x) => x.id == letter.id);
      this.generate_pdf(letter_f, letter_f.attachments);
    }

    setTimeout(() => {
      this.ClearAll();
    }, 300);
  }

  dimision() {
    this.modalService.dismissAll();
  }

  reason: any;
  open_reason(content: any, reason: any) {
    this.reason = reason;
    this.open(content);
  }

  tcesend: any;
  tceidsend: any;
  opensend(content: any, tce: any) {
    this.tcesend = tce;
    this.tceidsend = tce.id;

    this.open(content);
  }

  document_src: any;
  openpdf(pdf: any, content: any) {
    this.document_src = pdf;
    setTimeout(() => {
      this.modalService.open(content, {
        size: 'xl',
        windowClass: 'dark-modal',
      });
    }, 100);
  }
  dataSourceMessages: MatTableDataSource<any>;

  Messages_list: any[] = [];
  Arr_messages(content: any, list_message: any) {
    this.Messages_list = list_message;
    setTimeout(() => {
      this.Messages_list = this.Messages_list.sort((a, b) => a.id - b.id);
      this.dataSourceMessages = new MatTableDataSource(this.Messages_list);
      this.dataSourceMessages.paginator = this.paginator;
      this.modalService.open(content, {
        size: 'xl',
        windowClass: 'dark-modal',
      });
    }, 100);
  }
  displayedColumnsMessages: string[] = [
    'id',
    'status',
    'startDate',
    'endDate',
    'typeFlux',
    'etatFlux',
    'idDossier',
    'typeMessage',
    'typeScenario',
  ];
  y: any = 0;
  generate_pdf(letter: any, attachmentsName: string[]): void {
    this.y = 0;
    const doc = new jsPDF();
    const img = new Image();
    img.src = './../../../../assets/logo_dark.png';
    doc.setFont('italic');
    doc.addImage(img, 'png', 150, 5, 50, 20);
    doc.setFontSize(10);
    doc.setTextColor('#46B1E3');

    doc.text('Credit Letter  :' + String(letter.id), 5, 10);
    // doc.text('Shipping Company :' + '_', 5, 15);
    doc.setFontSize(20);
    doc.text('Lettre of credit', 75, 15);
    doc.setFontSize(11);
    this.y = 40;
    doc.setLineWidth(0.5);
    // const img = new Image();
    // // img.src = './../../../../assets/logo_dark.png';
    // img.src = './../../../content/images/logo_jhipster.png';
    // doc.setFont('italic');
    // doc.addImage(img, 'png', 150, 5, 50, 20);
    const x1 = 10;
    const x2 = 75;
    const x3 = 130;

    doc.setFontSize(11);
    doc.setTextColor('#55EC7A');
    doc.text('APPLICATION INFORMATION', 10, 80);

    doc.line(5, 83, 5, 185);
    doc.line(5, 83, 200, 83);
    doc.line(200, 83, 200, 185);
    doc.line(5, 185, 200, 185);

    doc.setTextColor('#1D3A66');
    this.y = 90;
    doc.setFontSize(9);

    doc.text('Form of L/C : ' + String(letter.lcTypeCode ?? '...'), x1, this.y);
    doc.text(
      'Beneficiary Name : ' + String(letter.beneficiaryName ?? '...'),
      x2,
      this.y
    );
    doc.text(
      'Beneficiary Address : ' + String(letter.beneficiaryAddress ?? '...'),
      x3,
      this.y
    );

    this.y = 100;
    doc.setFontSize(9);
    doc.text(
      'Beneficiary Bank Swift Code : ' +
      String(letter.beneficiaryBankSwiftCode ?? '...'),
      x3,
      this.y
    );
    doc.text(
      'Beneficiary Bank : ' + String(letter.beneficiaryBankName ?? '...'),
      x1,
      this.y
    );
    doc.text(
      'Beneficiary Bank A/C No. : ' +
      String(letter.beneficiaryBankAccountNumber ?? '...'),
      x2,
      this.y
    );

    // doc.setFontSize(11);
    // doc.setTextColor('#55EC7A');

    // doc.setTextColor('#1D3A66');

    this.y = 110;
    doc.setFontSize(9);
    doc.text(
      'Currency Code : ' + String(letter.beneficiaryBankName ?? '...'),
      x1,
      this.y
    );
    doc.text(
      'Amount : ' +
      String(letter.amount ?? '...') +
      '  (' +
      String(letter.allowanceRateFrom ?? '...') +
      '~' +
      String(letter.allowanceRateTo ?? '...') +
      ')',
      x2,
      this.y
    );

    this.y = 120;
    doc.setFontSize(9);
    doc.text(
      'Payment Terms : ' + String(letter.paymentTerms ?? '...') + ' ',
      x1,
      this.y
    );
    doc.text(
      'Deferred Payment Period : ' +
      String(letter.deferredPaymentPeriod ?? '...'),
      x2,
      this.y
    );

    this.y = 130;
    doc.setFontSize(9);
    doc.text(
      'Partial Shipment : ' + String(letter.partialShipment ?? '...'),
      x1,
      this.y
    );
    doc.text(
      'Transhipment : ' + String(letter.transhipment ?? '...') + ' ',
      x2,
      this.y
    );
    doc.text(
      'Shipping Company Name : ' + String(letter.shippingCompanyName ?? '...'),
      x3,
      this.y
    );

    // doc.setFontSize(11);
    // doc.setTextColor('#55EC7A');

    // doc.setTextColor('#1D3A66');

    this.y = 140;
    doc.setFontSize(9);
    doc.text(
      'Port Of Loading : ' + String(letter.portOfLoading ?? '...'),
      x1,
      this.y
    );
    doc.text(
      'Port Of Discharge : ' + String(letter.portOfDischarge ?? '...'),
      x2,
      this.y
    );
    doc.text(
      'Port Of Final Discharge : ' +
      String(letter.portOfFinalDischarge ?? '...') +
      ' ',
      x3,
      this.y
    );
    this.y = 150;
    doc.setFontSize(9);
    doc.text(
      'Description Of Goods : ' + String(letter.descriptionOfGoods ?? '...'),
      x1,
      this.y
    );
    doc.text(
      'Origin Nation Name : ' + String(letter.originNationName ?? '...') + ' ',
      x2,
      this.y
    );
    doc.text(
      'Pro Forma Invoice No : ' + String(letter.proFormaInvoiceNo ?? '...'),
      x3,
      this.y
    );

    this.y = 160;
    doc.setFontSize(9);

    doc.text(
      'Contract : ' + String(letter.contract ?? '...') + ' ',
      x1,
      this.y
    );
    doc.text(
      'Price Condition Code :' + String(letter.priceConditionCode ?? '...'),
      x2,
      this.y
    );

    this.y = 170;
    doc.setFontSize(9);
    doc.text(
      'Purchase Order Date : ' + String(letter.purchaseOrderDate ?? '...'),
      x1,
      this.y
    );
    this.y = 180;

    doc.text(
      'lastShippingDate : ' + String(letter.lastShippingDate ?? '...'),
      x1,
      this.y
    );
    //
    this.y = 50;
    doc.setFontSize(11);
    doc.setTextColor('#55EC7A');
    doc.text('REGISTRATION OF L/C ISSUE APPLICATION', 10, this.y);

    doc.setTextColor('#1D3A66');
    doc.setFontSize(9);
    this.y = 60;

    doc.text(
      'Applicant  : ' +
      String(
        String(letter.applicant ?? '...') +
        '. ' +
        String(letter.messages?.at(0)?.organizationSender?.name)
      ),
      x1,
      this.y
    );
    doc.text(
      'Issuing Bank : ' +
      String(
        String(letter.issuingBank ?? '...') +
        '. ' +
        String(letter.messages?.at(0)?.organizationReceiver?.name ?? '')
      ),
      x2,
      this.y
    );

    this.y = 70;
    doc.text(
      'A/c No. : ' + String(letter.applicantAccountNumber ?? '...'),
      x1,
      this.y
    );
    doc.text(
      'Application No : ' + String(letter.lcApplicationNumber ?? '...'),
      x2,
      this.y
    );

    doc.line(5, 55, 5, 75);
    doc.line(5, 55, 200, 55);
    doc.line(200, 55, 200, 75);
    doc.line(5, 75, 200, 75);
    this.y = 190;
    doc.setFontSize(11);
    doc.setTextColor('#55EC7A');
    doc.text('LIST OF ATTACHMENT', 10, this.y);

    doc.setTextColor('#1D3A66');

    doc.setFontSize(9);

    doc.line(5, 195, 5, 230);
    doc.line(5, 195, 200, 195);
    doc.line(200, 195, 200, 230);
    doc.line(5, 230, 200, 230);

    this.y = 200;
    for (const attachment of attachmentsName) {
      doc.text('* ' + String(attachment), x1, this.y);
      this.y = this.y + 5;
    }

    doc.save('creditLetter_' + String(letter.id) + '.pdf');
  }

  getInformationOrg(id: any): Promise<string> {
    return new Promise((resolve, reject) => {
      this.organisationService.GetInformationOrganisationByIdOrg(id)
        .subscribe({
          next: (response: any) => resolve(response.nameOrg),
          error: (error) => reject(error)
        });
    });
  }

  Id_org: any;
  creditDocument: any;
  nomOrgSender: String;
  nomOrgReciever: String;
  submit(content: any, row: any, contentmodal: any) {
    this.creditDocument = row;

    if (this.creditDocument.moreInformationResponse != null) {
      this.open(contentmodal);
    } else {
      this.creditLetterServices.submit(this.creditDocument).subscribe(async (res: any) => {
        this.GetCreditLetter();
        this.open(content);

        try {

          const [nomOrgSender, nomOrgReceiver] = await Promise.all([
            this.getInformationOrg(this.creditDocument.messages[0].organizationSender),
            this.getInformationOrg(this.creditDocument.messages[0].organizationReceiver)
          ]);

          this.nomOrgSender = nomOrgSender;
          this.nomOrgReciever = nomOrgReceiver;

          const formdata = {
            action: 'SUBMIT',
            idOrgSender: this.creditDocument.messages[0].organizationSender,
            nomOrgSender: this.nomOrgSender,
            idOrgReceiver: this.creditDocument.messages[0].organizationReceiver,
            nomOrgReceiver: this.nomOrgReciever,
            idScenario: this.creditDocument.id,
          };

          this.notificationApi.post(formdata).subscribe((res: any) => {
          });

        } catch (error) {
          console.error("Error fetching organization information:", error);
        }
      });
    }
  }

  submit_message(content: any) {
    this.dimision();
    this.creditDocument.moreInformationResponse = this.messageresponse;
    this.creditLetterServices
      .submit(this.creditDocument)
      .subscribe((res: any) => {
        this.GetCreditLetter();
        this.open(content);
      });
  }

  open_submit(content: any, document: any) {
    // this.Id_org = document.;
    this.creditDocument = document;
    this.open(content);
  }

  private socket1: any;
  web_socket: boolean = false;

  Websocket_credit() {
    var host = 'wss://ws.coincap.io/prices?assets=bitcoin,ethereum,litecoin';

    this.socket1 = new WebSocketSubject(host);

    this.socket1.subscribe(
      (message: any) => { },
      (err: any) => console.log(err)
    );

    this.socket1 = new WebSocket(host);
    this.socket1.onmessage = (e: any) => {
      if (this.id_search == 0) {
        this.GetCreditLetter();
      }
    };
  }

  attr: any;
  UserId: any;
  id_Org_en: any;
  search() {
    this.id_search = 1;
    this.creditLetterServices
      .Search(this.attr, this.valeurcondition)
      .subscribe((res: any) => {
        this.ListCredit_f = res;
        console.log("this.ListCredit_f" + this.ListCredit_f);
        console.log("this.valeurcondition" + this.valeurcondition);

        console.log("this.valeurcondition" + this.attr);

        setTimeout(() => {
          this.dataSource = new MatTableDataSource(this.ListCredit_f);
          this.dataSource.paginator = this.paginator;
        }, 100);
      });
  }

  Getorganisation_send(id: any) {
    this.organisationService.Get_org_byID(id).subscribe((res: any) => {
      this.id_Org_en = res.id;

      this.GetCreditLetter();
    });
  }

  GetIdUser() {
    this.userrService.Getuserbyusername().subscribe((res: any) => {
      this.TeamId = res.team.id;
      this.UserId = res.id;
      this.Getorganisation_send(this.UserId);
    });
  }

  Pieces_DATA: any[] = [];
  FormPieces: FormGroup;
  displayedColumnsPieces: string[] = ['name', 'file', 'action'];
  dataSourcePieces: MatTableDataSource<any>;

  getAttchements(id: any) {
    this.attchementsApi.findByLC(id).subscribe((res: any) => {
      this.Pieces_DATA = res.body;

      setTimeout(() => {
        this.dataSourcePieces = new MatTableDataSource(this.Pieces_DATA);
      }, 300);
    });
  }

  openFile(base64String: string, contentType: string | null | undefined): void {
    // this.dataUtils.openFile(base64String, contentType);
    window.open(base64String, '_blank');
  }

  file_source: any;
  type_source: any;
  viewPDF(content: any, file: any) {
    this.file_source = file.attachment;

    if (file.attachmentContentType.indexOf('image') != -1) {
      this.type_source = 'image';
    } else if (file.attachmentContentType.indexOf('pdf') != -1) {
      this.type_source = 'pdf';
    } else {
      this.type_source = 'no';
    }

    if (this.type_source != 'no') {
      setTimeout(() => {
        this.modalService.open(content, {
          size: 'xl',
          windowClass: 'dark-modal',
        });
      }, 400);
    }
  }

  dataSourceActions: MatTableDataSource<any>;
  listactions: any[];
  id_folder: any;
  message_user: any;
  message_user_list: any[] = [];
  reason_assign: any;
  messages_actions: any[] = [];
  message_sender: any;
  message_receiver: any;
  Arr_assign(content: any, id_folder: any, document: any) {
    this.transactiontype = 'Save';
    this.status_sc = false;
    this.id_folder = id_folder;
    let row_message = document.messages.length - 1;

    this.message_user_list = document.messages;
    this.message_user_list = this.message_user_list.sort((a, b) => a.id - b.id);
    let status_message = document.messages[row_message].status;
    this.message_user = document.messages[row_message];

    this.id_message = document.messages[row_message].id;
    console.log("this.message_user.organizationSender " + this.message_user.organizationSender)
    console.log("this.message_user.organizationReceiver " + this.message_user.organizationReceiver)
    if (this.message_user.organizationSender != null) {
      this.message_sender = this.message_user.organizationSender;
    }

    if (this.message_user.organizationReceiver != null) {
      this.message_receiver = this.message_user.organizationReceiver;
    }

    let conditionReceiver =
      document.status == 'SUBMITTED' && this.message_receiver == this.id_Org_en;

    let conditionSender =
      document.status == 'INPROGRESS_1T2' &&
      this.message_sender == this.id_Org_en;

    console.log(" this.id_Org_en " + this.id_Org_en);
    console.log(" conditionSender " + conditionSender);

    let conditionSenderIR =
      document.status == 'INFORMATION_REQUEST' &&
      console.log(" this.id_Org_en " + this.id_Org_en);
    this.message_receiver == this.id_Org_en;

    let conditionReceiverIR =
      document.status == 'SUBMITTED' && this.message_receiver == this.id_Org_en;

    if (status_message == 'SUCCESS' || status_message == 'INPROGRESS') {
      console.log(" this.id_Org_en " + this.id_Org_en);
      if (
        conditionReceiver ||
        conditionSender ||
        conditionSenderIR ||
        conditionReceiverIR
      ) {
        this.status_sc = false;
        this.messages_actions = this.message_user.actions;

        if (conditionReceiver) {
          this.messages_actions = this.message_user.actions.filter(
            (e: any) => e.message.organizationSender == this.id_Org_en
          );
        }

        if (conditionSender) {
          this.messages_actions = this.message_user.actions.filter(
            (e: any) => e.message.organizationSender == this.id_Org_en
          );
        }

        if (conditionReceiverIR) {
          this.messages_actions = this.message_user.actions.filter(
            (e: any) => e.message.organizationSender == this.id_Org_en
          );
        }
        let row_actions = this.messages_actions.length;

        if (row_actions > 0) {
          this.messages_actions = this.messages_actions.sort(
            (a, b) => a.id - b.id
          );

          if (this.messages_actions[row_actions - 1].status != 'FINISHED') {
            this.status_sc = true;
            this.reason_assign = this.languet.text_assign_2;
          }
        }

        setTimeout(() => {
          this.dataSourceActions = new MatTableDataSource(
            this.messages_actions
          );
        }, 200);
      } else {
        this.status_sc = true;
        this.reason_assign = this.languet.text_assign_1;
      }
    } else {
      this.status_sc = true;
      this.reason_assign = this.languet.text_assign_1;
    }

    setTimeout(() => {
      this.modalService.open(content, {
        size: 'xl',
        windowClass: 'dark-modal',
      });
    }, 500);
  }

  selectetassign: any;

  selectetuser: any;
  Id_user: any;
  acions_t: any;
  userslist: any[];

  changeuser(event: any) {
    this.Id_user = event.id;
  }

  getUser(team: any, sc: any) {
    this.userrService.GetAllUsers(team, sc).subscribe((res: any) => {
      this.userslist = res;
    });
  }
  Assign() {
    if (this.transactiontype == 'Save') {
      this.actiondata = {
        code: this.acions_t,
        affectedToUser: this.Id_user,
        designation: this.designation,
      };

      this.affecationService
        .assignm(this.creditLetterServices.Spring_serveur, this.actiondata, this.id_folder, 'lc')
        .subscribe((res: any) => {
          this.notifier.notify(
            'success',
            this.languet.titremodal + ' ' + this.languet.task
          );

          this.designation = '';
          this.selectetassign = null;
          this.selectetuser = null;
          this.dimision();
        });
    } else {
      this.actiondata.code = this.acions_t;
      this.actiondata.affectedToUser.id = this.Id_user;
      this.actiondata.designation = this.designation;

      this.affecationService
        .reassign(this.creditLetterServices.Spring_serveur, this.actiondata, this.id_Action)
        .subscribe((res: any) => {
          this.designation = '';
          this.selectetassign = null;
          this.selectetuser = null;
          this.dimision();
          this.notifier.notify(
            'success',
            this.languet.titremodal + ' ' + this.languet.task
          );
        });
    }
  }
  TeamId: any;

  designation: any;
  id_message: any;
  premission: any;
  changeaction(event: any) {
    this.userslist = [];
    this.selectetuser = null;
    if (event == undefined) {
      this.userslist = [];
    }

    if (event.id == '1') {
      this.getUser(this.TeamId, this.premission);
      this.acions_t = 'SAVE';
    } else if (event.id == '2') {
      this.getUser(this.TeamId, this.premission + 'S');
      this.acions_t = 'SUBMIT';
    }
  }

  color_active = '#77838e';
  status_sc: boolean = false;
  RoleTeammanager: boolean = false;
  tradingcompany: boolean = false;
  messageresponse: any;

  transactiontype = 'Save';
  id_Action: any;
  action: any;
  useraction: any;
  actiondata: any;

  reassign(action: any) {
    this.transactiontype = 'Update';
    this.action = action;
    this.actiondata = action;
    this.id_Action = action.id;
    this.useraction = action.affectedToUser;
    this.selectetuser = this.useraction.userName;
    this.Id_user = this.selectetuser.id;
    this.selectetassign = this.action.code;
    this.designation = this.action.designation;
    this.status_sc = false;
  }
}
