import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { VesselCallInformationModule } from './Shipping_Arrival/VesselCallInformation.module';
import { Shipping_departure_gestionModule } from './Shipping_Departure/Shipping_departure_gestion.module';
import { PageAccueilComponent } from './page/page-accueil/page-accueil.component';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { DashboardComponent } from './page/dashboard/dashboard.component';
import { AuthGuard } from 'src/Services/Guard/auth.guard';
import { CargoManifestModule } from './Cargo_manifest/Cargo_manifest.module';
import { AgentGuard } from 'src/Services/Roles/Agent/agent.guard';
import { Ship_dockingModule } from './Ship_docking/ship_docking.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgChartsModule } from 'ng2-charts';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from 'src/app/Interceptors/auth.interceptor';

@NgModule({
  declarations: [PageAccueilComponent, DashboardComponent],
  imports: [
    CommonModule,
    CargoManifestModule,
    VesselCallInformationModule,
    Shipping_departure_gestionModule,
    MatIconModule,
    RouterModule,
    FormsModule,
    MatTabsModule,
    MatRadioModule,
    Ship_dockingModule,
    NgxSkeletonLoaderModule,
    NgChartsModule,
    RouterModule.forChild([
      {
        path: 'liste',
        component: PageAccueilComponent,
        canActivate: [AuthGuard, AgentGuard],
      },

      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard, AgentGuard],
      },
    ]),
  ],
  exports: [DashboardComponent, PageAccueilComponent],
  providers: [DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },],
})
export class ElogisticModule { }
