import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  url: any;

  //Spring_serveur: string = 'foprodex/api/notifications/';
  constructor(private router: Router, private http: HttpClient) {
    this.url = environment.UrlGetway;
  }
  public Get(spring_serveur : string) {
    return this.http.get<any>(`${this.url + spring_serveur + '/notifications/by-org'}`);
  }
  public Getbyid(spring_serveur : string, id: any) {
    return this.http.get<any>(`${this.url + spring_serveur + '/notifications/' + id}`);
  }

  public post(spring_serveur : string, id_fop: any) {
    return this.http.post(this.url + spring_serveur , id_fop, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    });
  }

  public update(spring_serveur : string, id_notfi: any, formdata: any) {
    return this.http.put(this.url + spring_serveur + '/notifications/' + id_notfi, { formdata });
  }
}
