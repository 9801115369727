import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ManifestService {
  url: any;

  Spring_serveur: string = 'bsm-service/api';
  scenario_rest_url: string = '/Generalinformation/'
  constructor(private router: Router, private http: HttpClient) {
    this.url = environment.Url + this.Spring_serveur + this.scenario_rest_url;
  }
  public Get() {
    // Listinfos-per-user

    return this.http.get<any>(`${this.url + 'Listinfos-per-user'}`);
  }

  public GetByteam() {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.get<any>(this.url + 'Listinfos-per-team');
  }

  public GetByUser() {
    return this.http.get<any>(`${this.url + 'generalInfo/for-user-affecte'}`);
  }
  //port
  public GetByOrg() {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.get<any>(this.url + 'Listinfos-per-org', {
      headers: headers1,
    });
  }

  public GetById(id: any) {
    return this.http.get<any>(`${this.url + 'ginformations/' + id}`);
  }

  public post(formData: any, id: any) {
    return this.http.post(this.url + 'save/' + id, formData, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    });
  }
  public update(formData: any, id: any) {
    return this.http.put<any>(
      `${this.url + 'generalinformations/' + id}`,
      formData
    );
  }
  public delete(id: any) {
    return this.http.delete<any>(`${this.url + 'Deleteinformations/' + id}`);
  }

  public submit(formData: any, idOrgR: any) {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post<any>(this.url + `submit/${idOrgR}`, formData, {
      headers: headers1,
      responseType: 'text' as 'json',
    });
  }

  public response(formData: any) {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(this.url + 'response', formData, {
      headers: headers1,
      responseType: 'text',
    });
  }

  public Serach(attribue: any, valeur: any) {
    return this.http.get<any>(
      `${this.url +
      'Listinfos-by-attribute?attribute=' +
      attribue +
      '&value=' +
      valeur
      }`
    );
  }

  finishaction(formData: any) {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(this.url + 'user/finishAction', formData, {
      headers: headers1,
    });
  }
}
