import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AffectationService {
  url: any;
  Url: any;

  Spring_serveur: string = '/actions/';
  constructor(private http: HttpClient) {
    this.url = environment.Url + this.Spring_serveur;
    this.Url = environment.UrlGetway;
  }

  public assignm(spring_serveur: string, formData: any, id_Doc: any, sc: any) {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(this.Url + spring_serveur + this.Spring_serveur + sc + `?` + sc + `ID=${id_Doc}`, formData, {
      headers: headers1,
    });
  }

  public reassign(spring_serveur: string, formData: any, id_action: any) {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.put(this.Url + spring_serveur + this.Spring_serveur  + `${id_action}`, formData, {
      headers: headers1,
    });
  }
}
