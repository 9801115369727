import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ShippinggaranteService {
  url: any;

  Spring_serveur: string = 'bsm-service/api';
  scenario_rest_url: string = '/shipping-guarantees/';
  constructor(private http: HttpClient) {
    this.url = environment.Url + this.Spring_serveur + this.scenario_rest_url;
  }

  public Get() {
    return this.http.get<any>(`${this.url}`);
  }

  public GetByIdorg(id_org: any) {
    return this.http.get<any>(`${this.url + 'organization/' + id_org}`);
  }

  public GetByIdUser(id_user: any) {
    return this.http.get<any>(`${this.url + 'user/' + id_user}`);
  }

  public GetByid(id: any) {
    return this.http.get<any>(`${this.url + id}`);
  }

  public post(formData: any, idOrgR: any) {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(this.url + `?idOrgR=${idOrgR}`, formData, {
      headers: headers1,
    });
  }

  public update(formData: any, id_letter: any) {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.put(this.url + id_letter, formData, {
      headers: headers1,
    });
  }

  public submit(formData: any) {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(this.url + `saveAndSubmit/`, formData, {
      headers: headers1,
      responseType: 'text'
    });
  }

  public response(formData: any, id_copy: any) {
    const headers1 = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(this.url + `response/` + id_copy, formData, {
      headers: headers1,
    });
  }

  public delete(id: any) {
    return this.http.delete<any>(`${this.url + id }`);
  }
  public Search(attribue: any, valeur: any) {
    return this.http.get<any>(
      `${
        this.url +
        'List-by-attribute?attribute=' +
        attribue +
        '&value=' +
        valeur
      }`
    );
  }
}
