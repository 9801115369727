import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alliances-strategiques',
  templateUrl: './alliances-strategiques.component.html',
  styleUrls: ['./alliances-strategiques.component.css']
})
export class AlliancesStrategiquesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
