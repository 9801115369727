import { Component, OnInit } from '@angular/core';
import {
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';

import { FormBuilder, FormControl, Validators } from '@angular/forms';
import Langue from '../../../../../assets/langue.json';
import { portofcall } from '../../Models/portofcall';
import { OrganisationService } from 'src/Services/Organisation/organisation.service';
import { bank } from '../../Models/bank';
import { Title } from '@angular/platform-browser';
import { UserService } from 'src/Services/User/user.service';
import { ShippinggaranteService } from '../Services/shippinggarante.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.css'],
})
export class NewComponent implements OnInit {
  bill = [
    { id: 1, name: 'Bill Of Lading' },
    { id: 2, name: 'Airway Bill' },
  ];
  amount = [
    { id: 1, name: 'USD' },
    { id: 1, name: 'EUR' },
    { id: 1, name: 'TND' },
  ];
  langue: any;
  direction: any;
  languef: any;
  langue_list: any[] = Langue;
  languea: any;
  languem: any;
  languet: any;
  languee: any;

  constructor(
    private formBuilder: FormBuilder,
    private Organisation: OrganisationService,
    private modalService: NgbModal,
    private router: Router,
    private titleService: Title,
    private userrService: UserService,
    private OrganisationService: OrganisationService,
    private shippingGaranteService: ShippinggaranteService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {
    this.Getorganisation();
    //this.GetorganisationBank();
    if (localStorage.getItem('langue') != null) {
      this.langue = localStorage.getItem('langue') + '';
    } else {
      this.langue = 'AR';
    }

    if (this.langue == 'AR') {
      this.direction = 'rtl';
      this.languef = this.langue_list[0]['AR'].tablelist;
      this.languem = this.langue_list[0]['AR'].messageuser;
      this.languet = this.langue_list[0]['AR'].modal_text;
      this.languee = this.langue_list[0]['AR'].SG;
    }

    if (this.langue == 'EN') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['EN'].tablelist;
      this.languem = this.langue_list[0]['EN'].messageuser;
      this.languet = this.langue_list[0]['EN'].modal_text;
      this.languee = this.langue_list[0]['EN'].SG;
    }

    if (this.langue == 'FR') {
      this.direction = 'ltr';
      this.languef = this.langue_list[0]['FR'].tablelist;
      this.languem = this.langue_list[0]['FR'].messageuser;
      this.languet = this.langue_list[0]['FR'].modal_text;
      this.languee = this.langue_list[0]['FR'].SG;
    }
    this.titleService.setTitle(this.languet.entete);

    this.Shipping_id = this.route.snapshot.params['id'];
    this.Shipping_copy_id = this.route.snapshot.params['id1'];
    if (this.Shipping_id != undefined) {
      this.spinner.show();
      this.transactiontype = 'Update';
      this.GetSgById(this.Shipping_id);
    } else if (this.Shipping_copy_id != undefined) {
      this.spinner.show();
      this.GetSgById(this.Shipping_copy_id);

      this.transactiontype = 'Copy';
    } else {
      this.transactiontype = 'Save';
    }
  }
  Shipping_copy_id: any;
  Form1 = this.formBuilder.group({
    applicant: [''],
    applicationnumber: [''],
    shippingCompany: [''],
    applicationdate: [''],
    lc: [''],
    billoflading: [''],
    airwaybillnumber: [''],
    billoflading_v: [''],
    applicantaccountnumber: [''],
    amount: [''],
    currencycode: [''],
  });

  idOrgR: any;
  idOrgPort: any;
  PortCallName: any;
  PortCallcode: any;
  selectedportcall: any;

  changeportcall(event: any) {
    let port = this.portlistofcall.find((x) => x.id == event.id);
    this.PortCallName = port?.nom;
    this.PortCallcode = port?.id;
    this.idOrgPort = event.id;
  }
  billoflading_etat: boolean = false;
  airway_bill_etat: boolean = false;

  changebillofloading(event: any) {
    if (event != undefined) {
      if (event.id == 1) {
        this.billoflading_etat = true;
        this.airway_bill_etat = false;
      } else if (event.id == 2) {
        this.airway_bill_etat = true;
        this.billoflading_etat = false;
      }
    } else {
      this.airway_bill_etat = false;
      this.billoflading_etat = false;
    }
  }

  list_organsation: portofcall[] = [];
  list_organsationbank: bank[] = [];
  Getorganisation() {
    this.Organisation.Get().subscribe((res: any) => {
      console.log("list org " + res)
      this.organisation_total = res;
      this.portlistofcall = [];
      this.list_organsation = [];
      this.list_organsation = res;
      this.listofBank = [];
      this.list_organsationbank = [];
      this.list_organsationbank = res;

      this.list_organsationbank = this.list_organsationbank.filter(
        (e: any) => e.profil == 'Banque'
      );
      this.list_organsation = this.list_organsation.filter(
        (e: any) => e.profil == 'Shipping_agent'
      );

      this.portlistofcall = this.list_organsation;

      if (this.transactiontype == 'Update' || this.transactiontype == 'Copy') {
        let port = this.portlistofcall.filter(
          (e: any) => e.id == this.document_sg.shippingCompany
        );

        this.selectedportcall = port[0].nom;
      }
      this.listofBank = this.list_organsationbank;
    });
  }
  listofBank: bank[] = [];
  selectedissuingbank: any;
  issuingbank_list: any[] = [];
  organisation_total: any[] = [];
  portlistofcall: portofcall[] = [];

  bankName: any;
  bankCode: any;
  changeissuingbank(event: any) {
    let bank = this.listofBank.find((x) => x.id == event.id);
    this.bankName = bank?.nom;
    this.bankCode = bank?.id;
    this.idOrgR = event.id;
  }

  selectedform: any;
  selectedamount: any;
  selectedbill: any;
  form_list: any[] = [];
  changeform(event: any) { }
  currencycode: any;
  changeamount(event: any) {
    this.currencycode = event.name;
  }
  changebill(event: any) { }
  onchangeapplicationdate(event: any) { }
  applicationdate = new FormControl();

  lastshippingdate = new FormControl();
  onchangelastshippingdate(event: any) { }

  date = new FormControl();
  onchangedate(event: any) { }

  signaturedate = new FormControl();
  onchangesignaturedate(event: any) { }

  selectedcurrencycode: any;
  currencycode_list: any[] = [];
  changedcurrencycode(event: any) { }

  selectedpayment: any;
  payment_list: any[] = [];
  changepayment(event: any) { }

  selectedportofloading: any;
  portofloading_list: any[] = [];
  changeportofloading(event: any) { }

  selectedportofdischarge: any;
  portofdischarge_list: any[] = [];
  changeportofdischarge(event: any) { }

  selectedasper: any;
  asper_list: any[] = [];
  changeasper(event: any) { }

  selectedconfirminstructiontype: any;
  confirminstructiontype_list: any[] = [];
  changeconfirminstructiontype(event: any) { }

  ngOnInit(): void {
    this.GetIdUser();
  }

  Submit(content: any) {
    this.shippingGaranteService
      .submit(this.CreateFormShipping())
      .subscribe((res: any) => {
        this.open(content);

        setTimeout(() => {
          this.Go_list();
        }, 300);
      });
  }
  etat: any;

  transactiontype: any = 'Save';

  Save(content: any) {
    if (this.transactiontype == 'Save' || this.transactiontype == 'Copy') {
      this.shippingGaranteService
        .post(this.CreateFormShipping(), this.idOrgR)
        .subscribe((res: any) => {
          this.open(content);
        });
    } else if (this.transactiontype == 'Update') {
      this.shippingGaranteService
        .update(this.CreateFormShipping(), this.Shipping_id)
        .subscribe((res: any) => {
          this.open(content);
        });
    }
  }

  open(content: any) {
    this.modalService.open(content);
  }
  Go_list() {
    this.modalService.dismissAll();
    localStorage.setItem('redirecturl', '2');
    this.router.navigateByUrl('Trade/liste');
  }

  Shipping_id: any;

  CreateFormShipping(): any {
    return {
      id: this.Shipping_id,
      lcNumber: this.Form1.controls['lc'].value,
      issuingBank: this.idOrgR,
      shippingCompany: this.idOrgPort,
      invoiceCurrencyCode: this.currencycode,
      invoiceAmount: this.Form1.controls['amount'].value,
      billOfLadingNumber: this.Form1.controls['airwaybillnumber'].value,
      airwayBillNumber: this.Form1.controls['billoflading_v'].value,
      applicant: this.id_Org_en,
      applicantAccountNumber:
        this.Form1.controls['applicantaccountnumber'].value,
    };
  }

  UserId: any;
  GetIdUser() {
    this.userrService.Getuserbyusername().subscribe((res: any) => {
      this.UserId = res.id;
      this.Getorganisation_send();
    });
  }
  id_Org_en: any;
  Getorganisation_send() {
    this.OrganisationService.Get_org_byID(this.UserId).subscribe((res: any) => {
      this.id_Org_en = res.id;
    });
  }

  document_sg: any;
  remplirForm() {
    this.Form1.controls['lc'].setValue(this.document_sg.lcNumber);
    this.Form1.controls['amount'].setValue(this.document_sg.invoiceAmount);
    this.Form1.controls['billoflading_v'].setValue(
      this.document_sg.billOfLadingNumber
    );
    this.Form1.controls['airwaybillnumber'].setValue(
      this.document_sg.airwayBillNumber
    );
    this.Form1.controls['applicantaccountnumber'].setValue(
      this.document_sg.applicantAccountNumber
    );
    this.id_Org_en = this.document_sg.applicant;
    this.idOrgR = this.document_sg.issuingBank;
    this.idOrgPort = this.document_sg.shippingCompany;

    if (this.document_sg.airwayBillNumber != null) {
      this.billoflading_etat = true;
      this.airway_bill_etat = false;
    } else if (this.document_sg.airwayBillNumber != null) {
      this.airway_bill_etat = true;
      this.billoflading_etat = false;
    }

    this.selectedissuingbank =
      this.document_sg.messages[0].organizationReceiver.name;

    if (this.document_sg.billOfLadingNumber != '') {
      this.billoflading_etat = true;
      this.selectedbill = 'Bill Of Lading';
    }

    if (this.document_sg.airwayBillNumber != '') {
      this.airway_bill_etat = true;

      this.selectedbill = 'Airway Bill';
    }

    this.currencycode = this.document_sg.invoiceCurrencyCode;
    this.selectedamount = this.currencycode;
    this.spinner.hide();
  }

  GetSgById(id: any) {
    this.shippingGaranteService.GetByid(id).subscribe((res: any) => {
      this.document_sg = res;
      this.remplirForm();
    });
  }
}
