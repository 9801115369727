import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { ArchwizardModule } from 'angular-archwizard';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatNativeDateModule } from '@angular/material/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { AuthGuard } from 'src/Services/Guard/auth.guard';
import { ElogisticModule } from '../E-logistic/E-logistic.module';
import { EtradeModule } from '../E-trade/Etrade.module';
import { EcustomsModule } from '../E-customs/Ecustoms.module';
import { AffectationElogisticComponent } from './affectation.component';

@NgModule({
  declarations: [AffectationElogisticComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    MatIconModule,
    MatListModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    MatTabsModule,
    ArchwizardModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    NgSelectModule,
    MatStepperModule,
    MatRadioModule,
    MatCardModule,
    ElogisticModule,
    EtradeModule,
    EcustomsModule,
    MatTableModule,
    RouterModule.forChild([
      {
        path: 'organisation',
        component: AffectationElogisticComponent,
        canActivate: [AuthGuard],
      },
    ]),
  ],
  exports: [],
  providers: [DatePipe],
})
export class AffectationModule { }
