import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class VisitPurposeService {
  url: any;

  Spring_serveur: string = 'bsm-service/api';
  constructor(private router: Router, private http: HttpClient) {
    this.url = environment.Url + this.Spring_serveur + '/ArrVciVisitPurpose/';
  }
  public Get() {
    return this.http.get<any>(`${this.url}`);
  }

  public post(formData: any) {
    return this.http.post(this.url + 'saveArrVciVisitPurpose', formData, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    });
  }
  public update(formData: any, id: any) {
    return this.http.put<any>(`${this.url + id + '/'}`, formData);
  }
  public delete(id: any) {
    return this.http.delete<any>(
      `${this.url + 'ArrVciVisitPurpose' + id + '/'}`
    );
  }
}
