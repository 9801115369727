<link
  rel="stylesheet"
  href="https://use.fontawesome.com/releases/v5.5.0/css/all.css"
  integrity="sha384-B4dIYHKNBt8Bc12p+WXckhzcICo0wtJAoU8YZTY5qE0Id1GSseTk6S+L3BlXeVIU"
  crossorigin="anonymous"
/>
<script
  src="https://kit.fontawesome.com/8f702b3b95.js"
  crossorigin="anonymous"
></script>
<div class="c1" [ngStyle]="{ direction: direction }">
  <div class="Trac-2081"></div>

  <div>
    <h2 class="titre-page">{{ languea.titre }}</h2>
    <div class="about-border"></div>
  </div>
</div>

<div class="row" [ngStyle]="{ direction: direction }">
  <div class="col-lg-12">
    <mat-stepper linear #stepper>
      <mat-step [stepControl]="FormGeneralinformation">
        <ng-template matStepLabel>
          <div style="display: flex">
            <label style="min-width: 100px"
              >{{ languea.eneralinformation }}
            </label>
          </div>
        </ng-template>
        <div style="padding-top: 5%">
          <div
            class="titre_mobile"
            style="text-align: center; padding-bottom: 4%"
          >
            <label style="min-width: 100px; font-size: 18px"
              >{{ languea.eneralinformation }}
            </label>
          </div>
          <form [formGroup]="FormGeneralinformation" (ngSubmit)="next()">
            <div class="row">
              <div class="col-lg-6">
                <div>
                  <label class="titrechamp"> {{ languea.fdcc }} *</label>
                  <div>
                    <ng-select
                      formControlName="selectedfdcc"
                      [items]="fdcc_list"
                      (change)="changefdcc($event)"
                      bindLabel="nom"
                      placeholder=""
                      [(ngModel)]="selectedfdcc"
                      [ngClass]="{
                        'is-invalid': formSubmitted,
                        formerror: formSubmitted
                      }"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div>
                  <label class="titrechamp"> {{ languea.branchname }} *</label>
                  <div>
                    <ng-select
                      formControlName="selectedbranch"
                      [items]="branch_list"
                      (change)="changebranch($event)"
                      bindLabel="name"
                      placeholder=""
                      [(ngModel)]="selectedbranch"
                      [ngClass]="{
                        'is-invalid': formSubmitted,
                        formerror: formSubmitted
                      }"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div>
                  <label class="titrechamp"> {{ languea.typeaction }} *</label>
                  <div>
                    <ng-select
                      formControlName="selectedtype"
                      [items]="type_list"
                      (change)="changetype($event)"
                      bindLabel="name"
                      placeholder=""
                      [(ngModel)]="selectedtype"
                      [ngClass]="{
                        'is-invalid': formSubmitted,
                        formerror: formSubmitted
                      }"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <label class="titrechamp">{{ languea.agentcompany }} </label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    class="form-control mb-4"
                    type="text"
                    formControlName="ac"
                    [ngStyle]="{
                      'border-color':
                        (formSubmitted ||
                          FormGeneralinformation.controls.ac.touched) &&
                        FormGeneralinformation.controls.ac.errors?.required
                          ? 'red'
                          : '#47b2e4'
                    }"
                  />
                </div>
              </div>

              <div class="col-lg-6">
                <label class="titrechamp"> {{ languea.agentstaffname }} </label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    class="form-control mb-4"
                    type="text"
                    formControlName="asnm"
                    [ngStyle]="{
                      'border-color':
                        (formSubmitted ||
                          FormGeneralinformation.controls.asnm.touched) &&
                        FormGeneralinformation.controls.asnm.errors?.required
                          ? 'red'
                          : '#47b2e4'
                    }"
                  />
                </div>
              </div>
            </div>

            <div>
              <div
                class="div_next"
                [ngStyle]="{
                  float: langue === 'AR' ? 'left' : 'right'
                }"
              >
                <button
                  class="buttonnext"
                  mat-button
                  (click)="next()"
                  matStepperNext
                >
                  {{ languea.next }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </mat-step>
      <mat-step [stepControl]="FormIteminformation">
        <ng-template matStepLabel>
          <div style="display: flex">
            <label style="min-width: 100px">
              {{ languea.iteminformation }}
            </label>
          </div>
        </ng-template>
        <div style="padding-top: 5%">
          <div
            class="titre_mobile"
            style="text-align: center; padding-bottom: 4%"
          >
            <label style="min-width: 100px; font-size: 18px"
              >{{ languea.iteminformation }}
            </label>
          </div>
          <form [formGroup]="FormIteminformation" (ngSubmit)="next1()">
            <div class="row">
              <div class="col-lg-6">
                <div>
                  <label class="titrechamp">
                    {{ languea.itemdevision }} *</label
                  >
                  <div>
                    <ng-select
                      formControlName="selecteddevision"
                      [items]="devision_list"
                      (change)="changedevision($event)"
                      bindLabel="name"
                      placeholder=""
                      [(ngModel)]="selecteddevision"
                      [ngClass]="{
                        'is-invalid': formSubmitted1,
                        formerror: formSubmitted1
                      }"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div>
                  <label class="titrechamp"> {{ languea.hscode }} *</label>
                  <div>
                    <ng-select
                      formControlName="selectedcodehs"
                      [items]="codehs_list"
                      (change)="changecodehs($event)"
                      bindLabel="name"
                      placeholder=""
                      [(ngModel)]="selectedcodehs"
                      [ngClass]="{
                        'is-invalid': formSubmitted1,
                        formerror: formSubmitted1
                      }"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <label class="titrechamp">{{ languea.itemname }}</label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    class="form-control mb-4"
                    type="text"
                    formControlName="in"
                    [ngStyle]="{
                      'border-color':
                        (formSubmitted1 ||
                          FormIteminformation.controls.in.touched) &&
                        FormIteminformation.controls.in.errors?.required
                          ? 'red'
                          : '#47b2e4'
                    }"
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <label class="titrechamp">{{ languea.quantity }} *</label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    class="form-control mb-4"
                    type="number"
                    formControlName="qu"
                    required
                    [ngStyle]="{
                      'border-color':
                        (formSubmitted1 ||
                          FormIteminformation.controls.qu.touched) &&
                        FormIteminformation.controls.qu.errors?.required
                          ? 'red'
                          : '#47b2e4'
                    }"
                  />
                </div>
              </div>
              <div class="col-lg-4">
                <label class="titrechamp">{{ languea.packing }} *</label>

                <div>
                  <ng-select
                    formControlName="selectedcodemt"
                    [items]="codemt_list"
                    (change)="changecodemt($event)"
                    bindLabel="name"
                    placeholder=""
                    [(ngModel)]="selectedcodemt"
                  >
                  </ng-select>
                </div>
              </div>

              <div class="col-lg-4">
                <label class="titrechamp">{{ languea.originnational }} *</label>

                <div style="padding-top: 1%">
                  <mat-select-country
                    [value]="Valuedorigin"
                    placeHolder=""
                    (onCountrySelected)="onCountrySelected($event)"
                  >
                  </mat-select-country>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-3">
                  <label class="titrechamp">{{
                    languea.itemshippingamount
                  }}</label>
                  <div style="padding-top: 2%">
                    <input
                      matInput
                      class="form-control mb-4"
                      type="number"
                      formControlName="isa"
                    />
                  </div>
                </div>

                <div class="col-lg-3">
                  <label class="titrechamp">{{
                    languea.itemShippingamountcurrencyunit
                  }}</label>
                  <div style="padding-top: 1%">
                    <ng-select
                      formControlName="isacu"
                      [items]="deviselist"
                      (change)="changeCurrency($event.target.value)"
                      bindLabel="name"
                      placeholder=""
                      [(ngModel)]="devise"
                      [ngClass]="{
                        'is-invalid': formSubmitted,
                        formerror: formSubmitted
                      }"
                    >
                    </ng-select>
                  </div>
                </div>

                <div class="col-lg-3">
                  <label class="titrechamp">{{
                    languea.currencyexchange
                  }}</label>
                  <div style="padding-top: 2%">
                    <input
                      matInput
                      class="form-control mb-4"
                      type="number"
                      formControlName="ce"
                      [readonly]="true"
                    />
                  </div>
                </div>

                <div class="col-lg-3">
                  <label class="titrechamp">{{ languea.dinaramount }}</label>
                  <div style="padding-top: 2%">
                    <input
                      matInput
                      class="form-control mb-4"
                      type="number"
                      formControlName="da"
                      [readonly]="true"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                class="div_back"
                [ngStyle]="{
                  float: langue === 'AR' ? 'right' : 'left'
                }"
              >
                <button class="buttonback" mat-button matStepperPrevious>
                  {{ languea.previous }}
                </button>
              </div>
              <div
                class="div_next"
                [ngStyle]="{
                  float: langue === 'AR' ? 'left' : 'right'
                }"
              >
                <button
                  class="buttonnext"
                  mat-button
                  (click)="next1()"
                  matStepperNext
                >
                  {{ languea.next }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </mat-step>

      <mat-step [stepControl]="FormAdditioninformation">
        <ng-template matStepLabel>
          <div style="display: flex">
            <label style="min-width: 100px"
              >{{ languea.additioninformation }}
            </label>
          </div>
        </ng-template>
        <div style="padding-top: 5%">
          <div
            class="titre_mobile"
            style="text-align: center; padding-bottom: 4%"
          >
            <label style="min-width: 100px; font-size: 18px"
              >{{ languea.additioninformation }}
            </label>
          </div>
          <form [formGroup]="FormAdditioninformation">
            <div class="row">
              <div class="col-lg-6">
                <label class="titrechamp"
                  >{{ languea.arrivalnoticeno }} *
                </label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    class="form-control mb-4"
                    type="text"
                    formControlName="ano"
                    required
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <label class="titrechamp">
                  {{ languea.itemarrivalplace }}</label
                >

                <div>
                  <ng-select
                    formControlName="selectedarrival"
                    [items]="arrival_list"
                    (change)="changearrival($event)"
                    bindLabel="name"
                    placeholder=""
                    [(ngModel)]="selectedarrival"
                  >
                  </ng-select>
                </div>
              </div>

              <div class="col-lg-6">
                <div>
                  <label class="titrechamp">{{ languea.blnumber }} *</label>
                  <div style="padding-top: 1%">
                    <input
                      matInput
                      class="form-control mb-4"
                      type="text"
                      formControlName="bln"
                      required
                    />
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <label class="titrechamp">
                  {{ languea.clearancedeclarationnumber }} *
                </label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    class="form-control mb-4"
                    type="text"
                    formControlName="cdn"
                    required
                  />
                </div>
              </div>

              <div class="col-lg-6">
                <label class="titrechamp"> {{ languea.remark }} *</label>

                <div style="padding-top: 1%">
                  <input
                    matInput
                    class="form-control mb-4"
                    type="text"
                    formControlName="re"
                    required
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div>
          <div
            class="div_back"
            [ngStyle]="{
              float: langue === 'AR' ? 'right' : 'left'
            }"
          >
            <button class="buttonback" mat-button matStepperPrevious>
              {{ languea.previous }}
            </button>
          </div>
        </div>
      </mat-step>
    </mat-stepper>
  </div>

  <div class="col-lg-12">
    <div class="col-lg-12 actions">
      <div class="btnmobile">
        <button
          type="button"
          [disabled]="
            selectedbranch == null ||
            selectedfdcc == null ||
            transactiontype != 'Update'
          "
          class="btn buttonsave"
          (click)="Submit(content3)"
        >
          <label class="labsave"> {{ languea.submit }}</label>
        </button>
      </div>
      <div class="btnmobile">
        <button
          type="button"
          [disabled]="
            selectedbranch == null ||
            selectedfdcc == null ||
            selectedcodehs == null ||
            selectedcodemt == null
          "
          class="btn buttonsave"
          (click)="Save(content4)"
        >
          <label class="labsave"> {{ languea.save }}</label>
        </button>
      </div>

      <div class="btnmobile">
        <input
          style="display: none"
          type="file"
          #fileInput
          (change)="onFileChange($event)"
          name="uploadFile"
          multiple="multiple"
          #hiddenfileinput
        />
        <button
          type="button"
          class="btn buttonupload"
          (click)="hiddenfileinput.click()"
        >
          <label class="labupload"> {{ languea.upload }}</label>
        </button>
      </div>
      <div class="btnmobile">
        <button (click)="Go_list()" type="button" class="btn buttonlist">
          <div style="padding-top: 3px">
            <label class="lablist"> {{ languea.list }} </label>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #content3>
  <div class="modal-body rounded-8" [ngStyle]="{ direction: direction }">
    <div style="text-align: center">
      <div>
        <span
          class="material-icons green-color"
          style="
            font-size: 100px;
            color: #41d96f;
            text-align: center;
            font-weight: 800;
          "
          >check_circle
        </span>
      </div>
      <div>
        <label
          style="
            font-family: Montserrat;
            font-size: 26px;
            padding-top: 2%;
            font-weight: bold;
            color: #073866;
          "
        >
          {{ languet.titremodal }}</label
        >
      </div>
      <div>
        <label
          style="
            width: 310px;
            font-size: 16px;
            font-weight: bold;
            padding-top: 40px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.77;
            letter-spacing: normal;
            text-align: center;
          "
        >
          {{ languet.descriptionsubmittechnical }}</label
        >
      </div>
    </div>
  </div>
</ng-template>

<ng-template #content4>
  <div class="modal-body rounded-8" [ngStyle]="{ direction: direction }">
    <div style="text-align: center">
      <div>
        <span
          class="material-icons green-color"
          style="
            font-size: 100px;
            color: #41d96f;
            text-align: center;
            font-weight: 800;
          "
          >check_circle
        </span>
      </div>
      <div>
        <label
          style="
            font-family: Montserrat;
            font-size: 26px;
            padding-top: 2%;
            font-weight: bold;
            color: #073866;
          "
        >
          {{ languet.titremodal }}</label
        >
      </div>
      <div>
        <label
          style="
            width: 310px;
            font-size: 16px;
            font-weight: bold;
            padding-top: 40px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.77;
            letter-spacing: normal;
            text-align: center;
          "
        >
          {{ languet.descriptionsavetechnical }}</label
        >
      </div>
      <div class="text-center" style="padding-bottom: 2%; padding-top: 6%">
        <button type="button" class="btn button2" (click)="Go_list()">
          <span class="Save"> {{ languet.list }}</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.5.1/jspdf.umd.min.js"></script>

<notifier-container></notifier-container>
