import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class WaybillsService {
  url: any;

  Spring_serveur: string = 'bsm-service/api';
  scenario_rest_url: string = '/waybills/'
  constructor(private router: Router, private http: HttpClient) {
    this.url = environment.Url + this.Spring_serveur + this.scenario_rest_url;
  }
  public Get() {
    return this.http.get<any>(`${this.url}`);
  }

  public post(formData: any) {
    return this.http.post(this.url, formData, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    });
  }
  public update(formData: any, id: any) {
    return this.http.put<any>(`${this.url}`, formData);
  }
  public delete(id: any) {
    return this.http.delete<any>(`${this.url + id}`);
  }
}
