<div>
  <div
    style="text-align: center; min-height: 50px"
    *ngIf="profil == 'Trading_Company'"
  >
    <div style="padding-top: 1%">
      <label>{{ languef.Assignment }}</label>
      <mat-radio-group
        style="padding: 0% 2% 0% 2%"
        (change)="onchangetrade($event)"
      >
        <mat-radio-button
          checked="trading_customs_sc"
          style="padding: 0% 2% 0% 2%"
          value="1"
          style="color: black"
          >{{ languef.customs }}
        </mat-radio-button>
        <mat-radio-button value="2" style="padding: 0% 2% 0% 2%; color: black"
          >{{ languef.trade }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div style="text-align: center; min-height: 50px" *ngIf="profil == 'Customs'">
    <div style="padding-top: 1%">
      <label>{{ languef.Assignment }}</label>
      <mat-radio-group
        style="padding: 0% 2% 0% 2%"
        (change)="onchangecustoms($event)"
      >
        <mat-radio-button
          checked="trading_customs_sc"
          style="padding: 0% 2% 0% 2%"
          value="2"
          style="color: black"
          >{{ languef.customs }}
        </mat-radio-button>
        <mat-radio-button
          checked="trading_logistic_sc"
          value="1"
          style="padding: 0% 2% 0% 2%; color: black"
          >{{ languef.logistic }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div style="padding-top: 2%">
    <div *ngIf="logistic_etat && trading_logistic_sc">
      <app-page-accueil-logistic [profil]="profil"></app-page-accueil-logistic>
    </div>
    <div *ngIf="trade_etat && trading_trade_sc">
      <app-page-accueil-trade [profil]="profil"></app-page-accueil-trade>
    </div>

    <div *ngIf="customs_etat && trading_customs_sc">
      <app-page-accueil-customs [profil]="profil"></app-page-accueil-customs>
    </div>
  </div>
</div>
